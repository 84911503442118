import React, { FC, useState, useEffect, useContext, useRef } from 'react';
import { useTheme, Button, Container, Grid, makeStyles, Theme, Typography, Backdrop, CircularProgress, useMediaQuery } from '@material-ui/core';
import { Page, StandardConfirmationDialog, Breadcrumb, FormPinDialog } from 'components';
import { BLUE_PRIMARY, WHITE } from 'constants/colors';
import {
  GET_SALES_ORDER_DETAIL_BASE_URL,
  COMPANY_BASE_URL,
  SALES_ORDER_BASE_URL,
  INVOICE_BASE_URL,
  CHANGE_STATUS_SALES_ORDER_ITEM,
  GET_HISTORY_LOG_ORDER_ITEM,
  ORDER_ITEM_BASE_URL,
  CREATE_COLLY,
  SET_ISCLOSE_SO,
  DELETE_SALES_ORDER_ITEM
} from 'constants/url';
import { dummySalesOrder, dummyCompany, dummyPartner, dummyCollyFinal, dummyCollyFinalUpdate } from 'utils/dummy';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import ItemStatus from 'typings/enum/ItemStatus';
import axios from 'axios';
import useRouter from 'hooks/useRouter';
import useRole from 'hooks/useRole';
import Content from './components/content';
import Alert from '@material-ui/lab/Alert';
import useConfirmationPin from 'hooks/useConfirmationPin';
import TypeUser from 'typings/enum/TypeUser';
import ModalEdit from './components/content/components/ModalEdit';
import SalesOrderStatus from 'typings/enum/SalesOrderStatus';
import { remarkCode } from 'constants/remarkCode';
import ImageModal from 'components/ImageModal';
import ModalOpenSales from './components/ModalOpenSales';

const useStyles = makeStyles((theme: Theme) => ({
  ButtonNew: {
    color: BLUE_PRIMARY,
    backgroundColor: WHITE
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: ' none'
    }
  },

  desktop: {
    [theme.breakpoints.down('md')]: {
      display: ' none'
    }
  },
  mobileContainer: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
      '& > .MuiGrid-item': {
        padding: 0
      }
    }
  },
  buttonContainerMobile: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 4,
    backgroundColor: '#fff',
    padding: '10px 10px',
    boxShadow: '0px 0px 17px rgba(0, 0, 0, 0.08)'
  },
  containerCard: {
    backgroundColor: WHITE,
    border: '0.6px solid rgba(0, 0, 0, 0.08)',
    borderRadius: '6px',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2)
  }
}));

const SalesOrderDetailPage: FC = () => {
  const { match, history } = useRouter();
  const theme = useTheme();

  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const showComponent = useMediaQuery(theme.breakpoints.down('md'));

  const params = match.params.id;
  const classes = useStyles();
  const { currentUser } = useContext(CurrentUserContext);
  const [isOpenSO, setIsOpenSO] = useState<boolean>(false);
  const [openBy, setOpenBy] = useState<string>('');
  const [typeUserOpen, setTypeUserOpen] = useState<string>('');

  const isAccess = (currentUser && currentUser.type) || TypeUser.PICKER;
  const userName = (currentUser && `${currentUser.firstName} ${currentUser.lastName}`) || '';
  const getWareHouse: any = (currentUser && currentUser.WareHouseId) || 0;
  const [discountLoading, setDiscountLoading] = useState<boolean>(false);
  const [salesOrder, setSalesOrder] = useState<SalesOrderModel>(dummySalesOrder);
  const [company, setCompany] = useState<CompanyModel>(dummyCompany);
  const [getIdClick, setGetIdClick] = useState<number>(0);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [loadingDataSave, setLoadingDataSave] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [rejectOrder, setRejectOrder] = useState<ItemAction[]>([]);
  const [salesOrderItem, setSalesOrderItem] = useState<SalesOrderItemModel[]>([]);
  const [invoiceItem, setInvoiceItem] = useState<SalesOrderItemModel[]>([]);
  const [value, setValue] = useState(0);
  const [id, setId] = useState<number>(0);
  const [invoiceId, setInvoceId] = useState<number>(0);

  const [loadingPrint, setLoadingPrint] = useState<boolean>(false);
  const [partnerMessage, setPartnerMessage] = useState<string>('');
  const [pin, setPin] = useState<number | null>(null);

  const [currentItem, setCurrentItem] = useState<SalesOrderItemModel[]>([]);
  const [currentWareHouse, setCurrentWareHouse] = useState<string>('');
  const [totalItemWareHouse, setTotalItemWareHouse] = useState<{ name: string; total: number }[]>([]);
  const [currentCheckWarehouse, setCurrentCheckWarehouse] = useState<string>('');

  const [currentStatus, setCurrentStatus] = useState<{ id: number; status: string; currentStatus: string }[]>([]);
  const [itemColly, setItemColly] = useState<typeColly[]>([]);
  const [convertMessage, setConvertMessage] = useState<string>('');
  const [consistenMessage, setConsistenMessage] = useState<string>('');

  const [anchorEl, setAnchorEl] = useState(null);
  const [indexTooltip, setIndexTooltip] = useState<number>(-1);
  const [indexAlertPrice, setIndexAlertPrice] = useState<number>(-1);
  const [indexAlertDiscount, setIndexAlertDiscount] = useState<number>(-1);
  const [loadingReject, setLoadingReject] = useState<boolean>(false);
  const [isDeny, setDeny] = useState<boolean>(false);
  const [isComplete, setComplete] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [isChange, setChange] = useState<boolean>(false);
  const [isRedudant, setRedudant] = useState<boolean>(false);
  const [isValid, setValid] = useState<boolean>(false);
  const [isChangeSubmit, setChangeSubmit] = useState<boolean>(false);
  const [isDelete, setDelete] = useState<boolean>(false);
  const [loadingConvet, setLoadingConvet] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [confirmationInvoice, setConfirmationInvoice] = useState<boolean>(false);
  const [hasInvoice, setHasInvoice] = useState<boolean>(false);
  const [converting, setConverting] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [openPreview, setOpenPreview] = useState<boolean>(false);

  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const [openAlertPrice, setOpenAlertPrice] = useState<boolean>(false);
  const [collyFinal, setCollyFinal] = useState<typeCollyFinal>(dummyCollyFinal);

  const [collyResource, setCollyResource] = useState<typeCollyModel[]>([]);
  const [collyFinalUpdate, setCollyFinalUpdate] = useState<typeCollyModel>(dummyCollyFinalUpdate);

  const [openAlertDiscount, setOpenAlertDiscount] = useState<boolean>(false);
  const [historyResource, setHistoryResource] = useState<HistoryType[]>([]);
  const [loadingHistoryResource, setLoadingHistoryResource] = useState<boolean>(false);
  const [openImage, setOpenImage] = useState<boolean>(false);
  const [imagePath, setImagePath] = useState<string>('');
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [hasOpenModal, setHasOpenModal] = useState<number>(0);
  const [editQtyLoading, setEditQtyLoading] = useState<boolean>(false);

  const isConvertAllowed = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN, TypeUser.SUPERADMIN]
  });
  const isPacking = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN02, TypeUser.ADMIN02PLUS]
  });

  let isNotAdminAllowed = true;
  if (currentUser && (currentUser.type === TypeUser.SALES || currentUser.type === TypeUser.SUPERVISOR)) {
    isNotAdminAllowed = false;
    if (currentUser.id == salesOrder.SalesId) {
      isNotAdminAllowed = true;
    }
  }

  const buttonGroup = useRef<HTMLDivElement>(null);

  const pinMessage = useConfirmationPin({
    pin,
    confirmationDelete: confirmationDelete,
    confirmationCreate: confirmationInvoice,
    isCompletePin: isComplete,
    handleOnDelete: deleteSalesOrder,
    handleOnCreate: () => console.log(),
    setOpenConfirmation: setOpenConfirmation,
    setLoading: setLoadingDelete,
    setCompletePin: setComplete,
    setPin: setPin
  });

  const fetchData = async () => {
    setIsLoadingData(true);
    try {
      const { data } = await axios.get(GET_SALES_ORDER_DETAIL_BASE_URL(Number(params)), {
        cancelToken
      });

      if (isPacking && currentUser && data.data.isOpeWareHouse) {
        const tempSalesOrderView: Pick<SalesOrderModel & { userName: string }, 'isOpen' | 'isOpeWareHouse' | 'userName'>[] = JSON.parse(
          data.data.isOpeWareHouse
        );
        const salesOrederView = tempSalesOrderView.filter(
          ({ isOpen, isOpeWareHouse }) => isOpen !== currentUser.id && isOpeWareHouse === currentUser.WareHouseName[0].name
        );
        if (salesOrederView.length > 0) {
          setIsOpenSO(true);
          setOpenBy(salesOrederView[0].userName);
          setTypeUserOpen('PENGEPAKAN');

          return;
        }
      }

      if (!isPacking && currentUser && data.data.isOpen && data.data.isOpen !== currentUser.id) {
        setIsOpenSO(true);
        setOpenBy(data.data.openBy);
        setTypeUserOpen(data.data.typeUserOpen);
        return;
      }

      setSalesOrder(data.data);
      setSalesOrderItem(data.data.SalesOrderItem);

      setId(data.data.id);
      setHasInvoice(data.data.statusOrder === 'CONFIRMATION');
      setIsLoadingData(false);
    } catch (error) {
      console.log('error:', error);
    }
  };

  const fetchCompany = async () => {
    try {
      const { data } = await axios.get(COMPANY_BASE_URL);
      setCompany(data.data);
    } catch (error) {
      console.log('error:', error);
    }
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleOpenImage = (imagePath: string) => {
    setOpenImage(true);
    setImagePath(imagePath);
  };
  const handleCloseImage = () => {
    setOpenImage(false);
  };

  const handleConfirmSnackbar = () => {
    setOpenSnackbar(false);
    if (invoiceId > 0) {
      window.open(`/invoice/${invoiceId}`, '_blank');
      setInvoceId(0);
    }

    if (isDelete) {
      setDelete(false);
      history.push('/penjualan');
    }
  };
  const handleCloseSO = async () => {
    if (isAccess === TypeUser.SALES || isAccess === TypeUser.SUPERVISOR || isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS) {
      try {
        await axios.get(`${SET_ISCLOSE_SO(Number(params))}`);
        history.push(`/penjualan`);
      } catch (error) {
        console.log(error);
      }
    } else {
      history.push(`/penjualan`);
    }
  };

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        handleCloseSO();
      }
    };
  }, []);

  const confirmationMessage = 'You have unsaved changes. Continue?';

  const handleBeforeUnload = (event: any) => {
    event.preventDefault();
    event.returnValue = confirmationMessage;
    return confirmationMessage;
  };

  window.addEventListener('beforeunload', handleBeforeUnload);

  const handleRejectSuperAdmin = async (value: ItemAction) => {
    setLoadingReject(true);
    const newData = [
      {
        id: value.id,
        remark: value.remark,
        remarkCode: remarkCode.REJECT,
        status: SalesOrderStatus.REJECT,
        rejectBy: userName
      }
    ];

    try {
      await axios.post(CHANGE_STATUS_SALES_ORDER_ITEM, {
        items: newData
      });
      const index = salesOrderItem.findIndex(val => val.id === value.id);
      salesOrderItem[index].status = SalesOrderStatus.REJECT;
      salesOrderItem[index].rejectBy = userName;
      setLoadingReject(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelRejectSuperAdmin = async (value: ItemAction) => {
    setLoadingReject(true);
    const newData = [
      {
        id: value.id,
        remark: value.remark,
        remarkCode: remarkCode.REJECT,
        status: SalesOrderStatus.REJECT,
        rejectBy: userName
      }
    ];

    try {
      await axios.post(CHANGE_STATUS_SALES_ORDER_ITEM, {
        items: newData
      });
      const index = salesOrderItem.findIndex(val => val.id === value.id);
      salesOrderItem[index].status = SalesOrderStatus.PENDING;

      setLoadingReject(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleHistoryLog = async () => {
    setLoadingHistoryResource(true);
    try {
      const { data } = await axios.get(`${GET_HISTORY_LOG_ORDER_ITEM(Number(params))}`);
      setHistoryResource(data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingHistoryResource(false);
    }
  };

  const handleOnSubmit = async () => {};

  const validationChecker = salesOrderItem.filter(
    value =>
      value.status === SalesOrderStatus.PREVIEW ||
      value.status === SalesOrderStatus.PACKING ||
      (value.status === SalesOrderStatus.REJECT && value.rejectBy === userName)
  ).length;

  const validationPicker = salesOrderItem.filter(
    value =>
      value.status === SalesOrderStatus.PENDING ||
      value.status === SalesOrderStatus.PREVIEW ||
      (value.status === SalesOrderStatus.REJECT && value.rejectBy === userName)
  ).length;

  async function handleOnCreateInvoice(idle?: boolean) {}

  const downloadPdf = async () => {
    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/download/${invoiceId}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoice.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log('error :', error);
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleConfirm = (index: number): React.MouseEventHandler => () => {
    const currentItems = salesOrderItem.find(value => value.id === index);

    if (currentItems) {
      const item = {
        ...currentItems,
        status: ItemStatus.CONFIRM
      };

      setCurrentWareHouse(prevState => (prevState === '' ? item.warehouse || 'NO_WAREHOUSE' : prevState));

      setCurrentItem(prevState => {
        if (prevState.some(value => value.id === index)) {
          return prevState.map(value => {
            if (value.id === index) {
              value = item;
            }

            return value;
          });
        } else {
          return [...prevState, item];
        }
      });

      setCurrentStatus(prevState => {
        if (prevState.some(value => value.id === index)) {
          return prevState.map(value => {
            if (value.id === index) {
              value = {
                id: item.id,
                status: currentItems.status,
                currentStatus: item.status
              };
            }

            return value;
          });
        } else {
          return [
            ...prevState,
            {
              id: item.id,
              status: currentItems.status,
              currentStatus: item.status
            }
          ];
        }
      });

      if (currentItems.status === ItemStatus.CONFIRM) return;

      setSalesOrderItem(
        salesOrderItem.map(value => {
          if (value.id === item.id) {
            value = item;
          }
          return value;
        })
      );

      setSalesOrder(prevState => {
        prevState.totalPrice = prevState.totalPrice + item.totalPrice;
        prevState.totalDiscount = prevState.totalDiscount + item.discount;
        return prevState;
      });

      setChange(true);
    }
  };

  const handleReject = (index: number): React.MouseEventHandler => () => {
    const currentItems = salesOrderItem.find(value => value.id === index);

    if (currentItems) {
      const item = {
        ...currentItems,
        status: ItemStatus.REJECT
      };

      setCurrentWareHouse(prevState => (prevState === '' ? item.warehouse || 'NO_WAREHOUSE' : prevState));

      setCurrentItem(prevState => {
        if (prevState.some(value => value.id === index)) {
          return prevState.map(value => {
            if (value.id === index) {
              value = item;
            }

            return value;
          });
        } else {
          return [...prevState, item];
        }
      });

      setCurrentStatus(prevState => {
        if (prevState.some(value => value.id === index)) {
          return prevState.map(value => {
            if (value.id === index) {
              value = {
                id: item.id,
                status: currentItems.status,
                currentStatus: item.status
              };
            }

            return value;
          });
        } else {
          return [
            ...prevState,
            {
              id: item.id,
              status: currentItems.status,
              currentStatus: item.status
            }
          ];
        }
      });

      if (currentItems.status === ItemStatus.REJECT) return;

      setSalesOrderItem(
        salesOrderItem.map(value => {
          if (value.id === item.id) {
            value = item;
          }
          return value;
        })
      );

      if (currentItems.status === ItemStatus.CONFIRM) {
        setSalesOrder(prevState => {
          prevState.totalPrice = prevState.totalPrice - item.totalPrice;
          prevState.totalDiscount = prevState.totalDiscount - item.discount;
          return prevState;
        });
      }

      setChange(true);
    }
  };

  const handleOpenConfirmationDelete = () => {
    setConfirmationDelete(true);
  };

  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  const handleCloseConfirmationInvoice = () => {
    setConfirmationInvoice(false);
    if (salesOrderItem.length > 0) {
      if (currentCheckWarehouse !== '') {
        setInvoiceItem(
          salesOrderItem.filter(value => value.warehouse && value.warehouse === currentCheckWarehouse && value.status === ItemStatus.CONFIRM)
        );
      } else {
        setInvoiceItem(salesOrderItem.filter(value => value.status === ItemStatus.CONFIRM));
      }
    }
  };

  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
    setPin(null);
  };

  const handleOpenTooltip = (index: number): React.MouseEventHandler => () => {
    setOpenTooltip(prevState => (!prevState ? true : indexTooltip !== index ? true : false));
    setIndexTooltip(index);
  };

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
    setIndexTooltip(-1);
  };

  const handleOpenAlertPrice = (index: number): React.MouseEventHandler => () => {
    setOpenAlertPrice(prevState => (!prevState ? true : indexAlertPrice !== index ? true : false));
    setIndexAlertPrice(index);
  };

  const handleCloseAlertPrice = () => {
    setOpenAlertPrice(false);
    setIndexAlertPrice(-1);
  };

  const handleOpenAlertDiscount = (index: number): React.MouseEventHandler => () => {
    setOpenAlertDiscount(prevState => (!prevState ? true : indexAlertDiscount !== index ? true : false));
    setIndexAlertDiscount(index);
  };

  const handleCloseAlertDiscount = () => {
    setOpenAlertDiscount(false);
    setIndexAlertDiscount(-1);
  };

  const handleCheckIndividualPreview = (item: SalesOrderItemModel) => {
    setInvoiceItem(prevState => {
      if (prevState.some(value => value.id === item.id)) {
        return prevState.filter(value => value.id !== item.id);
      } else {
        return prevState;
      }
    });
  };

  async function deleteSalesOrder() {
    try {
      await axios.delete(GET_SALES_ORDER_DETAIL_BASE_URL(id));
      setDelete(true);
      handleSnackBar(true, 'success', 'Order berhasil dihapus');
    } catch (err) {
      console.log(err);
      handleSnackBar(true, 'error', 'Order gagal dihapus');
    } finally {
      setConfirmationDelete(false);
    }
  }

  useEffect(() => {
    const partner: PartnerModel = salesOrder.Partner || dummyPartner;
    const totalBill = partner.totalBill && partner.totalBill > 0 ? salesOrder.totalPrice + partner.totalBill : 0;
    const plafon = partner.plafon || 0;

    if (totalBill > plafon && plafon > 0) {
      setPartnerMessage('Customer ini memiliki jumlah total tagihan belum terbayarkan.');
      setDeny(true);
    }
  }, [salesOrder]);

  useEffect(() => {
    fetchCompany();
    handleHistoryLog();
  }, []);

  useEffect(() => {
    fetchData();
    return () => source.cancel('Cancel Unmounted');
  }, [isPacking]);

  useEffect(() => {
    if (salesOrderItem.length < 1 && totalItemWareHouse.length > 1) return;
    let totalWareHouse = salesOrderItem.map((value: any) => value.warehouse || 'NO_WAREHOUSE').filter((v: any, i: any, a: any) => a.indexOf(v) === i);
    setTotalItemWareHouse(
      totalWareHouse.map((value: any) => {
        return {
          name: value,
          total: salesOrderItem.filter((_value: any) => _value.warehouse && _value.warehouse === value).length
        };
      })
    );

    const getWareHouse = totalWareHouse[0];
    if (isAccess === TypeUser.SUPERADMIN) return;
    setCurrentCheckWarehouse(getWareHouse);
  }, [salesOrderItem]);

  useEffect(() => {
    setConvertMessage('');

    if (salesOrderItem.length === 0) return;

    if (currentCheckWarehouse === '') {
      let totalWareHouse = salesOrderItem
        .filter(value => value.status === ItemStatus.CONFIRM)
        .map((value: any) => value.warehouse || 'NO_WAREHOUSE')
        .filter((v: any, i: any, a: any) => a.indexOf(v) === i);

      setRedudant(totalWareHouse.length > 1);
      setConvertMessage(totalWareHouse.length > 1 ? 'Akan terdapat 2 gudang dalam 1 Invoice mohon, untuk memilih satu gudang.' : '');

      if (totalWareHouse.length > 1) {
        setAnchorEl(buttonGroup.current as any);
      }
      return;
    }

    let totalWareHouse = salesOrderItem
      .filter(value => value.status === ItemStatus.CONFIRM && value.warehouse === currentCheckWarehouse)
      .map((value: any) => value.warehouse || 'NO_WAREHOUSE')
      .filter((v: any, i: any, a: any) => a.indexOf(v) === i);

    setAnchorEl(null);
    setRedudant(totalWareHouse.length > 1);
  }, [salesOrderItem, currentCheckWarehouse]);

  useEffect(() => {
    if (salesOrderItem.length === 0) return;

    if (currentCheckWarehouse !== '') {
      setInvoiceItem(
        salesOrderItem.filter(value => value.warehouse && value.warehouse === currentCheckWarehouse && value.status === ItemStatus.CONFIRM)
      );
    } else {
      setInvoiceItem(salesOrderItem.filter(value => value.status === ItemStatus.CONFIRM));
    }
  }, [salesOrderItem, currentCheckWarehouse]);

  const resetAction = () => {
    setInvoiceItem([]);
    setRejectOrder([]);
    setCurrentItem([]);

    setItemColly([]);
    setCurrentWareHouse('');
    setCurrentCheckWarehouse('');
    setChange(false);
    fetchData();
    setAnchorEl(null);
    setValid(false);
    setOpenEdit(false);
  };

  const addItemAction = (value: ItemAction): void => {
    setRejectOrder([...rejectOrder, value]);
  };

  const tempItemColly = (data: typeColly): void => {
    setItemColly([...itemColly, data]);
  };

  const tempCollyFinal = (data: typeCollyFinal): void => {
    setCollyFinal({ ...collyFinal, ...data });
  };

  const tempCollyFinalUpdate = (id: number): void => {
    const newData = JSON.parse(JSON.stringify(collyResource.find(value => value.id === id)));
    if (newData) {
      setCollyFinalUpdate(newData);
    }
  };

  const handleSubmitColly = async () => {
    setLoadingPrint(true);
    try {
      const collyItem = collyFinal.items.map((value: typeColly) => ({
        id: value.SalesOrderItemId,
        status: SalesOrderStatus.CHECKING,
        remark: 'Masuk ke Colly',
        remarkCode: remarkCode.ACCEPT
      }));

      await axios.post(CREATE_COLLY, collyFinal);

      await axios.post(CHANGE_STATUS_SALES_ORDER_ITEM, {
        items: collyItem
      });

      setLoadingPrint(false);
      setItemColly([]);

      setHasOpenModal(4);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteColly = (id: number) => {
    if (itemColly.some(val => val.SalesOrderItemId === id)) {
      const deleteData = itemColly.filter(val => val.SalesOrderItemId !== id);
      setItemColly(deleteData);
    }
  };

  const updateColly = async () => {
    setLoadingPrint(true);

    const dataFinal = {
      ...collyFinalUpdate,

      id: collyFinalUpdate.id,
      SalesOrderId: Number(params),
      WareHouseId: getWareHouse[0],
      name: collyFinalUpdate.name,
      packName: collyFinalUpdate.packName,
      packCode: 'all',
      items: collyFinalUpdate.BagItem.map(value => ({
        id: value.id,
        BagId: collyFinalUpdate.id,
        SalesOrderItemId: value.SalesOrderItemId,
        totalItem: value.totalItem
      }))
    };

    try {
      const { data } = await axios.put(CREATE_COLLY, dataFinal);

      setLoadingPrint(false);
      resetAction();
    } catch (error) {
      console.log(error);
    }
  };

  const handletRequestEdit = async (id: number, productId: number, totalQty: number, remark: string, remarkCode: string) => {
    setEditQtyLoading(true);
    try {
      const { data } = await axios.put(ORDER_ITEM_BASE_URL, {
        id: id,
        ProductId: productId,
        totalItem: totalQty,
        remark: remark,
        remarkCode: remarkCode
      });

      const index = salesOrderItem.findIndex(val => val.id === data.data.id);
      const newList = [...salesOrderItem];
      newList[index].totalItem = data.data.totalItem;
      newList[index].salesStock = data.data.salesStock;
      newList[index].discount = data.data.discount;
      newList[index].holdStock = data.data.holdStock;
      newList[index].totalPrice = data.data.totalPrice;
      newList[index].subTotalPrice = data.data.subTotalPrice;
      setSalesOrderItem(newList);
      setEditQtyLoading(false);
      setOpenEdit(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteSO = async () => {
    setLoadingPrint(true);
    try {
      await axios.delete(`${SALES_ORDER_BASE_URL}/${Number(params)}`);
      setLoadingPrint(false);
      history.push('/penjualan');
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateDiscount = async (
    id: number,
    ProductId: number,
    discount: number,
    totalQty: number,
    price: number,
    remark: string,
    notes: string
  ) => {
    setDiscountLoading(true);
    const totalPrice = salesOrderItem
      .map(v => {
        if (v.id === id) {
          return { ...v, totalPrice: price * totalQty - discount * totalQty };
        }
        return v;
      })
      .reduce((total, d) => total + d.totalPrice, 0);

    console.log('data TOtal', totalPrice);

    const items = {
      id,
      ProductId,
      discount,
      price,
      remark: remark,
      isPlafon: (salesOrder?.plafon ?? 0) > 0 && totalPrice > (salesOrder?.plafon ?? 0),
      remarkCode: remarkCode.EDIT,
      totalItem: totalQty,
      notes
    };
    try {
      const { data } = await axios.put(ORDER_ITEM_BASE_URL, items);

      const index = salesOrderItem.findIndex(val => val.id === data.data.id);
      const newList = [...salesOrderItem];
      newList[index].totalItem = data.data.totalItem;
      newList[index].discountUnit = data.data.discountUnit;
      newList[index].price = data.data.price;
      newList[index].discount = data.data.discount;
      newList[index].totalPrice = data.data.totalPrice;
      newList[index].notes = data.data.notes;
      newList[index].salesStock = data.data.salesStock;
      if (data.data.discount > 0) {
        salesOrder.isAccept = false;
      }

      setSalesOrderItem(newList);

      setDiscountLoading(false);
      setOpenEdit(false);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteSalesOrderItem = async () => {
    setLoadingPrint(true);
    try {
      await axios.delete(DELETE_SALES_ORDER_ITEM(getIdClick));
      const newData = salesOrderItem.filter(value => value.id !== getIdClick);
      setSalesOrderItem(newData);
      setLoadingPrint(false);
      setOpenEdit(false);

      if (newData.length < 1) {
        await axios.delete(`${SALES_ORDER_BASE_URL}/${Number(params)}`);
        history.push('/penjualan');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusOnSubmit = async () => {
    setLoadingDataSave(true);
    const newData = rejectOrder.map(value => ({
      id: value.id,
      remark: value.remark,
      remarkCode: value.remarkCode,
      status: value.status,
      rejectBy: userName
    }));

    try {
      await axios.post(CHANGE_STATUS_SALES_ORDER_ITEM, {
        items: newData
      });
      setHasOpenModal(4);

      setLoadingDataSave(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAcceptItem = (id: number): void => {
    if (rejectOrder.some(val => val.id === id)) {
      const index = rejectOrder.findIndex(val => val.id === id);
      const newListReject = [...rejectOrder];
      newListReject[index].status =
        isAccess === TypeUser.ADMIN01 ? SalesOrderStatus.PACKING : isAccess === TypeUser.PICKER ? SalesOrderStatus.PREVIEW : '';
      newListReject[index].remarkCode = remarkCode.ACCEPT;
      setRejectOrder(newListReject);
    }

    const index: number = salesOrderItem.findIndex(value => value.id === id);
    const newSalesOrderItem = [...salesOrderItem];
    if (newSalesOrderItem.length > 0) {
      newSalesOrderItem[index].status = isAccess === TypeUser.ADMIN01 ? SalesOrderStatus.PACKING : SalesOrderStatus.PREVIEW;
      setSalesOrderItem(newSalesOrderItem);
    }
  };

  const handleRejectItem = (id: number): void => {
    if (rejectOrder.some(val => val.id === id)) {
      const index = rejectOrder.findIndex(val => val.id === id);
      const newListReject = [...rejectOrder];
      newListReject[index].status = SalesOrderStatus.REJECT;
      newListReject[index].remarkCode = remarkCode.REJECT;
      setRejectOrder(newListReject);
    }

    const index: number = salesOrderItem.findIndex(value => value.id === id);
    const newSalesOrderItem = [...salesOrderItem];
    if (newSalesOrderItem.length > 0) {
      newSalesOrderItem[index].status = SalesOrderStatus.REJECT;
      newSalesOrderItem[index].rejectBy = userName;
      setSalesOrderItem(newSalesOrderItem);
    }
  };

  return (
    <Page title='Sales Order'>
      <Container>
        <Backdrop className={classes.backdrop} open={converting}>
          <CircularProgress color='inherit' /> &nbsp; Membuat Invoice...
        </Backdrop>

        <Grid container direction='row' className={classes.desktop}>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <Typography variant='h1'> Penjualan </Typography>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12} container justify='space-between'>
            <Breadcrumb />
          </Grid>
        </Grid>

        <Grid container direction='row' justify='space-between'>
          <Grid item lg={6} sm={6} md={6} xs={6}>
            {isDeny && !hasInvoice && (
              <Alert variant='filled' severity='warning'>
                {partnerMessage}
              </Alert>
            )}

            {salesOrder.isDeleted && (
              <Alert variant='outlined' severity='warning'>
                SO ini telah dihapus pada tanggal {salesOrder.deletedAt}
              </Alert>
            )}
          </Grid>
        </Grid>

        <ImageModal open={openImage} handleClose={handleCloseImage} imageUrl={imagePath} />

        <ModalOpenSales open={isOpenSO} openBy={openBy} typeUserOpen={typeUserOpen} />

        <Grid container direction='row' spacing={showComponent ? 0 : 2} justify='space-between' classes={{ root: classes.mobileContainer }}>
          <Content
            salesOrder={salesOrder}
            salesOrderItem={salesOrderItem}
            company={company}
            handletRequestEdit={handletRequestEdit}
            isAccess={isAccess}
            userName={userName}
            handleSubmitConvet={() => console.log('delte')}
            idSalesOrder={Number(params)}
            loadingConvet={loadingConvet}
            getWareHouse={getWareHouse[0]}
            tempItemColly={tempItemColly}
            loadingReject={loadingReject}
            handleRejectSuperAdmin={handleRejectSuperAdmin}
            handleCancelRejectSuperAdmin={handleCancelRejectSuperAdmin}
            tempCollyFinalUpdate={tempCollyFinalUpdate}
            collyResource={collyResource}
            collyFinal={collyFinal}
            handleCloseSO={handleCloseSO}
            deleteColly={deleteColly}
            showComponent={showComponent}
            handleOpenImage={handleOpenImage}
            historyResource={historyResource}
            loadingHistoryResource={loadingHistoryResource}
            addItemAction={addItemAction}
            setOpenEdit={setOpenEdit}
            itemColly={itemColly}
            handleRejectItem={handleRejectItem}
            handleAcceptItem={handleAcceptItem}
            setHasOpenModal={setHasOpenModal}
            isLoadingData={isLoadingData}
            setGetIdClick={setGetIdClick}
            value={value}
            isChange={isChange}
            validationChecker={validationChecker}
            validationPicker={validationPicker}
            consistenMessage={consistenMessage}
            totalWarehouse={totalItemWareHouse}
            handleChange={handleChange}
            handleConfirm={handleConfirm}
            handleReject={handleReject}
            handleReset={resetAction}
            handleOpenConfirmationDelete={handleOpenConfirmationDelete}
            download={downloadPdf}
            isValid={isValid}
            hasInvoice={hasInvoice}
            isChangeSubmit={isChangeSubmit}
            isConvertAllowed={isConvertAllowed}
            currentWareHouse={currentWareHouse}
            anchorEl={anchorEl}
            buttonGroup={buttonGroup}
            currentCheckWarehouse={currentCheckWarehouse}
            totalApprove={currentItem.length}
            setCurrentCheckWarehouse={setCurrentCheckWarehouse}
            indexTooltip={indexTooltip}
            rejectOrder={rejectOrder}
            openTooltip={openTooltip}
            handleOpenTooltip={handleOpenTooltip}
            handleCloseTooltip={handleCloseTooltip}
            openAlertPrice={openAlertPrice}
            indexAlertPrice={indexAlertPrice}
            handleOpenAlertPrice={handleOpenAlertPrice}
            handleCloseAlertPrice={handleCloseAlertPrice}
            openAlertDiscount={openAlertDiscount}
            indexAlertDiscount={indexAlertDiscount}
            handleOpenAlertDiscount={handleOpenAlertDiscount}
            handleCloseAlertDiscount={handleCloseAlertDiscount}
          />
        </Grid>

        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
          onConfirm={handleCloseSnackbar}
          noCancelButton={true}
        />

        <StandardConfirmationDialog
          variant={'danger'}
          titleMessage={'Menghapus Data'}
          message={'Apakah kamu yakin akan menghapus data ini?'}
          open={confirmationDelete}
          handleClose={handleCloseConfirmationDelete}
          onConfirm={salesOrder.statusOrder !== 'CONFIRMATION' ? deleteSalesOrder : handleOpenConfirmation}
          isLoading={loadingDelete}
        />

        <FormPinDialog
          open={openConfirmation}
          pinMessage={pinMessage}
          isComplete={isComplete}
          setComplete={setComplete}
          setPin={setPin}
          handleClose={handleCloseConfirmation}
        />

        <ModalEdit
          isAccess={isAccess}
          openEdit={openEdit}
          handleSubmitColly={handleSubmitColly}
          getIdClick={getIdClick}
          idPartner={salesOrder.PartnerId}
          deleteSalesOrderItem={deleteSalesOrderItem}
          collyResource={collyResource}
          collyFinalUpdate={collyFinalUpdate}
          fetchData={fetchData}
          updateColly={updateColly}
          handleReset={resetAction}
          setItemColly={setItemColly}
          handletRequestEdit={handletRequestEdit}
          handleDeleteSO={handleDeleteSO}
          loadingPrint={loadingPrint}
          handleUpdateDiscount={handleUpdateDiscount}
          discountLoading={discountLoading}
          editQtyLoading={editQtyLoading}
          itemColly={itemColly}
          resetAction={() => resetAction()}
          handleSubmitConvet={() => console.log('delte')}
          deleteColly={deleteColly}
          loadingConvet={loadingConvet}
          tempCollyFinal={tempCollyFinal}
          handleStatusOnSubmit={handleStatusOnSubmit}
          showComponent={showComponent}
          getWareHouseID={getWareHouse}
          salesOrder={salesOrder}
          loadingDataSave={loadingDataSave}
          salesOrderItem={salesOrderItem}
          rejectOrder={rejectOrder}
          hasOpenModal={hasOpenModal}
          addItemAction={addItemAction}
          handleRejectItem={handleRejectItem}
          setOpenEdit={setOpenEdit}
          setHasOpenModal={setHasOpenModal}
        />

        {showComponent && (
          <React.Fragment>
            <div style={{ height: '8vh' }}></div>
            <Grid className={classes.buttonContainerMobile}>
              {isAccess === TypeUser.ADMIN01 && (
                <Button
                  color='primary'
                  style={{ borderRadius: '20px' }}
                  fullWidth
                  disabled={rejectOrder.length < 1 || rejectOrder.length !== validationChecker}
                  onClick={() => {
                    setOpenEdit(true);
                    setHasOpenModal(3);
                  }}
                >
                  konfirmasi packing
                </Button>
              )}

              {isAccess === TypeUser.PICKER && (
                <Button
                  color='primary'
                  style={{ borderRadius: '20px' }}
                  fullWidth
                  disabled={rejectOrder.length < 1 || rejectOrder.length !== validationPicker}
                  onClick={() => {
                    setOpenEdit(true);
                    setHasOpenModal(3);
                  }}
                >
                  Konfirmasi Stok
                </Button>
              )}
            </Grid>
          </React.Fragment>
        )}
      </Container>
    </Page>
  );
};

export default SalesOrderDetailPage;
