import React, { FC } from 'react';
import NumberFormat from 'react-number-format';
import { Grid, Typography, makeStyles } from '@material-ui/core';

interface Props {
  id: number;
  paymentNumber: string;
  totalPay: number;
}

const useStyles = makeStyles({
  textLink: {
    textDecorationLine: 'underline',
    cursor: 'pointer'
  }
});

const PaymentList: FC<Props> = props => {
  const classes = useStyles();
  const { id, paymentNumber, totalPay } = props;

  return (
    <Grid container item lg={12} md={12} sm={12} xs={12}>
      <Grid container item lg={12} sm={12} md={12} xs={12}>
        <Grid item lg={6} sm={12} md={6} xs={12}>
          <Typography variant='h6' align='right' className={classes.textLink} onClick={() => window.open(`/pembayaran-penjualan/${id}`)}>
            {paymentNumber}
          </Typography>
        </Grid>
        <Grid item lg={6} sm={12} md={6} xs={12}>
          <Typography variant='body1' align='right'>
            <NumberFormat value={totalPay} prefix={'(Rp'} suffix={')'} displayType='text' thousandSeparator={true} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentList;
