import React, { FC, useState, useEffect } from 'react';
import { TableContainer, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import { HeaderRow, TableCustom } from 'components/Table';
import BodyRow from './components/BodyRow';

interface Props {
  isLoadingData: boolean;
  wareHouses: WareHouseModel[];
  queryString?: string;
  order: 'asc' | 'desc';
  orderBy: string;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  handleConfirmationDelete: (id: number) => React.MouseEventHandler;
  handleOnUpdate: (id: number) => React.MouseEventHandler;
}

const dummyWarehouse: WareHouseModel = {
  id: 0,
  name: '',
  address: '',
  description: ''
};

const WareHouseTable: FC<Props> = props => {
  const { isLoadingData, wareHouses, order, orderBy, setOrder, setOrderBy, queryString, handleConfirmationDelete, handleOnUpdate } = props;
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    if (isLoadingData) {
      setShowSkeleton(true);
    }
    return () => {
      setShowSkeleton(false);
    };
  }, [isLoadingData]);

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={[
              { id: 'name', label: 'Nama Gudang', sort: true },
              { id: 'address', label: 'Alamat', sort: true },
              { label: 'Deskripsi' },
              { label: '' }
            ]}
          />
        </TableHead>
        <TableBody>
          {showSkeleton ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow
                key={index + 1}
                isLoading={showSkeleton}
                wareHouse={dummyWarehouse}
                onDelete={handleConfirmationDelete(0)}
                onUpdate={handleOnUpdate(0)}
              />
            ))
          ) : wareHouses && wareHouses.length > 0 ? (
            wareHouses.map((value, index) => (
              <BodyRow
                key={index + 1}
                isLoading={showSkeleton}
                wareHouse={value}
                onDelete={handleConfirmationDelete(value.id)}
                onUpdate={handleOnUpdate(index)}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align='center'>
                {queryString ? 'No matching result' : 'Data Tidak Tersedia.'}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default WareHouseTable;
