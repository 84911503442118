import React, { FC } from 'react';
import { Grid, Typography, Divider, Table, TableContainer, TableBody, TableRow, TableCell, makeStyles } from '@material-ui/core';
import { PaperCustom } from 'components';
import { format } from 'date-fns';
import { terbilang } from 'utils';
import NumberFormat from 'react-number-format';
import Skeleton from '@material-ui/lab/Skeleton';
import { BLUE_PRIMARY, BLACK } from 'constants/colors';
import clx from 'clsx';
import useRouter from 'hooks/useRouter';

interface Props {
  invoicePayment: PurchaseInvoicePaymentModel;
  invoicePiece: InvoicePieceModel[];
  company: CompanyModel;
  invoiceReturn?: InvoiceReturnModel[];
  isLoadingData: boolean;
}

const useStyles = makeStyles({
  leftCell: {
    border: 'none',
    width: '165px'
  },
  cellOrder: {
    marginBottom: 1,
    padding: '2px 0px 2px 2px',
    border: 'none'
  },
  cellOrderRightUnderline: {
    padding: '2px 0px 2px 2px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal',
    textDecorationLine: 'underline',
    cursor: 'pointer'
  },

  note: {
    paddingLeft: 53,
    marginLeft: 53,
    display: 'inline-flex',
    color: BLACK
  },
  noteTypo: {
    paddingLeft: 80,
    marginLeft: 80,
    display: 'inline-flex',
    color: BLACK
  },
  number: {
    color: BLACK
  },
  total: {
    paddingLeft: 25,
    marginLeft: 25
  },
  totalItem: {
    paddingRight: 26,
    marginRight: 26
  },
  totalPrice: {
    paddingRight: 9,
    marginRight: 9
  },
  contentPayment: {
    fontWeight: 500
  },
  text: {
    paddingTop: 20,
    marginTop: 20
  },
  textReturn: {
    paddingTop: 10,
    marginTop: 10
  }
});

const Content: FC<Props> = props => {
  const { invoicePayment, isLoadingData, company, invoiceReturn, invoicePiece } = props;
  const classes = useStyles();
  const { history } = useRouter();

  const handleLink = (id: Number) => {
    history.push(`/invoice-pembelian/detail`, { id });
  };

  return (
    <PaperCustom>
      <Grid item container direction='row' spacing={2} justify='space-between' lg={12} sm={12} md={12} xs={12}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography>No. Kwitansi</Typography>
          <Typography variant='h5'>
            {isLoadingData ? <Skeleton variant='text' width={'100%'} height={25} /> : invoicePayment.paymentNumber}
          </Typography>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography variant='h5' align='right'>
            Kwitansi
          </Typography>
          <Typography align='right'>
            No. Invoice Pembelian Rujukan
            {isLoadingData ? (
              <Typography className={clx(classes.cellOrder, classes.cellOrderRightUnderline)}>
                <Skeleton variant='text' width='100%' />
              </Typography>
            ) : (
              invoicePayment.PurchaseInvoicePaymentMultiple.map((val, key) => (
                <Grid xs={12} container key={key} alignItems='center' justify='flex-end'>
                  <Grid xs={5}>
                    <Typography className={clx(classes.cellOrder, classes.cellOrderRightUnderline)} onClick={() => handleLink(val.PurchaseInvoiceId)}>
                      {isLoadingData ? <Skeleton variant='text' width='50%' /> : val.invoiceNumber || '-'}
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography align='right'>
                      {isLoadingData ? (
                        <Skeleton variant='text' width='50%' />
                      ) : (
                        <NumberFormat value={val.totalPay} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              ))
            )}
          </Typography>
        </Grid>
      </Grid>

      <Divider />

      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.leftCell}>Sudah Terima Dari</TableCell>
              <TableCell colSpan={2} className={classes.contentPayment}>
                {isLoadingData ? <Skeleton variant='text' width={'100%'} height={25} /> : invoicePayment.partnerName || '-'}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.leftCell}>Terbilang</TableCell>
              <TableCell colSpan={2} className={classes.contentPayment}>
                {isLoadingData ? <Skeleton variant='text' width={'100%'} height={25} /> : terbilang(invoicePayment.totalPay) + ' Rupiah'}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.leftCell}>Catatan</TableCell>
              <TableCell colSpan={2} className={classes.contentPayment}>
                {isLoadingData ? <Skeleton variant='text' width={'100%'} height={25} /> : invoicePayment.note}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {invoicePayment.paymentMethod && invoicePayment.useReturn && (
        <Grid>
          <Typography className={classes.text} variant='h5' style={{ color: BLUE_PRIMARY, fontSize: '20px' }}>
            RETURN
          </Typography>

          <Divider />

          <Grid item container direction='row' className={classes.textReturn} spacing={1} justify='space-between' lg={12} sm={12} md={12} xs={12}>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Typography variant='h6'>Nomor Return</Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Typography variant='h6' align='right'>
                  Jumlah Barang
                </Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Typography variant='h6' align='right'>
                  Total Return
                </Typography>
              </Grid>
            </Grid>

            {invoiceReturn &&
              invoiceReturn.length > 0 &&
              invoiceReturn.map((value: InvoiceReturnModel, index) => (
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                  <Grid item lg={3} md={3} sm={12} xs={12}>
                    <Typography
                      style={{ cursor: 'pointer' }}
                      variant='subtitle1'
                      color='primary'
                      onClick={() => history.push('/return-penjualan/detail', { id: value.id })}
                    >
                      {isLoadingData ? <Skeleton variant='text' width={'100%'} height={25} /> : value.returnNumber}
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={3} sm={12} xs={12}>
                    <Typography className={classes.totalItem} variant='subtitle1' align='right'>
                      {isLoadingData ? <Skeleton variant='text' width={'100%'} height={25} /> : value.totalItem}
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={3} sm={12} xs={12}>
                    <Typography className={classes.totalPrice} variant='subtitle1' align='right'>
                      {isLoadingData ? (
                        <Skeleton variant='text' width={'100%'} height={25} />
                      ) : (
                        <NumberFormat value={value.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            <Grid item lg={3} md={3} sm={12} xs={12}></Grid>
          </Grid>
        </Grid>
      )}

      <Grid>
        <Typography className={classes.text} variant='h5' style={{ fontSize: '20px' }}>
          POTONGAN LAIN
        </Typography>

        <Divider />

        <Grid item container direction='row' className={classes.textReturn} spacing={2} justify='space-between' lg={12} sm={12} md={12} xs={12}>
          <Grid item container lg={3} md={3} sm={12} xs={12}>
            <Grid xs={12}>
              <Typography variant='h6'>Jenis Potongan</Typography>
            </Grid>

            {invoicePiece.length > 0 ? (
              invoicePiece.map((value: InvoicePieceModel, index) => (
                <Grid item xs={12}>
                  {isLoadingData ? (
                    <Skeleton variant='text' width={'100%'} height={25} />
                  ) : value.type === 'SHIPPING' ? (
                    <Typography>Ongkos Kirim</Typography>
                  ) : value.type === 'OTHER' ? (
                    <Typography>Lainnya</Typography>
                  ) : (
                    <Typography>-</Typography>
                  )}
                </Grid>
              ))
            ) : (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                -
              </Grid>
            )}
          </Grid>

          <Grid item container justify='flex-end' lg={3} md={3} sm={12} xs={12}>
            <Grid xs={12}>
              <Typography variant='h6' align='right'>
                Total Potongan
              </Typography>
            </Grid>

            {invoicePiece.length > 0 ? (
              invoicePiece.map((value: InvoicePieceModel, index) => (
                <Grid item>
                  <NumberFormat className={classes.total} value={value.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </Grid>
              ))
            ) : (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                -
              </Grid>
            )}
          </Grid>

          <Grid justify='flex-end' item lg={6} md={6} sm={12} xs={12}>
            <Typography className={classes.noteTypo} variant='h6' align='right'>
              Catatan
            </Typography>

            {invoicePiece.length > 0 ? (
              invoicePiece.map((value: InvoicePieceModel, index) => (
                <Grid item>
                  <Typography style={{ width: '100%', flexDirection: 'column' }} className={classes.note} variant='body2'>
                    {value.note || '-'}
                  </Typography>{' '}
                </Grid>
              ))
            ) : (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                -
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item container className={classes.text} direction='row' spacing={2} justify='space-between' lg={12} sm={12} md={12} xs={12}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Typography variant='h6'>Jumlah</Typography>
        </Grid>

        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Typography variant='h5'>
            {<NumberFormat value={invoicePayment.totalPay} prefix={'Rp'} thousandSeparator={true} displayType='text' />}
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Typography variant='subtitle1' align='center'>
            {format(new Date(invoicePayment.payDate), 'dd MMMM yyyy').toString()}
          </Typography>
          <Typography align='center'>{company.name}</Typography>{' '}
        </Grid>
      </Grid>
    </PaperCustom>
  );
};

export default Content;
