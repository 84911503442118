import React, { FC, useEffect, useState, useContext } from 'react';
import { Grid, Container, Typography, makeStyles, withStyles, Button, Tooltip, Hidden, Theme, useTheme, Badge } from '@material-ui/core';
import { AddBox, Description } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import { Page, StandardConfirmationDialog, PaperCustom, Breadcrumb } from 'components';
import axios, { CancelTokenSource } from 'axios';
import ReturnOrderTable from './components/ReturnOrderTable';
import Pagination from '@material-ui/lab/Pagination';
import useRouter from 'hooks/useRouter';
import useRole from 'hooks/useRole';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { dummyInvoiceReturn } from 'utils/dummy';
import { RETURN_INVOICE_BASE_URL, RETURN_DELETE_INVOICE_BASE_URL } from 'constants/url';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { GREEN, PRIMARY_MOBILE, WHITE, BLUE_PRIMARY, YELLOW_MOBILE } from 'constants/colors';
import RefreshIcon from '@material-ui/icons/Refresh';
import { format, startOfMonth } from 'date-fns';
import DateRangeFilter from 'components/DateRangeFilter';
import CalendarIcon from '@material-ui/icons/EventNote';
import useDebounced from 'hooks/useDebounced';
import PaginationCustom from 'components/PaginationCustom';
import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom';
// import { withStyles, Theme, makeStyles } from '@material-ui/core/styles';
// import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const useStyles = makeStyles((theme: Theme) => ({
  calendarIcon: {
    fontSize: 30,
    color: theme.palette.primary.main
  },
  spaceTop: {
    paddingBottom: 30
  },
  fourthGrid: {
    marginTop: 20
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  },
  refreshButton: {
    backgroundColor: GREEN,
    color: WHITE,
    padding: '.4em',
    borderRadius: '5px',
    transition: 'all .3s',
    '&:hover': {
      transform: 'scale(1.2)',
      backgroundColor: '#19A48F'
    }
  },
  wrapperHeader: {
    columnGap: '1em'
  },
  containerSelected: {
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxShadow: ' 0px 4px 24px rgba(50, 50, 50, 0.16)',
    borderRadius: '5px',
    marginTop: '0.5em'
  },

  wrapperInput: {
    backgroundColor: WHITE
  },

  boxShadow: {
    boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 3px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
    borderRadius: '.4em'
  },
  statisticContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '1.5em'
  },

  listContainer: {
    padding: '1em',
    cursor: 'pointer',
    backgroundColor: '#ffffff10',
    transition: 'all .3s ease',
    '&:hover': {
      transform: 'translateY(-7px)',
      border: `2px solid ${PRIMARY_MOBILE}`
    }
  },
  listActive: {
    border: `2px solid ${PRIMARY_MOBILE}`
  },
  colorGreen: {
    color: GREEN
  },
  infoIcon: {
    cursor: 'pointer',
    fontSize: '1.7rem',
    color: YELLOW_MOBILE,
    transition: 'all .3s ease-in-out',
    '&:hover': {
      scale: 1.2
    }
  },
  cardContainer: {
    position: 'absolute',
    zIndex: 1,
    backgroundColor: '#9F9F9F',
    width: '160px',
    height: '83px',
    right: 0,
    top: 45,
    borderRadius: 5,
    padding: '15px'
  },
  infoIconAnimation: {
    animation: `$iconAlert 0.6s infinite`,
    color: YELLOW_MOBILE,
    transition: 'all .3s ease-in-out',
    '&:hover': {
      scale: 1.5
    }
  },
  '@keyframes iconAlert': {
    '0%': {
      transform: 'scale(1) rotate(50deg)'
    },

    '10%': {
      transform: 'scale(1.1) rotate(-50deg)'
    },
    '15%': {
      transform: 'scale(1.1) rotate(50deg)'
    },
    '20%': {
      transform: 'scale(1) rotate(-50deg)'
    },
    '25%': {
      transform: 'scale(1.1) rotate(50deg)'
    },
    '30%': {
      transform: 'scale(1) rotate(0deg)'
    }
  },
  cardChild: {
    position: 'absolute',
    backgroundColor: '#9F9F9F',
    width: '15px',
    height: '15px',
    right: 8,
    top: 40,
    transform: 'rotate(130deg)'
  },
  alertContent: {
    opacity: 0,
    position: 'absolute',
    boxShadow: `${YELLOW_MOBILE} 0px 1px 3px 0px, ${YELLOW_MOBILE} 0px 1px 3px 0px, ${YELLOW_MOBILE} 0px 1px 3px 0px`,
    background: '#fff4e5',
    padding: '1em',
    borderRadius: '.4em',
    bottom: 200,
    transition: 'all .5s cubic-bezier(.68, -0.55, .265, 1.55)',
    left: 220,
    '&::before': {
      content: '""',
      position: 'absolute',
      borderBottom: `1px solid ${YELLOW_MOBILE}`,
      borderLeft: `1px solid ${YELLOW_MOBILE}`,
      background: '#fff4e5',
      height: '1em',
      width: '1em',
      left: 0,
      bottom: 8,
      transform: 'translateX(-50%) rotate(45deg)'
    },
    '& > *': {
      color: '#663c00'
    }
  },
  showAlertContent: {
    opacity: 1,
    bottom: 40
  },
  containerNotification: {
    position: 'relative',
    display: 'grid',
    placeItems: 'center'
  },
  animationNotif: {
    animation: `$buttonNotifAnimation 2s infinite`
  },
  tooltip: {
    backgroundColor: '#fff4e5', // Mengatur latar belakang menjadi putih
    color: 'black', // Opsional, untuk mengatur warna teks agar kontras dengan latar belakang
    border: '1px solid #ccc',
    opacity: 1,
    marginTop: 50,
    bottom: 40 // Opsional, untuk memberi sedikit border agar terlihat jelas
  }
}));

const ReturnOrderPage: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { history, location } = useRouter();
  const { currentUser } = useContext(CurrentUserContext);
  const isXs = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  const [count, setCount] = useState<number>(0);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [returnOrders, setReturnOrders] = useState<InvoiceReturnModel[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [selectedId, setSelectedId] = useState<number>(0);
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [name, setName] = useState<string>('');

  const [salesName, setSalesName] = useState<string>('');
  const [inProgress, setInProgress] = useState<'0' | 'inProgress' | 'needConvert'>('0');
  const [returnOrderId, setReturnOrderId] = useState<string>('');
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);
  const [loadingItem, setLoadingItem] = useState<boolean>(false);
  const [from, setFrom] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const typeReturn = location.pathname === '/return-penjualan' ? 'CUSTOMER' : 'SUPPLIER';
  const [notif, setNotif] = useState<number>(0);
  const [authorizaion, setAuthorizaion] = useState<boolean>(false);
  const [isShown, setIsShown] = useState<boolean>(false);

  const cancelToken: CancelTokenSource = axios.CancelToken.source();

  const isSuperVisor = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: ['SUPERVISOR']
  });

  const isSales = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: ['SALES']
  });

  const fetchData = async () => {
    setIsLoadingData(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (name) {
        params.append('keyword', name);
      }

      if (returnOrderId) {
        params.append('number', returnOrderId);
      }

      if (currentUser && isSuperVisor) {
        params.append('Zone', (currentUser.SalesRoute && currentUser.SalesRoute.map(value => value.ZoneId).join(',')) || '');
      }

      if (currentUser && isSales) {
        params.append('salesId', currentUser.id.toString());
      }

      if (inProgress === 'inProgress') {
        params.append('inProgress', '1');
      }

      if (inProgress === 'needConvert') {
        params.append('needConvert', '1');
      }

      params.append('typeReturn', typeReturn);
      params.append('startDate', startDate);
      params.append('endDate', endDate);
      params.append('orderBy', orderBy);
      params.append('ordered', order);
      params.append('withProcess', '1');

      params.append('page', currentPage.toString());
      return params.toString();
    };

    try {
      const { data } = await axios.get(`${RETURN_INVOICE_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelToken.token });
      const notif = await axios.get(`${RETURN_INVOICE_BASE_URL}/notif`);

      setNotif(notif.data);
      setReturnOrders(data.data);
      setCount(data.meta.last_page);
      setCurrentPage(data.meta.current_page);
      setTotal(data.meta.total);
      setTo(data.meta.to);
      setFrom(data.meta.from);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleConfirmationDelete = (id: number): React.MouseEventHandler => () => {
    setSelectedId(id);
    setConfirmationDelete(true);
  };

  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  const handleOpenAuthorization = () => {
    setAuthorizaion(true);
  };

  const handleCloseAuthorization = () => {
    setAuthorizaion(false);
  };

  const deleteReturnOrder = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      setLoadingDelete(false);
      await axios.delete(RETURN_DELETE_INVOICE_BASE_URL(selectedId));
      setReturnOrders(returnOrders.filter(value => value.id !== selectedId));
      setConfirmationDelete(false);
      handleSnackBar(true, 'success', 'Return barang berhasil dihapus.');
    } catch (err) {
      console.log(err);
      handleSnackBar(true, 'error', 'Return barang gagal dihapus.');
    } finally {
      setLoadingDelete(false);
    }
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleCreateReturnOrder = () => {
    if (typeReturn === 'CUSTOMER') {
      history.push('/return-penjualan/penjualan');
    } else {
      history.push('/return-pembelian/pembelian');
    }
  };

  const getData = async (id: number) => {
    setLoadingItem(true);
    try {
      const { data } = await axios.get(`${RETURN_INVOICE_BASE_URL}/${id}/items`);
      setReturnOrders(prevState =>
        prevState.map(value => {
          if (value.id === id) {
            value.InvoiceReturnItem = data.data;
          }
          return value;
        })
      );
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoadingItem(false);
    }
  };

  const handleOpenCollapse = (index: number, id: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    setOpenCollapse(openCollapse ? (index === indexCollapse ? false : true) : true);
    getData(id);
  };

  const handleDateRangeClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenCalendarFilter(!openCalendarFilter);
  };

  const debouncedFetchHits = useDebounced(fetchData, 500);

  useEffect(() => {
    debouncedFetchHits();
    return () => cancelToken.cancel('No longer latest query');
  }, [orderBy, order, currentPage, name, returnOrderId, name, startDate, endDate, isSales, isSuperVisor, typeReturn, inProgress]);

  return (
    <Page title={'Return ' + (typeReturn === 'CUSTOMER' ? 'Penjualan' : 'Pembelian')}>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <>
              <Typography variant='h1' component='h1'>
                Return {typeReturn === 'CUSTOMER' ? 'Penjualan' : 'Pembelian'}
                {typeReturn === 'CUSTOMER' && (
                  <Tooltip
                    title={
                      <React.Fragment>
                        <Typography variant='h6'>Informasi Bulan Sebelumnya:</Typography>
                        <Typography variant='h6'>
                          {' '}
                          Return Penjualan {notif} (
                          {new Intl.DateTimeFormat('id-ID', { month: 'long' }).format(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000))}{' '}
                          {new Intl.DateTimeFormat('id-ID', { year: 'numeric' }).format(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000))}){' '}
                        </Typography>
                      </React.Fragment>
                    }
                    classes={{ tooltip: classes.tooltip }}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    arrow
                  >
                    <InfoIcon className={classes.infoIconAnimation} />
                  </Tooltip>
                )}
              </Typography>
            </>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid container direction='row' alignItems='center' spacing={2}>
            <Hidden only={['xs', 'sm']}>
              <Grid item xl={1} lg={1} md={1} sm={3} xs={3}>
                <Tooltip title='Refresh'>
                  <Button onClick={() => fetchData()} color='inherit' className={classes.refresh}>
                    <RefreshIcon />
                  </Button>
                </Tooltip>
              </Grid>
            </Hidden>

            <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
              <Typography>{`Menampilkan ${total || 0} return (${from || 0} - ${to || 0} dari ${total || 0})`}</Typography>
              {startDate && endDate && (
                <Typography variant='body2' style={{ fontSize: 12, color: BLUE_PRIMARY }}>
                  Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                </Typography>
              )}
            </Grid>

            <Grid item container justify={isXs ? 'space-between' : 'flex-end'} alignItems='center' spacing={1} xl={6} lg={6} md={6} sm={12} xs={12}>
              <Hidden only={['xl', 'lg', 'md']}>
                <Grid item xl={2} lg={2} md={2} sm={3} xs={2}>
                  <Tooltip title='Refresh'>
                    <Button onClick={() => fetchData()} color='inherit' className={classes.refresh}>
                      <RefreshIcon />
                    </Button>
                  </Tooltip>
                </Grid>
              </Hidden>

              <Grid item xl={2} lg={2} md={2} sm={3} xs={2}>
                <Tooltip title='Calendar filter' placement='top'>
                  <Button color='inherit' onClick={event => handleDateRangeClick(event)}>
                    <CalendarIcon />
                  </Button>
                </Tooltip>
              </Grid>

              <Grid item sm={6} xs={6}>
                <Button fullWidth={isXs} onClick={handleCreateReturnOrder} disabled={isLoadingData}>
                  <AddBox fontSize='small' /> &nbsp; Return {(!isXs && (typeReturn === 'CUSTOMER' ? ' Penjualan' : ' Pembelian')) || ''}
                </Button>
              </Grid>
            </Grid>

            <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
              <ReturnOrderTable
                returnOrderId={returnOrderId}
                inProgress={inProgress}
                setReturnOrderId={setReturnOrderId}
                name={name}
                setName={setName}
                salesName={salesName}
                setSalesName={setSalesName}
                isLoadingData={isLoadingData}
                returnOrders={returnOrders}
                order={order}
                openCollapse={openCollapse}
                indexCollapse={indexCollapse}
                loadingItem={loadingItem}
                handleOpenCollapse={handleOpenCollapse}
                orderBy={orderBy}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                setInProgress={setInProgress}
                typeReturn={typeReturn}
                handleConfirmationDelete={handleConfirmationDelete}
              />
            </Grid>

            <Grid container justify='flex-end' item xl={12} md={12} sm={12} className={classes.fourthGrid}>
              <Grid item>
                <PaginationCustom
                  marginTop='-.4em '
                  show={returnOrders.length > 0}
                  sxPagination={{
                    count,
                    boundaryCount: 2,
                    variant: 'outlined',
                    shape: 'rounded',
                    onChange: (event, page) => {
                      setCurrentPage(page);
                    },
                    page: currentPage
                  }}
                  sxPopover={{
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'right'
                    },
                    transformOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right'
                    }
                  }}
                  customPageProps={{
                    defaultValue: currentPage,
                    maxValue: count,
                    onSubmit(value) {
                      setCurrentPage(value);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </PaperCustom>
        <DateRangeFilter
          openCalendarFilter={openCalendarFilter}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleClose={() => {
            setOpenCalendarFilter(false);
          }}
        />
        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
          onConfirm={handleCloseSnackbar}
          noCancelButton={true}
        />

        <StandardConfirmationDialog
          variant={'danger'}
          titleMessage={'Delete'}
          message={'Apakah kamu yakin menghapus data ini?'}
          open={confirmationDelete}
          handleClose={handleCloseConfirmationDelete}
          onConfirm={deleteReturnOrder}
          isLoading={loadingDelete}
        />
      </Container>
    </Page>
  );
};

export default ReturnOrderPage;
