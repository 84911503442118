import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { CheckCircleOutline, ExpandLess, ExpandMore, HourglassEmpty, Opacity } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { GREEN_MOBILE, GREY, PRIMARY_MOBILE, RED_MOBILE, WHITE, YELLOW_MOBILE } from 'constants/colors';
import React, { Fragment, useState } from 'react';
import NumberFormat from 'react-number-format';
import SalesOrderStatus from 'typings/enum/SalesOrderStatus';
import TypeUser from 'typings/enum/TypeUser';
const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    marginBottom: theme.spacing(2),
    borderRadius: '6px',
    boxShadow: '0px 2px 2px -1px rgb(0 0 0 / 24%)'
  },
  containerTop: {
    padding: '1.2em .6em',
    borderRadius: '6px'
  },

  wrapperItem: {
    padding: '.6em',
    display: 'none',
    borderTop: `1px solid ${GREY}`
  },
  wrapperItemShow: {
    display: 'flex'
  },

  containerTopSkelaton: {
    padding: '.8em .6em',
    borderRadius: '6px'
  },
  icon: {
    transition: 'all .3s ease-in-out'
  },
  iconRotate: {
    rotate: '180deg'
  },

  link: {
    color: PRIMARY_MOBILE
  },
  textContentMobile: {
    fontSize: '0.78rem',
    fontWeight: 400
  },
  textContentBoldMobile: {
    fontSize: '0.78rem',
    fontWeight: 500
  },

  statsContainerMobile: {
    borderRadius: '20px',
    display: 'grid',
    padding: '5px 7px',
    placeItems: 'center'
  },
  containerBottom: {
    maxHeight: '0px',
    opacity: 0,
    transition: 'all .3s ease-in-out'
  },
  containerBottomShow: {
    opacity: 1,
    display: 'flex',
    maxHeight: '600px'
  },
  containerStatus: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.3rem',
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  borderWarning: {
    border: `0.6px solid #27AE60`
  },
  border: {
    border: '0.6px solid rgba(0, 0, 0, 0.08)'
  },
  borderGrey: {
    border: `0.6px solid #b5b5b5`
  },
  backgroundColorGreen: {
    backgroundColor: `#F1FFF8`
  },
  backgroundColorGrey: {
    backgroundColor: 'rgb(230 230 230)'
  }
}));

interface Props {
  id: number;
  noOrder: string;
  customerName: string;
  dateOrder: string;
  statsOrder: string;
  handleExpandMobile: () => void;
  isLoading: boolean;
  isOpen?: boolean;
  isDone: boolean | undefined;
  idClick: number;
  loadingItem: boolean;
  totalItem?: number;
  totalQty?: number;
  salesName?: string;
  isAccess: string;
  isHasInvoice: boolean | undefined;
  nominalSO?: number;
  rute?: string;
  price?: string;
  progress?: { name: string; status: string }[];
  qtyStuck?: number;
  qty?: string;
  discount?: number;
  totalPrice?: number;
  onClick: () => void;
}
const CardMobile: React.FC<Props> = props => {
  const {
    id,
    noOrder,
    customerName,
    dateOrder,
    statsOrder,
    handleExpandMobile,
    idClick,
    isLoading,
    rute,
    onClick,
    salesName,
    isHasInvoice,
    isAccess,
    loadingItem,
    totalItem,
    progress,
    isDone,
    isOpen,
    discount,
    totalQty,
    nominalSO,
    totalPrice
  } = props;
  const classes = useStyles();
  const [isExpandLess, setisExpandLess] = useState<boolean>(false);

  const handleStatus = () => {
    if (statsOrder === 'CONFIRMATION') {
      return 'Konfirmasi';
    } else if (statsOrder === 'PENDING') {
      return 'Tertunda';
    } else return 'Batal';
  };

  return (
    <Grid
      xs={12}
      container
      direction='row'
      className={clsx(classes.cardContainer, isDone ? classes.borderWarning : classes.border, isOpen && classes.borderGrey)}
      key={id}
    >
      {isLoading ? (
        <Grid xs={12} className={classes.containerTopSkelaton}>
          <Skeleton variant='text' width='100%' height={30} />
          <Skeleton variant='text' width='100%' height={30} />
        </Grid>
      ) : (
        <>
          <Grid
            xs={12}
            className={clsx(classes.containerTop, isDone && classes.backgroundColorGreen, isOpen && classes.backgroundColorGrey)}
            container
            alignContent='center'
          >
            <Grid item xs={1} container alignContent='center' justify='flex-start'>
              <ExpandMore
                className={clsx(classes.icon, isExpandLess && id === idClick && classes.iconRotate)}
                onClick={() => {
                  setisExpandLess(id === idClick ? (isExpandLess ? false : true) : true);
                  handleExpandMobile();
                }}
              />
            </Grid>
            <Grid xs={11} item container direction='row' onClick={onClick} alignItems='center'>
              <Grid item xs={5}>
                <Typography className={clsx(classes.textContentMobile, classes.link)}>#{noOrder}</Typography>
                <Typography className={clsx(classes.textContentBoldMobile)} noWrap>
                  {customerName}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={clsx(classes.textContentMobile)} align='center'>
                  {dateOrder}
                </Typography>
                <Typography className={clsx(classes.textContentBoldMobile)} align='center'>
                  {rute}
                </Typography>
              </Grid>
              <Grid item xs={3} className={classes.containerStatus} container alignItems='center'>
                <Grid
                  item
                  className={classes.statsContainerMobile}
                  style={{
                    backgroundColor: statsOrder === 'PENDING' ? YELLOW_MOBILE : statsOrder === 'CONFIRMATION' ? GREEN_MOBILE : RED_MOBILE
                  }}
                >
                  <Typography align='center' className={classes.textContentBoldMobile} style={{ color: WHITE }}>
                    {handleStatus()}
                  </Typography>
                </Grid>

                {isHasInvoice && (
                  <Grid>
                    <Typography align='center' className={classes.textContentMobile} style={{ color: YELLOW_MOBILE }}>
                      Buat Invoice
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            xs={12}
            className={clsx(classes.containerBottom, isExpandLess && id === idClick && classes.containerBottomShow)}
            container
            justify='flex-end'
          >
            <Grid xs={11} container className={clsx(classes.wrapperItem, isExpandLess && id === idClick && classes.wrapperItemShow)}>
              {loadingItem ? (
                <>
                  <Skeleton variant='text' width='100%' height={30} />
                  <Skeleton variant='text' width='100%' height={30} />
                </>
              ) : isAccess === TypeUser.SALES || isAccess === TypeUser.SUPERVISOR ? (
                <Fragment>
                  <Grid xs={6}>
                    <Typography color='textSecondary' className={classes.textContentMobile}>
                      Nominal SO
                    </Typography>

                    {isAccess === TypeUser.SUPERVISOR && (
                      <Typography color='textSecondary' className={classes.textContentMobile}>
                        Nama Sales
                      </Typography>
                    )}
                  </Grid>
                  <Grid xs={6} container direction='column' alignItems='flex-end'>
                    <Typography className={classes.textContentBoldMobile}>
                      <NumberFormat value={nominalSO} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </Typography>

                    {isAccess === TypeUser.SUPERVISOR && <Typography className={classes.textContentBoldMobile}>{salesName}</Typography>}
                  </Grid>
                </Fragment>
              ) : isAccess === TypeUser.ADMIN03 ? (
                <Fragment>
                  <Grid xs={6}>
                    <Typography color='textSecondary' className={classes.textContentMobile}>
                      Total Produk
                    </Typography>
                    <Typography color='textSecondary' className={classes.textContentMobile}>
                      Total Kuantitas
                    </Typography>
                    <Typography color='textSecondary' className={classes.textContentMobile}>
                      Total Diskon
                    </Typography>

                    <Typography color='textSecondary' className={classes.textContentMobile}>
                      Total Harga
                    </Typography>
                  </Grid>
                  <Grid xs={6} container direction='column' alignItems='flex-end'>
                    <Typography className={classes.textContentBoldMobile}>{totalItem}</Typography>
                    <Typography className={classes.textContentBoldMobile}>{totalQty}</Typography>
                    <Typography className={classes.textContentBoldMobile} style={{ color: discount && discount > 0 ? YELLOW_MOBILE : '' }}>
                      {discount && discount > 0 ? `Rp ${discount}` : '-'}
                    </Typography>
                    <Typography className={classes.textContentBoldMobile}>Rp{totalPrice}</Typography>
                  </Grid>
                </Fragment>
              ) : isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS ? (
                <Fragment>
                  <Grid xs={6}>
                    <Typography color='textSecondary' className={classes.textContentMobile}>
                      Item Produk
                    </Typography>
                    <Typography color='textSecondary' className={classes.textContentMobile}>
                      Qty Pengepakan
                    </Typography>
                  </Grid>
                  <Grid xs={6} container direction='column' alignItems='flex-end'>
                    <Typography className={classes.textContentBoldMobile}>{totalItem}</Typography>
                    <Typography className={classes.textContentBoldMobile}>{totalQty}</Typography>
                  </Grid>
                </Fragment>
              ) : isAccess === TypeUser.ADMIN01 ? (
                <Fragment>
                  <Grid xs={6}>
                    <Typography color='textSecondary' className={classes.textContentMobile}>
                      Item Produk
                    </Typography>
                    <Typography color='textSecondary' className={classes.textContentMobile}>
                      Qty Pengecekan
                    </Typography>
                  </Grid>
                  <Grid xs={6} container direction='column' alignItems='flex-end'>
                    <Typography className={classes.textContentBoldMobile}>{totalItem}</Typography>
                    <Typography className={classes.textContentBoldMobile}>{totalQty}</Typography>
                  </Grid>
                </Fragment>
              ) : (
                isAccess === TypeUser.PICKER && (
                  <>
                    <Grid xs={6}>
                      <Typography color='textSecondary' className={classes.textContentMobile}>
                        Total Final Produk
                      </Typography>
                    </Grid>
                    <Grid xs={6}>
                      <Typography align='right' className={classes.textContentBoldMobile}>
                        {totalItem} Produk
                      </Typography>
                    </Grid>
                    {progress &&
                      progress
                        .filter((value, index, self) => index === self.findIndex(t => t.name === value.name))
                        .map(val => (
                          <Fragment>
                            <Grid xs={6}>
                              <Typography color='textSecondary' className={classes.textContentMobile}>
                                Progress {val.name}
                              </Typography>
                            </Grid>
                            <Grid xs={6} container alignItems='flex-end'>
                              <Grid xs={12} container alignItems='center' justify='flex-end'>
                                {progress.filter(
                                  value =>
                                    value.name === val.name &&
                                    (value.status === SalesOrderStatus.CONFIRM ||
                                      value.status === SalesOrderStatus.CHECKING ||
                                      value.status === SalesOrderStatus.HAS_INVOICE ||
                                      value.status === SalesOrderStatus.PACKING ||
                                      value.status === SalesOrderStatus.PREVIEW ||
                                      value.status === SalesOrderStatus.REJECT)
                                ).length >= progress.filter(value => value.name === val.name).length ? (
                                  <CheckCircleOutline fontSize='small' style={{ color: GREEN_MOBILE }} />
                                ) : (
                                  <HourglassEmpty fontSize='small' style={{ color: YELLOW_MOBILE }} />
                                )}
                                <Typography className={classes.textContentBoldMobile}>
                                  {`${
                                    progress.filter(
                                      value =>
                                        value.name === val.name &&
                                        (value.status === SalesOrderStatus.CONFIRM ||
                                          value.status === SalesOrderStatus.HAS_INVOICE ||
                                          value.status === SalesOrderStatus.CHECKING ||
                                          value.status === SalesOrderStatus.PACKING ||
                                          value.status === SalesOrderStatus.PREVIEW)
                                    ).length
                                  }/${
                                    progress.filter(
                                      value => value.name === val.name && value.name === val.name && value.status !== SalesOrderStatus.REJECT
                                    ).length
                                  } `}
                                  Diselesaikan
                                </Typography>
                              </Grid>
                            </Grid>
                          </Fragment>
                        ))}
                    <Grid xs={12} container justify='space-between'>
                      <Typography color='textSecondary' className={classes.textContentMobile}>
                        Sales
                      </Typography>
                      <Typography className={classes.textContentBoldMobile}>{salesName}</Typography>
                    </Grid>
                  </>
                )
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default CardMobile;
