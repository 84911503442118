import React, { FC, useState, useEffect, lazy, Suspense, useCallback } from 'react';
import { Grid, makeStyles, Typography, Theme } from '@material-ui/core';
import axios from 'axios';
import { PaperCustom } from 'components';
import FlexBox from 'components/FlexBox';
import {
  PAYMENT_PURCHASE_HISTORY_BASE_URL,
  PAYMENT_PURCHASE_HISTORY_DETAIL,
  PAYMENT_PURCHASE_HISTORY_MONTH,
  SALES_HISTORY_BASE_URL
} from 'constants/url';
import useDebounce from 'hooks/useDebounce';
import { dummyMetaData } from 'utils/dummy';
import { PaymentHistoryHeader, PaymentHistoryFilters } from './components';
import PaginationCustom from 'components/PaginationCustom';
import { format } from 'date-fns';
import monthNames from 'utils/month';
import { GREY } from 'constants/colors';
const PaymentHistoryContent = lazy(() => import('./components/PaymentHistoryContent'));
export type TListExpand = {
  id: number | string;
  action: 'year' | 'month';
};

export type TFilters = {
  keyWord: string;
  productName: string;

  id: number;
  startDate: string;
  endDate: string;
};

export type TData<T> = {
  isLoading: boolean;
  data: T;
};

interface Props {
  partnerId: number;
}

/* Defining a type called TFilters and then creating a constant called dummyFilters that is of type
TFilters. */
const currentYear = new Date().getFullYear();
const startOfYear = new Date(currentYear, 0, 1);
const today = new Date();
export const dummyFilters: TFilters = {
  productName: '',
  keyWord: '',
  id: 0,
  startDate: format(startOfYear, 'yyyy-MM'),
  endDate: format(today, 'yyyy-MM')
};

const dummyData = {
  isLoading: true,
  data: []
};

const dummySummary: stockMovementSummary = {
  totalStockFirstStart: 0,
  totalStockDamageStart: 0,
  totalStockSalesStart: 0,
  totalStockEnd: 0,
  totalStockDamageEnd: 0,
  totalStockSalesEnd: 0
};

const useStyles = makeStyles((theme: Theme) => ({
  borderTop: {
    borderTop: `1px solid ${GREY}`
  },
  minHight: {
    minHeight: '25em'
  },
  logo: {
    width: '400px',
    height: '337px',
    marginTop: '2em'
  }
}));
const PaymentPurchaseHistoryTable: FC<Props> = props => {
  const partnerId = props.partnerId;
  const classes = useStyles();
  const [filters, setFilters] = useState<TFilters>(dummyFilters);
  const [productMonths, setProductMonths] = useState<TData<StockMovementProductMonth[]>>(dummyData);
  const [year, setYear] = useState<TData<PaymentHistoryYear[]>>(dummyData);
  const [month, setMonth] = useState<TData<PaymentHistoryMonth[]>>(dummyData);
  const [productMeta, setProductMeta] = useState<MetaData>(dummyMetaData);
  const [paymentHistory, setPaymentHistory] = useState<TData<InvoicePaymentModel[]>>(dummyData);
  const [invoiceId, setInvoiceId] = useState<string>('');
  const [stockMovements, setStockMovements] = useState<TData<SalesHistory[]>>(dummyData);
  const [stockMovementSummary, setStockMovementSummary] = useState<TData<stockMovementSummary>>({
    isLoading: dummyData.isLoading,
    data: dummySummary
  });

  const [productExpand, setProductExpand] = useState<{ id: number; isOpen: boolean }>({
    id: 0,
    isOpen: false
  });
  const [monthExpand, setMonthExpand] = useState<{ month: string; isOpen: boolean }>({
    month: '',
    isOpen: false
  });
  const [yearExpand, setYearExpand] = useState<{ year: string; isOpen: boolean }>({
    year: '',
    isOpen: false
  });
  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const currentYear = new Date().getFullYear();
  const startOfYear = new Date(currentYear, 0, 1);
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(startOfYear);
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
  const [formattedEndDate, setFormattedEndDate] = useState<string>(format(selectedEndDate, 'yyyy-MM'));
  const [formattedStartDate, setFormattedStartDate] = useState<string>(format(selectedStartDate, 'yyyy-MM'));
  const [loadProduct, setLoadProduct] = useState<boolean>(false);
  const [isShowSuggest, setIsShowSuggest] = React.useState(false);

  const fetchYear = async () => {
    setProductMonths(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();

    params.append('page', productMeta.current_page.toString());

    if (invoiceId) {
      params.append('paymentNumber', invoiceId);
    }

    if (partnerId !== 0) {
      params.append('partnerId', partnerId.toString());
    } else {
      setProductMonths(prev => ({
        ...prev,
        isLoading: false
      }));
      return;
    }

    try {
      const { data } = await axios.get(`${PAYMENT_PURCHASE_HISTORY_BASE_URL}?${params}`, {
        cancelToken
      });
      setYear(prev => ({
        isLoading: false,
        data: data.data
      }));
      setProductMeta(prev => ({ ...prev, ...data.meta }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMonth = async (year: string) => {
    setProductMonths(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();

    if (invoiceId) {
      params.append('paymentNumber', invoiceId);
    }
    if (year) {
      params.append('year', year);
    }

    if (partnerId !== 0) {
      params.append('partnerId', partnerId.toString());
    } else {
      setProductMonths(prev => ({
        ...prev,
        isLoading: false
      }));
      return;
    }

    try {
      const { data } = await axios.get(`${PAYMENT_PURCHASE_HISTORY_MONTH}?${params}`, {
        cancelToken
      });
      setMonth(prev => ({
        isLoading: false,
        data: data.data
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDetail = async (date: string) => {
    setStockMovements(prev => ({ ...prev, isLoading: true }));

    const params = new URLSearchParams();

    params.append('date', date);
    if (invoiceId) {
      params.append('paymentNumber', invoiceId);
    }
    if (partnerId !== 0) {
      params.append('partnerId', partnerId.toString());
    } else {
      setPaymentHistory(prev => ({
        ...prev,
        isLoading: false
      }));
      return;
    }

    try {
      const { data } = await axios.get(`${PAYMENT_PURCHASE_HISTORY_DETAIL}?${params}`, {
        cancelToken
      });
      setPaymentHistory(prev => ({
        ...prev,
        isLoading: false,
        data: data.data
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleListExpand = useCallback(
    ({ id, action }: TListExpand) => {
      if (action === 'month') {
        setMonthExpand(prev => ({
          month: String(id),
          isOpen: prev.month === id ? !prev.isOpen : true
        }));
        const date = +id.toString().slice(5) < 10 ? `${new Date(id.toString()).getFullYear()}-0${id.toString().slice(5)}` : String(id);
        fetchDetail(date);
      } else if (action === 'year') {
        setYearExpand(prev => ({
          year: String(id),
          isOpen: prev.year === id ? !prev.isOpen : true
        }));
        const year = String(id);
        fetchMonth(year);
      }
    },
    [productMonths]
  );

  const handleChangeFilters = <T,>(key: keyof typeof dummyFilters, value: T) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  const handleRefresh = () => {
    // setFilters(dummyFilters);
    // fetchProducts();
    // setProductMeta(prev => ({ ...prev, current_page: 1 }));
  };

  const handleOnClickHeader = {
    handleRefresh
  };

  useEffect(() => {
    fetchYear();
    //fetchMonth();
    //fetchProductMonths();
    return () => source.cancel('Cancel Unmounted');
  }, [productMeta.current_page, filters.productName, filters.startDate, filters.endDate]);

  const invoiceFilterDebouce = useDebounce(invoiceId, 500);
  console.log('invoiceFilterDebouce --->', invoiceFilterDebouce);
  useEffect(() => {
    if (invoiceFilterDebouce.length < 1) return;
    fetchYear();
    setYearExpand(prev => ({
      year: '',
      isOpen: false
    }));
    setMonthExpand(prev => ({
      month: '',
      isOpen: false
    }));

    return () => source.cancel('Cancel Unmounted');
  }, [invoiceFilterDebouce]);

  return (
    <PaperCustom>
      <FlexBox container rowGap={1}>
        <PaymentHistoryFilters filters={filters} invoiceId={invoiceId} setInvoiceId={setInvoiceId} />
        <PaymentHistoryHeader
          titleNode={<Typography variant='h6'>Menampilkan seluruh riwayat pembayaran</Typography>}
          onClick={handleOnClickHeader}
        />
        <Suspense
          fallback={
            <Grid xs={12} container justify='center' alignItems='center'>
              <Typography variant='h6'>Sedang Membuka...</Typography>
            </Grid>
          }
        >
          <PaymentHistoryContent
            year={year}
            month={month}
            paymentHistory={paymentHistory}
            handleListExpand={handleListExpand}
            monthExpand={monthExpand}
            yearExpand={yearExpand}
          />
        </Suspense>
        {year.data.length != 0 ? (
          <Grid xs={12} justify='flex-end' container>
            <PaginationCustom
              marginTop='-.4em '
              show={true}
              sxPagination={{
                count: productMeta.last_page,
                boundaryCount: 2,
                variant: 'outlined',
                shape: 'rounded',
                onChange: (event, page) => {
                  setProductMeta(prev => ({ ...prev, current_page: page }));
                },
                page: productMeta.current_page
              }}
              sxPopover={{
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right'
                },
                transformOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right'
                }
              }}
              customPageProps={{
                defaultValue: productMeta.current_page,
                maxValue: productMeta.last_page,
                onSubmit(value) {
                  setProductMeta(prev => ({ ...prev, current_page: value }));
                }
              }}
            />
          </Grid>
        ) : null}
      </FlexBox>
    </PaperCustom>
  );
};

export default PaymentPurchaseHistoryTable;
