import React, { FC, Fragment, useEffect, useState, useContext } from 'react';
import axios, { CancelTokenSource } from 'axios';
import {
  Grid,
  Container,
  Typography,
  makeStyles,
  Button,
  TextField,
  CircularProgress,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  FormHelperText,
  useTheme,
  useMediaQuery
} from '@material-ui/core';

import { Page, StandardConfirmationDialog, PaperCustom, Breadcrumb, CardCustomer } from 'components';
import {
  PARTNER_BASE_URL,
  GET_RETURN_INVOICE_DETAIL_BASE_URL,
  RETURN_INVOICE_BASE_URL,
  INVOICE_ITEM_URL,
  PURCHASE_INVOICE_ITEM_URL,
  PARTNER_DETAIL_BASE_URL,
  GET_USER_DETAIL_URL,
  USER_BASE_URL,
  INVOICE_ITEM_LAST_PURCHASE_BASE_URL
} from 'constants/url';
import { format } from 'date-fns';
import { dummyUser, dummyPartner } from 'utils/dummy';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import useRouter from 'hooks/useRouter';
import useSearch from 'hooks/useSearch';

import Autocomplete from '@material-ui/lab/Autocomplete';
import ProductTableMobile from './components/ProductTableMobile';
import ProductTable from './components/ProductTable';
import NumberFormat from 'react-number-format';
import AddPartnerModal from './components/AddPartnerModal';
import ReactQuill from 'react-quill';
import TypeUser from 'typings/enum/TypeUser';
import useRole from 'hooks/useRole';
import ModalHistory from './components/ProductTable/ModalHistory';
import ModalReturn from './components/ProductTable/ModalReturn';

const useStyles = makeStyles({
  cellSummary: {
    border: 'none',
    padding: 5
  },
  cellSummaryRight: {
    border: 'none',
    padding: 5,
    width: '20%'
  },
  editorNotes: {
    marginBottom: '10px'
  }
});

const ReturnOrderCreateSupplierPage: FC = () => {
  console.log('ini dari pembelian yang baru dipisah');
  const classes = useStyles();
  const { history, location } = useRouter();
  const { currentUser } = useContext(CurrentUserContext);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const params: any = location.state;

  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [returnDate, setReturnDate] = useState<Date | null>(new Date());
  const [returnDateMessage, setReturnDateMessage] = useState<string>('');
  const [partner, setPartner] = useState<PartnerModel>(dummyPartner);
  const [partners, setPartners] = useState<PartnerModel[]>([dummyPartner]);
  const [partnerMessage, setPartnerMessage] = useState<string>('');
  const [id, setId] = useState<number>(0);
  const [sales, setSales] = useState<UserDetailsModel>(dummyUser);
  const [historyResource, setHistoryResource] = useState<{ createdAt: string; price: number }[]>([]);
  const [salesMessage, setSalesMessage] = useState<string>('');
  const [returnOrderItem, setReturnOrderItem] = useState<InvoiceReturnItemModel[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [subTotalPrice, setSubTotalPrice] = useState<number>(0);
  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [additionalDamage, setAdditionalDamage] = useState<number>(0);
  const [updateAdditionalDamage, setUpdateAdditionalDamage] = useState<number>(0);
  const [updateTotalItem, setUpdateTotalItem] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [notes, setNotes] = useState<string>('');
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [loadUpdate, setLoadUpdate] = useState<boolean>(false);
  const [returnOrderItemMessage, setReturnOrderItemMessage] = useState<string>('');
  const [typeReturn, setTypeReturn] = useState<string>(location.pathname === '/return-penjualan/penjualan' ? 'CUSTOMER' : 'SUPPLIER');
  const [open, setOpen] = useState<boolean>(false);
  const [historyPrice, setHistoryPrice] = useState<number>(0);
  const [productId, setProductId] = useState<number>(0);
  const [deletedItems, setDeletedItems] = useState<number[]>([]);
  const [searchSales, setSearchSales] = useState<string>('');
  const [searchPartner, setSearchPartner] = useState<string>('');
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);
  const [totalQtyReturn, setTotalQtyReturn] = useState<number>(0);
  const [totalQtyReturnWarehouse, setTotalQtyReturnWarehouse] = useState<number>(0);
  const [typeUnitReturn, setTypeUnitReturn] = useState<string>('');
  const [productAdditionalDamage, setProductAdditionalDamage] = useState<number>(0);
  const [openHistory, setOpenHistory] = useState(false);
  const [openReturn, setOpenReturn] = useState(false);
  const [isReturn, setIsReturn] = useState(false);
  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
  const [additionalDamageFilter, setAdditionalDamageFilter] = useState<number>(0);
  const [totalItemFilter, setTotalItemFilter] = useState<number>(0);

  const [loadingSales, dataSales] = useSearch({ query: searchSales, url: USER_BASE_URL, token: cancelTokenSource });
  const [loadingPartner, dataPartner] = useSearch({ query: searchPartner, url: PARTNER_BASE_URL, token: cancelTokenSource });

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: [TypeUser.SUPERADMIN, TypeUser.ADMIN03]
  });

  const isDriver = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: ['DRIVER']
  });

  const isAdmin = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: ['ADMIN']
  });

  let filterAdditionalDamage = returnOrderItem.filter(product => product.ProductId === productId);
  let countAdditionalDamage = filterAdditionalDamage.reduce((total, product) => {
    const qtyAdditionalDamage = product?.additionalDamage || 0;
    return total + qtyAdditionalDamage;
  }, 0);

  let filterAdditionalDamageEdit = returnOrderItem.filter(product => product.ProductId === productId && product.id === 0);
  let countAdditionalDamageEdit = filterAdditionalDamageEdit.reduce((total, product) => {
    const qtyAdditionalDamage = product?.additionalDamage || 0;
    return total + qtyAdditionalDamage;
  }, 0);

  let countTotalItem = filterAdditionalDamage.reduce((total, product) => {
    const qtyTotalItem = product?.totalItem || 0;
    return total + qtyTotalItem;
  }, 0);
  let countTotalItemEdit = filterAdditionalDamageEdit.reduce((total, product) => {
    const qtyTotalItem = product?.totalItem || 0;
    return total + qtyTotalItem;
  }, 0);

  // setUpdateAdditionalDamage(isUpdate ? additionalDamage : updateAdditionalDamage);
  // setUpdateTotalItem(isUpdate ? totalItem : updateTotalItem);
  // console.log('UpdateAdditionalDamage -->', updateAdditionalDamage);
  // console.log('updateTotalItem -->', updateTotalItem);

  const searchParams = (arg: { [key: string]: string }) => {
    const params = new URLSearchParams(arg);
    return params.toString();
  };

  const handleSearchPartner = (value: string) => {
    if (value.length >= 3) {
      setSearchPartner(searchParams({ keyword: value, partnerType: typeReturn }));
    }
  };

  const handleSearchSales = (value: string) => {
    if (value.length >= 3) {
      setSearchSales(searchParams({ name: value }));
    }
  };
  const handleOpenHistory = () => {
    setOpenHistory(true);
  };
  const handleOpenReturn = (isReturn: boolean) => {
    setOpenReturn(true);
    setIsReturn(isReturn);
  };

  const getHistory = async () => {
    const params = new URLSearchParams();
    params.append('PartnerId', String(partner.id));
    params.append('ProductId', String(productId));
    try {
      const { data } = await axios.get(
        `${typeReturn === 'SUPPLIER' ? PURCHASE_INVOICE_ITEM_URL : INVOICE_ITEM_LAST_PURCHASE_BASE_URL}?${params.toString()}`
      );

      if (typeReturn === 'SUPPLIER') {
        if (data.data.length > 0) {
          setHistoryPrice(data.data[0].price);
          setTotalQtyReturn(data.data[0].qtyInvoiceItem);
          setTotalItem(
            id
              ? totalItemFilter > 0
                ? data.data[0].qtyInvoiceItem - (countTotalItemEdit - totalItemFilter)
                : data.data[0].qtyInvoiceItem - (countTotalItemEdit + totalItemFilter)
              : data.data[0].qtyInvoiceItem - (countTotalItem + totalItemFilter)
          );
          setTotalQtyReturnWarehouse(data.data[0].qtyWarehouseItem);
          setAdditionalDamage(
            id
              ? additionalDamageFilter > 0
                ? data.data[0].qtyWarehouseItem - (countAdditionalDamageEdit - additionalDamageFilter)
                : data.data[0].qtyWarehouseItem - (countAdditionalDamageEdit + additionalDamageFilter)
              : data.data[0].qtyWarehouseItem - (countAdditionalDamage + additionalDamageFilter)
          );
          console.log('data.data.length > 0  setAdditionalDamage -->');
          console.log('countAdditionalDamageEdit -->', countAdditionalDamageEdit);
          setProductAdditionalDamage(data.data[0].qtyWarehouseItem);
          setTypeUnitReturn(data.data[0].typeUnit);
        } else {
          console.log('data.data.length == 0');
          setHistoryPrice(data.dataWareHouse.price);
          setTotalQtyReturn(data.dataWareHouse.qtyInvoiceItem);
          setTotalItem(
            id
              ? data.dataWareHouse.qtyInvoiceItem - (countTotalItemEdit - totalItemFilter)
              : data.dataWareHouse.qtyInvoiceItem - (countTotalItem - totalItemFilter)
          );
          setTotalQtyReturnWarehouse(data.dataWareHouse.qtyWarehouseItem);
          setAdditionalDamage(
            id
              ? data.dataWareHouse.qtyWarehouseItem - (countAdditionalDamageEdit - additionalDamageFilter)
              : data.dataWareHouse.qtyWarehouseItem - (countAdditionalDamage - additionalDamageFilter)
          );
          setProductAdditionalDamage(data.dataWareHouse.qtyWarehouseItem);
          setTypeUnitReturn(data.dataWareHouse.typeUnit);
        }
      } else {
        if (data.data) {
          setHistoryResource(data.data);
          setHistoryPrice(data.data[data.data.length - 1].price);
        }
      }
    } catch (error) {
      console.log('error :', error);
    }
  };

  const handleSales = async () => {
    try {
      const { data } = await axios.get(`${USER_BASE_URL}/${currentUser && currentUser.id}`);
      setSales(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
    }
  };

  const fetchData = async () => {
    setLoadUpdate(true);
    try {
      const { data } = await axios.get(GET_RETURN_INVOICE_DETAIL_BASE_URL(params.id));
      setTypeReturn(data.data.typeReturn);
      setId(data.data.id);
      setNotes(data.data.notes);
      setReturnDate(new Date(data.data.returnDate));

      fetchAll(data.data.PartnerId, params.id, data.data.SalesId);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadUpdate(false);
    }
  };

  const fetchAll = (prtId: number, rtnId: number, slsId: number) => {
    Promise.all([
      axios.get(PARTNER_DETAIL_BASE_URL(prtId)).catch(() => undefined),
      axios.get(`${RETURN_INVOICE_BASE_URL}/${rtnId}/items`).catch(() => undefined),
      axios.get(GET_USER_DETAIL_URL(slsId)).catch(() => undefined)
    ])
      .then((result: any) => {
        const [data0, data1, data2] = result;

        if (data0) {
          setPartner(data0.data.data);
          setPartners([data0.data.data]);
        }

        if (data1) {
          setReturnOrderItem(data1.data.data);
        }

        if (data2) {
          setSales(data2.data.data);
        }
      })
      .finally(() => {});
  };

  const handleOpenCollapse = (index: number): React.MouseEventHandler => () => {
    setIndexCollapse(prevState => (prevState !== index ? index : -1));
    setOpenCollapse(prevState => (prevState ? (index === indexCollapse ? false : true) : true));
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
    if (typeReturn === 'SUPPLIER') {
      history.push('/return-pembelian/detail', { id });
    } else {
      history.push('/return-penjualan/detail', { id });
    }
  };

  const handleOnSubmit = async () => {
    resetValidation();
    setSubmit(true);

    if (!validation()) {
      setSubmit(false);
      return;
    }

    try {
      const { data } = await axios.post(RETURN_INVOICE_BASE_URL, {
        id,
        returnDate,
        totalPrice: totalPrice,
        totalDiscount: totalDiscount,
        totalItem: returnOrderItem.length,
        PartnerId: partner && partner.id,
        SalesId: sales && sales.id,
        items: returnOrderItem,
        deletedItems,
        notes,
        typeReturn
      });

      setId(data.data.id);
      handleSnackBar(true, 'success', 'Return berhasil dibuat');
    } catch (error) {
      console.log('error :', error);
      handleSnackBar(true, 'error', 'Return gagal dibuat');
    } finally {
      setSubmit(false);
    }
  };

  const handleDeleteItem = (index: number): React.MouseEventHandler => () => {
    const items = [...returnOrderItem];
    const item = items[index];
    const newDeletedItems = [...deletedItems, item.id];

    items.splice(index, 1);
    setReturnOrderItem(items);
    setDeletedItems(newDeletedItems);
  };

  const validation = (): boolean => {
    let valid = true;

    if (!partner) {
      setPartnerMessage('Nama Mitra tidak boleh kosong.');
      valid = false;
    }

    if (!sales) {
      setSalesMessage('Nama Sales tidak boleh kosong');
      valid = false;
    }

    if (returnOrderItem.length === 0 || !returnOrderItem) {
      setReturnOrderItemMessage('Item tidak boleh kosong.');
      valid = false;
    }

    if (!returnDate) {
      setReturnDateMessage('');
      valid = false;
    }

    return valid;
  };

  const resetValidation = () => {
    setPartnerMessage('');
    setSalesMessage('');
    setReturnOrderItemMessage('');
  };

  const handleCancel = () => {
    typeReturn === 'CUSTOMER' ? history.push('/return-penjualan') : history.push('/return-pembelian');
  };

  useEffect(() => {
    if (returnOrderItem.length === 0) {
      return;
    }

    setTotalPrice(returnOrderItem.map(value => (value.isDeleted ? 0 : value.totalPrice)).reduce((a, b) => a + b));
    setSubTotalPrice(returnOrderItem.map(value => (value.isDeleted ? 0 : value.subTotalPrice)).reduce((a, b) => a + b));
    setTotalDiscount(returnOrderItem.map(value => (value.isDeleted ? 0 : value.discount)).reduce((a, b) => a + b));
  }, [returnOrderItem, isUpdate]);

  useEffect(() => {
    if (!params) {
      handleSales();
      return;
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (productId < 1) {
      setHistoryPrice(0);
      return;
    }
    if (!partner.id) return;
    getHistory();
  }, [productId]);

 

  return (
    <Page title='Return'>
      <Container>
        <Grid container direction='row'>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'>{typeReturn === 'CUSTOMER' ? 'Return Penjualan' : 'Return Pembelian'} </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid container direction='row' spacing={2}>
            <Grid container direction='row' justify='space-between' spacing={1}>
              <Grid item lg={6} sm={12} xs={12} md={6}>
                <TextField
                  id='typeReturn'
                  required
                  label='Tipe Return'
                  fullWidth
                  value={location.pathname === '/return-penjualan/penjualan' ? 'Customer ke CCA' : 'CCA ke Supplier'}
                  disabled
                />
              </Grid>
              <Grid item lg={6} sm={12} xs={12} md={6}>
                <TextField
                  id='returnDate'
                  required
                  fullWidth
                  type='date'
                  label='Tanggal Return'
                  placeholder='Tanggal Return Order'
                  value={returnDate && format(returnDate, 'yyyy-MM-dd')}
                  onChange={e => setReturnDate(new Date(e.target.value))}
                  error={returnDateMessage !== ''}
                  helperText={returnDateMessage}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item lg={6} sm={12} xs={12} md={6}>
                <Autocomplete
                  id='partner'
                  value={partner}
                  options={dataPartner as PartnerModel[]}
                  getOptionLabel={option => option.name}
                  getOptionSelected={(option, value) => value.id === option.id}
                  onChange={(event: any, value: any) => {
                    setPartner(value);
                  }}
                  loading={loadingPartner as boolean}
                  renderOption={(option, state) => (
                    <CardCustomer partner={option} isLoading={loadingPartner} isSelected={option.id === partner.id && !loadingPartner} />
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label={typeReturn === 'SUPPLIER' ? 'Pilih Supplier' : 'Pilih Mitra'}
                      onChange={e => handleSearchPartner(e.target.value)}
                      error={partnerMessage !== ''}
                      helperText={partnerMessage}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {loadingPartner && <CircularProgress color='inherit' size={20} />}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        )
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={6} sm={12} xs={12} md={6}>
                <Autocomplete
                  id='sales'
                  value={sales}
                  options={dataSales as UserDetailsModel[]}
                  getOptionLabel={option => option.firstName + ' ' + option.lastName}
                  getOptionSelected={(option, value) => value.id === option.id}
                  onChange={(event: any, value: any) => {
                    setSales(value);
                  }}
                  loading={loadingSales as boolean}
                  disabled={(!!currentUser && currentUser.type === TypeUser.SALES) || typeReturn === 'SUPPLIER' || isDriver}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label='Pilih Sales'
                      onChange={e => handleSearchSales(e.target.value)}
                      error={salesMessage !== ''}
                      helperText={salesMessage}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {loadingSales && <CircularProgress color='inherit' size={20} />}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item xl={12} md={12} sm={12}>
              <FormHelperText error={returnOrderItemMessage !== ''}>{returnOrderItemMessage}</FormHelperText>
            </Grid>

            <Grid container direction='row' item xl={12} md={12} sm={12}>
              <Typography variant='body2'>Total Item : {returnOrderItem.length}</Typography>

              {isSmall ? (
                <ProductTableMobile
                  setAdditionalDamageFilter={setAdditionalDamageFilter}
                  setTotalItemFilter={setTotalItemFilter}
                  returnOrderItem={returnOrderItem}
                  additionalDamage={additionalDamage}
                  productAdditionalDamage={productAdditionalDamage}
                  countAdditionalDamage={id ? countAdditionalDamageEdit - additionalDamageFilter : countAdditionalDamage - additionalDamageFilter}
                  countTotalItem={id ? countTotalItemEdit - totalItemFilter : countTotalItem - totalItemFilter}
                  isUpdate={isUpdate}
                  loadUpdate={loadUpdate}
                  historyPrice={historyPrice}
                  typeReturn={typeReturn}
                  indexCollapse={indexCollapse}
                  openCollapse={openCollapse}
                  totalQtyReturn={totalQtyReturn}
                  totalQtyReturnWarehouse={totalQtyReturnWarehouse}
                  totalItem={totalItem}
                  setTotalItem={setTotalItem}
                  isAdmin={isAdmin}
                  handleOpenHistory={handleOpenHistory}
                  handleOpenReturn={handleOpenReturn}
                  setProductId={setProductId}
                  setReturnOrderItem={setReturnOrderItem}
                  setUpdate={setUpdate}
                  setProductAdditionalDamage={setProductAdditionalDamage}
                  setAdditionalDamage={setAdditionalDamage}
                  handleDeleteItem={handleDeleteItem}
                  handleOpenCollapse={handleOpenCollapse}
                />
              ) : (
                <ProductTable
                  setAdditionalDamageFilter={setAdditionalDamageFilter}
                  setTotalItemFilter={setTotalItemFilter}
                  returnOrderItem={returnOrderItem}
                  additionalDamage={additionalDamage}
                  countAdditionalDamage={id ? countAdditionalDamageEdit - additionalDamageFilter : countAdditionalDamage - additionalDamageFilter}
                  countTotalItem={id ? countTotalItemEdit - totalItemFilter : countTotalItem - totalItemFilter}
                  productAdditionalDamage={productAdditionalDamage}
                  isUpdate={isUpdate}
                  loadUpdate={loadUpdate}
                  historyPrice={historyPrice}
                  typeReturn={typeReturn}
                  handleOpenReturn={handleOpenReturn}
                  isAdmin={isAdmin}
                  totalItem={totalItem}
                  totalQtyReturn={totalQtyReturn}
                  totalQtyReturnWarehouse={totalQtyReturnWarehouse}
                  setTotalItem={setTotalItem}
                  handleOpenHistory={handleOpenHistory}
                  setProductId={setProductId}
                  setReturnOrderItem={setReturnOrderItem}
                  setUpdate={setUpdate}
                  setAdditionalDamage={setAdditionalDamage}
                  setProductAdditionalDamage={setProductAdditionalDamage}
                  handleDeleteItem={handleDeleteItem}
                />
              )}
            </Grid>

            <Grid container direction='row' item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid item lg={12} sm={12} md={12}>
                <ReactQuill
                  id='notes'
                  value={notes}
                  onChange={(value: any) => setNotes(value)}
                  placeholder='Catatan'
                  className={classes.editorNotes}
                />
              </Grid>
            </Grid>

            {(typeReturn === 'CUSTOMER' || (typeReturn === 'SUPPLIER' && isSuperAdmin)) && (
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell align='right' className={classes.cellSummary}>
                          <Typography variant='subtitle2'>Subtotal</Typography>
                        </TableCell>
                        <TableCell align='right' className={classes.cellSummaryRight}>
                          <NumberFormat value={subTotalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell align='right' className={classes.cellSummary}>
                          <Typography variant='subtitle2'>Total Harga</Typography>
                        </TableCell>
                        <TableCell align='right' className={classes.cellSummaryRight}>
                          <NumberFormat value={totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}

            <Grid
              container
              direction={isSmall ? 'column' : 'row'}
              justify={isSmall ? 'space-between' : 'flex-end'}
              spacing={1}
              item
              xl={12}
              md={12}
              sm={12}
              xs={12}
            >
              {isSmall ? (
                <>
                  <Grid item>
                    <Button fullWidth disabled={isSubmit || isUpdate} onClick={handleOnSubmit}>
                      {isSubmit ? <CircularProgress color='inherit' size={20} /> : 'Simpan'}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button variant='text' fullWidth style={{ color: '#A9A9A9' }} onClick={handleCancel} color='inherit'>
                      <u> Batal</u>
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item>
                    <Button onClick={handleCancel} color='secondary'>
                      Batal
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button disabled={isSubmit || isUpdate} onClick={handleOnSubmit}>
                      {isSubmit ? <CircularProgress color='inherit' size={20} /> : 'Simpan'}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>

            <AddPartnerModal partners={partners} open={open} setOpen={setOpen} setPartners={setPartners} handleSnackBar={handleSnackBar} />

            <StandardConfirmationDialog
              variant={snackbarVariant}
              titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
              message={message}
              open={openSnackbar}
              handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
              onConfirm={handleConfirmSnackbar}
              noCancelButton={true}
            />
          </Grid>

          <ModalHistory open={openHistory} setOpen={setOpenHistory} historyResource={historyResource} />
          <ModalReturn
            open={openReturn}
            setOpen={setOpenReturn}
            totalQty={totalQtyReturn}
            totalQtyReturn={totalQtyReturnWarehouse}
            typeUnit={typeUnitReturn}
            isWareHouse={isReturn}
          />
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default ReturnOrderCreateSupplierPage;
