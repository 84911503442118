import React, { FC, useEffect, useCallback, useState } from 'react';
import { Grid, Container, Typography, Button, Breadcrumbs, Link, makeStyles } from '@material-ui/core';
import { Page, StandardConfirmationDialog, PaperCustom } from 'components';
import { AddBox } from '@material-ui/icons';
import axios, { CancelTokenSource } from 'axios';
import { ZONE_BASE_URL } from 'constants/url';
import { dummyZone } from 'utils/dummy';
import useDebounce from 'hooks/useDebounce';
import ZoneTable from './components/ZoneTable';
import CreateWarehouse from './components/CreateZone';
import Pagination from '@material-ui/lab/Pagination';
import SearchInput from 'components/SearchInput';
import useRouter from 'hooks/useRouter';
import PaginationCustom from 'components/PaginationCustom';

const useStyles = makeStyles({
  active: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px'
  }
});

const ZonePage: FC = () => {
  const classes = useStyles();
  const { history } = useRouter();
  const [open, setOpen] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [query, setQuery] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [zones, setZones] = useState<ZoneModel[]>([dummyZone]);
  const [zone, setZone] = useState<ZoneModel | null>(null);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');

  const [selectedId, setSelectedId] = useState<number>();
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('keyword', queryString);
      }

      if (orderBy || order) {
        params.append('orderBy', orderBy);
        params.append('ordered', order);
      }

      params.append('page', currentPage.toString());
      return params.toString();
    };

    try {
      const { data } = await axios.get(`${ZONE_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });
      setZones(data.data);
      setCount(data.meta.last_page);
      setCurrentPage(data.meta.current_page);
    } catch (error) {}
    setIsLoadingData(false);
    return () => {
      cancelTokenSource.cancel();
    };
  }, [queryString, orderBy, order, currentPage]);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  const handleSearch = useCallback((searchQuery: string) => {
    performActionAndRevertPage(setQueryString, searchQuery);
  }, []);

  const debouncedSearchTerm = useDebounce(query, 500);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleConfirmationDelete = (id: number): React.MouseEventHandler => () => {
    setSelectedId(id);
    setConfirmationDelete(true);
  };

  const handleOnUpdate = (id: number): React.MouseEventHandler => () => {
    setOpen(true);
    setZone(zones[id]);
  };

  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  const deleteZone = async () => {
    try {
      await axios.delete(`${ZONE_BASE_URL}/${selectedId}`);
      setZones(zones.filter(value => value.id !== selectedId));
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmationDelete(false);
    }
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleOpenForm = (): void => {
    setOpen(true);
  };

  const onClickHandler = (path: string): React.MouseEventHandler => event => {
    event.preventDefault();
    history.push(path);
  };

  return (
    <Page title='Warehouse'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Pengaturan </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumbs key={1} aria-label='breadcrumb'>
              <Link key={99} color='inherit' href='/' onClick={onClickHandler('/')}>
                Beranda
              </Link>
              <Link color='inherit' href='/' onClick={onClickHandler('/pengaturan')}>
                Pengaturan
              </Link>
              <Typography color='inherit' className={classes.active}>
                Rute
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <PaperCustom>
          <Grid container direction='row' spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container direction='row' justify='space-between'>
                <Grid container item lg={8} sm={8} md={8} justify='flex-start' alignItems='center'>
                  <Grid item>
                    <SearchInput
                      withBorder
                      withTransition={false}
                      width={150}
                      placeHolder='Cari Rute'
                      iconColor='#989898'
                      tableSearchValue={query}
                      setTableSearchValue={setQuery}
                    />
                  </Grid>
                </Grid>

                <Grid container item lg={4} sm={4} md={4} justify='flex-end' alignItems='center'>
                  <Grid item>
                    <Button disabled={isLoadingData} onClick={handleOpenForm}>
                      <AddBox fontSize='small' /> &nbsp; Tambah Rute
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container justify='center' item xl={12} lg={12} md={12} sm={12} xs={12}>
              <ZoneTable
                isLoadingData={isLoadingData}
                zones={zones}
                queryString={queryString}
                order={order}
                orderBy={orderBy}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                handleConfirmationDelete={handleConfirmationDelete}
                handleOnUpdate={handleOnUpdate}
              />
            </Grid>

            <Grid container justify='flex-end' item xl={12} md={12} sm={12}>
              <PaginationCustom
                marginTop='-.4em '
                show={zones.length > 0}
                sxPagination={{
                  count,
                  boundaryCount: 2,
                  variant: 'outlined',
                  shape: 'rounded',
                  onChange: (event, page) => {
                    setCurrentPage(page);
                  },
                  page: currentPage
                }}
                sxPopover={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  }
                }}
                customPageProps={{
                  defaultValue: currentPage,
                  maxValue: count,
                  onSubmit(value) {
                    setCurrentPage(value);
                  }
                }}
              />
            </Grid>

            <CreateWarehouse
              zone={zone}
              zones={zones}
              open={open}
              setOpen={setOpen}
              setZones={setZones}
              setZone={setZone}
              handleSnackBar={handleSnackBar}
            />

            <StandardConfirmationDialog
              variant={snackbarVariant}
              titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
              message={message}
              open={openSnackbar}
              handleClose={handleCloseSnackbar}
              onConfirm={handleCloseSnackbar}
              noCancelButton={true}
            />

            <StandardConfirmationDialog
              variant={'danger'}
              titleMessage={'Menghapus Data'}
              message={'Apakah kamu yakin akan menghapus data ini?'}
              open={confirmationDelete}
              handleClose={handleCloseConfirmationDelete}
              onConfirm={deleteZone}
            />
          </Grid>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default ZonePage;
