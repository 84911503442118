import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { Add, CloseRounded, RemoveRounded } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import FlexBox from 'components/FlexBox';
import { GREY, PRIMARY_MOBILE, WHITE } from 'constants/colors';
import React, { ReactNode } from 'react';
import { TProduct } from '..';
import useRouter from 'hooks/useRouter';
interface Props {
  productResources: ProductModel[];
  isLoading: boolean;
  topSection: ReactNode;
  bottomSection: ReactNode;
  handleGetCartQuantity: (id: number) => number;
  handleIncreaseCartQuantity: ({
    id,
    PurchaseOrderId,
    ProductId,
    typeUnit,
    productName,
    productImage,
    productCode,
    totalStock,
    holdItem,
    netStock,
    warehouse
  }: TProduct) => void;
  handleDecreaseCartQuantity: (id: number) => void;
  checkProductHasCart: (id: number) => boolean;
  handleChangeQuantity: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOpenImage: (imagePath: string) => void;
}

const useStyles = makeStyles(() => ({
  products: {
    borderTop: '1px solid rgba(0, 0, 0, 0.08)',
    maxHeight: '100vh',
    overflowY: 'scroll',
    padding: '1em 1em 1em 0'
  },
  productContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '1.5em'
  },

  productCard: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
    borderRadius: '.4em',
    rowGap: '1em',
    '&:hover': {
      boxShadow: 'rgb(0 0 0 / 20%) 0px 6px 4px -4px, rgb(0 0 0 / 14%) 0px 4px 4px 0px, rgb(0 0 0 / 12%) 0px 5px 7px 0px'
    }
  },
  productImage: {
    objectFit: 'cover',
    height: '300px',
    display: 'grid',
    placeItems: 'center',
    backgroundColor: GREY
  },
  cardContent: {
    height: '9em'
  },
  cardAction: {
    height: '5em',
    marginTop: 'auto'
  },
  iconButton: {
    background: PRIMARY_MOBILE,
    '&:hover': {
      background: '#008ABF'
    }
  },
  icon: {
    color: WHITE
  },
  emptyImage: {
    padding: '0 1em'
  }
}));
const ProductCardList = ({
  productResources,
  topSection,
  bottomSection,
  isLoading,
  handleIncreaseCartQuantity,
  handleGetCartQuantity,
  handleDecreaseCartQuantity,
  checkProductHasCart,
  handleChangeQuantity,
  handleOpenImage
}: Props) => {
  const { history } = useRouter();
  const classes = useStyles();

  return (
    <FlexBox container xs={12} rowGap={1}>
      {topSection}
      <Grid xs={12} container className={classes.products}>
        <Grid xs={12} className={classes.productContainer}>
          {isLoading
            ? [...new Array(8)].map(value => (
                <Card>
                  <CardActionArea>
                    <Skeleton animation='wave' variant='rect' width={300} height={200} />
                    <CardContent>
                      <Typography variant='h6'>
                        <Skeleton animation='wave' />
                      </Typography>
                      <Typography variant='h6'>
                        <Skeleton animation='wave' />
                      </Typography>
                      <Typography variant='h6'>
                        <Skeleton animation='wave' />
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button variant='contained' size='small' fullWidth disabled>
                        Tambahkan Barang
                      </Button>
                    </CardActions>
                  </CardActionArea>
                </Card>
              ))
            : productResources.map(productResource => (
                <Card className={classes.productCard} key={productResource.id}>
                  <CardActionArea>
                    <CardMedia
                      image={(productResource.ProductImage && productResource.ProductImage.url) || ''}
                      title={productResource.productName}
                      className={classes.productImage}
                      onClick={() => handleOpenImage((productResource.ProductImage && productResource.ProductImage.url) || '')}
                    >
                      {!productResource.ProductImage && (
                        <Typography variant='h6' color='textSecondary' align='center' className={classes.emptyImage}>
                          {productResource.productName}
                        </Typography>
                      )}
                    </CardMedia>
                    <CardContent className={classes.cardContent}>
                      <Typography variant='h6' onClick={() => history.push(`/produk/${productResource.id}`)}>
                        {productResource.productName}
                      </Typography>
                      <Typography color='textSecondary'>{productResource.productCode}</Typography>

                      <Grid xs={12} container justify='space-between'>
                        <Grid xs={10}>
                          {' '}
                          <Typography color='textSecondary' component='p'>
                            Stok Jual: {(productResource.netStock || 0) - (productResource.holdItem || 0)}
                          </Typography>
                          <Typography color='textSecondary' component='p'>
                            Stok Tertahan: {productResource.holdItem || 0}
                          </Typography>
                        </Grid>
                        <Grid xs={2}>
                          <Typography component='p'>{productResource.warehouse}</Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>

                  <CardActions className={classes.cardAction}>
                    {checkProductHasCart(productResource.id) ? (
                      <Grid xs={12} container justify='space-around' alignItems='center'>
                        <IconButton
                          size='small'
                          color='primary'
                          className={classes.iconButton}
                          onClick={() => handleDecreaseCartQuantity(productResource.id)}
                        >
                          {handleGetCartQuantity(productResource.id) > 0 ? (
                            <RemoveRounded className={classes.icon} />
                          ) : (
                            <CloseRounded className={classes.icon} />
                          )}
                        </IconButton>
                        <Grid xs={4}>
                          <TextField
                            id={String(productResource.id)}
                            variant='outlined'
                            autoFocus
                            inputProps={{ min: 0, style: { textAlign: 'center' }, inputMode: 'numeric' }}
                            size='small'
                            onChange={handleChangeQuantity}
                            fullWidth
                            value={handleGetCartQuantity(productResource.id)}
                          />
                        </Grid>
                        <IconButton
                          size='small'
                          color='primary'
                          className={classes.iconButton}
                          onClick={() =>
                            handleIncreaseCartQuantity({
                              id: 0,
                              PurchaseOrderId: 0,
                              ProductId: productResource.id,
                              typeUnit: productResource.typeUnit,
                              productName: productResource.productName,
                              productImage: (productResource.ProductImage && productResource.ProductImage.url) || '',
                              productCode: productResource.productCode,
                              totalStock: productResource.totalStock - (productResource.holdItem || 0),
                              holdItem: productResource.holdItem,
                              netStock: productResource.netStock,
                              warehouse: productResource.warehouse
                            })
                          }
                        >
                          <Add className={classes.icon} />
                        </IconButton>
                      </Grid>
                    ) : (
                      <Button
                        variant='contained'
                        size='small'
                        fullWidth
                        onClick={() =>
                          handleIncreaseCartQuantity({
                            id: 0,
                            PurchaseOrderId: 0,
                            ProductId: productResource.id,
                            typeUnit: productResource.typeUnit,
                            productName: productResource.productName,
                            productImage: (productResource.ProductImage && productResource.ProductImage.url) || '',
                            productCode: productResource.productCode,
                            totalStock: productResource.totalStock - (productResource.holdItem || 0)
                          })
                        }
                      >
                        Tambahkan Barang
                      </Button>
                    )}
                  </CardActions>
                </Card>
              ))}
        </Grid>
      </Grid>
      {bottomSection}
    </FlexBox>
  );
};

export default React.memo(ProductCardList);
