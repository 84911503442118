import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Checkbox, makeStyles } from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart, StatusPayment } from 'components';
import TableRowCustom from 'components/Table/TableRowCustom';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { dummyInvoicePayment } from 'utils/dummy';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';

interface Props {
  invoice: InvoiceModel;
  isLoading: boolean;
  checked: InvoicePaymentModel[];
  handleIndividualCheck: (item: InvoicePaymentModel) => void;
}

const useStyles = makeStyles({
  cellStatus: {
    width: '200px'
  }
});

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { invoice, isLoading, checked, handleIndividualCheck } = props;
  const { id } = invoice;

  let isChecked = checked.some(checkedValue => checkedValue.InvoiceId === id);

  const handleSetId = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    handleIndividualCheck({ ...dummyInvoicePayment, InvoiceId: id });
  };

  return (
    <TableRowCustom>
      <TableCellStart>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <Checkbox
            key={id}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<CheckBoxIcon />}
            edge='start'
            color='primary'
            checked={isChecked}
            tabIndex={-1}
            disableRipple
            // disabled={checked.length > 0 && !isChecked}
            onChange={handleSetId}
          />
        )}
      </TableCellStart>
      <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : invoice.invoiceNumber}</TableCellMiddle>
      <TableCellMiddle>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : invoice.createdAt && format(new Date(invoice.createdAt), 'dd/MM/yyyy')}
      </TableCellMiddle>
      <TableCellMiddle className={classes.cellStatus}>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <StatusPayment
            status={invoice.statusPayment}
            overdue={invoice.paymentDue ? new Date().getTime() > new Date(invoice.paymentDue).getTime() : false}
            totalPrice={invoice.totalPrice}
            totalPay={invoice.totalPay}
          />
        )}
      </TableCellMiddle>
      <TableCellMiddle>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <NumberFormat value={invoice.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        )}
      </TableCellMiddle>

      <TableCellMiddle>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <NumberFormat value={invoice.totalPrice - invoice.totalPay} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        )}
      </TableCellMiddle>

      <TableCellEnd align='center'>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : invoice.paymentDue ? (
          format(new Date(invoice.paymentDue), 'dd MMM yyyy')
        ) : (
          '-'
        )}
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRow;
