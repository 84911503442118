import React, { FC, useState, useCallback, useEffect } from 'react';
import { Container, Grid, makeStyles, Paper, Theme, Typography, Button } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { format } from 'date-fns';
import axios, { CancelTokenSource } from 'axios';
import NumberFormat from 'react-number-format';
import { Page, Breadcrumb } from 'components';
import { WHITE, BLUE_PRIMARY, BLUE_SECONDARY, RED_SECONDARY, GREEN_LIGHT } from 'constants/colors';
import { FINANCE_BASE_URL } from 'constants/url';

const useStyles = makeStyles((theme: Theme) => ({
  topPaper: {
    width: 'inherit',
    boxShadow: '0px 5px 24px rgba(2, 169, 234, 0.12)',
    borderRadius: 5,
    padding: 8,
    backgroundColor: BLUE_PRIMARY
  },
  topContainer: {
    padding: 30,
    color: WHITE
  },
  topGrandTotal: {
    color: WHITE,
    fontSize: 40
  },
  topSubInfo: {
    color: WHITE,
    fontSize: 17
  },
  textInfo: {
    color: BLUE_SECONDARY
  },
  iconCard: {
    fontSize: 48,
    color: BLUE_SECONDARY
  },
  icon: {
    paddingTop: 5,
    marginTop: 5,
    fontSize: 48,
    color: BLUE_SECONDARY
  },
  gridPaper: {
    paddingTop: 15,
    marginTop: 20
  },
  infoPaper: {
    paddingTop: 25,
    paddingBottom: 25,
    marginTop: 40,
    boxShadow: '0px 5px 24px rgba(2, 169, 234, 0.12)',
    backgroundColor: WHITE,
    borderRadius: 5
  },
  middleInfoContainer: {
    borderRight: '1px solid grey',
    borderLeft: '1px solid grey'
  },
  secondMiddleInfoContainer: {
    borderRight: '1px solid grey'
  }
}));

const FinancePage: FC = () => {
  const classes = useStyles();

  const [type, setType] = useState<string>('DAY');
  const [rateStatus, setRateStatus] = useState<string>('+');
  const [rate, setRate] = useState<number>(0);
  const [grandTotal, setGrandTotal] = useState<number>(0);
  const [cashTotal, setCashTotal] = useState<number>(0);
  const [bankGrandTotal, setBankGrandTotal] = useState<number>(0);
  const [banksTotal, setBanksTotal] = useState<[]>([]);
  const [giroTotal, setGiroTotal] = useState<number>(0);
  const [returnTotal, setReturnTotal] = useState<number>(0);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    try {
      const { data } = await axios.get(`${FINANCE_BASE_URL}?type=${type}`, { cancelToken: cancelTokenSource.token });

      setRate(data.rate);
      setRateStatus(data.rateStatus ? '+' : '-');
      setGrandTotal(data.grandTotal);
      setCashTotal(data.cashTotal);
      setBankGrandTotal(data.bankTotal);
      setBanksTotal(data.totalBanks);
      setGiroTotal(data.giroTotal);
      setReturnTotal(data.returnTotal);
    } catch (error) {}
    setIsLoadingData(false);
    return cancelTokenSource.cancel();
  }, [type]);

  const handleChangeType = (type: string) => {
    setType(type);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Page title='Keuangan'>
      <Container>
        <Grid container direction='row'>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Keuangan </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <Grid container spacing={2} direction='row' className={classes.gridPaper} justify='center'>
          <Grid container item lg={5} md={5} sm={12} xs={12}>
            <Paper className={classes.topPaper}>
              <Grid
                direction='column'
                item
                container
                lg={12}
                md={12}
                sm={12}
                xs={12}
                spacing={1}
                justify='center'
                alignItems='center'
                className={classes.topContainer}
              >
                <Typography variant='h1' className={classes.topGrandTotal}>
                  {isLoadingData ? (
                    <Skeleton variant='text' width={150} height={25} />
                  ) : (
                    <NumberFormat value={grandTotal} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  )}
                </Typography>
                {isLoadingData ? (
                  <Skeleton variant='text' width={150} height={25} />
                ) : (
                  <Grid>
                    <Typography
                      className={classes.topSubInfo}
                      variant='overline'
                      style={{ color: rateStatus === '+' ? GREEN_LIGHT : RED_SECONDARY }}
                    >{`${rateStatus}${rate}% `}</Typography>
                    <Typography className={classes.topSubInfo} variant='overline'>
                      dari {type === 'DAY' ? 'hari' : 'bulan'} kemarin
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2} justify='center' alignItems='center' className={classes.gridPaper}>
          <Grid item>
            <Button onClick={() => handleChangeType('DAY')} variant={type === 'DAY' ? 'contained' : 'outlined'}>
              HARI
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => handleChangeType('MONTH')} variant={type === 'MONTH' ? 'contained' : 'outlined'}>
              BULAN
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2} direction='row' className={classes.gridPaper} justify='center'>
          <Typography variant='h5'>{format(new Date(), type === 'DAY' ? 'dd MMMM yyyy' : 'MMMM yyyy')}</Typography>
        </Grid>

        <Grid container spacing={2} direction='row' className={classes.infoPaper}>
          <Grid item container md={3} sm={12} spacing={2} direction='column' justify='center' alignItems='center'>
            <Typography variant='h6' className={classes.textInfo}>
              CASH
            </Typography>
            <Typography variant='h5'>
              {isLoadingData ? (
                <Skeleton variant='text' width={150} height={25} />
              ) : (
                <NumberFormat value={cashTotal} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              )}
            </Typography>
          </Grid>
          <Grid
            item
            container
            md={3}
            sm={12}
            spacing={2}
            direction='column'
            justify='center'
            alignItems='center'
            className={classes.middleInfoContainer}
          >
            <Typography variant='h6' className={classes.textInfo}>
              BANK TRANSFER
            </Typography>
            <Typography variant='h5'>
              {isLoadingData ? (
                <Skeleton variant='text' width={150} height={25} />
              ) : (
                <NumberFormat value={bankGrandTotal} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              )}
            </Typography>
            {!isLoadingData &&
              banksTotal &&
              banksTotal.length > 0 &&
              banksTotal.map((bank: any) => {
                return (
                  <Typography variant='body1'>
                    {`${bank.name} - `}
                    <NumberFormat value={bank.total} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  </Typography>
                );
              })}
          </Grid>
          <Grid
            item
            container
            md={3}
            sm={12}
            spacing={2}
            direction='column'
            justify='center'
            alignItems='center'
            className={classes.secondMiddleInfoContainer}
          >
            <Typography variant='h6' className={classes.textInfo}>
              GIRO
            </Typography>
            <Typography variant='h5'>
              {isLoadingData ? (
                <Skeleton variant='text' width={150} height={25} />
              ) : (
                <NumberFormat value={giroTotal} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              )}
            </Typography>
          </Grid>
          <Grid item container md={3} sm={12} spacing={2} direction='column' justify='center' alignItems='center'>
            <Typography variant='h6' className={classes.textInfo}>
              RETURN
            </Typography>
            <Typography variant='h5'>
              {isLoadingData ? (
                <Skeleton variant='text' width={150} height={25} />
              ) : (
                <NumberFormat value={returnTotal} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              )}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default FinancePage;
