import React, { FC, useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  TextField,
  MenuItem,
  Tabs,
  Tab,
  Typography,
  Grid,
  IconButton,
  FormHelperText,
  Paper,
  CircularProgress,
  DialogActions,
  Button
} from '@material-ui/core';
import { isValidEmail } from 'utils';
import { GREY, WHITE, BLUE_PRIMARY } from 'constants/colors';
import { dummyRegion, dummyZone, dummyPartner } from 'utils/dummy';
import { TabPanel, a11yProps } from 'components';
import TypeUser from 'typings/enum/TypeUser';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactQuill from 'react-quill';
import NumberFormatMask from 'components/NumberFormatMask';

enum PartnerType {
  SUPPLIER = 'SUPPLIER',
  CUSTOMER = 'CUSTOMER'
}

interface Props {
  partnerId: string;
  isSubmit: boolean;
  region: RegionModel[];
  route: ZoneModel[];
  value: number;
  loadZone: boolean;
  loadProvinsi: boolean;
  loadKabupaten: boolean;
  loadKecamatan: boolean;
  typeUser: string;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (partner: PartnerModel, reset: () => void, resetValidation: () => void) => void;
  handleSearchProvinsi: (value: string) => void;
  handleSearchKabupaten: (code: string, value: string) => void;
  handleSearchKecamatan: (code: string, value: string) => void;
  handleSearchZone: (value: string) => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles({
  tabGrid: {
    marginBottom: 10
  },
  gridWrapper: {
    padding: '12px'
  },
  paper: {
    border: '1px solid #E0E0E0',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    width: '100%',
    height: '173px',
    paddingTop: 24
  },
  iconButton: {
    background: BLUE_PRIMARY
  },
  iconBorder: {
    border: `1px solid ${GREY}`,
    boxSizing: 'border-box',
    marginRight: 18
  },
  icon: {
    color: WHITE
  },
  errorType: {
    textAlign: 'center',
    width: '100%'
  }
});

const PartnerWizard: FC<Props> = props => {
  const classes = useStyles();

  const {
    isSubmit,
    partnerId,
    region,
    route,
    value,
    loadZone,
    loadProvinsi,
    loadKabupaten,
    loadKecamatan,
    typeUser,
    setValue,
    setSubmit,
    onSubmit,
    handleSearchProvinsi,
    handleSearchKabupaten,
    handleSearchZone,
    handleSearchKecamatan,
    setOpen
  } = props;

  const [name, setName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [remarkAddress, setRemarkAddress] = useState<string>('');
  const [cellPhoneNumber, setCellPhoneNumber] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [website, setWebsite] = useState<string>('');
  const [virtualAccount, setVirtualAccount] = useState<string>('');
  const [plafon, setPlafon] = useState<number | null>(null);
  const [notes, setNotes] = useState<string>('');
  const [postalCode, setPostalCode] = useState<number | null>(null);
  const [partnerType, setPartnerType] = useState<PartnerType | null>(null);
  const [provinsi, setProvinsi] = useState<RegionModel>(dummyRegion);
  const [kabupaten, setKabupaten] = useState<RegionModel>(dummyRegion);
  const [kecamatan, setKecamatan] = useState<RegionModel>(dummyRegion);
  const [zone, setZone] = useState<ZoneModel>(dummyZone);
  const [dueDate, setDueDate] = useState<number | null>(30);
  const [nameMessage, setNameMessage] = useState<string>('');
  const [emailMessage, setEmailMessage] = useState<string>('');
  const [addressMessage, setAddressMessage] = useState<string>('');
  const [businessEntityMessage, setBusinessEntityMessage] = useState<string>('');
  const [provinsiMessage, setProvinsiMessage] = useState<string>('');
  const [kabupatenMessage, setKabupatenMessage] = useState<string>('');
  const [cellPhoneNumberMessage, setCellPhoneNumberMessage] = useState<string>('');
  const [kecamatanMessage, setKecamatanMessage] = useState<string>('');
  const [postalCodeMessage, setPostalCodeMessage] = useState<string>('');
  const [zoneMessage, setZoneMessage] = useState<string>('');
  const [phoneNumberMessage, setPhoneNumberMessage] = useState<string>('');
  const [dueDateMessage, setDueDateMessage] = useState<string>('');
  const [partnerTypeMessage, setPartnerTypeMessage] = useState<string>('');
  const [isSalesOrSpv, setIsSalesOrSpv] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleNextStep = () => {
    if (value === 0) {
      if (!validationFormOne()) {
        return;
      }
      setValue(prevState => prevState + 1);
    } else {
      handleSubmit();
    }
  };

  const validationFormOne = (): boolean => {
    let valid = true;

    if (name === '' || !name) {
      setNameMessage('Nama tidak boleh kosong.');
      valid = false;
    }

    if (email && !isValidEmail(email)) {
      setEmailMessage('Email tidak valid.');
      valid = false;
    }

    if (type === '' || !type) {
      setBusinessEntityMessage('Badan Usaha tidak boleh kosong');
      valid = false;
    }

    if (partnerType === PartnerType.CUSTOMER) {
      if (!dueDate) {
        setDueDateMessage('Jatuh tempo tidak boleh kosong');
        valid = false;
      }
    }

    if (!partnerType) {
      setPartnerTypeMessage('Pilih tipe mitra terlebih dahulu');
    }
    if (cellPhoneNumber === '') {
      setCellPhoneNumberMessage('No handphone tidak boleh kosong');
      valid = false;
    }

    if (phoneNumber === '') {
      setPhoneNumberMessage('No telpon tidak boleh kosong');
      valid = false;
    }

    return valid;
  };

  const validationFormTwo = (): boolean => {
    let valid = true;

    if (!address || !address.trim()) {
      setAddressMessage('Alamat tidak boleh kosong,');
      valid = false;
    }

    if (provinsi.name === '' || provinsi.code === '') {
      setProvinsiMessage('Asal provinsi tidak boleh kosong');
      valid = false;
    }

    if (kabupaten.name === '' || kabupaten.code === '') {
      setKabupatenMessage('Kabupaten tidak boleh kosong');
      valid = false;
    }

    if (kecamatan.name === '' || kecamatan.code === '') {
      setKecamatanMessage('Kecamatan tidak boleh kosong');
      valid = false;
    }

    return valid;
  };

  const resetValidation = () => {
    setNameMessage('');
    setEmailMessage('');
    setAddressMessage('');
    setProvinsiMessage('');
    setKabupatenMessage('');
    setKecamatanMessage('');
    setPostalCodeMessage('');
    setBusinessEntityMessage('');
    setDueDateMessage('');
    setPartnerTypeMessage('');
    setCellPhoneNumberMessage('');
    setPhoneNumberMessage('');
  };

  const reset = () => {
    setName('');
    setEmail('');
    setPhoneNumber('');
    setAddress('');
    setProvinsi(dummyRegion);
    setKabupaten(dummyRegion);
    setKecamatan(dummyRegion);
    setPostalCode(null);
    setDueDate(null);
    setPartnerType(null);
    setType('');
  };

  const handleCancel = () => {
    setOpen(false);
    resetValidation();
  };

  const handleSubmit = () => {
    setSubmit(true);
    resetValidation();
  };

  useEffect(() => {
    if (name !== '') {
      setNameMessage('');
    }

    if (isValidEmail(email)) {
      setEmailMessage('');
    }

    if (type !== '') {
      setBusinessEntityMessage('');
    }

    if (partnerType === PartnerType.CUSTOMER) {
      if (dueDate) {
        setDueDateMessage('');
      }

      if (zone.id !== 0 || zone.name !== '') {
        setZoneMessage('');
      }
    }

    if (partnerType) {
      setPartnerTypeMessage('');
    }

    if (address !== '') {
      setAddressMessage('');
    }

    if (provinsi.name !== '' || provinsi.code !== '') {
      setProvinsiMessage('');
    }

    if (kabupaten.name !== '' || kabupaten.code !== '') {
      setKabupatenMessage('');
    }

    if (kecamatan.name !== '' || kecamatan.code !== '') {
      setKecamatanMessage('');
    }
  }, [name, email, type, dueDate, zone, partnerType, address, provinsi, kabupaten, kecamatan]);

  useEffect(() => {
    if (!isSubmit) {
      return;
    }

    resetValidation();

    if (!validationFormOne()) {
      setValue(0);
      setSubmit(false);
      return;
    } else if (!validationFormTwo()) {
      setValue(1);
      setSubmit(false);
      return;
    }

    const item: PartnerModel = {
      ...dummyPartner,
      partnerId,
      name,
      partnerType: partnerType!,
      phoneNumber,
      cellPhoneNumber,
      email,
      address,
      notes,
      website,
      postalCode,
      areaCode: kecamatan.code,
      regionName: provinsi.name + ', ' + kabupaten.name + ', ' + kecamatan.name,
      ZoneId: zone.id,
      businessEntity: type,
      virtualAccount,
      dueDate,
      plafon,
      remarkAddress
    };
    onSubmit(item, reset, resetValidation);
  }, [isSubmit]);

  useEffect(() => {
    if (typeUser === TypeUser.SALES || typeUser === TypeUser.SUPERVISOR || typeUser === TypeUser.ADMIN) {
      setPartnerType(PartnerType.CUSTOMER);
    }
    if (typeUser === TypeUser.BUYER) {
      setPartnerType(PartnerType.SUPPLIER);
    }
    if (typeUser === TypeUser.SALES || typeUser === TypeUser.SUPERVISOR) {
      setIsSalesOrSpv(true);
    }

    return;
  }, [typeUser]);

  return (
    <Grid container direction='row' spacing={1} className={classes.gridWrapper}>
      {typeUser === TypeUser.SUPERADMIN && (
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center'>
            Pilih tipe mitra <code style={{ color: 'red' }}>*</code>
          </Typography>

          <Grid container direction='row' item lg={12} md={12} sm={12} justify='space-between'>
            <Grid container item lg={6} sm={6} md={6} xs={6} justify='center' alignItems='center'>
              <IconButton
                onClick={() => setPartnerType(PartnerType.SUPPLIER)}
                className={clsx({ [classes.iconButton]: PartnerType.CUSTOMER !== partnerType && partnerType }, classes.iconBorder)}
              >
                <ShoppingCartIcon color='primary' className={clsx({ [classes.icon]: PartnerType.CUSTOMER !== partnerType && partnerType })} />
              </IconButton>
              <Typography variant='h5'>Supplier</Typography>
            </Grid>
            <Grid container item lg={6} sm={6} md={6} xs={6} justify='center' alignItems='center'>
              <IconButton
                onClick={() => setPartnerType(PartnerType.CUSTOMER)}
                className={clsx({ [classes.iconButton]: PartnerType.CUSTOMER === partnerType }, classes.iconBorder)}
              >
                <PersonIcon color='primary' className={clsx({ [classes.icon]: PartnerType.CUSTOMER === partnerType })} />
              </IconButton>
              <Typography variant='h5'>Customer</Typography>
            </Grid>
          </Grid>

          <FormHelperText error={partnerTypeMessage !== ''} className={classes.errorType}>
            {partnerTypeMessage}
          </FormHelperText>
        </Paper>
      )}

      <Grid item container direction='row' justify='center' className={classes.tabGrid}>
        <Tabs value={value} indicatorColor='primary' onChange={handleChange} aria-label='disabled tabs example'>
          <Tab label='Informasi' {...a11yProps(0)} disabled={partnerType === null} />
          <Tab label='Alamat' {...a11yProps(1)} disabled={partnerType === null} />
        </Tabs>
      </Grid>

      <TabPanel value={value} index={0}>
        <Grid container direction='row' spacing={1} justify='space-between'>
          <Grid item lg={6} sm={6} md={6} xs={6}>
            <TextField id='partnerId' required fullWidth placeholder='ID Mitra' value={partnerId} disabled />
          </Grid>
          <Grid item lg={6} sm={6} md={6} xs={6}>
            <TextField
              id='type'
              select
              value={type as string}
              required
              label='Badan Usaha'
              fullWidth
              onChange={event => setType(event.target.value as string)}
              error={businessEntityMessage !== ''}
              disabled={partnerType === null}
            >
              <MenuItem key={1} value='' selected>
                Badan Usaha
              </MenuItem>
              <MenuItem key={2} value='PT'>
                PT
              </MenuItem>
              <MenuItem key={3} value='CV'>
                CV
              </MenuItem>
              <MenuItem key={4} value='Firma'>
                Firma
              </MenuItem>
              <MenuItem key={5} value='Koperasi'>
                Koperasi
              </MenuItem>
              <MenuItem key={6} value='Perorangan'>
                Perorangan
              </MenuItem>
            </TextField>
            <FormHelperText error={businessEntityMessage !== ''}>{businessEntityMessage}</FormHelperText>
          </Grid>
          <Grid item lg={6} sm={6} md={6} xs={6}>
            <TextField
              id='name'
              name='name'
              required
              fullWidth
              label='Nama '
              value={name}
              onChange={event => setName(event.target.value)}
              error={nameMessage !== ''}
              helperText={nameMessage}
              disabled={partnerType === null}
            />
          </Grid>
          <Grid item lg={6} sm={6} md={6} xs={6}>
            <TextField
              id='email'
              name='email'
              type='email'
              fullWidth
              label='Email '
              value={email}
              onChange={event => setEmail(event.target.value)}
              error={emailMessage !== ''}
              helperText={emailMessage}
              disabled={partnerType === null}
            />
          </Grid>
          <Grid item lg={6} sm={6} md={6} xs={6}>
            <TextField
              id='phoneNumber'
              fullWidth
              label='No Telepon '
              value={phoneNumber}
              onChange={event => setPhoneNumber(event.target.value)}
              error={phoneNumberMessage !== ''}
              helperText={phoneNumberMessage}
              disabled={partnerType === null}
            />
          </Grid>
          <Grid item lg={6} sm={6} md={6} xs={6}>
            <TextField
              id='cellPhoneNumber'
              fullWidth
              label='No Handphone '
              value={cellPhoneNumber}
              onChange={event => setCellPhoneNumber(event.target.value)}
              error={cellPhoneNumberMessage !== ''}
              helperText={cellPhoneNumberMessage}
              disabled={partnerType === null}
            />
          </Grid>
          <Grid item lg={6} sm={6} md={6} xs={6}>
            <TextField
              id='website'
              disabled={partnerType === null}
              fullWidth
              label='Laman Website'
              value={website}
              onChange={event => setWebsite(event.target.value)}
            />
          </Grid>
          <Grid item lg={6} sm={6} md={6} xs={6}>
            <TextField
              id='virtualAccount'
              fullWidth
              label='No Akun Virtual'
              value={virtualAccount}
              onChange={event => setVirtualAccount(event.target.value)}
              disabled={partnerType === null}
            />
          </Grid>
          {!isSalesOrSpv && (
            <Grid item lg={6} sm={6} md={6} xs={6}>
              <TextField
                id='plafon'
                fullWidth
                label='Plafon'
                value={plafon}
                onChange={event => setPlafon(+event.target.value)}
                disabled={partnerType === null}
                InputProps={{
                  inputComponent: NumberFormatMask as any
                }}
              />
            </Grid>
          )}

          {partnerType === PartnerType.CUSTOMER && !isSalesOrSpv && (
            <Fragment>
              <Grid item lg={6} sm={6} md={6} xs={6}>
                <TextField
                  id='dueDate'
                  required
                  fullWidth
                  label='Set Jatuh Tempo'
                  value={dueDate}
                  onChange={event => setDueDate(+event.target.value)}
                  error={dueDateMessage !== ''}
                  helperText={dueDateMessage}
                  disabled={partnerType === null}
                />
              </Grid>

              {!isSalesOrSpv && (
                <Grid item lg={6} sm={6} md={6} xs={6}>
                  <Autocomplete
                    id='zone'
                    fullWidth
                    value={zone}
                    options={route}
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) => option.id === value.id}
                    onChange={(event: any, value: any) => {
                      if (value) {
                        setZone(value);
                      }
                    }}
                    loading={loadZone}
                    onOpen={e => handleSearchZone('')}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Route *'
                        size='small'
                        onChange={e => handleSearchZone(e.target.value)}
                        variant='outlined'
                        error={zoneMessage !== ''}
                        helperText={zoneMessage}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {loadZone && <CircularProgress color='inherit' size={20} />}
                              {params.InputProps.endAdornment}
                            </Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
            </Fragment>
          )}
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Grid container direction='row' spacing={1} justify='space-between'>
          <Grid item lg={6} sm={6} md={6} xs={6}>
            <TextField
              id='address'
              required
              fullWidth
              label='Alamat'
              value={address}
              onChange={event => setAddress(event.target.value)}
              error={addressMessage !== ''}
              helperText={addressMessage}
            />
          </Grid>

          <Grid item lg={6} sm={6} md={6} xs={6}>
            <Autocomplete
              id='provinsi'
              fullWidth
              value={provinsi}
              options={region}
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.code === value.code}
              onChange={(event: any, value: any) => {
                if (value) {
                  setProvinsi(value);
                }
              }}
              loading={loadProvinsi}
              onOpen={e => handleSearchProvinsi('')}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Provinsi *'
                  size='small'
                  onChange={e => handleSearchProvinsi(e.target.value)}
                  variant='outlined'
                  error={provinsiMessage !== ''}
                  helperText={provinsiMessage}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>
                        {loadProvinsi && <CircularProgress color='inherit' size={20} />}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    )
                  }}
                />
              )}
            />
          </Grid>

          <Grid item lg={6} sm={6} md={6} xs={6}>
            <Autocomplete
              id='kabupaten'
              fullWidth
              value={kabupaten}
              options={region}
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.code === value.code}
              onChange={(event: any, value: any) => {
                if (value) {
                  setKabupaten(value);
                }
              }}
              loading={loadKabupaten}
              onOpen={e => handleSearchKabupaten(provinsi.code, '')}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  label='Kabupaten *'
                  size='small'
                  onChange={e => handleSearchKabupaten(provinsi.code, e.target.value)}
                  variant='outlined'
                  error={kabupatenMessage !== ''}
                  helperText={kabupatenMessage}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>
                        {loadKabupaten && <CircularProgress color='inherit' size={20} />}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    )
                  }}
                />
              )}
            />
          </Grid>

          <Grid item lg={6} sm={6} md={6} xs={6}>
            <Autocomplete
              id='kecamatan'
              fullWidth
              value={kecamatan}
              options={region}
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.code === value.code}
              onChange={(event: any, value: any) => {
                if (value) {
                  setKecamatan(value);
                }
              }}
              loading={loadKecamatan}
              onOpen={e => handleSearchKecamatan(kabupaten.code, '')}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Kecamatan *'
                  size='small'
                  onChange={e => handleSearchKecamatan(kabupaten.code, e.target.value)}
                  variant='outlined'
                  error={kecamatanMessage !== ''}
                  helperText={kecamatanMessage}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>
                        {loadKecamatan && <CircularProgress color='inherit' size={20} />}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    )
                  }}
                />
              )}
            />
          </Grid>

          <Grid item lg={6} sm={6} md={6} xs={6}>
            <TextField
              id='postalCode'
              required
              fullWidth
              label='Kode Pos '
              value={postalCode}
              onChange={event => setPostalCode(+event.target.value)}
              error={postalCodeMessage !== ''}
              helperText={postalCodeMessage}
            />
          </Grid>

          {partnerType === PartnerType.CUSTOMER && (typeUser === TypeUser.SUPERADMIN || typeUser === TypeUser.ADMIN) && (
            <Grid item lg={6} sm={6} md={6} xs={6}>
              <TextField
                id='remarkAddress'
                required
                fullWidth
                label='Remark Alamat '
                value={remarkAddress}
                onChange={event => setRemarkAddress(event.target.value)}
              />
            </Grid>
          )}
        </Grid>
      </TabPanel>

      <Grid item lg={12} sm={12} md={12} xs={12}>
        <ReactQuill id='notes' value={notes} onChange={(value: any) => setNotes(value)} placeholder='Catatan' />
      </Grid>
      <Grid container direction='row' justify='space-between'>
        <Grid item lg={6} sm={6} md={6} xs={6}></Grid>

        <Grid container item lg={6} sm={6} md={6} xs={6} spacing={3} justify='flex-end'>
          <Grid item>
            <DialogActions>
              <Button color='secondary' disabled={isSubmit} onClick={handleCancel}>
                Batal
              </Button>
              <Button disabled={isSubmit} onClick={handleNextStep}>
                {value === 0 ? 'Selanjutnya' : 'Simpan'}
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PartnerWizard;
