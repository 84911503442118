import React, { FC } from 'react';
import {
  Checkbox,
  ListItemText,
  makeStyles,
  Grid,
  MenuItem,
  Select,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  TableBody,
  TableCell,
  IconButton,
  Table,
  Typography
} from '@material-ui/core';
import {
  HeaderRow,
  StatusPayment,
  TableCellEnd,
  TableCellHead,
  TableCellMiddle,
  TableCellStart,
  TableCustom,
  TableRowCollapse,
  TableRowCustom
} from 'components';
import { Skeleton } from '@material-ui/lab';
import useRouter from 'hooks/useRouter';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
interface Props {
  returnBills: ReturnBill[];
  zones: ZoneModel[];
  zone: number[];
  partnerName: string;
  returnBillNumber: string;
  isLoading: boolean;
  isLoadingItem: boolean;
  openCollapse: boolean;
  indexCollapse: number;
  statusPayment: string;
  setStatusPayment: React.Dispatch<React.SetStateAction<string>>;
  setZone: React.Dispatch<React.SetStateAction<number[]>>;
  setPartnerName: React.Dispatch<React.SetStateAction<string>>;
  setReturnBillNumber: React.Dispatch<React.SetStateAction<string>>;
  handleOpenCollapse: (index: number, id: number) => React.MouseEventHandler;
}
const useStyles = makeStyles((theme: Theme) => ({
  cellTable: {
    width: '20%'
  },
  cellTable1: {
    width: '17%'
  },
  cellTable2: {
    width: '5%'
  },

  cellTable3: {
    width: '13%'
  },
  tableCollapse: {
    width: '100%'
  },
  cellCollapse: {
    border: 'none'
  }
}));
const ReturnBillContent: FC<Props> = ({
  returnBills,
  zone,
  zones,
  setZone,
  isLoading,
  setReturnBillNumber,
  returnBillNumber,
  statusPayment,
  setStatusPayment,
  partnerName,
  handleOpenCollapse,
  setPartnerName,
  isLoadingItem,
  openCollapse,
  indexCollapse
}): JSX.Element => {
  const classes = useStyles();
  const { history } = useRouter();

  const handleLink = (id: number) => history.push(`/tagihan-return/${id}`);
  return (
    <Grid xs={12}>
      <TableContainer>
        <TableCustom>
          <TableHead>
            <HeaderRow
              headers={[
                { label: '#' },
                { id: 'invoiceNumber', label: 'No Invoice', sort: true },
                { id: 'createdAt', label: 'Tgl Invoice', sort: true },
                { id: 'name', label: 'Nama Customer', sort: true },
                { id: 'status', label: 'Status Pembayaran', sort: true },
                { id: 'zone', label: 'RUTE', sort: true, align: 'center' },
                { id: 'totalColly', label: 'Total Item', sort: true },
                { id: 'totalPrice', label: 'Total Tagihan', sort: true },
                { id: 'totalPay', label: 'Total Pembayaran', sort: true }
              ]}
            />
            <TableRow>
              <TableCellHead variant='head' className={classes.cellTable1} colSpan={2}>
                <TextField placeholder='Nomor Invoice' value={returnBillNumber} onChange={event => setReturnBillNumber(event.target.value)} />
              </TableCellHead>
              <TableCellHead variant='head' className={classes.cellTable} />
              <TableCellHead variant='head' className={classes.cellTable1}>
                <TextField placeholder='Nama Customer' value={partnerName} onChange={event => setPartnerName(event.target.value)} />
              </TableCellHead>
              <TableCellHead variant='head' className={classes.cellTable3}>
                <TextField select id='statusPayment' value={statusPayment} onChange={event => setStatusPayment(event.target.value as string)}>
                  <MenuItem selected key={1} value='' disabled>
                    Pilih Pembayaran
                  </MenuItem>
                  <MenuItem key={2} value='PAID'>
                    Lunas
                  </MenuItem>
                  <MenuItem key={3} value='UNPAID'>
                    Belum Lunas
                  </MenuItem>
                </TextField>
              </TableCellHead>
              <TableCellHead variant='head' className={classes.cellTable3}>
                <Select
                  id='demo-mutiple-checkbox'
                  margin='dense'
                  style={{ marginTop: '0.4em' }}
                  multiple
                  variant='outlined'
                  fullWidth
                  value={zone}
                  onChange={e => setZone(e.target.value as number[])}
                  renderValue={selected =>
                    `${zones
                      .filter(value => (selected as number[]).includes(value.id as number))
                      .map(value => value.name)
                      .join(',')
                      .substr(0, 8)}...`
                  }
                >
                  {zones.map(value => (
                    <MenuItem key={value.id} value={value.id}>
                      <Checkbox color='primary' checked={zone.indexOf(value.id) > -1} />
                      <ListItemText primary={value.name} />
                    </MenuItem>
                  ))}
                </Select>
              </TableCellHead>

              <TableCellHead variant='head' className={classes.cellTable} />
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={9} style={{ padding: 0 }}>
                  <Grid xs={12} container>
                    {[...new Array(5)].map((val, key) => (
                      <Grid xs={12}>
                        <Skeleton variant='text' animation='wave' height={60} key={key} />
                      </Grid>
                    ))}
                  </Grid>
                </TableCell>
              </TableRow>
            ) : returnBills.length > 0 ? (
              returnBills.map((returnBill, key) => (
                <>
                  <TableRowCustom key={returnBill.id} style={{ cursor: 'pointer' }}>
                    <TableCellStart>
                      {isLoading ? (
                        <Skeleton variant='text' width={60} height={25} />
                      ) : (
                        <Grid container direction='row' alignItems='center'>
                          <IconButton aria-label='expand row' size='small' onClick={handleOpenCollapse(key, returnBill.id)}>
                            {openCollapse && key === indexCollapse ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                          </IconButton>
                        </Grid>
                      )}
                    </TableCellStart>

                    <TableCellMiddle>
                      <Typography onClick={() => handleLink(returnBill.id)}>#{returnBill.invoiceNumber}</Typography>
                    </TableCellMiddle>
                    <TableCellMiddle>{format(new Date(returnBill.createdAt), 'dd MMM yyyy')}</TableCellMiddle>
                    <TableCellMiddle>{returnBill.partnerName}</TableCellMiddle>
                    <TableCellMiddle align='center'>
                      <StatusPayment
                        status={returnBill.statusPayment}
                        overdue={false}
                        totalPrice={returnBill.totalPrice}
                        totalPay={returnBill.statusPayment === 'PAID' ? returnBill.totalPrice : returnBill.totalPay}
                      />
                    </TableCellMiddle>
                    <TableCellMiddle>{returnBill.zoneName}</TableCellMiddle>
                    <TableCellMiddle>{returnBill.totalItem}</TableCellMiddle>

                    <TableCellMiddle>
                      <NumberFormat value={returnBill.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </TableCellMiddle>

                    <TableCellEnd>
                      <NumberFormat value={returnBill.totalPay} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </TableCellEnd>
                  </TableRowCustom>
                  <TableRowCollapse open={openCollapse && key === indexCollapse && !isLoading} colSpan={9}>
                    <Table className={classes.tableCollapse} size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell>Nama Product</TableCell>
                          <TableCell align='center'>Kuantitas</TableCell>
                          <TableCell align='right'>Harga</TableCell>
                          <TableCell align='right'> Total Harga</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {isLoadingItem ? (
                          <TableRow>
                            <TableCell colSpan={4}>
                              <Skeleton variant='text' width='100%' height={25} />
                            </TableCell>
                          </TableRow>
                        ) : (
                          returnBill.InvoiceItem &&
                          returnBill.InvoiceItem.map((invoiceItem, index) => (
                            <TableRow key={index}>
                              <TableCell className={classes.cellCollapse}>{invoiceItem.productName || '-'}</TableCell>

                              <TableCell align='center' className={classes.cellCollapse}>
                                {invoiceItem.totalItem || '-'}
                              </TableCell>

                              <TableCell align='right' className={classes.cellCollapse}>
                                <NumberFormat value={invoiceItem.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                              </TableCell>
                              <TableCell align='right' className={classes.cellCollapse}>
                                <NumberFormat value={invoiceItem.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableRowCollapse>
                </>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align='center'>
                  Data Tidak Tersedia.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </TableCustom>
      </TableContainer>
    </Grid>
  );
};

export default ReturnBillContent;
