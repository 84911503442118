import React, { FC } from 'react';
import { TableContainer, TableBody, TableHead, TableRow, TableCell, TextField, makeStyles, Checkbox } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { HeaderRow, TableCellHead, TableCustom } from 'components/Table';
import { dummyPurchaseInvoicePayment } from 'utils/dummy';
import BodyRow from './components/BodyRow';

interface Props {
  giroInvoicePayments: PurchaseInvoicePaymentModel[];
  checked: number[];
  isLoadingData: boolean;
  order: 'asc' | 'desc';
  orderBy: string;
  name: string;
  giroBank: string;
  giroNumber: string;
  giroAmount: number;
  invoiceNumber?: string;
  setChecked: React.Dispatch<React.SetStateAction<number[]>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setGiroBank: React.Dispatch<React.SetStateAction<string>>;
  setGiroNumber: React.Dispatch<React.SetStateAction<string>>;
  setGiroAmount: React.Dispatch<React.SetStateAction<number>>;
  setInvoiceNumber: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles({
  cellStart: {
    width: '20%'
  },
  cellTable: {
    width: '17%'
  },
  cellMediumTable: {
    width: '30%'
  }
});

const GiroTable: FC<Props> = props => {
  const classes = useStyles();

  const {
    giroInvoicePayments,
    isLoadingData,
    checked,
    order,
    orderBy,
    name,
    giroBank,
    giroNumber,
    giroAmount,
    invoiceNumber,
    setOrder,
    setOrderBy,
    setChecked,
    setName,
    setGiroBank,
    setGiroNumber,
    setGiroAmount,
    setInvoiceNumber
  } = props;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleCheckAll = () => {
    const newBulkChecked = [...checked];
    const countChecked = newBulkChecked.length;
    const countData = giroInvoicePayments.length;

    if (countChecked != countData) {
      newBulkChecked.splice(0, countChecked);
      giroInvoicePayments.map(giro => {
        if (giro.giroDueDate && new Date(giro.giroDueDate) <= new Date()) {
          newBulkChecked.push(giro.id);
        }

        return;
      });
      setChecked(newBulkChecked);
    } else {
      setChecked([]);
    }
  };

  const handleIndividualCheck = (id: number) => {
    const newChecked = [...checked];
    const countElement = newChecked.filter(newCheckedValue => newCheckedValue === id).length;

    if (countElement === 0) {
      newChecked.push(id);
    } else {
      const checkedFilterIndex = newChecked.map(newCheckedValue => newCheckedValue).indexOf(id);
      newChecked.splice(checkedFilterIndex, 1);
    }
    setChecked(newChecked);
  };

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={[
              { label: '#', pR: '10px', pT: '7px', verticalAlign: 'top' },
              { id: 'giroNumber', label: 'No Giro', sort: true },
              { id: 'name', label: 'Nama Customer', sort: true },
              { id: 'giroDueDate', label: 'Jatuh Tempo Giro', sort: true },
              { id: 'giroAmount', label: 'Nominal Giro', sort: true },
              { id: 'giroBank', label: 'Bank Penerbit', sort: true },
              { id: 'invoiceNumber', label: 'No Invoice & Pembayaran', sort: true }
            ]}
          />
          <TableRow>
            <TableCellHead variant='head' className={classes.cellStart}>
              <Checkbox
                key={0}
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                edge='start'
                color='primary'
                tabIndex={-1}
                disableRipple
                checked={checked.length > 0}
                onChange={handleCheckAll}
              />
            </TableCellHead>
            <TableCellHead variant='head' className={classes.cellTable}>
              <TextField id='giroNumber' placeholder='No Giro' value={giroNumber} onChange={event => setGiroNumber(event.target.value)} />
            </TableCellHead>
            <TableCellHead variant='head' className={classes.cellTable}>
              <TextField placeholder='Nama Customer' id='name' value={name} onChange={event => setName(event.target.value)} />
            </TableCellHead>
            <TableCellHead variant='head' className={classes.cellMediumTable}></TableCellHead>
            <TableCellHead variant='head' className={classes.cellStart}>
              <TextField id='giroAmount' placeholder='Nominal Giro' value={giroAmount} onChange={event => setGiroAmount(+event.target.value)} />
            </TableCellHead>
            <TableCellHead variant='head' className={classes.cellStart}>
              <TextField id='giroBank' placeholder='Bank Penerbit' value={giroBank} onChange={event => setGiroBank(event.target.value)} />
            </TableCellHead>
            <TableCellHead variant='head' className={classes.cellTable}>
              <TextField
                placeholder='No. Invoice'
                id='invoiceNumber'
                value={invoiceNumber}
                onChange={event => setInvoiceNumber(event.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </TableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow
                key={index + 1}
                isLoading={isLoadingData}
                invoicePayment={dummyPurchaseInvoicePayment}
                checked={checked}
                handleIndividualCheck={handleIndividualCheck}
              />
            ))
          ) : giroInvoicePayments && giroInvoicePayments.length > 0 ? (
            giroInvoicePayments.map((value, index) => (
              <BodyRow
                key={index + 1}
                isLoading={isLoadingData}
                invoicePayment={value}
                checked={checked}
                handleIndividualCheck={handleIndividualCheck}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={7} align='center'>
                Data Tidak Tersedia.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default GiroTable;
