import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { Grid, makeStyles, Typography, Theme, Button, IconButton, CircularProgress, Popover, MenuItem, Menu } from '@material-ui/core';
import {
  Add,
  PostAddRounded,
  AddCircleRounded,
  CheckRounded,
  CloseRounded,
  CreateOutlined,
  DeleteForever,
  ExpandLessOutlined,
  ExpandMore,
  InfoOutlined,
  InfoRounded,
  MoreVertRounded,
  PhotoSizeSelectActualOutlined,
  DeleteOutlineOutlined
} from '@material-ui/icons';
import useRouter from 'hooks/useRouter';
import EmptyIcon from 'images/icon/EmptyIcon.svg';
import CustomizedCheckbox from 'components/CustomizedCheckbox';
import ModalProductName from './ModalProductName';
import axios from 'axios';
import {
  CHANGE_STATUS_SALES_ORDER_ITEM,
  CREATE_COLLY,
  GET_COLLY_DETAILS,
  INVOICE_BASE_URL,
  INVOICE_CHECK,
  INVOICE_COLLY,
  ORDER_ITEM_BASE_URL
} from 'constants/url';
import CollyCreate from './CollyCreate';
import { Skeleton } from '@material-ui/lab';
import CollyUpdate from './CollyUpdate';
import SalesOrderStatus from 'typings/enum/SalesOrderStatus';
import ModalSuccess from './ModalSuccess';
import CollyModalSuccess from './CollyModalSuccess';
import CollyCombine from './CollyCombine';
import { GREY, YELLOW_MOBILE } from 'constants/colors';
import CollyCombineDetails from './CollyCombineDetails';
import ModalMergeColly from './ModalMergeColly';
import CreateCollyMerge from './CreateCollyMerge';
import ListItemMerge from './ListItemMerge';
import CollyModalNotif from './CollyModalNotif';
import FlexBox from 'components/FlexBox';
import clsx from 'clsx';
import Loading from 'components/Loading';

const useStyles = makeStyles((theme: Theme) => ({
  containerEmpty: {
    minHeight: '250px',
    padding: '0 1em',
    position: 'relative'
  },
  iconSize: {
    fontSize: '5rem',
    color: 'lightblue'
  },
  textSecondary: {
    fontSize: '0.8rem',
    color: 'grey'
  },
  bottonOption: {
    color: '#616161',
    position: 'relative',
    left: 3
  },
  itemBody: {
    display: 'flex',
    gap: '1.4em',
    padding: '0.6em 1em'
  },
  button: {
    borderRadius: '1.4em'
  },
  containerButton: {
    width: '100%',
    padding: '1em'
  },
  buttonContainerMobile: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 5,
    backgroundColor: '#fff',
    padding: '10px 10px',
    boxShadow: '0px 0px 17px rgba(0, 0, 0, 0.08)'
  },
  containerItem: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    display: 'flex',
    alignItems: 'center',
    padding: '0 1rem 0.3rem'
  },
  containerItemActive: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(2, 169, 234, 0.12)',
    padding: '0 1rem 0.3rem'
  },
  noImage: {
    backgroundColor: GREY,
    height: '60px',
    width: '60px',
    borderRadius: '.5em',
    display: 'grid',
    placeItems: 'center'
  },
  textErrorContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.3rem',
    fontWeight: 500,
    fontSize: '14px'
  },
  containerButtom: {
    display: 'flex',
    marginTop: '1em',
    flexDirection: 'column'
  },
  alertWarning: {
    border: '1px solid #FFBF00',
    backgroundColor: 'rgba(255, 191, 0, 0.12)',
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
    borderRadius: '5px'
  },
  wrapContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 1em',
    gap: '1rem',
    marginBottom: '1em'
  },
  textMobile: {
    fontSize: '14px',
    fontWeight: 500
  },
  buttonActionTop: {
    display: 'flex',
    gap: '1em'
  },
  wrapperLoading: {
    minHeight: '30vh'
  },
  buttonSize: {
    fontSize: '.7rem'
  }
}));

type collyName = {
  id: number;
  name: string;
};

interface Props {
  idSalesOrder: number;
  salesOrderItem: SalesOrderItemModel[];
  collyResource: typeCollyModel[];
  getWareHouse: number;
  loading: boolean;
  openModalMerge: boolean;
  invoiceResource: { id: number; invoiceNumber: string }[];
  setCollyResource: React.Dispatch<React.SetStateAction<typeCollyModel[]>>;
  setOpenModalMerge: React.Dispatch<React.SetStateAction<boolean>>;
}

const CollyList: FC<Props> = props => {
  const {
    idSalesOrder,
    salesOrderItem,
    collyResource,
    setCollyResource,
    setOpenModalMerge,
    loading,
    getWareHouse,
    openModalMerge,
    invoiceResource
  } = props;
  const classes = useStyles();
  const { history } = useRouter();
  const [hasExpand, setHasExpand] = useState<boolean>(true);
  const [collyResourceBefore, setCollyResourceBefore] = useState<typeCollyModel[]>([]);
  const [loadingCollyResourceBefore, setLoadingCollyResourceBefore] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<string>('');

  const [openModalNotif, setOpenModalNotif] = useState<boolean>(false);
  const [isCollyMerge, setIsCollyMerge] = useState<boolean>(false);

  const [hasInputNameColly, setHasInputNameColly] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [clicked, setClicked] = useState<number>(0);

  const [nameCollyTemp, setNameCollyTemp] = useState<collyName[]>([
    {
      id: 0,
      name: ''
    }
  ]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModalUpdate, setOpenModalUpdate] = useState<boolean>(false);
  const [openModalUpdateMerge, setOpenModalUpdateMerge] = useState<boolean>(false);
  const [openModalCombine, setOpenModalCombine] = useState<boolean>(false);
  const [openListModalMerge, setOpenListModalMerge] = useState<boolean>(false);
  const [listModalMergeId, setListModalMergeId] = useState<number>(0);
  const [openModalCombineDetails, setOpenModalCombineDetails] = useState<boolean>(false);
  const [hasAddColly, setHasAddColly] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [isAddColly, setIsAddColly] = useState<boolean>(false);

  const [idColly, setIdColly] = useState(0);
  const [idBagColly, setBagIdColly] = useState(0);
  const [salesOrderItemId, setSalesOrderItemId] = useState(0);
  const open = Boolean(anchorEl);
  const handleSubmitColly = (value: typeCollyModel) => {
    if (collyResource.some(val => val.name === value.name)) {
      const colly = [...collyResource];
      const newColly = collyResource
        .filter(val => val.name === value.name)
        .map(val => ({
          ...val,
          BagItem: val.BagItem.some(val => value.BagItem.map(val => val.SalesOrderItemId).includes(val.SalesOrderItemId))
            ? val.BagItem.map((val, key) => ({
                ...val,
                totalItem: val.totalItem + value.BagItem.filter(value => value.SalesOrderItemId === val.SalesOrderItemId)[0].totalItem
              }))
            : val.BagItem.concat(value.BagItem)
        }));

      const index = collyResource.findIndex(value => value.id === newColly[0].id);
      colly[index].BagItem = newColly[0].BagItem;
      setCollyResource(colly);
    } else {
      setCollyResource([...collyResource, value]);
    }
    setHasAddColly(false);
  };

  const handleMergeColly = (remark: string) => {
    const getColly = collyResource.find(value => value.id === idColly);
    const getBagColly = getColly && getColly.BagItem.find(value => value.id === idBagColly);
    if (getColly && getBagColly) {
      let collyTempFinal: typeCollyModel[] = [];
      nameCollyTemp.map((val, key) => {
        const bagItemTemp = [
          {
            id: key + Date.now(),
            SalesOrderItemId: getBagColly.SalesOrderItemId,
            productName: getBagColly.productName,
            remark: remark,
            totalItem: getBagColly.totalItem,
            productImage: getBagColly.productImage
          }
        ];
        const collyItems = {
          id: key + Date.now(),
          SalesOrderId: getColly.SalesOrderId,
          name: val.name,
          packName: '',
          packCode: 'all',
          BagItem: bagItemTemp,
          totalItem: 0,
          checked: false
        };
        collyTempFinal.push(collyItems);
      });
      getBagColly.remark = remark;
      setCollyResource([...collyResource, ...collyTempFinal]);
      setOpenModalCombine(false);
      setNameCollyTemp([
        {
          id: 0,
          name: ''
        }
      ]);
    }
  };

  const handleOpenNotif = () => {
    setOpenModalNotif(true);
  };
  const handleCloseNotif = () => {
    setOpenModalNotif(false);
  };
  const getTotalQty = (id: number) => {
    const total = collyResource.filter(val => val.id === id).map(val => val.BagItem.map(val => val.totalItem).reduce((a, b) => a + b, 0));
    return Number(total);
  };

  const handleOpenModalItem = (id: number) => {
    setOpenListModalMerge(true);
    setListModalMergeId(id);
  };

  const handleSaveMerge = (
    BagItem: { id: number; SalesOrderItemId: number; productName?: string; productImage?: string; totalItem: number; remark: string }[]
  ) => {
    const getCollyBefore = collyResourceBefore.filter(val => val.id === listModalMergeId)[0];
    const getCollyBeforeFinal = {
      ...getCollyBefore,
      SalesOrderId: idSalesOrder,
      InvoiceIdReference: typeof invoice === 'number' ? [invoice] : invoice.split(','),
      BagItem: BagItem,
      packCode: 'all'
    };

    if (collyResource.some(val => val.id === listModalMergeId)) {
      const collyResourceTemp = collyResource
        .filter(val => val.id === listModalMergeId)
        .map(val => ({ ...val, BagItem: val.BagItem.concat(BagItem) }));
      const collyResourceBefore = collyResource.filter(val => val.id !== listModalMergeId);

      setCollyResource([...collyResourceBefore, ...collyResourceTemp]);
    } else {
      setCollyResource([...collyResource, getCollyBeforeFinal]);
    }

    const newCollyItem = collyResourceBefore.map(value =>
      value.id === listModalMergeId ? { ...value, BagItem: value.BagItem.concat(BagItem) } : { ...value }
    );
    setCollyResourceBefore([...newCollyItem]);
    setOpenListModalMerge(false);
  };

  const handleUpdateBefore = (totalItem: number) => {
    const indexBagItemBefore = collyResourceBefore.find(value => value.id === idColly)!.BagItem.findIndex(value => value.id === idBagColly);
    const getIdItemBefore = collyResourceBefore.find(value => value.id === idColly)!.BagItem.find(val => val.id === idBagColly);
    const indexBagItem = collyResource.find(value => value.id === idColly)!.BagItem.findIndex(value => value.id === idBagColly);
    const getIdItem = collyResource.find(value => value.id === idColly)!.BagItem.find(val => val.id === idBagColly);

    if (totalItem < 1) {
      const newDataBefore = collyResourceBefore.map(value => ({ ...value, BagItem: value.BagItem.filter(value => value.id !== idBagColly) }));
      const newData = collyResource.map(value => ({ ...value, BagItem: value.BagItem.filter(value => value.id !== idBagColly) }));

      if (newDataBefore.filter(value => value.id === idColly)[0].BagItem.length) {
        setCollyResourceBefore(newDataBefore);
      } else {
        setCollyResourceBefore(newDataBefore.filter(value => value.id !== idColly));
      }
      if (newData.filter(value => value.id === idColly)[0].BagItem.length) {
        setCollyResource(newData);
      } else {
        setCollyResource(newData.filter(value => value.id !== idColly));
      }
    } else {
      const newDataBefore = [...collyResourceBefore];
      const newData = [...collyResource];
      newDataBefore.find(value => value.id === idColly)!.BagItem[indexBagItemBefore].totalItem = totalItem;
      newData.find(value => value.id === idColly)!.BagItem[indexBagItem].totalItem = totalItem;
      setCollyResourceBefore(newDataBefore);
      setCollyResource(newData);
    }

    const index = salesOrderItem.findIndex(val => val.id === (getIdItemBefore && getIdItemBefore.SalesOrderItemId));
    salesOrderItem[index].totalInBag = totalItem;
    setOpenModalUpdateMerge(false);
  };

  const handleSaveCollyBefore = async () => {
    setLoadingData(true);
    try {
      const collyFinal = collyResource.map(value => ({ ...value, WareHouseId: getWareHouse, items: value.BagItem.map(value => value) }));
      await axios.post(CREATE_COLLY, collyFinal);
      salesOrderItem
        .filter(value => value.status !== SalesOrderStatus.REJECT)
        .map(value => (salesOrderItem.find(val => val.id === value.id)!.status = SalesOrderStatus.CHECKING));
      setLoadingData(false);
      setIsAddColly(true);
    } catch (error) {
      setCollyResourceBefore([]);
      console.log(error);
    }
  };

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const index = collyResource.findIndex(value => value.id === Number(event.target.value));
    const newData = [...collyResource];
    newData[index].checked = event.target.checked;
    setCollyResource(newData);
  };
  const handleCheckedAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newData = collyResource.map(val => ({ ...val, checked: event.target.checked }));
    setCollyResource(newData);
  };

  const handleDeletePackName = (id: number) => {
    const newData = collyResource.map(val => {
      if (id === val.id) {
        return { ...val, packName: '' };
      } else {
        return val;
      }
    });
    setCollyResource(newData);
  };

  const handleUpdate = (totalItem: number) => {
    const indexBagItem = collyResource.find(value => value.id === idColly)!.BagItem.findIndex(value => value.id === idBagColly);
    const getIdItem = collyResource.find(value => value.id === idColly)!.BagItem.find(val => val.id === idBagColly);

    if (totalItem < 1) {
      const newData = collyResource.map(value => ({ ...value, BagItem: value.BagItem.filter(value => value.id !== idBagColly) }));
      if (newData.filter(value => value.id === idColly)[0].BagItem.length) {
        setCollyResource(newData);
      } else {
        setCollyResource(newData.filter(value => value.id !== idColly));
      }
    } else {
      const newData = [...collyResource];
      newData.find(value => value.id === idColly)!.BagItem[indexBagItem].totalItem = totalItem;
      setCollyResource(newData);
    }

    const index = salesOrderItem.findIndex(val => val.id === (getIdItem && getIdItem.SalesOrderItemId));
    salesOrderItem[index].totalInBag = totalItem;
    setOpenModalUpdate(false);
  };

  const getTotalBag = (id: number) => {
    const total = collyResource.map(value =>
      value.BagItem.filter(value => value.SalesOrderItemId === id)
        .map(value => value.totalItem)
        .reduce((a, b) => a + b, 0)
    );
    return Number(total.reduce((a, b) => a + b, 0));
  };
  const isSubmitColly = salesOrderItem.some(value => value.status === SalesOrderStatus.PACKING);
  const isSubmitInvoice = salesOrderItem.some(value => value.status === SalesOrderStatus.CHECKING || value.status === SalesOrderStatus.HAS_INVOICE);
  const disableAfterSave = salesOrderItem.some(value => value.status === SalesOrderStatus.HAS_INVOICE);

  const disabled = collyResource.some(value => value.packName === '');
  const disabledSecond = salesOrderItem
    .filter(value => value.status !== SalesOrderStatus.REJECT)
    .some(value => getTotalBag(value.id) < value.totalItem);

  const handleSubmit = async () => {
    setLoadingData(true);
    try {
      const collyFinal = collyResource.map(value => ({ ...value, WareHouseId: getWareHouse, items: value.BagItem.map(value => value) }));
      await axios.post(CREATE_COLLY, collyFinal);
      salesOrderItem
        .filter(value => value.status !== SalesOrderStatus.REJECT)
        .map(value => (salesOrderItem.find(val => val.id === value.id)!.status = SalesOrderStatus.CHECKING));
      setLoadingData(false);
      setIsAddColly(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOption = () => {
    setAnchorEl(null);
  };
  const handleOpenMerge = () => {
    setIsCollyMerge(true);
    setOpenModalMerge(false);
  };

  const getCollyBefore = useCallback(async () => {
    setLoadingCollyResourceBefore(true);
    try {
      const { data } = await axios.get(INVOICE_COLLY(invoice));

      setCollyResourceBefore(data);
      setLoadingCollyResourceBefore(false);
    } catch (error) {
      console.log(error);
    }
  }, [invoice]);

  useEffect(() => {
    if (invoice === '') return;
    getCollyBefore();
  }, [getCollyBefore]);

  const handleSubmitConvet = async () => {
    setLoadingData(true);

    const getItems = salesOrderItem
      .filter(value => value.totalItem > 0)
      .map((value: SalesOrderItemModel) => ({
        id: 0,
        SalesOrderItemId: value.id,
        ProductId: value.ProductId,
        typeUnit: value.typeUnit,
        totalItem: value.totalItem,
        price: value.price,
        upPrice: value.upPrice,
        discountUnit: value.discountUnit
      }));

    try {
      const { data } = await axios.post(INVOICE_BASE_URL, {
        SalesOrderId: idSalesOrder,
        WareHouseId: getWareHouse,
        items: getItems
      });

      setLoadingData(false);
      setSuccess(true);
    } catch (error) {
      console.log(error);
    }
  };

  return hasAddColly ? (
    <CollyCreate
      setHasAddColly={setHasAddColly}
      salesOrderItem={salesOrderItem}
      salesOrderId={idSalesOrder}
      collyResource={collyResource}
      handleSubmitColly={handleSubmitColly}
    />
  ) : loading ? (
    <Grid xs={12} className={classes.wrapperLoading} container justify='center' alignItems='center'>
      <Grid container xs={12} direction='column' justify='center' alignItems='center'>
        <Loading size='small' />
        <Typography color='textSecondary'>Sedang membuka...</Typography>
      </Grid>
    </Grid>
  ) : (
    <Grid xs={12}>
      {collyResource.length < 1 ? (
        <Grid xs={12} container direction='column' alignItems='center' justify='center' className={classes.containerEmpty}>
          <img src={EmptyIcon} alt='Data Kosong' width='65px' height='65px' />

          <Typography variant='h6' align='center' style={{ marginTop: '0.4em' }}>
            Wah, saat ini masih belum ada koli
          </Typography>
          <Typography align='center' className={classes.textSecondary}>
            Tambahkan koli dibawah ini, lalu isi item produknya.
          </Typography>
          <Grid xs={12} className={classes.containerButton}>
            <Button variant='outlined' color='primary' fullWidth className={classes.button} onClick={() => setHasAddColly(true)} size='small'>
              <Add color='primary' />
              Tambah Koli
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid xs={12} className={classes.containerButtom}>
          <Grid xs={12} className={classes.wrapContainer}>
            {hasInputNameColly ? (
              <Grid xs={12} className={classes.buttonActionTop}>
                <Grid xs={6}>
                  <Button
                    variant='outlined'
                    color='primary'
                    fullWidth
                    className={clsx(classes.button, classes.buttonSize)}
                    size='small'
                    startIcon={<CloseRounded />}
                    onClick={() => setHasInputNameColly(false)}
                  >
                    Batalkan
                  </Button>
                </Grid>
                <Grid xs={6}>
                  <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    className={clsx(classes.button, classes.buttonSize)}
                    size='small'
                    onClick={() => setOpenModal(true)}
                    startIcon={<CheckRounded />}
                  >
                    Lanjutkan
                  </Button>
                </Grid>
              </Grid>
            ) : (
              disabled && (
                <Grid xs={12}>
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<PostAddRounded />}
                    className={clsx(classes.button, classes.buttonSize)}
                    size='small'
                    fullWidth
                    onClick={() => setHasInputNameColly(true)}
                  >
                    Tambah Nama Barang
                  </Button>
                </Grid>
              )
            )}
            <Grid xs={12} className={classes.alertWarning}>
              <InfoOutlined style={{ color: '#ff9800' }} />
              {!hasInputNameColly ? (
                <Grid item>
                  <Typography className={classes.textMobile}>Input Nama Barang Sekaligus</Typography>
                  <Typography className={classes.textSecondary}>Input nama barang untuk banyak koli sekaligus melalui tombol diatas.</Typography>
                </Grid>
              ) : (
                <Grid item>
                  <Typography className={classes.textMobile}>Centang Koli dan Input Nama Barang</Typography>
                  <Typography className={classes.textSecondary}>Input nama barang untuk banyak koli sekaligus melalui tombol diatas .</Typography>
                </Grid>
              )}
            </Grid>
            {hasInputNameColly && (
              <Grid xs={12} container alignItems='center'>
                <CustomizedCheckbox onChange={handleCheckedAll} value={12} checked={collyResource.every(val => val.checked)} />
                <Typography variant='h6'>Centang Semua Koli</Typography>
              </Grid>
            )}
          </Grid>
          {collyResource.map((val, key) => (
            <Fragment key={key}>
              <Grid xs={12} className={key === clicked ? classes.containerItemActive : classes.containerItem}>
                <Grid item xs={10} container alignItems='center'>
                  {hasInputNameColly && val.packName === '' && (
                    <Grid item>
                      <CustomizedCheckbox onChange={handleChecked} value={val.id} checked={val.checked || false} />
                    </Grid>
                  )}
                  <Grid item>
                    <Typography variant='h6'>{val.name}</Typography>
                    {val.packName !== '' ? (
                      <FlexBox columnGap={0.5} container xs={12} alignItems='center'>
                        <DeleteOutlineOutlined onClick={() => handleDeletePackName(val.id)} color='error' fontSize='small' />
                        <Typography className={classes.textMobile}>{val.packName}</Typography>
                      </FlexBox>
                    ) : (
                      <Typography color='error' className={classes.textErrorContainer}>
                        <InfoOutlined fontSize='small' />
                        Belum ada nama barang
                      </Typography>
                    )}
                    <Typography className={classes.textSecondary}>Total List Item: {val.BagItem.length}</Typography>
                    <Typography className={classes.textSecondary}>Total Pengepakan: {getTotalQty(val.id)}</Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={2} justify='flex-end'>
                  {hasExpand && key === clicked ? (
                    <ExpandLessOutlined
                      fontSize='small'
                      style={{ color: '#616161' }}
                      onClick={() => {
                        setHasExpand(false);
                        setClicked(key);
                      }}
                    />
                  ) : (
                    <ExpandMore
                      fontSize='small'
                      style={{ color: '#616161' }}
                      onClick={() => {
                        setHasExpand(true);
                        setClicked(key);
                      }}
                    />
                  )}
                </Grid>
              </Grid>

              {hasExpand &&
                key === clicked &&
                val.BagItem.map((value, index) => (
                  <Grid xs={12} className={classes.itemBody} key={index}>
                    <Grid xs={2}>
                      {value.productImage ? (
                        <img
                          src={value.productImage}
                          alt='gambar produk'
                          width='60px'
                          height='60px'
                          style={{ borderRadius: '0.3rem', border: '1px solid rgba(0, 0, 0, 0.08)' }}
                        />
                      ) : (
                        <Grid className={classes.noImage}>
                          <PhotoSizeSelectActualOutlined />
                        </Grid>
                      )}
                    </Grid>
                    <Grid xs={10}>
                      <Typography className={classes.textMobile} style={{ position: 'relative' }}>
                        {value.productName}
                        {value.isMerge ? (
                          <InfoRounded
                            style={{ position: 'absolute', bottom: -1, color: YELLOW_MOBILE, marginLeft: '0.2em' }}
                            onClick={handleOpenNotif}
                          />
                        ) : (
                          value.remark !== '' && (
                            <InfoRounded
                              style={{ position: 'absolute', bottom: -1, color: YELLOW_MOBILE, marginLeft: '0.2em' }}
                              onClick={() => {
                                setOpenModalCombineDetails(true);
                                setIdColly(val.id);
                                setBagIdColly(value.id);
                                setSalesOrderItemId(value.SalesOrderItemId);
                              }}
                            />
                          )
                        )}
                      </Typography>
                      <Typography className={classes.textSecondary}>Total Qty Pengepakan: {value.totalItem} </Typography>
                    </Grid>
                    <Grid xs={2} container justify='flex-end' alignItems='flex-start'>
                      {disabled && (
                        <>
                          <IconButton size='small' onClick={handleOpenOption} className={classes.bottonOption}>
                            <MoreVertRounded
                              onClick={() => {
                                setIdColly(val.id);
                                setBagIdColly(value.id);
                              }}
                            />
                          </IconButton>
                          <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleCloseOption}>
                            <MenuItem
                              key={0}
                              onClick={() => {
                                handleCloseOption();
                                setOpenModalUpdate(true);
                              }}
                            >
                              Edit Quantity Koli
                            </MenuItem>
                            <MenuItem
                              key={1}
                              onClick={() => {
                                handleCloseOption();
                                setOpenModalCombine(true);
                              }}
                            >
                              Pindah sebagian ke koli lain
                            </MenuItem>
                          </Menu>
                        </>
                      )}
                    </Grid>
                  </Grid>
                ))}
            </Fragment>
          ))}

          {!hasInputNameColly && disabledSecond && (
            <Grid xs={12} className={classes.containerButton}>
              <Button variant='outlined' color='primary' fullWidth className={classes.button} onClick={() => setHasAddColly(true)} size='small'>
                <Add color='primary' />
                Tambah Koli Lainnya
              </Button>
            </Grid>
          )}
        </Grid>
      )}

      <ModalProductName
        openModal={openModal}
        setOpenModal={setOpenModal}
        collyResource={collyResource}
        setCollyResource={setCollyResource}
        setHasInputNameColly={setHasInputNameColly}
      />
      <ModalSuccess open={isSuccess} />
      <ModalMergeColly open={openModalMerge} setOpen={setOpenModalMerge} handleOpen={handleOpenMerge} />
      <CreateCollyMerge
        open={isCollyMerge}
        setOpen={setIsCollyMerge}
        invoiceResource={invoiceResource}
        handleOpenModalItem={handleOpenModalItem}
        loadingData={loadingData}
        setOpenModalUpdateMerge={setOpenModalUpdateMerge}
        setIdColly={setIdColly}
        setBagIdColly={setBagIdColly}
        collyResourceBefore={collyResourceBefore}
        setInvoice={setInvoice}
        handleOpenNotif={handleOpenNotif}
        isDisabled={disabledSecond || disabled || collyResourceBefore.length < 1 || loadingData}
        isLoading={loadingCollyResourceBefore}
        handleSaveColly={handleSaveCollyBefore}
      />
      <CollyModalSuccess open={isAddColly} setOpen={setIsAddColly} setIsCollyMerge={setIsCollyMerge} />

      <CollyUpdate
        openModalUpdate={openModalUpdate}
        setOpenModalUpdate={setOpenModalUpdate}
        collyResource={collyResource}
        handleUpdate={handleUpdate}
        idColly={idColly}
        idBagColly={idBagColly}
      />

      <CollyUpdate
        openModalUpdate={openModalUpdateMerge}
        setOpenModalUpdate={setOpenModalUpdateMerge}
        collyResource={collyResourceBefore}
        handleUpdate={handleUpdateBefore}
        idColly={idColly}
        idBagColly={idBagColly}
      />
      <ListItemMerge
        open={openListModalMerge}
        setOpen={setOpenListModalMerge}
        salesOrderItem={salesOrderItem}
        collyResourceBefore={collyResourceBefore}
        handleSaveMerge={handleSaveMerge}
      />
      <CollyCombine
        open={openModalCombine}
        setOpen={setOpenModalCombine}
        idColly={idColly}
        idBagColly={idBagColly}
        collyResource={collyResource}
        handleMergeColly={handleMergeColly}
        setNameCollyTemp={setNameCollyTemp}
        nameCollyTemp={nameCollyTemp}
      />
      <CollyCombineDetails
        open={openModalCombineDetails}
        setOpen={setOpenModalCombineDetails}
        idColly={idColly}
        idBagColly={idBagColly}
        salesOrderItemId={salesOrderItemId}
        collyResource={collyResource}
      />

      <CollyModalNotif open={openModalNotif} handleCloseModal={handleCloseNotif} />

      <Grid className={classes.buttonContainerMobile}>
        {isSubmitColly && !isSubmitInvoice && (
          <Button
            color='primary'
            style={{ borderRadius: '20px' }}
            fullWidth
            onClick={() => handleSubmit()}
            disabled={disabledSecond || disabled || collyResource.length < 1 || loadingData}
          >
            {loadingData ? <CircularProgress size={20} color='primary' /> : 'LANJUTKAN '}
          </Button>
        )}
        {isSubmitInvoice && (
          <Button
            color='primary'
            style={{ borderRadius: '20px' }}
            disabled={disableAfterSave || loadingData}
            fullWidth
            onClick={() => handleSubmitConvet()}
          >
            {loadingData && <CircularProgress size={20} color='primary' />}
            {!loadingData && 'Konfirmasi ke Invoice'}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default CollyList;
