import React, { FC, Fragment, useEffect, useState } from 'react';
import { Grid, makeStyles, Theme, Typography, Tabs, Tab, Checkbox, FormControlLabel, Divider } from '@material-ui/core';
import ListProduct from './ListProduct';
import NumberFormat from 'react-number-format';
import { BLACK, WHITE, YELLOW_MOBILE, GREEN_MOBILE, PRIMARY_MOBILE } from 'constants/colors';
import { TabPanel, a11yProps } from 'components';
import ItemStatus from 'typings/enum/ItemStatus';
import { DeleteForeverOutlined, ExpandMore } from '@material-ui/icons';
import TypeUser from 'typings/enum/TypeUser';
import SalesOrderStatus from 'typings/enum/SalesOrderStatus';
import CollyList from './CollyPage/CollyList';
import { GET_COLLY_DETAILS, INVOICE_CHECK } from 'constants/url';
import axios from 'axios';
import CollyTemp from './CollyPage/CollyTemp';
import CityAddress from 'typings/enum/CityAddress';
import CollyListProduct from './CollyPage/CollyListProduct';

interface Props {
  idSalesOrder: number;
  salesOrder: SalesOrderModel;
  company: CompanyModel;
  isLoadingData: boolean;
  salesOrderItem: SalesOrderItemModel[];
  value: number;
  addItemAction: (value: ItemAction) => void;
  tempCollyFinalUpdate: (id: number) => void;
  isChange: boolean;
  isAccess: string;
  userName: string;
  handleAcceptItem: (id: number) => void;
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setHasOpenModal: React.Dispatch<React.SetStateAction<number>>;
  hasInvoice: boolean;
  tempItemColly: (data: typeColly) => void;
  deleteColly: (id: number) => void;
  handleRejectItem: (id: number) => void;
  showComponent: boolean;
  rejectOrder: ItemAction[];
  isChangeSubmit: boolean;
  isConvertAllowed: boolean;
  getWareHouse: number;
  consistenMessage: string;
  currentWareHouse: string;
  totalWarehouse: { name: string; total: number }[];
  handleOpenImage: (imagePath: string) => void;
  currentCheckWarehouse: string;
  anchorEl: any;
  buttonGroup: React.RefObject<HTMLDivElement>;
  totalApprove: number;
  setCurrentCheckWarehouse: React.Dispatch<React.SetStateAction<string>>;
  isValid: boolean;
  indexTooltip: number;
  openTooltip: boolean;
  handleOpenTooltip: (index: number) => React.MouseEventHandler;
  handleCloseTooltip: () => void;
  handleChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  handleConfirm: (index: number) => React.MouseEventHandler;
  handleReject: (index: number) => React.MouseEventHandler;
  collyFinal: typeCollyFinal;
  handleReset: () => void;
  openAlertPrice: boolean;
  handletRequestEdit: (id: number, productId: number, totalQty: number, remark: string, remarkCode: string) => void;
  indexAlertPrice: number;
  handleOpenAlertPrice: (index: number) => React.MouseEventHandler;
  handleCloseAlertPrice: () => void;
  setGetIdClick: React.Dispatch<React.SetStateAction<number>>;
  openAlertDiscount: boolean;
  indexAlertDiscount: number;
  handleOpenAlertDiscount: (index: number) => React.MouseEventHandler;
  handleCloseAlertDiscount: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  cellSummaryRight: {
    border: 'none',
    padding: 4,
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontStyle: 'normal',
    color: BLACK
  },

  cellSummaryLeft: {
    width: '26%',
    border: 'none',
    padding: 4,
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal',
    color: BLACK
  },
  cellInfoRight: {
    padding: '2px 0px 2px 2px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  cellInfoLeft: {
    padding: '2px 0px 2px 0px',
    border: 'none',
    fontSize: '0.8rem',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  cellFinalRight: {},
  cellFinalLeft: {},
  spaceTop: {
    marginTop: 10
  },
  cellFinalText: {
    marginRight: 105,
    marginTop: 10,
    paddingTop: 10
  },
  cellFinalRightText: {
    marginRight: 65,
    marginBottom: 10,
    paddingBottom: 10
  },
  paper: {
    border: '1px solid #ccc',
    padding: 2,
    backgroundColor: WHITE,
    marginTop: 4,
    borderRadius: 8
  },
  bigger: {
    backgroundColor: '#ffb400',
    color: WHITE,
    transform: 'scale(1.5) perspective(1px)'
  },
  cellOrderRightUnderline: {
    padding: '2px 0px 2px 2px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal',
    color: BLACK,
    textDecorationLine: 'underline',
    cursor: 'pointer'
  },
  refresh: {
    backgroundColor: GREEN_MOBILE,
    color: WHITE,
    fontSize: '1px',
    width: '10px',
    '&:hover': {
      backgroundColor: GREEN_MOBILE
    }
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: ' none'
    }
  },
  tabContainerMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  spacing: {
    margin: '0.5em 0'
  },

  mobileContainer: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
      '& > .MuiGrid-item': {
        padding: 0
      }
    }
  },

  desktop: {
    [theme.breakpoints.down('md')]: {
      display: ' none'
    }
  },
  fontMobile: {
    fontSize: 0.875 + 'rem',
    color: BLACK,
    lineHeight: 1.4 + 'rem'
  },
  titleSizeMobile: {
    fontSize: 1 + 'rem',
    color: BLACK,
    lineHeight: 1.5 + 'rem',
    fontWeight: 500,
    paddingBottom: '0.4em'
  },

  titleContainer: {
    padding: '10px'
  },

  containerPrice: {
    padding: '0 10px 10px'
  },
  optionsContainer: {
    padding: '0 6px',
    backgroundColor: '#F7F9FC'
  },
  stickyCotainer: {
    position: 'sticky',
    top: '-140px',
    zIndex: 1,
    backgroundColor: WHITE
  },
  fontSizeMobile: {
    fontSize: 0.875 + 'rem',

    lineHeight: 1 + 'rem'
  },
  fontBoldMobile: {
    fontWeight: 500
  },
  bottomContainer: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  iconRefreshButton: {
    width: '44px',
    height: '25px',
    backgroundColor: GREEN_MOBILE,
    display: 'grid',
    placeItems: 'center',
    marginRight: theme.spacing(1),
    borderRadius: '5px',
    color: WHITE
  },
  containerTabPanel: {
    width: '93.5vw',
    display: 'grid',
    placeItems: 'center'
  },
  tabColly: {
    width: '100%'
  }
}));

const ProduckRequest: FC<Props> = props => {
  const classes = useStyles();
  const {
    salesOrder,
    isLoadingData,
    company,
    isAccess,
    salesOrderItem,
    value,
    isChange,
    collyFinal,
    userName,
    hasInvoice,
    rejectOrder,
    handletRequestEdit,
    setOpenEdit,
    handleAcceptItem,
    handleRejectItem,
    tempCollyFinalUpdate,
    setHasOpenModal,
    handleOpenImage,
    idSalesOrder,
    isChangeSubmit,
    isConvertAllowed,
    consistenMessage,
    currentWareHouse,
    totalWarehouse,
    currentCheckWarehouse,
    getWareHouse,
    anchorEl,
    buttonGroup,
    showComponent,
    totalApprove,
    isValid,
    tempItemColly,
    deleteColly,
    indexTooltip,
    openTooltip,
    handleOpenTooltip,
    handleCloseTooltip,
    setCurrentCheckWarehouse,

    handleChange,
    handleConfirm,
    addItemAction,
    handleReject,
    handleReset,
    openAlertPrice,
    indexAlertPrice,
    setGetIdClick,
    handleOpenAlertPrice,
    handleCloseAlertPrice,
    openAlertDiscount,
    indexAlertDiscount,
    handleOpenAlertDiscount,
    handleCloseAlertDiscount
  } = props;
  const [collyResource, setCollyResource] = useState<typeCollyModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openModalMerge, setOpenModalMerge] = useState<boolean>(false);
  const [invoiceResource, setInvoiceResource] = useState<{ id: number; invoiceNumber: string }[]>([]);
  const fetchDataColly = async () => {
    setLoading(true);

    try {
      const { data } = await axios.get(GET_COLLY_DETAILS(idSalesOrder));
      setCollyResource(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const invoiceCheck = async () => {
    if (totalWarehouse.length > 0) {
      try {
        const { data } = await axios.get(INVOICE_CHECK(salesOrder.PartnerId, totalWarehouse[0].name));
        setOpenModalMerge(data.flat().length > 0 ? true : false);
        setInvoiceResource(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS) {
      invoiceCheck();
    }
  }, [salesOrder.PartnerId, totalWarehouse]);

  useEffect(() => {
    fetchDataColly();
  }, []);

  const getTotalBag = (id: number) => {
    const total = collyResource.map(value =>
      value.BagItem.filter(value => value.SalesOrderItemId === id)
        .map(value => value.totalItem)
        .reduce((a, b) => a + b, 0)
    );
    return Number(total.reduce((a, b) => a + b, 0));
  };

  const getDataFilter = () => {
    if (isAccess === TypeUser.PICKER) {
      return SalesOrderStatus.PREVIEW;
    } else if (isAccess === TypeUser.ADMIN01) {
      return SalesOrderStatus.PACKING;
    } else if (
      isAccess === TypeUser.SALES ||
      isAccess === TypeUser.SUPERVISOR ||
      isAccess === TypeUser.SUPERVISOR ||
      isAccess === TypeUser.SUPERADMIN
    ) {
      return SalesOrderStatus.HAS_INVOICE;
    } else {
      return '';
    }
  };

  const sumSubtotalPrice = salesOrderItem
    .filter(value => value.status !== SalesOrderStatus.REJECT)
    .map(value => value.subTotalPrice)
    .reduce((total, price) => total + price, 0);
  const sumDiscount = salesOrderItem
    .filter(value => value.status !== SalesOrderStatus.REJECT)
    .map(value => value.discount)
    .reduce((total, price) => total + price, 0);
  const sumTotalPrice = salesOrderItem
    .filter(value => value.status !== SalesOrderStatus.REJECT)
    .map(value => value.totalPrice)
    .reduce((total, price) => total + price, 0);

  const getFilterData = (value: string) => {
    if (isAccess === TypeUser.PICKER) {
      return value === SalesOrderStatus.PENDING || value === SalesOrderStatus.PREVIEW;
    } else if (isAccess === TypeUser.ADMIN01) {
      return value === SalesOrderStatus.REJECT || value === SalesOrderStatus.PACKING || value === SalesOrderStatus.PREVIEW;
    } else if (isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS) {
      return (
        value === SalesOrderStatus.PACKING ||
        value === SalesOrderStatus.CHECKING ||
        value === SalesOrderStatus.CONFIRM ||
        value === SalesOrderStatus.HAS_INVOICE
      );
    } else {
      return (
        SalesOrderStatus.PENDING ||
        SalesOrderStatus.CHECKING ||
        SalesOrderStatus.CONFIRM ||
        SalesOrderStatus.PACKING ||
        SalesOrderStatus.PREVIEW ||
        SalesOrderStatus.REJECT
      );
    }
  };
  const fetchData = () => {
    if (isAccess === TypeUser.SALES || isAccess === TypeUser.SUPERVISOR || isAccess === TypeUser.SUPERADMIN) {
      const data = currentCheckWarehouse
        ? salesOrderItem.filter(value => value.warehouse && value.warehouse === currentCheckWarehouse && getFilterData(value.status))
        : salesOrderItem.filter(value => getFilterData(value.status));
      return data;
    } else {
      const data = salesOrderItem.filter(value => value.warehouse && value.warehouse === currentCheckWarehouse && getFilterData(value.status));
      return data;
    }
  };

  const getStatusItem = () => {
    if (isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS) {
      const stats = salesOrderItem.some(value => value.status === SalesOrderStatus.CHECKING);

      if (stats && !isLoadingData) {
        return (
          <Typography className={classes.fontMobile} style={{ color: YELLOW_MOBILE }}>
            Buat Invoice
          </Typography>
        );
      }
    }
  };

  const handleIsCan = () => {
    if (isAccess === TypeUser.PICKER) {
      const can = salesOrderItem.some(value => value.status === SalesOrderStatus.PREVIEW);
      return can;
    } else {
      const can = salesOrderItem.some(value => value.status === SalesOrderStatus.PACKING);
      return can;
    }
  };

  return (
    <Fragment>
      <Grid xs={12} container direction='row' className={classes.stickyCotainer}>
        <Grid
          item
          xs={
            isAccess === TypeUser.ADMIN01 || isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS
              ? 7
              : isAccess === TypeUser.ADMIN03
              ? 6
              : 12
          }
          className={classes.titleContainer}
          classes={{
            root: classes.mobileContainer
          }}
        >
          <Typography className={classes.titleSizeMobile}>{isAccess === TypeUser.ADMIN03 ? 'Semua Produk' : 'Daftar Permintaan Produk'}</Typography>

          <Typography
            className={classes.fontMobile}
            style={{
              color: isAccess === TypeUser.SALES || isAccess === TypeUser.SUPERVISOR || isAccess === TypeUser.PICKER ? PRIMARY_MOBILE : YELLOW_MOBILE
            }}
          >
            {isAccess === TypeUser.ADMIN01
              ? 'PRATINJAU'
              : isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS
              ? 'READY TO PACK '
              : isAccess === TypeUser.PICKER
              ? 'TOTAL PRODUK : ' + salesOrderItem.filter(value => value.status !== SalesOrderStatus.REJECT).length
              : 'TOTAL PRODUK : ' + salesOrderItem.length}
          </Typography>
          {getStatusItem()}
        </Grid>

        {isAccess === TypeUser.ADMIN01 && (
          <Grid item xs={5} className={classes.titleContainer}>
            <Typography align='right' className={classes.fontMobile} style={{ fontWeight: 'bold' }}>
              Disiapkan Oleh:
            </Typography>

            <Typography align='right' className={classes.fontMobile} style={{ color: PRIMARY_MOBILE }}>
              Gudangm01
            </Typography>
            <Typography align='right' className={(classes.fontMobile, classes.fontSizeMobile)} style={{ color: 'lightgray' }}>
              30-07-2021 15:20:01
            </Typography>
          </Grid>
        )}

        {isAccess === TypeUser.ADMIN03 && (
          <Fragment>
            <Grid item xs={6} container justify='flex-end' className={classes.titleContainer} alignItems='flex-start'>
              <Grid item xs={10}>
                <Typography align='right' className={classes.fontMobile} style={{ fontWeight: 'bold' }}>
                  Dipacking Oleh:
                </Typography>

                <Typography align='right' className={classes.fontMobile} style={{ color: PRIMARY_MOBILE }}>
                  Admin 02
                </Typography>
                <Typography align='right' className={(classes.fontMobile, classes.fontSizeMobile)} style={{ color: 'lightgray' }}>
                  30-07-2021 15:20:01
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <ExpandMore style={{ marginTop: '-0.15em' }} />
              </Grid>
            </Grid>
          </Fragment>
        )}

        {(isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS) && (
          <Grid item xs={5} className={classes.titleContainer} spacing={3}>
            <Typography align='right' className={classes.fontMobile} style={{ fontWeight: 'bold' }}>
              Disiapkan Oleh:
            </Typography>

            <Typography align='right' className={classes.fontMobile} style={{ color: PRIMARY_MOBILE }}>
              Gudangm01
            </Typography>
            <Typography align='right' className={(classes.fontMobile, classes.fontSizeMobile)} style={{ color: 'lightgray' }}>
              30-07-2021 15:20:01
            </Typography>
            <Grid className={classes.spacing} />
            <Typography align='right' className={classes.fontMobile} style={{ fontWeight: 'bold' }}>
              Dikonfirmasi Oleh:
            </Typography>

            <Typography align='right' className={classes.fontMobile} style={{ color: PRIMARY_MOBILE }}>
              Admin 01
            </Typography>
            <Typography align='right' className={(classes.fontMobile, classes.fontSizeMobile)} style={{ color: 'lightgray' }}>
              30-07-2021 15:20:01
            </Typography>
          </Grid>
        )}

        {(isAccess === TypeUser.SALES || isAccess === TypeUser.SUPERVISOR) && (
          <Grid xs={12} container alignItems='center' className={classes.containerPrice}>
            <Grid xs={3}>
              <Typography className={classes.fontMobile}>Subtotal</Typography>
              <Typography className={classes.fontMobile}>Diskon</Typography>
              <Typography className={classes.fontMobile}>Total Harga</Typography>
            </Grid>
            <Grid xs={9}>
              <Typography className={classes.fontMobile}>
                : <NumberFormat value={sumSubtotalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              </Typography>
              <Typography className={classes.fontMobile}>
                : {sumDiscount > 0 ? <NumberFormat value={sumDiscount} prefix={'Rp'} thousandSeparator={true} displayType='text' /> : '-'}
              </Typography>
              <Typography className={classes.fontMobile}>
                : <NumberFormat value={sumTotalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid
          item
          container
          direction='row'
          spacing={0}
          alignItems='center'
          xs={12}
          sm={12}
          md={12}
          className={classes.optionsContainer}
          justify='space-between'
        >
          <Grid item xs={8} container alignItems='center'>
            {isAccess === TypeUser.SALES || isAccess === TypeUser.SUPERVISOR || isAccess === TypeUser.SUPERADMIN
              ? totalWarehouse.map((value, key) => (
                  <Grid xs={4}>
                    <FormControlLabel
                      onClick={e =>
                        setCurrentCheckWarehouse(prevState => (prevState !== value.name ? value.name : prevState === value.name ? '' : value.name))
                      }
                      control={<Checkbox value={value.name} checked={currentCheckWarehouse === value.name} color='primary' />}
                      label={
                        <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
                          {value.name}
                        </Typography>
                      }
                    />
                  </Grid>
                ))
              : totalWarehouse.map((value, key) => (
                  <Grid xs={4}>
                    <FormControlLabel
                      control={<Checkbox checked={key === 0 ? true : false} color='primary' />}
                      label={
                        <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
                          {value.name}
                        </Typography>
                      }
                    />
                  </Grid>
                ))}
          </Grid>

          <Grid item xs={4} container justify='flex-end' alignItems='center'>
            {!isLoadingData &&
              (isAccess === TypeUser.SALES || isAccess === TypeUser.SUPERVISOR) &&
              !salesOrderItem.some(val => val.status !== SalesOrderStatus.PENDING) && (
                <DeleteForeverOutlined
                  fontSize='large'
                  color='error'
                  onClick={() => {
                    setOpenEdit(true);
                    setHasOpenModal(12);
                  }}
                />
              )}
          </Grid>
        </Grid>

        {isAccess === TypeUser.SALES || isAccess === TypeUser.SUPERVISOR || isAccess === TypeUser.SUPERVISOR || isAccess === TypeUser.SUPERVISOR ? (
          <Grid item container direction='row' alignItems='center' justify='flex-start' lg={12} sm={12} md={12} xs={12}>
            <Tabs value={value} indicatorColor='primary' textColor='inherit' onChange={handleChange} style={{ overflow: 'visible' }}>
              <Tab
                style={{ fontSize: '0.7rem' }}
                label={`Semua Produk (${salesOrderItem.filter(value => getFilterData(value.status)).length})`}
                {...a11yProps(0)}
              />
            </Tabs>
          </Grid>
        ) : isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS ? (
          <Grid item container direction='row' alignItems='center' justify='flex-start' lg={12} sm={12} md={12} xs={12}>
            {isLoadingData ? (
              <></>
            ) : salesOrder.cityAddress.includes(CityAddress.In) ? (
              <Tabs value={0} indicatorColor='primary' textColor='inherit'>
                <Tab
                  style={{ fontSize: '0.7rem' }}
                  label={`Semua Produk (${salesOrderItem.filter(value => getFilterData(value.status)).length})`}
                  {...a11yProps(0)}
                />
              </Tabs>
            ) : (
              <Tabs value={value} indicatorColor='primary' textColor='inherit' onChange={handleChange} style={{ overflow: 'visible' }}>
                <Tab style={{ fontSize: '0.7rem' }} label='Semua  Koli' {...a11yProps(0)} />
                <Tab
                  style={{ fontSize: '0.7rem' }}
                  label={`Sisa Pengepakan (${
                    salesOrderItem.filter(value => getTotalBag(value.id) < value.totalItem && value.status === SalesOrderStatus.PACKING).length
                  })`}
                  {...a11yProps(1)}
                />
              </Tabs>
            )}
          </Grid>
        ) : (
          <Grid item container alignItems='center' xs={12}>
            <Tabs
              value={value}
              variant='fullWidth'
              className={classes.tabContainerMobile}
              indicatorColor='primary'
              textColor='inherit'
              onChange={handleChange}
              style={{ overflow: 'visible' }}
            >
              <Tab
                label={`Semua Produk (${
                  salesOrderItem.filter(
                    value => getFilterData(value.status) || (value.status === SalesOrderStatus.REJECT && value.rejectBy === userName)
                  ).length
                })`}
                {...a11yProps(0)}
                style={{ fontSize: '0.65rem', flex: '0.4' }}
              />
              <Tab
                style={{ fontSize: '0.65rem', flex: '0.3' }}
                label={`Disetujui (${salesOrderItem.filter(value => value.status === getDataFilter().toString()).length})`}
                {...a11yProps(1)}
              />
              <Tab
                style={{ fontSize: '0.65rem', flex: '0.3' }}
                label={`Ditolak (${salesOrderItem.filter(value => value.status === ItemStatus.REJECT && value.rejectBy === userName).length})`}
                {...a11yProps(2)}
              />
            </Tabs>
          </Grid>
        )}
        <Grid xs={12}>
          <Divider className={classes.mobile} />
        </Grid>
      </Grid>

      {isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS ? (
        <TabPanel value={value} index={0} className={classes.tabColly}>
          {salesOrder.cityAddress.includes(CityAddress.In) ? (
            <CollyListProduct idSalesOrder={idSalesOrder} getWareHouse={getWareHouse} salesOrderItem={fetchData()} />
          ) : (
            <CollyList
              idSalesOrder={idSalesOrder}
              getWareHouse={getWareHouse}
              salesOrderItem={fetchData()}
              collyResource={collyResource}
              setCollyResource={setCollyResource}
              loading={loading}
              openModalMerge={openModalMerge}
              invoiceResource={invoiceResource}
              setOpenModalMerge={setOpenModalMerge}
            />
          )}
        </TabPanel>
      ) : (
        <TabPanel value={value} index={0}>
          <Grid xs={12} className={classes.containerTabPanel}>
            <ListProduct
              key={1}
              tab={value}
              tempItemColly={tempItemColly}
              deleteColly={deleteColly}
              isAccess={isAccess}
              collyFinal={collyFinal}
              handleRejectItem={handleRejectItem}
              isCan={handleIsCan()}
              setHasOpenModal={setHasOpenModal}
              handleRejectSuperAdmin={() => console.log('')}
              handleCancelRejectSuperAdmin={() => console.log('')}
              handleAcceptItem={handleAcceptItem}
              handleOpenImage={handleOpenImage}
              setOpenEdit={setOpenEdit}
              setGetIdClick={setGetIdClick}
              showComponent={showComponent}
              handleConfirm={handleConfirm}
              handleReject={handleReject}
              handletRequestEdit={handletRequestEdit}
              isLoadingData={isLoadingData}
              rejectOrder={rejectOrder}
              salesOrderItem={fetchData()}
              loadingReject={false}
              hasInvoice={hasInvoice}
              isConvertAllowed={isConvertAllowed}
              salesOrder={salesOrder}
              currentWareHouse={currentWareHouse}
              addItemAction={addItemAction}
              indexTooltip={indexTooltip}
              openTooltip={openTooltip}
              handleOpenTooltip={handleOpenTooltip}
              handleCloseTooltip={handleCloseTooltip}
              openAlertPrice={openAlertPrice}
              indexAlertPrice={indexAlertPrice}
              handleOpenAlertPrice={handleOpenAlertPrice}
              handleCloseAlertPrice={handleCloseAlertPrice}
              openAlertDiscount={openAlertDiscount}
              indexAlertDiscount={indexAlertDiscount}
              handleOpenAlertDiscount={handleOpenAlertDiscount}
              handleCloseAlertDiscount={handleCloseAlertDiscount}
            />
          </Grid>
        </TabPanel>
      )}

      <TabPanel value={value} index={1}>
        <Grid xs={12} className={classes.containerTabPanel}>
          {isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS ? (
            <CollyTemp
              salesOrderItem={salesOrderItem.filter(value => getTotalBag(value.id) < value.totalItem && value.status === SalesOrderStatus.PACKING)}
              collyResource={collyResource}
            />
          ) : (
            <ListProduct
              key={2}
              tab={value}
              collyFinal={collyFinal}
              showComponent={showComponent}
              handleRejectItem={handleRejectItem}
              isAccess={isAccess}
              handleRejectSuperAdmin={() => console.log('')}
              handleCancelRejectSuperAdmin={() => console.log('')}
              rejectOrder={rejectOrder}
              isCan={handleIsCan()}
              handletRequestEdit={handletRequestEdit}
              loadingReject={false}
              handleAcceptItem={handleAcceptItem}
              handleOpenImage={handleOpenImage}
              setGetIdClick={setGetIdClick}
              setHasOpenModal={setHasOpenModal}
              addItemAction={addItemAction}
              setOpenEdit={setOpenEdit}
              handleConfirm={handleConfirm}
              handleReject={handleReject}
              tempItemColly={tempItemColly}
              deleteColly={deleteColly}
              isLoadingData={isLoadingData}
              salesOrderItem={salesOrderItem.filter(value => value.status === getDataFilter().toString())}
              hasInvoice={hasInvoice}
              isConvertAllowed={isConvertAllowed}
              salesOrder={salesOrder}
              currentWareHouse={currentWareHouse}
              indexTooltip={indexTooltip}
              openTooltip={openTooltip}
              handleOpenTooltip={handleOpenTooltip}
              handleCloseTooltip={handleCloseTooltip}
              openAlertPrice={openAlertPrice}
              indexAlertPrice={indexAlertPrice}
              handleOpenAlertPrice={handleOpenAlertPrice}
              handleCloseAlertPrice={handleCloseAlertPrice}
              openAlertDiscount={openAlertDiscount}
              indexAlertDiscount={indexAlertDiscount}
              handleOpenAlertDiscount={handleOpenAlertDiscount}
              handleCloseAlertDiscount={handleCloseAlertDiscount}
            />
          )}
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Grid xs={12} className={classes.containerTabPanel}>
          <ListProduct
            key={3}
            tab={value}
            setHasOpenModal={setHasOpenModal}
            setOpenEdit={setOpenEdit}
            addItemAction={addItemAction}
            isAccess={isAccess}
            collyFinal={collyFinal}
            handleRejectSuperAdmin={() => console.log('')}
            handleCancelRejectSuperAdmin={() => console.log('')}
            handleAcceptItem={handleAcceptItem}
            isCan={handleIsCan()}
            handleOpenImage={handleOpenImage}
            setGetIdClick={setGetIdClick}
            showComponent={showComponent}
            loadingReject={false}
            handleConfirm={handleConfirm}
            handleReject={handleReject}
            handletRequestEdit={handletRequestEdit}
            handleRejectItem={handleRejectItem}
            isLoadingData={isLoadingData}
            salesOrderItem={salesOrderItem.filter(value => value.status === ItemStatus.REJECT && value.rejectBy === userName)}
            hasInvoice={hasInvoice}
            rejectOrder={rejectOrder}
            isConvertAllowed={isConvertAllowed}
            salesOrder={salesOrder}
            tempItemColly={tempItemColly}
            deleteColly={deleteColly}
            currentWareHouse={currentWareHouse}
            indexTooltip={indexTooltip}
            openTooltip={openTooltip}
            handleOpenTooltip={handleOpenTooltip}
            handleCloseTooltip={handleCloseTooltip}
            openAlertPrice={openAlertPrice}
            indexAlertPrice={indexAlertPrice}
            handleOpenAlertPrice={handleOpenAlertPrice}
            handleCloseAlertPrice={handleCloseAlertPrice}
            openAlertDiscount={openAlertDiscount}
            indexAlertDiscount={indexAlertDiscount}
            handleOpenAlertDiscount={handleOpenAlertDiscount}
            handleCloseAlertDiscount={handleCloseAlertDiscount}
          />
        </Grid>
      </TabPanel>

      {isAccess === TypeUser.ADMIN03 && showComponent && (
        <Grid xs={12} className={classes.bottomContainer}>
          <Grid xs={11}>
            {/* <Typography className={classes.titleSizeMobile}>Keterangan</Typography>
            <Typography style={{ fontSize: '0.9rem' }}>Pratinjau oleh Cadis Hangoluan (Gudang) pada tanggal 06 Oktober 2021, 13:31 WIB</Typography> */}
          </Grid>

          <Grid container direction='row' style={{ marginTop: '1em' }}>
            <Grid xs={8} container direction='column' alignItems='flex-end'>
              <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
                Subtotal
              </Typography>
              <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
                Diskon
              </Typography>
              <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
                Total Harga
              </Typography>
              {/* <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
                Lunas
              </Typography> */}
              <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
                Jumlah Tagihan
              </Typography>
            </Grid>

            <Grid xs={3} container direction='column' alignItems='flex-end'>
              <Typography className={classes.fontMobile}>
                <NumberFormat value={sumSubtotalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              </Typography>
              <Typography className={classes.fontMobile}>
                <NumberFormat value={sumDiscount} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              </Typography>
              <Typography className={classes.fontMobile}>
                <NumberFormat value={sumTotalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              </Typography>

              <Typography className={classes.fontMobile}>
                <NumberFormat value={sumTotalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default ProduckRequest;
