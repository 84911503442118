import React from 'react';
import { Theme, Grid, Typography, makeStyles, Divider, Hidden } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { Skeleton } from '@material-ui/lab';

import { WHITE } from 'constants/colors';
const useStyles = makeStyles((theme: Theme) => ({
  boxTitle: {
    marginBottom: 8
  },
  box: {
    backgroundColor: WHITE,
    borderRadius: '5px',
    padding: '10px 20px',
    border: '1px solid #F2F2F2',
    boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.08)',
    marginTop: 2,
    overflowX: 'auto'
  }
}));
interface Props {
  invoiceTotal: number;
  tagihanTotal: number;
  totalSo: number;
  totalSoTerkonfirmasi: number;
  totalSoTertunda: number;
  rute: string;
  status: string;
  loading: boolean;
}
const CardTotal: React.FC<Props> = ({ invoiceTotal, tagihanTotal, totalSo, totalSoTerkonfirmasi, totalSoTertunda, rute, status, loading }) => {
  const classes = useStyles();
  return (
    <Grid item container justify='space-between' className={classes.box}>
      <Grid item lg={2} md={12} sm={12} xs={12} justify='center'>
        <Typography variant='h6' className={classes.boxTitle}>
          Jumlah SO
        </Typography>
        {loading ? <Skeleton variant='text' width='70%' /> : <Typography variant='body2'> {invoiceTotal} </Typography>}
      </Grid>
      <Hidden smUp smDown>
        <Divider orientation='vertical' />
      </Hidden>
      <Grid item lg={5} md={12} sm={12} xs={12}>
        <Typography variant='h6' className={classes.boxTitle}>
          Total Nominal SO
        </Typography>
        {loading ? (
          <Skeleton variant='text' width='70%' />
        ) : (
          <>
            <Typography variant='body2'> {<NumberFormat value={totalSo} prefix={'Rp '} thousandSeparator={true} displayType='text' />} </Typography>
            <Typography variant='body2' style={{ color: '#E4AE42' }}>
              (
              {
                <NumberFormat
                  value={totalSoTerkonfirmasi}
                  prefix={'Terkonfirmasi: Rp '}
                  thousandSeparator={true}
                  displayType='text'
                  style={{ color: '#E4AE42' }}
                />
              }
              ){' '}
            </Typography>
            <Typography variant='body2' style={{ color: '#E4AE42' }}>
              (
              {
                <NumberFormat
                  value={totalSoTertunda}
                  prefix={'Tertunda: Rp'}
                  thousandSeparator={true}
                  displayType='text'
                  style={{ color: '#E4AE42' }}
                />
              }
              ){' '}
            </Typography>
          </>
        )}
      </Grid>
      <Hidden smUp smDown>
        <Divider orientation='vertical' />
      </Hidden>
      <Grid item lg={3} md={6} sm={6} xs={6}>
        <Typography variant='h6' className={classes.boxTitle}>
          Rute
        </Typography>
        {loading ? <Skeleton variant='text' width='70%' /> : <Typography variant='body2'> {rute} </Typography>}
      </Grid>
      <Hidden smUp smDown>
        <Divider orientation='vertical' />
      </Hidden>
      <Grid item lg={2} md={6} sm={6} xs={6}>
        <Typography align='right' variant='h6' className={classes.boxTitle}>
          Status SO
        </Typography>
        {loading ? (
          <Skeleton variant='text' width='70%' />
        ) : (
          <Typography align='right' variant='body2'>
            {status}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default CardTotal;
