import { Button, CircularProgress, Drawer, Grid, makeStyles, MenuItem, TextField, Theme, Typography } from '@material-ui/core';
import { Add, CloseRounded, CreateOutlined, ExpandLessOutlined, ExpandMore, InfoOutlined, InfoRounded } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { YELLOW_MOBILE } from 'constants/colors';
import EmptyIcon from 'images/icon/EmptyIcon.svg';
import React, { FC, useState } from 'react';

interface Props {
  open: boolean;
  isLoading: boolean;
  loadingData: boolean;
  isDisabled: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIdColly: React.Dispatch<React.SetStateAction<number>>;
  setBagIdColly: React.Dispatch<React.SetStateAction<number>>;
  setOpenModalUpdateMerge: React.Dispatch<React.SetStateAction<boolean>>;
  setInvoice: React.Dispatch<React.SetStateAction<string>>;
  collyResourceBefore: typeCollyModel[];
  handleOpenModalItem: (id: number) => void;
  invoiceResource: { id: number; invoiceNumber: string }[];
  handleOpenNotif: () => void;
  handleSaveColly: () => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    minHeight: '100vh',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  containerEmpty: {
    minHeight: '250px',
    padding: '0 1em',
    position: 'relative'
  },
  textSecondary: {
    fontSize: '0.8rem',
    color: 'grey'
  },
  buttonContainerMobile: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 4,
    backgroundColor: '#fff',
    padding: '13px 10px',
    boxShadow: '0px 0px 17px rgba(0, 0, 0, 0.08)'
  },
  container: {
    rowGap: '1em',
    backgroundColor: '#fff'
  },
  button: {
    borderRadius: '1.4em'
  },
  header: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    padding: '1em',
    gap: '1em'
  },
  section: {
    padding: '0 1em',
    rowGap: '1em'
  },
  divider: {
    borderTop: '0.2em solid #F3F3F3'
  },
  alertWarning: {
    border: '1px solid #FFBF00',
    backgroundColor: 'rgba(255, 191, 0, 0.12)',
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
    borderRadius: '5px'
  },
  containerItem: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    display: 'flex',
    alignItems: 'center',
    padding: '0 1rem 0.3rem'
  },
  itemBody: {
    display: 'flex',
    gap: '1.4em',
    padding: '0.6em 1em'
  },
  combineInv: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.2em',
    alignItems: 'flex-start'
  },
  containerItemActive: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(2, 169, 234, 0.12)',
    padding: '0 1rem 0.3rem'
  }
}));

const CreateCollyMerge: FC<Props> = props => {
  const {
    open,
    setOpen,
    isDisabled,
    invoiceResource,
    collyResourceBefore,
    setIdColly,
    setBagIdColly,
    handleSaveColly,
    setInvoice,
    setOpenModalUpdateMerge,
    loadingData,
    isLoading,
    handleOpenModalItem,
    handleOpenNotif
  } = props;
  const classes = useStyles();
  const [clicked, setClicked] = useState<number>(0);
  const [hasExpand, setHasExpand] = useState<boolean>(true);

  const getTotalQty = (id: number) => {
    const total = collyResourceBefore.filter(val => val.id === id).map(val => val.BagItem.map(val => val.totalItem).reduce((a, b) => a + b, 0));
    return Number(total.reduce((a, b) => a + b, 0));
  };

  const handleUpdate = (idColly: number, idBagColly: number) => {
    setOpenModalUpdateMerge(true);
    setIdColly(idColly);
    setBagIdColly(idBagColly);
  };

  return (
    <Drawer
      anchor='bottom'
      open={open}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <Grid xs={12} className={classes.container} container direction='column'>
        <Grid xs={12} container alignItems='center' className={classes.header}>
          <CloseRounded onClick={() => setOpen(false)} style={{ fontSize: '1.5em' }} />
          <Typography variant='h5'>Pilih Invoice</Typography>
        </Grid>
        <Grid xs={12} className={classes.section} container>
          <Grid xs={12} className={classes.alertWarning}>
            <InfoOutlined style={{ color: '#ff9800' }} />
            <Grid item>
              <Typography variant='h6'>Informasi Penting!</Typography>
              <Typography>Nama barang setiap koli diinputkan di akhir setelah semua produk selesai dipacking kedalam koli.</Typography>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <TextField
              id='invoiceNumber'
              size='small'
              required
              select
              label='Pilih Nomor Invoice'
              onChange={e => setInvoice(e.target.value)}
              fullWidth
              InputLabelProps={{ style: { fontSize: '1rem' } }}
            >
              {invoiceResource.map((val, key) => {
                if (Array.isArray(val)) {
                  if (val.length === 0) return;

                  return (
                    <MenuItem key={key} value={val.map(val => val.id).join(',')} className={classes.combineInv}>
                      {val.map(value => (
                        <Typography>{'#'.concat(value.invoiceNumber) + '\n'}</Typography>
                      ))}
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem
                      key={key}
                      value={val.id}
                      style={{ borderBottom: key < invoiceResource.length - 1 ? '1px solid rgba(0, 0, 0, 0.08)' : '' }}
                    >
                      #{val.invoiceNumber}
                    </MenuItem>
                  );
                }
              })}
            </TextField>
          </Grid>
        </Grid>

        <Grid xs={12} className={classes.divider} />

        <Grid xs={12} container>
          <Grid xs={12} style={{ padding: '0 1em', marginBottom: '1em' }}>
            <Typography variant='h6'>Semua Koli</Typography>
          </Grid>

          {isLoading ? (
            <Grid xs={12} container justify='center'>
              <Grid xs={11}>
                {[...new Array(5)].map(val => (
                  <Skeleton variant='text' width='100%' height={60} />
                ))}
              </Grid>
            </Grid>
          ) : collyResourceBefore.length > 0 ? (
            collyResourceBefore.map((val, key) => (
              <Grid xs={12}>
                <Grid
                  xs={12}
                  className={key === clicked ? classes.containerItemActive : classes.containerItem}
                  onClick={() => {
                    setHasExpand(!hasExpand);
                    setClicked(key);
                  }}
                >
                  <Grid item xs={10} container alignItems='center'>
                    {/* {hasInputNameColly && val.packName === '' && (
                      <Grid item>
                        <CustomizedCheckbox onChange={handleChecked} value={val.id} checked={val.checked || false} />
                      </Grid>
                    )} */}
                    <Grid item>
                      <Typography variant='h6'>{val.name}</Typography>

                      <Typography variant='h6'>{val.packName}</Typography>

                      <Typography>Total List Item: {val.BagItem.length}</Typography>
                      <Typography>Total Pengepakan: {getTotalQty(val.id)}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={2} justify='flex-end'>
                    {hasExpand && key === clicked ? (
                      <ExpandLessOutlined
                        fontSize='small'
                        style={{ color: '#616161' }}
                        onClick={() => {
                          setHasExpand(false);
                          setClicked(key);
                        }}
                      />
                    ) : (
                      <ExpandMore
                        fontSize='small'
                        style={{ color: '#616161' }}
                        onClick={() => {
                          setHasExpand(true);
                          setClicked(key);
                        }}
                      />
                    )}
                  </Grid>
                </Grid>

                {hasExpand &&
                  key === clicked &&
                  val.BagItem.map((value, index) => (
                    <>
                      <Grid xs={12} className={classes.itemBody} key={index}>
                        <Grid xs={2}>
                          <img
                            src={value.productImage}
                            alt='gambar produk'
                            width='60px'
                            height='60px'
                            style={{ borderRadius: '0.3rem', border: '1px solid rgba(0, 0, 0, 0.08)' }}
                          />
                        </Grid>
                        <Grid xs={10} container>
                          <Grid xs={11}>
                            <Typography variant='h6' style={{ position: 'relative' }}>
                              {value.productName}
                              {value.isMerge && (
                                <InfoRounded
                                  style={{ position: 'absolute', bottom: -1, color: YELLOW_MOBILE, marginLeft: '0.2em' }}
                                  onClick={handleOpenNotif}
                                />
                              )}
                            </Typography>
                            <Typography>Total Qty Pengepakan: {value.totalItem} </Typography>
                          </Grid>
                          <Grid xs={1} container justify='flex-end'>
                            {value.isMerge && <CreateOutlined color='primary' fontSize='small' onClick={() => handleUpdate(val.id, value.id)} />}
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ))}
                {hasExpand && key === clicked && (
                  <Grid xs={12} container justify='center' style={{ margin: '0.7em 0' }}>
                    <Grid xs={11}>
                      <Button
                        variant='outlined'
                        color='primary'
                        fullWidth
                        className={classes.button}
                        size='small'
                        onClick={() => handleOpenModalItem(val.id)}
                      >
                        <Add color='primary' />
                        Tambah Koli
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            ))
          ) : (
            <Grid xs={12} container direction='column' alignItems='center' justify='center' className={classes.containerEmpty}>
              <img src={EmptyIcon} alt='Data Kosong' width='65px' height='65px' />

              <Typography variant='h6' align='center' style={{ marginTop: '0.4em' }}>
                Tidak ada koli yang ditampilkan
              </Typography>
              <Typography align='center' className={classes.textSecondary}>
                Silahkan pilih invoice terlebih dahulu.
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid xs={12} style={{ minHeight: '3.4em' }}>
          <Grid xs={12} className={classes.buttonContainerMobile}>
            <Button color='primary' style={{ borderRadius: '20px' }} fullWidth onClick={handleSaveColly} disabled={isDisabled}>
              {loadingData ? <CircularProgress size={20} color='primary' /> : 'Simpan Koli'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default CreateCollyMerge;
