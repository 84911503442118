import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import { CreateOutlined, ExpandLessOutlined, ExpandMore, HighlightOff } from '@material-ui/icons';
import useRouter from 'hooks/useRouter';
import React, { FC, useEffect, useState } from 'react';

interface Props {
  openModal: boolean;
  collyResource: typeCollyModel[];
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setHasInputNameColly: React.Dispatch<React.SetStateAction<boolean>>;
  setCollyResource: React.Dispatch<React.SetStateAction<typeCollyModel[]>>;
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'inline-block',
    width: '100vh',
    position: 'relative'
  },
  titleContainer: {
    fontWeight: 500,
    fontSize: '1.2rem'
  },
  button: {
    borderRadius: '15px',
    padding: '7px 0'
  },
  itemBody: {
    display: 'flex',
    gap: '1.4em',
    padding: '0.6em 0'
  },
  textStyle: {
    fontSize: '0.8rem'
  },
  wrapProductName: {
    padding: '0 1em'
  },
  containerItem: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',

    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: '0.4em 1.2em 0.4em 1.9em',
    marginLeft: '-30px',

    width: '45vh'
  },
  containerItemActive: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',

    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(2, 169, 234, 0.12)',
    position: 'absolute',
    padding: '0.4em 1.2em 0.4em 1.9em',
    marginLeft: '-30px',

    width: '45vh'
  },
  textSecondary: {
    fontSize: '0.8rem',
    color: 'grey'
  },
  textMobile: {
    fontSize: '14px',
    fontWeight: 500
  },
  divined: {
    border: '1px solid rgba(0, 0, 0, 0.08)',
    margin: '1em 0',
    position: 'absolute',
    marginLeft: '-30px',
    width: '45vh'
  }
}));
const ModalProductName: FC<Props> = ({ openModal, setOpenModal, collyResource, setCollyResource, setHasInputNameColly }) => {
  const classes = useStyles();
  const { history } = useRouter();
  const [hasExpand, setHasExpand] = useState<boolean>(false);

  const [packName, setPackName] = useState<string>('');
  const [isCustomPackName, setIsCustomPackName] = useState<boolean>(false);
  const [customPackName, setCustomPackName] = useState<string>('');
  const [selected, setSelected] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const handleSubmit = () => {
    const isSelected = collyResource
      .filter(value => value.checked && value.packName === '')
      .map(value => ({ ...value, packName: selected ? customPackName : packName }));

    const isNotSelected = collyResource.filter(value => !value.checked || (value.checked && value.packName !== ''));

    setCollyResource([...isNotSelected, ...isSelected]);
    setOpenModal(false);
    setHasInputNameColly(false);
  };
  useEffect(() => {
    setSelected(false);
    setPackName('');
    setCustomPackName('');
  }, [openModal]);

  return (
    <Dialog open={openModal} style={{ maxHeight: 80 + 'vh', marginTop: '60px' }} scroll='paper' fullWidth>
      <DialogTitle>
        <Grid item container direction='column' alignItems='center' xs={12}>
          <Typography className={classes.titleContainer}>Masukkan Nama Barang</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ overflowX: 'hidden', minHeight: '25vh' }}>
        <Grid xs={12} container>
          {selected ? (
            <Grid xs={12} style={{ position: 'relative' }}>
              <TextField
                size='small'
                id='edit'
                label='Nama Barang'
                autoComplete='off'
                autoFocus={true}
                fullWidth
                placeholder='Input Nama Barang'
                onChange={event => setCustomPackName(event.target.value)}
              />
              <HighlightOff onClick={() => setSelected(false)} style={{ position: 'absolute', right: 5, top: 16, color: 'grey' }} />
            </Grid>
          ) : (
            <TextField
              select
              size='small'
              id='edit'
              value={packName}
              label='Nama Barang'
              autoComplete='off'
              fullWidth
              // SelectProps={{
              //   open: open,

              //   onOpen: () => setOpen(true),
              //   onClose: e => (packName === 'other' ? setOpen(true) : false)
              // }}
              onChange={event => {
                setPackName(event.target.value);
              }}
            >
              <MenuItem key={1} value='' selected disabled>
                Pilih Nama Barang
              </MenuItem>
              <MenuItem key={2} value='Alat listrik campur'>
                Alat listrik campur
              </MenuItem>
              <MenuItem key={3} value=' Barang elektronik campur'>
                Barang elektronik campur
              </MenuItem>
              <MenuItem key={4} value='Peralatan rumah tangga campur'>
                Peralatan rumah tangga campur
              </MenuItem>
              <MenuItem key={4} value='Barang audio campur'>
                Barang audio campur
              </MenuItem>

              <MenuItem key={5} value='other' onClick={() => setSelected(true)}>
                Lainnya
              </MenuItem>

              {/* {isCustomPackName && (
                <Grid xs={12} container justify='center'>
                  <Grid xs={10} container direction='column' style={{ gap: '1rem' }}>
                    <TextField
                      size='small'
                      id='edit'
                      value={customPackName}
                      label='Nama Barang'
                      placeholder='Input Nama Barang'
                      autoComplete='off'
                      fullWidth
                      onChange={event => setCustomPackName(event.target.value)}
                    />
                    <Button
                      variant='contained'
                      size='small'
                      fullWidth
                      disabled={customPackName === ''}
                      onClick={() => {
                        setOpen(false);
                        setIsCustomPackName(false);
                        setSelected(true);
                      }}
                    >
                      Simpan Nama Barang
                    </Button>
                  </Grid>
                </Grid>
              )} */}
            </TextField>
          )}
        </Grid>

        <Grid xs={12} className={classes.container} style={{ minHeight: '20px' }}>
          <Grid className={classes.divined} />
        </Grid>
        <Grid xs={12}>
          <Grid xs={12}>
            <Typography variant='h6'> Koli Dipilih</Typography>
          </Grid>
          {collyResource
            .filter(value => value.checked && value.packName === '')
            .map((value, key) => (
              <>
                <Grid xs={12} className={classes.container} style={{ minHeight: '81px' }}>
                  <Grid className={!hasExpand && value.id === index ? classes.containerItemActive : classes.containerItem}>
                    <Grid item xs={10} container alignItems='center'>
                      <Grid item>
                        <Typography variant='h6'>{value.name}</Typography>

                        <Typography className={classes.textSecondary}>Total List Item: {value.BagItem.length}</Typography>
                        <Typography className={classes.textSecondary}>
                          Total Pengepakan: {value.BagItem.map(value => value.totalItem).reduce((a, b) => a + b, 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container xs={2} justify='flex-end'>
                      {hasExpand ? (
                        <ExpandLessOutlined
                          fontSize='small'
                          onClick={() => {
                            setHasExpand(false);
                            setIndex(value.id);
                          }}
                          style={{ color: '#616161' }}
                        />
                      ) : (
                        <ExpandMore
                          fontSize='small'
                          onClick={() => {
                            setHasExpand(true);
                            setIndex(value.id);
                          }}
                          style={{ color: '#616161' }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                {hasExpand &&
                  value.id === index &&
                  value.BagItem.map(val => (
                    <Grid xs={12} className={classes.itemBody}>
                      <Grid xs={10}>
                        <Typography className={classes.textMobile}>{val.productName}</Typography>
                        <Typography className={classes.textSecondary}>Total Pengepakan: {val.totalItem} Unit</Typography>
                      </Grid>
                      <Grid xs={2}>
                        <img
                          src={val.productImage}
                          alt='gambar produk'
                          width='60px'
                          height='60px'
                          style={{ borderRadius: '0.3rem', border: '1px solid rgba(0, 0, 0, 0.08)' }}
                        />
                      </Grid>
                    </Grid>
                  ))}
              </>
            ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid xs={12} container justify='center'>
          <Grid xs={11} container direction='column' style={{ gap: '0.6rem' }}>
            <Button
              size='small'
              fullWidth
              className={classes.button}
              color='primary'
              onClick={() => handleSubmit()}
              disabled={selected ? customPackName === '' : packName === '' || packName === 'other'}
            >
              Konfirmasi
            </Button>
            <Button size='small' fullWidth variant='text' color='primary' onClick={() => setOpenModal(false)}>
              Tutup
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModalProductName;
