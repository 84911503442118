import React, { FC, Fragment, useEffect, useState, useContext } from 'react';
import useRouter from 'hooks/useRouter';
import axios, { CancelTokenSource } from 'axios';
import ReactQuill from 'react-quill';
import NumberFormatMask from 'components/NumberFormatMask';
import {
  Grid,
  Container,
  Typography,
  makeStyles,
  Button,
  TextField,
  CircularProgress,
  FormHelperText,
  MenuItem,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';
import { Page, StandardConfirmationDialog, PaperCustom, Breadcrumb } from 'components';
import {
  PURCHASE_ORDER_BASE_URL,
  PARTNER_BASE_URL,
  GET_PURCHASE_ORDER_DETAIL_BASE_URL,
  GET_PURCHASE_ORDER_NUMBER_BASE_URL,
  WAREHOUSE_BASE_URL,
  GET_PURCHASE_ORDER_ITEM_BASE_URL,
  PRODUCT_BASE_URL,
  PRODUCT_SEARCH,
  CATEGORY_BASE_URL
} from 'constants/url';
import { format } from 'date-fns';
import { dummyCategory, dummyMetaData, dummyPartner } from 'utils/dummy';
import { Autocomplete, AutocompleteInputChangeReason, Pagination } from '@material-ui/lab';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import ProductCardList from './components/ProductCardList';
import { AddRounded, CloseRounded, SearchRounded } from '@material-ui/icons';
import FlexBox from 'components/FlexBox';
import ProductReview from './components/ProductReview';
import clsx from 'clsx';
import useDebounce from 'hooks/useDebounce';
import ProductDeleteConfirm from './components/ProductDeleteConfirm';
import ImageModal from 'components/ImageModal';
import PaginationCustom from 'components/PaginationCustom';

const useStyles = makeStyles({
  editorNotes: {
    marginBottom: '10px'
  },
  actionContainer: {
    padding: '1em 0'
  },
  containerSuggest: {
    marginTop: '.5em',
    position: 'relative'
  },
  absolute: {
    position: 'absolute',
    zIndex: 1,
    top: 50,
    width: '100vw'
  },
  containerBox: {
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    borderRadius: '5px',
    marginTop: '0.5em'
  }
});

export interface TProduct {
  id: number;
  PurchaseOrderId: number;
  ProductId: number;
  typeUnit: string;
  productName: string;
  productImage: string;
  productCode: string;
  totalStock: number;
  netStock?: number;
  holdItem?: number;
  warehouse?: string;
}

interface IProductOptional {
  totalItem: number;
  isDeleted?: boolean;
  deletedAt?: Date;
}
const PurchaseOrderCreatePage: FC = () => {
  const classes = useStyles();
  const { history, location } = useRouter();
  // eslint-disable-next-line
  const params: any = location.state;
  let cancelToken: CancelTokenSource = axios.CancelToken.source();
  const { currentUser } = useContext(CurrentUserContext);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [orderNumber, setOrderNumber] = useState<string>('');
  const [orderDate, setOrderDate] = useState<Date | null>(new Date());
  const [travelDate, setTravelDate] = useState<Date | null>(new Date());
  const [logisticPrice, setLogisticPrice] = useState<number>(0);

  const [orderDateMessage, setOrderDateMessage] = useState<string>('');
  const [travelDateMessage, setTravelDateMessage] = useState<string>('');
  const [logisticMessage, setLogisticMessage] = useState<string>('');
  const [isDeletedConfirm, setIsDeletedConfirm] = useState<{ id: number; isOpen: boolean }>({
    id: 0,
    isOpen: false
  });

  const [partner, setPartner] = useState<PartnerModel>(dummyPartner);
  const [partners, setPartners] = useState<PartnerModel[]>([dummyPartner]);
  const [partnerMessage, setPartnerMessage] = useState<string>('');
  const [loadPartner, setLoadPartner] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [wareHouse, setWareHouse] = useState<number>(0);
  const [wareHouses, setWareHouses] = useState<WareHouseModel[]>([]);
  const [warehouseMessage, setWareHouseMessage] = useState<string>('');
  const [loadWareHouse, setLoadWareHouse] = useState<boolean>(false);
  const [productReviewResources, setProductReviewResources] = useState<(TProduct & IProductOptional)[]>([]);
  const [productResources, setProductResources] = useState<ProductModel[]>([]);
  const [productMeta, setProductMeta] = useState<MetaData>(dummyMetaData);
  const [loadProductResources, setLoadProductResources] = useState<boolean>(true);
  const [categories, setCategories] = useState<{ isLoading: boolean; data: CategoryModel[] }>({
    isLoading: false,
    data: []
  });

  const [openImage, setOpenImage] = useState<boolean>(false);
  const [imagePath, setImagePath] = useState<string>('');
  const [category, setCategory] = useState<CategoryModel>(dummyCategory);
  const [keyword, setKeyword] = useState<string>('');
  const [productName, setProductName] = useState<string>('');

  const [suggestResource, setSuggestResource] = useState<{ isShow: boolean; data: { productName: string, wareHouse: string }[] }>({
    isShow: false,
    data: []
  });
  const [id, setId] = useState<number>(0);
  const [purchaseOrderItem, setPurchaseOrderItem] = useState<PurchaseOrderItemModel[]>([]);
  const [notes, setNotes] = useState<string>('');
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [loadUpdate, setLoadUpdate] = useState<boolean>(false);
  const [purchaseOrderItemMessage, setPurchaseOrderItemMessage] = useState<string>('');
  const [orderNumberLoad, setOrderNumberLoad] = useState<boolean>(false);
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const salesRoute = currentUser && currentUser.SalesRoute ? currentUser.SalesRoute.map(value => value.ZoneId) : [];

  const fetchData = async () => {
    setLoadUpdate(true);

    Promise.all([axios.get(GET_PURCHASE_ORDER_DETAIL_BASE_URL(params.id)), axios.get(GET_PURCHASE_ORDER_ITEM_BASE_URL(params.id))])
      .then(result => {
        const order = result[0].data.data;

        setId(order.id);
        setOrderDate(new Date(order.orderDate));
        setTravelDate(new Date(order.travelDate));
        setLogisticPrice(order.logisticPrice);
        setOrderNumber(order.orderNumber);
        setNotes(order.notes);
        setPartner(prevState => ({ ...prevState, id: order.PartnerId, name: order.supplierName, partnerType: 'SUPPLIER' }));
        setPartners([partner]);
        setWareHouse(order.WareHouseId);

        const item = result[1].data.data;
        setProductReviewResources(item.map((value: any) => ({ ...value, productImage: '' })));
        setIsPreview(true);
      })
      .catch(error => {
        console.log('error :', error);
      })
      .finally(() => {
        setLoadUpdate(false);
      });
  };

  const handleOpenImage = (imagePath: string) => {
    setOpenImage(true);
    setImagePath(imagePath);
  };

  const handleCloseImage = () => {
    setOpenImage(false);
  };

  const handleSearchPartner = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    params.append('partnerType', 'SUPPLIER');
    setLoadPartner(true);
    try {
      const { data } = await axios.get(`${PARTNER_BASE_URL}?${params.toString()}`);
      setPartners(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadPartner(false);
    }
  };

  const fetchWareHouse = async () => {
    setLoadWareHouse(true);
    try {
      const { data } = await axios.get(WAREHOUSE_BASE_URL);
      setWareHouses(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadWareHouse(false);
    }
  };

  const handleOrderNumber = async () => {
    setOrderNumberLoad(true);
    try {
      const { data } = await axios.get(GET_PURCHASE_ORDER_NUMBER_BASE_URL);
      setOrderNumber(data.data.orderNumber);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setOrderNumberLoad(false);
    }
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
    history.push(`/purchase/${id}`);
  };

  const fetchProductResource = async () => {
    setLoadProductResources(true);
    const params = new URLSearchParams();
    if (productName) {
      params.append('keyword', productName);
    }
    if (category && category.id > 0) {
      params.append('CategoryId', String(category.id));
    }
    params.append('isProductPackage', 'false');
    params.append('route', salesRoute.join(','));
    params.append('page', String(page));

    if (wareHouse !== 0) {
      params.append('WareHouseId', wareHouse.toString());
    }

    try {
      const { data } = await axios.get(`${PRODUCT_BASE_URL}?${params.toString()}`, { cancelToken: cancelToken.token });
      setProductResources(data.data);
      setProductMeta(data.meta);
      setLoadProductResources(false);
    } catch (error) {
      console.log('error :', error);
    }
  };

  const fetchProductSearchSuggest = async () => {
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (keyword) {
        params.append('keyword', keyword);
      }
      params.append('isProductPackage', 'false');

      return params;
    };
    try {
      const { data } = await axios.get(`${PRODUCT_SEARCH}?${getQueryParams()}`, { cancelToken: cancelToken.token });
      if (data.data.length > 0) {
        setSuggestResource(prev => ({ ...prev, data: data.data }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCategory = async () => {
    const params = new URLSearchParams();
    params.append('product', 'true');
    setCategories(prev => ({ ...prev, isLoading: true }));
    try {
      const { data } = await axios.get(`${CATEGORY_BASE_URL}?${params.toString()}`);
      setCategories(() => ({ isLoading: true, data: data.data }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnSubmit = async () => {
    resetValidation();
    setSubmit(true);

    if (!validation()) {
      setSubmit(false);
      return;
    }

    try {
      const { data } = await axios.post(PURCHASE_ORDER_BASE_URL, {
        id,
        orderNumber,
        orderDate,
        PartnerId: partner!.id,
        totalItem: productReviewResources.filter(value => !value.isDeleted).reduce((prev, item) => prev + item.totalItem, 0),
        items: productReviewResources.map(value => {
          if (value.id === 0) {
            return value;
          }
          return value;
        }),
        WareHouseId: wareHouse,
        notes,
        travelDate,
        logisticPrice
      });

      setId(data.data.id);
      handleSnackBar(true, 'success', 'Order Pembelian berhasil dibuat');
    } catch (error) {
      console.log('error :', error);
      handleSnackBar(true, 'error', 'Order Pembelian gagal dibuat');
    } finally {
      setSubmit(false);
    }
  };

  const handleBlurSuggest = () => {
    setTimeout(() => {
      setSuggestResource(prev => ({ ...prev, isShow: false }));
    }, 300);
  };
  const handleFocusSuggest = () => {
    setSuggestResource(prev => ({ ...prev, isShow: true }));
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setProductName(keyword);
      setPage(1);
      handleBlurSuggest();
    }
  };

  const handleClickSuggest = (keyword: string) => {
    setProductName(keyword);
    setKeyword(keyword);
    handleBlurSuggest();
    setPage(1);
  };

  const handleClickClearSuggest = () => {
    setProductName('');
    setKeyword('');
    handleBlurSuggest();
    setPage(1);
  };

  const handleProductReview = () => setIsPreview(prev => !prev);

  const handleIncreaseCartQuantity = ({
    id,
    PurchaseOrderId,
    ProductId,
    typeUnit,
    productName,
    productImage,
    productCode,
    totalStock,
    holdItem,
    netStock,
    warehouse
  }: TProduct) => {
    setProductReviewResources(prev => {
      if (prev.every(value => value.ProductId !== ProductId)) {
        return [
          ...prev,
          {
            id,
            PurchaseOrderId,
            ProductId,
            typeUnit,
            totalItem: 1,
            productName,
            productImage,
            productCode,
            totalStock,
            holdItem,
            netStock,
            warehouse
          }
        ];
      } else {
        return prev.map(value => {
          if (value.ProductId === ProductId) {
            return { ...value, totalItem: value.totalItem + 1 };
          } else {
            return value;
          }
        });
      }
    });
  };

  const handleDecreaseCartQuantity = (id: number) =>
    setProductReviewResources(prev => {
      if (prev.filter(item => item.ProductId === id)[0].totalItem === 0) {
        return prev.filter(item => item.ProductId !== id);
      } else {
        return prev.map(item => {
          if (item.ProductId === id) {
            return {
              ...item,
              totalItem: item.totalItem - 1
            };
          } else {
            return item;
          }
        });
      }
    });

  const handleGetCartQuantity = (id: number) => {
    const productTemp = productReviewResources.find(value => value.ProductId === id);
    if (productTemp) {
      return productTemp.totalItem;
    } else {
      return 0;
    }
  };

  const checkProductHasCart = (id: number) => {
    return productReviewResources.filter(value => !value.isDeleted).some(value => value.ProductId === id);
  };

  const handleOpenProductCartDelete = (id: number) => {
    setIsDeletedConfirm(() => ({ id, isOpen: true }));
  };

  const handleCloseProductCartDelete = () => {
    setIsDeletedConfirm(prev => ({ ...prev, isOpen: false }));
  };

  const handleProductCartDelete = () => {
    setProductReviewResources(prev => {
      if (prev.filter(value => value.ProductId !== isDeletedConfirm.id).length < 1) {
        setIsPreview(false);
      }

      return prev
        .filter(value => (value.id === 0 ? value.ProductId !== isDeletedConfirm.id : true))
        .map(value => {
          if (value.ProductId === isDeletedConfirm.id) {
            return { ...value, isDeleted: true, deletedAt: new Date() };
          }
          return value;
        });
    });
    setIsDeletedConfirm(prev => ({ ...prev, isOpen: false }));
  };

  const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    if (!isNaN(+value)) {
      setProductReviewResources(prev => {
        return prev.map(item => {
          if (item.ProductId === +id) {
            return { ...item, totalItem: +value };
          } else {
            return item;
          }
        });
      });
    }
  };

  const validationCreateOrder = isSubmit || productReviewResources.filter(value => !value.isDeleted).some(value => value.totalItem < 1);

  const validation = (): boolean => {
    let valid = true;

    if (!partner || partner.id === 0) {
      setPartnerMessage('Supllier tidak boleh kosong.');
      valid = false;
    }

    if (!orderDate) {
      setOrderDateMessage('Tanggal Order tidak boleh kosong.');
      valid = false;
    }

    if (!travelDate) {
      setTravelDateMessage('Tanggal Surat Jalan tidak boleh kosong.');
      valid = false;
    }

    if (!logisticPrice || logisticPrice === 0) {
      setLogisticMessage('Biaya logistik tidak boleh kosong.');
      valid = false;
    }

    if (wareHouse === 0) {
      setWareHouseMessage('Gudang Tidak Boleh Kosong.');
      valid = false;
    }

    return valid;
  };

  const resetValidation = () => {
    setPartnerMessage('');
    setOrderDateMessage('');
    setPurchaseOrderItemMessage('');
    setWareHouseMessage('');
    setTravelDateMessage('');
    setLogisticMessage('');
  };

  useEffect(() => {
    setPurchaseOrderItemMessage(wareHouse === 0 ? 'Harap memilih gudang terlebih dahulu untuk memasukan produk.' : '');
  }, [wareHouse]);

  useEffect(() => {
    if (!params) {
      handleOrderNumber();
      return;
    }
    fetchData();
  }, []);

  const handleChageProductPage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };
  useEffect(() => {
    fetchWareHouse();
  }, []);

  useEffect(() => {
    fetchCategory();
  }, []);

  const keywordDebounce = useDebounce(keyword, 500);

  useEffect(() => {
    if (keywordDebounce.length > 3) {
      fetchProductSearchSuggest();
    }
    return () => cancelToken.cancel('No longer latest query');
  }, [keywordDebounce]);

  useEffect(() => {
    fetchProductResource();
    return () => cancelToken.cancel('No longer latest query');
  }, [wareHouse, productName, page, category]);

  return (
    <Page title='Pembelian'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Pembelian </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>
        <PaperCustom>
          <FlexBox container direction='row' rowGap={1} xs={12}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant='h5'> Order Pembelian </Typography>
            </Grid>

            <Grid container direction='row' justify='space-between' xl={12} lg={12} md={12} sm={12} xs={12} spacing={1}>
              <Grid item lg={6} sm={6} md={6}>
                <TextField
                  id='orderNumber'
                  fullWidth
                  label='No Order'
                  placeholder='No Order Pembelian'
                  value={orderNumber}
                  onChange={e => setOrderNumber(e.target.value)}
                  disabled
                  InputProps={{
                    endAdornment: (loadUpdate || orderNumberLoad) && <CircularProgress color='inherit' size={20} />
                  }}
                />
              </Grid>
              <Grid item lg={3} sm={12} md={3}>
                <TextField
                  id='orderDate'
                  required
                  fullWidth
                  type='date'
                  label='Tanggal Terima Barang'
                  placeholder='Tanggal Terima Barang'
                  value={orderDate && format(new Date(orderDate), 'yyyy-MM-dd')}
                  onChange={e => setOrderDate(new Date(e.target.value))}
                  error={orderDateMessage !== ''}
                  helperText={orderDateMessage}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item lg={3} sm={12} md={3}>
                <TextField
                  id='travelDate'
                  required
                  fullWidth
                  type='date'
                  label='Tanggal Surat Jalan'
                  value={travelDate && format(new Date(travelDate), 'yyyy-MM-dd')}
                  onChange={e => setTravelDate(new Date(e.target.value))}
                  error={travelDateMessage !== ''}
                  helperText={travelDateMessage}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item lg={6} sm={6} md={6}>
                <Autocomplete
                  id='partner'
                  value={partner}
                  options={partners}
                  getOptionLabel={option => option.name}
                  getOptionSelected={(option, value) => {
                    return value.id === option.id;
                  }}
                  onChange={(event: any, value: any) => value && setPartner(value)}
                  onOpen={e => handleSearchPartner('')}
                  loading={loadPartner}
                  onInputChange={(event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
                    if (reason === 'clear') {
                      setPartner(dummyPartner);
                      setPartners([dummyPartner]);
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label='Pilih Supplier'
                      onChange={e => handleSearchPartner(e.target.value)}
                      error={partnerMessage !== ''}
                      helperText={partnerMessage}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {loadPartner && <CircularProgress color='inherit' size={20} />}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        )
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={3} sm={12} md={3}>
                <TextField
                  fullWidth
                  id='logisticPrice'
                  label='Biaya Logistik'
                  value={logisticPrice === 0 ? null : logisticPrice}
                  onChange={e => setLogisticPrice(+e.target.value)}
                  helperText={logisticMessage}
                  error={logisticMessage !== ''}
                  InputProps={{
                    inputComponent: NumberFormatMask as any
                  }}
                />
              </Grid>

              <Grid item lg={3} sm={12} md={3}>
                <TextField
                  fullWidth
                  select
                  id='warehouse'
                  label='Pilih Gudang'
                  value={wareHouse}
                  helperText={warehouseMessage}
                  error={warehouseMessage !== ''}
                  disabled={params}
                  onChange={event => {
                    /* Setting the state of the warehouse to the value of the event.target.value. */
                    setWareHouse(+event.target.value);
                    /* Setting the productReviewResources to an empty array. */
                    setProductReviewResources([]);
                  }}
                >
                  <MenuItem key={0} value={0} disabled>
                    Pilih Nama Gudang
                  </MenuItem>

                  {wareHouses.map(value => (
                    <MenuItem key={value.id} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid item xl={12} md={12} sm={12}>
              <FormHelperText error={purchaseOrderItemMessage !== ''}>{purchaseOrderItemMessage}</FormHelperText>
            </Grid>

            {!isPreview && (
              <Grid xs={12}>
                <ProductCardList
                  isLoading={loadProductResources}
                  productResources={productResources}
                  handleIncreaseCartQuantity={handleIncreaseCartQuantity}
                  handleGetCartQuantity={handleGetCartQuantity}
                  handleDecreaseCartQuantity={handleDecreaseCartQuantity}
                  handleChangeQuantity={handleChangeQuantity}
                  checkProductHasCart={checkProductHasCart}
                  handleOpenImage={handleOpenImage}
                  topSection={
                    <>
                      <Grid xs={12} container spacing={1} alignItems='stretch'>
                        <Grid xs={4} item container>
                          <Autocomplete
                            loading={categories.isLoading}
                            loadingText={<LinearProgress />}
                            disableListWrap
                            fullWidth
                            options={categories.data}
                            getOptionLabel={option => option.name}
                            getOptionSelected={(option, value) => {
                              return value.id === option.id;
                            }}
                            onChange={(event, value) => {
                              if (value) {
                                setCategory(value);
                              }
                            }}
                            size='small'
                            PaperComponent={({ children }) => <Grid className={classes.containerBox}>{children}</Grid>}
                            renderInput={params => <TextField {...params} label='Kategori' variant='outlined' required fullWidth />}
                          />
                        </Grid>
                        <Grid xs={8} item container alignItems='center' className={classes.containerSuggest}>
                          <FormControl size='small' fullWidth>
                            <OutlinedInput
                              id='productName'
                              autoComplete='off'
                              value={keyword}
                              onChange={event => setKeyword(event.target.value)}
                              onBlur={handleBlurSuggest}
                              onFocus={handleFocusSuggest}
                              onKeyDown={handleKeyDown}
                              placeholder='Cari nama produk'
                              endAdornment={
                                <InputAdornment position='end'>
                                  {keyword.length > 0 && (
                                    <IconButton aria-label='toggle to clear' edge='end' onClick={handleClickClearSuggest}>
                                      <CloseRounded />
                                    </IconButton>
                                  )}
                                  <IconButton aria-label='toggle search product' edge='end' onClick={() => handleClickSuggest(keyword)}>
                                    <SearchRounded />
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>

                          {suggestResource.isShow && suggestResource.data.length > 0 && (
                            <Grid className={clsx(classes.containerBox, classes.absolute)} xs={12}>
                              {suggestResource.data.map(productSugest => (
                                <ListItem button onClick={() => handleClickSuggest(productSugest.productName)}>
                                  <ListItemIcon>
                                    <SearchRounded fontSize='default' />
                                  </ListItemIcon>
                                  <ListItemText    primary={`${productSugest.productName} (${productSugest.wareHouse})`} />
                                </ListItem>
                              ))}
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid xs={12} container alignItems='center'>
                        <Grid xs={6}>
                          <Typography>
                            {`Menampilkan ${productMeta.total || 0} Surat Jalan (${productMeta.from || 0} - ${productMeta.to ||
                              0} dari ${productMeta.total || 0})`}{' '}
                          </Typography>
                        </Grid>
                        <Grid xs={6} container justify='flex-end'>
                          <PaginationCustom
                            show={productResources.length > 0}
                            sxPagination={{
                              count: productMeta.last_page,
                              boundaryCount: 2,
                              variant: 'outlined',
                              shape: 'rounded',
                              onChange: handleChageProductPage,
                              page: productMeta.current_page
                            }}
                            customPageProps={{
                              defaultValue: page,
                              maxValue: productMeta?.last_page ?? 0,
                              onSubmit(value) {
                                setPage(value);
                              }
                            }}
                            sxPopover={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right'
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  }
                  bottomSection={
                    <Grid xs={12} className={classes.actionContainer}>
                      <Button fullWidth size='small' onClick={handleProductReview} disabled={productReviewResources.length < 1}>
                        Lanjutkan ({productReviewResources.filter(value => !value.isDeleted).length})
                      </Button>
                    </Grid>
                  }
                />
              </Grid>
            )}
            {isPreview && (
              <Grid xs={12}>
                <ProductReview
                  productReviewResources={productReviewResources.filter(value => !value.isDeleted)}
                  handleIncreaseCartQuantity={handleIncreaseCartQuantity}
                  handleGetCartQuantity={handleGetCartQuantity}
                  handleDecreaseCartQuantity={handleDecreaseCartQuantity}
                  handleChangeQuantity={handleChangeQuantity}
                  handleOpenProductCartDelete={handleOpenProductCartDelete}
                  handleOpenImage={handleOpenImage}
                  topSection={
                    <Grid xs={12} container alignItems='center'>
                      <Grid xs={6}>
                        <Typography variant='h5'>Review Product</Typography>
                      </Grid>
                      <Grid xs={6} container justify='flex-end'>
                        <Button startIcon={<AddRounded />} onClick={handleProductReview}>
                          Produk Lainnya
                        </Button>
                      </Grid>
                    </Grid>
                  }
                  bottomSection={
                    <>
                      <Grid xs={12}>
                        <ReactQuill
                          id='notes'
                          value={notes}
                          onChange={(value: any) => setNotes(value)}
                          placeholder='Catatan'
                          className={classes.editorNotes}
                        />
                      </Grid>
                      <Grid xs={12} className={classes.actionContainer}>
                        <Button fullWidth size='small' disabled={validationCreateOrder} onClick={handleOnSubmit}>
                          {isSubmit ? <CircularProgress color='primary' size={20} /> : 'Buat Order Pembelian'}
                        </Button>
                      </Grid>
                    </>
                  }
                />
              </Grid>
            )}

            <StandardConfirmationDialog
              variant={snackbarVariant}
              titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
              message={message}
              open={openSnackbar}
              handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
              onConfirm={handleConfirmSnackbar}
              noCancelButton={true}
            />
            <ProductDeleteConfirm open={isDeletedConfirm.isOpen} handleClose={handleCloseProductCartDelete} handleDelete={handleProductCartDelete} />
            <ImageModal open={openImage} handleClose={handleCloseImage} imageUrl={imagePath} />
          </FlexBox>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default PurchaseOrderCreatePage;
