import React, { FC, useState, useEffect } from 'react';
import { TextField, Button, Grid } from '@material-ui/core';
import axios from 'axios';
import { ZONE_BASE_URL } from 'constants/url';

interface Props {
  zones: ZoneModel[];
  zone: ZoneModel | null;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setZones: React.Dispatch<React.SetStateAction<ZoneModel[]>>;
  setZone: React.Dispatch<React.SetStateAction<ZoneModel | null>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const CreateZoneForm: FC<Props> = props => {
  const { zones, zone, setZones, setZone, setOpen, handleSnackBar } = props;
  const [id, setId] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [repaymentDay, setRepaymentDay] = useState<number>();

  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [nameMessage, setNameMessage] = useState<string>('');

  useEffect(() => {
    if (!zone) {
      return;
    }

    setId(zone.id);
    setName(zone.name);
    setDescription(zone.description);
    setRepaymentDay(zone.repaymentDay || 0);
  }, [zone]);

  const handleOnCancel = (): void => {
    setOpen(false);
    resetForm();
  };

  const handleOnSubmit = async () => {
    resetValidation();

    if (!validation()) {
      return;
    }

    try {
      setSubmit(true);
      const { data } = await axios.post(`${ZONE_BASE_URL}`, {
        id,
        name,
        description,
        repaymentDay
      });

      if (zone) {
        setZones(
          zones.map(value => {
            if (value.id === data.data.id) {
              return data.data;
            }
            return value;
          })
        );
        resetForm();
      } else {
        setZones([data.data, ...zones]);
        resetForm();
      }

      setOpen(false);
      handleSnackBar(true, 'success', zone ? 'Rute berhasil diperbaharui.' : 'Rute berhasil ditambahkan.');
    } catch (error) {
      errorRequest(error.data.error);
      handleSnackBar(true, 'error', zone ? 'Rute gagal diperbaharui.' : 'Rute gagal ditambahkan.');
    }
    setSubmit(false);
  };

  const validation = (): boolean => {
    let valid = true;

    if (name === '' || !name) {
      setNameMessage('Nama tidak boleh kosong.');
      valid = false;
    }

    return valid;
  };

  const resetValidation = () => {
    setNameMessage('');
  };

  const resetForm = () => {
    setName('');
    setDescription('');
    setId(0);
    setZone(null);
    setRepaymentDay(0);
  };

  const errorRequest = (error: { [key: string]: string[] }) => {
    if (error) {
      if (error.name) {
        setNameMessage(error.name[0]);
      }
    }
  };

  return (
    <Grid container direction='row' spacing={1}>
      <Grid item lg={12} sm={12} md={12} xs={12}>
        <TextField
          id='name'
          name='name'
          required
          fullWidth
          label='Nama Rute'
          value={name}
          onChange={event => setName(event.target.value)}
          error={nameMessage !== ''}
          helperText={nameMessage}
        />
      </Grid>

      <Grid item lg={12} sm={12} md={12} xs={12}>
        <TextField
          id='repaymentDay'
          fullWidth
          size='small'
          label='Tempo Pelunasan'
          value={repaymentDay}
          onChange={event => setRepaymentDay(+event.target.value)}
        />
      </Grid>

      <Grid item lg={12} sm={12} md={12} xs={12}>
        <TextField
          id='Deskripsi'
          rows={4}
          multiline
          fullWidth
          size='small'
          variant='outlined'
          label='Deskripsi'
          value={description}
          onChange={event => setDescription(event.target.value)}
        />
      </Grid>

      <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} justify='center'>
        <Grid item>
          <Button disabled={isSubmit} color='secondary' onClick={handleOnCancel}>
            Batal
          </Button>
        </Grid>
        <Grid item>
          <Button type='submit' disabled={isSubmit} onClick={handleOnSubmit}>
            Simpan
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CreateZoneForm;
