import React, { FC } from 'react';
import { makeStyles, Table, TableCell, TableBody, TableRow } from '@material-ui/core';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom, ButtonCollapse, TableRowCollapse } from 'components';
import { ORANGE, RED } from 'constants/colors';
import clx from 'clsx';
import Skeleton from '@material-ui/lab/Skeleton';
import NumberFormat from 'react-number-format';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
interface Props {
  isLoading: boolean;
  index: number;
  item: StockCheckItemModel;
  isFirstStock: boolean;
  itemAdjustment: StockCheckItemModel[];
  isLoadingCollapse: boolean;
  indexCollapse: number;
  openCollapse: boolean;
  handleOpenCollapse: React.MouseEventHandler;
}

const useStyles = makeStyles({
  danger: {
    color: RED
  },
  warning: {
    color: ORANGE
  },
  ok: {
    color: '#27AE60'
  }
});

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { item, isLoading, isFirstStock, itemAdjustment, isLoadingCollapse, indexCollapse, openCollapse, handleOpenCollapse } = props;

  const handleDiff = () => {
    let diff = item.adjustItem - item.currentItem;

    return item.adjustItem !== item.currentItem ? (
      <span className={clx(item.adjustItem > item.currentItem && classes.warning, item.adjustItem < item.currentItem && classes.danger)}>
        ({item.adjustItem > item.currentItem ? '+' : ''} {diff})
      </span>
    ) : (
      ''
    );
  };

  const handleCollapseDiff = (_item: StockCheckItemModel) => {
    let diff = _item.adjustItem - _item.currentItem;

    return _item.adjustItem !== _item.currentItem ? (
      <span className={clx(_item.adjustItem > _item.currentItem && classes.warning, _item.adjustItem < _item.currentItem && classes.danger)}>
        ({_item.adjustItem > _item.currentItem ? '+' : ''} {diff})
      </span>
    ) : (
      ''
    );
  };

  let firstStock = 0;

  if (isFirstStock) {
    firstStock = item.diff || 0;
  }

  return (
    <>
      <TableRowCustom>
        <TableCellStart align='left'>
          <ButtonCollapse
            name={item.productName || '-'}
            isLoading={false}
            isOpen={openCollapse && item.ProductId === indexCollapse}
            useCollapse={item.hasAdjustment ? item.hasAdjustment : false}
            handleOpenCollapse={handleOpenCollapse}
            handleNameLink={() => console.log('')}
          />
        </TableCellStart>

        <TableCellMiddle align='center'>{isLoading ? <Skeleton variant='text' width={100} height={25} /> : item.typeUnit || '-'}</TableCellMiddle>

        <TableCellMiddle align='center'>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : !isFirstStock ? (
            item.firstStock || 0
          ) : (
            item.adjustItem - (item.diff || 0)
          )}
          {isFirstStock && !isLoading && <ArrowForwardIcon fontSize='small' style={{ verticalAlign: 'middle' }} />}
          {isFirstStock && !isLoading && item.adjustItem}
          {isFirstStock && !isLoading && (
            <span
              className={clx(
                item.adjustItem > item.adjustItem - (item.diff || 0) && classes.warning,
                item.adjustItem < item.adjustItem - (item.diff || 0) && classes.danger
              )}
            >
              {`(${item.adjustItem > item.firstStock ? '+' : ''}${firstStock})`}
            </span>
          )}
        </TableCellMiddle>

        <TableCellMiddle align='center'>
          {isLoading ? <Skeleton variant='text' width={100} height={25} /> : item.currentItem || 0}
          {!isLoading && <ArrowForwardIcon fontSize='small' style={{ verticalAlign: 'middle' }} />}

          {isFirstStock && !isLoading && item.currentItem + firstStock}
          {!isFirstStock && !isLoading && item.adjustItem}

          {isFirstStock && !isLoading && (
            <span className={clx(firstStock > 0 && classes.warning, firstStock < 0 && classes.danger)}>
              {`(${item.diff && item.diff > 0 ? '+' : ''}${item.diff})`}
            </span>
          )}

          {!isFirstStock && !isLoading && handleDiff()}
        </TableCellMiddle>

        <TableCellMiddle align='center'>
          {isLoading ? (
            <Skeleton variant='text' width={120} height={25} />
          ) : (
            <>
              <NumberFormat value={item.purchasePrice || 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              {item.isUpdatePrice && (
                <>
                  <ArrowForwardIcon fontSize='small' style={{ verticalAlign: 'middle' }} />
                  <NumberFormat
                    value={(item.adjustPrice && item.adjustPrice) || 0}
                    prefix={'Rp'}
                    thousandSeparator={true}
                    displayType='text'
                    className={clx(
                      item.adjustPrice && item.adjustPrice > item.purchasePrice && classes.warning,
                      item.adjustPrice && item.adjustPrice < item.purchasePrice && classes.danger
                    )}
                  />
                </>
              )}
            </>
          )}
        </TableCellMiddle>

        <TableCellEnd align='center' className={clx(item.adjustItem === item.currentItem && classes.ok)}>
          {isLoading ? <Skeleton variant='text' width={120} height={25} /> : item.adjustItem}
        </TableCellEnd>
      </TableRowCustom>

      <TableRowCollapse open={openCollapse && item.ProductId === indexCollapse && !isLoading} colSpan={6}>
        <Table size='small'>
          <TableBody>
            {isLoadingCollapse ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <Skeleton variant='text' width='100%' height={25} />
                </TableCell>
              </TableRow>
            ) : (
              itemAdjustment.length > 0 &&
              itemAdjustment.map((value: StockCheckItemModel, index: number) => (
                <TableRow key={index}>
                  <TableCell>{value.createdAt || '-'}</TableCell>

                  <TableCell align='center'>{value.typeUnit || '-'}</TableCell>

                  <TableCell align='center'>
                    {value.adjustItem - (value.diff || 0)}
                    {isFirstStock && <ArrowForwardIcon fontSize='small' style={{ verticalAlign: 'middle' }} />}
                    {isFirstStock && value.adjustItem}
                    {isFirstStock && (
                      <span
                        className={clx(
                          value.adjustItem > value.adjustItem - (value.diff || 0) && classes.warning,
                          value.adjustItem < value.adjustItem - (value.diff || 0) && classes.danger
                        )}
                      >
                        {`(${value.adjustItem > value.firstStock ? '+' : ''}${value.diff || 0})`}
                      </span>
                    )}
                  </TableCell>

                  <TableCell align='center'>
                    {isLoading ? <Skeleton variant='text' width={100} height={25} /> : value.currentItem || 0}
                    {<ArrowForwardIcon fontSize='small' style={{ verticalAlign: 'middle' }} />}

                    {isFirstStock && value.currentItem + firstStock}

                    {isFirstStock && (
                      <span className={clx(firstStock > 0 && classes.warning, firstStock < 0 && classes.danger)}>
                        {`(${value.diff && value.diff > 0 ? '+' : ''}${value.diff})`}
                      </span>
                    )}

                    {!isFirstStock && handleCollapseDiff(value)}
                  </TableCell>

                  <TableCell align='center'>{value.adjustItem}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableRowCollapse>
    </>
  );
};

export default BodyRow;
