import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { FC } from 'react';
interface Props {
  isLoading: boolean;
  company: CompanyModel;
  partner: PartnerModel;
}

const CompanyInfo: FC<Props> = ({ isLoading, company, partner }) => {
  return (
    <Grid xs={12} container justify='space-between'>
      <Grid xs={5}>
        <Typography variant='h5'>Nama Perusahaan</Typography>

        <Typography variant='h6'>{isLoading ? <Skeleton /> : company.name}</Typography>
        <Typography>{isLoading ? <Skeleton /> : company.address}</Typography>
        <Typography>{isLoading ? <Skeleton /> : `Tel: ${company.phoneNumber || '-'}`}</Typography>
        <Typography>{isLoading ? <Skeleton /> : `No. Hp: ${company.cellPhoneNumber || '-'}`}</Typography>
        <Typography>{isLoading ? <Skeleton /> : `Email: ${company.email || '-'}`}</Typography>
      </Grid>
      <Grid xs={5}>
        <Typography variant='h5' align='right'>
          Order Penjualan dari
        </Typography>
        <Typography variant='h6' align='right'>
          {isLoading ? <Skeleton width='100%' /> : partner.name}
        </Typography>
        <Typography align='right'>{isLoading ? <Skeleton /> : partner.address || '-'}</Typography>
        <Typography align='right'> {isLoading ? <Skeleton /> : `Tel: ${partner.phoneNumber || '-'}`}</Typography>
        <Typography align='right'>{isLoading ? <Skeleton /> : `No. Hp: ${partner.cellPhoneNumber || '-'}`}</Typography>
        <Typography align='right'>{isLoading ? <Skeleton /> : `Email: ${partner.email || '-'}`}</Typography>
      </Grid>
    </Grid>
  );
};

export default CompanyInfo;
