import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Checkbox } from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart } from 'components';
import TableRowCustom from 'components/Table/TableRowCustom';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';

interface Props {
  item: InvoicePaymentModel;
  index: number;
  invoice: InvoiceModel;
  isLoading: boolean;
  isUpdate: boolean;
  checked: InvoicePaymentModel[];
  totalPay: number;
  handleIndividualCheck: (item: InvoicePaymentModel) => void;
}

const BodyRow: FC<Props> = props => {
  const { item, invoice, isLoading, isUpdate, checked, totalPay, handleIndividualCheck } = props;
  const { id } = invoice;
  let isChecked = checked.some(value => value.InvoiceId === id);
  const handleSetId = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    handleIndividualCheck(item);
  };

  return (
    <TableRowCustom>
      <TableCellStart>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <Checkbox
            key={id}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<CheckBoxIcon />}
            edge='start'
            color='primary'
            checked={isChecked}
            tabIndex={-1}
            disableRipple
            // disabled={isUpdate}
            onChange={handleSetId}
          />
        )}
      </TableCellStart>

      <TableCellMiddle align='center'>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : invoice.invoiceNumber}</TableCellMiddle>

      <TableCellMiddle align='center'>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : invoice.paymentDue ? (
          format(new Date(invoice.paymentDue), 'dd/MM/yyyy')
        ) : (
          ''
        )}
      </TableCellMiddle>

      <TableCellMiddle align='right'>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <NumberFormat value={invoice.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        )}
      </TableCellMiddle>

      <TableCellEnd align='right'>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <NumberFormat
            value={isUpdate ? invoice.totalPrice - invoice.totalPay + totalPay : invoice.totalPrice - invoice.totalPay}
            prefix={'Rp'}
            thousandSeparator={true}
            displayType='text'
          />
        )}
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRow;
