import React, { FC, useState, useEffect } from 'react';
import { Container, Grid,Typography } from '@material-ui/core';
import axios from 'axios';
import useRouter from 'hooks/useRouter';
import Contents from './components/content';
import { Page, StandardConfirmationDialog, Breadcrumb } from 'components';
import { GET_STOCK_DETAIL_BASE_URL } from 'constants/url';
import { dummyStock } from 'utils/dummy';

const StockOutDetailPage: FC = () => {
  const { location } = useRouter();
  // eslint-disable-next-line
  const params: any = location.state;

  const [stock, setStock] = useState<StockModel>(dummyStock);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [id, setId] = useState<number>(0);

  useEffect(() => {
    setIsLoadingData(true);
    const fetchData = async () => {
      try {
        const { data } = await axios.get(GET_STOCK_DETAIL_BASE_URL(params.id));
        setStock(data.data);
        setId(data.data.id);
      } catch (error) {
        console.log('error:', error);
      }
      setIsLoadingData(false);
    };
    fetchData();
  }, []);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Page title='Stok Detail'>
      <Container>
        <Grid container direction='row'>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <Typography variant='h1'> Stok </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <Grid container direction='row' spacing={2} justify='space-between'>
          <Contents stock={stock} isLoadingData={isLoadingData} />
        </Grid>

        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
          onConfirm={handleCloseSnackbar}
          noCancelButton={true}
        />
      </Container>
    </Page>
  );
};

export default StockOutDetailPage;
