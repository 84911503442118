import React, { FC, Fragment, useState } from 'react';
import {
  Grid,
  makeStyles,
  Theme,
  Typography,
  TableContainer,
  Tabs,
  Tab,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  ButtonGroup,
  CircularProgress,
  Checkbox,
  Popper,
  Divider
} from '@material-ui/core';
import ListProduct from './ListProduct';
import NumberFormat from 'react-number-format';
import ItemStatus from 'typings/enum/ItemStatus';
import axios from 'axios';
import EditingHistory from '../content/components/EditingHistory';
import SalesOrderStatus from 'typings/enum/SalesOrderStatus';
import ModalConvert from '../content/components/ModalConvert';
import { BLACK, RED, WHITE, PRIMARY_MOBILE } from 'constants/colors';
import { TabPanel, a11yProps } from 'components';
import { SET_CONFIRM_PLAFON, SET_CONFIRM_SO } from 'constants/url';
import { WarningRounded } from '@material-ui/icons';

interface Props {
  salesOrder: SalesOrderModel;
  isLoadingData: boolean;
  salesOrderItem: SalesOrderItemModel[];
  value: number;
  isAccess: string;
  historyResource: HistoryType[];
  showComponent: boolean;
  totalWarehouse: { name: string; total: number }[];
  currentCheckWarehouse: string;
  anchorEl: any;
  buttonGroup: React.RefObject<HTMLDivElement>;
  isValid: boolean;
  loadingReject: boolean;
  loadingHistoryResource: boolean;
  handleChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setHasOpenModal: React.Dispatch<React.SetStateAction<number>>;
  handleRejectSuperAdmin: (value: ItemAction) => void;
  handleCancelRejectSuperAdmin: (value: ItemAction) => void;
  setCurrentCheckWarehouse: React.Dispatch<React.SetStateAction<string>>;
  setGetIdClick: React.Dispatch<React.SetStateAction<number>>;
  handleOpenImage: (imagePath: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  cellSummaryRight: {
    border: 'none',
    padding: 4,
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontStyle: 'normal',
    color: BLACK
  },
  cellSummaryLeft: {
    width: '26%',
    border: 'none',
    padding: 4,
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal',
    color: BLACK
  },
  cellInfoRight: {
    padding: '2px 0px 2px 2px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal'
  },

  paper: {
    border: '1px solid #ccc',
    padding: 2,
    backgroundColor: WHITE,
    marginTop: 4,
    borderRadius: 8
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: ' none'
    }
  },
  warningText: {
    color: WHITE,
    fontSize: '0.9rem',
    background: '#EFA101',
    borderRadius: '7px',
    display: 'flex',
    gap: '0.7em',
    alignItem: 'center',
    padding: theme.spacing(2)
  },
  fontStyleBoldWDesktop: {
    fontWeight: 500
  },
  containerTab: {},
  tabsItem: {
    backgroundColor: ' #F7F9FC',
    '&& .Mui-selected': {
      backgroundColor: PRIMARY_MOBILE,
      color: WHITE
    }
  },
  buttonContainerMobile: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 5,
    backgroundColor: '#fff',
    padding: '10px 10px',
    boxShadow: '0px 0px 17px rgba(0, 0, 0, 0.08)'
  }
}));

const SAMobileListProduct: FC<Props> = props => {
  const classes = useStyles();
  const {
    salesOrder,
    isLoadingData,
    salesOrderItem,
    value,
    loadingReject,
    historyResource,
    isAccess,
    loadingHistoryResource,
    totalWarehouse,
    currentCheckWarehouse,
    anchorEl,
    buttonGroup,
    showComponent,
    isValid,
    handleChange,
    handleRejectSuperAdmin,
    setCurrentCheckWarehouse,
    setGetIdClick,
    handleCancelRejectSuperAdmin,
    setOpenEdit,
    setHasOpenModal,
    handleOpenImage
  } = props;
  const [isTab, setIsTab] = useState<number>(0);
  const [loadingConfirmation, setLoadingConfirmation] = useState<boolean>(false);
  const [openModalSuccess, setOpenModalSuccess] = useState<boolean>(false);

  const handleCheck = () => {
    confirmationSO();
  };

  const handleTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setIsTab(newValue);
  };

  const getFilterData = (value: string) => {
    return (
      SalesOrderStatus.PENDING ||
      SalesOrderStatus.CHECKING ||
      SalesOrderStatus.CONFIRM ||
      SalesOrderStatus.PACKING ||
      SalesOrderStatus.PREVIEW ||
      SalesOrderStatus.REJECT
    );
  };

  const fetchData = () => {
    const data = currentCheckWarehouse
      ? salesOrderItem.filter(value => value.warehouse && value.warehouse === currentCheckWarehouse && getFilterData(value.status))
      : salesOrderItem.filter(value => getFilterData(value.status));
    return data;
  };

  const sumSubtotalPrice = salesOrderItem
    .filter(val => val.status !== SalesOrderStatus.REJECT)
    .map(value => value.subTotalPrice)
    .reduce((total, price) => total + price, 0);
  const sumDiscount = salesOrderItem
    .filter(val => val.status !== SalesOrderStatus.REJECT)
    .map(value => value.discount)
    .reduce((total, price) => total + price, 0);
  const sumTotalPrice = salesOrderItem
    .filter(val => val.status !== SalesOrderStatus.REJECT)
    .map(value => value.totalPrice)
    .reduce((total, price) => total + price, 0);

  const confirmationSO = async () => {
    setLoadingConfirmation(true);
    try {
      await axios.get(SET_CONFIRM_SO(salesOrder.id));
      await axios.get(SET_CONFIRM_PLAFON(salesOrder.id));

      setLoadingConfirmation(false);
      setOpenModalSuccess(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <Grid item xs={12}>
        <Divider className={classes.mobile} style={{ marginBottom: `10px` }} />
      </Grid>

      <Grid item xs={12} style={{ marginBottom: '10px', padding: '0px 4px 0px 4px' }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align='left' className={classes.cellSummaryRight}>
                  Subtotal
                </TableCell>
                <TableCell align='right' className={classes.cellSummaryLeft}>
                  <NumberFormat value={sumSubtotalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' className={classes.cellSummaryRight}>
                  Diskon
                </TableCell>
                <TableCell align='right' className={classes.cellSummaryLeft}>
                  <NumberFormat value={sumDiscount} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' className={classes.cellSummaryRight}>
                  Total Harga
                </TableCell>
                <TableCell align='right' className={classes.cellSummaryLeft}>
                  <NumberFormat value={sumTotalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' className={classes.cellSummaryRight}>
                  Jumlah Tertagih
                </TableCell>
                <TableCell align='right' className={classes.cellSummaryLeft}>
                  <NumberFormat value={sumTotalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12}>
        <Divider className={classes.mobile} style={{ marginBottom: `10px` }} />
      </Grid>

      <Grid item xs={12}>
        {!isLoadingData && (!salesOrder.isAccept || salesOrder.isPlafon) && (
          <div style={{ padding: 4, marginBottom: '10px' }}>
            <Typography className={classes.warningText}>
              <WarningRounded style={{ color: WHITE, fontSize: '1.4rem' }} />
              {!salesOrder.isAccept && salesOrder.isPlafon
                ? 'Mohon konfirmasi discount produk dan plafon mitra ini'
                : !salesOrder.isAccept
                ? 'Mohon konfirmasi discount produk mitra ini'
                : salesOrder.isPlafon && 'Mohon konfirmasi plafon mitra ini'}
            </Typography>
          </div>
        )}

        <Grid item xs={12} container justify='flex-end' style={{ padding: '0px 4px 4px 4px' }}>
          <ButtonGroup disabled={isValid} ref={buttonGroup} size='small'>
            {totalWarehouse.map((value, index) => (
              <Button
                key={index}
                size='small'
                onClick={e =>
                  setCurrentCheckWarehouse(prevState => (prevState !== value.name ? value.name : prevState === value.name ? '' : value.name))
                }
              >
                <Checkbox value={value.name} checked={currentCheckWarehouse === value.name} size='small' />
                {`${value.name} (${value.total})`}
              </Button>
            ))}
          </ButtonGroup>
          <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} className={classes.paper}>
            <Typography style={{ padding: 8, color: RED }}>Pilih Salah satu gudang untuk membuat Invoice</Typography>
          </Popper>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.containerTab}>
        <div className={classes.tabsItem}>
          <Tabs value={isTab} textColor='primary' onChange={handleTab} variant='scrollable' scrollButtons='auto'>
            <Tab label={'Daftar Permintaan Produk'} {...a11yProps(0)} />
            <Tab label={'Riwayat Aksi'} {...a11yProps(1)} />
          </Tabs>
        </div>
      </Grid>

      <Grid item xs={12}>
        <TabPanel value={isTab} index={0}>
          <Tabs
            value={value}
            indicatorColor='primary'
            textColor='inherit'
            onChange={handleChange}
            variant='scrollable'
            scrollButtons='auto'
            style={{ borderBottom: '1px solid #E0E0E0' }}
          >
            <Tab label={`Semua Produk (${salesOrderItem.filter(value => getFilterData(value.status)).length})`} {...a11yProps(0)} />
            <Tab label={`Ditolak (${salesOrderItem.filter(value => value.status === ItemStatus.REJECT && value.rejectBy === isAccess).length})`} />
          </Tabs>

          <TabPanel value={value} index={0}>
            <ListProduct
              isAccess={isAccess}
              tab={value}
              loadingReject={loadingReject}
              salesOrder={salesOrder}
              isLoadingData={isLoadingData}
              setHasOpenModal={setHasOpenModal}
              handleOpenImage={handleOpenImage}
              setOpenEdit={setOpenEdit}
              handleRejectSuperAdmin={handleRejectSuperAdmin}
              handleCancelRejectSuperAdmin={handleCancelRejectSuperAdmin}
              setGetIdClick={setGetIdClick}
              salesOrderItem={fetchData()}
            />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <ListProduct
              isAccess={isAccess}
              tab={value}
              loadingReject={loadingReject}
              salesOrder={salesOrder}
              isLoadingData={isLoadingData}
              setHasOpenModal={setHasOpenModal}
              handleOpenImage={handleOpenImage}
              setOpenEdit={setOpenEdit}
              handleRejectSuperAdmin={handleRejectSuperAdmin}
              handleCancelRejectSuperAdmin={handleCancelRejectSuperAdmin}
              setGetIdClick={setGetIdClick}
              salesOrderItem={fetchData()}
            />
          </TabPanel>
        </TabPanel>

        <TabPanel value={isTab} index={1}>
          <Grid container xs={12}>
            <EditingHistory isAccess={isAccess} showComponent={showComponent} historyResource={historyResource} isLoading={loadingHistoryResource} />
          </Grid>
        </TabPanel>
      </Grid>

      <Grid item xs={12} className={classes.buttonContainerMobile}>
        {(!salesOrder.isAccept || salesOrder.isPlafon) && (
          <div style={{ background: 'white', padding: '4px' }}>
            <Button variant='contained' fullWidth color='primary' style={{ borderRadius: '20px' }} onClick={() => handleCheck()}>
              {loadingConfirmation ? <CircularProgress size={20} color='secondary' /> : 'Konfirmasi Sales Order'}
            </Button>
          </div>
        )}
      </Grid>

      <ModalConvert open={openModalSuccess} />
    </Fragment>
  );
};

export default SAMobileListProduct;
