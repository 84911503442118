import React, { FC, Fragment } from 'react';
import { makeStyles, IconButton, Table, TableCell, TableContainer, TableBody, TableRow, Link } from '@material-ui/core';
import { RED, GREEN } from 'constants/colors';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom, TableRowCollapse } from 'components';
import { format, differenceInDays } from 'date-fns';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import NumberFormat from 'react-number-format';
import Skeleton from '@material-ui/lab/Skeleton';
import useRouter from 'hooks/useRouter';
import clx from 'clsx';

interface Props {
  invoice: InvoiceModel;
  isLoading: boolean;
  openCollapse: boolean;
  index: number;
  indexCollapse: number;
  handleOpenCollapse: React.MouseEventHandler;
}

const useStyles = makeStyles({
  tableCollapse: {
    width: '100%'
  },
  cellCollapse: {
    border: 'none'
  },
  cellStart: {
    width: '50px'
  },
  textSuccess: {
    color: GREEN
  },
  textError: {
    color: RED
  },
  noneBorder: {
    border: 'none'
  }
});

const BodyRow: FC<Props> = props => {
  const { invoice, isLoading, openCollapse, index, indexCollapse, handleOpenCollapse } = props;
  const { history } = useRouter();
  const classes = useStyles();


  const note = () => {
    let totalPaymentDueDay = differenceInDays(new Date(invoice.paymentDue || 0), new Date(invoice.dueDate));
    let totalPaymentDay = differenceInDays(invoice.paidDate ? new Date(invoice.paidDate) : new Date(), new Date(invoice.paymentDue!));
    let desc = '-';

    if (invoice.statusPayment === 'PAID' && invoice.paidDate && invoice.paidDate > invoice.paymentDue!) {
      desc = `Pelunasan > (${totalPaymentDueDay}+${totalPaymentDay})`;
    }

    return desc;
  };

  return (
    <Fragment>
      <TableRowCustom>
        <TableCellStart align='center' className={classes.cellStart}>
          {isLoading ? (
            <Skeleton variant='text' width={60} height={25} />
          ) : (
            <IconButton aria-label='expand row' size='small' onClick={handleOpenCollapse}>
              {openCollapse && index === indexCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCellStart>

        <TableCellMiddle align='left'>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <Link href='#' onClick={() => history.push('/invoice/', { id: invoice.id })}>
              {`#${invoice.invoiceNumber}`}
            </Link>
          )}
        </TableCellMiddle>

        <TableCellMiddle align='center'>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            (invoice.createdAt && format(new Date(invoice.createdAt), 'dd/MM/yyyy')) || '-'
          )}
        </TableCellMiddle>

        <TableCellMiddle align='right'>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <NumberFormat value={invoice.totalPrice + invoice.totalPiece!} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>

        <TableCellMiddle align='right'>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <NumberFormat value={invoice.totalPiece!} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>

        <TableCellMiddle align='right'>
          {isLoading ? (
            <Skeleton variant='text' width={120} height={25} />
          ) : (
            <NumberFormat value={invoice.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>

        <TableCellEnd
          align='center'
          className={clx(
            invoice.statusPayment === 'UNPAID' && classes.textError,
            invoice.statusPayment === 'PAID' && invoice.paidDate && invoice.paidDate > invoice.paymentDue! && classes.textError
          )}
        >
          {isLoading ? (
            <Skeleton variant='text' width={120} height={25} />
          ) : invoice.statusPayment === 'PAID' ? (
            <NumberFormat value={invoice.totalPay || 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          ) : (
            'Belum Lunas'
          )}
        </TableCellEnd>
      </TableRowCustom>

      <TableRowCollapse open={openCollapse && index === indexCollapse} colSpan={7}>
        <TableContainer>
          <Table size='small'>
            <TableBody>
              <TableRow>
                <TableCell rowSpan={invoice.InvoicePayment && invoice.InvoicePayment.length > 0 ? invoice.InvoicePayment.length + 1 : undefined}>
                  Riwayat Pembayaran
                </TableCell>
                {invoice.InvoicePayment && invoice.InvoicePayment.length < 1 && (
                  <TableCell align='center' colSpan={3}>
                    Belum Ada Pembayaran.
                  </TableCell>
                )}
              </TableRow>

              {invoice.InvoicePayment &&
                invoice.InvoicePayment.length > 0 &&
                invoice.InvoicePayment.map((value: InvoicePaymentModel, index: number) => (
                  <TableRow key={index}>
                    <TableCell>{format(new Date(value.payDate), 'dd/MM/yyyy')}</TableCell>
                    <TableCell align='right'>
                      <NumberFormat value={value.totalPay || 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </TableCell>
                    <TableCell>-{value.note}</TableCell>
                  </TableRow>
                ))}

              <TableRow>
                <TableCell>Sisa Bayar</TableCell>
                <TableCell colSpan={3} align='right'>
                  <NumberFormat value={invoice.totalPrice - (invoice.totalPay || 0)} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Tanggal Lunas</TableCell>
                <TableCell colSpan={3} align='right'>
                  {(invoice.paidDate && format(new Date(invoice.paidDate), 'dd/MM/yyyy')) || '-'}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell className={classes.noneBorder}>Keterangan</TableCell>
                <TableCell colSpan={3} align='right' className={classes.noneBorder}>
                  {note()}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </TableRowCollapse>
    </Fragment>
  );
};

export default BodyRow;
