import React, { FC, useEffect, useState } from 'react';
import { Grid, makeStyles, Typography, Theme, AppBar, Toolbar, IconButton, TextField } from '@material-ui/core';
import { GREY, PRIMARY_MOBILE, WHITE } from 'constants/colors';
import { Add, PhotoSizeSelectActualOutlined, Remove } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  cotainerAction: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: '1rem'
  },
  buttonAction: {
    background: PRIMARY_MOBILE,
    '&:hover': {
      background: '#008ABF'
    }
  },
  textFieldInput: {
    textAlign: 'center'
  },
  noImage: {
    backgroundColor: GREY,
    height: '60px',
    width: '60px',
    borderRadius: '.5em',
    display: 'grid',
    placeItems: 'center'
  }
}));
interface Props {
  items: SalesOrderItemModel;
  totalBag: number;
}
const CollyItem: FC<Props> = ({ items, totalBag }) => {
  const classes = useStyles();
  const [qty, setQty] = useState<number>(0);
  const [qtyBag, setQtyBag] = useState(totalBag);

  useEffect(() => {
    items.totalInBag = qty;
  }, [qty]);

  return (
    <Grid xs={12}>
      <Grid xs={12} container>
        <Grid xs={10}>
          <Typography variant='h6'>{items.productName}</Typography>
          <Typography color='textSecondary'>
            Total Pengepakan:{' '}
            <span style={{ color: PRIMARY_MOBILE }}>
              {items.totalItem - qtyBag < 0 ? 0 : items.totalItem - qtyBag} {items.typeUnit}
            </span>
          </Typography>
        </Grid>
        <Grid xs={2}>
          {items.productImage ? (
            <img
              src={items.productImage}
              alt='gambar produk'
              width='65px'
              height='65px'
              style={{ borderRadius: '0.3rem', border: '1px solid rgba(0, 0, 0, 0.08)' }}
            />
          ) : (
            <Grid className={classes.noImage}>
              <PhotoSizeSelectActualOutlined />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid xs={12} className={classes.cotainerAction}>
        <IconButton
          aria-label='remove'
          component='div'
          className={classes.buttonAction}
          size='small'
          onClick={() => {
            setQty(qty - 1);
          }}
          disabled={qty + qtyBag < 1}
        >
          <Remove style={{ color: WHITE }} />
        </IconButton>
        <TextField
          value={qty}
          size='small'
          onChange={e => {
            setQty(Number(e.target.value));
          }}
          error={qty + qtyBag > items.totalItem}
          helperText={qty + qtyBag > items.totalItem && 'Jumlah melebihi qty pengepakan'}
          InputProps={{
            classes: {
              input: classes.textFieldInput
            },
            inputMode: 'numeric'
          }}
        />
        <IconButton
          aria-label='add'
          disableFocusRipple={false}
          disableRipple={true}
          component='div'
          disabled={qty + qtyBag >= items.totalItem}
          className={classes.buttonAction}
          onClick={() => {
            setQty(qty + 1);
          }}
          size='small'
        >
          <Add style={{ color: WHITE }} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default CollyItem;
