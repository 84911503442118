import React, { FC, useState } from 'react';
import { Grid, TableRow, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { InfoRounded } from '@material-ui/icons';
import { BLACK, GREEN, ORANGE, RED, WHITE, YELLOW_MOBILE } from 'constants/colors';
import { remarkCode } from 'constants/remarkCode';
import theme from 'theme/hady';
import SalesOrderStatus from 'typings/enum/SalesOrderStatus';
import BodyItem from './BodyItem';

interface Props {
  tab: number;
  salesOrder: SalesOrderModel;
  salesOrderItem: SalesOrderItemModel;
  loadingReject: boolean;
  isAccess: string;
  handleRejectSuperAdmin: (value: ItemAction) => void;
  handleCancelRejectSuperAdmin: (value: ItemAction) => void;
  setGetIdClick: React.Dispatch<React.SetStateAction<number>>;
  setHasOpenModal: React.Dispatch<React.SetStateAction<number>>;
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenImage: (imagePath: string) => void;
}

const useStyles = makeStyles({
  confirm: {
    background: WHITE,
    color: GREEN
  },
  reject: {
    background: WHITE,
    color: RED
  },
  discount: {
    background: WHITE,
    color: ORANGE
  },
  tableCollapse: {
    width: '100%'
  },
  cellCollapse: {
    border: 'none'
  },
  table: {
    backgroundColor: 'rgba(255, 255, 255, 0.87)',
    maxWidth: 220,
    fontSize: '12pt',
    border: '1px solid #dadde9'
  },
  iconPrice: {
    verticalAlign: 'middle',
    marginLeft: 8
  },
  highlight: {
    boxShadow: '0px 2px 4px rgba(0, 136, 186, 0.4)',
    borderRadius: 4
  },
  done: {
    backgroundColor: '#e0e0e0'
  },
  productImage: {
    width: '100%',
    height: '100%'
  },
  defaultImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    color: '#fff'
  },
  bodyContainer: {
    border: '1px solid #E0E0E0',
    borderRadius: '5px',
    marginBottom: theme.spacing(2),

    padding: theme.spacing(1)
  },
  containerRight: {
    display: 'flex'
  },
  descriptionContainer: {
    backgroundColor: WHITE,
    borderRadius: '6px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    border: '1px solid #E0E0E0'
  },
  description: {
    color: BLACK
  },
  warning: {
    color: YELLOW_MOBILE,
    marginRight: '.3em',
    marginTop: '.3em'
  }
});

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const {
    tab,
    salesOrder,
    salesOrderItem,
    isAccess,
    loadingReject,
    handleRejectSuperAdmin,
    handleCancelRejectSuperAdmin,
    setGetIdClick,
    setHasOpenModal,
    setOpenEdit,
    handleOpenImage
  } = props;

  const [isEditReject, setIsEditReject] = useState(0);
  const handleInvoice = (id: number) => {
    window.open(`/invoice/${id}`, '_blank');
  };

  const handleOpenModal = () => {
    setHasOpenModal(10);
    setGetIdClick(salesOrderItem.id);
    setOpenEdit(true);
  };

  const handleOpenEditQty = () => {
    setOpenEdit(true);
    setHasOpenModal(1);
    setGetIdClick(salesOrderItem.id);
  };

  const handleCancelRejectSuper = () => {
    setIsEditReject(salesOrderItem.ProductId);
    const newData = [
      {
        id: salesOrderItem.id,
        remark: 'Super Admin membatalkan penolakan',
        remarkCode: remarkCode.ACCEPT,
        status: SalesOrderStatus.PENDING,
        rejectBy: isAccess
      }
    ];
    handleCancelRejectSuperAdmin({ ...newData[0] });
  };
  const handleRejectSuper = () => {
    setIsEditReject(salesOrderItem.ProductId);
    const newData = [
      {
        id: salesOrderItem.id,
        remark: 'Super Admin melakukan penolakan',
        remarkCode: remarkCode.REJECT,
        status: SalesOrderStatus.REJECT,
        rejectBy: isAccess
      }
    ];
    handleRejectSuperAdmin({ ...newData[0] });
  };

  return (
    <TableRow>
      <BodyItem
        imageUrl={salesOrderItem.productImage || 'noimage'}
        title={salesOrderItem.productName}
        handleOpenImage={handleOpenImage}
        beforeTitle={
          salesOrderItem.notes &&
          salesOrderItem.notes && (
            <Tooltip
              classes={{ tooltip: classes.descriptionContainer }}
              placement='right-end'
              title={
                <Grid>
                  <Typography variant='h6' color='textSecondary'>
                    Catatan:
                  </Typography>
                  <Typography align='justify' className={classes.description}>
                    {(salesOrderItem.notes && salesOrderItem.notes) || 'Tidak ada catatan'}
                  </Typography>
                </Grid>
              }
            >
              <InfoRounded className={classes.warning} />
            </Tooltip>
          )
        }
      />

      <BodyItem title={`Rp${new Intl.NumberFormat('id-ID').format(salesOrderItem.price)}`} />
      <BodyItem
        title={salesOrderItem.totalItem.toString().concat(` ${salesOrderItem.typeUnit}`)}
        //isUpdateQty={salesOrder.isPlafon}
        onClickEdit={handleOpenEditQty}
      />
      <BodyItem
        title={salesOrderItem.discount > 0 ? `Rp${new Intl.NumberFormat('id-ID').format(salesOrderItem.discount)}` : '-'}
        isDiscount={true}
        onClick={handleOpenModal}
        isEditDiscount={salesOrderItem.status === SalesOrderStatus.REJECT || salesOrderItem.status === SalesOrderStatus.HAS_INVOICE}
      />
      <BodyItem title={`Rp${new Intl.NumberFormat('id-ID').format(salesOrderItem.totalPrice)}`} />
      <BodyItem title={salesOrderItem.warehouse} />
      <BodyItem
        isReject={true}
        hasAction={salesOrderItem.discount < 1}
        hasStatus={salesOrderItem.status}
        handleRejectAdmin={handleRejectSuper}
        tab={tab}
        handleCancelRejectAdmin={handleCancelRejectSuper}
        loadingReject={loadingReject}
        handleInvoice={() => handleInvoice((salesOrderItem.invoice && salesOrderItem.invoice.id) || 0)}
        isLoadingCancel={isEditReject === salesOrderItem.ProductId}
        isLoadingDiskon={salesOrderItem.status !== SalesOrderStatus.REJECT && isEditReject === salesOrderItem.ProductId}
      />
    </TableRow>
  );
};

export default BodyRow;
