import React, { FC, Fragment, useState, useEffect, useCallback } from 'react';
import axios, { CancelTokenSource } from 'axios';

import {
  makeStyles,
  TextField,
  Button,
  Typography,
  Grid,
  TableContainer,
  TableBody,
  TableHead,
  Backdrop,
  Theme,
  CircularProgress,
  FormHelperText
} from '@material-ui/core';
import { PRODUCT_BASE_URL, CATEGORY_BASE_URL, PACKAGE_BASE_URL, ZONE_BASE_URL } from 'constants/url';
import { HeaderRow, TableCustom } from 'components/Table';
import { red } from '@material-ui/core/colors';
import { dummyCategory, dummyProduct, dummyZone } from 'utils/dummy';
import { useDropzone, FileWithPath } from 'react-dropzone';
import BodyRow from './BodyRow';
import ProductModal from './ProductModal';
import DropZone from './DropZone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormatMask from 'components/NumberFormatMask';
import ReactQuill from 'react-quill';
import empty from 'images/empty.svg';

interface Props {
  isSubmit: boolean;
  products: ProductModel[];
  setProducts: React.Dispatch<React.SetStateAction<ProductModel[]>>;
  setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string, link?: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    margin: '0 auto',
    width: 1031,
    height: 590,
    padding: '24px'
  },
  image: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  bordered: {
    border: `1px solid ${red[500]}`
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const ProductPackageWizard: FC<Props> = props => {
  const classes = useStyles();
  const { isSubmit, handleSnackBar, setSubmit } = props;

  const [name, setName] = useState<string>('');
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [description, setDescription] = useState<string>('');
  const [checked, setChecked] = useState<ProductItemModel[]>([]);
  const [checkedProduct, setCheckedProduct] = useState<ProductModel[]>([]);
  const [category, setCategory] = useState<CategoryModel>(dummyCategory);
  const [categories, setCategories] = useState<CategoryModel[]>([dummyCategory]);
  const [nameMessage, setNameMessage] = useState<string>('');
  const [packageMessageError, setPackageMessageError] = useState<string>('');
  const [openProduct, setOpenProduct] = useState<boolean>(false);
  const [productOriginals, setProductOriginals] = useState<ProductModel[]>([dummyProduct]);
  const [count, setCount] = useState<number>(0);
  const [wareHouseId, setWareHouseId] = useState<number>(0);
  const [queryString, setQueryString] = useState<string>('');
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [zone, setZone] = useState<ZoneModel>(dummyZone);
  const [zones, setZones] = useState<ZoneModel[]>([dummyZone]);
  const [zoneMessage, setZoneMessage] = useState<string>('');
  const [loadZone, setLoadZone] = useState<boolean>(false);
  const [progressMessage, setProgressMessage] = useState<string>('');
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
  const [productItemMessage, setProductItemMessage] = useState<string>('');
  const [image, setImage] = useState<{ path: string }[]>([]);
  const [imageBlob, setImageBlob] = useState<FileWithPath[]>([]);
  const [imageUrl, setImageUrl] = useState<string[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(100);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpeg', 'image/png'],
    maxFiles: 5,
    onDrop: (acceptedFiles: FileWithPath[]) => {
      setImageUrl([]);
      setImage([]);
      setImageBlob([]);
      uploadImage(acceptedFiles);
    }
  });

  const uploadImage = (files: FileWithPath[]) => {
    files.map((value: any) => {
      setImageUrl(prevState => [...prevState, URL.createObjectURL(value)]);
      setImage(prevState => [...prevState, { path: `paket${new Date().getTime()}.${value.name.split('.').pop()}` }]);
      setImageBlob(prevState => [...prevState, value]);
      return value;
    });
  };

  const getQueryParams = () => {
    const params = new URLSearchParams();
    if (queryString) {
      params.append('keyword', queryString);
    }

    if (orderBy || order) {
      params.append('orderBy', orderBy);
      params.append('ordered', order);
    }

    if (category && category.id !== 0) {
      params.append('CategoryId', String(category.id));
    }

    params.append('isProductPackage', 'false');
    params.append('page', currentPage.toString());
    return params.toString();
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    try {
      const { data } = await axios.get(`${PRODUCT_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });
      setProductOriginals(data.data);
      setCount(data.meta.last_page);
      setCurrentPage(data.meta.current_page);
    } catch (error) {
      console.log('error: ', error);
    }
    setIsLoadingData(false);
    return () => {
      cancelTokenSource.cancel();
    };
  }, [queryString, orderBy, order, currentPage, category]);

  const handleSearchCategory = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);

    try {
      const { data } = await axios.get(`${CATEGORY_BASE_URL}?${params.toString()}`);
      setCategories(data.data);
    } catch (error) {
      console.log('error :', error);
    }
  };

  const handleSearchZone = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    params.append('perPage', rowsPerPage.toString());
    setLoadZone(true);
    try {
      const { data } = await axios.get(`${ZONE_BASE_URL}?${params.toString()}`);
      setZones(data.data);
      setLoadZone(false);
    } catch (error) {
      console.log('error :', error);
      setLoadZone(false);
    }
  };

  const handleOnSubmit = async () => {
    try {
      setSubmit(true);
      setOpenBackdrop(true);
      setProgressMessage('Membuat produk...');

      const response = await axios.post(PRODUCT_BASE_URL, {
        id: 0,
        productName: name,
        productCode: String(`PR-${new Date().getTime()}`),
        sellingPrice: 0,
        purchasePrice: 0,
        minimumStock: 0,
        isReminder: false,
        description: description,
        typeUnit: 'Paket',
        CategoryId: 1,
        isProductPackage: true,
        image: image || [],
        price: [],
        WareHouseId: wareHouseId
      });

      const { data } = response;

      if (data.data.ProductImages.length) {
        let i = 0;
        data.data.ProductImages.map(async (value: any, index: number) => {
          if (image.filter(img => img.path === value.path).length) {
            // @ts-ignore
            const newImageKey = value.path;
            const fileExtension = newImageKey.split('.').pop();

            const myHeaders = new Headers();
            myHeaders.append('Content-Type', `image/${fileExtension}`);

            const config: RequestInit = {
              method: 'PUT',
              headers: myHeaders,
              body: imageBlob[i]
            };

            await fetch(value.url, config)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));

            i++;
          }
        });
      }

      setProgressMessage('Membuat paket...');
      await axios.post(PACKAGE_BASE_URL, {
        id: 0,
        name,
        description,
        totalPrice,
        items: checked,
        ProductId: response.data.data.id,
        ZoneId: zone.id
      });

      setSubmit(false);
      setOpenBackdrop(false);
      handleSnackBar(true, 'success', 'Paket berhasil ditambahkan', { path: '/paket/detail', id: response.data.data.id });
    } catch (error) {
      console.log('error :', error);
      handleSnackBar(true, 'error', 'Paket gagal ditambahkan');
    } finally {
      setSubmit(false);
      setOpenBackdrop(false);
    }
  };

  const validation = (): boolean => {
    let valid = true;

    if (name === '' || !name) {
      setNameMessage('Nama Paket tidak boleh kosong.');
      valid = false;
    }

    if (!checked || !checked.length) {
      setPackageMessageError('Product belum dipilih.');
      valid = false;
    }

    if (zone.id === 0 || !zone) {
      setZoneMessage('Pilih salaha satu Route');
      valid = false;
    }

    if (!checked || checked.length === 0) {
      setProductItemMessage('Daftar produk tidak boleh kosong');
      valid = false;
    }

    return valid;
  };

  const validationCheckProduct = (): boolean => {
    let valid = true;

    if (!checked || !checked.length) {
      setPackageMessageError('Product belum dipilih.');
      valid = false;
    }

    return valid;
  };

  const resetValidation = () => {
    setNameMessage('');
    setPackageMessageError('');
    setZoneMessage('');
    setProductItemMessage('');
  };

  const handleClickOpen = () => {
    setOpenProduct(true);
  };

  const handleClose = () => {
    resetValidation();
    if (!validationCheckProduct()) {
      return;
    }
    setOpenProduct(false);
  };

  const handleCancelProduct = () => {
    setOpenProduct(false);
    setChecked([]);
    setCheckedProduct([]);
  };

  const individualCheck = (item: ProductItemModel) => {
    const newChecked = [...checked];
    const newCheckedProduct = [...checkedProduct];
    const isReady = newChecked.some(value => value.ProductId === item.ProductId);

    if (!isReady) {
      newChecked.push(item);
      newCheckedProduct.push(productOriginals.filter(value => value.id === item.ProductId)[0]);
    } else {
      newChecked.splice(
        newChecked.findIndex(value => value.ProductId === item.ProductId),
        1
      );
      newCheckedProduct.splice(
        newCheckedProduct.findIndex(value => value.id === item.ProductId),
        1
      );
    }

    if (Array.from(new Set(newCheckedProduct.map(value => value.warehouse))).length > 1) {
      setPackageMessageError('Product yang dipilih hanya boleh dari satu gudang yang sama.');
      return;
    }

    setPackageMessageError('');
    setChecked(newChecked);
    setWareHouseId(newCheckedProduct[0].WareHouseId || 0);

    setCheckedProduct(newCheckedProduct);
  };

  const individualQty = (item: ProductItemModel) => {
    setChecked(
      checked.map(value => {
        if (value.ProductId === item.ProductId) {
          value.minimumItem = item.minimumItem;
        }
        return value;
      })
    );
  };

  const individualBonusQty = (item: ProductItemModel) => {
    setChecked(
      checked.map(value => {
        if (value.ProductId === item.ProductId) {
          value.bonusItem = item.bonusItem;
        }
        return value;
      })
    );
  };

  const individualPromo = (price: number, ProductId: number) => {
    const newChecked = [...checked];
    const index = checked.findIndex(value => value.ProductId === ProductId);
    newChecked[index].promoPrice = price;
    setChecked(newChecked);
  };

  useEffect(() => {
    if (!isSubmit) {
      return;
    }

    resetValidation();

    if (!validation()) {
      setSubmit(false);
      return;
    }

    handleOnSubmit();
  }, [isSubmit]);

  useEffect(() => {
    if (checked.length < 1) {
      return;
    }
    setTotalPrice(checked.map(value => value.promoPrice).reduce((a, b) => a + b));
  }, [checked]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Grid container direction='row' spacing={2}>
      <Grid container item xs={12} sm={12} md={6} lg={6}>
        <DropZone getRootProps={getRootProps} getInputProps={getInputProps} acceptedFiles={acceptedFiles} imageUrl={imageUrl} />
      </Grid>

      <Grid container item direction='row' spacing={1} justify='flex-end' xs={12} sm={12} md={6} lg={6}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            id='name'
            required
            fullWidth
            label={'Nama Paket'}
            value={name}
            onChange={event => setName(event.target.value)}
            error={nameMessage !== ''}
            helperText={nameMessage}
          />

          <Autocomplete
            id='zone'
            fullWidth
            value={zone}
            options={zones}
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(event: any, value: any) => {
              if (value) {
                setZone(value);
              }
            }}
            onOpen={e => handleSearchZone('')}
            loading={loadZone}
            renderInput={params => (
              <TextField
                {...params}
                required
                label='Pilih Rute'
                onChange={e => handleSearchZone(e.target.value)}
                error={zoneMessage !== ''}
                helperText={zoneMessage}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {loadZone && <CircularProgress color='inherit' size={20} />}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  )
                }}
              />
            )}
          />

          <ReactQuill id='notes' value={description} onChange={(value: any) => setDescription(value)} placeholder='Deskripsi' />
        </Grid>
      </Grid>

      <Grid item lg={12} sm={12} md={12} xs={12}>
        <Typography variant='h5'>Semua Produk</Typography>
        <FormHelperText error={productItemMessage !== ''}>{productItemMessage}</FormHelperText>
        {checkedProduct.length === 0 ? (
          <Fragment>
            <img src={empty} className={classes.image} />
            <Typography variant='subtitle1' align='center'>
              Belum ada produk yang ditambahkan dalam paket ini :(
            </Typography>
          </Fragment>
        ) : (
          <TableContainer>
            <TableCustom>
              <TableHead>
                <HeaderRow
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  headers={[
                    { label: '' },
                    { id: 'productCode', label: 'Kode Produk', sort: true },
                    { id: 'productName', label: 'Nama', sort: true },
                    { id: 'CategoryId', label: 'Kategori', sort: true },
                    { id: 'typeUnit', label: 'Satuan', sort: true, align: 'center' },
                    { label: 'Qty', align: 'center' },
                    { label: 'Bonus Qty' },
                    { label: 'Harga Promo' }
                  ]}
                />
              </TableHead>
              <TableBody>
                {checkedProduct.length > 0 &&
                  checkedProduct.map((value, index) => (
                    <BodyRow
                      key={index + 1}
                      index={index}
                      item={checked[index]}
                      product={value}
                      isLoading={isLoadingData}
                      checked={checked}
                      handleIndividualCheck={individualCheck}
                      handleIndividualQty={individualQty}
                      handleIndividualBonusQty={individualBonusQty}
                      handlePromo={individualPromo}
                    />
                  ))}
              </TableBody>
            </TableCustom>
          </TableContainer>
        )}
      </Grid>

      <Grid item container lg={12} sm={12} md={12} xs={12} spacing={2} justify='flex-end'>
        <Button variant='text' onClick={handleClickOpen}>
          Tambahkan Produk Lainnya
        </Button>
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <TextField
          id='promoPrice'
          fullWidth
          label='Total Harga Paket'
          value={totalPrice}
          disabled
          InputProps={{
            inputComponent: NumberFormatMask as any
          }}
        />
      </Grid>

      <ProductModal
        checked={checked}
        openProduct={openProduct}
        queryString={queryString}
        categories={categories}
        packageMessageError={packageMessageError}
        order={order}
        orderBy={orderBy}
        isLoadingData={isLoadingData}
        productOriginals={productOriginals}
        count={count}
        currentPage={currentPage}
        setCategory={setCategory}
        handleClose={handleClose}
        setQueryString={setQueryString}
        handleSearchCategory={handleSearchCategory}
        handleRequestSort={handleRequestSort}
        setCurrentPage={setCurrentPage}
        handleIndividualCheck={individualCheck}
        handleCancelProduct={handleCancelProduct}
      />

      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <Typography variant='inherit'>{progressMessage}</Typography>
        <CircularProgress size='small' color='inherit' />
      </Backdrop>
    </Grid>
  );
};

export default ProductPackageWizard;
