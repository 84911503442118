import React, { FC } from 'react';
import { TableContainer, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { PaperCustom } from 'components';
import Skeleton from '@material-ui/lab/Skeleton';

interface Props {
  sales: UserDetailsModel;
  isLoading: boolean;
}
const SalesDetail: FC<Props> = props => {
  const { sales, isLoading } = props;

  return (
    <PaperCustom>
      <Typography variant='h5'>Personal Informasi</Typography>
      <br />
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>ID Sales</TableCell>
              <TableCell align='right'>{isLoading ? <Skeleton variant='text' width='100%' /> : '#' + sales.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Nama</TableCell>
              <TableCell align='right'>{isLoading ? <Skeleton variant='text' width='100%' /> : sales.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell align='right'>{isLoading ? <Skeleton variant='text' width='100%' /> : sales.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>No. Handphone</TableCell>
              <TableCell align='right'>{isLoading ? <Skeleton variant='text' width='100%' /> : sales.contactNumber}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </PaperCustom>
  );
};

export default SalesDetail;
