import {
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useContext } from 'react';
import { HeaderRow, TableCellEnd, TableCellHead, TableCellMiddle, TableCellStart, TableCustom, TableRowCollapse, TableRowCustom } from 'components';
import { Skeleton } from '@material-ui/lab';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import clsx from 'clsx';
import { PRIMARY_MOBILE } from 'constants/colors';
import useRole from 'hooks/useRole';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import TypeUser from 'typings/enum/TypeUser';

interface Props {
  productName: string;
  setProductName: React.Dispatch<React.SetStateAction<string>>;
  productResource: IDashboard<DashboardProduct[]>;
  productDetailResource: IDashboard<DashboardProductDetail[]>;
  properties: {
    id: keyof DashboardProduct;
    label: string;
    sort: boolean;
    align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  }[];

  propertiesCollapse: {
    id: keyof DashboardProductDetail;
    label: string;
  }[];
  handleExpand: (id: number) => void;
}

const useStyles = makeStyles({
  containerEmpty: {
    minHeight: '30vh',
    borderTop: '1px solid rgba(45, 55, 72, 0.12)'
  },
  link: {
    cursor: 'pointer',
    fontWeight: 500,
    transition: 'all .3s',
    '&:hover': {
      color: PRIMARY_MOBILE,
      textDecoration: 'underline'
    }
  }
});

const ProductTable = ({
  productResource,
  productDetailResource,
  properties,
  propertiesCollapse,
  handleExpand,
  productName,
  setProductName
}: Props) => {
  const classes = useStyles();
  const { currentUser } = useContext(CurrentUserContext);
  const isHR = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.HR]
  });
  const handleOpenLink = (path: string) => window.open(path);

  return (
    <Grid xs={12}>
      <TableContainer>
        <TableCustom>
          <TableHead>
            <HeaderRow
              headers={properties.map(value => ({
                ...value,
                id: value.id.toString(),
                align: value.align ? value.align : undefined
              }))}
            />
          </TableHead>

          <TableRow>
            <TableCellHead />
            <TableCellHead variant='head'>
              <TextField
                id='productName'
                placeholder='Masukkan Nama Produk'
                value={productName}
                autoComplete='off'
                label='Nama Produk'
                fullWidth
                onChange={event => setProductName(event.target.value)}
              />
            </TableCellHead>
          </TableRow>

          <TableBody>
            {productResource.isLoading ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <Grid xs={12} container>
                    {[...new Array(10)].map((val, key) => (
                      <Grid xs={12}>
                        <Skeleton variant='text' animation='wave' height={60} key={key} />
                      </Grid>
                    ))}
                  </Grid>
                </TableCell>
              </TableRow>
            ) : productResource.data.length > 0 ? (
              productResource.data.map((items, key) => (
                <>
                  <TableRowCustom key={key}>
                    {properties.map((property, index) =>
                      index === 0 ? (
                        <TableCellStart key={String(property.id)}>
                          <IconButton aria-label='expand row' size='small' onClick={() => handleExpand(items['productId'])}>
                            {items['isExpand'] ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                          </IconButton>
                        </TableCellStart>
                      ) : index < properties.length - 1 ? (
                        <TableCellMiddle key={String(property.id)}>{items[property.id]}</TableCellMiddle>
                      ) : (
                        <TableCellEnd key={String(property.id)}>{items[property.id]}</TableCellEnd>
                      )
                    )}
                  </TableRowCustom>

                  <TableRowCollapse open={items['isExpand']} colSpan={7}>
                    <Table size='small'>
                      <TableHead>
                        <TableRow>
                          {propertiesCollapse.map(property => (
                            <TableCell key={property.id}>{property.label}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {productDetailResource.isLoading ? (
                          <TableRow>
                            <TableCell colSpan={7}>
                              <Grid xs={12} container>
                                {[...new Array(5)].map((val, key) => (
                                  <Grid xs={12}>
                                    <Skeleton variant='text' animation='wave' height={60} key={key} />
                                  </Grid>
                                ))}
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ) : productDetailResource.data.length > 0 ? (
                          productDetailResource.data.map(productDetail => (
                            <TableRow key={key}>
                              {propertiesCollapse.map(propertie => (
                                <TableCell
                                  className={clsx(
                                    ((!isHR && propertie.id.includes('orderNumber')) || propertie.id.includes('customerName')) && classes.link
                                  )}
                                  onClick={() => {
                                    if (!isHR && propertie.id.includes('orderNumber')) {
                                      handleOpenLink(`/penjualan/${productDetail.id}`);
                                    }
                                    if (propertie.id.includes('customerName')) {
                                      handleOpenLink(`/mitra/${productDetail.partnerId}`);
                                    }
                                  }}
                                >
                                  {propertie.id.includes('orderNumber') ? `#${productDetail[propertie.id]}` : productDetail[propertie.id]}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={7} align='center'>
                              <Grid xs={12} className={classes.containerEmpty} container alignItems='center' justify='center'>
                                <Typography variant='h6'>Data Tidak Tersedia.</Typography>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableRowCollapse>
                </>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align='center'>
                  <Grid xs={12} className={classes.containerEmpty} container alignItems='center' justify='center'>
                    <Typography variant='h6'>Data Tidak Tersedia.</Typography>
                  </Grid>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </TableCustom>
      </TableContainer>
    </Grid>
  );
};

export default ProductTable;
