import { Button, Grid, IconButton, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import { Create, Delete, DeleteOutline, EditOutlined, Visibility, AttachFile, VisibilityOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom } from 'components';

import { BLACK, PRIMARY_MOBILE, WHITE } from 'constants/colors';
import React from 'react';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    cursor: 'pointer',
    '&:hover': {
      color: PRIMARY_MOBILE
    }
  },
  cellStart: {
    width: '3%'
  },
  cellMiddle: {
    width: '14%'
  },
  cellMiddleDesc: {
    width: '15%'
  },
  cellEnd: {
    width: '15%'
  },
  descriptionContainer: {
    backgroundColor: WHITE,
    borderRadius: '6px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    border: '1px solid #E0E0E0'
  },
  description: {
    color: BLACK
  }
}));
interface Props {
  operasionalResource: Operasional;
  handleOpenCarousel: (isOpen: boolean, id: number) => void;
  handleAction: (isOpen: boolean, id: number, action: 'delete' | 'update' | 'detail') => void;
}
const OperationalBody = ({ operasionalResource, handleAction, handleOpenCarousel }: Props) => {
  const classes = useStyles();
  /* onClick={onUpdate} */
  return (
    <TableRowCustom key={operasionalResource.id}>
      <TableCellStart className={clsx(classes.cellMiddle)}>{operasionalResource.name}</TableCellStart>
      <TableCellMiddle className={classes.cellMiddle}>
        <NumberFormat value={operasionalResource.amount} prefix={'Rp'} thousandSeparator={true} displayType='text' />
      </TableCellMiddle>
      <TableCellMiddle className={classes.cellMiddle}>
        <Button
          variant='text'
          color='primary'
          size='small'
          startIcon={<AttachFile fontSize='small' color='primary' />}
          onClick={() => handleOpenCarousel(true, operasionalResource.id)}
        >
          {operasionalResource.image.length}
        </Button>
      </TableCellMiddle>
      <TableCellMiddle className={clsx(classes.cellMiddle)}>
        <>
          {operasionalResource.financialAccount} <br />
          {operasionalResource.vendor}
        </>
      </TableCellMiddle>
      <TableCellMiddle className={clsx(classes.cellMiddle)}>{operasionalResource.accountCategoriesName}</TableCellMiddle>
      <TableCellMiddle className={classes.cellMiddle}>{format(new Date(operasionalResource.createdAt), 'dd MMM yyyy')}</TableCellMiddle>
      <TableCellEnd className={classes.cellEnd} align='right'>
        <>
          {/* <Tooltip title='Perbaharui Biaya'>
            <IconButton size='small' onClick={() => handleAction(true, operasionalResource.id, 'detail')}>
              <VisibilityOutlined fontSize='small' />
            </IconButton>
          </Tooltip> */}
          <Tooltip title='Perbaharui Biaya'>
            <IconButton size='small' onClick={() => handleAction(true, operasionalResource.id, 'update')}>
              <EditOutlined fontSize='small' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Hapus Biaya'>
            <IconButton size='small' onClick={() => handleAction(true, operasionalResource.id, 'delete')}>
              <DeleteOutline fontSize='small' />
            </IconButton>
          </Tooltip>
        </>
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default OperationalBody;
