import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  MobileStepper,
  Slide,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';

import { TransitionProps } from '@material-ui/core/transitions';
import { CloseRounded, GetAppRounded, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { PRIMARY_MOBILE } from 'constants/colors';
interface Props {
  open: boolean;
  images: ProductImageModel[];
  handleClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) => ({
  img: {
    height: '30em',
    display: 'block',
    overflow: 'hidden',
    objectFit: 'contain',
    width: '100%',
    borderRadius: '.5em'
  },
  imagesPlacholder: {
    display: 'grid',
    placeItems: 'center',
    height: '30em',
    borderRadius: '.6em .6em 0 0',
    background: PRIMARY_MOBILE
  }
}));

const OperationalCarousel = ({ open, handleClose, images }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  useEffect(() => {
    setActiveStep(0);
  }, [open]);

  return (
    <Dialog open={open} maxWidth='sm' TransitionComponent={Transition} keepMounted onClose={handleClose} fullWidth>
      <DialogTitle>
        <Grid xs={12} container alignItems='center' justify='space-between'>
          <Typography variant='h5'>Lampiran</Typography>

          <IconButton size='small' onClick={handleClose}>
            <CloseRounded />
          </IconButton>
        </Grid>
        <Grid xs={12}>
          <Divider />
        </Grid>
      </DialogTitle>
      <DialogContent>
        {maxSteps > 0 ? (
          <img className={classes.img} src={images[activeStep].url} alt={images[activeStep].id.toString()} />
        ) : (
          // <img src={product.ProductImage.url} className={classes.productImage} />
          <Grid className={classes.imagesPlacholder} xs={12}>
            <Typography variant='h6'>Lampiran tidak tersedia</Typography>
          </Grid>
        )}

        <MobileStepper
          steps={maxSteps}
          position='static'
          variant='dots'
          activeStep={activeStep}
          nextButton={
            <Button size='small' onClick={handleNext} disabled={activeStep >= maxSteps - 1}>
              Next
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button size='small' onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              Back
            </Button>
          }
        />
      </DialogContent>
    </Dialog>
  );
};

export default OperationalCarousel;
