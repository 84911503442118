import React, { FC, useState, useEffect } from 'react';
import { TextField, Button, Grid, MenuItem, CircularProgress } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import { ACCOUNT_CATEGORY_BASE_URL } from 'constants/url';

interface RoleModel {
  name: string;
}

interface Props {
  type: string;
  typeError: string;
  roles: RoleModel[];
  accountCategorie: AccountCategoryModel | null;
  setAccountCategorie: React.Dispatch<React.SetStateAction<AccountCategoryModel | null>>;
  setType: React.Dispatch<React.SetStateAction<string>>;
  accountCategories: AccountCategoryModel[];
  setAccountCategories: React.Dispatch<React.SetStateAction<AccountCategoryModel[]>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const CreateAccountCategoriesForm: FC<Props> = props => {
  const {
    setAccountCategorie,
    setAccountCategories,
    accountCategories,
    accountCategorie,
    type,
    typeError,
    setType,
    roles,
    setOpen,
    handleSnackBar
  } = props;
  const [id, setId] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [number, setNumber] = useState<number>(0);
  const [subAccounts, setSubAccounts] = useState<AccountCategoryModel[]>([]);
  const [subAccount, setSubAccount] = useState<number>(0);
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [nameMessage, setNameMessage] = useState<string>('');
  const disabled = name === '' || number === 0 || category === '';
  useEffect(() => {
    if (!accountCategorie) {
      return;
    }

    setId(accountCategorie.id);
    setName(accountCategorie.name);
    setSubAccount(accountCategorie.subAccount);
    setSubAccounts(accountCategories);
    setCategory(accountCategorie.category);
    setNumber(accountCategorie.number);
  }, [accountCategorie]);

  const handleOnCancel = (): void => {
    setOpen(false);
    resetForm();
  };

  const handleOnSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    resetValidation();

    if (!validation()) {
      return;
    }

    try {
      setSubmit(true);
      const { data } = await axios.post(
        `${ACCOUNT_CATEGORY_BASE_URL}`,
        {
          id,
          name,
          category,
          number,
          subAccount
        },
        {
          cancelToken: cancelTokenSource.token
        }
      );

      if (accountCategorie) {
        setAccountCategories(
          accountCategories.map(value => {
            if (value.id === data.data.id) {
              return data.data;
            }
            return value;
          })
        );
        resetForm();
      } else {
        setAccountCategories([data.data, ...accountCategories]);
        resetForm();
      }

      setOpen(false);
      handleSnackBar(true, 'success', accountCategorie ? 'Akun berhasil diperbaharui.' : 'Akun berhasil ditambahkan.');
    } catch (error) {
      handleSnackBar(true, 'error', accountCategorie ? 'Akun gagal diperbaharui.' : 'Akun gagal ditambahkan.');
    }
    setSubmit(false);
  };

  const validation = (): boolean => {
    let valid = true;

    if (name === '' || !name) {
      setNameMessage('Nama tidak boleh kosong.');
      valid = false;
    }

    return valid;
  };

  const resetValidation = () => {
    setNameMessage('');
  };

  const resetForm = () => {
    setName('');
    setId(0);
    setAccountCategorie(null);
    setName('');
    setNumber(0);
    setSubAccount(0);
    setCategory('');
  };

  const errorRequest = (error: { [key: string]: string[] }) => {
    if (error) {
      if (error.name) {
        setNameMessage(error.name[0]);
      }
    }
  };

  return (
    <Grid container direction='row' spacing={1}>
      <Grid item lg={12} sm={12} md={12} xs={12}>
        <TextField
          id='name'
          name='name'
          required
          fullWidth
          label='Nama Akun'
          placeholder='Contoh: Pendapatan lain -lain'
          value={name}
          onChange={event => setName(event.target.value)}
          error={nameMessage !== ''}
          helperText={nameMessage}
        />
      </Grid>
      <Grid item lg={12} sm={12} md={12} xs={12}>
        <TextField
          id='number'
          placeholder='Contoh: 1000'
          name='number'
          fullWidth
          label='No.Akun'
          value={number !== 0 ? number : ''}
          onChange={event => setNumber(Number(event.target.value))}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
          id='category'
          required
          label='kategori'
          placeholder='Pilih Kategori'
          value={category || ''}
          error={typeError !== ''}
          helperText={typeError}
          onChange={e => setCategory(e.target.value)}
          select
          fullWidth
        >
          <MenuItem value=''>Pilih Tipe</MenuItem>
          {roles.length > 0 &&
            roles.map((value, index) => (
              <MenuItem key={index} value={value.name}>
                {value.name}
              </MenuItem>
            ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
          id='category'
          label='Sub Akun'
          placeholder='Pilih Akun'
          value={subAccount || 0}
          error={typeError !== ''}
          helperText={typeError}
          onChange={e => setSubAccount(Number(e.target.value))}
          select
          fullWidth
        >
          <MenuItem value={0}>Pilih Akun</MenuItem>
          {accountCategories.length > 0 &&
            accountCategories.map((value, index) => (
              <MenuItem key={index} value={value.id}>
                {value.number} - {value.name}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} justify='center'>
        <Grid item>
          <Button disabled={isSubmit} color='secondary' onClick={handleOnCancel}>
            Batal
          </Button>
        </Grid>
        <Grid item>
          <Button type='submit' disabled={isSubmit || disabled} onClick={handleOnSubmit}>
            {isSubmit ? <CircularProgress color='primary' size={20} /> : 'Simpan'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CreateAccountCategoriesForm;
