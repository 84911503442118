import React, { FC, useContext } from 'react';
import { Typography, Grid, Table, TableRow, TableCell, TableBody, TableContainer } from '@material-ui/core';
import { PaperCustom } from 'components';
import NumberFormat from 'react-number-format';
import Skeleton from '@material-ui/lab/Skeleton';
import useRole from 'hooks/useRole';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import TypeUser from 'typings/enum/TypeUser';
interface Props {
  partner: PartnerModel;
  isLoading: boolean;
}

enum PartnerType {
  SUPPLIER = 'SUPPLIER',
  CUSTOMER = 'CUSTOMER'
}

const PartnerDetail: FC<Props> = props => {
  const { partner, isLoading } = props;
  const { currentUser } = useContext(CurrentUserContext);

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN]
  });

  const isAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN]
  });

  return (
    <Grid container direction='row' justify='space-between'>
      <Grid item container direction='row' justify='space-between' spacing={2}>
        <Grid item lg={6} sm={12} md={6} xs={12}>
          <PaperCustom>
            <Typography variant='h5'>Informasi</Typography>

            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2}>Nama</TableCell>
                    <TableCell colSpan={2} align='right'>
                      {isLoading ? <Skeleton variant='text' width='100%' /> : partner.name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>ID Mitra</TableCell>
                    <TableCell colSpan={2} align='right'>
                      {isLoading ? <Skeleton variant='text' width='100%' /> : partner.partnerId}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2}>Badan Usaha</TableCell>
                    <TableCell colSpan={2} align='right'>
                      {isLoading ? <Skeleton variant='text' width='100%' /> : partner.businessEntity}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2}>Email</TableCell>
                    <TableCell colSpan={2} align='right'>
                      {isLoading ? <Skeleton variant='text' width='100%' /> : partner.email}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>No. Telepon</TableCell>
                    <TableCell align='right'>{isLoading ? <Skeleton variant='text' width='100%' /> : partner.phoneNumber}</TableCell>
                    <TableCell>No. Handphone</TableCell>
                    <TableCell align='right'>{isLoading ? <Skeleton variant='text' width='100%' /> : partner.cellPhoneNumber}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>Laman Website</TableCell>
                    <TableCell colSpan={2} align='right'>
                      {isLoading ? <Skeleton variant='text' width='100%' /> : partner.website}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2}>No. Akun Virtual</TableCell>
                    <TableCell colSpan={2} align='right'>
                      {isLoading ? <Skeleton variant='text' width='100%' /> : partner.virtualAccount}
                    </TableCell>
                  </TableRow>
                  {(isAdmin || isSuperAdmin) && (
                    <TableRow>
                      <TableCell colSpan={2}>Plafon</TableCell>
                      <TableCell colSpan={2} align='right'>
                        <NumberFormat value={partner.plafon ? partner.plafon : 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                      </TableCell>
                    </TableRow>
                  )}
                  {(isAdmin || isSuperAdmin) && (
                    <TableRow>
                      <TableCell colSpan={2}>Jatuh Tempo</TableCell>
                      <TableCell colSpan={2} align='right'>
                        {isLoading ? <Skeleton variant='text' width='100%' /> : partner.dueDate}
                      </TableCell>
                    </TableRow>
                  )}
                  {partner.partnerType === PartnerType.CUSTOMER && (isAdmin || isSuperAdmin) && (
                    <TableRow>
                      <TableCell colSpan={2}>Durasi Pelunasan</TableCell>
                      <TableCell colSpan={2} align='right'>
                        {isLoading ? <Skeleton variant='text' width='100%' /> : partner.duration}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </PaperCustom>
        </Grid>

        <Grid item lg={6} sm={12} md={6} xs={12}>
          <PaperCustom>
            <Typography variant='h5'>Detail Alamat</Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Alamat</TableCell>
                    <TableCell align='right'>{isLoading ? <Skeleton variant='text' width='100%' /> : partner.address}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Remark Alamat</TableCell>
                    <TableCell align='right'>{isLoading ? <Skeleton variant='text' width='100%' /> : partner.remarkAddress || '-'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Provinsi</TableCell>
                    <TableCell align='right'>
                      {isLoading ? <Skeleton variant='text' width='100%' /> : partner.Provinsi ? partner.Provinsi.name : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Kabupaten</TableCell>
                    <TableCell align='right'>
                      {isLoading ? <Skeleton variant='text' width='100%' /> : partner.Kabupaten ? partner.Kabupaten.name : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Kecamatan</TableCell>
                    <TableCell align='right'>
                      {isLoading ? <Skeleton variant='text' width='100%' /> : partner.Kecamatan ? partner.Kecamatan.name : ''}
                    </TableCell>
                  </TableRow>
                  {partner.partnerType === PartnerType.CUSTOMER && (
                    <TableRow>
                      <TableCell>Route</TableCell>
                      <TableCell align='right'>
                        {isLoading ? <Skeleton variant='text' width='100%' /> : partner.Zone ? partner.Zone.name : ''}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>Kode Pos</TableCell>
                    <TableCell align='right'>{isLoading ? <Skeleton variant='text' width='100%' /> : partner.postalCode}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Catatan</TableCell>
                    <TableCell align='right'>
                      <div dangerouslySetInnerHTML={{ __html: partner.notes }} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </PaperCustom>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PartnerDetail;
