import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { CreateOutlined, PhotoSizeSelectActualOutlined } from '@material-ui/icons';
import React, { FC, useState } from 'react';
import EmptyIcon from 'images/icon/EmptyIcon.svg';
import ModalEditQty from './ModalEditQty';
import axios from 'axios';
import { ORDER_ITEM_BASE_URL } from 'constants/url';
import { GREY } from 'constants/colors';
interface Props {
  salesOrderItem: SalesOrderItemModel[];
  collyResource: typeCollyModel[];
}

const useStyles = makeStyles((theme: Theme) => ({
  containerEmpty: {
    minHeight: '240px',
    padding: '0 1em'
  },
  iconSize: {
    fontSize: '5rem',
    color: 'lightblue'
  },
  textSecondary: {
    fontSize: '0.8rem',
    color: 'grey'
  },

  itemBody: {
    display: 'flex',
    gap: '1.4em',
    padding: '0.6em 1em'
  },
  button: {
    borderRadius: '1.4em'
  },
  containerButton: {
    width: '100%',
    padding: '1em'
  },
  buttonContainerMobile: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 5,
    backgroundColor: '#fff',
    padding: '10px 10px',
    boxShadow: '0px 0px 17px rgba(0, 0, 0, 0.08)'
  },
  containerItem: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    display: 'flex',
    alignItems: 'center',
    padding: '0 1rem 0.3rem'
  },
  containerItemActive: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(2, 169, 234, 0.12)',
    padding: '0 1rem 0.3rem'
  },

  textErrorContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.3rem',
    fontWeight: 500,
    fontSize: '14px'
  },
  containerButtom: {
    display: 'flex',
    marginTop: '1em',
    flexDirection: 'column'
  },
  alertWarning: {
    border: '1px solid #FFBF00',
    backgroundColor: 'rgba(255, 191, 0, 0.12)',
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
    borderRadius: '5px'
  },
  wrapContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 1em',
    gap: '1rem',
    marginBottom: '1em'
  },
  textMobile: {
    fontSize: '14px',
    fontWeight: 500
  },
  buttonActionTop: {
    display: 'flex',
    gap: '1em'
  },
  containerSkelaton: {
    padding: '0.75em 1em',
    minHeight: '200px'
  },
  noImage: {
    backgroundColor: GREY,
    height: '60px',
    width: '60px',
    borderRadius: '.5em',
    display: 'grid',
    placeItems: 'center'
  }
}));

const CollyTemp: FC<Props> = ({ salesOrderItem, collyResource }) => {
  const classes = useStyles();
  const [openModalQty, setOpenModalQty] = useState<boolean>(false);
  const [hasId, setHasId] = useState<number>(0);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const getTotalBag = (id: number) => {
    const total = collyResource.map(value =>
      value.BagItem.filter(value => value.SalesOrderItemId === id)
        .map(value => value.totalItem)
        .reduce((a, b) => a + b, 0)
    );
    return Number(total.reduce((a, b) => a + b, 0));
  };

  const handletEditQty = async (id: number, productId: number, totalQty: number, remark: string, remarkCode: string) => {
    setisLoading(true);
    try {
      const { data } = await axios.put(ORDER_ITEM_BASE_URL, {
        id: id,
        ProductId: productId,
        totalItem: totalQty,
        remark: remark,
        remarkCode: remarkCode
      });

      const index = salesOrderItem.findIndex(val => val.id === data.data.id);
      const newList = [...salesOrderItem];
      newList[index].totalItem = data.data.totalItem;
      setisLoading(false);
      setOpenModalQty(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid xs={12}>
      <Grid xs={12} container style={{ padding: '1em', width: '90vw' }} justify='flex-start'>
        <Typography variant='h6' align='left'>
          Semua Produk
        </Typography>
      </Grid>

      {salesOrderItem.filter(value => getTotalBag(value.id) < value.totalItem).length < 1 && (
        <Grid xs={12} container direction='column' alignItems='center' justify='center' className={classes.containerEmpty}>
          <img src={EmptyIcon} alt='Data Kosong' width='65px' height='65px' />

          <Typography variant='h6' align='center' style={{ marginTop: '0.4em' }}>
            Sudah tidak ada sisa pengepakan!
          </Typography>
          <Typography align='center' className={classes.textSecondary}>
            Silahkan untuk melanjutkan untuk mengkonfirm ke invoice
          </Typography>
        </Grid>
      )}

      {salesOrderItem
        .filter(value => getTotalBag(value.id) < value.totalItem)
        .map((value, key) => (
          <Grid xs={12} className={classes.itemBody} key={key}>
            <Grid xs={2}>
              {value.productImage ? (
                <img
                  src={value.productImage}
                  alt='gambar produk'
                  width='60px'
                  height='60px'
                  style={{ borderRadius: '0.3rem', border: '1px solid rgba(0, 0, 0, 0.08)' }}
                />
              ) : (
                <Grid className={classes.noImage}>
                  <PhotoSizeSelectActualOutlined />
                </Grid>
              )}
            </Grid>
            <Grid xs={10}>
              <Typography className={classes.textMobile}>{value.productName}</Typography>
              <Typography className={classes.textSecondary}>Total Qty Pengepakan: {value.totalItem - getTotalBag(value.id)} </Typography>
            </Grid>
            <Grid xs={2} container justify='flex-end'>
              <CreateOutlined
                fontSize='small'
                color='primary'
                onClick={() => {
                  setOpenModalQty(true);
                  setHasId(value.id);
                }}
              />
            </Grid>
          </Grid>
        ))}

      <ModalEditQty
        collyResource={collyResource}
        idSalesOrderItem={hasId}
        loading={isLoading}
        open={openModalQty}
        handletEditQty={handletEditQty}
        setOpen={setOpenModalQty}
        salesOrderItem={salesOrderItem.filter(value => value.id === hasId)}
      />
    </Grid>
  );
};

export default CollyTemp;
