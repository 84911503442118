import React, { useCallback, useEffect, useState } from 'react';
import { Button, CircularProgress, Container, Grid, LinearProgress, makeStyles, TextField, Theme, Tooltip, Typography } from '@material-ui/core';
import { AddBox, Today } from '@material-ui/icons';
import Refresh from '@material-ui/icons/Refresh';
import { Autocomplete, Pagination } from '@material-ui/lab';
import axios, { CancelTokenSource } from 'axios';
import { Breadcrumb, Page, PaperCustom } from 'components';
import { GREEN_MOBILE, WHITE } from 'constants/colors';
import { DELIVERY_ORDER_BASE_URL, DELIVERY_ORDER_INVOICE, DELIVERY_ORDER_PARTNER, DELIVERY_ORDER_PRINT, WAREHOUSE_BASE_URL } from 'constants/url';
import useDebounced from 'hooks/useDebounced';
import { format, startOfMonth } from 'date-fns';
import useRouter from 'hooks/useRouter';
import { dummyMetaData } from 'utils/dummy';
import DeliveryContent from './DeliveryContent';
import ModalInfo from './ModalInfo';
import ModalSuccess from './ModalSuccess';
import DateRangeFilter from 'components/DateRangeFilter';

const useStyles = makeStyles((theme: Theme) => ({
  refresh: {
    backgroundColor: GREEN_MOBILE,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN_MOBILE,
      transform: 'scale(1.1)'
    }
  },
  containerBox: {
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    borderRadius: '5px',
    marginTop: '0.5em'
  }
}));
const DeliveryOrdersCreate = () => {
  const classes = useStyles();
  const { history } = useRouter();
  let cancelToken: CancelTokenSource = axios.CancelToken.source();
  const [deliveryInvoice, setDeliveryInvoice] = useState<DeliveryInvoice[]>([]);
  const [wareHouses, setWareHouses] = useState<WareHouseModel[]>([]);
  const [metaDelivery, setMetaDelivery] = useState<MetaData>(dummyMetaData);
  const [wareHouse, setWareHouse] = useState<string>('');
  const [partner, setPartner] = useState<string>('');

  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isInfo, setIsInfo] = useState<boolean>(false);
  const [invoiceId, setInvoiceId] = useState<number[]>([]);
  const [partners, setPartners] = useState<{ id: number; namePartner: string; route: string }[]>([]);
  const [rute, setRute] = useState<string>('');
  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false);
  const [isLoadingPartner, setIsLoadingPartner] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
  const [isLoadingPrint, setIsLoadingPrint] = useState<boolean>(false);
  const [deliveryId, setDeliveryId] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [salesName, setSalesName] = useState<string>('');
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');

  const handleGetWareHouse = async () => {
    try {
      const { data } = await axios.get(WAREHOUSE_BASE_URL);
      setWareHouses(data.data);
    } catch (error) {
      console.log('error', error);
    }
  };
  const handleOpenModalInfo = (invoiceId: number[]) => {
    setIsInfo(true);
    setInvoiceId(invoiceId);
  };
  const handleCloseModalInfo = () => {
    setIsInfo(false);
  };

  const handleCalendarFilterClick = () => {
    setOpenCalendarFilter(!openCalendarFilter);
  };
  const handleSearchPartner = async () => {
    setIsLoadingPartner(true);
    try {
      const { data } = await axios.get(`${DELIVERY_ORDER_PARTNER}?keyword=`);
      setPartners(data.data);
      setIsLoadingPartner(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  const fetchDataInvoices = async () => {
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (invoiceNumber) {
        params.append('invoiceNumber', invoiceNumber);
      }
      if (partners.filter(val => val.namePartner === partner).length > 0) {
        params.append('partner', partners.filter(val => val.namePartner.includes(partner))[0].id.toString());
      }
      if (wareHouse) {
        params.append('warehouse', wareHouse);
      }
      if (salesName) {
        params.append('salesName', salesName);
      }

      params.append('page', currentPage.toString());

      params.append('startDate', startDate);
      params.append('endDate', endDate);
      return params;
    };
    setIsLoading(true);
    setIsCheckedAll(false);
    try {
      const { data } = await axios.get(`${DELIVERY_ORDER_INVOICE}?${getQueryParams()}`);
      setMetaDelivery(data.meta);
      // setDeliveryInvoice(data.data.map((val: DeliveryInvoice) => ({ ...val, checked: false })));
      setDeliveryInvoice(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleCheckListAll = (isChecked: boolean) => {
    setIsCheckedAll(isChecked);
    setDeliveryInvoice(deliveryInvoice.map(val => ({ ...val, checked: isChecked })));
  };

  const handleCheckListIndividual = (id: number, isChecked: boolean) => {
    const index = deliveryInvoice.findIndex(val => val.id === id);
    const newList = [...deliveryInvoice];
    newList[index].checked = isChecked;
    setDeliveryInvoice(newList);
  };
  const handleSkip = () => {
    setIsSuccess(false);
    fetchDataInvoices();
  };

  const handleCreate = async () => {
    setIsLoadingCreate(true);
    const deliveryFinal = {
      totalInvoice: invoiceId.length,
      items: invoiceId.map(val => ({
        InvoiceId: val
      }))
    };

    try {
      const { data } = await axios.post(DELIVERY_ORDER_BASE_URL, deliveryFinal);
      setDeliveryId(data.data.id);
      handleCloseModalInfo();
      setIsLoadingCreate(false);
      setIsSuccess(true);
    } catch (error) {
      console.log(error);
    }
  };

  const deliveryPrint = async () => {
    setIsLoadingPrint(true);
    try {
      const { data } = await axios.get(DELIVERY_ORDER_PRINT(deliveryId), { responseType: 'blob' });
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      setIsLoadingPrint(false);
      const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
      if (newwindow) {
        newwindow.focus();
      }
      handleSkip();
    } catch (error) {
      console.log('error :', error);
    }
  };

  const handleRefresh = () => {
    setPartner('');
    setWareHouse('');
    setSalesName('');
    setRute('');
    setInvoiceNumber('');
    setCurrentPage(1);
    setIsCheckedAll(false);
  };

  useEffect(() => {
    handleGetWareHouse();
    handleSearchPartner();
  }, []);

  const debouncedFetchHits = useDebounced(fetchDataInvoices, 500);
  useEffect(() => {
    debouncedFetchHits();
  }, [partner, wareHouse, currentPage, salesName, invoiceNumber, startDate, endDate]);

  return (
    <Page title='Surat Jalan Create'>
      <Container>
        <Grid xs={12}>
          <Typography variant='h1' component='h1'>
            Surat Jalan
          </Typography>
          <Breadcrumb />
        </Grid>

        <PaperCustom>
          <Grid xs={12} container style={{ rowGap: '1em' }}>
            <Grid xs={12} container alignItems='center'>
              <Grid xs={12} container style={{ columnGap: '1em' }}>
                <Typography variant='h5'>Buat Surat Jalan Baru</Typography>
              </Grid>

              <Grid xs={12}>
                <Typography color='textSecondary'>Pilih Invoice untuk surat jalan </Typography>
              </Grid>
            </Grid>
            <Grid xs={12} container alignItems='center'>
              <Grid xs={10} container style={{ columnGap: '1em' }} alignItems='center'>
                <Grid item>
                  <Tooltip title='Memuat Ulang'>
                    <Button size='small' onClick={handleRefresh} color='inherit' className={classes.refresh}>
                      <Refresh fontSize='small' />
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant='h6'>
                    {`Menampilkan ${metaDelivery.total || 0} Invoice (${metaDelivery.from || 0} - ${metaDelivery.to || 0} dari ${metaDelivery.total ||
                      0})`}
                  </Typography>
                  <Typography color='primary'>
                    Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={2} container justify='flex-end'>
                <Tooltip title='Calendar filter' placement='top'>
                  <Button color='primary' variant='outlined' onClick={handleCalendarFilterClick}>
                    <Today />
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>

            <DeliveryContent
              deliveryInvoice={deliveryInvoice}
              isLoading={isLoading}
              salesName={salesName}
              partner={partner}
              wareHouse={wareHouse}
              partners={partners}
              setWareHouse={setWareHouse}
              handleOpenModalInfo={handleOpenModalInfo}
              wareHouses={wareHouses}
              setPartner={setPartner}
              isLoadingPartner={isLoadingPartner}
              setSalesName={setSalesName}
              invoiceNumber={invoiceNumber}
              setInvoiceNumber={setInvoiceNumber}
            />

            {deliveryInvoice.length > 0 && (
              <Grid xs={12} container justify='flex-end'>
                <Pagination
                  count={metaDelivery.last_page}
                  onChange={(event, page) => setCurrentPage(page)}
                  boundaryCount={2}
                  variant='outlined'
                  shape='rounded'
                />
              </Grid>
            )}
          </Grid>
          <ModalSuccess open={isSuccess} deliveryPrint={deliveryPrint} isLoadingPrint={isLoadingPrint} handleSkip={handleSkip} />
          <ModalInfo open={isInfo} isLoading={isLoadingCreate} handleCloseModalInfo={handleCloseModalInfo} handleCreate={handleCreate} />
          <DateRangeFilter
            openCalendarFilter={openCalendarFilter}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            handleClose={() => setOpenCalendarFilter(false)}
          />
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default DeliveryOrdersCreate;
