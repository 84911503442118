import React, { FC } from 'react';
import {
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  makeStyles,
  MenuItem,
  Select,
  ListItemText,
  Checkbox
} from '@material-ui/core';
import { HeaderRow, TableCellHead, TableCustom } from 'components/Table';
import { dummyPurchaseInvoicePayment } from 'utils/dummy';
import BodyRow from './components/BodyRow';
import PaymentMethod from 'typings/enum/PaymentMethod';
import PaymentStatus from 'typings/enum/PaymentStatus';

interface Props {
  isLoadingData: boolean;
  invoicePayments: PurchaseInvoicePaymentModel[];
  invoicePaymentReturn: InvoiceReturnModel[];
  isLoadingCollapse: boolean;
  indexCollapse: number;
  openCollapse: boolean;
  order: 'asc' | 'desc';
  orderBy: string;
  name: string;
  code: string;
  totalPay: string;
  status: string;
  payDate: Date;
  paymentMethod: string;
  invoiceNumber: string;
  salesName: string;
  giroNumber: string;
  zones: ZoneModel[];
  selectedZone: number[];
  setSelectedZone: React.Dispatch<React.SetStateAction<number[]>>;
  setInvoiceNumber: React.Dispatch<React.SetStateAction<string>>;
  setGiroNumber: React.Dispatch<React.SetStateAction<string>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setSalesName: React.Dispatch<React.SetStateAction<string>>;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  setTotalPay: React.Dispatch<React.SetStateAction<string>>;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  setPayDate: React.Dispatch<React.SetStateAction<Date>>;
  setPaymentMethod: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  handleOpenCollapse: (index: number) => React.MouseEventHandler;
}

const useStyles = makeStyles({
  cellStart: {
    width: '20%'
  },
  cellTable: {
    width: '17%'
  }
});

const PaymentTable: FC<Props> = props => {
  const classes = useStyles();

  const {
    isLoadingData,
    invoicePayments,
    order,
    orderBy,
    name,
    salesName,
    setSalesName,
    giroNumber,
    setGiroNumber,
    code,
    setName,
    setCode,
    setOrder,
    setOrderBy,
    status,
    setStatus,
    paymentMethod,
    setPaymentMethod,
    invoiceNumber,
    setInvoiceNumber,
    zones,
    selectedZone,
    setSelectedZone,
    invoicePaymentReturn,
    isLoadingCollapse,
    indexCollapse,
    openCollapse,
    handleOpenCollapse
  } = props;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={[
              { id: 'paymentNumber', label: 'No Kwitansi', sort: true },
              { id: 'name', label: 'Nama Customer', sort: true },
              { id: 'route', label: 'Route', sort: true },
              { id: 'payDate', label: 'Tgl Kwitansi & Pembayaran', sort: true },
              { id: 'paymentMethod', label: 'Metode Pembayaran', sort: true },
              { id: 'giroNumber', label: 'Nomor Giro', sort: true },
              { label: 'Status' }
            ]}
          />
          <TableRow>
            <TableCellHead variant='head' className={classes.cellStart}>
              <TextField id='paymentNumber' placeholder='No Kwitansi' value={code} onChange={event => setCode(event.target.value)} />
            </TableCellHead>
            <TableCellHead variant='head' className={classes.cellTable}>
              <TextField placeholder='Nama Customer' id='name' value={name} onChange={event => setName(event.target.value)} />
            </TableCellHead>
            <TableCellHead variant='head' className={classes.cellTable}>
              <Select
                id='demo-mutiple-checkbox'
                margin='dense'
                multiple
                variant='outlined'
                fullWidth
                value={selectedZone}
                onChange={e => setSelectedZone(e.target.value as number[])}
                renderValue={selected =>
                  `${zones
                    .filter(value => (selected as number[]).includes(value.id as number))
                    .map(value => value.name)
                    .join(',')
                    .substr(0, 8)}...`
                }
              >
                {zones.map(value => (
                  <MenuItem key={value.id} value={value.id}>
                    <Checkbox checked={selectedZone.indexOf(value.id) > -1} />
                    <ListItemText primary={value.name} />
                  </MenuItem>
                ))}
              </Select>
            </TableCellHead>

            <TableCellHead variant='head' className={classes.cellTable}></TableCellHead>

            <TableCellHead variant='head' className={classes.cellTable}>
              <TextField
                select
                placeholder='Metode Pembayaran'
                id='paymentMethod'
                value={paymentMethod}
                onChange={event => setPaymentMethod(event.target.value)}
                style={{ width: '100%' }}
              >
                <MenuItem key={0} value=''>
                  Semua Metode Pembayaran
                </MenuItem>
                <MenuItem key={1} value={PaymentMethod.BANK_TRANSFER}>
                  Bank
                </MenuItem>
                <MenuItem key={2} value={PaymentMethod.CASH}>
                  Cash
                </MenuItem>
                <MenuItem key={3} value={PaymentMethod.GIRO}>
                  Giro
                </MenuItem>
              </TextField>
            </TableCellHead>

            <TableCellHead variant='head' className={classes.cellTable}>
              <TextField placeholder='Nomor Giro' id='giroNumber' value={giroNumber} onChange={event => setGiroNumber(event.target.value)} />
            </TableCellHead>

            <TableCellHead variant='head' className={classes.cellTable}>
              <TextField
                select
                placeholder='Status'
                id='status'
                value={status}
                onChange={event => setStatus(event.target.value)}
                style={{ width: '100%' }}
              >
                <MenuItem key={0} value=''>
                  Status Pembayaran
                </MenuItem>
                <MenuItem key={1} value={PaymentStatus.PAID}>
                  Diterima / Sudah Cair
                </MenuItem>
                <MenuItem key={2} value={PaymentStatus.UNPAID}>
                  Belum Cair
                </MenuItem>
              </TextField>
            </TableCellHead>

            <TableCellHead variant='head' className={classes.cellTable}></TableCellHead>
            <TableCellHead variant='head' colSpan={2} className={classes.cellTable}></TableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow
                key={index}
                isLoading={isLoadingData}
                invoicePayment={dummyPurchaseInvoicePayment}
                invoicePaymentReturn={invoicePaymentReturn}
                isLoadingCollapse={isLoadingCollapse}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                handleOpenCollapse={handleOpenCollapse(0)}
              />
            ))
          ) : invoicePayments && invoicePayments.length > 0 ? (
            invoicePayments.map((value, index) => (
              <BodyRow
                key={index}
                isLoading={isLoadingData}
                invoicePayment={value}
                invoicePaymentReturn={invoicePaymentReturn}
                isLoadingCollapse={isLoadingCollapse}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                handleOpenCollapse={handleOpenCollapse(value.id)}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={8} align='center'>
                {'Data Tidak Tersedia.'}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default PaymentTable;
