import React, { FC, Fragment } from 'react';
import clx from 'clsx';
import { Grid, makeStyles, Theme, Typography, Table, TableRow, TableCell, TableBody, Hidden, Divider } from '@material-ui/core';
import { format } from 'date-fns';
import Skeleton from '@material-ui/lab/Skeleton';
import CCATMP from 'images/NewLogo.jpg';
import { BLACK } from 'constants/colors';

interface Props {
  salesOrder: SalesOrderModel;
  isLoadingData: boolean;
  company: CompanyModel;
  showComponent: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  cellOrder: {
    marginBottom: 1,
    padding: '2px 0px 2px 2px',

    border: 'none'
  },
  cellOrderRight: {
    width: '35%',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontStyle: 'normal',
    color: BLACK
  },
  cellOrderLeft: {
    width: '30%'
  },
  titleOrder: {
    color: BLACK,
    marginBottom: '16px'
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: ' none'
    }
  },

  desktop: {
    [theme.breakpoints.down('md')]: {
      display: ' none'
    }
  },
  fontMobile: {
    fontSize: 0.875 + 'rem',
    color: BLACK,
    lineHeight: 1.2 + 'rem'
  },

  gridContainer: {
    [theme.breakpoints.down('md')]: {
      margin: 10 + 'px'
    }
  }
}));

const SalesOrderInfo: FC<Props> = props => {
  const classes = useStyles();
  const { salesOrder, isLoadingData, showComponent, company } = props;
  return (
    <Fragment>
      <Hidden smDown xsDown>
        <Grid item lg={8} sm={8} md={8} xs={8}>
          <img src={CCATMP} alt='company' width='236px' height='236' />
        </Grid>
      </Hidden>

      <Grid container item lg={4} sm={12} md={4} xs={12} justify='flex-end' className={classes.gridContainer}>
        <Table>
          <TableBody>
            <TableRow className={classes.desktop}>
              <TableCell align='right' colSpan={2} className={classes.cellOrder}>
                <Typography variant='h1' className={classes.titleOrder}>
                  Order Penjualan
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                {showComponent ? (
                  <Typography className={classes.fontMobile}>ID Penjualan</Typography>
                ) : (
                  <Typography variant='subtitle2'>ID Penjualan</Typography>
                )}
              </TableCell>
              <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                {isLoadingData ? (
                  <Skeleton variant='text' width='100%' />
                ) : company.id === 0 ? (
                  <Skeleton variant='text' width='100%' />
                ) : showComponent ? (
                  <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
                    {salesOrder.orderNumber || '-'}
                  </Typography>
                ) : (
                  salesOrder.orderNumber || '-'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                {showComponent ? (
                  <Typography className={classes.fontMobile}>Tanggal Order</Typography>
                ) : (
                  <Typography variant='subtitle2'>Tanggal Order</Typography>
                )}
              </TableCell>
              <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                {isLoadingData ? (
                  <Skeleton variant='text' width='100%' />
                ) : company.id === 0 ? (
                  <Skeleton variant='text' width='100%' />
                ) : salesOrder.orderDate ? (
                  showComponent ? (
                    <Typography className={classes.fontMobile}>{format(new Date(salesOrder.orderDate), 'dd-MM-yyyy')}</Typography>
                  ) : (
                    format(new Date(salesOrder.orderDate), 'dd-MM-yyyy')
                  )
                ) : '' ? (
                  <Skeleton variant='text' width='100%' />
                ) : showComponent ? (
                  <Typography className={classes.fontMobile}> {salesOrder.orderNumber || '-'}</Typography>
                ) : (
                  salesOrder.orderNumber || '-'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                {showComponent ? <Typography className={classes.fontMobile}>Rute</Typography> : <Typography variant='subtitle2'>Rute</Typography>}
              </TableCell>
              <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                {isLoadingData ? (
                  <Skeleton variant='text' width='100%' />
                ) : company.id === 0 ? (
                  <Skeleton variant='text' width='100%' />
                ) : salesOrder.zoneName ? (
                  showComponent ? (
                    <Typography className={classes.fontMobile}>{salesOrder.zoneName}</Typography>
                  ) : (
                    salesOrder.zoneName
                  )
                ) : (
                  '' || '-'
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                {showComponent ? (
                  <Typography className={classes.fontMobile}>Sales</Typography>
                ) : (
                  <Typography variant='subtitle2' color='initial'>
                    Sales
                  </Typography>
                )}
              </TableCell>
              <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                {isLoadingData ? (
                  <Skeleton variant='text' width='100%' />
                ) : company.id === 0 ? (
                  <Skeleton variant='text' width='100%' />
                ) : salesOrder.salesName ? (
                  showComponent ? (
                    <Typography className={classes.fontMobile}>{salesOrder.salesName}</Typography>
                  ) : (
                    salesOrder.salesName
                  )
                ) : (
                  ''
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Fragment>
  );
};

export default SalesOrderInfo;
