import React, { FC, useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, makeStyles, Typography } from '@material-ui/core';
import { PRODUCT_BASE_URL } from 'constants/url';
import { dummyReturnItem, dummyProduct } from 'utils/dummy';
import BodyRow from './components/BodyRow';
import ReturnOrderItem from './components/ReturnOrderItem';
import useDebounce from 'hooks/useDebounce';
import TypeUser from 'typings/enum/TypeUser';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import useRole from 'hooks/useRole';

interface Props {
  returnOrderItem: InvoiceReturnItemModel[];
  historyPrice: number;
  productAdditionalDamage: number;
  isUpdate: boolean;
  loadUpdate: boolean;
  typeReturn: string;
  totalQtyReturn: number;
  additionalDamage: number;
  countAdditionalDamage: number;
  countTotalItem: number;
  handleOpenHistory: () => void;
  handleOpenReturn: (isReturn: boolean) => void;
  isAdmin: boolean;
  totalItem: number;
  totalQtyReturnWarehouse: number;
  setTotalItem: React.Dispatch<React.SetStateAction<number>>;
  setProductAdditionalDamage: React.Dispatch<React.SetStateAction<number>>;
  setAdditionalDamage: React.Dispatch<React.SetStateAction<number>>;
  setProductId: React.Dispatch<React.SetStateAction<number>>;
  setReturnOrderItem: React.Dispatch<React.SetStateAction<InvoiceReturnItemModel[]>>;
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteItem: (index: number) => React.MouseEventHandler;
  setAdditionalDamageFilter: React.Dispatch<React.SetStateAction<number>>;
  setTotalItemFilter: React.Dispatch<React.SetStateAction<number>>;
}

const useStyles = makeStyles({
  addProductCell: {
    border: 'none'
  },
  xtraSmallTable: {
    width: '10%'
  },
  smallTable: {
    width: '15%'
  },
  bigTable: {
    width: '35%'
  }
});

const ProductTable: FC<Props> = props => {
  const classes = useStyles();
  const {
    productAdditionalDamage,
    returnOrderItem,
    isUpdate,
    loadUpdate,
    historyPrice,
    additionalDamage,
    typeReturn,
    totalItem,
    setTotalItem,
    totalQtyReturn,
    totalQtyReturnWarehouse,
    isAdmin,
    handleOpenReturn,
    setReturnOrderItem,
    setUpdate,
    handleDeleteItem,
    setProductId,
    handleOpenHistory,
    setAdditionalDamage,
    setProductAdditionalDamage,
    countAdditionalDamage,
    countTotalItem,
    setTotalItemFilter,
    setAdditionalDamageFilter
  } = props;
  const [price, setPrice] = useState<number>(0);

  const [typeUnit, setTypeUnit] = useState<string>('');
  const [discount, setDiscount] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [subTotalPrice, setSubTotalPrice] = useState<number>(0);
  const [product, setProduct] = useState<ProductModel>(dummyProduct);
  const [products, setProducts] = useState<ProductModel[]>([dummyProduct]);
  const [loadProduct, setLoadProduct] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [productMessage, setProductMessage] = useState<string>('');
  const [priceMessageError, setPriceMessageError] = useState<string>('');
  const [totalItemMessage, setTotalItemMessage] = useState<string>('');
  const [totalItemMessageSupplier, setTotalItemMessageSupplier] = useState<string>('');
  const [selectProduct, setSelectProduct] = useState<string>('');
  const { currentUser } = useContext(CurrentUserContext);

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN, TypeUser.ADMIN03]
  });

  const handleAddItem = () => {
    if (!validation()) {
      return;
    }

    setReturnOrderItem([
      ...returnOrderItem,
      {
        ...dummyReturnItem,
        price,
        typeUnit,
        discount,
        totalPrice,
        subTotalPrice,
        additionalDamage,
        totalItem,
        ProductId: product.id,
        Product: product
      }
    ]);
    reset();
  };

  const handleUpdateItem = (index: number): React.MouseEventHandler => () => {
    const item: InvoiceReturnItemModel = returnOrderItem[index];

    setUpdate(true);
    setCurrentIndex(index);
    setProduct(item.Product!);
    setAdditionalDamage(item.additionalDamage!);
    setTotalPrice(item.totalPrice);
    setDiscount(item.discount);
    setPrice(item.price);
    setTypeUnit(item.typeUnit);
    resetValidation();
    setAdditionalDamageFilter(item.additionalDamage!);
    setTotalItemFilter(item.totalItem);
    setTotalItem(item.totalItem);
  };

  const handleSaveUpdate = (index: number): React.MouseEventHandler => () => {
    if (!validation()) {
      return;
    }

    const item: InvoiceReturnItemModel = {
      ...returnOrderItem[index],
      price,
      typeUnit,
      discount,
      totalPrice,
      additionalDamage,
      subTotalPrice,
      totalItem,
      ProductId: product.id,
      Product: product
    };

    returnOrderItem[index] = item;
    setReturnOrderItem(returnOrderItem);
    setUpdate(false);
    setCurrentIndex(-1);
    reset();
  };

  const handleCloseUpdate = () => {
    setUpdate(false);
    setCurrentIndex(-1);
    reset();
    resetValidation();
  };

  const reset = () => {
    setProduct(dummyProduct);
    setPrice(0);
    setTotalItem(0);
    setDiscount(0);
    setAdditionalDamage(0);
    setTotalPrice(0);
    setTotalItemFilter(0);
    setAdditionalDamageFilter(0);
  };

  const validation = () => {
    let valid = true;
    resetValidation();

    if (!product || product.id === 0) {
      setProductMessage('Produk tidak boleh kosong');
      valid = false;
    }

    if (typeReturn !== 'SUPPLIER' && (!totalItem || totalItem === 0)) {
      setTotalItemMessage('Kuantitas tidak boleh kosong');
      valid = false;
    }

    if (typeReturn === 'SUPPLIER' && isSuperAdmin && price === 0) {
      setPriceMessageError('Harga tidak boleh 0');
      valid = false;
    }

    return valid;
  };

  const resetValidation = () => {
    setProductMessage('');
    setTotalItemMessage('');
    setTotalItemMessageSupplier('');
    setPriceMessageError('');
  };

  const debouncedValue = useDebounce(selectProduct, 500);

  const handleSearchProduct = useCallback(async () => {
    const params = new URLSearchParams();
    params.append('route', (currentUser && currentUser.SalesRoute ? currentUser.SalesRoute.map(value => value.ZoneId) : []).join(','));
    params.append('keyword', selectProduct);
    params.append('page', '1');
    params.append('ordered', 'desc');
    params.append('isProductPackage', 'false');

    setLoadProduct(true);
    try {
      const { data } = await axios.get(`${PRODUCT_BASE_URL}?${params.toString()}`);

      setProducts(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadProduct(false);
    }
  }, [debouncedValue]);

  useEffect(() => {
    handleSearchProduct();
  }, [handleSearchProduct]);

  useEffect(() => {
    setSubTotalPrice(typeReturn === 'SUPPLIER' ? (additionalDamage + totalItem) * price : totalItem * price);
    setTotalPrice((typeReturn === 'SUPPLIER' ? additionalDamage + totalItem : totalItem) * price - discount);
  }, [totalItem, price, discount, additionalDamage]);

  useEffect(() => {
    if (historyPrice < 1) {
      setPrice(0);
      return;
    }

    setPrice(historyPrice);
  }, [historyPrice]);

  useEffect(() => {
    if (product.id === 0) {
      setProductId(0);
      setTypeUnit('');
      setPrice(0);
      return;
    }

    setProductId(product.id);
    setTypeUnit(product.typeUnit);
  }, [product]);



  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='center' width='35%'>
              Nama Produk
            </TableCell>

            {(typeReturn === 'CUSTOMER' || (typeReturn === 'SUPPLIER' && isSuperAdmin)) && (
              <TableCell align='center' width='15%'>
                Harga
              </TableCell>
            )}

            <TableCell align='center' width='18%'>
              Kuantitas <Typography variant='h6'>(dari Penjualan) </Typography>
            </TableCell>

            {typeReturn === 'SUPPLIER' && (
              <TableCell align='center' width='18%'>
                Kuantitas <Typography variant='h6'>(dari Gudang) </Typography>
              </TableCell>
            )}

            {(typeReturn === 'CUSTOMER' || (typeReturn === 'SUPPLIER' && isSuperAdmin)) && (
              <TableCell align='center' width='15%'>
                Total Harga
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {!loadUpdate && returnOrderItem.length === 0 && (
            <TableRow>
              <TableCell colSpan={typeReturn === 'CUSTOMER' ? 4 : typeReturn === 'SUPPLIER' && !isAdmin ? 5 : 3} align='center'>
                Belum ada Produk yang ditambahkan.
              </TableCell>
            </TableRow>
          )}

          {loadUpdate
            ? [1, 2, 3, 4].map((value, index) => (
                <BodyRow
                  key={index + 1}
                  index={index}
                  typeReturn={typeReturn}
                  currentIndex={currentIndex}
                  item={dummyReturnItem}
                  isLoading={loadUpdate}
                  isAdmin={isAdmin}
                  handleUpdateItem={handleUpdateItem}
                  handleDeleteItem={handleDeleteItem}
                />
              ))
            : returnOrderItem.length > 0 &&
              returnOrderItem
                .filter(value => !value.isDeleted)
                .map((value, index) =>
                  isUpdate && index === currentIndex ? (
                    <ReturnOrderItem
                      productAdditionalDamage={productAdditionalDamage}
                      product={product}
                      products={products}
                      countAdditionalDamage={countAdditionalDamage}
                      countTotalItem={countTotalItem}
                      price={price}
                      totalItem={totalItem}
                      typeUnit={typeUnit}
                      discount={discount}
                      totalQtyReturn={totalQtyReturn}
                      totalQtyReturnWarehouse={totalQtyReturnWarehouse}
                      totalPrice={totalPrice}
                      isUpdate={isUpdate}
                      typeReturn={typeReturn}
                      handleOpenReturn={handleOpenReturn}
                      index={index}
                      currentIndex={currentIndex}
                      handleOpenHistory={handleOpenHistory}
                      loadProduct={loadProduct}
                      productMessage={productMessage}
                      priceMessageError={priceMessageError}
                      totalItemMessage={totalItemMessage}
                      additionalDamage={additionalDamage}
                      totalItemMessageSupplier={totalItemMessageSupplier}
                      isAdmin={isAdmin}
                      setAdditionalDamage={setAdditionalDamage}
                      setProduct={setProduct}
                      setPrice={setPrice}
                      setTotalItem={setTotalItem}
                      setProductAdditionalDamage={setProductAdditionalDamage}
                      setDiscount={setDiscount}
                      setSelectProduct={setSelectProduct}
                      setTypeUnit={setTypeUnit}
                      handleSearchProduct={handleSearchProduct}
                      handleSaveUpdate={handleSaveUpdate}
                      handleCloseUpdate={handleCloseUpdate}
                    />
                  ) : (
                    <BodyRow
                      key={index + 1}
                      index={index}
                      typeReturn={typeReturn}
                      currentIndex={currentIndex}
                      item={value}
                      isLoading={loadUpdate}
                      isAdmin={isAdmin}
                      handleUpdateItem={handleUpdateItem}
                      handleDeleteItem={handleDeleteItem}
                    />
                  )
                )}

          {!isUpdate && (
            <ReturnOrderItem
              productAdditionalDamage={productAdditionalDamage}
              product={product}
              products={products}
              price={price}
              totalItem={totalItem}
              typeUnit={typeUnit}
              typeReturn={typeReturn}
              discount={discount}
              totalPrice={totalPrice}
              handleOpenHistory={handleOpenHistory}
              totalItemMessageSupplier={totalItemMessageSupplier}
              index={0}
              currentIndex={0}
              handleOpenReturn={handleOpenReturn}
              totalQtyReturn={totalQtyReturn}
              totalQtyReturnWarehouse={totalQtyReturnWarehouse}
              loadProduct={loadProduct}
              isUpdate={isUpdate}
              productMessage={productMessage}
              priceMessageError={priceMessageError}
              totalItemMessage={totalItemMessage}
              additionalDamage={additionalDamage}
              countAdditionalDamage={countAdditionalDamage}
              countTotalItem={countTotalItem}
              setProductAdditionalDamage={setProductAdditionalDamage}
              isAdmin={isAdmin}
              setAdditionalDamage={setAdditionalDamage}
              setProduct={setProduct}
              setPrice={setPrice}
              setTotalItem={setTotalItem}
              setDiscount={setDiscount}
              setSelectProduct={setSelectProduct}
              setTypeUnit={setTypeUnit}
              handleSearchProduct={handleSearchProduct}
              handleSaveUpdate={handleSaveUpdate}
              handleCloseUpdate={handleCloseUpdate}
            />
          )}

          {!isUpdate && (
            <TableRow>
              <TableCell
                align='right'
                colSpan={typeReturn === 'CUSTOMER' ? 6: typeReturn === 'SUPPLIER' && !isAdmin ? 6 : 5}
                className={classes.addProductCell}
              >
                <Button
                  variant='contained'
                  onClick={handleAddItem}
                  disabled={
                    typeReturn === 'CUSTOMER'
                      ? totalItem < 0 || totalPrice < 1 || isNaN(totalPrice) || additionalDamage < 0 || additionalDamage > totalQtyReturnWarehouse
                      : totalItem < 0 ||
                        totalItem > totalQtyReturn - countTotalItem ||
                        totalPrice < 1 ||
                        isNaN(totalPrice) ||
                        additionalDamage < 0 ||
                        additionalDamage > totalQtyReturnWarehouse - countAdditionalDamage ||
                        (typeReturn === 'SUPPLIER' && totalItem > totalQtyReturn)
                  }
                >
                  Tambah Produk
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductTable;
