import React, { FC, Fragment, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
  TextField,
  Checkbox,
  DialogActions,
  MenuItem,
  Divider,
  InputAdornment,
  Input,
  InputLabel,
  FormControl,
  OutlinedInput,
  FormHelperText,
  IconButton,
  CircularProgress,
  Menu
} from '@material-ui/core';
import { DialogTitle } from 'components/Dialog';
import useRouter from 'hooks/useRouter';
import NumberFormat from 'react-number-format';
import { BLACK, RED, WHITE, GREEN_MOBILE, BLUE_PRIMARY, YELLOW_MOBILE, ORANGE_MOBILE, PRIMARY_MOBILE, GREY } from 'constants/colors';

import {
  Add,
  ArrowRightAlt,
  CheckCircle,
  Close,
  Description,
  ErrorOutlineRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  Height,
  InfoOutlined,
  InputOutlined,
  Print,
  Remove,
  WarningRounded
} from '@material-ui/icons';
import TypeUser from 'typings/enum/TypeUser';

import { remarkCode } from 'constants/remarkCode';
import SalesOrderStatus from 'typings/enum/SalesOrderStatus';

interface Props {
  isAccess: string;
  discountResource: { productName: string; totalQty: number; discount: number; totalDiscount: number };
  openEdit: boolean;
  showComponent: boolean;

  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    fontWeight: 500,
    fontSize: '1.2rem',
    color: BLACK,
    paddingLeft: '6px'
  },
  fontSizeMobile: {
    color: BLACK,
    lineHeight: 1 + 'rem',
    fontSize: '1rem'
  },
  button: {
    borderRadius: '15px',
    padding: '7px 0'
  },

  itemContainer: {
    border: '0.6px solid rgba(0, 0, 0, 0.08)',
    marginBottom: theme.spacing(2),
    borderRadius: '6px',
    padding: 10 + 'px'
  },
  warningAlert: {
    backgroundColor: '#fff4e5',
    padding: '0.5em',
    margin: '0.7em 0',
    borderRadius: '1px 0px'
  }
}));

const ModalDiscount: FC<Props> = props => {
  const classes = useStyles();
  const { history } = useRouter();
  const {
    isAccess,
    openEdit,

    setOpenEdit,

    showComponent,

    discountResource
  } = props;

  return (
    <Dialog
      open={openEdit}
      onClose={() => setOpenEdit(false)}
      fullWidth={showComponent ? true : false}
      style={{ maxHeight: 80 + 'vh', marginTop: showComponent ? '60px' : '0' }}
      scroll='paper'
    >
      <DialogTitle>
        <Grid item container direction='row' justify='space-between' alignItems='center' xs={12}>
          <Typography className={classes.titleContainer}>{isAccess === TypeUser.ADMIN03 ? 'Detail Discount' : 'Edit Discount'}</Typography>
          <Close
            fontSize='small'
            color='action'
            onClick={() => {
              setOpenEdit(false);
            }}
            style={{ cursor: 'pointer' }}
          />
        </Grid>
      </DialogTitle>
      <DialogContent>
        {/* {isAccess === TypeUser.SUPERADMIN && (
          <React.Fragment>
            <TextField
              id='name'
              size='small'
              required
              label='Nama Produk'
              InputProps={{
                classes: {
                  input: classes.fontSizeMobile
                }
              }}
              fullWidth
              value='Blender Homey Pelastik B2PC 7 Tabung'
              disabled
            />
            <Grid item container direction='row' xs={12} justify='space-between' style={{ display: 'flex' }}>
              <Grid item container direction='row' alignItems='center' style={{ flex: 0.48 }}>
                <TextField
                  id='qty-request'
                  size='small'
                  required
                  label='Qty'
                  InputProps={{
                    classes: {
                      input: classes.fontSizeMobile
                    }
                  }}
                  InputLabelProps={{ style: { fontSize: showComponent ? 15 : '1rem' } }}
                  value={5}
                />
              </Grid>
              <Grid item container direction='row' alignItems='center' justify='flex-start' style={{ flex: 0.46 }}>
                <TextField
                  id='edit-qty'
                  size='small'
                  required
                  label='Edit Discount'
                  InputProps={{
                    classes: {
                      input: classes.fontSizeMobile
                    }
                  }}
                  InputLabelProps={{ style: { fontSize: showComponent ? 15 : '1rem' } }}
                  value='Rp50,000'
                  autoFocus={true}
                />
              </Grid>
              <Grid xs={12} container alignItems='center' className={classes.warningAlert}>
                <InfoOutlined style={{ color: '#ff9800' }} />
                <Typography className={classes.fontSizeMobile}>Diskon Per Item Rp.23.000</Typography>
              </Grid>
            </Grid>

            <TextField
              select
              size='small'
              id='edit'
              value=''
              label='Alasan Edit'
              InputProps={{
                classes: {
                  input: classes.fontSizeMobile
                }
              }}
              InputLabelProps={{ style: { fontSize: showComponent ? 0.7 + 'rem' : '1rem' } }}
              autoComplete='off'
              fullWidth
              // onChange={event => setStatusOrder(event.target.value as string)}
            >
              <MenuItem key={1} value='' selected disabled className={classes.fontSizeMobile}>
                Pilih Alasan
              </MenuItem>
              <MenuItem key={2} value='PENDING' className={classes.fontSizeMobile}>
                Barang fisik rusak
              </MenuItem>
              <MenuItem key={3} value='CONFIRMATION' className={classes.fontSizeMobile}>
                Barang fisik rusak
              </MenuItem>
              <MenuItem key={4} value='CANCEL' className={classes.fontSizeMobile}>
                Barang fisik rusak
              </MenuItem>
              <MenuItem key={5} value='CANCEL' className={classes.fontSizeMobile}>
                Lain nya
              </MenuItem>
            </TextField>
          </React.Fragment>
        )} */}

        <Grid xs={12}>
          <Grid className={classes.itemContainer}>
            <Typography className={classes.fontSizeMobile} style={{ fontWeight: 500, color: PRIMARY_MOBILE }}>
              Details Produk Awal
            </Typography>
            <Grid container style={{ marginTop: '0.8em' }}>
              <Grid xs={5} item>
                <Typography className={classes.fontSizeMobile}>Nama Produk</Typography>
                <Typography className={classes.fontSizeMobile} style={{ fontWeight: 500 }}>
                  {discountResource.productName}
                </Typography>
              </Grid>
              <Grid xs={2} item>
                <Typography className={classes.fontSizeMobile}>Total Qty</Typography>
                <Typography className={classes.fontSizeMobile} style={{ fontWeight: 500 }}>
                  {discountResource.totalQty}
                </Typography>
              </Grid>
              <Grid xs={2} item>
                <Typography className={classes.fontSizeMobile} align='right'>
                  Diskon/Qty
                </Typography>

                <Typography className={classes.fontSizeMobile} align='right' style={{ fontWeight: 500 }}>
                  <NumberFormat
                    value={discountResource.discount / discountResource.totalQty}
                    prefix={'Rp'}
                    thousandSeparator={true}
                    displayType='text'
                  />
                </Typography>
              </Grid>
              <Grid xs={3} item>
                <Typography className={classes.fontSizeMobile} align='right'>
                  Total Diskon
                </Typography>

                <Typography className={classes.fontSizeMobile} align='right' style={{ color: YELLOW_MOBILE, fontWeight: 500 }}>
                  <NumberFormat value={discountResource.discount} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.itemContainer}>
            <Typography className={classes.fontSizeMobile} style={{ fontWeight: 500, color: PRIMARY_MOBILE }}>
              Details Produk Akhir
            </Typography>
            <Grid container style={{ marginTop: '0.8em' }}>
              <Grid xs={5} item>
                <Typography className={classes.fontSizeMobile}>Nama Produk</Typography>
                <Typography className={classes.fontSizeMobile} style={{ fontWeight: 500 }}>
                  {discountResource.productName}
                </Typography>
              </Grid>
              <Grid xs={2} item>
                <Typography className={classes.fontSizeMobile}>Total Qty</Typography>
                <Typography className={classes.fontSizeMobile} style={{ fontWeight: 500 }}>
                  {discountResource.totalQty}
                </Typography>
              </Grid>
              <Grid xs={2} item>
                <Typography className={classes.fontSizeMobile} align='right'>
                  Diskon/Qty
                </Typography>

                <Typography className={classes.fontSizeMobile} align='right' style={{ fontWeight: 500 }}>
                  <NumberFormat
                    value={discountResource.discount / discountResource.totalQty}
                    prefix={'Rp'}
                    thousandSeparator={true}
                    displayType='text'
                  />
                </Typography>
              </Grid>
              <Grid xs={3} item>
                <Typography className={classes.fontSizeMobile} align='right'>
                  Total Diskon
                </Typography>

                <Typography className={classes.fontSizeMobile} align='right' style={{ color: YELLOW_MOBILE, fontWeight: 500 }}>
                  <NumberFormat value={discountResource.discount} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item container direction='row' justify='space-evenly' alignItems='center'>
          {/* {isAccess === TypeUser.SUPERADMIN && (
            <Grid xs={11}>
              <Button size='small' fullWidth className={classes.button} color='primary' onClick={() => console.log('jajaj')}>
                Simpan
              </Button>
            </Grid>
          )} */}

          {/* {isAccess === TypeUser.ADMIN03 && ( */}
          <Grid xs={11}>
            <Button size='small' fullWidth className={classes.button} color='primary' onClick={() => setOpenEdit(false)}>
              Tutup
            </Button>
          </Grid>
          {/* )} */}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDiscount;
