import React, { FC } from 'react';
import { TableBody, TableHead, TableRow, TableCell, TableContainer } from '@material-ui/core';
import BodyRow from './components/BodyRow';
import { TableCustom } from 'components/Table';

interface Props {
  isLoadingData: boolean;
  stockItem: StockItemModel[];
}

const ListProduct: FC<Props> = props => {
  const { isLoadingData, stockItem } = props;
  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <TableRow>
            <TableCell>Nama Produk</TableCell>
            <TableCell align='center'>Kategori</TableCell>
            <TableCell align='right'>Harga Beli</TableCell>
            <TableCell align='center'>Jumlah</TableCell>
            <TableCell align='right'>Total Harga</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stockItem.length > 0 && stockItem.map((value, index) => <BodyRow key={index} isLoading={isLoadingData} stockItem={value} />)}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default ListProduct;
