import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Checkbox } from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart } from 'components';
import TableRowCustom from 'components/Table/TableRowCustom';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import NumberFormat from 'react-number-format';

interface Props {
  index: number;
  invoice: InvoiceReturnModel;
  invoicePayment: InvoicePaymentModel;
  isLoading: boolean;
  handleIndividualCheck: (item: InvoiceReturnModel) => void;
}

const BodyRow: FC<Props> = props => {
  const { invoice, isLoading, invoicePayment, handleIndividualCheck } = props;
  const { id } = invoice;

  const handleSetId = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    handleIndividualCheck(invoice);
  };

  return (
    <TableRowCustom>
      <TableCellStart>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <Checkbox
            key={id}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<CheckBoxIcon />}
            edge='start'
            color='primary'
            checked={true}
            tabIndex={-1}
            disableRipple
            onChange={handleSetId}
          />
        )}
      </TableCellStart>

      <TableCellMiddle align='left'>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : invoice.returnNumber}</TableCellMiddle>

      <TableCellMiddle align='left'>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : <div dangerouslySetInnerHTML={{ __html: invoice.notes || '-' }}></div>}
      </TableCellMiddle>

      <TableCellMiddle align='center'>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : invoice.totalItem}</TableCellMiddle>

      <TableCellMiddle align='right'>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <NumberFormat value={invoice.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        )}
      </TableCellMiddle>

      <TableCellEnd align='right'>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <NumberFormat value={invoicePayment.totalPay} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        )}
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRow;
