import React, { FC } from 'react';
import { Tooltip, IconButton, Table, makeStyles, TableCell, Typography, Grid } from '@material-ui/core';
import { EditOutlined, DeleteOutline } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import { TableCellMiddle, TableCellEnd, TableCellStart } from 'components/Table/TableCellCustom';
import TableRowCustom from 'components/Table/TableRowCustom';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { AntSwitch, TableRowCollapse } from 'components';

const useStyles = makeStyles({
  subRow: {
    marginLeft: '100px'
  },
  subFirstRow: {
    borderLeft: 'none',
    borderBottom: 'none'
  }
});

interface Props {
  setId: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  indexCollapse: number;
  openCollapse: boolean;
  accountCategorie: AccountCategoryModel;
  isLoading: boolean;
  onDelete: React.MouseEventHandler;
  onUpdateSubAccount: React.MouseEventHandler;
  handleOpenCollapse: React.MouseEventHandler;
  onStatusChange: React.MouseEventHandler;
}

const SubBodyRow: FC<Props> = props => {
  const {
    onStatusChange,
    handleOpenCollapse,
    setId,
    indexCollapse,
    openCollapse,
    index,
    accountCategorie,
    isLoading,
    onDelete,
    onUpdateSubAccount
  } = props;
  const classes = useStyles();

  return (
    <>
      <TableRowCustom>
        <TableCell className={classes.subFirstRow}> </TableCell>
        <TableCellStart>
          {isLoading ? (
            <Skeleton variant='text' width={'100%'} height={25} />
          ) : (
            <>
              {accountCategorie.name}
              <br />
              {accountCategorie.number}
            </>
          )}
        </TableCellStart>
        <TableCellEnd width={'45%'} align='right'>
          {isLoading ? (
            <Skeleton variant='text' width={'100%'} height={25} />
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Tooltip title='Perbaharui Bagan Akun'>
                <IconButton size='small' onClick={onUpdateSubAccount} disabled={!accountCategorie.isEditable}>
                  <EditOutlined fontSize='small' color='primary' />
                  <span style={{ marginLeft: '4px', fontSize: '0.875rem' }}>Ubah</span>
                </IconButton>
              </Tooltip>

              <Typography component='div' style={{ paddingLeft: '.6em', fontSize: '0.875rem' }}>
                <Grid component='label' container alignItems='center' spacing={1}>
                  <Grid item>
                    <AntSwitch checked={accountCategorie.isActive} onClick={onStatusChange} />
                  </Grid>
                  <Grid item>{accountCategorie.isActive === true ? 'On' : 'Off'}</Grid>
                </Grid>
              </Typography>
            </div>
          )}
        </TableCellEnd>
      </TableRowCustom>
    </>
  );
};

export default SubBodyRow;
