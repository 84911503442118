import React, { FC, useRef, useState } from 'react';
import {
  Grid,
  makeStyles,
  Theme,
  IconButton,
  Typography,
  TableContainer,
  TextField,
  Table,
  CircularProgress,
  TableRow,
  TableCell,
  TableBody,
  Hidden,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Button,
  Fade
} from '@material-ui/core';
import clx from 'clsx';
import useRole from 'hooks/useRole';
import ListProduct from '../ListProduct';
import Skeleton from '@material-ui/lab/Skeleton';
import NumberFormat from 'react-number-format';
import { BLACK, WHITE, GREEN } from 'constants/colors';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import NumberFormatMask from 'components/NumberFormatMask';
import TimerOffTwoToneIcon from '@material-ui/icons/TimerOffTwoTone';
import CCATMP from 'images/NewLogo.jpg';
import useRouter from 'hooks/useRouter';
import { format } from 'date-fns';
import ModalPermission from './ModalPermission';

interface Props {
  invoice: ReturnBill;
  company: CompanyModel;
  isLoadingData: boolean;
  invoiceItem: ReturnBillItem[];
  invoicePayment?: InvoicePaymentModel[];
  openTotalPrice: boolean;
  loadingTotalPrice: boolean;
  isFinance: boolean;
  isInvoice: boolean;
  totalPrice: number;
  isRequestEdit: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  item: { id: number; notes: string; isRequestEdit: boolean }[];
  indexCollapse: number;
  indexEdit: number;
  type: string;
  loadingEdit: boolean;
  loadingRequest: boolean;
  onDeleteItem: (id: number) => void;
  onRefresh: () => void;
  onUpdate: () => void;
  individualEdit: (item: InvoiceItemModel) => void;
  handleRequest: () => void;
  handleEdit: (index: number, type: string) => void;
  handleOpenCollapse: (index: number) => React.MouseEventHandler;
  handleRequestNote: (item: { id: number; notes: string }) => void;
  handleRequestCheck: (item: { id: number; notes: string; isRequestEdit: boolean }) => void;

  handleOpenUpdateTotalPrice: () => void;
  handleCancelUpdateTotalPrice: () => void;
  updateTotalPrice: () => void;
  setTotalPrice: React.Dispatch<React.SetStateAction<number>>;
  setOpenPartner: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  cellSummaryRight: {
    border: 'none',
    padding: 4,
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontStyle: 'normal',
    color: BLACK
  },
  cellSummaryLeft: {
    width: '35%',
    border: 'none',
    padding: '4px 4px 4px 4px',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal',
    color: BLACK
  },
  cellInfoRight: {
    padding: '2px 0px 2px 2px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  cellInfoLeft: {
    padding: '2px 0px 2px 0px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  cellSummaryRightLink: {
    border: 'none',
    padding: 4,
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontStyle: 'normal',
    color: BLACK,
    textDecorationLine: 'underline',
    cursor: 'pointer'
  },
  note: {
    overflowX: 'auto'
  },
  cellOrder: {
    marginBottom: 1,
    padding: '2px 0px 2px 2px',
    border: 'none'
  },
  cellOrderRight: {
    width: '35%',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontStyle: 'normal',
    color: BLACK
  },
  cellOrderRightUnderline: {
    padding: '2px 0px 2px 2px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal',
    textDecorationLine: 'underline',
    cursor: 'pointer'
  },
  cellOrderLeft: {
    width: '30%'
  },
  titleOrder: {
    color: BLACK,
    marginBottom: '16px'
  },
  textTooltip: {
    color: WHITE
  },
  timeOut: {
    animation: 'shake 0.5s',
    animationIterationCount: 'infinite'
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  },
  '@keyframes shake': {
    '0%': { transform: 'translate(1px, 1px) rotate(0deg)' },
    '10%': { transform: ' translate(-1px, -2px) rotate(-1deg)' },
    '20%': { transform: ' translate(-3px, 0px) rotate(1deg)' },
    '30%': { transform: ' translate(3px, 2px) rotate(0deg)' },
    '40%': { transform: ' translate(1px, -1px) rotate(1deg)' },
    '50%': { transform: ' translate(-1px, 2px) rotate(-1deg)' },
    '60%': { transform: ' translate(-3px, 1px) rotate(0deg)' },
    '70%': { transform: ' translate(3px, 1px) rotate(-1deg)' },
    '80%': { transform: ' translate(-1px, -1px) rotate(1deg)' },
    '90%': { transform: ' translate(1px, 2px) rotate(0deg)' },
    '100%': { transform: ' translate(1px, -2px) rotate(-1deg)' }
  }
}));

const ContentInvoice: FC<Props> = props => {
  const { history } = useRouter();
  const classes = useStyles();
  const {
    invoice,
    isLoadingData,
    company,
    invoiceItem,
    invoicePayment,
    openTotalPrice,
    totalPrice,
    isFinance,
    loadingTotalPrice,
    isRequestEdit,
    item,
    indexCollapse,
    indexEdit,
    type,

    loadingEdit,
    loadingRequest,
    isAdmin,
    isSuperAdmin,
    onDeleteItem,
    onRefresh,
    handleEdit,
    isInvoice,
    onUpdate,
    handleRequest,
    individualEdit,
    handleOpenCollapse,
    handleRequestNote,
    handleRequestCheck,

    setTotalPrice,
    handleOpenUpdateTotalPrice,
    handleCancelUpdateTotalPrice,
    updateTotalPrice,
    setOpenPartner
  } = props;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Grid container direction='row' spacing={1}>
      <Hidden smDown xsDown>
        <Grid item lg={8} sm={8} md={8} xs={8}>
          <img src={CCATMP} alt='company' width='236px' height='236' />
        </Grid>
      </Hidden>

      <Grid container item lg={4} sm={12} md={4} xs={12} justify='flex-end'>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align='right' colSpan={2} className={classes.cellOrder}>
                  <Typography variant='h1' className={classes.titleOrder}>
                    &nbsp; Invoice Tagihan Return
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                  <Typography variant='subtitle2'>Invoice Number </Typography>
                </TableCell>
                <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : invoice.invoiceNumber}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                  <Typography variant='subtitle2'>Tanggal Invoice</Typography>
                </TableCell>
                <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                  {isLoadingData ? (
                    <Skeleton variant='text' width='100%' />
                  ) : invoice.createdAt ? (
                    format(new Date(invoice.createdAt), 'dd-MM-yyyy')
                  ) : (
                    '-'
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                  <Typography variant='subtitle2'>Rute</Typography>
                </TableCell>
                <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : invoice.zoneName || '-'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item lg={6} sm={12} md={6} xs={12}>
        <Typography variant='h5' align='left'>
          Nama Perusahaan
        </Typography>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align='left' style={{ fontWeight: 500, color: BLACK }} className={classes.cellInfoLeft}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : company.name || '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' className={classes.cellInfoLeft}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : company.address || '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' className={classes.cellInfoLeft}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : 'Telp: ' + company.phoneNumber || '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' className={classes.cellInfoLeft}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : 'No Hp: ' + (company.cellPhoneNumber || '-')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' className={classes.cellInfoLeft}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : 'Email: ' + (company.email || '-')}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Typography variant='h5' align='right'>
          Tagihan return dari
        </Typography>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell onClick={() => setOpenPartner(true)} align='right' className={classes.cellOrderRightUnderline}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : invoice.Partner ? invoice.Partner.name : '-'}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align='right' className={classes.cellInfoRight}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : `Telp ${(invoice.Partner && invoice.Partner.phoneNumber) || '-'}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='right' className={classes.cellInfoRight}>
                  {isLoadingData ? (
                    <Skeleton variant='text' width='100%' />
                  ) : (
                    'No Hp: ' + ((invoice.Partner && invoice.Partner.cellPhoneNumber) || '-')
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='right' className={classes.cellInfoRight}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : 'Email: ' + ((invoice.Partner && invoice.Partner.email) || '-')}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item lg={6} sm={6} md={6} xs={6}>
        <Typography variant='body2'>Total Item : {invoiceItem.length}</Typography>
      </Grid>

      <Grid container item lg={12} sm={12} md={12} xs={12}>
        <ListProduct
          isRequestEdit={isRequestEdit}
          isLoadingData={isLoadingData}
          invoiceItem={invoiceItem}
          item={item}
          indexCollapse={indexCollapse}
          indexEdit={indexEdit}
          rute={invoice.zoneName || ''}
          type={type}
          loadingEdit={loadingEdit}
          isAdmin={isAdmin}
          isSuperAdmin={isSuperAdmin}
          onDeleteItem={onDeleteItem}
          onUpdate={onUpdate}
          individualEdit={individualEdit}
          handleEdit={handleEdit}
          handleOpenCollapse={handleOpenCollapse}
          handleRequestNote={handleRequestNote}
          handleRequestCheck={handleRequestCheck}
        />
      </Grid>

      <Grid container item lg={12} sm={12} md={12} xs={12}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align='right' className={classes.cellSummaryRight}>
                  Total Harga
                </TableCell>
                <TableCell align='right' className={classes.cellSummaryLeft}>
                  <NumberFormat value={invoice.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <ModalPermission setOpen={setOpen} open={open} />
    </Grid>
  );
};

export default ContentInvoice;
