import React, { FC, useState, useEffect } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import _ from 'lodash';
import { Button, makeStyles, Grid, Dialog, DialogContent, Theme, Typography, Tooltip, IconButton, FormHelperText } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import DateRangeFilter from 'components/DateRangeFilter';
import { dummyPurchaseInvoicePayment } from 'utils/dummy';
import { BLUE_PRIMARY } from 'constants/colors';
import { INVOICE_PAYMENT_BASE_URL, INVOICE_PAYMENT_GIRO_BASE_URL } from 'constants/url';
import PaymentMethod from 'typings/enum/PaymentMethod';
import PaymentStatus from 'typings/enum/PaymentStatus';
import { DialogTitle } from 'components/Dialog';
import GiroTable from './components/GiroTable';
import CalendarIcon from '@material-ui/icons/EventNote';
import GiroModal from './components/GiroModal';
import WarningIcon from '@material-ui/icons/Error';

interface Props {
  openModal: boolean;
  giroMonthDue: { total: Number; month: string }[];
  handleCloseModal: () => void;
  handleUpdatePaymentIndividual: (id: number, data: PurchaseInvoicePaymentModel) => void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    margin: '0 auto',
    padding: 12,
    overflow: 'auto'
  },
  calendarIcon: {
    fontSize: 30,
    color: theme.palette.primary.main
  },
  warningText: {
    fontSize: 13,
    color: orange[500]
  }
}));

const PaymentGiroModal: FC<Props> = props => {
  const classes = useStyles();

  const { setOpenSnackbar, openModal, giroMonthDue, handleCloseModal, handleSnackBar, handleUpdatePaymentIndividual } = props;

  const [checked, setChecked] = useState<number[]>([]);

  const [giroInvoicePayments, setGiroInvoicePayments] = useState<PurchaseInvoicePaymentModel[]>([dummyPurchaseInvoicePayment]);

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('giroDueDate');

  const [name, setName] = useState<string>('');
  const [giroBank, setGiroBank] = useState<string>('');
  const [giroNumber, setGiroNumber] = useState<string>('');
  const [giroAmount, setGiroAmount] = useState<number>(0);
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');
  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

  const [selectedGiroDirection, setSelectedGiroDirection] = useState<string>('');
  const [selectedGiroDirectionId, setSelectedGiroDirectionId] = useState<number>(0);
  const [selectedGiroDate, setSelectedGiroDate] = useState<Date>(new Date());
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(endOfMonth(new Date()), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [openGiroModal, setOpenGiroModal] = useState<boolean>(false);
  const [outRange, setOutRange] = useState<boolean>(false);

  const fetchData = async () => {
    setIsLoadingData(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (name) {
        params.append('keyword', name);
      }

      if (startDate && endDate) {
        params.append('startGiroDate', startDate);
        params.append('endGiroDate', endDate);
      }

      if (giroBank) {
        params.append('giroBank', giroBank);
      }

      if (giroNumber) {
        params.append('giroNumber', giroNumber);
      }

      if (giroAmount) {
        params.append('giroAmount', String(giroAmount));
      }

      if (invoiceNumber) {
        params.append('invoiceNumber', invoiceNumber);
      }

      if (orderBy || order) {
        params.append('orderBy', orderBy);
        params.append('ordered', order);
      }

      params.append('method', PaymentMethod.GIRO);
      params.append('status', PaymentStatus.UNPAID);
      return params.toString();
    };

    try {
      const { data } = await axios.get(`${INVOICE_PAYMENT_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });
      setGiroInvoicePayments(data.data);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleCalendarFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenCalendarFilter(!openCalendarFilter);
  };

  const handleGiroModalClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOutRange(false);
    checked.map(check => {
      const find = giroInvoicePayments.find(inv => inv.id === check);
      if (find && find.giroDueDate && (new Date(find.giroDueDate) > new Date() || new Date(find.giroDueDate) < startOfMonth(new Date()))) {
        setOutRange(true);
      }
    });

    setOpenGiroModal(!openGiroModal);
  };

  const handleOnSubmit = async () => {
    setIsSubmit(true);
    try {
      await axios.post(
        INVOICE_PAYMENT_GIRO_BASE_URL,
        {
          id: checked.join(', '),
          date: format(selectedGiroDate, 'yyyy-MM-dd'),
          giroDirection: selectedGiroDirection,
          giroDirectionId: selectedGiroDirectionId
        },
        { cancelToken: cancelTokenSource.token }
      );
      let newData = [...giroInvoicePayments];
      checked.map((id: number) => {
        const currentData = giroInvoicePayments.find(giro => giro.id === id);
        if (currentData) {
          currentData.status = PaymentStatus.PAID;
          currentData.giroDueDate = selectedGiroDate;
          handleUpdatePaymentIndividual(id, currentData);
        }

        newData = newData.filter(giro => giro.id === id);
      });

      setOpenSnackbar(true);
      setIsSubmit(false);
      setGiroInvoicePayments(newData);
      handleSnackBar(true, 'success', 'User berhasil diperbaharui');
      handleCloseModal();
    } catch (error) {
      console.log('error :', error);
      setOpenSnackbar(true);
      setIsSubmit(false);
      handleSnackBar(true, 'error', 'User gagal diperbaharui.');
    } finally {
      setChecked([]);
      setIsSubmit(false);
      setOpenGiroModal(false);
    }
  };

  useEffect(() => {
    const debouncedFetchHits = _.debounce(fetchData, 500);
    debouncedFetchHits();
    return () => cancelTokenSource.cancel('No longer latest query');
  }, [orderBy, order, name, invoiceNumber, startDate, endDate, giroBank, giroNumber, giroAmount]);

  useEffect(() => {
    if (!name && !invoiceNumber && !startDate && !endDate && !giroBank && !giroNumber && !giroAmount) {
      return;
    }

    // setCurrentPage(1);
  }, [name, invoiceNumber, startDate, endDate, giroBank, giroNumber, giroAmount]);

  useEffect(() => {
    if (!openModal) {
      return;
    }

    fetchData();
  }, [openModal]);

  const currentGiro = checked && giroInvoicePayments.find(value => checked[0] === value.id);
  const checkSelected =
    checked &&
    giroInvoicePayments
      .filter(value => checked.includes(value.id))
      .map(value => value.giroDueDate || new Date())
      .filter((v: any, i: any, a: any) => a.indexOf(v) === i);

  return (
    <Dialog open={openModal} onClose={handleCloseModal} maxWidth='md' classes={{ paper: classes.dialogContent }}>
      <DialogTitle>
        Pilih Giro untuk Dicairkan
        <span style={{ float: 'right', color: BLUE_PRIMARY }}> Hari Ini : {format(new Date(), 'dd-MM-yyyy')}</span>
      </DialogTitle>
      <DialogContent>
        <Grid container direction='row' alignItems='center' item lg={12} md={12} sm={12} xs={12} spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} container justify='flex-start' alignItems='center'>
            <Tooltip title='Calendar filter' placement='top'>
              <IconButton size='small' onClick={event => handleCalendarFilterClick(event)}>
                <CalendarIcon className={classes.calendarIcon} />
              </IconButton>
            </Tooltip>

            {startDate && endDate && (
              <Typography variant='body2' style={{ fontSize: 14, color: BLUE_PRIMARY }}>
                Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
              </Typography>
            )}
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} container justify='flex-end' alignItems='center' alignContent='center'>
            {giroMonthDue.length > 0 && (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container justify='flex-end' alignItems='center' alignContent='center'>
                <Typography variant='body2' className={classes.warningText}>
                  <WarningIcon className={classes.warningText} /> Giro belum cair dibulan berlalu :
                </Typography>
              </Grid>
            )}

            {giroMonthDue.length > 0 && (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container justify='flex-end' alignItems='center' alignContent='center'>
                {giroMonthDue.map((value, index) => (
                  <Typography key={index} variant='body2' className={classes.warningText}>
                    {value.month}({value.total}){(giroMonthDue[index + 1] && ', ') || ''}
                  </Typography>
                ))}
              </Grid>
            )}
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} container justify='flex-start' alignItems='center'>
            <FormHelperText error={checkSelected.length > 1}>
              {(checkSelected.length > 1 && 'Tanggal jatuh tempo giro harus sama') || ''}
            </FormHelperText>
          </Grid>
        </Grid>

        <Grid direction='row' container justify='space-between' spacing={1}>
          <Grid container justify='center' item xl={12} lg={12} md={12} sm={12} xs={12}>
            <GiroTable
              giroInvoicePayments={giroInvoicePayments}
              isLoadingData={isLoadingData}
              checked={checked}
              order={order}
              orderBy={orderBy}
              giroBank={giroBank}
              giroNumber={giroNumber}
              giroAmount={giroAmount}
              invoiceNumber={invoiceNumber}
              name={name}
              setChecked={setChecked}
              setName={setName}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              setGiroBank={setGiroBank}
              setGiroNumber={setGiroNumber}
              setGiroAmount={setGiroAmount}
              setInvoiceNumber={setInvoiceNumber}
            />
          </Grid>
          <DateRangeFilter
            openCalendarFilter={openCalendarFilter}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            handleClose={() => {
              setOpenCalendarFilter(false);
            }}
          />

          <GiroModal
            openGiroModal={openGiroModal}
            giroDirection={selectedGiroDirection}
            setGiroDirection={setSelectedGiroDirection}
            giroDirectionId={selectedGiroDirectionId}
            setGiroDirectionId={setSelectedGiroDirectionId}
            selectedGiroDate={selectedGiroDate}
            setSelectedGiroDate={setSelectedGiroDate}
            isSubmit={isSubmit}
            outRange={outRange}
            dueDate={currentGiro && currentGiro.giroDueDate}
            handleClose={() => setOpenGiroModal(false)}
            handleOnSubmit={handleOnSubmit}
          />

          <Grid container justify='flex-end' item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Button
              disabled={isLoadingData || isSubmit || checked.length < 1 || checkSelected.length > 1}
              onClick={event => handleGiroModalClick(event)}
            >{`Cairkan & Lunas (${checked.length})`}</Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentGiroModal;
