import React, { FC } from 'react';
import { Grid, TableContainer, Typography, TableBody, TableHead, TableRow, TableCell, Button } from '@material-ui/core';
import { HeaderRow, TableCustom } from 'components/Table';
import { PaperCustom } from 'components';
import { dummyCommissiom } from 'utils/dummy';
import { Pagination } from '@material-ui/lab';
import BodyRow from './components/BodyRow';

interface Props {
  isLoadingData: boolean;
  commissions: CommissionModel[];
  count: number;
  isAccounting: boolean;
  order: 'asc' | 'desc';
  orderBy: string;
  currentPage: number;
  handleConfirmationDelete: (id: number) => React.MouseEventHandler;
  handleAddNew: () => void;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const CommissionTable: FC<Props> = props => {
  const {
    isLoadingData,
    commissions,
    order,
    orderBy,
    count,
    currentPage,
    handleAddNew,
    isAccounting,
    setCurrentPage,
    setOrder,
    setOrderBy,
    handleConfirmationDelete
  } = props;

  const handleRequestSort = ({}, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <PaperCustom>
      <Grid container direction='row' justify='space-between' alignItems='center'>
        <Grid item>
          <Typography variant='h5'>Riwayat Komisi</Typography>
        </Grid>

        <Grid item>
          {!isAccounting && (
            <Button onClick={handleAddNew} size='small' color='primary'>
              Tambah Komisi
            </Button>
          )}
        </Grid>
      </Grid>

      <TableContainer>
        <TableCustom size='small'>
          <TableHead>
            <HeaderRow
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={[
                { id: 'month', label: 'BULAN', sort: true },
                { id: 'year', label: 'TAHUN', sort: true },
                { id: 'totalInvoice', label: 'JUMLAH INVOICE', sort: true },
                { id: 'totalBbill', label: 'TOTAL TERTAGIH', sort: true },
                { id: 'totalCommission', label: 'TOTAL KOMISI', sort: true },
                { label: '' }
              ]}
            />
          </TableHead>

          <TableBody>
            {isLoadingData ? (
              [0, 1, 2, 3].map(index => (
                <BodyRow key={index + 1} isLoading={isLoadingData} commission={dummyCommissiom} handleConfirmationDelete={handleConfirmationDelete} />
              ))
            ) : commissions && commissions.length > 0 ? (
              commissions.map((value, index) => (
                <BodyRow key={index + 1} isLoading={isLoadingData} commission={value} handleConfirmationDelete={handleConfirmationDelete} />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align='center'>
                  Data Tidak Tersedia.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </TableCustom>
      </TableContainer>
      <Grid container justify='center' item xl={12} md={12} sm={12}>
        <Grid item>
          {commissions.length > 0 && (
            <Pagination count={count} onChange={(event, page) => setCurrentPage(page)} page={currentPage} boundaryCount={2} variant='outlined' />
          )}
        </Grid>
      </Grid>
    </PaperCustom>
  );
};

export default CommissionTable;
