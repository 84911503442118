import { Dialog, DialogContent, Divider, Grid, makeStyles, Modal, Theme, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { FC } from 'react';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em',
    padding: '1em'
  },
  titleContainer: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)'
  }
}));
interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  totalQty: number;
  totalQtyReturn: number;
  typeUnit: string;
  isWareHouse: boolean;
}
const ModalReturn: FC<Props> = ({ open, setOpen, totalQty, isWareHouse, typeUnit, totalQtyReturn }) => {
  const classes = useStyles();
  return (
    <Dialog open={open}>
      <Grid xs={12} container className={classes.container}>
        <Grid xs={12} container justify='space-between' alignItems='center' className={classes.titleContainer}>
          <Typography variant='h5'>Kuantitas Return</Typography>
          <Close style={{ cursor: 'pointer' }} onClick={() => setOpen(false)} />
        </Grid>
        <Grid xs={12}>
          {(isWareHouse && totalQtyReturn >= 0) || (!isWareHouse && totalQty >= 0) ? (
            <Grid xs={12}>
              <Typography>{`${isWareHouse ? totalQtyReturn : totalQty} ${typeUnit} ${isWareHouse ? 'dari Gudang' : 'dari Penjualan'}`}</Typography>
            </Grid>
          ) : (
            <Grid xs={12}>
              <Typography>Data tidak ada</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ModalReturn;
