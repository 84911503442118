import React, { FC, useEffect, useState } from 'react';
import {
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  makeStyles,
  MenuItem,
  Grid,
  Tooltip,
  Button,
  Typography
} from '@material-ui/core';
import { HeaderRow, TableCellHead, TableCustom } from 'components/Table';
import { Refresh } from '@material-ui/icons';
import axios, { CancelTokenSource } from 'axios';
import { GET_INVOICE_PAYMENT_RETURN_BASE_URL, INVOICE_PAYMENT_BASE_URL, RETURN_INVOICE_BASE_URL } from 'constants/url';
import { GREEN, WHITE } from 'constants/colors';
import PaymentMethod from 'typings/enum/PaymentMethod';
import PaymentStatus from 'typings/enum/PaymentStatus';
import BodyRow from './BodyRow';
import { dummyInvoicePayment } from 'utils/dummy';
import { Pagination } from '@material-ui/lab';

const useStyles = makeStyles({
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  },
  cellStart: {
    width: '20%'
  },
  cellTable: {
    width: '17%'
  }
});

interface Props {
  partnerId: string | number;
}
const PaymentPage: FC<Props> = ({ partnerId }) => {
  const classes = useStyles();
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [invoicePayments, setInvoicePayments] = useState<InvoicePaymentModel[]>([]);
  const [invoicePaymentReturn, setInvoicePaymentReturn] = useState<InvoiceReturnModel[]>([]);

  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('payDate');
  const [name, setName] = useState<string>('');
  const [salesName, setSalesName] = useState<string>('');
  const [giroNumber, setGiroNumber] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [totalPay, setTotalPay] = useState<string>('');
  //   const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  //   const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [payDate, setPayDate] = useState<Date>(new Date());
  const [paymentMethod, setPaymentMethod] = useState<string>('');

  const [selectedZone, setSelectedZone] = useState<number[]>([]);

  const [total, setTotal] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [from, setFrom] = useState<number>(0);

  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(0);
  const [isLoadingCollapse, setLoadingCollapse] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  let cancelToken: CancelTokenSource = axios.CancelToken.source();

  const onRefresh = () => {
    setCount(0);
    setCurrentPage(1);
    setInvoicePayments([]);
    setInvoicePaymentReturn([]);
    setOrder('desc');
    setName('');
    setCode('');
    setTotal(0);
    setTo(0);
    setFrom(0);
    setOpenCollapse(false);
    setLoadingCollapse(false);

    fetchData();
  };

  const fetchData = async () => {
    setIsLoadingData(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();

      params.append('partnerId', partnerId.toString());

      if (orderBy || order) {
        params.append('orderBy', orderBy);
        params.append('ordered', order);
      }

      params.append('page', currentPage.toString());
      return params;
    };

    try {
      const { data } = await axios.get(`${INVOICE_PAYMENT_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelToken.token });
      setInvoicePayments(data.data);
      setCount(data.meta.last_page);
      setTotal(data.meta.total);
      setTo(data.meta.to);
      setFrom(data.meta.from);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const fetchReturn = async (id: number) => {
    setLoadingCollapse(true);
    try {
      const { data } = await axios.get(GET_INVOICE_PAYMENT_RETURN_BASE_URL(id));
      const invoiceReturn = await axios.get(`${RETURN_INVOICE_BASE_URL}?id=${data.data.map((value: any) => value.ReturnId || 0).join(',')}`);
      setInvoicePaymentReturn(invoiceReturn.data.data);
    } catch (e) {
      console.log('error', e);
    } finally {
      setLoadingCollapse(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [orderBy, order, currentPage, name, code, selectedZone, invoiceNumber, status, paymentMethod, salesName, giroNumber]);

  const handleOpenCollapse = (index: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    setOpenCollapse(prevState => (prevState ? (index === indexCollapse ? false : true) : true));
    fetchReturn(index);
  };
  return (
    <Grid xs={12}>
      <Grid container direction='row' alignItems='center' item lg={12} md={12} sm={12} xs={12} spacing={2}>
        <Grid item xl={1} lg={1} md={1} sm={6} xs={6}>
          <Tooltip title='Memuat Ulang'>
            <Button onClick={onRefresh} color='inherit' className={classes.refresh}>
              <Refresh />
            </Button>
          </Tooltip>
        </Grid>

        <Grid item xl={5} lg={5} md={5} sm={6} xs={6}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography>{`Menampilkan ${total || 0} pembayaran (${from || 0} - ${to || 0} dari ${total || 0})`}</Typography>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {/* {startDate && endDate && (
              <Typography variant='body2' style={{ fontSize: 12, color: BLUE_PRIMARY }}>
                Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
              </Typography>
            )} */}
          </Grid>
        </Grid>
      </Grid>
      <Grid container justify='center' item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TableContainer>
          <TableCustom>
            <TableHead>
              <HeaderRow
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headers={[
                  { id: 'paymentNumber', label: 'No Kwitansi', sort: true },
                  { id: 'payDate', label: 'Tgl Kwitansi & Pembayaran', sort: true },
                  { id: 'paymentMethod', label: 'Metode Pembayaran', sort: true },
                  { label: 'Status' }
                ]}
              />
              <TableRow>
                <TableCellHead variant='head' className={classes.cellStart}>
                  <TextField id='paymentNumber' placeholder='No Kwitansi' value={code} onChange={event => setCode(event.target.value)} />
                </TableCellHead>
                <TableCellHead variant='head' className={classes.cellTable} />

                <TableCellHead variant='head' className={classes.cellTable}>
                  <TextField
                    select
                    placeholder='Metode Pembayaran'
                    id='paymentMethod'
                    value={paymentMethod}
                    onChange={event => setPaymentMethod(event.target.value)}
                    style={{ width: '100%' }}
                  >
                    <MenuItem key={0} value=''>
                      Semua Metode Pembayaran
                    </MenuItem>
                    <MenuItem key={1} value={PaymentMethod.BANK_TRANSFER}>
                      Bank
                    </MenuItem>
                    <MenuItem key={2} value={PaymentMethod.CASH}>
                      Cash
                    </MenuItem>
                    <MenuItem key={3} value={PaymentMethod.GIRO}>
                      Giro
                    </MenuItem>
                  </TextField>
                </TableCellHead>

                <TableCellHead variant='head' className={classes.cellTable}>
                  <TextField
                    select
                    placeholder='Status'
                    id='status'
                    value={status}
                    onChange={event => setStatus(event.target.value)}
                    style={{ width: '100%' }}
                  >
                    <MenuItem key={0} value=''>
                      Status Pembayaran
                    </MenuItem>
                    <MenuItem key={1} value={PaymentStatus.PAID}>
                      Diterima / Sudah Cair
                    </MenuItem>
                    <MenuItem key={2} value={PaymentStatus.UNPAID}>
                      Belum Cair
                    </MenuItem>
                  </TextField>
                </TableCellHead>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoadingData ? (
                [0, 1, 2, 3].map(index => (
                  <BodyRow
                    key={index}
                    isLoading={isLoadingData}
                    invoicePayment={dummyInvoicePayment}
                    invoicePaymentReturn={invoicePaymentReturn}
                    isLoadingCollapse={isLoadingCollapse}
                    indexCollapse={indexCollapse}
                    openCollapse={openCollapse}
                    handleOpenCollapse={handleOpenCollapse(0)}
                  />
                ))
              ) : invoicePayments && invoicePayments.length > 0 ? (
                invoicePayments.map((value, index) => (
                  <BodyRow
                    key={index}
                    isLoading={isLoadingData}
                    invoicePayment={value}
                    invoicePaymentReturn={invoicePaymentReturn}
                    isLoadingCollapse={isLoadingCollapse}
                    indexCollapse={indexCollapse}
                    openCollapse={openCollapse}
                    handleOpenCollapse={handleOpenCollapse(value.id)}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align='center'>
                    {'Data Tidak Tersedia.'}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </TableCustom>
        </TableContainer>
      </Grid>
      <Grid container justify='center' item xl={12} md={12} sm={12}>
        {invoicePayments && invoicePayments.length > 0 && (
          <Pagination count={count} onChange={(event, page) => setCurrentPage(page)} page={currentPage} boundaryCount={2} variant='outlined' />
        )}
      </Grid>
    </Grid>
  );
};

export default PaymentPage;
