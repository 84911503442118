import React, { FC } from 'react';
import { TableRow } from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart } from 'components/Table';
import Skeleton from '@material-ui/lab/Skeleton';
import NumberFormat from 'react-number-format';

interface Props {
  stockItem: StockItemModel;
  isLoading: boolean;
}

const BodyRow: FC<Props> = props => {
  const { stockItem, isLoading } = props;

  return (
    <TableRow>
      <TableCellStart>
        {isLoading ? <Skeleton variant='text' width={100} height={25} /> : stockItem.Product ? stockItem.Product.productName : ''}
      </TableCellStart>

      <TableCellMiddle align='center'>
        {isLoading ? (
          <Skeleton variant='text' width={100} height={25} />
        ) : (
          stockItem.Product && stockItem.Product.Category && stockItem.Product.Category.name
        )}
      </TableCellMiddle>

      <TableCellMiddle align='right'>
        {isLoading ? (
          <Skeleton variant='text' width={100} height={25} />
        ) : (
          <NumberFormat value={stockItem.purchasePrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        )}
      </TableCellMiddle>

      <TableCellMiddle align='center'>
        {isLoading ? <Skeleton variant='text' width={100} height={25} /> : stockItem.totalItem + ' ' + stockItem.typeUnit}
      </TableCellMiddle>

      <TableCellEnd align='right'>
        {isLoading ? (
          <Skeleton variant='text' width={100} height={25} />
        ) : (
          <NumberFormat value={stockItem.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        )}
      </TableCellEnd>
    </TableRow>
  );
};

export default BodyRow;
