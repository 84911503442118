import { Box, CircularProgress, Grid, InputAdornment, ListItem, makeStyles, TextField, Typography } from '@material-ui/core';
import { CloseRounded, SearchRounded, ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import FlexBox from 'components/FlexBox';
import { GRAY_3, GREY, WHITE } from 'constants/colors';
import React, { useState } from 'react';
import { dummyFilters, TFilters } from 'pages/PartnerDetailPage/components/SalesHistoryPage';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';

const useStyles = makeStyles({
  productWrapper: {
    position: 'relative'
  },
  containerBox: {
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    borderRadius: '5px',
    marginTop: '0.5em'
  },
  absolute: {
    position: 'absolute',
    top: 1200,
    width: '38vw',
    zIndex: 3
  },
  scrollY: {
    maxHeight: '50vh',
    overflowY: 'scroll'
  },
  searchIcon: {
    padding: '.1em',
    fontSize: '1.7rem',
    color: '#323232',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: GREY,
      borderRadius: '50%'
    }
  },
  colorGrey: {
    color: GRAY_3
  },
  wrapperInput: {
    backgroundColor: WHITE
  }
});
/* Defining the props that the component will receive. */
interface Props {
  filters: TFilters;
  productSuggests: { productName: string; id: number; wareHouse: string }[];
  selectedStartDate: Date;
  selectedEndDate: Date;
  loadProduct: boolean;
  isShowSuggest: boolean;
  setIsShowSuggest: React.Dispatch<React.SetStateAction<boolean>>;
  handleChangeFilters: <T>(key: keyof typeof dummyFilters, value: T) => void;
  handleClickSuggest: (productName: string, id: number) => void;
  handleClearKeyWord: () => void;
  handleSearchPress: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  setSelectedEndDate: React.Dispatch<React.SetStateAction<Date>>;
  setSelectedStartDate: React.Dispatch<React.SetStateAction<Date>>;
}

/* A function that returns a component. */
const PriceHistoryFilters = ({
  filters,
  productSuggests,
  loadProduct,
  setIsShowSuggest,
  handleChangeFilters,
  handleClickSuggest,
  handleClearKeyWord,
  handleSearchPress
}: Props) => {
  const classes = useStyles();
  /* A state that is used to show or hide the suggest list. */

  return (
    /* */

    <Grid container direction='row' spacing={1} justify='flex-start'>
      <Grid item lg={6} sm={12} md={6} xs={12}>
        <TextField
          placeholder='Cari Barang'
          id='keyWord'
          value={filters.keyWord}
          onFocus={() => setIsShowSuggest(true)}
          onBlur={() =>
            setTimeout(() => {
              setIsShowSuggest(false);
            }, 500)
          }
          autoComplete='off'
          fullWidth
          onKeyDown={handleSearchPress}
          onChange={event => handleChangeFilters('keyWord', event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchRounded className={classes.colorGrey} fontSize='small' />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                {loadProduct ? (
                  <CircularProgress size={20} />
                ) : (
                  filters.keyWord.length > 0 && <CloseRounded className={classes.searchIcon} fontSize='small' onClick={handleClearKeyWord} />
                )}
              </InputAdornment>
            )
          }}
        />

        {productSuggests.length > 0 && !loadProduct && (
          <Grid className={clsx(classes.containerBox, classes.absolute, classes.scrollY)} xs={12}>
            {productSuggests.map(productSugest => (
              <ListItem button key={productSugest.id} onClick={() => handleClickSuggest(productSugest.productName, productSugest.id)}>
                <FlexBox container columnGap={1} alignItems='center'>
                  <SearchRounded fontSize='default' />
                  <Typography>
                    {productSugest.productName} ({productSugest.wareHouse})
                  </Typography>
                </FlexBox>
              </ListItem>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

/* Exporting the component. */
export default PriceHistoryFilters;
