import React, { FC, Fragment, useContext } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Tooltip,
  IconButton,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Button,
  Chip,
  Grid,
  LinearProgress,
  CardMedia
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { RED, BLACK, GREEN, BLUE_PRIMARY, GREEN_MOBILE, YELLOW_MOBILE } from 'constants/colors';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom, StatusOrder, TableRowCollapse } from 'components';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { DeleteOutline } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ItemStatus from 'typings/enum/ItemStatus';
import useRouter from 'hooks/useRouter';
import TypeUser from 'typings/enum/TypeUser';
import HeaderRow from 'pages/SalesOrderDetailPage/components/content/components/ListProduct/components/HeaderRow';
import BodyItem from 'pages/SalesOrderDetailPage/components/content/components/ListProduct/components/BodyItem';

interface Props {
  salesOrder: SalesOrderModel;
  isLoading: boolean;
  handleOpenSO: (id: number) => void;
  onDelete: React.MouseEventHandler;
  openCollapse: boolean;
  openModalDiscount: (productName: string, totalQty: number, discount: number, totalDiscount: number) => void;
  handleOpenCollapse: React.MouseEventHandler;
  index: number;
  handleOpenImage: (imagePath: string) => void;
  indexCollapse: number;
  loadingItem: boolean;
  isAccess: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      backgroundColor: '#FFFAEF',

      '&  .MuiTableCell-root': {
        borderTop: '1px solid #E4AE42',
        borderBottom: '1px solid #E4AE42'
      }
    },
    deleteButton: {
      color: RED,
      width: 30
    },
    tableCell: {
      width: 100
    },
    tableCellAction: {
      width: 30
    },
    tableCollapse: {
      width: '100%'
    },
    cellCollapse: {
      border: 'none'
    },
    customerName: {
      fontSize: '15px',
      fontWeight: 500,
      fontFamily: 'Rubik',
      fontStyle: 'normal',
      lineHeight: '22px'
    },
    pending: {
      color: BLACK
    },
    reject: {
      color: RED
    },
    confirm: {
      color: BLUE_PRIMARY
    },
    success: {
      color: GREEN
    },
    containerHeader: {
      margin: theme.spacing(1),
      display: 'flex'
    },
    bodyContainer: {
      borderRadius: '5px',
      marginBottom: theme.spacing(2),

      padding: theme.spacing(1)
    }
  })
);

const BodyRow: FC<Props> = props => {
  const {
    salesOrder,
    isLoading,
    handleOpenImage,
    onDelete,
    handleOpenSO,
    openCollapse,
    indexCollapse,
    handleOpenCollapse,
    index,
    loadingItem,
    isAccess,
    openModalDiscount
  } = props;
  const classes = useStyles();

  const { history } = useRouter();

  const handleLink = (id: number) => {
    if (isAccess === TypeUser.SUPERADMIN || isAccess === TypeUser.ADMIN) {
      window.open(`/penjualan/${id}`);
    } else {
      handleOpenSO(salesOrder.id);
      history.push(`/penjualan/${id}`);
    }
  };

  const handleLinkInvoice = (id: number) => {
    window.open(`/invoice/${id}`, '_blank');
  };

  const handleStatus = (item: SalesOrderItemModel) => {
    if (item.status === ItemStatus.CONFIRM) {
      return (
        <Typography variant='body2' className={classes.confirm}>
          Disetujui
        </Typography>
      );
    } else if (item.status === ItemStatus.REJECT) {
      return (
        <Typography variant='body2' className={classes.reject}>
          Ditolak
        </Typography>
      );
    } else if (item.status === ItemStatus.HAS_INVOICE) {
      return (
        <Typography variant='body2' className={classes.success}>
          Invoice Telah <br /> Dibuat
        </Typography>
      );
    } else {
      return (
        <Typography variant='body2' className={classes.pending}>
          Menunggu
        </Typography>
      );
    }
  };

  return (
    <Fragment>
      <TableRowCustom
        className={isAccess === TypeUser.SUPERADMIN || isAccess === TypeUser.ADMIN ? (!salesOrder.isAccept ? classes.tableRow : '') : ''}
      >
        <TableCellStart
          style={{
            borderLeft:
              isAccess === TypeUser.SUPERADMIN || isAccess === TypeUser.ADMIN
                ? salesOrder.isAccept
                  ? '1px solid lightgrey'
                  : '1px solid  #E4AE42'
                : '1px solid lightgrey'
          }}
        >
          {isLoading ? (
            <Skeleton variant='text' width={60} height={25} />
          ) : (
            <IconButton aria-label='expand row' size='small' onClick={handleOpenCollapse}>
              {openCollapse && index === indexCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCellStart>

        <TableCellMiddle onClick={() => handleLink(salesOrder.id)}>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <Typography variant='inherit' component='p' className={classes.customerName}>
              {'#' + salesOrder.orderNumber}
            </Typography>
          )}
        </TableCellMiddle>

        <TableCellMiddle onClick={() => handleLink(salesOrder.id)}>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <Typography variant='inherit' component='p' className={classes.customerName}>
              {salesOrder.partnerName || '-'}
            </Typography>
          )}
        </TableCellMiddle>

        <TableCellMiddle onClick={() => handleLink(salesOrder.id)} align='center'>
          {isLoading ? <Skeleton variant='text' width={100} height={25} /> : <StatusOrder status={salesOrder.statusOrder} />}
        </TableCellMiddle>
        {(isAccess === TypeUser.SUPERADMIN || isAccess === TypeUser.SUPERVISOR || isAccess === TypeUser.ADMIN) && (
          <React.Fragment>
            <TableCellMiddle onClick={() => handleLink(salesOrder.id)} className={classes.tableCell} align='center'>
              {isLoading ? <Skeleton variant='text' width={100} height={25} /> : salesOrder.zoneName || '-'}
            </TableCellMiddle>

            <TableCellMiddle onClick={() => handleLink(salesOrder.id)} className={classes.tableCell} align='center'>
              {isLoading ? <Skeleton variant='text' width={100} height={25} /> : salesOrder.salesName || '-'}
            </TableCellMiddle>
          </React.Fragment>
        )}

        {(isAccess === TypeUser.SALES || isAccess === TypeUser.ACCOUNTING) && (
          <React.Fragment>
            <TableCellMiddle onClick={() => handleLink(salesOrder.id)} className={classes.tableCell} align='center'>
              {isLoading ? <Skeleton variant='text' width={100} height={25} /> : salesOrder.zoneName || '-'}
            </TableCellMiddle>

            <TableCellMiddle onClick={() => handleLink(salesOrder.id)} className={classes.tableCell} align='center'>
              {isLoading ? <Skeleton variant='text' width={100} height={25} /> : salesOrder.salesName || '-'}
            </TableCellMiddle>
          </React.Fragment>
        )}

        <TableCellMiddle onClick={() => handleLink(salesOrder.id)} className={classes.tableCell}>
          {isLoading ? <Skeleton variant='text' width={100} height={25} /> : format(new Date(salesOrder.orderDate), 'dd MMM yyyy')}
        </TableCellMiddle>

        {(isAccess === TypeUser.SUPERADMIN || isAccess === TypeUser.SUPERVISOR) && (
          <TableCellEnd
            onClick={() => handleLink(salesOrder.id)}
            className={classes.tableCell}
            style={{
              borderRight:
                isAccess === TypeUser.SUPERADMIN ? (salesOrder.isAccept ? '1px solid lightgrey' : '1px solid #E4AE42') : '1px solid lightgrey',
              minWidth: '180px'
            }}
          >
            {isLoading ? (
              <Skeleton variant='text' width={100} height={25} />
            ) : (
              <>
                <NumberFormat value={salesOrder.totalPrice || 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />

                <br />
                {salesOrder.statusOrder == 'PENDING' ? (
                  <span style={{ color: '#E4AE42' }}>
                    <NumberFormat value={salesOrder.totalSisa} prefix={'Sisa: Rp'} thousandSeparator={true} displayType='text' />
                  </span>
                ) : (
                  ''
                )}
              </>
            )}
          </TableCellEnd>
        )}

        {(isAccess === TypeUser.SALES || isAccess === TypeUser.ADMIN || isAccess === TypeUser.ACCOUNTING) && (
          <TableCellMiddle onClick={() => handleLink(salesOrder.id)} className={classes.tableCell}>
            {isLoading ? (
              <Skeleton variant='text' width={100} height={25} />
            ) : (
              <>
                <NumberFormat value={salesOrder.totalPrice || 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />

                <br />
                <span style={{ color: '#E4AE42' }}>
                  {salesOrder.statusOrder == 'PENDING' ? (
                    <NumberFormat value={salesOrder.totalSisa} prefix={'Sisa: Rp'} thousandSeparator={true} displayType='text' />
                  ) : (
                    ''
                  )}
                </span>
              </>
            )}
          </TableCellMiddle>
        )}

        {(isAccess === TypeUser.PICKER || isAccess === TypeUser.ADMIN01 || isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS) && (
          <TableCellEnd onClick={() => handleLink(salesOrder.id)} className={classes.tableCell}>
            {isLoading ? (
              <Skeleton variant='text' width={100} height={25} />
            ) : (
              <Grid>
                <Typography>{salesOrder.totalItem} Unit</Typography>
                {/* <Typography style={{ color: GREEN_MOBILE, fontStyle: '0.8rem' }}>Tersedia: 130 Unit</Typography> */}
              </Grid>
            )}
          </TableCellEnd>
        )}

        {(isAccess === TypeUser.SALES || isAccess === TypeUser.ADMIN || isAccess === TypeUser.ACCOUNTING) && (
          <TableCellEnd
            align='center'
            className={classes.tableCellAction}
            style={{
              borderRight: isAccess === TypeUser.ADMIN ? (salesOrder.isAccept ? '1px solid lightgrey' : '1px solid #E4AE42') : '1px solid lightgrey'
            }}
          >
            {isLoading ? (
              <Skeleton variant='text' width={100} height={25} />
            ) : (
              <Fragment>
                {isAccess !== TypeUser.ACCOUNTING && (
                  <Tooltip title='Hapus '>
                    <IconButton size='small' onClick={onDelete}>
                      <DeleteOutline fontSize='small' color='error' />
                    </IconButton>
                  </Tooltip>
                )}
              </Fragment>
            )}
          </TableCellEnd>
        )}
      </TableRowCustom>

      {isAccess !== TypeUser.SALES && (
        <TableRowCollapse open={openCollapse && index === indexCollapse && !isLoading} colSpan={9}>
          {isAccess !== TypeUser.ADMIN02PLUS && isAccess !== TypeUser.ADMIN02 && isAccess !== TypeUser.PICKER && isAccess !== TypeUser.ADMIN01 && (
            <Table className={classes.tableCollapse} size='small'>
              <TableBody>
                {salesOrder.invoice &&
                  salesOrder.invoice.map((value, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        Nomor Invoice {index + 1}: &nbsp; {value.statusPayment === 'CANCEL' && <Chip label='Dibatalkan' disabled />}
                      </TableCell>
                      <TableCell colSpan={8} align='right'>
                        <Button onClick={() => handleLinkInvoice(value.id)} variant='text'>
                          {value.invoiceNumber || '-'}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
          {(isAccess === TypeUser.ADMIN || isAccess === TypeUser.ACCOUNTING) && (
            <Table className={classes.tableCollapse} size='small'>
              <TableHead>
                <TableRow>
                  <TableCell> Nama </TableCell>
                  <TableCell>Kuantitas</TableCell>
                  <TableCell align='center'>Gudang</TableCell>
                  <TableCell align='right'> Total Harga </TableCell>
                  <TableCell align='center'> Status</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loadingItem ? (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Skeleton variant='text' width='100%' height={25} />
                    </TableCell>
                  </TableRow>
                ) : (
                  salesOrder.SalesOrderItem &&
                  salesOrder.SalesOrderItem.map((value: SalesOrderItemModel, index) => (
                    <TableRow key={index}>
                      <TableCell className={classes.cellCollapse}>{value.productName || '-'}</TableCell>
                      <TableCell align='center' className={classes.cellCollapse}>
                        {value.totalItem + ' ' + value.typeUnit}
                      </TableCell>
                      <TableCell align='center' className={classes.cellCollapse}>
                        {value.warehouse || '-'}
                      </TableCell>

                      <TableCell align='right' className={classes.cellCollapse}>
                        <NumberFormat value={value.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                      </TableCell>

                      <TableCell align='center' className={classes.cellCollapse}>
                        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : handleStatus(value)}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          )}

          {isAccess === TypeUser.SALES && (
            <TableRowCollapse open={openCollapse && index === indexCollapse && !isLoading} colSpan={9}>
              <Table className={classes.tableCollapse} size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell> Nama </TableCell>
                    <TableCell>Kuantitas</TableCell>
                    <TableCell align='center'>Gudang</TableCell>
                    <TableCell align='right'> Total Harga </TableCell>
                    <TableCell align='center'> Status</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {loadingItem ? (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Skeleton variant='text' width='100%' height={25} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    salesOrder.SalesOrderItem &&
                    salesOrder.SalesOrderItem.map((value: SalesOrderItemModel, index) => (
                      <TableRow key={index}>
                        <TableCell className={classes.cellCollapse}>{value.productName || '-'}</TableCell>
                        <TableCell align='center' className={classes.cellCollapse}>
                          {value.totalItem + ' ' + value.typeUnit}
                        </TableCell>
                        <TableCell align='center' className={classes.cellCollapse}>
                          {value.warehouse || '-'}
                        </TableCell>

                        <TableCell align='right' className={classes.cellCollapse}>
                          <NumberFormat value={value.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                        </TableCell>

                        <TableCell align='center' className={classes.cellCollapse}>
                          {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : handleStatus(value)}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableRowCollapse>
          )}

          <Grid xl={12} lg={12} container className={classes.containerHeader}>
            {(isAccess === TypeUser.SUPERADMIN || isAccess === TypeUser.SUPERVISOR) && (
              <React.Fragment>
                <HeaderRow title='nama produk' isFlex='0.33' />
                <HeaderRow title='harga' isFlex='0.1' />
                <HeaderRow title='qty diambil' isFlex='0.15' />
                <HeaderRow title='diskon' isFlex='0.14' />
                <HeaderRow title='total harga' isFlex='0.16' />
                <HeaderRow title='gudang' isVisibility={true} isFlex='0.12' />
                <HeaderRow title='Status' isVisibility={true} isFlex='0.12' />
              </React.Fragment>
            )}

            {isAccess === TypeUser.PICKER && (
              <React.Fragment>
                <HeaderRow title='nama produk' isFlex='0.46' />
                <HeaderRow title='Qty request' isFlex='0.4' />
                <HeaderRow title='Gudang' isFlex='0.05' isVisibility={true} />
              </React.Fragment>
            )}

            {isAccess === TypeUser.ADMIN01 && (
              <React.Fragment>
                <HeaderRow title='nama produk' isFlex='0.46' />
                <HeaderRow title='Qty request' isFlex='0.4' />
                <HeaderRow title='Gudang' isFlex='0.05' isVisibility={true} />
              </React.Fragment>
            )}

            {(isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS) && (
              <React.Fragment>
                <HeaderRow title='nama produk' isFlex='0.46' />
                <HeaderRow title='Qty request' isFlex='0.4' />
                <HeaderRow title='Gudang' isFlex='0.05' isVisibility={true} />
              </React.Fragment>
            )}
          </Grid>

          {loadingItem && (
            <Grid xs={12}>
              <Skeleton variant='text' width={'100%'} height={25} />
            </Grid>
          )}

          {isAccess !== TypeUser.ADMIN &&
            isAccess !== TypeUser.ACCOUNTING &&
            salesOrder.SalesOrderItem &&
            salesOrder.SalesOrderItem.map((value: SalesOrderItemModel, index: number) => {
              return (
                <Grid
                  xs={12}
                  xl={12}
                  container
                  className={classes.bodyContainer}
                  style={{
                    borderBottom: salesOrder.SalesOrderItem && index === salesOrder.SalesOrderItem.length - 1 ? 'none' : '1px solid #E0E0E0'
                  }}
                >
                  {(isAccess === TypeUser.SUPERADMIN || isAccess === TypeUser.SUPERVISOR) && (
                    <Fragment>
                      <BodyItem imageUrl={value.productImage} title={value.productName} isWidth='0.33' handleOpenImage={handleOpenImage} />

                      <BodyItem title={`Rp${new Intl.NumberFormat('id-ID').format(value.price)}`} isWidth='0.16' />
                      <BodyItem title={`${value.totalItem} ${value.typeUnit}`} isWidth='0.1' />
                      <BodyItem
                        title={value.discount > 0 ? `Rp${new Intl.NumberFormat('id-ID').format(value.discount)}` : '-'}
                        isWidth='0.14'
                        isDiscount={true}
                      />
                      <BodyItem title={`Rp${new Intl.NumberFormat('id-ID').format(value.totalPrice)}`} isWidth='0.16' />
                      <BodyItem title={value.warehouse} isWidth='0.12' />
                      <BodyItem status={true} isWidth='0.12' hasStatus={value.status} isAccess={TypeUser.SUPERADMIN} />
                    </Fragment>
                  )}

                  {isAccess === TypeUser.PICKER && (
                    <Fragment>
                      <BodyItem imageUrl={value.productImage} title={value.productName || '-'} isWidth='0.45' handleOpenImage={handleOpenImage} />

                      <BodyItem title={`${value.totalItem} ${value.typeUnit}`} isWidth='0.4' />
                      <BodyItem title={value.warehouse || '-'} isWidth='0.05' />
                    </Fragment>
                  )}

                  {isAccess === TypeUser.ADMIN01 && (
                    <Fragment>
                      <BodyItem imageUrl={value.productImage} title={value.productName || '-'} isWidth='0.45' handleOpenImage={handleOpenImage} />

                      <BodyItem title={`${value.totalItem} ${value.typeUnit}`} isWidth='0.4' />
                      <BodyItem title={value.warehouse || '-'} isWidth='0.05' />
                    </Fragment>
                  )}

                  {(isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS) && (
                    <Fragment>
                      <BodyItem imageUrl={value.productImage} title={value.productName || '-'} isWidth='0.45' handleOpenImage={handleOpenImage} />

                      <BodyItem title={`${value.totalItem} ${value.typeUnit}`} isWidth='0.4' />
                      <BodyItem title={value.warehouse || '-'} isWidth='0.05' />
                    </Fragment>
                  )}
                </Grid>
              );
            })}
        </TableRowCollapse>
      )}
    </Fragment>
  );
};

export default BodyRow;
