import React from 'react';
import { Theme, Grid, Typography, makeStyles, Divider, Hidden } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { Skeleton } from '@material-ui/lab';
import { WHITE } from 'constants/colors';
const useStyles = makeStyles((theme: Theme) => ({
  boxTitle: {
    marginBottom: 8
  },
  box: {
    backgroundColor: WHITE,
    borderRadius: '5px',
    padding: '10px 20px',
    border: '1px solid #F2F2F2',
    boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.08)',
    marginTop: 2,
    overflowX: 'auto'
  }
}));
interface Props {
  invoiceTotal: number;
  tagihanTotal: number;
  rute: string;
  status: string;
  loading: boolean;
}
const CardTotal: React.FC<Props> = ({ invoiceTotal, tagihanTotal, rute, status, loading }) => {
  const classes = useStyles();
  return (
    <Grid item container justify='space-between' className={classes.box}>
      <Grid xs={12} className={classes.boxTitle}>
        <Typography variant='h6'>Data yang di tampilkan hanya perhalaman</Typography>
      </Grid>
      <Grid xs={12} className={classes.boxTitle}>
        <Divider />
      </Grid>
      <Grid item lg={3} md={12} sm={12} xs={12} justify='center'>
        <Typography variant='h6' className={classes.boxTitle}>
          Jumlah Invoice
        </Typography>
        {loading ? <Skeleton variant='text' width='70%' /> : <Typography variant='body2'> {invoiceTotal} </Typography>}
      </Grid>
      <Hidden smUp smDown>
        <Divider orientation='vertical' />
      </Hidden>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <Typography variant='h6' className={classes.boxTitle}>
          Total Tagihan Invoice
        </Typography>
        {loading ? (
          <Skeleton variant='text' width='70%' />
        ) : (
          <Typography variant='body2'> {<NumberFormat value={tagihanTotal} prefix={'Rp'} thousandSeparator={true} displayType='text' />} </Typography>
        )}
      </Grid>
      <Hidden smUp smDown>
        <Divider orientation='vertical' />
      </Hidden>
      <Grid item lg={2} md={6} sm={6} xs={6}>
        <Typography variant='h6' className={classes.boxTitle}>
          Rute
        </Typography>
        {loading ? <Skeleton variant='text' width='70%' /> : <Typography variant='body2'> {rute} </Typography>}
      </Grid>
      <Hidden smUp smDown>
        <Divider orientation='vertical' />
      </Hidden>
      <Grid item lg={3} md={6} sm={6} xs={6}>
        <Typography align='right' variant='h6' className={classes.boxTitle}>
          Status Invoice
        </Typography>
        {loading ? (
          <Skeleton variant='text' width='70%' />
        ) : (
          <Typography align='right' variant='body2'>
            {status}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default CardTotal;
