import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { Button, makeStyles, Grid, Dialog, DialogActions, DialogContent, TextField, Typography, MenuItem, CircularProgress } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import WarningIcon from '@material-ui/icons/Error';
import { DialogTitle } from 'components/Dialog';
import { BLUE_PRIMARY, GREEN, WHITE } from 'constants/colors';
import { BANK_BASE_URL } from 'constants/url';
import { dummyBank } from 'utils/dummy';
import { format } from 'date-fns';
import ActionSnackBar from 'components/ActionSnackBar';

interface Props {
  isSubmit: boolean;
  outRange: boolean;
  openGiroModal: boolean;
  selectedGiroDate: Date;
  dueDate?: Date;
  giroDirection: string;
  giroDirectionId: number;
  handleClose: () => void;
  handleOnSubmit: () => void;
  setSelectedGiroDate: React.Dispatch<React.SetStateAction<Date>>;
  setGiroDirection: React.Dispatch<React.SetStateAction<string>>;
  setGiroDirectionId: React.Dispatch<React.SetStateAction<number>>;
}

const useStyles = makeStyles({
  dialogContent: {
    width: 420,
    padding: 12,
    margin: '0 auto',
    overflow: 'auto'
  },
  warningText: {
    fontSize: 13,
    color: orange[500]
  },
  warningIcon: {
    color: orange[500]
  },
  submit: {
    color: WHITE,
    backgroundColor: GREEN
  }
});

const GiroModal: FC<Props> = props => {
  const classes = useStyles();
  const {
    isSubmit,
    outRange,
    dueDate,
    handleOnSubmit,
    openGiroModal,
    selectedGiroDate,
    setSelectedGiroDate,
    giroDirection,
    setGiroDirection,
    giroDirectionId,
    setGiroDirectionId,
    handleClose
  } = props;

  const [banks, setBanks] = useState<BankModel[]>([dummyBank]);
  const [directionError, setDirectionError] = useState<string>('');
  const [loadingBank, setLoadingBank] = useState<boolean>(false);
  const [messageSnackBar, setMessageSnackBar] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [isDateError, setIsDateError] = useState<boolean>(false);

  const fetchBanks = async () => {
    setLoadingBank(true);

    try {
      const { data } = await axios.get(BANK_BASE_URL);
      setBanks(data.data);
    } catch (error) {
      console.log('error', error);
      handleSnackBar('Load data bank telah gagal');
    } finally {
      setLoadingBank(false);
    }
  };

  const handleSnackBar = (message: string): void => {
    setOpenSnackbar(true);
    setMessageSnackBar(message);
  };

  const handleDoSubmit = (): void => {
    if (!giroDirection) {
      setDirectionError('Pilih tujuan pencarian');
      return
    }

    handleOnSubmit();
  }

  const handleChangeDirection = (id: number): void => {
    setDirectionError('');
    if (id) {
      setGiroDirectionId(id);

      const findBank = banks.find(bank => bank.id === id);
      const value = id > 0 && findBank ? findBank.bankName : 'CASH';
      setGiroDirection(value);
    } else {
      setDirectionError('Pilih tujuan pencarian');
      return;
    }
  }

  useEffect(() => {
    if (!openGiroModal) {
      return;
    }

    setDirectionError('');
    fetchBanks();
    setSelectedGiroDate(new Date());
    setGiroDirection('CASH');
    setGiroDirectionId(0);
    setIsDateError(false);
  }, [openGiroModal]);

  useEffect(() => {
    if(!selectedGiroDate || !dueDate) return;
    
    const formated = format(selectedGiroDate, 'yyyy-MM-dd');
    
    setIsDateError(false);
    if (new Date(formated) < new Date(dueDate)) {
      setIsDateError(true);
    }
  },[selectedGiroDate, dueDate])

  return (
    <Dialog open={openGiroModal} onClose={handleClose} maxWidth='sm' classes={{ paper: classes.dialogContent }}>
      <DialogTitle>
        <Typography variant='h5'>Pencairan dan Pelunasan Giro</Typography>
        <Typography variant='body2' style={{ fontSize: 12, color: BLUE_PRIMARY }}>
          Pastikan tujuan dan tanggal pencairan sudah benar
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid direction='row' container justify='space-between' spacing={1}>
          <TextField
            style={{ width: '100%', marginBottom: 20 }}
            label='Pilih Tujuan Pencairan'
            id='directionSelect'
            select
            value={giroDirectionId}
            error={directionError !== ''}
            helperText={directionError}
            onChange={(e: any) => handleChangeDirection(e.target.value)}
          >
            <MenuItem value='0'>Cash</MenuItem>
            {banks.map(bank => (
              <MenuItem value={bank.id}>{bank.bankName}</MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid direction='row' container justify='space-between' spacing={1}>
          <Typography>Pilih Tanggal Pencairan</Typography>
          <Grid item lg={12} md={12} sm={12}>
            <TextField
              type='date'
              id='payDate'
              style={{ width: 350 }}
              value={format(selectedGiroDate, 'yyyy-MM-dd')}
              onChange={event => setSelectedGiroDate(new Date(event.target.value))}
              InputLabelProps={{
                shrink: true
              }}
              error={isDateError}
              helperText={isDateError && 'Tanggal Pencairan Giro kurang dari Jatuh Tempo'}
            />
          </Grid>

          {outRange && (
            <Grid item lg={12} md={12} sm={12} container justify='flex-start' alignItems='center'>
              <WarningIcon className={classes.warningText} />
              <Typography variant='body2' className={classes.warningText}>
                Tanggal Pencarian Giro berada diluar bulan berjalan
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' disabled={isSubmit} onClick={handleClose}>
          Keluar
        </Button>
        <Button disabled={isSubmit || loadingBank || isDateError} onClick={handleDoSubmit} className={classes.submit}>
          {isSubmit ? <CircularProgress color='inherit' size={20} /> : 'Konfirmasi'}
        </Button>
      </DialogActions>

      <ActionSnackBar variant={'error'} message={messageSnackBar} open={openSnackbar} handleClose={() => setOpenSnackbar(false)} />
    </Dialog>
  );
};

export default GiroModal;
