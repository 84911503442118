import { Button, Drawer, Grid, makeStyles, MenuItem, TextField, Theme, Typography } from '@material-ui/core';
import { Add, CloseRounded, ExpandLessOutlined, ExpandMore, InfoOutlined, InfoRounded } from '@material-ui/icons';
import { Alert, Skeleton } from '@material-ui/lab';
import EmptyIcon from 'images/icon/EmptyIcon.svg';
import React, { FC, useState } from 'react';
import CollyItem from './CollyItem';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  salesOrderItem: SalesOrderItemModel[];
  collyResourceBefore: typeCollyModel[];
  handleSaveMerge: (
    BagItem: { id: number; SalesOrderItemId: number; productName?: string; productImage?: string; totalItem: number; remark: string }[]
  ) => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    minHeight: '100vh',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  containerItem: {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
    border: '1px solid #D5D5D5',
    borderRadius: '5px',
    padding: '1rem',
    marginTop: theme.spacing(2)
  },
  buttonContainerMobile: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 4,
    backgroundColor: '#fff',
    padding: '13px 10px',
    boxShadow: '0px 0px 17px rgba(0, 0, 0, 0.08)'
  },
  container: {
    rowGap: '1em',
    backgroundColor: '#fff'
  },
  button: {
    borderRadius: '1.4em'
  },
  header: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    padding: '1em',
    gap: '1em'
  },
  section: {
    padding: '0 1em',
    rowGap: '1em'
  },
  textSecondary: {
    fontSize: '0.7rem',
    lineHeight: '1.2rem'
  },
  containerEmpty: {
    minHeight: '250px',
    padding: '0 1em'
  }
}));

const ListItemMerge: FC<Props> = props => {
  const { open, setOpen, salesOrderItem, collyResourceBefore, handleSaveMerge } = props;
  const classes = useStyles();
  const [validation, setValidation] = useState<boolean>(false);
  const getTotalBag = (id: number) => {
    const total = collyResourceBefore.map(value =>
      value.BagItem.filter(value => value.SalesOrderItemId === id)
        .map(value => value.totalItem)
        .reduce((a, b) => a + b, 0)
    );
    return Number(total.reduce((a, b) => a + b, 0));
  };
  const handleSave = () => {
    if (
      salesOrderItem &&
      salesOrderItem
        .filter(value => value.totalItem > getTotalBag(value.id))
        .some(value => value.totalInBag + getTotalBag(value.id) > value.totalItem)
    ) {
      setValidation(true);
    } else {
      const bagItem =
        salesOrderItem &&
        salesOrderItem
          .filter(value => value.totalInBag > 0 && value.totalItem > getTotalBag(value.id))
          .map((value, key) => ({
            id: Date.now() + key,
            SalesOrderItemId: value.id,
            productName: value.productName,
            totalItem: value.totalInBag,
            productImage: value.productImage,
            remark: 'Produk ini memiliki invoice yang berbeda dari produk lainnya pada koli ini',
            isMerge: true
          }));
      if (bagItem.length > 0) {
        handleSaveMerge(bagItem);
        setValidation(false);
      } else {
        setValidation(true);
      }
    }
  };
  return (
    <Drawer
      anchor='bottom'
      open={open}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <Grid xs={12} className={classes.container} container direction='column'>
        <Grid xs={12} container alignItems='center' className={classes.header}>
          <CloseRounded onClick={() => setOpen(false)} style={{ fontSize: '1.5em' }} />
          <Typography variant='h6'>Pilih produk dimasukkan kedalam koli ini</Typography>
        </Grid>
        {validation && (
          <Grid xs={12}>
            <Alert severity='error' className={classes.textSecondary}>
              Silahkan masukkan qty pengepakan terlebih dahulu atau jumlah melebihi qty pengepakan
            </Alert>
          </Grid>
        )}
        <Grid xs={12} className={classes.section} container>
          {salesOrderItem.filter(value => value.totalItem > getTotalBag(value.id)).length > 0 ? (
            salesOrderItem
              .filter(value => value.totalItem > getTotalBag(value.id))
              .map(val => (
                <Grid xs={12} className={classes.containerItem}>
                  <CollyItem items={val} totalBag={getTotalBag(val.id)} />
                </Grid>
              ))
          ) : (
            <Grid xs={12} container direction='column' alignItems='center' justify='center' className={classes.containerEmpty}>
              <img src={EmptyIcon} alt='Data Kosong' width='65px' height='65px' />

              <Typography variant='h6' align='center' style={{ marginTop: '0.4em' }}>
                Sudah tidak ada produk!
              </Typography>
              <Typography align='center' className={classes.textSecondary}>
                Silahkan untuk melihat preview surat jalan
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid xs={12} style={{ minHeight: '3.4em' }}>
          <Grid xs={12} className={classes.buttonContainerMobile}>
            <Button color='primary' style={{ borderRadius: '20px' }} fullWidth onClick={handleSave}>
              Simpan
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default ListItemMerge;
