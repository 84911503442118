import React, { FC, useState, useEffect, useContext } from 'react';
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Tabs,
  ButtonGroup,
  Tab,
  Typography,
  Divider,
  Hidden,
  Tooltip,
  CircularProgress,
  TextField,
  Badge
} from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import useRouter from 'hooks/useRouter';
import ContentInvoice from './components/ContentInvoice';
import { Page, Breadcrumb, StandardConfirmationDialog, PaperCustom } from 'components';
import { BLUE_PRIMARY, WHITE, RED, YELLOW } from 'constants/colors';
import {
  GET_INVOICE_DETAIL_BASE_URL,
  INVOICE_ITEM_UPDATE_BASE_URL,
  COMPANY_BASE_URL,
  INVOICE_BASE_URL,
  INVOICE_PAYMENT_BASE_URL,
  INVOICE_PIECE_BASE_URL,
  INVOICE_ROUNDING_BASE_URL,
  RETURN_INVOICE_BASE_URL,
  BANK_BASE_URL,
  INVOICE_REQUEST_EDIT_BASE_URL,
  INVOICE_REQUEST_CANCEL_BASE_URL,
  INVOICE_ITEM_DELETE_URL,
  INVOICE_CANCEL_BASE_URL,
  INVOICE_NEW_EDIT_BASE_URL,
  GET_INVOICE_PAYMENT_RETURN_BASE_URL,
  GET_INVOICE_PAYMENT_LIST_BASE_URL,
  PARTNER_DETAIL_BASE_URL,
  PARTNER_BASE_URL,
  RETURN_BILL_BASE_URL
} from 'constants/url';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { dummyInvoice, dummyCompany, dummyBank, dummyPartner, dummyInvoiceReturnBill } from 'utils/dummy';
import { TabPanel, a11yProps } from 'components';
import { format } from 'date-fns';
import Alert from '@material-ui/lab/Alert';
import ErrorIcon from '@material-ui/icons/Error';
import NumberFormat from 'react-number-format';
import useRole from 'hooks/useRole';
import PaymentMethod from 'typings/enum/PaymentMethod';
import PaymentStatus from 'typings/enum/PaymentStatus';
import PaymentTable from './components/PaymentTable';
import PieceTable from './components/PieceTable';
import PartnerModal from './components/PartnerModal';
import TypeUser from 'typings/enum/TypeUser';
import useToggle from 'hooks/useToggle';
import PrintIcon from '@material-ui/icons/Print';
import PrintDisabledIcon from '@material-ui/icons/PrintDisabled';

const useStyles = makeStyles({
  box: {
    backgroundColor: WHITE,
    borderRadius: '5px',
    padding: 24,
    border: '1px solid #F2F2F2',
    boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.08)',
    marginTop: 2,
    marginBottom: 10,
    overflowX: 'auto'
  },
  boxTitle: {
    marginBottom: 8
  },
  panel: {
    width: '100%'
  },
  cancelConfirmButton: {
    backgroundColor: RED
  }
});

const InvoiceDetailPage: FC = () => {
  const { match } = useRouter();
  // eslint-disable-next-line
  const params = match.params.id;
  const classes = useStyles();
  const { currentUser } = useContext(CurrentUserContext);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [partner, setPartner] = useState<PartnerModel>(dummyPartner);
  const [invoice, setInvoice] = useState<ReturnBill>(dummyInvoiceReturnBill);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [invoiceItem, setInvoiceItem] = useState<ReturnBillItem[]>([]);
  const [invoicePayment, setInvoicePayment] = useState<InvoicePaymentModel[]>([]);
  const [company, setCompany] = useState<CompanyModel>(dummyCompany);

  const [id, setId] = useState<number>(0);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [loadingTotalPrice, setLoadingTotalPrice] = useState<boolean>(false);
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);

  const [indexCollapse, setIndexCollapse] = useState<number>(-1);
  const [indexEdit, setIndexEdit] = useState<number>(-1);

  const [isRequestEdit, setRequestEdit] = useState<boolean>(false);

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openTotalPrice, setOpenTotalPrice] = useState<boolean>(false);

  const [itemId, setItemId] = useState<{ id: number; notes: string; isRequestEdit: boolean }[]>([]);
  const [typeEdit, setTypeEdit] = useState<string>('');

  const [isPartner, setIsPartner] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<InvoiceItemModel>();
  const [openPartner, setOpenPartner] = useState<boolean>(false);
  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN]
  });

  const isAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN]
  });

  const isInvoice = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN03]
  });

  const isAccounting = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ACCOUNTING]
  });

  const isHR = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.HR]
  });

  const isFinance = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN04]
  });

  const handleOpenUpdateTotalPrice = () => {
    setOpenTotalPrice(true);
  };

  const updateTotalPrice = async () => {
    try {
      setLoadingTotalPrice(true);

      const { data } = await axios.post(INVOICE_ROUNDING_BASE_URL, {
        id: invoice.id,
        totalPrice
      });

      setInvoice(data.data);
      setTotalPrice(data.data.totalPrice);
      handleOpenSnackBar('success', 'Total biaya telah berhasil diperbaharui.');
    } catch (error) {
      console.log('error', error);
      handleOpenSnackBar('error', 'Total biaya telah gagal diperbaharui.');
    } finally {
      setLoadingTotalPrice(false);
      setOpenTotalPrice(false);
    }
  };

  const handleCancelUpdateTotalPrice = () => {
    setOpenTotalPrice(false);
    setTotalPrice(invoice.totalPrice);
  };

  const fetchData = async () => {
    setIsLoadingData(true);

    try {
      const { data } = await axios.get(`${RETURN_BILL_BASE_URL}/${+params}`);

      setInvoice(data.data);
      setTotalPrice(data.data.totalPrice);
      setInvoiceItem(data.data.InvoiceItem);
      setId(data.data.id);
    } catch (error) {
      console.log('error:', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const fetchCompany = async () => {
    try {
      const { data } = await axios.get(COMPANY_BASE_URL);
      setCompany(data.data);
    } catch (error) {
      console.log('error:', error);
    }
  };

  const fetchPayment = async () => {
    try {
      const { data } = await axios.get(GET_INVOICE_PAYMENT_LIST_BASE_URL(id));
      setInvoicePayment(data.data);
    } catch (error) {
      console.log('error:', error);
    } finally {
    }
  };

  const getPartner = async () => {
    setIsPartner(true);
    try {
      const { data } = await axios.get(PARTNER_DETAIL_BASE_URL(invoice.PartnerId), { cancelToken: cancelTokenSource.token });
      setPartner(data.data);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsPartner(false);
    }
  };

  const onRefresh = () => {
    fetchData();
    setItemId([]);
    setIndexCollapse(-1);
    setRequestEdit(false);
  };

  const onSubmit = async () => {
    setLoadingEdit(true);
    try {
      await axios.post(INVOICE_ITEM_UPDATE_BASE_URL, currentItem);
      const { data } = await axios.get(GET_INVOICE_DETAIL_BASE_URL(parseInt(params)));
      const invoice = { ...data.data, totalPrice: data.data.totalPriceReal };

      setInvoice(invoice);
      setTotalPrice(invoice.totalPrice);
      setInvoiceItem(data.data.InvoiceItem);
      setId(data.data.id);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoadingEdit(false);
      setCurrentItem(undefined);
      setIndexEdit(-1);
      setTypeEdit('');
    }
  };

  const onRequest = async () => {
    setLoadingRequest(true);
    try {
      await axios.post(INVOICE_REQUEST_EDIT_BASE_URL, {
        id,
        items: itemId
      });
      handleOpenSnackBar('success', 'Permintaan telah dikirim.');
    } catch (error) {
      console.log('error', error);
      handleOpenSnackBar('error', 'Permintaan gagal dikirim.');
    } finally {
      setLoadingRequest(false);
      setItemId([]);
      setRequestEdit(false);
      setIndexCollapse(-1);
    }
  };

  const onDeleteItem = async (id: number) => {
    setTypeEdit('DELETE');
    setLoadingEdit(true);
    try {
      await axios.delete(INVOICE_ITEM_DELETE_URL(id));
      setInvoiceItem(prevState => prevState.filter(value => value.id !== id));
      const { data } = await axios.get(GET_INVOICE_DETAIL_BASE_URL(parseInt(params)));
      const invoice = { ...data.data, totalPrice: data.data.totalPriceReal };

      setInvoice(invoice);
      setTotalPrice(invoice.totalPrice);
      setInvoiceItem(data.data.InvoiceItem);
      handleOpenSnackBar('success', 'Item berhasil dihapus.');
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoadingEdit(false);
      setCurrentItem(undefined);
      setIndexEdit(-1);
      setTypeEdit('');
    }
  };

  const updateStatusNewEdit = async () => {
    try {
      await axios.get(INVOICE_NEW_EDIT_BASE_URL(id));
    } catch (error) {
      console.log('error', error);
    } finally {
    }
  };

  const handleOpenSnackBar = (variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setMessage(message);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleEdit = (id: number, type: string) => {
    setIndexEdit(prevState => (prevState === id ? -1 : id));
    setTypeEdit(prevState => (prevState === type ? '' : type));
  };

  const handleOpenCollapse = (index: number): React.MouseEventHandler => () => {
    setIndexCollapse(indexCollapse === index ? -1 : index);
  };

  const handleRequestCheck = (item: { id: number; notes: string; isRequestEdit: boolean }) => {};

  const individualEdit = (item: InvoiceItemModel) => {};

  const handleRequestNote = (item: { id: number; notes: string }) => {
    const index = itemId.findIndex(value => value.id === item.id);
    if (index >= 0) {
      itemId[index].notes = item.notes;
      setItemId([...itemId]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [params]);

  useEffect(() => {
    if (id === 0) return;
    fetchPayment();
  }, [id]);

  useEffect(() => {
    if (!openPartner) {
      return;
    }
    getPartner();
  }, [openPartner]);

  useEffect(() => {
    fetchCompany();
  }, []);

  return (
    <Page title='Tagihan Return'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'>Tagihan Return </Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
            <Breadcrumb />
          </Grid>

          <Grid item justify='space-between' lg={12} xl={12} md={12} sm={12} xs={12} container spacing={1} className={classes.box}>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Typography variant='h6' className={classes.boxTitle}>
                Nama Mitra &nbsp;
              </Typography>
              <Typography variant='body2'> {invoice.partnerName || ''} </Typography>
            </Grid>
            <Hidden smUp smDown>
              <Divider orientation='vertical' />
            </Hidden>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Typography variant='h6' className={classes.boxTitle}>
                Tanggal Terbit
              </Typography>
              <Typography variant='body2'> {invoice.createdAt ? format(new Date(invoice.createdAt), 'dd MMM yyyy') : ''} </Typography>
            </Grid>
            <Hidden smUp smDown>
              <Divider orientation='vertical' />
            </Hidden>

            <Hidden smUp smDown>
              <Divider orientation='vertical' />
            </Hidden>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Typography variant='h6' align='right' className={classes.boxTitle}>
                Total Tagihan
              </Typography>
              <Typography align='right' variant='body2'>
                <NumberFormat value={totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              </Typography>
            </Grid>
            <Hidden smUp smDown>
              <Divider orientation='vertical' />
            </Hidden>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Typography variant='h6' align='right' className={classes.boxTitle}>
                Sisa Tagihan
              </Typography>
              <Typography align='right' variant='body2'>
                <NumberFormat value={totalPrice - invoice.totalPay} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} xl={12} md={12} sm={12} xs={12} container>
            <PaperCustom>
              <ContentInvoice
                onDeleteItem={onDeleteItem}
                onRefresh={onRefresh}
                isAdmin={isAdmin}
                isSuperAdmin={isSuperAdmin}
                loadingRequest={loadingRequest}
                isFinance={isFinance}
                loadingEdit={loadingEdit}
                isInvoice={isInvoice}
                onUpdate={onSubmit}
                individualEdit={individualEdit}
                invoice={invoice}
                invoiceItem={invoiceItem}
                invoicePayment={invoicePayment}
                company={company}
                isLoadingData={isLoadingData}
                totalPrice={totalPrice}
                openTotalPrice={openTotalPrice}
                loadingTotalPrice={loadingTotalPrice}
                isRequestEdit={isRequestEdit}
                item={itemId}
                indexCollapse={indexCollapse}
                indexEdit={indexEdit}
                type={typeEdit}
                setOpenPartner={setOpenPartner}
                handleRequest={onRequest}
                handleEdit={handleEdit}
                handleRequestNote={handleRequestNote}
                handleRequestCheck={handleRequestCheck}
                handleOpenCollapse={handleOpenCollapse}
                setTotalPrice={setTotalPrice}
                handleOpenUpdateTotalPrice={handleOpenUpdateTotalPrice}
                handleCancelUpdateTotalPrice={handleCancelUpdateTotalPrice}
                updateTotalPrice={updateTotalPrice}
              />
            </PaperCustom>
          </Grid>
        </Grid>

        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
          onConfirm={handleConfirmSnackbar}
          noCancelButton={true}
        />

        <PartnerModal partner={partner} open={openPartner} isLoadingData={isPartner} setOpen={setOpenPartner} />
      </Container>
    </Page>
  );
};

export default InvoiceDetailPage;
