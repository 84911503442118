import React, { FC } from 'react';
import { TableCellMiddle, TableCellEnd, TableCellStart, TableRowCustom } from 'components/Table';
import Skeleton from '@material-ui/lab/Skeleton';

interface Props {
  item: InvoiceItemModel;
  isLoading: boolean;
}

const BodyRow: FC<Props> = props => {
  const { item, isLoading } = props;

  return (
    <TableRowCustom>
      <TableCellStart>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : item.createdAt || '-'}</TableCellStart>
      <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : '#' + item.InvoiceId}</TableCellMiddle>
      <TableCellMiddle>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : item.Product && item.Product.productName}
      </TableCellMiddle>
      <TableCellMiddle align='center'>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : item.totalItem + ' ' + item.typeUnit}
      </TableCellMiddle>
      <TableCellEnd align='center'>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : (item.Product && item.Product.warehouse) || '-'}
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRow;
