import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { remarkCode } from 'constants/remarkCode';

import React, { FC, useEffect, useState } from 'react';

interface Props {
  collyResource: typeCollyModel[];
  idSalesOrderItem: number;
  loading: boolean;
  open: boolean;
  handletEditQty: (id: number, productId: number, totalQty: number, remark: string, remarkCode: string) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  salesOrderItem: SalesOrderItemModel[];
}
const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    fontWeight: 500,
    fontSize: '1.2rem'
  },
  button: {
    borderRadius: '15px',
    padding: '7px 0'
  }
}));

const remarkEdit = [
  'Pilih Alasan',
  'Kemasan atau dus tidak muat',
  'Salah hitung kuantitas',
  'Perbedaan kuantitas fisik dengan di sistem',
  'Salah warna atau tipe produk'
];
const ModalEditQty: FC<Props> = ({ open, setOpen, salesOrderItem, collyResource, loading, handletEditQty, idSalesOrderItem }) => {
  const classes = useStyles();

  const getSalesOrderItem = salesOrderItem[0];

  const [newQty, setNewQty] = useState<number>(0);
  const [rejectRemark, setRejectRemark] = useState<string>('');

  const handleSubmit = () => {
    handletEditQty(idSalesOrderItem, (getSalesOrderItem && getSalesOrderItem.ProductId) || 0, newQty + getTotalBag(), rejectRemark, remarkCode.EDIT);
  };

  const getTotalBag = () => {
    const total = collyResource.map(value =>
      value.BagItem.filter(value => value.SalesOrderItemId === idSalesOrderItem)
        .map(value => value.totalItem)
        .reduce((a, b) => a + b, 0)
    );
    return Number(total.reduce((a, b) => a + b, 0));
  };

  useEffect(() => {
    if (getSalesOrderItem) {
      setNewQty(getSalesOrderItem.totalItem - getTotalBag());
    }
  }, [getSalesOrderItem]);

  return (
    <Dialog open={open} style={{ maxHeight: 80 + 'vh', marginTop: '60px' }} scroll='paper' fullWidth>
      <DialogTitle>
        <Grid container direction='row' justify='space-between' alignItems='center' xs={12}>
          <Typography className={classes.titleContainer}>Kurangin Stok Pengepakan</Typography>
          <Close fontSize='small' color='action' onClick={() => setOpen(false)} style={{ cursor: 'pointer' }} />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid xs={12} container justify='center'>
          <TextField
            id='name'
            size='small'
            required
            label='Nama Produk'
            // InputProps={{
            //   classes: {
            //     input: classes.fontSizeMobile
            //   }
            // }}
            fullWidth
            value={(getSalesOrderItem && getSalesOrderItem.productName) || ''}
            disabled
          />
          <Grid item container direction='row' xs={12} style={{ display: 'flex' }} justify='space-between'>
            <Grid item style={{ flex: 0.55, display: 'flex', alignItems: 'center' }}>
              <TextField
                id='qty-request'
                size='small'
                required
                label='Qty Request'
                // InputProps={{
                //   classes: {
                //     input: classes.fontSizeMobile
                //   }
                // }}
                // InputLabelProps={{ style: { fontSize: showComponent ? 15 : '1rem' } }}
                value={(getSalesOrderItem && getSalesOrderItem.totalItem) || 0}
                disabled
              />
              <Typography style={{ marginLeft: '0.5em' }}>{(getSalesOrderItem && getSalesOrderItem.typeUnit) || ''}</Typography>
            </Grid>
            <Grid item style={{ flex: 0.45, display: 'flex', alignItems: 'center', paddingLeft: '0.3em' }}>
              <TextField
                id='edit-qty'
                size='small'
                required
                label='Edit Qty'
                // InputProps={{
                //   classes: {
                //     input: classes.fontSizeMobile
                //   }
                // }}
                // InputLabelProps={{ style: { fontSize: showComponent ? 15 : '1rem' } }}
                value={newQty}
                InputProps={{
                  inputMode: 'numeric'
                }}
                error={newQty > (getSalesOrderItem && getSalesOrderItem.totalItem - getTotalBag())}
                onChange={e => setNewQty(Number(e.target.value))}
                autoFocus={true}
              />
              <Typography style={{ marginLeft: '0.5em' }}>{(getSalesOrderItem && getSalesOrderItem.typeUnit) || ''}</Typography>
            </Grid>
          </Grid>
          {newQty > (getSalesOrderItem && getSalesOrderItem.totalItem - getTotalBag()) && (
            <Grid xs={12} container>
              <FormHelperText error={true}>
                Qty melebihi sisa pengepakan ({getSalesOrderItem && getSalesOrderItem.totalItem - getTotalBag()})
              </FormHelperText>
            </Grid>
          )}
          <Grid xs={12}>
            <TextField
              select
              size='small'
              id='edit'
              value={rejectRemark}
              label='Alasan Edit'
              //   InputProps={{
              //     classes: {
              //       input: classes.fontSizeMobile
              //     }
              //   }}
              autoComplete='off'
              fullWidth
              onChange={event => {
                setRejectRemark(event.target.value);
              }}
            >
              {remarkEdit.map((value: string, key: number) => (
                <MenuItem key={key} value={value} selected={key === 0} disabled={key === 0}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid xs={12} container justify='center'>
          <Grid xs={11}>
            <Button
              size='small'
              fullWidth
              className={classes.button}
              color='primary'
              onClick={() => handleSubmit()}
              disabled={rejectRemark === '' || newQty > (getSalesOrderItem && getSalesOrderItem.totalItem - getTotalBag())}
            >
              {loading ? <CircularProgress size={20} color='secondary' /> : 'Simpan'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEditQty;
