import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import FlexBox from 'components/FlexBox';
import React from 'react';

const useStyles = makeStyles({
  container: {
    maxHeight: '0px',
    overflow: 'hidden',
    transition: 'all .3s ease-in-out'
  },
  isExpand: {
    maxHeight: '600000px'
  }
});

/**
 * It's a function that takes in an object with three properties: `isExpand`, `children`, and
 * `pagination`. It returns a `FlexBox` component with the `children` and `pagination` properties
 * passed in as children
 * @param  - { isExpand: boolean; children: React.ReactNode; }
 * @returns A React component.
 */
const PaymentHistoryContainer = ({ isExpand, children }: { isExpand: boolean; children: React.ReactNode }) => {
  const classes = useStyles();
  return (
    <FlexBox xs={12} container className={clsx(classes.container, isExpand && classes.isExpand)} direction='column'>
      {isExpand ? (
        /* It's a destructuring assignment. It's the same as writing `const children = props.children`. */
        <FlexBox xs={12} container rowGap={1}>
          {/* It's a destructuring assignment. It's the same as writing `const children = props.children`.  */}
          {children}
        </FlexBox>
      ) : null}
    </FlexBox>
  );
};

export default PaymentHistoryContainer;
