import React, { FC, Fragment } from 'react';
import { TableContainer, TableBody, TableHead, TableRow, TableCell, TextField, makeStyles, CircularProgress } from '@material-ui/core';
import { HeaderRow, TableCellHead, TableCustom } from 'components/Table';
import { dummyStock } from 'utils/dummy';
import BodyRow from './components/BodyRow';
import { Autocomplete } from '@material-ui/lab';

interface Props {
  isLoadingData: boolean;
  purchases?: StockModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  enterDate: Date | null;
  setEnterDate: React.Dispatch<React.SetStateAction<Date | null>>;
  purchaseNumber: string;
  setPurchaseNumber: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  handleConfirmationDelete: (id: number) => React.MouseEventHandler;
  warehouse: WareHouseModel;
  warehouses: WareHouseModel[];
  loadWarehouse: boolean;
  setWarehouse: React.Dispatch<React.SetStateAction<WareHouseModel>>;
  handleSearchWarehouse: (value: string) => void;
}

const useStyles = makeStyles({
  cellTable: {
    width: '17%'
  }
});

const StockTable: FC<Props> = props => {
  const classes = useStyles();

  const {
    isLoadingData,
    purchases,
    order,
    orderBy,
    purchaseNumber,
    setPurchaseNumber,
    setOrder,
    setOrderBy,
    handleConfirmationDelete,
    warehouse,
    warehouses,
    setWarehouse,
    loadWarehouse,
    handleSearchWarehouse
  } = props;

  const handleRequestSort = ({}, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={[
              { id: 'enterDate', label: 'Tanggal Masuk', sort: true },
              { id: 'pruchaseNumber', label: 'No. Invoice', sort: true },
              { id: 'totalItem', label: 'Jumlah', sort: true, align: 'center' },
              { id: 'WareHouseId', label: 'Lokasi Gudang', sort: true, align: 'center' },
              { label: 'Aksi', align: 'right' }
            ]}
          />
          <TableRow>
            <TableCellHead variant='head' className={classes.cellTable}></TableCellHead>
            <TableCellHead variant='head' className={classes.cellTable}>
              <TextField
                id='invoiceNumber'
                placeholder='Nomor Invoice'
                value={purchaseNumber}
                onChange={event => setPurchaseNumber(event.target.value)}
              />
            </TableCellHead>
            <TableCellHead variant='head' className={classes.cellTable}></TableCellHead>
            <TableCellHead variant='head' className={classes.cellTable}>
              <Autocomplete
                id='warehouse'
                options={warehouses}
                value={warehouse}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={(event: any, value: any) => value && setWarehouse(value)}
                onOpen={e => handleSearchWarehouse('')}
                loading={loadWarehouse}
                renderInput={params => (
                  <TextField
                    {...params}
                    label=' Gudang'
                    onChange={e => handleSearchWarehouse(e.target.value)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {loadWarehouse && <CircularProgress color='inherit' size={20} />}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      )
                    }}
                  />
                )}
              />
            </TableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow key={index + 1} isLoading={isLoadingData} purchase={dummyStock} onDelete={handleConfirmationDelete(0)} />
            ))
          ) : purchases && purchases.length > 0 ? (
            purchases.map((value, index) => (
              <BodyRow key={index + 1} isLoading={isLoadingData} purchase={value} onDelete={handleConfirmationDelete(value.id)} />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align='center'>
                Data Tidak Tersedia.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default StockTable;
