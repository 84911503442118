import React, { FC, useState, useEffect, useCallback, useContext } from 'react';
import { Page, Breadcrumb, StandardConfirmationDialog } from 'components';
import { dummyUser } from 'utils/dummy';
import { Container, Typography, Grid } from '@material-ui/core';
import { COMMISSION_BASE_URL, USER_BASE_URL } from 'constants/url';
import axios from 'axios';
import useRouter from 'hooks/useRouter';
import SalesDetail from './components/SalesDetail';
import CommissionTable from './components/CommissionTable';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import useRole from 'hooks/useRole';
import TypeUser from 'typings/enum/TypeUser';

const CommissionDetail: FC = () => {
  const { location } = useRouter();
  // eslint-disable-next-line
  const param: any = location.state;
  const { history } = useRouter();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [commission, setCommission] = useState<CommissionModel[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [sales, setSales] = useState<UserDetailsModel>(dummyUser);
  const [loadCommission, setLoadCommission] = useState<boolean>(false);

  const [selectedId, setSelectedId] = useState<number>();
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const { currentUser } = useContext(CurrentUserContext);
  const isAccounting = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ACCOUNTING]
  });
  const handleConfirmationDelete = (id: number): React.MouseEventHandler => () => {
    setSelectedId(id);
    setConfirmationDelete(true);
  };
  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleConfirmSnackbar = () => {
    setOpenSnackbar(false);
  };

  const fetchSales = async () => {
    setIsLoadingData(true);
    try {
      const { data } = await axios.get(`${USER_BASE_URL}/${param.id}`);
      setSales(data.data);
    } catch (error) {
      console.log('error:', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const fetchCommission = useCallback(async () => {
    const queryParams = () => {
      const params = new URLSearchParams();
      params.append('salesId', String(sales.id));

      if (orderBy || order) {
        params.append('orderBy', orderBy);
        params.append('ordered', order);
      }

      params.append('page', currentPage.toString());
      return params.toString();
    };
    setLoadCommission(true);
    try {
      const { data } = await axios.get(`${COMMISSION_BASE_URL}?${queryParams()}`);
      setCount(data.meta.last_page);
      setCurrentPage(data.meta.current_page);
      setCommission(data.data);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoadCommission(false);
    }
  }, [orderBy, order, currentPage, sales]);

  const handleCreateCommission = () => {
    history.push('/komisi/tambah', { salesId: param.id });
  };

  // const exportPdf = async () => {
  //   try {
  //     const { data } = await axios.get(COMMISSION_EXPORT_BASE_URL(params), { responseType: 'blob' });
  //     const file = new Blob([data], { type: 'application/pdf' });
  //     const fileURL = URL.createObjectURL(file);

  //     const newwindow = window.open(fileURL, 'komisi', 'height=700,width=750');
  //     if (newwindow && window.focus) {
  //       newwindow.focus();
  //     }
  //   } catch (error) {
  //     console.log('error :', error);
  //   }
  // };

  const deleteCommission = async () => {
    try {
      await axios.delete(`${COMMISSION_BASE_URL}/${selectedId}`);
      setCommission(prevState => prevState.filter(value => value.id !== selectedId));
      handleSnackBar(true, 'success', 'Komisi berhasi dihapus');
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmationDelete(false);
    }
  };

  useEffect(() => {
    fetchSales();
  }, []);

  useEffect(() => {
    fetchCommission();
  }, [fetchCommission]);

  return (
    <Page title='Komisi'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'>Komisi</Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <SalesDetail sales={sales} isLoading={isLoadingData} />
          </Grid>

          <Grid item lg={8} md={8} sm={12} xs={12}>
            <CommissionTable
              handleAddNew={handleCreateCommission}
              isLoadingData={loadCommission}
              commissions={commission}
              isAccounting={isAccounting}
              count={count}
              currentPage={currentPage}
              order={order}
              orderBy={orderBy}
              handleConfirmationDelete={handleConfirmationDelete}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              setCurrentPage={setCurrentPage}
            />
          </Grid>
        </Grid>
        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
          onConfirm={handleConfirmSnackbar}
          noCancelButton={true}
        />
        <StandardConfirmationDialog
          variant={'danger'}
          titleMessage={'Menghapus Data'}
          message={'Apakah kamu yakin akan menghapus data ini?'}
          open={confirmationDelete}
          handleClose={handleCloseConfirmationDelete}
          onConfirm={deleteCommission}
        />
        ;
      </Container>
    </Page>
  );
};

export default CommissionDetail;
