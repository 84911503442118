import React, { FC } from 'react';
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import { dummyItem } from 'utils/dummy';
import BodyRow from './components/BodyRow';

interface Props {
  salesOrderItem: SalesOrderItemModel[];
  loadUpdate: boolean;
}

const ProductInvoiceTable: FC<Props> = props => {
  const { salesOrderItem, loadUpdate } = props;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Nama Produk</TableCell>
            <TableCell align='center'>Harga</TableCell>
            <TableCell align='center'>Kuantitas</TableCell>
            <TableCell align='center'>Satuan</TableCell>
            <TableCell align='center'>Diskon</TableCell>
            <TableCell align='center'>Total Harga</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loadUpdate
            ? [1, 2, 3, 4].map((value, index) => <BodyRow key={index} index={index} item={dummyItem} isLoading={loadUpdate} />)
            : salesOrderItem &&
              salesOrderItem.length > 0 &&
              salesOrderItem.map((value, index) => <BodyRow key={index} index={index} item={value} isLoading={loadUpdate} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductInvoiceTable;
