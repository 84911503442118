import React, { FC, Fragment, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  makeStyles,
  Button,
  Typography,
  Grid,
  TableContainer,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Table,
  CircularProgress
} from '@material-ui/core';
import { MUTATION_ACCEPTED_BASE_URL } from 'constants/url';
import { TableCustom } from 'components/Table';
import { format } from 'date-fns';
import BodyRow from './BodyRow';
import empty from 'images/empty.svg';
import { CurrentUserContext } from 'contexts/CurrentUserContext';

interface Props {
  mutation: MutationModel;
  setMutation: React.Dispatch<React.SetStateAction<MutationModel>>;
  setMutations: React.Dispatch<React.SetStateAction<MutationModel[]>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const useStyles = makeStyles({
  image: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container: {
    maxHeight: 640
  }
});

const StockWizard: FC<Props> = props => {
  const classes = useStyles();
  const { handleSnackBar, setMutations, mutation, setMutation } = props;
  const { currentUser } = useContext(CurrentUserContext);

  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [isSubmit, setSubmit] = useState<boolean>(false);

  const handleOnSubmit = async () => {
    setSubmit(true);
    try {
      const { data } = await axios.post(MUTATION_ACCEPTED_BASE_URL, {
        id: mutation.id,
        acceptedBy: (currentUser && currentUser.id) || 0
      });

      handleSnackBar(true, 'success', 'Mutasi berhasil dikonfirmasi');
      setMutation(data.data);
      setMutations(prevState => {
        prevState[prevState.findIndex(value => value.id === mutation.id)] = data.data;
        return prevState;
      });
    } catch (error) {
      console.log('error :', error);
      handleSnackBar(true, 'error', 'Mutasi gagal dikonfirmasi');
    } finally {
      setSubmit(false);
    }
  };

  useEffect(() => {
    if (mutation && mutation.id === 0) {
      setIsLoadingData(true);
      return;
    }

    setIsLoadingData(false);
  }, [mutation]);

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Table size='small'>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2}>Nomor</TableCell>
              <TableCell colSpan={2} align='right'>
                {mutation.mutationNumber || '-'}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2}>Tanggal</TableCell>
              <TableCell colSpan={2} align='right'>
                {format(new Date(mutation.mutationDate), 'dd/MM/yyy')}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Gudang Asal</TableCell>
              <TableCell align='right'>{(mutation.Origin && mutation.Origin.name) || '-'}</TableCell>
              <TableCell>Gudang Tujuan</TableCell>
              <TableCell align='right'>{(mutation.Destination && mutation.Destination.name) || '-'}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2}>Total Produk</TableCell>
              <TableCell colSpan={2} align='right'>
                {mutation.totalItem}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2}>Diterima Oleh</TableCell>
              <TableCell colSpan={2} align='right'>
                {mutation.acceptedBy === 0 ? (
                  <Button onClick={handleOnSubmit} disabled={isSubmit}>
                    {isSubmit ? <CircularProgress color='inherit' size={20} /> : 'Konfirmasi'}
                  </Button>
                ) : (
                  (mutation.accepted && mutation.accepted.firstName) || '-'
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2}>Status</TableCell>
              <TableCell colSpan={2} align='right'>
                {mutation.status || '-'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <Grid item lg={12} sm={12} md={12} xs={12}>
        {mutation.MutationItem && mutation.MutationItem.length === 0 ? (
          <Fragment>
            <img src={empty} className={classes.image} />
            <Typography variant='subtitle1' align='center'>
              Belum ada produk yang ditambahkan dalam paket ini :(
            </Typography>
          </Fragment>
        ) : (
          <TableContainer className={classes.container}>
            <TableCustom stickyHeader size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Nama Produk</TableCell>
                  <TableCell>Kategori</TableCell>
                  <TableCell align='center'>Jumlah</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mutation.MutationItem &&
                  mutation.MutationItem.length > 0 &&
                  mutation.MutationItem.map((value, index) => <BodyRow key={index} index={index} item={value} isLoading={isLoadingData} />)}
              </TableBody>
            </TableCustom>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};

export default StockWizard;
