import React, { FC } from 'react';
import { Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import Skeleton from '@material-ui/lab/Skeleton';

interface Props {
  data: InvoiceReturnModel[];
  isLoading: boolean;
}

const TablePaymentReturn: FC<Props> = props => {
  const { data, isLoading } = props;

  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell>No Return</TableCell>
          <TableCell align='center'>Jumah Barang</TableCell>
          <TableCell align='right'> Total Return</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {isLoading ? (
          <TableRow>
            <TableCell colSpan={4}>
              <Skeleton variant='text' width='100%' height={25} />
            </TableCell>
          </TableRow>
        ) : (
          data.length > 0 &&
          data.map((value: InvoiceReturnModel, index) => (
            <TableRow key={index}>
              <TableCell>{value.returnNumber}</TableCell>

              <TableCell align='center'>{value.totalItem}</TableCell>

              <TableCell align='right'>
                <NumberFormat value={value.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};

export default TablePaymentReturn;
