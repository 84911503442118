import React, { FC, useState, useEffect } from 'react';
import { Container, Grid, Typography, TableContainer, Table, TableRow, TableCell, TableBody, Chip, TextField } from '@material-ui/core';
import { Page, Breadcrumb, PaperCustom } from 'components';
import { GET_STOCK_CHECK_DETAIL_BASE_URL, STOCK_CHECK_GET_ADJUSTMENT_BASE_URL } from 'constants/url';
import { dummyStockCheck } from 'utils/dummy';
import axios from 'axios';
import useRouter from 'hooks/useRouter';
import Skeleton from '@material-ui/lab/Skeleton';
import ProductTable from './components/ProductTable';
import NumberFormat from 'react-number-format';

const StockCheckDetailPage: FC = () => {
  const { location } = useRouter();
  // eslint-disable-next-line
  const params: any = location.state;

  const [stock, setStock] = useState<StockCheckModel>(dummyStockCheck);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [items, setItems] = useState<StockCheckItemModel[]>([]);
  const [itemsAdjustment, setItemsAdjustment] = useState<StockCheckItemModel[]>([]);

  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(0);
  const [isLoadingCollapse, setLoadingCollapse] = useState<boolean>(false);
  const [term, setTerm] = useState<string>('');

  useEffect(() => {
    setIsLoadingData(true);
    const fetchData = async () => {
      try {
        const { data } = await axios.get(GET_STOCK_CHECK_DETAIL_BASE_URL(params.id) + '?expand=items');
        setStock(data.data);
        setItems(data.data.StockCheckItem);
      } catch (error) {
        console.log('error:', error);
      } finally {
        setIsLoadingData(false);
      }
    };
    fetchData();
  }, []);

  const handleOpenCollapse = (ProductId: number, StockCheckId: number): React.MouseEventHandler => async () => {
    setIndexCollapse(ProductId);
    setOpenCollapse(prevState => (prevState ? (ProductId === indexCollapse ? false : true) : true));

    setLoadingCollapse(true);
    const params = new URLSearchParams();
    params.append('ProductId', ProductId.toString());
    params.append('StockCheckId', StockCheckId.toString());

    try {
      const { data } = await axios.get(`${STOCK_CHECK_GET_ADJUSTMENT_BASE_URL}?${params.toString()}`);
      setItemsAdjustment(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadingCollapse(false);
    }
  };

  return (
    <Page title='Stok Cek Detail'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <Typography variant='h1'> Stok Cek Detail </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid container direction='row' spacing={2}>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <TableContainer>
                <Table size='small'>
                  <TableBody>
                    <TableRow>
                      <TableCell>Tipe</TableCell>
                      <TableCell align='right'>
                        {isLoadingData ? <Skeleton variant='text' width='100%' /> : stock.isStockFirst ? 'Stok Awal' : 'Pengecekan Stok'}
                      </TableCell>
                    </TableRow>

                    {stock.isStockFirst && (
                      <TableRow>
                        <TableCell>
                          <Typography> Periode </Typography>
                        </TableCell>
                        <TableCell align='right'>
                          {isLoadingData ? (
                            <Skeleton variant='text' width='100%' />
                          ) : (
                            (stock.isCurrent && <Chip color='primary' label={stock.periode || '-'} />) || '-'
                          )}
                        </TableCell>
                      </TableRow>
                    )}

                    <TableRow>
                      <TableCell>Tanggal Cek</TableCell>
                      <TableCell align='right'>{isLoadingData ? <Skeleton variant='text' width='100%' /> : stock.checkDate}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Gudang</TableCell>
                      <TableCell align='right'>
                        {isLoadingData ? <Skeleton variant='text' width='100%' /> : stock.WareHouse ? stock.WareHouse.name : '-'}
                      </TableCell>
                    </TableRow>

                    {stock.isStockFirst && (
                      <>
                        <TableRow>
                          <TableCell>
                            <Typography>Total Aset Saat Ini </Typography>
                          </TableCell>
                          <TableCell align='right'>
                            <NumberFormat
                              value={(items.length > 0 && items.map(value => value.purchasePrice).reduce((a, b) => a + b)) || 0}
                              prefix={'Rp'}
                              thousandSeparator={true}
                              displayType='text'
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <Typography>Total Aset Terbaru </Typography>
                          </TableCell>
                          <TableCell align='right'>
                            <NumberFormat
                              value={(items.length > 0 && items.map(value => value.adjustPrice || 0).reduce((a, b) => a + b)) || 0}
                              prefix={'Rp'}
                              thousandSeparator={true}
                              displayType='text'
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <Grid container item lg={8} md={8} sm={12} xs={12} alignContent='center' alignItems='center'>
                <Typography variant='h5'>Semua Produk</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <TextField fullWidth label='Cari Produk' variant='standard' size='small' value={term} onChange={e => setTerm(e.target.value)} />
              </Grid>
            </Grid>

            <Grid item lg={12} sm={12} md={12} xs={12}>
              <ProductTable
                isLoadingData={isLoadingData}
                items={items}
                isFirstStock={stock.isStockFirst || false}
                itemAdjustment={itemsAdjustment}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                isLoadingCollapse={isLoadingCollapse}
                term={term}
                handleOpenCollapse={handleOpenCollapse}
              />
            </Grid>
          </Grid>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default StockCheckDetailPage;
