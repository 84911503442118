import React, { FC, useEffect, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { Grid, Container, Typography, Tooltip, Button } from '@material-ui/core';
import { Page, Breadcrumb, PaperCustom } from 'components';
import { USER_BASE_URL } from 'constants/url';
import CommissionPageTable from './components/CommissionPageTable';
import Pagination from '@material-ui/lab/Pagination';
import RefreshIcon from '@material-ui/icons/Refresh';
import _ from 'lodash';
import PaginationCustom from 'components/PaginationCustom';

const CommissionPage: FC = () => {
  const [count, setCount] = useState<number>(0);
  const [queryString, setQueryString] = useState<string>();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [sales, setSales] = useState<UserDetailsModel[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [salesId, setSalesId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [from, setFrom] = useState<number>(0);
  let cancelToken: CancelTokenSource = axios.CancelToken.source();

  const fetchData = async () => {
    setIsLoadingData(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (name) {
        params.append('keyword', name);
      }

      if (email) {
        params.append('keyword', email);
      }

      if (phoneNumber) {
        params.append('keyword', phoneNumber);
      }
      if (address) {
        params.append('keyword', address);
      }

      if (salesId) {
        params.append('keyword', salesId);
      }

      if (orderBy || order) {
        params.append('orderBy', orderBy);
        params.append('ordered', order);
      }
      params.append('page', currentPage.toString());

      return params.toString();
    };

    try {
      const { data } = await axios.get(`${USER_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelToken.token });
      setSales(data.data);
      setCount(data.meta.last_page);
      setCurrentPage(data.meta.current_page);
      setTotal(data.meta.total);
      setTo(data.meta.to);
      setFrom(data.meta.from);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    const debouncedFetchHits = _.debounce(fetchData, 500);
    debouncedFetchHits();
    return () => cancelToken.cancel('No longer latest query');
  }, [orderBy, order, currentPage, name, address, email, salesId, phoneNumber]);

  useEffect(() => {
    if (!name && !address && !email && !salesId && !phoneNumber) {
      return;
    }

    setCurrentPage(1);
  }, [name, address, email, salesId, phoneNumber]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page title='Komisi'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Komisi </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid container direction='row' alignItems='center' item lg={12} md={12} sm={12} xs={12} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={6} xs={6}>
              <Tooltip title='Refresh'>
                <Button onClick={() => fetchData()} color='inherit'>
                  <RefreshIcon />
                </Button>
              </Tooltip>
            </Grid>

            <Grid item xl={5} lg={5} md={5} sm={6} xs={6}>
              <Typography>{`Menampilkan ${total} komisi (${from} - ${to} dari ${total})`}</Typography>
            </Grid>
            <Grid container justify='center' item xl={12} lg={12} md={12} sm={12} xs={12}>
              <CommissionPageTable
                isLoadingData={isLoadingData}
                queryString={queryString}
                order={order}
                orderBy={orderBy}
                name={name}
                setName={setName}
                address={address}
                setAddress={setAddress}
                email={email}
                setEmail={setEmail}
                salesId={salesId}
                setSalesId={setSalesId}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                sales={sales}
              />
            </Grid>

            <Grid container justify='flex-end' item xl={12} md={12} sm={12}>
              <PaginationCustom
                marginTop='-.4em '
                show={sales.length > 0}
                sxPagination={{
                  count,
                  boundaryCount: 2,
                  variant: 'outlined',
                  shape: 'rounded',
                  onChange: (event, page) => {
                    setCurrentPage(page);
                  },
                  page: currentPage
                }}
                sxPopover={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  }
                }}
                customPageProps={{
                  defaultValue: currentPage,
                  maxValue: count,
                  onSubmit(value) {
                    setCurrentPage(value);
                  }
                }}
              />
            </Grid>
          </Grid>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default CommissionPage;
