import React, { FC, Fragment, useState } from 'react';
import axios from 'axios';
import {
  Grid,
  Container,
  Typography,
  makeStyles,
  Button,
  TextField,
  CircularProgress,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  FormHelperText
} from '@material-ui/core';
import { Page, StandardConfirmationDialog, PaperCustom, Breadcrumb } from 'components';
import { INVOICE_BASE_URL, SALES_ORDER_BASE_URL } from 'constants/url';
import { format } from 'date-fns';
import { dummyUser, dummyPartner, dummySalesOrder } from 'utils/dummy';
import useRouter from 'hooks/useRouter';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ProductTable from './components/ProductInvoiceTable';
import NumberFormat from 'react-number-format';
import ReactQuill from 'react-quill';

const useStyles = makeStyles({
  cellSummary: {
    border: 'none',
    padding: 5
  },
  cellSummaryRight: {
    border: 'none',
    padding: 5,
    width: '20%'
  },
  editorNotes: {
    marginBottom: '10px'
  }
});

const InvoiceCreatePage: FC = () => {
  const classes = useStyles();
  const { history, location } = useRouter();
  // eslint-disable-next-line
  const params: any = location.state;

  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [orderDate, setOrderDate] = useState<Date | null>(new Date());
  const [orderDateMessage, setOrderDateMessage] = useState<string>('');
  const [partner, setPartner] = useState<PartnerModel>(dummyPartner);
  const [partnerMessage, setPartnerMessage] = useState<string>('');
  const [id, setId] = useState<number>(0);
  const [sales, setSales] = useState<UserDetailsModel>(dummyUser);
  const [salesMessage, setSalesMessage] = useState<string>('');
  const [salesOrderItem, setSalesOrderItem] = useState<SalesOrderItemModel[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [notes, setNotes] = useState<string>('');
  const [term, setTerm] = useState<string>('');
  const [dueDate, setDueDate] = useState<string>(String(new Date()));
  const [dueDateMessage, setDueDateMessage] = useState<string>('');
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [loadUpdate, setLoadUpdate] = useState<boolean>(false);
  const [salesOrderItemMessage, setSalesOrderItemMessage] = useState<string>('');
  const [salesOrder, setSalesOrder] = useState<SalesOrderModel>(dummySalesOrder);
  const [salesOrders, setSalesOrders] = useState<SalesOrderModel[]>([dummySalesOrder]);
  const [loadSalesOrder, setLoadSalesOrder] = useState<boolean>(false);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [invoiceNumber, setInvoiceNumber] = useState<string>(new Date().getTime().toString());
  const [salesOrderMessage, setSalesOrderMessage] = useState<string>('');

  const getSalesOrder = (value: SalesOrderModel) => {
    setId(value.id);
    setPartner(value.Partner!);
    setOrderDate(new Date(value.orderDate));
    setDueDate(format(new Date(value.dueDate), 'yyyy-MM-dd'));
    setSales(value.Sales!);
    setSalesOrderItem(value.SalesOrderItem!);
    setNotes(value.notes);
    setTerm(value.terms);
    setTotalItem(value.totalItem);
    setTotalDiscount(value.totalDiscount);
    setTotalPrice(value.totalPrice);
    setLoadUpdate(false);
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
    history.push('/invoice/', { id });
  };

  const handleOnSubmit = async () => {
    resetValidation();
    setSubmit(true);

    if (!validation()) {
      setSubmit(false);
      return;
    }

    try {
      const { data } = await axios.post(INVOICE_BASE_URL, {
        id: 0,
        orderDate,
        totalPrice,
        totalDiscount: totalDiscount,
        PartnerId: salesOrder.PartnerId,
        SalesId: salesOrder.SalesId,
        SalesOrderId: id,
        totalItem,
        dueDate,
        items: salesOrderItem,
        notes,
        terms: term
      });

      setId(data.data.id);
      handleSnackBar(true, 'success', 'Invoice berhasil dibuat');
    } catch (error) {
      console.log('error :', error);
      handleSnackBar(true, 'error', 'Invoice gagal dibuat');
    } finally {
      setSubmit(false);
    }
  };

  const validation = (): boolean => {
    let valid = true;

    if (!partner) {
      setPartnerMessage('Nama Mitra tidak boleh kosong.');
      valid = false;
    }

    if (!sales) {
      setSalesMessage('Nama Sales tidak boleh kosong');
      valid = false;
    }

    if (!orderDate) {
      setOrderDateMessage('Tanggal Order tidak boleh kosong.');
      valid = false;
    }

    if (!dueDate || dueDate === '') {
      setDueDateMessage('Waktu jatuh tempo tidak boleh kosong');
      valid = false;
    }

    if (salesOrderItem.length === 0 || !salesOrderItem) {
      setSalesOrderItemMessage('Item tidak boleh kosong.');
      valid = false;
    }

    if (salesOrder.id === 0 || !salesOrder) {
      setSalesOrderMessage('Sales Order tidak boleh kosong.');
      valid = false;
    }

    return valid;
  };

  const resetValidation = () => {
    setPartnerMessage('');
    setSalesMessage('');
    setOrderDateMessage('');
    setDueDateMessage('');
    setSalesOrderItemMessage('');
  };

  const handleCancel = () => {
    history.push('/invoice');
  };

  const handleSearchSalesOrderNumber = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    params.append('invoiceReady', 'true');
    setLoadSalesOrder(true);

    try {
      const { data } = await axios.get(`${SALES_ORDER_BASE_URL}?${params.toString()}`);
      setSalesOrders(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadSalesOrder(false);
    }
  };

  return (
    <Page title='Penjualan'>
      <Container>
        <Grid container direction='row'>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Penjualan </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>
        <PaperCustom>
          <Grid container direction='row' spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container direction='row' justify='space-between'>
                <Grid container item lg={8} sm={8} md={8} justify='flex-start' alignItems='center'>
                  <Grid item></Grid>
                </Grid>

                <Grid container item lg={4} sm={4} md={4} justify='flex-end' alignItems='center'>
                  <Grid item></Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container direction='row' justify='center' spacing={1} item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={6} sm={6} md={6}>
                <Autocomplete
                  id='sales order'
                  fullWidth
                  value={salesOrder}
                  options={salesOrders}
                  getOptionLabel={option => option.orderNumber}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={(event: any, value: any) => {
                    if (value) {
                      setLoadUpdate(true);
                      setSalesOrder(value);
                      getSalesOrder(value);
                    }
                  }}
                  onOpen={e => handleSearchSalesOrderNumber('')}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label='Pilih Sales Order'
                      onChange={e => handleSearchSalesOrderNumber(e.target.value)}
                      error={salesOrderMessage !== ''}
                      helperText={salesOrderMessage}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {loadSalesOrder && <CircularProgress color='inherit' size={20} />}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} sm={6} md={6}>
                <TextField
                  id='customer'
                  disabled
                  required
                  fullWidth
                  label='Nama Customer'
                  value={partner.name}
                  error={partnerMessage !== ''}
                  helperText={partnerMessage}
                  InputProps={{
                    endAdornment: loadUpdate && <CircularProgress color='inherit' size={20} />
                  }}
                />
              </Grid>
              <Grid item lg={6} sm={6} md={6}>
                <TextField
                  id='invoiceNumber'
                  required
                  fullWidth
                  label='No Invoice'
                  placeholder='No Invoice Penjualan'
                  value={invoiceNumber}
                  onChange={e => setInvoiceNumber(e.target.value)}
                  InputProps={{
                    endAdornment: loadUpdate && <CircularProgress color='inherit' size={20} />
                  }}
                />
              </Grid>
              <Grid item lg={6} sm={6} md={6}>
                <TextField
                  id='sales'
                  disabled
                  required
                  fullWidth
                  label='Nama Sales'
                  value={sales.name}
                  error={salesMessage !== ''}
                  helperText={salesMessage}
                  InputProps={{
                    endAdornment: loadUpdate && <CircularProgress color='inherit' size={20} />
                  }}
                />
              </Grid>
              <Grid item lg={6} sm={6} md={6}>
                <TextField
                  id='orderDate'
                  disabled
                  required
                  fullWidth
                  type='date'
                  label='Tanggal Order'
                  placeholder='Tanggal Terbit Invoice'
                  value={orderDate && format(orderDate, 'yyyy-MM-dd')}
                  onChange={e => setOrderDate(new Date(e.target.value))}
                  error={orderDateMessage !== ''}
                  helperText={orderDateMessage}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item lg={6} sm={6} md={6}>
                <TextField
                  id='dueDate'
                  disabled
                  required
                  fullWidth
                  label='Tanggal Jatuh Tempo'
                  placeholder='No Order Penjualan'
                  value={format(new Date(dueDate), 'dd/MM/yyyy')}
                  error={dueDateMessage !== ''}
                  helperText={dueDateMessage}
                />
              </Grid>
            </Grid>
            <Grid container direction='row' item xl={12} md={12} sm={12}>
              <Typography variant='body2'>Total Item : {salesOrderItem.length}</Typography>
              <br />
              <FormHelperText error={salesOrderItemMessage !== ''}>{salesOrderItemMessage}</FormHelperText>
              <ProductTable salesOrderItem={salesOrderItem} loadUpdate={loadUpdate} />
            </Grid>

            <Grid container direction='row' item xl={12} md={12} sm={12}>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell align='right' className={classes.cellSummary}>
                        <Typography variant='subtitle2'>Subtotal</Typography>
                      </TableCell>
                      <TableCell align='right' className={classes.cellSummaryRight}>
                        <NumberFormat value={totalPrice + totalDiscount} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='right' className={classes.cellSummary}>
                        <Typography variant='subtitle2'>Diskon</Typography>
                      </TableCell>
                      <TableCell align='right' className={classes.cellSummaryRight}>
                        <NumberFormat value={totalDiscount} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='right' className={classes.cellSummary}>
                        <Typography variant='subtitle2'>Total Harga</Typography>
                      </TableCell>
                      <TableCell align='right' className={classes.cellSummaryRight}>
                        <NumberFormat value={totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='right' className={classes.cellSummary}>
                        <Typography variant='subtitle2'>Lunas</Typography>
                      </TableCell>
                      <TableCell align='right' className={classes.cellSummaryRight}>
                        -
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='right' className={classes.cellSummary}>
                        <Typography variant='subtitle2'>Jumlah Tertagih</Typography>
                      </TableCell>
                      <TableCell align='right' className={classes.cellSummaryRight}>
                        <NumberFormat value={totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid container direction='row' item xl={12} md={12} sm={12}>
              <Grid item lg={6} sm={6} md={6}>
                <ReactQuill
                  id='notes'
                  value={notes}
                  onChange={(value: any) => setNotes(value)}
                  placeholder='Catatan'
                  className={classes.editorNotes}
                />
              </Grid>
            </Grid>

            <Grid container direction='row' justify='flex-end' spacing={2} item xl={12} md={12} sm={12}>
              <Grid item>
                <Button onClick={handleCancel} color='secondary'>
                  Batal
                </Button>
              </Grid>
              <Grid item>
                <Button disabled={isSubmit} onClick={handleOnSubmit}>
                  {isSubmit ? <CircularProgress color='inherit' size={20} /> : 'Simpan'}
                </Button>
              </Grid>
            </Grid>

            <StandardConfirmationDialog
              variant={snackbarVariant}
              titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
              message={message}
              open={openSnackbar}
              handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
              onConfirm={handleConfirmSnackbar}
              noCancelButton={true}
            />
          </Grid>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default InvoiceCreatePage;
