import React, { FC, useEffect } from 'react';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom, TableRowCollapse, ButtonCollapse } from 'components';
import { TablePaymentReturn } from 'components/TableCollapse';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';
import { makeStyles, Typography, Theme, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import useRouter from 'hooks/useRouter';
import PaymentStatus from 'typings/enum/PaymentStatus';
import PaymentMethod from 'typings/enum/PaymentMethod';
import { BLUE_PRIMARY, CONFIRMATION_COLOR, PENDING_COLOR, WHITE } from 'constants/colors';

const useStyles = makeStyles((theme: Theme) => ({
  accept: {
    padding: '5px 12px 5px 12px',
    borderRadius: '100px',
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '22px',
    color: WHITE,
    background: BLUE_PRIMARY,
    textAlign: 'center',
    width: 120
  },
  unpaid: {
    padding: '5px 12px 5px 12px',
    borderRadius: '100px',
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '22px',
    color: WHITE,
    background: PENDING_COLOR,
    textAlign: 'center',
    width: 120
  },
  paid: {
    padding: '5px 12px 5px 12px',
    borderRadius: '100px',
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '22px',
    color: WHITE,
    background: CONFIRMATION_COLOR,
    textAlign: 'center',
    width: 120
  },
  cancel: {
    background: '#ccc',
    color: '#000'
  }
}));

interface Props {
  invoicePayment: InvoicePaymentModel;
  invoicePaymentReturn: InvoiceReturnModel[];
  isLoading: boolean;
  isLoadingCollapse: boolean;
  indexCollapse: number;
  openCollapse: boolean;
  handleOpenCollapse: React.MouseEventHandler;
}

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { invoicePayment, isLoading, invoicePaymentReturn, isLoadingCollapse, indexCollapse, openCollapse, handleOpenCollapse } = props;
  const { history } = useRouter();

  const handleLink = () => {
    history.push(`/pembayaran-penjualan/${invoicePayment.id}`);
  };

  useEffect(() => {
    if (!invoicePayment || !invoicePaymentReturn) {
      return;
    }

    if (invoicePayment.paymentMethod === 'Return' && invoicePayment.useReturn && invoicePaymentReturn[0]) {
      invoicePaymentReturn[0].totalPrice = invoicePayment.totalReturn || 0;
    }
  }, [openCollapse, invoicePayment, invoicePaymentReturn]);

  return (
    <>
      <TableRowCustom>
        <TableCellStart>
          <ButtonCollapse
            name={invoicePayment.paymentNumber}
            isLoading={isLoading}
            isOpen={openCollapse && invoicePayment.id === indexCollapse}
            useCollapse={true}
            handleOpenCollapse={handleOpenCollapse}
            handleNameLink={handleLink}
          />
        </TableCellStart>

        <TableCellMiddle onClick={handleLink}>
          {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : invoicePayment.partnerName}
        </TableCellMiddle>

        <TableCellMiddle onClick={handleLink}>
          {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : invoicePayment.routeName || '-'}
        </TableCellMiddle>

        <TableCellMiddle>
          {isLoading ? (
            <Skeleton variant='text' width={'100%'} height={25} />
          ) : (
            <div>
              <Typography variant='body2'>{format(new Date(invoicePayment.payDate), 'dd MMM yyyy')}</Typography>
              <NumberFormat value={invoicePayment.totalPay} prefix={'Rp'} thousandSeparator={true} displayType='text' />
            </div>
          )}
        </TableCellMiddle>

        <TableCellMiddle>
          {isLoading ? (
            <Skeleton variant='text' width={'100%'} height={25} />
          ) : (
            invoicePayment.paymentMethod && (
              <div>
                <Typography variant='inherit'>{`${invoicePayment.paymentMethod.toUpperCase()}${(invoicePayment.paymentMethod !==
                  PaymentMethod.RETURN &&
                  invoicePayment.useReturn &&
                  ' + RETURN') ||
                  ''}`}</Typography>
                {invoicePayment.paymentMethod === PaymentMethod.GIRO && (
                  <Typography variant='body2' style={{ color: BLUE_PRIMARY, fontSize: '12px' }}>
                    ({invoicePayment.status === PaymentStatus.PAID ? 'Pencairan : ' : 'Jatuh Tempo : '}
                    {format(
                      invoicePayment.status === PaymentStatus.PAID ? new Date(invoicePayment.giroDate!) : new Date(invoicePayment.giroDueDate!),
                      'dd-MM-yyyy'
                    )}
                    )
                  </Typography>
                )}
              </div>
            )
          )}
        </TableCellMiddle>

        <TableCellMiddle onClick={handleLink}>
          {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : invoicePayment.giroNumber}
        </TableCellMiddle>

        <TableCellEnd align='right'>
          {isLoading ? (
            <Skeleton className={classes.cancel} variant='text' width={'100%'} height={25} />
          ) : invoicePayment.paymentMethod !== PaymentMethod.GIRO ? (
            <Typography className={classes.accept}>DITERIMA</Typography>
          ) : invoicePayment.status === PaymentStatus.PAID ? (
            <Typography className={classes.paid}>SUDAH CAIR</Typography>
          ) : (
            <Typography className={classes.unpaid} align='center'>
              BELUM CAIR
            </Typography>
          )}
        </TableCellEnd>
      </TableRowCustom>

      <TableRowCollapse open={openCollapse && invoicePayment.id === indexCollapse && !isLoading} colSpan={7}>
        {invoicePayment.InvoicePiece.length > 0 && (
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Jenis Potongan</TableCell>
                <TableCell>Catatan</TableCell>
                <TableCell align='right'> Total Potongan</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoicePayment.InvoicePiece.map((InvoicePiece, index) => (
                <TableRow key={index}>
                  <TableCell>{InvoicePiece.type === 'SHIPPING' ? 'Ongkos Kirim' : 'Lainnya'}</TableCell>
                  <TableCell>{InvoicePiece.note}</TableCell>
                  <TableCell align='right'>
                    <NumberFormat value={InvoicePiece.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {invoicePayment.useReturn && <TablePaymentReturn isLoading={isLoadingCollapse} data={invoicePaymentReturn} />}

        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Invoice Number</TableCell>
              <TableCell>Nama Sales</TableCell>
              <TableCell align='right'> Total Pembayaran</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <Skeleton variant='text' width='100%' height={25} />
                </TableCell>
              </TableRow>
            ) : (
              invoicePayment.InvoicePaymentMultiple.length > 0 &&
              invoicePayment.InvoicePaymentMultiple.map((value, index) => (
                <TableRow key={index}>
                  <TableCell>{value.invoiceNumber}</TableCell>
                  <TableCell>{value.firstName ? `${value.firstName} ${value.lastName}` : '-'}</TableCell>

                  <TableCell align='right'>
                    <NumberFormat value={value.totalPay} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableRowCollapse>
    </>
  );
};

export default BodyRow;
