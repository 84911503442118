import React, { FC, Fragment } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  IconButton,
  Typography,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Tooltip,
  Badge,
  Button,
  CircularProgress
} from '@material-ui/core';
import { RED, GREEN } from 'constants/colors';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom, StatusPayment, TableRowCollapse } from 'components';
import { format } from 'date-fns';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import NumberFormat from 'react-number-format';
import Skeleton from '@material-ui/lab/Skeleton';
import ErrorIcon from '@material-ui/icons/Error';
import TimerOffTwoToneIcon from '@material-ui/icons/TimerOffTwoTone';
import clx from 'clsx';
import PrintRequest from 'images/icon/PrintIndicator1.png';
import PrintAccept from 'images/icon/PrintIndicator2.png';
import HasPrint from 'images/icon/PrintIndicator3.png';

interface Props {
  invoice: InvoiceModel;
  isLoading: boolean;
  openCollapse: boolean;
  index: number;
  invoicePrint: number;
  indexCollapse: number;
  statusPrint: number;
  loadingItem: boolean;
  isRequestEdit: boolean;
  handlePrint: (id: number) => void;
  handlePrintRequest: (id: number) => void;
  loadingPrintRequest: boolean;
  isAdmin: boolean;
  loadingPrint: boolean;
  adminInvoice: boolean;
  isSuperAdmin: boolean;
  onDelete: React.MouseEventHandler;
  handleOpenCollapse: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      color: RED,
      width: 30
    },
    tableCellAction: {
      width: 30
    },
    tableCellPrint: {
      width: 100
    },
    tableCellPrint1: {
      width: 200
    },
    customerName: {
      fontSize: '15px',
      fontWeight: 500,
      fontFamily: 'Rubik',
      fontStyle: 'normal',
      lineHeight: '22px'
    },
    tableCollapse: {
      width: '100%'
    },
    cellCollapse: {
      border: 'none'
    },
    requestEdit: {
      backgroundColor: GREEN,
      padding: 4
    },
    rowRequestEdited: {
      backgroundColor: '#ccc',
      '&:hover': {
        backgroundColor: '#ccc'
      }
    },
    rowNewEdited: {
      boxShadow: '0px 2px 4px rgba(0, 136, 186, 0.4)',
      borderRadius: 4
    },
    tableCell: {
      width: 100
    },
    timeOut: {
      animation: 'shake 0.5s',
      animationIterationCount: 'infinite'
    },
    '@keyframes shake': {
      '0%': { transform: 'translate(1px, 1px) rotate(0deg)' },
      '10%': { transform: ' translate(-1px, -2px) rotate(-1deg)' },
      '20%': { transform: ' translate(-3px, 0px) rotate(1deg)' },
      '30%': { transform: ' translate(3px, 2px) rotate(0deg)' },
      '40%': { transform: ' translate(1px, -1px) rotate(1deg)' },
      '50%': { transform: ' translate(-1px, 2px) rotate(-1deg)' },
      '60%': { transform: ' translate(-3px, 1px) rotate(0deg)' },
      '70%': { transform: ' translate(3px, 1px) rotate(-1deg)' },
      '80%': { transform: ' translate(-1px, -1px) rotate(1deg)' },
      '90%': { transform: ' translate(1px, 2px) rotate(0deg)' },
      '100%': { transform: ' translate(1px, -2px) rotate(-1deg)' }
    }
  })
);

const BodyRow: FC<Props> = props => {
  const {
    invoice,
    isLoading,
    loadingItem,
    openCollapse,
    index,
    statusPrint,
    indexCollapse,
    adminInvoice,
    invoicePrint,
    isSuperAdmin,
    handlePrintRequest,
    loadingPrintRequest,
    loadingPrint,
    isAdmin,
    isRequestEdit,
    handlePrint,
    handleOpenCollapse
  } = props;

  const classes = useStyles();

  const handleLink = () => {
    if (isRequestEdit && isSuperAdmin) {
      if (invoice.isRequestEdit) {
        window.open(`/invoice/${invoice.id}`);
      }
    } else {
      window.open(`/invoice/${invoice.id}`);
    }
  };

  const visible = () => {
    if (isSuperAdmin) {
      return !invoice.isRequestEdit;
    }

    if (isAdmin) {
      return !invoice.isNewEdited;
    }

    return true;
  };

  const printIndicator = () => {
    if (!invoice.requestPrint && invoice.canPrint && invoice.printCount && invoice.printCount > 0) {
      return <img src={PrintAccept} alt='image' width={60} height={50} />;
    } else if (invoice.requestPrint) {
      return <img src={PrintRequest} alt='image' width={60} height={50} />;
    } else if (!invoice.canPrint && invoice.printCount && invoice.printCount > 0) {
      return <img src={HasPrint} alt='image' width={60} height={50} />;
    } else {
      return null;
    }
  };

  return (
    <Fragment>
      <TableRowCustom
        className={clx(
          isSuperAdmin && isRequestEdit && !invoice.isRequestEdit && classes.rowRequestEdited,
          isAdmin && isRequestEdit && !invoice.isNewEdited && classes.rowRequestEdited,
          isAdmin && isRequestEdit && invoice.isNewEdited && classes.rowNewEdited
        )}
      >
        <TableCellStart>
          {isLoading ? (
            <Skeleton variant='text' width={60} height={25} />
          ) : (
            <IconButton aria-label='expand row' size='small' onClick={handleOpenCollapse}>
              {openCollapse && index === indexCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCellStart>

        <TableCellMiddle onClick={handleLink}>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (isSuperAdmin || isAdmin) && isRequestEdit ? (
            <Badge variant='dot' color='primary' invisible={visible()}>
              #{invoice.invoiceNumber}
            </Badge>
          ) : (
            ` #${invoice.invoiceNumber}`
          )}
        </TableCellMiddle>
        <TableCellMiddle onClick={handleLink}>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : invoice.createdAt ? (
            format(new Date(invoice.createdAt), 'dd MMM yyyy')
          ) : null}
        </TableCellMiddle>

        <TableCellMiddle onClick={handleLink}>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <Typography variant='inherit' component='p' className={classes.customerName}>
              {invoice.partnerName || '-'}
            </Typography>
          )}
        </TableCellMiddle>

        <TableCellMiddle align='center' onClick={handleLink}>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <StatusPayment
              status={invoice.statusPayment}
              overdue={invoice.paymentDue ? new Date().getTime() > new Date(invoice.paymentDue).getTime() : false}
              totalPrice={invoice.totalPrice}
              totalPay={invoice.totalPay}
            />
          )}
        </TableCellMiddle>

        <TableCellMiddle className={classes.tableCell} align='center'>
          {isLoading ? <Skeleton variant='text' width={100} height={25} /> : invoice.zoneName || '-'}
        </TableCellMiddle>

        <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={100} height={25} /> : invoice.salesName || '-'}</TableCellMiddle>

        <TableCellMiddle onClick={handleLink}>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <NumberFormat value={invoice.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>

        <TableCellMiddle onClick={handleLink}>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <NumberFormat value={invoice.totalPay} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>

        {adminInvoice ? (
          <TableCellMiddle className={classes.tableCellPrint}>
            {isLoading ? (
              <Skeleton variant='text' width={100} height={25} />
            ) : statusPrint > 0 ? (
              <Badge
                color='error'
                variant='dot'
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                invisible={!invoice.requestPrint}
              >
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() => handlePrintRequest(invoice.id)}
                  disabled={loadingPrintRequest && invoicePrint === invoice.id}
                >
                  {loadingPrintRequest && invoicePrint === invoice.id ? <CircularProgress size={20} color='primary' /> : 'Meminta Cetak'}
                </Button>
              </Badge>
            ) : (
              <Button
                variant='outlined'
                color='primary'
                onClick={() => handlePrint(invoice.id)}
                disabled={loadingPrint && invoicePrint === invoice.id}
              >
                {loadingPrint && invoicePrint === invoice.id ? <CircularProgress size={20} color='primary' /> : 'Cetak INV'}
              </Button>
            )}
          </TableCellMiddle>
        ) : (
          <TableCellMiddle className={classes.tableCellPrint1}>
            {isLoading ? <Skeleton variant='text' width={100} height={25} /> : printIndicator()}
          </TableCellMiddle>
        )}

        <TableCellEnd align='center' className={classes.tableCellAction}>
          {invoice.typeInvoice && invoice.typeInvoice === 'ALLOWED' && (
            <Tooltip title='Invoice ini melalui izin plafon.'>
              <ErrorIcon fontSize='small' color='action' />
            </Tooltip>
          )}

          {invoice.isDue && (
            <Tooltip title='Invoice ini sudah melewati tanggal pelunasan.'>
              <TimerOffTwoToneIcon fontSize='small' color='error' className={classes.timeOut} />
            </Tooltip>
          )}
        </TableCellEnd>
      </TableRowCustom>

      <TableRowCollapse open={openCollapse && index === indexCollapse && !isLoading} colSpan={9}>
        <Table className={classes.tableCollapse} size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Nama</TableCell>
              <TableCell align='center'>Kuantitas</TableCell>
              <TableCell align='center'>Gudang</TableCell>
              <TableCell align='right'> Total Harga</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loadingItem ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <Skeleton variant='text' width='100%' height={25} />
                </TableCell>
              </TableRow>
            ) : (
              invoice.InvoiceItem &&
              invoice.InvoiceItem.map((value: InvoiceItemModel, index) => (
                <TableRow key={index} className={clx(value.isRequestEdit && classes.requestEdit)}>
                  <TableCell className={classes.cellCollapse}>{value.productName || '-'}</TableCell>

                  <TableCell align='center' className={classes.cellCollapse}>
                    {value.totalItem + ' ' + value.typeUnit}
                  </TableCell>

                  <TableCell align='center' className={classes.cellCollapse}>
                    {value.warehouse || '-'}
                  </TableCell>

                  <TableCell align='right' className={classes.cellCollapse}>
                    <NumberFormat value={value.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableRowCollapse>
    </Fragment>
  );
};

export default BodyRow;
