import React, { useState, useEffect, lazy, Suspense, useCallback } from 'react';
import { Button, Container, FormControl, Grid, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import axios, { CancelTokenSource } from 'axios';
import { Breadcrumb, Page, PaperCustom, StandardConfirmationDialog } from 'components';
import FlexBox from 'components/FlexBox';
import { CATEGORY_BASE_URL, INCOME_STATEMENT_BASE_URL, OPERATIONAL_BASE_URL, PRODUCT_SEARCH, STOCK_MOVEMENT_BASE_URL } from 'constants/url';
import useDebounce from 'hooks/useDebounce';
import { dummyMetaData } from 'utils/dummy';
import { CreateIncomeStatementFilter } from './components';
import PaginationCustom from 'components/PaginationCustom';
import { format, startOfMonth } from 'date-fns';
import DateRangeFilter from 'components/DateRangeFilter';
import SearchInput from 'components/SearchInput';
import { Today } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh';
const StockMovementContent = lazy(() => import('./components/CreateIncomeStatementContent'));

export type TListExpand = {
  id: number | string;
  action: 'products' | 'months';
};

export type TFilters = {
  keyWord: string;
  productName: string;
  categoryId: number;
  isProductPackage: boolean;
};

export type TData<T> = {
  isLoading: boolean;
  data: T;
};

export const dummyFilters: TFilters = {
  productName: '',
  keyWord: '',
  isProductPackage: false,
  categoryId: 0
};

export type TypeOperasionalResource = {
  isLoading: boolean;
  data: Operasional[];
};

/* Creating a dummyData object with two properties: isLoading and data. */
const dummyData = {
  isLoading: true,
  data: []
};

const dummyIncomeStatement: IncomeStatement = {
  id: 0,
  totalSales: 0,
  salesReturnBill: 0,
  salesReturn: 0,
  salesDiscount: 0,
  netRevenue: 0,
  totalPurchase: 0,
  purchaseLogisticCost: 0,
  purchaseReturn: 0,
  totalPurchaseCost: 0,
  beginningInventory: 0,
  endingInventory: 0,
  costOfGoodsSold: 0,
  totalCost: 0,
  grossProfit: 0,
  profitLoss: 0,
  otherIncome: 0,
  totalIncome: 0,
  startDate: '',
  endDate: '',
  isDeleted: false,
  createdAt: ''
};

interface Props {
  id: number;
  open: boolean;
  setId: React.Dispatch<React.SetStateAction<number>>;
  setIsCreate: React.Dispatch<React.SetStateAction<boolean>>;
}

/* A function that returns a react component. */
const StockMovementPage = ({ setId, id, open, setIsCreate }: Props) => {
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [filters, setFilters] = useState<TFilters>(dummyFilters);
  const [productCategories, setProductCategories] = useState<TData<CategoryModel[]>>(dummyData);
  const [operasionalResource, setOperasionalResource] = useState<TypeOperasionalResource>({
    isLoading: false,
    data: []
  });
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [incomeStatementId, setIncomeStatementId] = useState<IncomeStatement | null>(null);
  const [incomeStatement, setIncomeStatement] = useState<TData<IncomeStatement>>({
    isLoading: dummyData.isLoading,
    data: dummyIncomeStatement
  });
  const [omset, setOmset] = useState<boolean>(false);
  const [purchase, setPurchase] = useState<boolean>(false);
  const [hpp, setHpp] = useState<boolean>(false);
  const [costOperational, setCostOperational] = useState<boolean>(false);

  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const handleCalendarFilterClick = () => setOpenCalendarFilter(true);

  // const handleChangeFilters = <T,>(key: keyof typeof dummyFilters, value: T) => {
  //   setFilters(prev => ({ ...prev, [key]: value }));
  // };

  const [filter, setFilter] = useState<{
    from: Date;
    to: Date;
  }>({
    from: startOfMonth(new Date()),
    to: new Date()
  });

  const handleChangeFilter = useCallback(
    <T,>(key: string, value: T) => {
      setFilter(prev => ({ ...prev, [key]: value }));
    },
    [filter]
  );

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
    setIsCreate(false);
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const fetchCost = useCallback(async () => {
    setOperasionalResource(prev => ({ ...prev, isLoading: true }));
    const getQueryParams = () => {
      const params = new URLSearchParams();
      params.append('startDate', format(new Date(startDate), 'yyyy-MM-dd'));
      params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));
      return params;
    };

    try {
      const { data } = await axios.get(`${OPERATIONAL_BASE_URL}?${getQueryParams()}`, {
        cancelToken
      });
      setOperasionalResource(prev => ({ ...prev, isLoading: false, data: data.data }));
    } catch (error) {
      console.log(error);
    }
  }, [startDate, endDate]);

  const getForm = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    const getQueryParams = () => {
      const params = new URLSearchParams();

      params.append('startDate', format(new Date(startDate), 'yyyy-MM-dd'));
      params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));

      return params;
    };

    try {
      if (id == 0) {
        console.log('get form');
        const { data } = await axios.get(`${INCOME_STATEMENT_BASE_URL}/form?${getQueryParams()}`, {
          cancelToken
        });
        console.log(data);
        setIncomeStatement(prev => ({ ...prev, isLoading: false, data: data }));
      } else {
        console.log('get id');
        const { data } = await axios.get(`${INCOME_STATEMENT_BASE_URL}/${id}`, { cancelToken: cancelTokenSource.token });
        setIncomeStatement(prev => ({ ...prev, isLoading: false, data: data.data }));
      }
    } catch (error) {
      console.log(error);
    }
  }, [startDate, endDate]);

  const handleOnUpdate = async (id: number) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    try {
      // setIncomeStatementId(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnSubmit = async () => {
    try {
      const { data } = await axios.post(INCOME_STATEMENT_BASE_URL, {
        ...incomeStatement.data,
        startDate: startDate,
        endDate: endDate
      });

      handleSnackBar(true, 'success', 'Laba Rugi berhasil dibuat');
    } catch (error) {
      console.log('error :', error);
      handleSnackBar(true, 'error', 'Laba Rugi gagal dibuat');
    }
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleCancel = () => {
    setId(0);
    setIsCreate(false);
    setIncomeStatement({ isLoading: false, data: dummyIncomeStatement });
  };

  /**
   * When the user clicks the refresh button, reset the filters to the default values, fetch the
   * products, and reset the current page to 1.
   */
  // const handleRefresh = () => {
  //   setFilters(dummyFilters);
  //   fetchProducts();
  //   setProductMeta(prev => ({ ...prev, current_page: 1 }));
  // };

  /**
   * The function fetches products from the server and sets the state of the products and productMeta.
  //  */
  // const fetchProducts = async () => {
  //   setProducts(prev => ({ ...prev, isLoading: true }));
  //   const params = new URLSearchParams();

  //   params.append('page', productMeta.current_page.toString());
  //   if (filters.productName) {
  //     params.append('keyword', filters.productName);
  //     params.append('isProductPackage', 'false');
  //   }
  //   if (filters.categoryId !== 0) {
  //     params.append('CategoryId', filters.categoryId.toString());
  //   }

  //   try {
  //     const { data } = await axios.get(`${STOCK_MOVEMENT_BASE_URL}?${params}`, {
  //       cancelToken
  //     });
  //     setProducts({
  //       isLoading: false,
  //       data: data.data.map((product: StockMovementProduct) => {
  //         return { ...product, hasExpand: false };
  //       })
  //     });

  //     setProductMeta(prev => ({ ...prev, ...data.meta }));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const fetchProductMonths = async (id: number) => {
  //   setProductMonths(prev => ({ ...prev, isLoading: true }));
  //   try {
  //     const { data } = await axios.get(`${INCOME_STATEMENT_BASE_URL}/form`, {
  //       cancelToken
  //     });
  //     setProductMonths(prev => ({
  //       isLoading: false,
  //       data: data.data
  //     }));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const fetchStockMovement = async (date: string) => {
  //   setStockMovements(prev => ({ ...prev, isLoading: true }));

  //   const params = new URLSearchParams();

  //   params.append('date', date);
  //   params.append('productId', String(productExpand.id));

  //   try {
  //     const { data } = await axios.get(`${STOCK_MOVEMENT_BASE_URL}/detail?${params}`, {
  //       cancelToken
  //     });
  //     setStockMovements(prev => ({
  //       ...prev,
  //       isLoading: false,
  //       data: data.data
  //     }));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchProducts();
  //   return () => source.cancel('Cancel Unmounted');
  // }, [productMeta.current_page, filters.productName, filters.categoryId]);

  // const productNameDebouce = useDebounce(filters.keyWord, 500);

  // useEffect(() => {
  //   if (productNameDebouce.length < 1 || filters.productName === filters.keyWord) return;
  //   fetchProductSuggests();
  //   return () => source.cancel('Cancel Unmounted');
  // }, [productNameDebouce]);

  /* A callback function that is being used to expand the list. */
  // const handleListExpand = useCallback(
  //   ({ id, action }: TListExpand) => {
  //     if (action.includes('products')) {
  //       setProducts(prev => ({
  //         ...prev,
  //         data: prev.data.map(product => {
  //           return { ...product, hasExpand: product.id === id };
  //         })
  //       }));

  //       setProductExpand(prev => ({
  //         id: Number(id),
  //         isOpen: prev.id === id ? !prev.isOpen : true
  //       }));

  //       if (products.data.filter(product => product.id === id)[0].hasExpand) return;
  //       fetchProductMonths(+id);
  //     } else {
  //       setMonthExpand(prev => ({
  //         month: String(id),
  //         isOpen: prev.month === id ? !prev.isOpen : true
  //       }));

  //       const date = +id.toString().slice(5) < 10 ? `${new Date(id.toString()).getFullYear()}-0${id.toString().slice(5)}` : String(id);
  //       // fetchStockMovementSummary(date);
  //       fetchStockMovement(date);
  //     }
  //   },
  //   [products, productMonths, stockMovements]
  // );

  // const handleOnClickHeader = {
  //   isLoadingDownload,
  //   handleRefresh
  // };

  useEffect(() => {
    fetchCost();
    getForm();
    // handleOnUpdate(id);
    // if (id !== 0) {
    //   handleOnUpdate(id);

    // }
  }, [startDate, endDate, id]);

  console.log('id --->', id);

  return (
    <Page title='Laba Rugi'>
      <Container>
        <Grid container direction='row'>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'>Laba Rugi</Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>
        <PaperCustom>
          <FlexBox container rowGap={1}>
            <StandardConfirmationDialog
              variant={snackbarVariant}
              titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
              message={message}
              open={openSnackbar}
              handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
              onConfirm={handleConfirmSnackbar}
              noCancelButton={true}
            />
            <Grid container direction='row' justify='space-between' alignItems='center'>
              <Grid item>
                <Grid container alignItems='center'>
                  {id == 0 && (
                    <Grid item>
                      <Tooltip title='Calendar filter' placement='top'>
                        <Button color='primary' variant='contained' startIcon={<Today />} onClick={handleCalendarFilterClick}>
                          Filter Tanggal
                        </Button>
                      </Tooltip>
                    </Grid>
                  )}
                  <Grid item style={{ marginLeft: '1em' }}>
                    <Typography color='initial'>
                      Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {id != 0 && (
                <Grid item>
                  <Grid container alignItems='center'>
                    <Grid item>
                      <Button onClick={handleCancel} color='primary'>
                        Kembali
                      </Button>
                    </Grid>
                    <Grid item style={{ marginLeft: '1em' }}>
                      <Button onClick={handleCancel} color='primary'>
                        Download Pdf
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {/* <Grid container item justify='flex-start' alignItems='center'>
                <Grid container alignItems='center' item xl={12} lg={8} md={12} sm={12} xs={12}>
                  <Grid item>
                   
                    <CreateIncomeStatementFilter filter={filter} handleChangeFilter={handleChangeFilter} />
                  </Grid>
                  <Grid item>
                    <Tooltip title='Calendar filter' placement='top'>
                      <IconButton color='primary' aria-label='filter date' component='span' onClick={handleCalendarFilterClick}>
                        <Today />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid> */}
              {/* {id != 0 && (
                <Grid item container justify='flex-end' style={{ columnGap: '1em' }}>
                  <Grid item>
                    <Button onClick={handleCancel} color='primary'>
                      Kembali
                    </Button>
                  </Grid>
                </Grid>
              )} */}
            </Grid>

            <Suspense
              fallback={
                <Grid xs={12} container justify='center' alignItems='center'>
                  <Typography variant='h6'>Sedang Membuka...</Typography>
                </Grid>
              }
            >
              <StockMovementContent
                incomeStatement={incomeStatement}
                omset={omset}
                setOmset={setOmset}
                hpp={hpp}
                setHpp={setHpp}
                purchase={purchase}
                setPurchase={setPurchase}
                costOperational={costOperational}
                setCostOperational={setCostOperational}
                operasionalResource={operasionalResource}
              />
            </Suspense>
            {id === 0 && (
              <Grid container direction='row' justify='flex-end' spacing={2} item xl={12} lg={12} md={12} sm={12}>
                <Grid item>
                  <Button onClick={handleCancel} color='secondary'>
                    Batal
                  </Button>
                </Grid>
                <Grid item>
                  <Grid item>
                    <Button onClick={handleOnSubmit}>
                      {/* {isSubmit ? <CircularProgress color='inherit' size={20} /> : 'Simpan'} */}
                      Simpan
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </FlexBox>
          <DateRangeFilter
            openCalendarFilter={openCalendarFilter}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            handleClose={() => setOpenCalendarFilter(false)}
          />
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default StockMovementPage;
