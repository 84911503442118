import React, { FC } from 'react';
import {
  Checkbox,
  ListItemText,
  makeStyles,
  Grid,
  MenuItem,
  Select,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  TableBody,
  TableCell
} from '@material-ui/core';
import { HeaderRow, TableCellHead, TableCustom } from 'components';
import BodyTable from './BodyTable';
import { Skeleton } from '@material-ui/lab';
import useRouter from 'hooks/useRouter';
interface Props {
  deliveryOrders: DeliveryOrders[];
  zones: ZoneModel[];
  deliveryNumber: string;
  zone: number[];
  isLoading: boolean;
  salesName: string;
  partnerName: string;
  setZone: React.Dispatch<React.SetStateAction<number[]>>;
  setSalesName: React.Dispatch<React.SetStateAction<string>>;
  setPartnerName: React.Dispatch<React.SetStateAction<string>>;
  setDeliveryNumber: React.Dispatch<React.SetStateAction<string>>;
}
const useStyles = makeStyles((theme: Theme) => ({
  cellTable: {
    width: '20%'
  },
  cellTable1: {
    width: '17%'
  },
  cellTable2: {
    width: '5%'
  },

  cellTable3: {
    width: '13%'
  }
}));
const BodySection: FC<Props> = (props): JSX.Element => {
  const classes = useStyles();
  const { history } = useRouter();
  const {
    deliveryOrders,
    zone,
    zones,
    setZone,
    isLoading,
    setSalesName,
    salesName,
    setDeliveryNumber,
    deliveryNumber,
    partnerName,
    setPartnerName
  } = props;
  const handleLink = (id: number) => history.push(`/surat-jalan/${id}`);
  return (
    <Grid xs={12}>
      <TableContainer>
        <TableCustom>
          <TableHead>
            <HeaderRow
              // order={order}
              // orderBy={orderBy}
              // onRequestSort={handleRequestSort}
              headers={[
                { id: 'doNumber', label: 'Nomor Surat Jalan', sort: true },
                { id: 'createdAt', label: 'Tgl Surat Jalan', sort: true },
                { id: 'name', label: 'Nama Customer', sort: true },
                { id: 'zone', label: 'RUTE', sort: true, align: 'center' },
                { id: 'totalColly', label: 'Total Koli', sort: true },
                { id: 'invoiceNumber', label: 'Invoice Rujukan', sort: true },
                { id: 'nameSales', label: 'NAMA SALES', sort: true },
                { label: '' }
              ]}
            />
            <TableRow>
              <TableCellHead variant='head' className={classes.cellTable1}>
                <TextField placeholder='Nomor surat jalan' value={deliveryNumber} onChange={event => setDeliveryNumber(event.target.value)} />
              </TableCellHead>
              <TableCellHead variant='head' className={classes.cellTable1} />
              <TableCellHead variant='head' className={classes.cellTable1}>
                <TextField placeholder='Nama Customer' value={partnerName} onChange={event => setPartnerName(event.target.value)} />
              </TableCellHead>
              <TableCellHead variant='head' className={classes.cellTable3}>
                <Select
                  id='demo-mutiple-checkbox'
                  margin='dense'
                  style={{ marginTop: '0.4em' }}
                  multiple
                  variant='outlined'
                  fullWidth
                  value={zone}
                  onChange={e => setZone(e.target.value as number[])}
                  renderValue={selected =>
                    `${zones
                      .filter(value => (selected as number[]).includes(value.id as number))
                      .map(value => value.name)
                      .join(',')
                      .substr(0, 8)}...`
                  }
                >
                  {zones.map(value => (
                    <MenuItem key={value.id} value={value.id}>
                      <Checkbox color='primary' checked={zone.indexOf(value.id) > -1} />
                      <ListItemText primary={value.name} />
                    </MenuItem>
                  ))}
                </Select>
              </TableCellHead>

              <TableCellHead variant='head' className={classes.cellTable2} />
              <TableCellHead variant='head' className={classes.cellTable2} />
              <TableCellHead variant='head' className={classes.cellTable}>
                <TextField placeholder='Nama Sales' value={salesName} onChange={event => setSalesName(event.target.value)} />
              </TableCellHead>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={7} style={{ padding: 0 }}>
                  <Grid xs={12} container>
                    {[...new Array(5)].map((val, key) => (
                      <Grid xs={12}>
                        <Skeleton variant='text' animation='wave' height={60} key={key} />
                      </Grid>
                    ))}
                  </Grid>
                </TableCell>
              </TableRow>
            ) : deliveryOrders.length > 0 ? (
              deliveryOrders.map((deliveryOrder, key) => <BodyTable handleLink={() => handleLink(deliveryOrder.id)} deliveryOrder={deliveryOrder} />)
            ) : (
              <TableRow>
                <TableCell colSpan={7} align='center'>
                  Data Tidak Tersedia.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </TableCustom>
      </TableContainer>
    </Grid>
  );
};

export default BodySection;
