import React, { FC } from 'react';
import { Dialog, DialogContent, DialogActions, TextField, Grid, Button } from '@material-ui/core';
import { DialogTitle } from 'components/Dialog';

interface Props {
  remark: string;
  isLoading: boolean;
  open: boolean;
  setRemark: React.Dispatch<React.SetStateAction<string>>;
  handleClose: () => void;
  handleOnSubmit: () => void;
}

const RemarkDialog: FC<Props> = props => {
  return (
    <Dialog fullWidth open={props.open} onClose={props.handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='customized-dialog-title'>Remark Alamat</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField id='remark' label='Remark' value={props.remark} fullWidth onChange={e => props.setRemark(e.target.value)} multiline rows={2} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant='outlined' onClick={props.handleClose} color='primary'>
          Keluar
        </Button>
        <Button onClick={props.handleOnSubmit} color='primary'>
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemarkDialog;
