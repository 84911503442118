import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { memo } from 'react';
import { ArgumentAxis, ValueAxis, Chart, LineSeries, Tooltip, ScatterSeries } from '@devexpress/dx-react-chart-material-ui';
import FlexBox from 'components/FlexBox';
import { TimelineDot } from '@material-ui/lab';
import { GREEN_MOBILE, GREY, GREY_6, YELLOW_MOBILE } from 'constants/colors';
import clsx from 'clsx';
import { EventTracker } from '@devexpress/dx-react-chart';
import NumberFormat from 'react-number-format';
import monthNames from 'utils/month';
import Loading from 'components/Loading';

interface Props {
  chartTitle: string;
  chartResource: IDashboard<DashboardChart[]>;
}

const useStyles = makeStyles({
  bgGreen: {
    backgroundColor: GREEN_MOBILE
  },
  bgYellow: {
    backgroundColor: YELLOW_MOBILE
  },
  colorGreen: {
    color: GREEN_MOBILE
  },
  colorYellow: {
    color: YELLOW_MOBILE
  },
  container: {
    boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 3px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
    borderRadius: '.4em'
  },

  loadingWrapper: {
    minHeight: '60vh',
    rowGap: '1em',
    backgroundColor: GREY_6
  },

  padding: {
    padding: '1rem'
  },
  chartPadding: {
    padding: '1.4em .4rem 1em'
  },
  pointer: {
    cursor: 'pointer'
  }
});

const ChartSection = ({ chartResource, chartTitle }: Props) => {
  const classes = useStyles();

  const tooltipContent = ({ targetItem }: { targetItem: { series: string; point: number } }) => {
    const { series, point } = targetItem;
    const totalPrice = series.includes('done') ? chartResource.data[point].done : chartResource.data[point].pending;
    const totalSalerOrder = series.includes('done') ? chartResource.data[point].totalSoDone : chartResource.data[point].totalSoPending;
    const date = chartResource.data[point].dateFull;

    return (
      <Grid className={clsx(classes.container, classes.padding)}>
        <Typography variant='h6'>{series.includes('done') ? 'Nominal diselesaikan:' : 'Nominal tertunda:'}</Typography>
        <Typography variant='h6'>{`${new Date(date).getDate()} ${monthNames[new Date(date).getMonth()]} ${new Date(date).getFullYear()}`}</Typography>
        <Typography variant='h6'>Total Sales Order: {totalSalerOrder}</Typography>
        <Typography variant='h6' className={clsx(series.includes('done') ? classes.colorGreen : classes.colorYellow)}>
          Total: <NumberFormat value={totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        </Typography>
      </Grid>
    );
  };

  const LabelWithThousand = ({ text, ...restProps }: any) => {
    return <ValueAxis.Label text={`Rp.${text}`} {...restProps} />;
  };

  const Point = (props: any) => {
    const { value } = props;
    if (value) {
      return <ScatterSeries.Point {...props} point={{ size: 6 }} />;
    }
    return null;
  };

  const LineWithPoint = (props: any) => (
    <React.Fragment>
      <LineSeries.Path {...props} />
      <ScatterSeries.Path {...props} pointComponent={Point} />
    </React.Fragment>
  );
  return (
    <FlexBox xs={12} container rowGap={1}>
      <FlexBox container alignItems='center' columnGap={1} xs={12}>
        <FlexBox container columnGap={1} alignItems='center' xs={2}>
          <TimelineDot className={classes.bgYellow} />
          <Typography>Nominal tertunda</Typography>
        </FlexBox>
        <FlexBox container columnGap={1} alignItems='center' xs={3}>
          <TimelineDot className={classes.bgGreen} />
          <Typography>Nominal diselesaikan</Typography>
        </FlexBox>
      </FlexBox>
      {chartResource.isLoading ? (
        <Grid xs={12} className={clsx(classes.loadingWrapper, classes.container)} container alignItems='center' justify='center' direction='column'>
          <Loading />
          <Typography variant='h6'>Sedang membuka...</Typography>
        </Grid>
      ) : (
        <Grid xs={12} className={clsx(classes.container, classes.chartPadding)}>
          <Typography variant='h5' align='center'>
            {`Grafik ${chartTitle}`}
          </Typography>
          <Chart data={chartResource.data}>
            <ArgumentAxis />
            <ValueAxis labelComponent={LabelWithThousand} />
            <EventTracker />

            <Tooltip contentComponent={tooltipContent} />
            <LineSeries name='pending' valueField='pending' argumentField='date' color={YELLOW_MOBILE} seriesComponent={LineWithPoint} />
            <LineSeries name='done' valueField='done' argumentField='date' color={GREEN_MOBILE} seriesComponent={LineWithPoint} />
          </Chart>
        </Grid>
      )}
    </FlexBox>
  );
};

export default memo(ChartSection);
