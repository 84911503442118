import React from 'react';
import { Grid, FormControl, FormControlLabel, Checkbox, Typography, CircularProgress, FormLabel, FormGroup, FormHelperText } from '@material-ui/core';

interface Props {
  warehouses: WareHouseModel[];
  currentWarehouse: number[];
  userId: number;
  handleOnSubmit: (UserId: number, WareHouseId: number) => void;
}

const WareHouseList: React.FC<Props> = props => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>Pilih Gudang</FormLabel>
          <FormGroup>
            {props.warehouses.map((value: WareHouseModel) => (
              <FormControlLabel
                key={value.id}
                control={
                  <Checkbox
                    color='primary'
                    onChange={() => props.handleOnSubmit(props.userId, value.id)}
                    checked={props.currentWarehouse.some(val => val === value.id)}
                  />
                }
                label={value.name}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default WareHouseList;