import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { GREEN_MOBILE, GREY_6 } from 'constants/colors';
import React from 'react';
import { ArgumentAxis, ValueAxis, Chart, LineSeries, Tooltip, ScatterSeries } from '@devexpress/dx-react-chart-material-ui';
import clsx from 'clsx';
import FlexBox from 'components/FlexBox';
import { EventTracker } from '@devexpress/dx-react-chart';
import monthNames from 'utils/month';
import Loading from 'components/Loading';

interface Props {
  tooltipTitle: string;
  contentCard: React.ReactNode;
  chartHeader: React.ReactNode;
  dataChart: IDashboard<DashboardChartStatistic[]>;
}

const useStyles = makeStyles({
  scroll: {
    overflowX: 'scroll'
  },
  loadingWrapper: {
    minHeight: '60vh',
    rowGap: '1em',
    backgroundColor: GREY_6
  },

  boxShadow: {
    boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 3px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
    borderRadius: '.4em'
  },
  colorGreen: {
    color: GREEN_MOBILE
  },
  padding: {
    padding: '1rem'
  },
  chartPadding: {
    padding: '1.4em .4rem 1em'
  }
});

const StatisticSection = ({ dataChart, chartHeader, contentCard, tooltipTitle }: Props) => {
  const classes = useStyles();

  const tooltipContent = ({ targetItem }: { targetItem: { point: number } }) => {
    const { point } = targetItem;
    const total = dataChart.data[point].data;
    const date = dataChart.data[point].dateFull;

    return (
      <Grid className={clsx(classes.boxShadow, classes.padding)}>
        <Typography variant='h6'>{`${tooltipTitle}:`}</Typography>
        <Divider />
        <Typography variant='h6'>{`${new Date(date).getDate()} ${monthNames[new Date(date).getMonth()]} ${new Date(date).getFullYear()}`}</Typography>
        <Typography variant='h6'>
          Total:
          <span className={classes.colorGreen}> ({total})</span>
        </Typography>
      </Grid>
    );
  };

  const Point = (props: any) => {
    const { value } = props;
    if (value) {
      return <ScatterSeries.Point {...props} point={{ size: 6 }} />;
    }
    return null;
  };

  const LineWithPoint = (props: any) => (
    <React.Fragment>
      <LineSeries.Path {...props} />
      <ScatterSeries.Path {...props} pointComponent={Point} />
    </React.Fragment>
  );
  return (
    <FlexBox container xs={12} rowGap={1}>
      {contentCard}
      {chartHeader}
      {dataChart.isLoading ? (
        <Grid xs={12} className={clsx(classes.loadingWrapper, classes.boxShadow)} container alignItems='center' justify='center' direction='column'>
          <Loading />
          <Typography variant='h6'>Sedang membuka...</Typography>
        </Grid>
      ) : (
        <Grid xs={12} className={clsx(classes.boxShadow, classes.chartPadding)}>
          <Typography variant='h5' align='center'>
            {`Grafik ${tooltipTitle}`}
          </Typography>
          <Chart data={dataChart.data}>
            <ArgumentAxis />
            <ValueAxis />
            <EventTracker />
            <Tooltip contentComponent={tooltipContent} />
            <LineSeries valueField='data' argumentField='date' seriesComponent={LineWithPoint} />
          </Chart>
        </Grid>
      )}
    </FlexBox>
  );
};

export default StatisticSection;
