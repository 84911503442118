import React, { FC, Fragment } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import NumberFormat from 'react-number-format';
import { Checkbox } from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart, TableRowCustom } from 'components';

interface Props {
  index: number;
  invoice: InvoiceReturnModel;
  isLoading: boolean;
  checked: InvoiceReturnModel[];
  handleIndividualCheck: (item: InvoiceReturnModel) => void;
}

const BodyRow: FC<Props> = props => {
  const { invoice, isLoading, checked, handleIndividualCheck } = props;
  const { id } = invoice;

  let isChecked = checked.some(checkedValue => checkedValue.id === id);

  const handleSetId = () => {
    handleIndividualCheck(invoice);
  };

  return (
    <Fragment>
      <TableRowCustom>
        <TableCellStart>
          {isLoading ? (
            <Skeleton variant='text' width={'100%'} height={25} />
          ) : (
            <Fragment>
              {isLoading ? (
                <Skeleton variant='text' width={60} height={25} />
              ) : (
                <Checkbox
                  key={id}
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  edge='start'
                  color='primary'
                  size='small'
                  checked={isChecked}
                  tabIndex={-1}
                  disableRipple
                  onChange={handleSetId}
                />
              )}
            </Fragment>
          )}
        </TableCellStart>

        <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : invoice.returnNumber}</TableCellMiddle>

        <TableCellMiddle>
          {isLoading ? (
            <Skeleton variant='text' width={'100%'} height={25} />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: invoice.notes || '-' }}></div>
          )}
        </TableCellMiddle>

        <TableCellMiddle align='center'>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : invoice.totalItem}</TableCellMiddle>

        <TableCellMiddle align='right'>
          {isLoading ? (
            <Skeleton variant='text' width={'100%'} height={25} />
          ) : (
            <NumberFormat value={invoice.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>

        <TableCellEnd align='right'>
          {isLoading ? (
            <Skeleton variant='text' width={'100%'} height={25} />
          ) : (
            <NumberFormat value={invoice.totalPrice - (invoice.totalUsage || 0)} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCellEnd>
      </TableRowCustom>
    </Fragment>
  );
};

export default BodyRow;
