import React, { FC } from 'react';
import { TableBody, TableHead, TableRow, TableCell, TextField, TableContainer, MenuItem } from '@material-ui/core';
import { HeaderRow, TableCellHead, TableCustom } from 'components/Table';
import { dummyInvoiceReturn } from 'utils/dummy';
import BodyRow from './components/BodyRow';

interface Props {
  isLoadingData: boolean;
  loadingItem: boolean;
  returnOrders: InvoiceReturnModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  name: string;
  salesName: string;
  returnOrderId: string;
  openCollapse: boolean;
  indexCollapse: number;
  inProgress: string;
  handleOpenCollapse: (index: number, id: number) => React.MouseEventHandler;
  typeReturn: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setSalesName: React.Dispatch<React.SetStateAction<string>>;
  setReturnOrderId: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  setInProgress: React.Dispatch<React.SetStateAction<'0' | 'inProgress' | 'needConvert'>>;
  handleConfirmationDelete: (id: number) => React.MouseEventHandler;
}

const ReturnOrderTable: FC<Props> = props => {
  const {
    isLoadingData,
    returnOrders,
    order,
    orderBy,
    name,
    returnOrderId,
    loadingItem,
    typeReturn,
    inProgress,
    setName,
    setReturnOrderId,
    setOrder,
    setOrderBy,
    setInProgress,
    handleConfirmationDelete,
    openCollapse,
    indexCollapse,
    handleOpenCollapse
  } = props;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={[
              { label: '#' },
              { id: 'returnNumber', label: 'NO RETURN', sort: true },
              { id: 'name', label: typeReturn === 'CUSTOMER' ? 'NAMA CUSTOMER' : 'NAMA SUPPLIER', sort: true },
              { id: 'nameSales', label: 'Sales', sort: true },
              { id: 'returnDate', label: 'TGL RETURN', sort: true },
              { id: 'totalItem', label: 'TOTAL Barang', sort: true, align: 'center' },
              { id: 'totalPrice', label: 'Nominal Return', sort: true, align: 'center' },
              { id: 'progress', label: 'Progres', align: 'center' },
              { label: '' }
            ]}
          />
          <TableRow>
            <TableCellHead variant='head' colSpan={2}>
              <TextField id='returnOrderId' placeholder='No Return' value={returnOrderId} onChange={event => setReturnOrderId(event.target.value)} />
            </TableCellHead>
            <TableCellHead variant='head'>
              <TextField id='name' placeholder='Mitra' value={name} onChange={event => setName(event.target.value)} />
            </TableCellHead>

            <TableCellHead variant='head' />

            <TableCellHead variant='head' />

            <TableCellHead variant='head' />

            <TableCellHead variant='head' />

            <TableCellHead variant='head'>
              <TextField
                id='name'
                placeholder='Pilih Progres'
                fullWidth
                value={inProgress}
                onChange={event => setInProgress(event.target.value as any)}
                select
                style={{ textTransform: 'none' }}
              >
                <MenuItem value='0' selected disabled>
                  Pilih Progres
                </MenuItem>
                <MenuItem value='inProgress'>Belum Diproses</MenuItem>
                <MenuItem value='needConvert'>Butuh Konversi</MenuItem>
              </TextField>
            </TableCellHead>

            <TableCellHead variant='head' />
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow
                key={index}
                isLoading={isLoadingData}
                returnOrder={dummyInvoiceReturn}
                onDelete={handleConfirmationDelete(0)}
                handleOpenCollapse={handleOpenCollapse(index, 0)}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                loadingItem={loadingItem}
                index={index}
              />
            ))
          ) : returnOrders && returnOrders.length > 0 ? (
            returnOrders.map((value, index) => (
              <BodyRow
                key={index}
                isLoading={isLoadingData}
                returnOrder={value}
                onDelete={handleConfirmationDelete(value.id)}
                index={index}
                indexCollapse={indexCollapse}
                loadingItem={loadingItem}
                openCollapse={openCollapse}
                handleOpenCollapse={handleOpenCollapse(index, value.id)}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={9} align='center'>
                Data Return tidak tersedia.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default ReturnOrderTable;
