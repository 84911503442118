import React, { FC, useContext, useState } from 'react';
import { TableBody, TableHead, TableRow, TableCell, TableContainer, Typography } from '@material-ui/core';
import BodyRow from './components/BodyRow';
import { TableCustom } from 'components/Table';
import { dummyMiniReturnItem } from 'utils/dummy';
import Skeleton from '@material-ui/lab/Skeleton';
import { CurrentUserContext } from 'contexts/CurrentUserContext';

interface Props {
  isLoadingData: boolean;
  loadingUpdate: boolean;
  withCollapse: boolean;
  returnOrderItem: InvoiceReturnItemModel[];
  item: {
    id: number;
    totalDamage: number;
    totalMerge: number;
    totalReturned: number;
  };
  isShow: boolean;
  openCollapse: boolean;
  indexCollapse: number;
  setItem: React.Dispatch<
    React.SetStateAction<{
      id: number;
      totalDamage: number;
      totalMerge: number;
      totalReturned: number;
    }>
  >;
  handleOpenCollapse: (index: number) => React.MouseEventHandler;
  handleUpdateItem: () => void;
}

const ListProduct: FC<Props> = props => {
  const { currentUser } = useContext(CurrentUserContext);
  const { isLoadingData, returnOrderItem, loadingUpdate, item, withCollapse, isShow, setItem, handleUpdateItem } = props;
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);

  const handleOpenCollapse = (index: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    const current = returnOrderItem.find(value => value.id === index);
    if (openCollapse) {
      if (index === indexCollapse) {
        setOpenCollapse(false);
        setItem(dummyMiniReturnItem);
      } else {
        setOpenCollapse(true);

        if (current) {
          setItem({
            id: current.id,
            totalDamage: current.totalDamage,
            totalMerge: current.totalMerge,
            totalReturned: current.totalReturned || 0
          });
        }
      }
    } else {
      setOpenCollapse(true);

      if (current) {
        setItem({
          id: current.id,
          totalDamage: current.totalDamage,
          totalMerge: current.totalMerge,
          totalReturned: current.totalReturned || 0
        });
      }
    }
  };

  return (
    <TableContainer>
      <TableCustom size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Nama Produk</TableCell>

            {isShow && <TableCell align='center'>Harga</TableCell>}

            <TableCell align='center'>
              Kuantitas <Typography> (dari Pelanggan)</Typography>
            </TableCell>

            {!withCollapse && (
              <TableCell align='center'>
                Kuantitas <Typography>(dari Gudang)</Typography>
              </TableCell>
            )}

            {isShow && <TableCell align='center'>Total Harga</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            <TableRow>
              <TableCell colSpan={!withCollapse ? 5 : 4}>
                <Skeleton variant='text' width='100%' />
              </TableCell>
            </TableRow>
          ) : (
            returnOrderItem.length > 0 &&
            returnOrderItem.map(value => (
              <BodyRow
                withCollapse={withCollapse}
                loadingUpdate={loadingUpdate}
                key={value.id}
                isLoading={isLoadingData}
                item={value}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                handleOpenCollapse={handleOpenCollapse(value.id)}
                _item={{ ...item, totalReturned: item.totalReturned || 0 }}
                setItem={setItem}
                index={value.id}
                isShow={isShow}
                handleUpdateItem={handleUpdateItem}
              />
            ))
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default ListProduct;
