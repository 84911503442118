import React, { FC, Fragment, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
  TextField,
  DialogActions,
  MenuItem,
  Divider,
  InputAdornment,
  InputLabel,
  FormControl,
  OutlinedInput,
  FormHelperText,
  CircularProgress
} from '@material-ui/core';
import { format } from 'date-fns';
import { DialogTitle } from 'components/Dialog';
import useRouter from 'hooks/useRouter';
import NumberFormat from 'react-number-format';
import { BLACK, GREEN_MOBILE, YELLOW_MOBILE, PRIMARY_MOBILE } from 'constants/colors';
import { CheckCircle, Close, ErrorOutlineRounded, ExpandLessRounded, ExpandMoreRounded, InfoOutlined, WarningRounded } from '@material-ui/icons';
import TypeUser from 'typings/enum/TypeUser';
import { remarkCode } from 'constants/remarkCode';
import SalesOrderStatus from 'typings/enum/SalesOrderStatus';
import { GET_HISTORY_DISCOUNT } from 'constants/url';
import { GET_HISTORY_PRICE } from 'constants/url';
import axios from 'axios';

type typeDiscount = {
  discountUnit: number;
  orderDate: string;
  price: number;
  totalItem: number;
};
interface Props {
  isAccess: string;
  salesOrder: SalesOrderModel;
  salesOrderItem: SalesOrderItemModel[];
  updateColly: () => void;
  rejectOrder: ItemAction[];
  getWareHouseID: any;
  handleReset: () => void;
  handleDeleteSO: () => void;
  fetchData: () => void;
  collyResource: typeCollyModel[];
  handleStatusOnSubmit: () => void;
  resetAction: () => void;
  loadingPrint: boolean;
  openEdit: boolean;
  handleSubmitConvet: () => void;
  loadingConvet: boolean;
  handleUpdateDiscount: (id: number, ProductId: number, discount: number, totalQty: number, price: number, remark: string, notes: string) => void;
  discountLoading: boolean;
  handletRequestEdit: (id: number, productId: number, totalQty: number, remark: string, remarkCode: string) => void;
  tempCollyFinal: (data: typeCollyFinal) => void;
  collyFinalUpdate: typeCollyModel;
  idPartner: number;
  getIdClick: number;
  loadingDataSave?: boolean;
  itemColly: typeColly[];
  addItemAction: (value: ItemAction) => void;
  handleRejectItem: (id: number) => void;
  showComponent: boolean;
  editQtyLoading: boolean;
  handleSubmitColly: () => void;
  deleteSalesOrderItem: () => void;
  hasOpenModal: number;
  deleteColly: (id: number) => void;
  setItemColly: React.Dispatch<React.SetStateAction<typeColly[]>>;
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setHasOpenModal: React.Dispatch<React.SetStateAction<number>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    borderRadius: '15px',
    padding: '7px 0'
  },
  cancelButtonStyle: {
    borderRadius: '15px'
  },
  addButton: {
    color: '#FFFFFF'
  },
  root: {
    flexGrow: 1,
    maxWidth: 700
  },
  gridRolesHidden: {
    display: 'none'
  },
  fontMobile: {
    color: BLACK,
    lineHeight: 1 + 'rem',
    fontSize: '1rem'
  },
  fontSizeMobile: {
    fontSize: '1rem'
  },

  control: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: '0.3px solid lightgrey'
  },

  optionsContainer: {
    padding: '0 6px',
    backgroundColor: '#F7F9FC'
  },
  textField: {
    width: 99
  },
  textField2: {
    width: 80
  },

  mobileContainer: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
      '& > .MuiGrid-item': {
        padding: 0
      }
    }
  },
  rootContainer: {
    marginTop: theme.spacing(1)
  },
  titleContainer: {
    fontWeight: 500,
    fontSize: '1.2rem',
    color: BLACK,
    paddingLeft: '6px'
  },
  itemContainer: {
    border: '0.6px solid rgba(0, 0, 0, 0.08)',
    marginBottom: theme.spacing(2),
    borderRadius: '6px',
    padding: 10 + 'px'
  },
  titleSizeMobile: {
    fontSize: 1 + 'rem',
    color: BLACK,
    lineHeight: 1.5 + 'rem',
    fontWeight: 'bold',
    paddingBottom: '0.4em'
  },

  stickyCotainer: {
    position: 'sticky',
    top: '56px',
    zIndex: 1,
    borderRadius: '20px',
    backgroundColor: '#fff',
    border: 'none'
  },

  fontBoldMobile: {
    fontWeight: 500
  },
  actionColi: {
    marginTop: theme.spacing(2)
  },

  containerRow: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    position: 'relative',
    display: 'flex'
  },
  containerContent: {
    [theme.breakpoints.up('md')]: {
      width: '70vh'
    },
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  containerAction: {
    marginBottom: theme.spacing(1)
  },
  warningAlert: {
    backgroundColor: '#fff4e5',
    padding: '0.5em',
    margin: '0.7em 0',
    borderRadius: '1px 0px'
  }
}));

const remarkEdit = [
  'Pilih Alasan',
  'Kuantitas produk tersedia tidak mencukupi permintaan SO',
  'Kuantitas produk tersedia tidak sesuai dengan fisik produk yang ada',
  'Ubah nominal diskon',
  'Diskon dikurangi karena harga tidak masuk (Customer sudah menyetujui)',
  'Kuantitas barang dikurangi karena customer mencapai limit',
  'Kuantitas barang ditambah untuk menghabiskan sisa stok'
];

const remarkReject = ['Pilih Alasan', 'Barang belum sampai di Gudang', 'Barang tidak layak jual', 'Barang tidak ketemu dicari'];

const Modal: FC<Props> = props => {
  const classes = useStyles();
  const { history } = useRouter();
  const {
    isAccess,
    openEdit,
    hasOpenModal,
    idPartner,
    setOpenEdit,
    editQtyLoading,
    handleUpdateDiscount,
    discountLoading,
    handleSubmitConvet,
    setHasOpenModal,
    handleDeleteSO,
    deleteSalesOrderItem,
    loadingPrint,
    loadingConvet,
    showComponent,
    handleSubmitColly,
    handletRequestEdit,
    fetchData,
    handleStatusOnSubmit,
    itemColly,
    loadingDataSave,
    salesOrder,
    addItemAction,
    getIdClick,
    handleRejectItem,
    salesOrderItem,
    rejectOrder
  } = props;
  const getSalesOrderItem = salesOrderItem.find(value => value.id === getIdClick);

  const [isExpand, setIsExpand] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [rejectRemark, setRejectRemark] = useState<string>('');
  const [hasDiscount, setHasDiscount] = useState(false);
  const [nameColly, setNameColly] = useState('');
  const [productColly, setProductColly] = useState<string>('');
  const [discountResource, setDiscountResource] = useState<typeDiscount[]>([]);
  const [priceResource, setPriceResource] = useState<typeDiscount[]>([]);
  const [newQty, setNewQty] = useState<number>(0);
  const [notes, setNotes] = useState<string>('');
  const [discount, setDiscount] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);

  const totalPrice = salesOrderItem
    .filter(val => val.status !== 'REJECT')
    .map(val => {
      if (val.id === getIdClick) {
        return newQty * val.price;
      }
      return val.totalItem * val.price;
    })
    .reduce((a, b) => a + b, 0);

  const getWareHouse = () => {
    const newWareHouse = [new Set(salesOrderItem.map(val => val.warehouse))];
    return newWareHouse;
  };

  useEffect(() => {
    setNewQty((getSalesOrderItem && getSalesOrderItem.totalItem) || 0);
    setDiscount((getSalesOrderItem && getSalesOrderItem.discountUnit) || 0);
    setPrice((getSalesOrderItem && getSalesOrderItem.price) || 0);
    setNotes((getSalesOrderItem && getSalesOrderItem.notes) || '');
    setRejectRemark('');
    setNameColly('');
    setProductColly('');
  }, [openEdit]);

  const UpdateItemQty = () => {
    handletRequestEdit(getIdClick, (getSalesOrderItem && getSalesOrderItem.ProductId) || 0, newQty, rejectRemark, remarkCode.EDIT);
  };

  const handleUpdateDiscountQty = () => {
    handleUpdateDiscount(getIdClick, (getSalesOrderItem && getSalesOrderItem.ProductId) || 0, discount, newQty, price, rejectRemark, notes);
  };

  const fetchHistoryDiscount = async () => {
    try {
      const { data } = await axios.get(GET_HISTORY_DISCOUNT(idPartner, (getSalesOrderItem && getSalesOrderItem.ProductId) || 0));
      setDiscountResource(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHistoryPrice = async () => {
    try {
      const { data } = await axios.get(GET_HISTORY_PRICE(idPartner, (getSalesOrderItem && getSalesOrderItem.ProductId) || 0));
      setPriceResource(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (idPartner === 0 || !getSalesOrderItem) return;
    fetchHistoryDiscount();
    fetchHistoryPrice();
  }, [openEdit]);

  return (
    <Dialog
      open={openEdit}
      onClose={() => setOpenEdit(false)}
      fullWidth={showComponent ? true : false}
      style={{ maxHeight: 180 + 'vh', marginTop: showComponent ? '60px' : '0' }}
      scroll='paper'
    >
      <DialogTitle>
        <Grid item container direction='row' justify='space-between' alignItems='center' xs={12}>
          {hasOpenModal === 1 ? (
            <Fragment>
              <Typography className={classes.titleContainer}>Edit Quantity</Typography>
              <Close
                fontSize='small'
                color='action'
                onClick={() => {
                  setOpenEdit(false);
                }}
                style={{ cursor: 'pointer' }}
              />
            </Fragment>
          ) : hasOpenModal === 2 ? (
            <Grid item container direction='column' alignItems='center' xs={12}>
              <WarningRounded style={{ color: YELLOW_MOBILE, fontSize: showComponent ? '3rem' : '3.5rem' }} />
              <Typography className={classes.titleContainer}>Alasan Penolakan Barang</Typography>
            </Grid>
          ) : hasOpenModal === 3 ? (
            <Grid item container direction='column' alignItems='center' xs={12}>
              <WarningRounded style={{ color: YELLOW_MOBILE, fontSize: showComponent ? '3rem' : '3.5rem' }} />
              <Typography className={classes.titleContainer}>
                {isAccess === TypeUser.ADMIN01 ? 'Konfirmasi Quantity Stok Yang Dicek' : 'Konfirmasi Stok Yang Diambil'}
              </Typography>
            </Grid>
          ) : (
            hasOpenModal === 4 && (
              <Grid item container direction='column' alignItems='center' xs={12}>
                <CheckCircle fontSize='large' style={{ color: GREEN_MOBILE, fontSize: showComponent ? '3rem' : '3.5rem' }} />
                <Typography className={classes.titleContainer}>Sukses</Typography>
              </Grid>
            )
          )}

          {hasOpenModal === 6 && (
            <React.Fragment>
              <Grid item container direction='column' alignItems='center' xs={11}>
                <WarningRounded style={{ color: YELLOW_MOBILE, fontSize: showComponent ? '3rem' : '3.5rem' }} />
                <Typography className={classes.titleContainer}>Konfirmasi Koli Siap Packing</Typography>
              </Grid>
              <Grid item container xs={1}>
                <Close
                  fontSize='small'
                  color='action'
                  onClick={() => {
                    setOpenEdit(false);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Grid>
            </React.Fragment>
          )}
          {hasOpenModal === 7 && (
            <React.Fragment>
              <Typography className={classes.titleContainer}>{isAccess === TypeUser.ADMIN03 ? 'Detail Discount' : 'Edit Discount'}</Typography>
              <Close
                fontSize='small'
                color='action'
                onClick={() => {
                  setOpenEdit(false);
                }}
                style={{ cursor: 'pointer' }}
              />
            </React.Fragment>
          )}

          {hasOpenModal === 8 && (
            <React.Fragment>
              <Grid item container direction='column' alignItems='center' xs={11}>
                <WarningRounded style={{ color: YELLOW_MOBILE, fontSize: showComponent ? '3rem' : '3.5rem' }} />
                <Typography className={classes.titleContainer}>Pratinjau Invoice</Typography>
              </Grid>
              <Grid item container xs={1}>
                <Close
                  fontSize='small'
                  color='action'
                  onClick={() => {
                    setOpenEdit(false);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Grid>
            </React.Fragment>
          )}

          {hasOpenModal === 14 && (
            <Grid item container direction='column' alignItems='center' xs={12}>
              <CheckCircle fontSize='large' style={{ color: GREEN_MOBILE, fontSize: showComponent ? '3rem' : '3.5rem' }} />
              <Typography className={classes.titleContainer}>Sukses</Typography>
            </Grid>
          )}
          {hasOpenModal === 10 && (
            <React.Fragment>
              <Typography className={classes.titleContainer}>Edit Produk</Typography>
              <Close
                fontSize='small'
                color='action'
                onClick={() => {
                  setOpenEdit(false);
                }}
                style={{ cursor: 'pointer' }}
              />
            </React.Fragment>
          )}
          {hasOpenModal === 11 && (
            <Grid item container direction='column' alignItems='center' xs={12}>
              <WarningRounded style={{ color: YELLOW_MOBILE, fontSize: showComponent ? '3rem' : '3.5rem' }} />
              <Typography className={classes.titleContainer}>Hapus Item</Typography>
            </Grid>
          )}
          {hasOpenModal === 12 && (
            <Grid item container direction='column' alignItems='center' xs={12}>
              <WarningRounded style={{ color: YELLOW_MOBILE, fontSize: showComponent ? '3rem' : '3.5rem' }} />
              <Typography className={classes.titleContainer}>Hapus Sales Order</Typography>
            </Grid>
          )}

          {hasOpenModal === 13 && (
            <Grid item container direction='column' alignItems='center' xs={12}>
              <WarningRounded style={{ color: YELLOW_MOBILE, fontSize: showComponent ? '3rem' : '3.5rem' }} />
              <Typography className={classes.titleContainer}>Konfirmasi Total Harga Jual </Typography>
            </Grid>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.containerContent}>
        {hasOpenModal === 1 ? (
          <React.Fragment>
            <TextField
              id='name'
              size='small'
              required
              label='Nama Produk'
              InputProps={{
                classes: {
                  input: classes.fontSizeMobile
                }
              }}
              fullWidth
              value={(getSalesOrderItem && getSalesOrderItem.productName) || ''}
              disabled
            />

            <Grid item container direction='row' xs={12} style={{ display: 'flex' }} justify='space-between'>
              <Grid item style={{ flex: 0.55, display: 'flex', alignItems: 'center' }}>
                <TextField
                  id='qty-request'
                  size='small'
                  required
                  label='Qty Request'
                  InputProps={{
                    classes: {
                      input: classes.fontSizeMobile
                    }
                  }}
                  InputLabelProps={{ style: { fontSize: showComponent ? 15 : '1rem' } }}
                  value={(getSalesOrderItem && getSalesOrderItem.totalItem) || 0}
                  disabled
                />
                <Typography className={classes.fontMobile} style={{ marginLeft: '0.5em' }}>
                  {(getSalesOrderItem && getSalesOrderItem.typeUnit) || ''}
                </Typography>
              </Grid>
              <Grid item style={{ flex: 0.45, display: 'flex', alignItems: 'center', paddingLeft: '0.3em' }}>
                <TextField
                  id='edit-qty'
                  size='small'
                  required
                  label='Edit Qty'
                  autoComplete='off'
                  InputProps={{
                    classes: {
                      input: classes.fontSizeMobile
                    },
                    inputMode: 'numeric'
                  }}
                  InputLabelProps={{ style: { fontSize: showComponent ? 15 : '1rem' } }}
                  value={newQty}
                  error={
                    newQty > ((getSalesOrderItem && getSalesOrderItem.totalItem) || 0) + ((getSalesOrderItem && getSalesOrderItem.salesStock) || 0) ||
                    newQty < 0
                  }
                  onChange={e => setNewQty(Number(e.target.value))}
                  autoFocus={true}
                />
                <Typography className={classes.fontMobile} style={{ marginLeft: '0.5em' }}>
                  {(getSalesOrderItem && getSalesOrderItem.typeUnit) || ''}
                </Typography>
              </Grid>
              {newQty < 0 && (
                <Grid xs={12} container justify='flex-end'>
                  <FormHelperText error={true}>Jumlah qty tidak bisa kecil dari 0</FormHelperText>
                </Grid>
              )}

              {newQty > ((getSalesOrderItem && getSalesOrderItem.totalItem) || 0) + ((getSalesOrderItem && getSalesOrderItem.salesStock) || 0) ? (
                <Grid xs={12} container justify='flex-end'>
                  <FormHelperText error={true}>
                    Qty melebihi stok jual (
                    {((getSalesOrderItem && getSalesOrderItem.totalItem) || 0) + ((getSalesOrderItem && getSalesOrderItem.salesStock) || 0)})
                  </FormHelperText>
                </Grid>
              ) : (
                // : (salesOrder.plafon || 0) > 0 && totalPrice > (salesOrder.plafon || 0) ? (
                //   <Grid xs={12} container justify='flex-end'>
                //     <FormHelperText error={true}>
                //       Total Harga (
                //       <NumberFormat value={totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                //       ) melebihi batas plafon (
                //       <NumberFormat value={salesOrder.plafon || 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />)
                //     </FormHelperText>
                //   </Grid>
                // )

                newQty > 0 && (
                  <Grid xs={12} container justify='flex-end'>
                    <FormHelperText
                      style={{
                        color: BLACK
                      }}
                    >
                      Total Stock Jual: (
                      {((getSalesOrderItem && getSalesOrderItem.totalItem) || 0) + ((getSalesOrderItem && getSalesOrderItem.salesStock) || 0)})
                    </FormHelperText>
                  </Grid>
                )
              )}
              <Grid xs={12}>
                <TextField
                  select
                  size='small'
                  id='edit'
                  value={rejectRemark}
                  label='Alasan Pengeditan'
                  InputProps={{
                    classes: {
                      input: classes.fontSizeMobile
                    }
                  }}
                  autoComplete='off'
                  fullWidth
                  onChange={event => {
                    setRejectRemark(event.target.value);
                  }}
                >
                  {remarkEdit.map((value: string, key: number) => (
                    <MenuItem key={key} value={value} selected={key === 0} disabled={key === 0} className={classes.fontSizeMobile}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : hasOpenModal === 2 ? (
          <Grid xs={12}>
            <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
              Gudang
            </Typography>
            <Typography className={classes.fontSizeMobile} color='primary' style={{ fontWeight: 500 }}>
              {salesOrderItem.filter(val => val.id === getIdClick).map(val => val.warehouse)}
            </Typography>
            <Grid item container direction='row' justify='space-between' alignItems='center' xs={12}>
              <Grid xs={7}>
                <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
                  Nama
                </Typography>
                <Typography className={classes.fontMobile}>
                  {salesOrderItem.filter(val => val.id === getIdClick).map(val => val.productName)}
                </Typography>
              </Grid>
              <Grid xs={2}>
                <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
                  Qty
                </Typography>
                <Typography className={classes.fontMobile}>
                  {salesOrderItem.filter(val => val.id === getIdClick).map(val => val.totalItem.toString().concat(` ${val.typeUnit}`))}
                </Typography>
              </Grid>
              <Grid xs={3} container justify='flex-end'>
                <img src={getSalesOrderItem && getSalesOrderItem.productImage} alt='Item Image' width='40px' height='40px' />
              </Grid>
            </Grid>
            <Grid xs={12}>
              <TextField
                select
                size='small'
                id='edit'
                value={rejectRemark}
                label='Alasan Penolakan'
                InputProps={{
                  classes: {
                    input: classes.fontSizeMobile
                  }
                }}
                autoComplete='off'
                fullWidth
                onChange={event => {
                  setRejectRemark(event.target.value);
                }}
              >
                {remarkReject.map((value: string, key: number) => (
                  <MenuItem key={key} value={value} selected={key === 0} disabled={key === 0} className={classes.fontSizeMobile}>
                    {value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        ) : hasOpenModal === 3 ? (
          <React.Fragment>
            <Grid item container direction='row' justify='space-between' xs={12}>
              <Grid item xs={6}>
                <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
                  Gudang
                </Typography>
                <Typography className={classes.fontSizeMobile} color='primary' style={{ fontWeight: 500 }}>
                  {getWareHouse()}
                </Typography>
              </Grid>

              <Grid item container direction='column' alignItems='flex-end' xs={6}>
                {isAccess === TypeUser.PICKER ? (
                  <Typography className={classes.fontMobile}>
                    Total Disetujui: {rejectOrder.filter(val => val.status === SalesOrderStatus.PREVIEW).length || 0}
                  </Typography>
                ) : (
                  <Typography className={classes.fontMobile}>
                    Total Disetujui: {rejectOrder.filter(val => val.status === SalesOrderStatus.PACKING).length || 0}
                  </Typography>
                )}
                <Typography className={classes.fontMobile}>
                  Total Ditolak: {rejectOrder.filter(val => val.status === SalesOrderStatus.REJECT).length}
                </Typography>
              </Grid>
            </Grid>

            <Divider style={{ margin: '5px 0' }} />

            <React.Fragment>
              {salesOrderItem
                .filter(val => rejectOrder.some(value => value.id === val.id))
                .map((val, key: number) => (
                  <Fragment key={key}>
                    <Grid item container direction='row' justify='space-between' alignItems='flex-start' xs={12}>
                      <Grid item xs={2}>
                        <img src={val.productImage} alt='Item Image' width='40px' height='40px' />
                      </Grid>

                      <Grid item xs={10}>
                        <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
                          {val.productName}
                        </Typography>
                        <Typography className={classes.fontMobile}>Stock Diambil: {val.totalItem + ' ' + val.typeUnit} </Typography>
                        {rejectOrder.find(value => value.id === val.id)!.status === SalesOrderStatus.PREVIEW ||
                        rejectOrder.find(value => value.id === val.id)!.status === SalesOrderStatus.PACKING ? (
                          <Typography className={classes.fontMobile}>
                            Status: <span style={{ color: GREEN_MOBILE }}> DISETUJUI</span>
                          </Typography>
                        ) : (
                          <Grid item xs={12} container direction='row'>
                            <ErrorOutlineRounded style={{ color: YELLOW_MOBILE }} />
                            <Typography className={classes.fontMobile} style={{ fontWeight: 500, paddingLeft: '5px' }}>
                              Alasan Penolakan
                              <span style={{ display: 'block', fontWeight: 'normal' }}>
                                {rejectOrder.find(value => value.id === val.id)!.remark || 'Tidak ada alasan'}
                              </span>
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      <Divider />
                    </Grid>
                    <Divider style={{ margin: '5px 0' }} />
                  </Fragment>
                ))}
            </React.Fragment>
          </React.Fragment>
        ) : hasOpenModal === 4 ? (
          <Grid xs={12} container direction='row' justify='center'>
            <Grid item xs={10}>
              <Typography className={classes.fontMobile} align='center'>
                {isAccess === TypeUser.ADMIN01 && 'Kuantiti barang di SO berhasil dikonfirmasi. SO menunggu persiapan packing'}
                {isAccess === TypeUser.PICKER && 'Konfirmasi stok yang diambil di SO berhasil disimpan. SO menunggu peninjauan Admin selanjutnya. '}
                {isAccess === TypeUser.ADMIN02 && 'Data koli berhasil di konfirmasi, SO menunggu convert invoice oleh finance.'}
              </Typography>
            </Grid>
          </Grid>
        ) : hasOpenModal === 6 ? (
          <React.Fragment>
            <Grid item className={classes.control} container direction='row' alignItems='center' justify='space-between'>
              <Grid item>
                <Typography className={(classes.fontMobile, classes.fontSizeMobile)} style={{ fontWeight: 500 }}>
                  Nama Koli : {nameColly}
                </Typography>
                <Typography className={(classes.fontMobile, classes.fontSizeMobile)}>Nama Barang : {productColly}</Typography>
              </Grid>
              {!isExpand ? <ExpandMoreRounded onClick={() => setIsExpand(true)} /> : <ExpandLessRounded onClick={() => setIsExpand(false)} />}
            </Grid>
            {!isExpand &&
              itemColly.map(val => (
                <Grid item container direction='row' alignItems='center' justify='space-between' xs={12}>
                  <Grid item xs={2}>
                    <img src={val.imageUrl} alt='Item Image' width='30px' height='30px' />
                  </Grid>
                  <Grid xs={10}>
                    <Typography className={(classes.fontMobile, classes.fontSizeMobile)} style={{ fontWeight: 500 }}>
                      {val.productName}
                    </Typography>
                    <Typography className={(classes.fontMobile, classes.fontSizeMobile)} style={{ color: PRIMARY_MOBILE }}>
                      Total qty: {val.totalItem}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
          </React.Fragment>
        ) : (
          hasOpenModal === 7 &&
          isAccess === TypeUser.SUPERADMIN && (
            <React.Fragment>
              <TextField
                id='name'
                size='small'
                required
                label='Nama Produk'
                InputProps={{
                  classes: {
                    input: classes.fontSizeMobile
                  }
                }}
                fullWidth
                value='Blender Homey Pelastik B2PC 7 Tabung'
                disabled
              />
              <Grid item container direction='row' xs={12} justify='space-between' style={{ display: 'flex' }}>
                <Grid item container direction='row' alignItems='center' style={{ flex: 0.48 }}>
                  <TextField
                    id='qty-request'
                    size='small'
                    required
                    label='Qty'
                    InputProps={{
                      classes: {
                        input: classes.fontSizeMobile
                      }
                    }}
                    InputLabelProps={{ style: { fontSize: showComponent ? 15 : '1rem' } }}
                    value={5}
                  />
                </Grid>
                <Grid item container direction='row' alignItems='center' justify='flex-start' style={{ flex: 0.46 }}>
                  <TextField
                    id='edit-qty'
                    size='small'
                    required
                    label='Edit Discount'
                    InputProps={{
                      classes: {
                        input: classes.fontSizeMobile
                      }
                    }}
                    InputLabelProps={{ style: { fontSize: showComponent ? 15 : '1rem' } }}
                    value='Rp50,000'
                    autoFocus={true}
                  />
                </Grid>
                <Grid xs={12} container alignItems='center' className={classes.warningAlert}>
                  <InfoOutlined style={{ color: '#ff9800' }} />
                  <Typography className={classes.fontSizeMobile}>Diskon Per Item Rp.23.000</Typography>
                </Grid>
              </Grid>

              <TextField
                select
                size='small'
                id='edit'
                value=''
                label='Alasan Edit'
                InputProps={{
                  classes: {
                    input: classes.fontSizeMobile
                  }
                }}
                InputLabelProps={{ style: { fontSize: showComponent ? 0.7 + 'rem' : '1rem' } }}
                autoComplete='off'
                fullWidth
              >
                <MenuItem key={1} value='' selected disabled className={classes.fontSizeMobile}>
                  Pilih Alasan
                </MenuItem>
                <MenuItem key={2} value='PENDING' className={classes.fontSizeMobile}>
                  Barang fisik rusak
                </MenuItem>
                <MenuItem key={3} value='CONFIRMATION' className={classes.fontSizeMobile}>
                  Barang fisik rusak
                </MenuItem>
                <MenuItem key={4} value='CANCEL' className={classes.fontSizeMobile}>
                  Barang fisik rusak
                </MenuItem>
                <MenuItem key={5} value='CANCEL' className={classes.fontSizeMobile}>
                  Lain nya
                </MenuItem>
              </TextField>
            </React.Fragment>
          )
        )}

        {hasOpenModal === 7 && isAccess === TypeUser.ADMIN03 && (
          <Grid xs={12}>
            <Grid className={classes.itemContainer}>
              <Typography className={(classes.fontMobile, classes.fontSizeMobile)} style={{ fontWeight: 500, color: PRIMARY_MOBILE }}>
                Details Produk Awal
              </Typography>
              <Grid container style={{ marginTop: '0.8em' }}>
                <Grid xs={5} item>
                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)}>Nama Produk</Typography>
                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)} style={{ fontWeight: 500 }}>
                    {getSalesOrderItem && getSalesOrderItem.productName}
                  </Typography>
                </Grid>
                <Grid xs={2} item>
                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)}>Total Qty</Typography>
                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)} style={{ fontWeight: 500 }}>
                    {getSalesOrderItem && getSalesOrderItem.totalItem + ' ' + getSalesOrderItem.typeUnit}
                  </Typography>
                </Grid>
                <Grid xs={2} item>
                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)} align='right'>
                    Diskon/Qty
                  </Typography>

                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)} align='right' style={{ fontWeight: 500 }}>
                    <NumberFormat
                      value={getSalesOrderItem && getSalesOrderItem.discount / getSalesOrderItem.totalItem}
                      prefix={'Rp'}
                      thousandSeparator={true}
                      displayType='text'
                    />
                  </Typography>
                </Grid>
                <Grid xs={3} item>
                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)} align='right'>
                    Total Diskon
                  </Typography>

                  <Typography
                    className={(classes.fontMobile, classes.fontSizeMobile)}
                    align='right'
                    style={{ color: YELLOW_MOBILE, fontWeight: 500 }}
                  >
                    <NumberFormat value={getSalesOrderItem && getSalesOrderItem.discount} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.itemContainer}>
              <Typography className={(classes.fontMobile, classes.fontSizeMobile)} style={{ fontWeight: 500, color: PRIMARY_MOBILE }}>
                Details Produk Akhir
              </Typography>
              <Grid container style={{ marginTop: '0.8em' }}>
                <Grid xs={5} item>
                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)}>Nama Produk</Typography>
                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)} style={{ fontWeight: 500 }}>
                    {getSalesOrderItem && getSalesOrderItem.productName}
                  </Typography>
                </Grid>
                <Grid xs={2} item>
                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)}>Total Qty</Typography>
                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)} style={{ fontWeight: 500 }}>
                    {getSalesOrderItem && getSalesOrderItem.totalItem + ' ' + getSalesOrderItem.typeUnit}
                  </Typography>
                </Grid>
                <Grid xs={2} item>
                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)} align='right'>
                    Diskon/Qty
                  </Typography>

                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)} align='right' style={{ fontWeight: 500 }}>
                    <NumberFormat
                      value={getSalesOrderItem && getSalesOrderItem.discount / getSalesOrderItem.totalItem}
                      prefix={'Rp'}
                      thousandSeparator={true}
                      displayType='text'
                    />
                  </Typography>
                </Grid>
                <Grid xs={3} item>
                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)} align='right'>
                    Total Diskon
                  </Typography>

                  <Typography
                    className={(classes.fontMobile, classes.fontSizeMobile)}
                    align='right'
                    style={{ color: YELLOW_MOBILE, fontWeight: 500 }}
                  >
                    <NumberFormat value={getSalesOrderItem && getSalesOrderItem.discount} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {hasOpenModal === 8 && (
          <Grid xs={12} container>
            {salesOrderItem.map(val => (
              <Grid item container direction='row' className={classes.itemContainer} xs={12}>
                <Grid item xs={3}>
                  <img src={getSalesOrderItem && getSalesOrderItem.productImage} alt='Item Image' width='40px' height='40px' />
                </Grid>
                <Grid xs={9} item>
                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)} style={{ fontWeight: 500, marginLeft: '4px' }}>
                    {val.productName}
                  </Typography>
                </Grid>

                <Grid item xs={12} style={{ marginTop: 5 + 'px' }}>
                  <Divider />
                </Grid>
                <Grid xs={5} item style={{ marginTop: '5px' }}>
                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)} color='textSecondary'>
                    Harga
                  </Typography>
                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)} color='textSecondary'>
                    Kuantitas
                  </Typography>
                  <Typography
                    className={(classes.fontMobile, classes.fontSizeMobile)}
                    color='textSecondary'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    Diskon <InfoOutlined fontSize='small' style={{ color: YELLOW_MOBILE, marginLeft: '5px' }} />
                  </Typography>
                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)} color='textSecondary'>
                    Total Harga
                  </Typography>
                </Grid>

                <Grid xs={7} item container direction='column' alignItems='flex-end' style={{ marginTop: '5px' }}>
                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)}>Rp {val.price}</Typography>
                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)}>
                    {val.totalItem} {val.typeUnit}
                  </Typography>
                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)} style={{ color: YELLOW_MOBILE }}>
                    Rp{val.discount}
                  </Typography>
                  <Typography className={(classes.fontMobile, classes.fontSizeMobile)}>Rp{val.totalPrice}</Typography>
                </Grid>
              </Grid>
            ))}
            <Grid xs={12}>
              <Typography className={classes.titleSizeMobile}>Keterangan</Typography>
              <Typography className={classes.fontMobile}>Pratinjau oleh Cadis Hangoluan (Gudang) pada tanggal 06 Oktober 2021, 13:31 WIB</Typography>
              <Grid container direction='row' style={{ marginTop: 14 + 'px' }}>
                <Grid xs={7} container direction='column' alignItems='flex-end'>
                  <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
                    Subtotal
                  </Typography>
                  <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
                    Diskon
                  </Typography>
                  <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
                    Total Harga
                  </Typography>
                  <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
                    Lunas
                  </Typography>
                  <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
                    Jumlah Tagihan
                  </Typography>
                </Grid>
                <Grid xs={5} container direction='column' alignItems='flex-end'>
                  <Typography className={classes.fontMobile}>Rp50.000</Typography>
                  <Typography className={classes.fontMobile}>Rp5.000</Typography>
                  <Typography className={classes.fontMobile}>Rp50.000</Typography>
                  <Typography className={classes.fontMobile}>Rp0</Typography>
                  <Typography className={classes.fontMobile}>Rp500.000</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {hasOpenModal === 14 && (
          <Grid xs={12}>
            <Typography align='center' className={(classes.fontMobile, classes.fontSizeMobile)}>
              Apakah kamu yakin ingin ke Halaman Invoice ?
            </Typography>
          </Grid>
        )}
        {hasOpenModal === 10 && (
          <Grid xs={12}>
            <TextField
              label='Produk'
              fullWidth
              id='outlined-size-small'
              value={getSalesOrderItem && getSalesOrderItem.productName}
              variant='outlined'
              multiline
              size='small'
              disabled
            />

            <FormControl variant='outlined' size='small' required fullWidth style={{ margin: '0.5em 0' }}>
              <InputLabel htmlFor='outlined-adornment-amount'>Harga per {getSalesOrderItem && getSalesOrderItem.typeUnit}</InputLabel>
              <OutlinedInput
                id='outlined-adornment-amount'
                startAdornment={<InputAdornment position='start'>Rp</InputAdornment>}
                labelWidth={120}
                autoComplete='off'
                error={price < ((getSalesOrderItem && getSalesOrderItem.price) || 0)}
                value={Number.isNaN(price) ? 0 : new Intl.NumberFormat('id-ID').format(price)}
                onChange={e => {
                  if (e.target.value === '' && Number.isNaN(price)) {
                    setPrice(0);
                  } else {
                    setPrice(
                      Number(
                        e.target.value
                          .split('.')
                          .join('')
                          .replace(/\,/g, '')
                      )
                    );
                  }
                }}
              />
            </FormControl>
            {price < ((getSalesOrderItem && getSalesOrderItem.price) || 0) && (
              <FormHelperText error={true}>
                Harga per {getSalesOrderItem && getSalesOrderItem.typeUnit} tidak boleh kurang dari harga rute
              </FormHelperText>
            )}

            <Grid xs={12} container justify='space-between' className={classes.containerRow}>
              <Grid style={{ flex: 0.47 }}>
                <FormControl variant='outlined' size='small' required>
                  <InputLabel htmlFor='outlined-adornment-amount'>Qty</InputLabel>
                  <OutlinedInput
                    id='outlined-adornment-amount'
                    value={newQty}
                    autoComplete='off'
                    error={
                      newQty >
                        ((getSalesOrderItem && getSalesOrderItem.totalItem) || 0) + ((getSalesOrderItem && getSalesOrderItem.salesStock) || 0) ||
                      newQty < 1
                    }
                    endAdornment={
                      <InputAdornment position='end' style={{ color: BLACK }}>
                        {getSalesOrderItem && getSalesOrderItem.typeUnit}
                      </InputAdornment>
                    }
                    onChange={e => setNewQty(Number(e.target.value))}
                    labelWidth={40}
                  />
                </FormControl>
                {newQty > ((getSalesOrderItem && getSalesOrderItem.totalItem) || 0) + ((getSalesOrderItem && getSalesOrderItem.salesStock) || 0) && (
                  <FormHelperText style={{ marginLeft: '5px' }} error={true}>
                    Melebihi jumlah stok (
                    {((getSalesOrderItem && getSalesOrderItem.totalItem) || 0) + ((getSalesOrderItem && getSalesOrderItem.salesStock) || 0)})
                  </FormHelperText>
                )}

                {newQty < 1 && (
                  <FormHelperText style={{ marginLeft: '5px' }} error={true}>
                    Qty tidak boleh kurang dari 1
                  </FormHelperText>
                )}
              </Grid>
              <Grid style={{ flex: 0.47 }}>
                <FormControl variant='outlined' size='small' required>
                  <InputLabel htmlFor='outlined-adornment-amount'>Diskon per {getSalesOrderItem && getSalesOrderItem.typeUnit}</InputLabel>
                  <OutlinedInput
                    id='outlined-adornment-amount'
                    startAdornment={<InputAdornment position='start'>Rp</InputAdornment>}
                    labelWidth={130}
                    autoComplete='off'
                    error={discount >= price}
                    autoFocus={true}
                    onFocus={() => setHasDiscount(true)}
                    value={Number.isNaN(discount) ? 0 : new Intl.NumberFormat('id-ID').format(discount)}
                    onChange={e => {
                      if (e.target.value === '' && Number.isNaN(discount)) {
                        setDiscount(0);
                      } else {
                        setDiscount(
                          Number(
                            e.target.value
                              .split('.')
                              .join('')
                              .replace(/\,/g, '')
                          )
                        );
                      }
                    }}
                  />
                </FormControl>
                {discount >= price && (
                  <FormHelperText style={{ marginLeft: '5px' }} error={true}>
                    Diskon per {getSalesOrderItem && getSalesOrderItem.typeUnit} tidak boleh melebihi atau sama dengan harga produk
                  </FormHelperText>
                )}
              </Grid>
            </Grid>

            {discountResource.length > 0 && (
              <Grid xs={12} style={{ margin: '0.5em 0' }}>
                <Typography variant='h6' style={{ color: YELLOW_MOBILE, fontWeight: 500 }}>
                  Riwayat 3 penjualan terakhir:
                </Typography>
                <Grid xs={12} container justify='space-between'>
                  <Grid xs={4}>
                    <Typography style={{ color: 'lightgrey' }}>Harga/item sebelum disc</Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Typography style={{ color: 'lightgrey' }}>Diskon/</Typography>
                    <Typography style={{ color: 'lightgrey' }}>item</Typography>
                  </Grid>
                  <Grid xs={1}>
                    <Typography style={{ color: 'lightgrey' }}>Qty</Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Typography style={{ color: 'lightgrey' }}>Harga/item sesudah disc</Typography>
                  </Grid>
                  {discountResource.map((value, key) => (
                    <Grid xs={12} container justify='space-between' key={key}>
                      <Grid xs={4}>
                        <Typography>
                          <NumberFormat value={value.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                        </Typography>
                        <Typography style={{ color: 'lightgrey', fontSize: '12px' }}>{format(new Date(value.orderDate), 'dd-MM-yyyy')}</Typography>
                      </Grid>
                      <Grid xs={3}>
                        <Typography>
                          <NumberFormat value={value.discountUnit} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                        </Typography>
                      </Grid>
                      <Grid xs={1}>
                        <Typography>{value.totalItem}</Typography>
                      </Grid>
                      <Grid xs={3}>
                        <Typography>
                          <NumberFormat value={value.price - value.discountUnit} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}

            {discountResource.length < 3 && discountResource.length > 0 && (
              <Typography style={{ color: 'lightgrey' }}>Mitra ini belum memiliki 3 penjualan sampai saat ini</Typography>
            )}

            <TextField
              label='Total Diskon'
              fullWidth
              id='outlined-size-small'
              value={Number.isNaN(discount) ? 0 : new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(newQty * discount)}
              variant='outlined'
              size='small'
              disabled
            />

            <TextField
              label='Total Harga'
              fullWidth
              id='outlined-size-small'
              value={new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(price * newQty - newQty * discount)}
              variant='outlined'
              size='small'
              disabled
            />
            <TextField
              select
              label='Alasan Edit'
              autoComplete='off'
              fullWidth
              value={rejectRemark}
              id='outlined-size-small'
              defaultValue='Small'
              variant='outlined'
              onChange={event => setRejectRemark(event.target.value)}
              size='small'
            >
              {remarkEdit.map((value: string, key: number) => (
                <MenuItem key={key} value={value} selected={key === 0} disabled={key === 0} className={classes.fontSizeMobile}>
                  {value}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label='Catatan'
              placeholder='Masukkan catatan disini'
              multiline
              fullWidth
              onChange={event => setNotes(event.target.value)}
              rows={4}
              value={notes}
              defaultValue='Default Value'
              variant='outlined'
            />
          </Grid>
        )}
        {hasOpenModal === 11 && (
          <Grid xs={12}>
            <Typography align='center' className={(classes.fontMobile, classes.fontSizeMobile)}>
              Apakah kamu yakin ingin menghapus item ini?
            </Typography>
          </Grid>
        )}
        {hasOpenModal === 12 && (
          <Grid xs={12}>
            <Typography align='center' className={(classes.fontMobile, classes.fontSizeMobile)}>
              Apakah kamu yakin ingin menghapus Sales Order ini secara keseluruhan?
            </Typography>
          </Grid>
        )}
        {hasOpenModal === 13 && (
          <Grid xs={12}>
            <Typography align='center' className={(classes.fontMobile, classes.fontSizeMobile)}>
              Total Harga Jual yang ditawarkan dibawah
            </Typography>
            <Typography align='center' className={(classes.fontMobile, classes.fontSizeMobile)}>
              Total Harga rute saat ini.
            </Typography>
          </Grid>
        )}
      </DialogContent>
      <DialogActions className={classes.containerAction}>
        <Grid item container direction='row' justify='space-evenly' alignItems='center'>
          {hasOpenModal === 2 && (
            <React.Fragment>
              <Grid xs={5}>
                <Button
                  size='small'
                  fullWidth
                  className={classes.button}
                  color='primary'
                  disabled={rejectRemark === ''}
                  onClick={() => {
                    addItemAction({ id: getIdClick || 0, status: SalesOrderStatus.REJECT, remark: rejectRemark, remarkCode: remarkCode.REJECT });
                    handleRejectItem(getIdClick || 0);
                    setLoading(false);
                    setOpenEdit(false);
                    setHasOpenModal(0);
                  }}
                >
                  Simpan
                </Button>
              </Grid>

              <Grid xs={5}>
                <Button size='small' fullWidth className={classes.button} variant='outlined' onClick={() => setOpenEdit(false)}>
                  Batal
                </Button>
              </Grid>
            </React.Fragment>
          )}

          {hasOpenModal === 1 && (
            <Grid xs={11}>
              <Button
                size='small'
                fullWidth
                className={classes.button}
                color='primary'
                onClick={() => UpdateItemQty()}
                disabled={
                  rejectRemark === '' ||
                  newQty < 0 ||
                  newQty === (getSalesOrderItem && getSalesOrderItem.totalItem) ||
                  newQty > ((getSalesOrderItem && getSalesOrderItem.totalItem) || 0) + ((getSalesOrderItem && getSalesOrderItem.salesStock) || 0)
                  //||((salesOrder.plafon || 0) > 0 && totalPrice > (salesOrder.plafon || 0))
                }
              >
                {editQtyLoading && <CircularProgress size={20} color='secondary' />}
                {!editQtyLoading && 'Simpan'}
              </Button>
            </Grid>
          )}

          {hasOpenModal === 3 && (
            <Grid item container direction='row' justify='space-evenly' alignItems='center'>
              <Grid xs={5}>
                <Button size='small' fullWidth className={classes.button} variant='outlined' onClick={() => setOpenEdit(false)}>
                  Batal
                </Button>
              </Grid>

              <Grid xs={5}>
                <Button
                  size='small'
                  fullWidth
                  className={classes.button}
                  color='primary'
                  onClick={() => {
                    handleStatusOnSubmit();
                  }}
                >
                  {loadingDataSave && <CircularProgress size={20} color='secondary' />}
                  {!loadingDataSave && 'Konfirmasi'}
                </Button>
              </Grid>
            </Grid>
          )}

          {hasOpenModal === 4 && (
            <Grid xs={11}>
              <Button
                size='small'
                fullWidth
                className={classes.button}
                color='primary'
                onClick={() => {
                  if (isAccess === TypeUser.ADMIN02) {
                    fetchData();
                    setOpenEdit(false);
                  } else {
                    history.push('/penjualan');
                  }
                }}
              >
                Tutup
              </Button>
            </Grid>
          )}

          {hasOpenModal === 6 && (
            <Grid xs={11}>
              <Button size='small' fullWidth className={classes.button} color='primary' onClick={() => handleSubmitColly()}>
                {loadingPrint && <CircularProgress size={20} color='secondary' />}
                {!loadingPrint && 'KONFIRMASI '}
              </Button>
            </Grid>
          )}

          {hasOpenModal === 7 && isAccess === TypeUser.SUPERADMIN && (
            <Grid xs={11}>
              <Button size='small' fullWidth className={classes.button} color='primary' onClick={() => console.log('jajaj')}>
                Simpan
              </Button>
            </Grid>
          )}

          {hasOpenModal === 7 && isAccess === TypeUser.ADMIN03 && (
            <Grid xs={11}>
              <Button size='small' fullWidth className={classes.button} color='primary' onClick={() => setOpenEdit(false)}>
                Tutup
              </Button>
            </Grid>
          )}

          {hasOpenModal === 8 && (
            <Grid xs={11}>
              <Button size='small' fullWidth className={classes.button} color='primary' onClick={handleSubmitConvet}>
                {loadingConvet && <CircularProgress size={20} color='secondary' />}
                {!loadingConvet && 'Create Invoice Sekarang'}
              </Button>
            </Grid>
          )}

          {hasOpenModal === 14 && (
            <Grid item container direction='row' justify='space-evenly' alignItems='center' xs={12}>
              <Grid xs={5}>
                <Button
                  size='small'
                  fullWidth
                  variant='outlined'
                  color='primary'
                  className={classes.cancelButtonStyle}
                  onClick={() => {
                    setOpenEdit(false);
                  }}
                >
                  Tidak
                </Button>
              </Grid>

              <Grid xs={6}>
                <Button
                  size='small'
                  fullWidth
                  variant='contained'
                  className={classes.cancelButtonStyle}
                  color='primary'
                  onClick={() => console.log('')}
                >
                  Iya
                </Button>
              </Grid>
            </Grid>
          )}

          {hasOpenModal === 10 && (
            <Grid xs={11}>
              <Button
                size='small'
                fullWidth
                className={classes.button}
                color='primary'
                onClick={() => handleUpdateDiscountQty()}
                disabled={
                  rejectRemark === '' ||
                  newQty > ((getSalesOrderItem && getSalesOrderItem.totalItem) || 0) + ((getSalesOrderItem && getSalesOrderItem.salesStock) || 0) ||
                  discount >= price ||
                  newQty < 1 ||
                  price < ((getSalesOrderItem && getSalesOrderItem.price) || 0)
                }
              >
                {!discountLoading && 'Simpan'}
                {discountLoading && <CircularProgress size={20} color='secondary' />}
              </Button>
            </Grid>
          )}

          {hasOpenModal === 11 && (
            <Grid item container direction='row' justify='space-evenly' alignItems='center'>
              <Grid xs={5}>
                <Button size='small' fullWidth className={classes.button} variant='outlined' onClick={() => setOpenEdit(false)}>
                  Batal
                </Button>
              </Grid>

              <Grid xs={5}>
                <Button size='small' fullWidth className={classes.button} color='primary' onClick={() => deleteSalesOrderItem()}>
                  {loadingPrint && <CircularProgress size={20} color='secondary' />}
                  {!loadingPrint && 'Hapus'}
                </Button>
              </Grid>
            </Grid>
          )}

          {hasOpenModal === 12 && (
            <Grid item container direction='row' justify='space-evenly' alignItems='center'>
              <Grid xs={5}>
                <Button
                  size='small'
                  fullWidth
                  className={classes.button}
                  variant='outlined'
                  onClick={() => {
                    setOpenEdit(false);
                  }}
                >
                  Batal
                </Button>
              </Grid>

              <Grid xs={5}>
                <Button size='small' fullWidth className={classes.button} color='primary' onClick={() => handleDeleteSO()}>
                  {loadingPrint && <CircularProgress size={20} color='secondary' />}
                  {!loadingPrint && 'Hapus'}
                </Button>
              </Grid>
            </Grid>
          )}
          {hasOpenModal === 13 && (
            <Grid item container direction='row' justify='space-evenly' alignItems='center' xs={12} style={{ marginBottom: '13px' }}>
              <Button size='small' style={{ width: '91px' }} color='secondary' onClick={() => setOpenEdit(false)}>
                Batal
              </Button>

              <Button size='small' color='primary' onClick={() => console.log('')}>
                Konfirmasi
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
