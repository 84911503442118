import React, { FC, useState } from 'react';
import { Dialog, DialogContent, Button, DialogActions, createStyles, makeStyles, Theme } from '@material-ui/core';
import ProductPackageWizard from './components/ProductPackageWizard';
import { DialogTitle } from 'components/Dialog';

interface Props {
  products: ProductModel[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setProducts: React.Dispatch<React.SetStateAction<ProductModel[]>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string, link?: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPackage: {
      margin: '0 auto',
      width: 859,
      height: 523,
      padding: 24
    }
  })
);

const ProductModal: FC<Props> = props => {
  const classes = useStyles();
  const { products, open, setOpen, setProducts, handleSnackBar } = props;
  const [isSubmit, setSubmit] = useState<boolean>(false);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOnSubmit = () => {
    setSubmit(true);
  };

  return (
    <Dialog classes={{ paper: classes.dialogPackage }} maxWidth={false} disableBackdropClick={true} open={open}>
      <DialogTitle>Tambah Paket</DialogTitle>
      <DialogContent>
        <ProductPackageWizard
          isSubmit={isSubmit}
          setSubmit={setSubmit}
          setProducts={setProducts}
          products={products}
          handleSnackBar={handleSnackBar}
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={isSubmit} color='secondary' onClick={handleCancel}>
          Batal
        </Button>
        <Button disabled={isSubmit} onClick={handleOnSubmit}>
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductModal;
