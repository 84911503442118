import React, { FC } from 'react';
import { TableBody, TableHead, TableRow, TableCell, TableContainer, makeStyles } from '@material-ui/core';
import BodyRow from './components/BodyRow';
import { TableCustom } from 'components/Table';
import { dummyStockCheckItem } from 'utils/dummy';

interface Props {
  isLoadingData: boolean;
  isUpdatePrice: boolean;
  items: StockCheckItemModel[];
  isFirstStock: boolean;
}

const useStyles = makeStyles({
  container: {
    maxHeight: 440
  }
});

const ProductTable: FC<Props> = props => {
  const classes = useStyles();
  const { isLoadingData, items, isUpdatePrice, isFirstStock } = props;

  return (
    <TableContainer className={classes.container}>
      <TableCustom stickyHeader size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Produk</TableCell>
            <TableCell align='center'>Satuan</TableCell>
            <TableCell align='center'>Stok Awal </TableCell>
            <TableCell align='center'>Stok Berjalan</TableCell>
            <TableCell align='center'>HPP</TableCell>
            <TableCell align='center'>Stok Adj</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow
                key={index}
                index={index}
                isLoading={isLoadingData}
                isUpdatePrice={isUpdatePrice}
                isFirstStock={isFirstStock}
                item={dummyStockCheckItem}
              />
            ))
          ) : items && items.length > 0 ? (
            items.map((value, index) => (
              <BodyRow key={index} index={index} isLoading={isLoadingData} isFirstStock={isFirstStock} isUpdatePrice={isUpdatePrice} item={value} />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={isFirstStock ? 5 : 7} align='center'>
                Data Tidak Tersedia.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default ProductTable;
