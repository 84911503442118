import React, { FC, Fragment, useContext } from 'react';
import { TableRow, TableCell, TextField, CircularProgress, IconButton, Tooltip, Grid } from '@material-ui/core';
import { Skeleton, Autocomplete } from '@material-ui/lab';
import NumberFormatMask from 'components/NumberFormatMask';
import TypeUser from 'typings/enum/TypeUser';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import useRole from 'hooks/useRole';
import EditItem from 'images/icon/EditItem.svg';
import CloseCircleItem from 'images/icon/CloseCircleItem.svg';
import { CardProduct } from 'components';
import { YELLOW_MOBILE } from 'constants/colors';
import { InfoOutlined } from '@material-ui/icons';

interface Props {
  product: ProductModel;
  products: ProductModel[];
  productAdditionalDamage: number;
  price: number;
  totalItem: number;
  handleOpenReturn: (isReturn: boolean) => void;
  typeUnit: string;
  typeReturn: string;
  discount: number;
  totalPrice: number;
  loadProduct: boolean;
  index: number;
  id?: number;
  currentIndex: number;
  isUpdate: boolean;
  totalQtyReturn: number;
  totalQtyReturnWarehouse: number;
  productMessage: string;
  totalItemMessage: string;
  priceMessageError: string;
  totalItemMessageSupplier: string;
  additionalDamage: number;
  isAdmin: boolean;
  countAdditionalDamage: number;
  countTotalItem: number;
  handleOpenHistory: () => void;
  setProduct: React.Dispatch<React.SetStateAction<ProductModel>>;
  setPrice: React.Dispatch<React.SetStateAction<number>>;
  setAdditionalDamage: React.Dispatch<React.SetStateAction<number>>;
  setProductAdditionalDamage: React.Dispatch<React.SetStateAction<number>>;
  setTotalItem: React.Dispatch<React.SetStateAction<number>>;
  setTypeUnit: React.Dispatch<React.SetStateAction<string>>;
  setSelectProduct: React.Dispatch<React.SetStateAction<string>>;
  setDiscount: React.Dispatch<React.SetStateAction<number>>;
  handleSearchProduct: (value: string) => void;
  handleSaveUpdate?: (index: number) => React.MouseEventHandler;
  handleCloseUpdate?: () => void;
}

const ReturnOrderItem: FC<Props> = props => {
  const {
    product,
    products,
    price,
    totalItemMessageSupplier,
    handleOpenHistory,
    handleOpenReturn,
    productAdditionalDamage,
    totalItem,
    typeUnit,
    totalPrice,
    totalQtyReturn,
    totalQtyReturnWarehouse,
    isUpdate,
    index,
    id,
    currentIndex,
    loadProduct,
    typeReturn,
    setSelectProduct,
    productMessage,
    totalItemMessage,
    priceMessageError,
    additionalDamage,
    countAdditionalDamage,
    countTotalItem,
    isAdmin,
    setAdditionalDamage,
    setProduct,
    setPrice,
    setTotalItem,
    handleSearchProduct,
    handleSaveUpdate,
    handleCloseUpdate
  } = props;

  const { currentUser } = useContext(CurrentUserContext);

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN, TypeUser.ADMIN03]
  });

  return (
    <TableRow>
      <TableCell>
        <Autocomplete
          id='product'
          value={product}
          options={products}
          getOptionLabel={option => option.productName}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={(event: any, value: any) => {
            if (value) {
              setProduct(value);
            }
          }}
          onOpen={e => handleSearchProduct('')}
          loading={loadProduct}
          renderOption={(option: ProductModel, state) => (loadProduct ? <Skeleton variant='text' width='100%' /> : <CardProduct product={option} />)}
          renderInput={params => (
            <TextField
              {...params}
              required
              fullWidth
              label='Pilih Produk'
              onChange={e => setSelectProduct(e.target.value)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loadProduct && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </Fragment>
                )
              }}
              error={productMessage !== ''}
              helperText={productMessage}
            />
          )}
        />
      </TableCell>

      {(typeReturn === 'CUSTOMER' || (typeReturn === 'SUPPLIER' && isSuperAdmin)) && (
        <TableCell
          style={
            typeReturn === 'CUSTOMER' ? { display: 'flex', alignItems: 'center', columnGap: '0.5em' } : { display: 'flex', alignItems: 'center' }
          }
        >
          <TextField
            required
            fullWidth
            label='Harga'
            value={price}
            onChange={event => setPrice(+event.target.value)}
            InputProps={{
              inputComponent: NumberFormatMask as any
            }}
            error={priceMessageError !== ''}
            helperText={priceMessageError}
          />
          {typeReturn === 'CUSTOMER' && <InfoOutlined style={{ color: YELLOW_MOBILE, cursor: 'pointer' }} onClick={handleOpenHistory} />}
        </TableCell>
      )}

      <TableCell>
        <Grid xs={12} container alignItems='center' justify='space-between'>
          <Grid xs={typeReturn === 'SUPPLIER' ? 10 : 12}>
            <TextField
              required
              label='Kuantitas'
              value={totalItem}
              onChange={e => setTotalItem(+e.target.value)}
              error={totalItem < 0 || typeReturn === 'SUPPLIER' ? totalItem > totalQtyReturn - countTotalItem : totalItemMessage !== ''}
              helperText={
                (totalItem < 0 && 'Qty tidak boleh kecil dari 0') ||
                (typeReturn === 'SUPPLIER' ? totalItem > totalQtyReturn - countTotalItem && 'Melebihi batas qty return' : totalItemMessage)
              }
              InputProps={{
                endAdornment: ' ' + (typeUnit || '___')
              }}
            />
          </Grid>
          {typeReturn === 'SUPPLIER' && <InfoOutlined style={{ color: YELLOW_MOBILE, cursor: 'pointer' }} onClick={() => handleOpenReturn(false)} />}
        </Grid>
      </TableCell>

      {typeReturn === 'SUPPLIER' && (
        <TableCell>
          <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} xs={12}>
            <Grid xs={10}>
              <TextField
                label='Kuantitas'
                key={id}
                id={String(id || 0)}
                value={additionalDamage}
                error={(additionalDamage > 0 && additionalDamage > totalQtyReturnWarehouse - countAdditionalDamage) || additionalDamage < 0}
                helperText={
                  (additionalDamage < 0 && 'Qty tidak boleh kecil dari 0') ||
                  (additionalDamage > totalQtyReturnWarehouse - countAdditionalDamage && `Melebihi batas qty return gudang`)
                }
                onChange={event => setAdditionalDamage(+event.target.value)}
                InputProps={{
                  endAdornment: ' ' + (typeUnit || '___')
                }}
              />
            </Grid>
            <InfoOutlined style={{ color: YELLOW_MOBILE, cursor: 'pointer' }} onClick={() => handleOpenReturn(true)} />
          </Grid>
        </TableCell>
      )}

      {(typeReturn === 'CUSTOMER' || (typeReturn === 'SUPPLIER' && isSuperAdmin)) && (
        <TableCell>
          <TextField
            required
            disabled
            fullWidth
            label='Total Harga'
            value={totalPrice}
            InputProps={{
              inputComponent: NumberFormatMask as any
            }}
          />
        </TableCell>
      )}

      {isUpdate && index === currentIndex && (
        <TableCell>
          <Tooltip title='Ubah'>
            <IconButton
              onClick={handleSaveUpdate!(index)}
              size='small'
              disabled={
                isNaN(totalPrice) ||
                (typeReturn === 'SUPPLIER' && totalItem > totalQtyReturn) ||
                (typeReturn === 'SUPPLIER' && additionalDamage > totalQtyReturnWarehouse) ||
                (typeReturn === 'SUPPLIER' && additionalDamage < 0)
              }
            >
              <img src={EditItem} />
            </IconButton>
          </Tooltip>
          <Tooltip title='Hapus'>
            <IconButton onClick={handleCloseUpdate!} size='small'>
              <img src={CloseCircleItem} />
            </IconButton>
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  );
};

export default ReturnOrderItem;
