import React, { FC, useContext } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Tooltip,
  IconButton,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Chip
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import useRouter from 'hooks/useRouter';
import { RED } from 'constants/colors';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCollapse, TableRowCustom } from 'components';
import { format } from 'date-fns';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import useRole from 'hooks/useRole';
import NumberFormat from 'react-number-format';
import { Check, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import CircularProgressLabel from 'components/Progress/CircularProgressLabel';

interface Props {
  returnOrder: InvoiceReturnModel;
  isLoading: boolean;
  loadingItem: boolean;
  onDelete: React.MouseEventHandler;
  openCollapse: boolean;
  index: number;
  indexCollapse: number;
  handleOpenCollapse: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      color: RED,
      width: 30
    },
    tableCell: {
      // width: 150,
      whiteSpace: 'nowrap'
    },
    tableCellAction: {
      width: 30
    },
    tableCollapse: {
      width: '100%'
    },
    cellCollapse: {
      border: 'none'
    }
  })
);

const BodyRow: FC<Props> = props => {
  const { returnOrder, isLoading, onDelete, openCollapse, index, indexCollapse, handleOpenCollapse, loadingItem } = props;
  const { history } = useRouter();

  const classes = useStyles();
  const { currentUser } = useContext(CurrentUserContext);

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: ['SUPERADMIN']
  });

  const handleLink = () => {
    if (returnOrder.typeReturn === 'SUPPLIER') {
      history.push('/return-pembelian/detail', { id: returnOrder.id });
    } else {
      history.push('/return-penjualan/detail', { id: returnOrder.id });
    }
  };

  return (
    <>
      <TableRowCustom>
        <TableCellStart>
          {isLoading ? (
            <Skeleton variant='text' width={60} height={25} />
          ) : (
            <IconButton aria-label='expand row' size='small' onClick={handleOpenCollapse}>
              {openCollapse && index === indexCollapse ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          )}
        </TableCellStart>
        <TableCellMiddle onClick={handleLink}>
          {isLoading ? <Skeleton variant='text' width={60} height={25} /> : '#' + returnOrder.returnNumber}
        </TableCellMiddle>

        <TableCellMiddle onClick={handleLink}>
          {isLoading ? (
            <Skeleton variant='text' width={150} height={25} />
          ) : (
            <Typography variant='inherit' component='p'>
              {returnOrder.partnerName || '-'}
            </Typography>
          )}
        </TableCellMiddle>

        <TableCellMiddle onClick={handleLink}>
          {isLoading ? <Skeleton variant='text' width={150} height={25} /> : returnOrder.salesName || '-'}
        </TableCellMiddle>

        <TableCellMiddle className={classes.tableCell} onClick={handleLink}>
          {isLoading ? <Skeleton variant='text' width={120} height={25} /> : format(new Date(returnOrder.returnDate), 'dd MMM yyyy')}
        </TableCellMiddle>

        <TableCellMiddle align='center' className={classes.tableCell}>
          {isLoading ? <Skeleton variant='text' width={100} height={25} /> : returnOrder.totalItem}
        </TableCellMiddle>

        <TableCellMiddle align='center' className={classes.tableCell}>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <NumberFormat value={returnOrder.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>

        <TableCellMiddle align='center' className={classes.tableCell}>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (returnOrder.totalReturned || 0) > 0 && returnOrder.hasConvert ? (
            <Chip label='Selesai' color='primary' variant='outlined' size='small' />
          ) : (returnOrder.totalReturned || 0) > 0 && !returnOrder.hasConvert ? (
            <Chip label='Butuh Konversi' style={{color: '#F6891F', border: '1px solid #F6891F'}} variant='outlined' size='small' />
          ) : returnOrder.totalItem === (returnOrder.totalProcess || 0) ? (
            <Chip label='Selesai' color='primary' variant='outlined' size='small' />
          ) : (
            <CircularProgressLabel value={returnOrder?.totalProcess || 0} total={returnOrder.totalItem || 0} />
          )}
        </TableCellMiddle>

        <TableCellEnd align='center' className={classes.tableCellAction}>
          {isLoading ? (
            <Skeleton variant='text' width={'100%'} height={25} />
          ) : (
            isSuperAdmin && (
              <>
                <Tooltip title='Hapus '>
                  <span>
                    <IconButton
                      size='small'
                      onClick={onDelete}
                      disabled={returnOrder.typeReturn === 'CUSTOMER' ? (returnOrder.totalUsage && returnOrder.totalUsage > 0 ? true : false) : false}
                    >
                      <DeleteOutline fontSize='small' />
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            )
          )}
        </TableCellEnd>
      </TableRowCustom>

      <TableRowCollapse open={openCollapse && index === indexCollapse && !isLoading} colSpan={9}>
        <Table className={classes.tableCollapse} size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Nama Produk</TableCell>
              <TableCell>Harga</TableCell>
              <TableCell align='center'>Kuantitas</TableCell>
              <TableCell align='right'> Total Harga</TableCell>
              <TableCell align='center'> Progres</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loadingItem ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Skeleton variant='text' width='100%' height={25} />
                </TableCell>
              </TableRow>
            ) : (
              returnOrder.InvoiceReturnItem &&
              returnOrder.InvoiceReturnItem.map((value, index) => {
                const hasProcess = (value.totalDamage || value.totalReturned || value.totalMerge) > 0;

                return (
                  <TableRow key={index}>
                    <TableCell className={classes.cellCollapse}>{(value.Product && value.Product.productName) || '-'}</TableCell>
                    <TableCell className={classes.cellCollapse}>
                      <NumberFormat value={value.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </TableCell>

                    <TableCell align='center' className={classes.cellCollapse}>
                      {value.totalItem + ' ' + value.typeUnit}
                    </TableCell>

                    <TableCell align='right' className={classes.cellCollapse}>
                      <NumberFormat value={value.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </TableCell>
                    <TableCell align='center' className={classes.cellCollapse}>
                      {hasProcess ? <Check color='primary' /> : <Chip label='Menunggu Diproses' variant='outlined' size='small' />}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableRowCollapse>
    </>
  );
};

export default BodyRow;
