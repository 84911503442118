import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Grid,
  Container,
  Typography,
  Button,
  MenuItem,
  TextField,
  CircularProgress,
  Chip,
  Checkbox,
  FormHelperText,
  Table,
  TableBody,
  TableRow,
  TableCell,
  FormGroup,
  FormControlLabel,
  makeStyles,
  RadioGroup,
  Radio
} from '@material-ui/core';
import { Page, StandardConfirmationDialog, PaperCustom, Breadcrumb } from 'components';
import { dummyStockCheckItem } from 'utils/dummy';
import { STOCK_CHECK_BASE_URL, WAREHOUSE_BASE_URL } from 'constants/url';
import { format } from 'date-fns';
import { RED } from 'constants/colors';
import useRouter from 'hooks/useRouter';
import XLSX from 'xlsx';
import ProductTable from './components/ProductTable';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles({
  textDanger: {
    color: RED
  }
});

const StockCheckCreatePage: FC = () => {
  const { history, location } = useRouter();
  const classes = useStyles();

  // eslint-disable-next-line
  const param: any = location.state;

  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [notice, setNotice] = useState<boolean>(false);

  const [checkDate, setCheckDate] = useState<Date>(new Date());
  const [checkDateMessage, setCheckDateMessage] = useState<string>('');

  const [warehouses, setWarehouses] = useState<WareHouseModel[]>([]);
  const [warehouse, setWarehouse] = useState<number>(0);
  const [warehouseMessage, setWarehouseMessage] = useState<string>('');
  const [loadWarehouse, setLoadWarehouse] = useState<boolean>(false);

  const [isUpdatePrice, setUpdatePrice] = useState<boolean>(false);
  const [isFirstStock, setFirstStock] = useState<boolean>(false);
  const [isMerge, setMerge] = useState<boolean>(false);

  const [id, setId] = useState<number>(0);

  const [adjustItem, setAdjustItem] = useState<number>(0);
  const [totalProduct, setTotalProduct] = useState<number>(0);
  const [totalCurrent, setTotalCurrent] = useState<number>(0);

  const [items, setItems] = useState<StockCheckItemModel[]>([]);
  const [itemsMessage, setItemsMessage] = useState<string>('');

  const [isLoading, setLoading] = useState<boolean>(false);

  const [upPrice, setUpPrice] = useState<boolean>(false);
  const [downPrice, setDownPrice] = useState<boolean>(false);

  const [upStock, setUpStock] = useState<boolean>(false);
  const [downStock, setDownStock] = useState<boolean>(false);

  const [stockCheckActive, setStockCheckActive] = useState<StockCheckModel[]>([]);
  const [isCurrent, setCurrent] = useState<boolean>(false);
  const [isAdjustment, setAdjustment] = useState<boolean>(false);

  const fethcWareHouse = async () => {
    setLoadWarehouse(true);
    try {
      const { data } = await axios.get(WAREHOUSE_BASE_URL);
      setWarehouses(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadWarehouse(false);
    }
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
    history.push('/cek-stok');
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const validation = () => {
    let valid = true;

    if (items.length === 0) {
      setItemsMessage('Daftar Produk tidak boleh kosong.');
      valid = false;
    }

    if (warehouse === 0) {
      setWarehouseMessage('Gudang tidak boleh kosong.');
      valid = false;
    }

    if (!checkDate) {
      setCheckDateMessage('Tanggal tidak boleh kosong.');
      valid = false;
    }

    return valid;
  };

  const reset = () => {
    setItems([]);
    setWarehouse(0);
    setCheckDate(new Date());
  };

  const resetValidation = () => {
    setItemsMessage('');
    setWarehouseMessage('');
    setCheckDateMessage('');
  };

  const fetchActive = async () => {
    try {
      const { data } = await axios.get(`${STOCK_CHECK_BASE_URL}/active`);
      setStockCheckActive(data.data);
    } catch (error) {
      console.log('error :', error);
    }
  };

  const handleOnSubmit = async () => {
    resetValidation();
    setSubmit(true);

    if (!validation()) {
      setSubmit(false);
      return;
    }

    try {
      await axios.post(STOCK_CHECK_BASE_URL, {
        id,
        checkDate,
        WareHouseId: warehouse,
        isUpdatePrice,
        isFirstStock,
        isMerge,
        isCurrent,
        isAdjustment,
        items
      });
      reset();
      handleSnackBar(true, 'success', 'Stok berhasil dibuat');
    } catch (error) {
      console.log('error :', error);
      handleSnackBar(true, 'error', 'Stok gagal dibuat');
    } finally {
      setSubmit(false);
    }
  };

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files = e.target.files;
    const f = files && files.length > 0 ? files[0] : new Blob(['']);

    const reader = new FileReader();
    setLoading(true);
    setItems([]);

    reader.onload = (e: any) => {
      var data = reader.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      if (dataParse && dataParse.length > 1) {
        let checkItem: StockCheckItemModel[] = [];

        dataParse.shift();
        dataParse.shift();

        dataParse.map((value: any, index: number) => {
          checkItem.push({
            ...dummyStockCheckItem,
            id: 0,
            ProductId: (value && value[0]) || 0,
            StockCheckId: 0,
            typeUnit: (value && value[2]) || 'Pcs',
            isMerge: false,
            isUpdatePrice: false,
            isCurrent: false,
            isAdjustment,
            firstStock: (value && value[4]) || 0,
            purchasePrice: (value && value[14]) || 0,
            adjustPrice: (value && value[15]) || 0,
            currentItem: (value && value[13]) || 0,
            adjustItem: (value && value[16]) || 0,
            productName: (value && value[1]) || '',
            warehouse: (value && value[3]) || '',
            diff: isFirstStock ? ((value && value[16]) || 0) - ((value && value[4]) || 0) : ((value && value[16]) || 0) - ((value && value[13]) || 0)
          });
        });

        setItems(checkItem.filter(_value => _value.ProductId !== 0));
        setLoading(false);
      }
      setLoading(false);
    };
    reader.readAsBinaryString(f);
  };

  useEffect(() => {
    if (items.length < 1) {
      setWarehouseMessage('');
      return;
    }

    setTotalProduct(items.length);
    setAdjustItem(items.map(value => value.adjustItem).reduce((a, b) => a + b));
    setTotalCurrent(items.map(value => value.currentItem).reduce((a, b) => a + b));

    const wh = items.map(value => value.warehouse || 'NO_WAREHOUSE').filter((v: any, i: any, a: any) => a.indexOf(v) === i);
    if (wh.length === 1) {
      const _wh = warehouses.find(value => value.name === wh[0]);
      if (_wh) {
        setWarehouse(_wh.id);
      }
    }

    if (wh.length > 1) {
      setWarehouseMessage('Mohon untuk memilih salah satu gudang.');
    }
  }, [items]);

  useEffect(() => {
    setItems(items);
  }, [isFirstStock]);

  useEffect(() => {
    fethcWareHouse();
  }, []);

  useEffect(() => {
    if (!isUpdatePrice && !isMerge) return;

    setMessage(
      isUpdatePrice
        ? 'Dengan menyetujui ini anda akan mempebaharui semua harga produk!. Abaikan ini jika anda hanya ingin memperbaharui stok'
        : 'Dengan menyetujui ini anda akan mempebaharui semua stok produk!. Abaikan ini jika anda hanya ingin membuat stok awal'
    );
    setNotice(true);
  }, [isUpdatePrice, isMerge]);

  useEffect(() => {
    fetchActive();
  }, []);

  return (
    <Page title='Cek Stok'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Pengecekan Stok </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid container direction='row' spacing={2}>
            <Grid container direction='row' spacing={1} item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Table size='small'>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography> Tipe Pengecekan ? </Typography>
                      <RadioGroup aria-label='first-stock' value={isFirstStock} onChange={e => setFirstStock(prevState => !prevState)} row>
                        <FormControlLabel value={true} control={<Radio color='primary' />} label='Stok Awal' />
                        <FormControlLabel value={false} control={<Radio color='primary' />} label='Stok Berjalan' />
                      </RadioGroup>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography>Apa yang ingin anda lakukan ?</Typography>
                      <RadioGroup aria-label='adjustment' value={isAdjustment} onChange={e => setAdjustment(prevState => !prevState)} row>
                        <FormControlLabel value={true} control={<Radio color='primary' />} label='Penyesuaian (Adjustment)' />
                        <FormControlLabel value={false} control={<Radio color='primary' />} label='Stok Awal Baru' />
                      </RadioGroup>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography>Unggah Berkas </Typography>
                    </TableCell>

                    <TableCell align='right'>
                      <input
                        accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        id='contained-button-file'
                        type='file'
                        onChange={handleUpload}
                        hidden
                      />
                      <label htmlFor='contained-button-file'>
                        <Button color='default' component='span'>
                          Unggah Berkas
                        </Button>
                      </label>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography>Tanggal Pengecekan </Typography>
                    </TableCell>

                    <TableCell align='right'>
                      <TextField
                        id='date'
                        type='date'
                        required
                        label='Tanggal Cek'
                        value={format(checkDate, 'yyyy-MM-dd')}
                        error={checkDateMessage !== ''}
                        helperText={checkDateMessage}
                        onChange={e => setCheckDate(new Date(e.target.value))}
                        InputLabelProps={{
                          shrink: true
                        }}
                        style={{ width: 200 }}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography>Gudang </Typography>
                    </TableCell>

                    <TableCell align='right'>
                      <TextField
                        id='warehouse'
                        value={warehouse}
                        onChange={e => setWarehouse(+e.target.value)}
                        label='Gudang'
                        select
                        error={warehouseMessage !== ''}
                        helperText={warehouseMessage}
                        style={{ width: 200 }}
                      >
                        <MenuItem key={''}>Pilih Gudang</MenuItem>
                        {warehouses.length > 0 &&
                          warehouses.map((value, index) => (
                            <MenuItem key={index} value={value.id}>
                              {value.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2}>
                      {warehouse === 0 && stockCheckActive.length > 0
                        ? stockCheckActive.map(value => (
                            <Chip
                              disabled={!isFirstStock}
                              label={`${(value.WareHouse && value.WareHouse.name) || '-'} ${value.checkDate}`}
                              color={!isFirstStock ? 'default' : 'primary'}
                            />
                          ))
                        : stockCheckActive
                            .filter(value => value.WareHouseId === warehouse)
                            .map(value => (
                              <Chip
                                disabled={!isFirstStock}
                                label={`${(value.WareHouse && value.WareHouse.name) || '-'} ${value.checkDate}`}
                                color={!isFirstStock ? 'default' : 'primary'}
                              />
                            ))}

                      <br />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography>Perbaharui Harga </Typography>
                      <small className={classes.textDanger}>
                        <i>Abaikan jika tidak ingin melakukan perubahan harga produk.</i>
                      </small>
                    </TableCell>
                    <TableCell align='right'>
                      <Checkbox color='primary' checked={isUpdatePrice} onChange={() => setUpdatePrice(prevState => !prevState)} />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2} align='right'>
                      <Button onClick={handleOnSubmit}>{isSubmit ? <CircularProgress color='inherit' size={20} /> : 'Simpan'}</Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <Typography align='left'>Filter Stok</Typography>
              <FormGroup row>
                <FormControlLabel
                  control={<Checkbox color='primary' checked={upStock} onChange={() => setUpStock(prevState => !prevState)} />}
                  label='Lebih Tinggi'
                />

                <FormControlLabel
                  control={<Checkbox color='primary' checked={downStock} onChange={() => setDownStock(prevState => !prevState)} />}
                  label='Lebih Rendah'
                />
              </FormGroup>
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <Typography align='left'>Filter Harga</Typography>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox disabled={!isUpdatePrice} color='primary' checked={upPrice} onChange={() => setUpPrice(prevState => !prevState)} />
                  }
                  label='Lebih Tinggi'
                />

                <FormControlLabel
                  control={
                    <Checkbox disabled={!isUpdatePrice} color='primary' checked={downPrice} onChange={() => setDownPrice(prevState => !prevState)} />
                  }
                  label='Lebih Rendah'
                />
              </FormGroup>
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <Typography align='right'>
                Total Aset Saat Ini :
                <NumberFormat
                  value={(items.length > 0 && items.map(value => value.purchasePrice * value.currentItem).reduce((a, b) => a + b)) || 0}
                  prefix={'Rp'}
                  thousandSeparator={true}
                  displayType='text'
                />
              </Typography>

              <Typography align='right'>
                Total Aset Terbaru :
                <NumberFormat
                  value={
                    (items.length > 0 && items.map(value => value.adjustPrice || value.purchasePrice * value.adjustItem).reduce((a, b) => a + b)) || 0
                  }
                  prefix={'Rp'}
                  thousandSeparator={true}
                  displayType='text'
                />
              </Typography>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography align='left'>Total Produk: {totalProduct}</Typography>
              <Typography align='right'>Total Item Produk: {adjustItem}</Typography>
            </Grid>

            <br />

            <Grid container direction='row' item xl={12} lg={12} md={12} sm={12} xs={12}>
              <FormHelperText error={itemsMessage !== ''}>{itemsMessage}</FormHelperText>
              <br />
              <ProductTable
                isLoadingData={isLoading}
                isFirstStock={isFirstStock}
                items={
                  upPrice || downPrice || upStock || downStock
                    ? items.filter(
                        value =>
                          (isUpdatePrice && upPrice && value.adjustPrice && value.adjustPrice > value.purchasePrice) ||
                          (isUpdatePrice && downPrice && value.adjustPrice && value.adjustPrice < value.purchasePrice) ||
                          (upStock && value.adjustItem > value.currentItem) ||
                          (downStock && value.currentItem > value.adjustItem) ||
                          (isUpdatePrice &&
                            upPrice &&
                            upStock &&
                            value.adjustPrice &&
                            value.adjustPrice > value.purchasePrice &&
                            value.adjustItem > value.currentItem) ||
                          (isUpdatePrice &&
                            upPrice &&
                            downStock &&
                            value.adjustPrice &&
                            value.adjustPrice > value.purchasePrice &&
                            value.adjustItem < value.currentItem) ||
                          (isUpdatePrice &&
                            downPrice &&
                            upStock &&
                            value.adjustPrice &&
                            value.adjustPrice < value.purchasePrice &&
                            value.adjustItem > value.currentItem) ||
                          (isUpdatePrice &&
                            downPrice &&
                            downStock &&
                            value.adjustPrice &&
                            value.adjustPrice < value.purchasePrice &&
                            value.adjustItem < value.currentItem)
                      )
                    : items
                }
                isUpdatePrice={isUpdatePrice}
              />
            </Grid>

            <StandardConfirmationDialog
              variant={snackbarVariant}
              titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
              message={message}
              open={openSnackbar}
              handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
              onConfirm={handleConfirmSnackbar}
              noCancelButton={true}
            />

            <StandardConfirmationDialog
              variant={'warning'}
              titleMessage={'Perhatian'}
              message={message}
              open={notice}
              handleClose={() => setNotice(false)}
              onConfirm={() => setNotice(false)}
              noCancelButton={true}
            />
          </Grid>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default StockCheckCreatePage;
