import React, { FC, Fragment } from 'react';
import {
  TableRow,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Checkbox,
  Zoom,
  Fade,
  TableContainer,
  Divider,
  Typography,
  TextField,
  InputBase,
  CircularProgress,
  Tooltip
} from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart, TableRowCollapse } from 'components/Table';
import Skeleton from '@material-ui/lab/Skeleton';
import NumberFormat from 'react-number-format';
import { RED, WHITE, GREEN } from 'constants/colors';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import NumberFormatMask from 'components/NumberFormatMask';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import clx from 'clsx';
import TypeUser from 'typings/enum/TypeUser';
import Error from '@material-ui/icons/Error';

interface Props {
  invoiceItem: ReturnBillItem;
  rute: string;
  isLoading: boolean;
  index: number;
  isRequestEdit: boolean;
  indexCollapse: number;
  indexEdit: number;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  item: { id: number; notes: string; isRequestEdit: boolean }[];
  type: string;
  loadingEdit: boolean;
  onDeleteItem: (id: number) => void;
  onUpdate: () => void;
  individualEdit: (item: InvoiceItemModel) => void;
  handleEdit: (index: number, type: string) => void;
  handleOpenCollapse: React.MouseEventHandler;
  handleRequestNote: (item: { id: number; notes: string }) => void;
  handleRequestCheck: (item: { id: number; notes: string; isRequestEdit: boolean }) => void;
}

const useStyles = makeStyles({
  confirm: {
    background: WHITE,
    color: GREEN
  },
  reject: {
    background: WHITE,
    color: RED
  },
  tableCollapse: {
    width: '100%'
  },
  cellCollapse: {
    border: 'none'
  },
  titlePackage: {
    fontWeight: 'bold'
  },
  price: {
    padding: '4px 4px 4px 4px',
    borderBottom: '2px dotted #272727',
    backgroundColor: 'rgba(1, 136, 189, 0)',
    '&:hover': {
      padding: '4px 4px 4px 4px',
      backgroundColor: 'rgba(1, 136, 189, 0.05)',
      borderRadius: 8,
      borderBottom: '2px solid white'
    }
  }
});

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const {
    invoiceItem,
    isLoading,
    isRequestEdit,
    indexCollapse,
    indexEdit,
    item,
    type,

    rute,
    loadingEdit,
    isAdmin,
    isSuperAdmin,
    onDeleteItem,
    onUpdate,
    individualEdit,
    handleEdit,
    handleOpenCollapse,
    handleRequestNote,
    handleRequestCheck
  } = props;

  const reqEdit = item.find(value => value.id === invoiceItem.id) || { id: 0, notes: '', isRequestEdit: false };
  const isChecked = item.some(value => value.id === invoiceItem.id);

  return (
    <Fragment>
      <TableRow>
        <TableCellStart>{isLoading ? <Skeleton variant='text' width={100} height={25} /> : invoiceItem.productName || '-'}</TableCellStart>

        <TableCellMiddle align='center'>{isLoading ? <Skeleton variant='text' width={100} height={25} /> : invoiceItem.typeUnit}</TableCellMiddle>
        <TableCellMiddle align='center'>{isLoading ? <Skeleton variant='text' width={100} height={25} /> : invoiceItem.totalItem}</TableCellMiddle>
        <TableCellMiddle align='center'>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <NumberFormat
              // onClick={() => isSuperAdmin && invoiceItem.isRequestEdit && handleEdit(invoiceItem.id, 'DISCOUNT')}
              value={(invoiceItem && invoiceItem.price) || 0}
              prefix={'Rp'}
              thousandSeparator={true}
              displayType='text'
              //className={clx(!isCanceledInvoice && isSuperAdmin && invoiceItem.isRequestEdit && classes.price)}
            />
          )}
        </TableCellMiddle>
        <TableCellEnd align='center'>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <NumberFormat
              // onClick={() => isSuperAdmin && invoiceItem.isRequestEdit && handleEdit(invoiceItem.id, 'DISCOUNT')}
              value={(invoiceItem && invoiceItem.totalPrice) || 0}
              prefix={'Rp'}
              thousandSeparator={true}
              displayType='text'
              //className={clx(!isCanceledInvoice && isSuperAdmin && invoiceItem.isRequestEdit && classes.price)}
            />
          )}
        </TableCellEnd>
      </TableRow>
    </Fragment>
  );
};

export default BodyRow;
