import React, { FC } from 'react';
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import { dummyInvoicePayment } from 'utils/dummy';
import BodyRow from './components/BodyRow';

interface Props {
  isLoadingData: boolean;
  invoicePayment: InvoicePaymentModel[];
  checked: InvoiceReturnModel[];
  handleIndividualCheck: (item: InvoiceReturnModel) => void;
}

const InvoiceReturnTable: FC<Props> = props => {
  const { isLoadingData, checked, invoicePayment, handleIndividualCheck } = props;

  return (
    <TableContainer>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell align='center'></TableCell>
            <TableCell>Nomor Invoice</TableCell>
            <TableCell>Keterangan</TableCell>
            <TableCell align='center'>Jumlah Barang</TableCell>
            <TableCell align='right'>Jumlah Return</TableCell>
            <TableCell align='right'>Dibayarkan</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {checked.length > 0 &&
            checked.map((value, index) => (
              <BodyRow
                key={index}
                index={index}
                invoice={value}
                invoicePayment={invoicePayment.find(_value => _value.ReturnId === value.id) || dummyInvoicePayment}
                isLoading={isLoadingData}
                handleIndividualCheck={handleIndividualCheck}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceReturnTable;
