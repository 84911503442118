import { Button, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import { InfoOutlined, PhotoSizeSelectActualOutlined } from '@material-ui/icons';
import axios from 'axios';
import FlexBox from 'components/FlexBox';
import { GREY } from 'constants/colors';
import { INVOICE_BASE_URL } from 'constants/url';
import React, { useState } from 'react';
import SalesOrderStatus from 'typings/enum/SalesOrderStatus';
import ModalSuccess from './ModalSuccess';

const useStyles = makeStyles({
  wrapper: {
    minHeight: '20vh',
    padding: '.7em'
  },
  fontSize: {
    fontSize: '.8rem'
  },
  warningColor: {
    color: '#ff9800'
  },
  image: {
    borderRadius: '0.3rem',
    border: '1px solid rgba(0, 0, 0, 0.08)'
  },
  alertWarning: {
    border: '1px solid #FFBF00',
    backgroundColor: 'rgba(255, 191, 0, 0.12)',
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
    borderRadius: '5px'
  },
  noImage: {
    backgroundColor: GREY,
    height: '60px',
    width: '60px',
    borderRadius: '.5em',
    display: 'grid',
    placeItems: 'center'
  },
  buttonRadius: {
    borderRadius: '20px'
  },
  buttonSubmitWrapper: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 5,
    backgroundColor: '#fff',
    padding: '10px 10px',
    boxShadow: '0px -3px 3px -1px rgb(0 0 0 / 30%)'
  }
});
const CollyListProduct = ({
  salesOrderItem,
  idSalesOrder,
  getWareHouse
}: {
  salesOrderItem: SalesOrderItemModel[];
  idSalesOrder: number;
  getWareHouse: number;
}) => {
  const classes = useStyles();

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [isSuccess, setSuccess] = useState<boolean>(false);
  const disableAfterSave = salesOrderItem.some(value => value.status === SalesOrderStatus.HAS_INVOICE);
  const handleSubmitConvet = async () => {
    setLoadingData(true);

    const getItems = salesOrderItem.map((value: SalesOrderItemModel) => ({
      id: 0,
      SalesOrderItemId: value.id,
      ProductId: value.ProductId,
      typeUnit: value.typeUnit,
      totalItem: value.totalItem,
      price: value.price,
      upPrice: value.upPrice,
      discountUnit: value.discountUnit
    }));

    try {
      const { data } = await axios.post(`${INVOICE_BASE_URL}/in`, {
        SalesOrderId: idSalesOrder,
        WareHouseId: getWareHouse,
        items: getItems
      });

      setLoadingData(false);
      setSuccess(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FlexBox xs={12} className={classes.wrapper} container rowGap={1}>
      <Grid xs={12}>
        <Grid xs={12} className={classes.alertWarning}>
          <InfoOutlined className={classes.warningColor} />
          <Grid>
            <Typography className={classes.fontSize}>
              Orderan ini berada dalam kota, sehingga dapat langsung dikonfirmasi ke invoice tanpa perlu proses pengkolian.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {salesOrderItem.map(item => (
        <Grid xs={12} container>
          <Grid xs={3}>
            {item.productImage ? (
              <img src={item.productImage} alt='Produk' width='60px' height='60px' className={classes.image} />
            ) : (
              <Grid className={classes.noImage}>
                <PhotoSizeSelectActualOutlined />
              </Grid>
            )}
          </Grid>
          <Grid xs={9}>
            <Typography variant='h6' className={classes.fontSize}>
              {item.productName}
            </Typography>
            <Typography color='textSecondary' className={classes.fontSize}>{`Total Qty Pengepakan: ${item.totalItem} ${item.typeUnit}`}</Typography>
          </Grid>
        </Grid>
      ))}

      <Grid xs={12} className={classes.buttonSubmitWrapper}>
        <Button
          color='primary'
          className={classes.buttonRadius}
          disabled={disableAfterSave || loadingData}
          fullWidth
          onClick={() => handleSubmitConvet()}
        >
          {loadingData ? <CircularProgress size={20} color='primary' /> : 'Konfirmasi ke Invoice'}
        </Button>
      </Grid>
      <ModalSuccess open={isSuccess} />
    </FlexBox>
  );
};

export default CollyListProduct;
