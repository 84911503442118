import React, { memo, useState } from 'react';
import {
  DialogContent,
  Dialog,
  Grid,
  Typography,
  IconButton,
  TextField,
  makeStyles,
  Button,
  CircularProgress,
  MenuItem,
  Divider
} from '@material-ui/core';
import { OPERATIONAL_BASE_URL } from 'constants/url';
import { Close, CloudDownload } from '@material-ui/icons';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { DialogTitle, NumberFormatMask } from 'components';
import { GREY, GREY_6, PRIMARY_MOBILE, WHITE } from 'constants/colors';
import { dummyOperasional } from 'utils/dummy';
import axios from 'axios';
import NumberFormat from 'react-number-format';
const useStyles = makeStyles({
  container: {
    marginTop: 10,
    columnGap: '1em'
  },

  iconLarge: {
    fontSize: '3.5em'
  },
  containerImage: {
    margin: '0.7em 0',
    rowGap: '0.7em'
  },
  uploadImage: {
    padding: '1em ',
    backgroundColor: GREY,
    border: '2px dashed ' + GREY_6
  },
  browser: {
    color: PRIMARY_MOBILE,
    cursor: 'pointer'
  },
  wrapperImage: {
    columnGap: '1em'
  },
  containerBoxImage: {
    position: 'relative'
  },
  image: {
    objectFit: 'contain',
    borderRadius: '0.5em',
    height: '4em'
  },
  closeImage: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  closeIcon: {
    backgroundColor: 'rgba(32, 42, 61, 0.88)',
    borderRadius: '50%',
    cursor: 'pointer',
    color: WHITE
  }
});
interface Props {
  operasionalResourceId: Operasional | null;
  open: boolean;
  handleAction: (isOpen: boolean, id: number, action: 'delete' | 'update' | 'detail') => void;
}

const OperationalDetail = ({ operasionalResourceId, open }: Props) => {
  const classes = useStyles();
  return (
    <Dialog maxWidth={'xl'} disableBackdropClick={true} aria-labelledby='customized-dialog-title' open={open}>
      <DialogTitle>
        <Grid xs={12} container justify='space-between'>
          <Typography variant='h5'>Tambah Biaya </Typography>
          <IconButton size='small'>
            {/* onClick={handleCloseCreate} */}
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid item justify='space-between' lg={12} xl={12} md={12} sm={12} xs={12} container spacing={1}>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <Typography variant='h6'>Nama Supplier</Typography>
            <Typography variant='body2'> 0 </Typography>
          </Grid>
          <Divider orientation='vertical' />
          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <Typography variant='h6'>Tanggal Terbit</Typography>
            <Typography variant='body2'> e </Typography>
          </Grid>
          <Divider orientation='vertical' />
          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <Typography variant='h6'>Jatuh Tempo</Typography>
            <Typography variant='body2'> e </Typography>
          </Grid>

          <Divider orientation='vertical' />
          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <Typography variant='h6'>Total Tagihan</Typography>
            <Typography variant='body2'>
              <NumberFormat prefix={'Rp'} thousandSeparator={true} displayType='text' />
            </Typography>
          </Grid>
          <Divider orientation='vertical' />
          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <Typography variant='h6'>Sisa Tagihan</Typography>
            <Typography variant='body2'>
              <NumberFormat prefix={'Rp'} thousandSeparator={true} displayType='text' />
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction='row'>
          <Grid xs={12}>
            <TextField id='name' required fullWidth autoComplete='off' label='Nama Biaya' />

            <TextField id='vendor' required fullWidth autoComplete='off' label='Nama Vendor' />

            <TextField id='amount' required fullWidth name='amount' autoComplete='off' label='Jumlah' />

            <TextField id='description' label='Keterangan' multiline rows={4} variant='outlined' fullWidth />
          </Grid>

          <Grid container item lg={12} md={12} sm={12} xs={12} justify='flex-end' className={classes.container}>
            <Button color='secondary'>Batal</Button>

            <Button>{'Simpan'}</Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default OperationalDetail;
