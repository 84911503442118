const collyName = [
  'Pilih Koli',
  'Koli A',
  'Koli B',
  'Koli C',
  'Koli D',
  'Koli E',
  'Koli F',
  'Koli G',
  'Koli H',
  'Koli I',
  'Koli J',
  'Koli K',
  'Koli L',
  'Koli M',
  'Koli N',
  'Koli O',
  'Koli P',
  'Koli Q',
  'Koli R',
  'Koli S',
  'Koli T',
  'Koli U',
  'Koli V',
  'Koli W',
  'Koli X',
  'Koli Y',
  'Koli Z',
  'Koli A1',
  'Koli B2',
  'Koli C3',
  'Koli D4',
  'Koli E5',
  'Koli F6',
  'Koli G7',
  'Koli H8',
  'Koli I9',
  'Koli J10',
  'Koli K11',
  'Koli L12',
  'Koli M13',
  'Koli N14',
  'Koli O15',
  'Koli P16',
  'Koli Q17',
  'Koli R18',
  'Koli S19',
  'Koli T20',
  'Koli U21',
  'Koli V22',
  'Koli W23',
  'Koli X24'
];
export default collyName;
