import React, { FC, Fragment, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { makeStyles, TextField, Button, Typography, Grid, TableContainer, TableBody, TableHead } from '@material-ui/core';
import { PRODUCT_BASE_URL, CATEGORY_BASE_URL, STOCK_BASE_URL, WAREHOUSE_BASE_URL, PARTNER_BASE_URL, PURCHASE_INVOICE_BASE_URL } from 'constants/url';
import { HeaderRow, TableCustom } from 'components/Table';
import { dummyCategory, dummyWareHouse, dummyPartnerMinim, dummyProduct, dummyPurchaseInvoice, dummyStockItem } from 'utils/dummy';
import { format } from 'date-fns';
import ProductModal from './ProductModal';
import BodyRow from './BodyRow';
import empty from 'images/empty.svg';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormatMask from 'components/NumberFormatMask';
import NumberFormat from 'react-number-format';

interface Props {
  isSubmit: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
  setPurchases: React.Dispatch<React.SetStateAction<StockModel[]>>;
}

interface Partner {
  id: number;
  name: string;
}

const useStyles = makeStyles({
  dialogContent: {
    margin: '0 auto',
    width: 1031,
    height: 590,
    padding: '24px'
  },
  image: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  warehouse: {
    paddingTop: -70,
    marginTop: -70
  }
});

const StockWizard: FC<Props> = props => {
  const classes = useStyles();
  const { isSubmit, setOpen, handleSnackBar, setSubmit, setPurchases } = props;

  const [enterDate, setEnterDate] = useState<Date | null>(null);
  const [logisticPrice, setLogisticPrice] = useState<number>(0);
  const [description, setDescription] = useState<string>('');
  const [checked, setChecked] = useState<StockItemModel[]>([]);
  const [checkedProduct, setCheckedProduct] = useState<ProductModel[]>([]);
  const [category, setCategory] = useState<CategoryModel>(dummyCategory);
  const [categories, setCategories] = useState<CategoryModel[]>([dummyCategory]);
  const [warehouse, setWarehouse] = useState<WareHouseModel>(dummyWareHouse);
  const [warehouses, setWarehouses] = useState<WareHouseModel[]>([dummyWareHouse]);
  const [wareHouseMessage, setWareHouseMessage] = useState<string>('');
  const [enterDateMessage, setEnterDateMessage] = useState<string>('');
  const [openProduct, setOpenProduct] = useState<boolean>(false);
  const [products, setProducts] = useState<ProductModel[]>([dummyProduct]);
  const [count, setCount] = useState<number>(0);
  const [queryString, setQueryString] = useState<string>('');
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [isLoadingPurchaseInvoice, setIsLoadingPurchaseInvoice] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [partner, setPartner] = useState<Partner>(dummyPartnerMinim);
  const [partners, setPartners] = useState<Partner[]>([dummyPartnerMinim]);
  const [purchaseInvoices, setPurchaseInvoices] = useState<PurchaseInvoiceModel[]>([dummyPurchaseInvoice]);
  const [purchaseInvoice, setPurchaseInvoice] = useState<PurchaseInvoiceModel>(dummyPurchaseInvoice);
  const [purchaseInvoiceError, setPurchaseInvoiceError] = useState<string>('');

  const netPrice = (item: StockItemModel) => {
    let unitNetPrice = 0;
    let totalNet = 0;

    if (logisticPrice > 0 && item.totalItem > 0 && checked.length > 0) {
      unitNetPrice = Math.ceil(logisticPrice / checked.length / item.totalItem) + item.purchasePrice;
      totalNet = unitNetPrice * item.totalItem;
    }

    return [unitNetPrice, totalNet];
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const fetchData = useCallback(async () => {
    setIsLoadingData(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (queryString) {
        params.append('keyword', queryString);
      }

      if (orderBy || order) {
        params.append('orderBy', orderBy);
        params.append('ordered', order);
      }

      if (category && category.id > 0) {
        params.append('CategoryId', String(category.id));
      }

      params.append('page', currentPage.toString());
      return params.toString();
    };

    try {
      const { data } = await axios.get(`${PRODUCT_BASE_URL}?${getQueryParams()}`);
      setProducts(data.data);
      setCount(data.meta.last_page);
      setCurrentPage(data.meta.current_page);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setIsLoadingData(false);
    }
  }, [queryString, orderBy, order, currentPage, category, openProduct]);

  const handleSearchCategory = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);

    try {
      const { data } = await axios.get(`${CATEGORY_BASE_URL}?${params.toString()}`);
      setCategories(data.data);
    } catch (error) {
      console.log('error :', error);
    }
  };

  const handleSearchWareHouse = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    try {
      const { data } = await axios.get(`${WAREHOUSE_BASE_URL}?${params.toString()}`);
      setWarehouses(data.data);
    } catch (error) {
      console.log('error :', error);
    }
  };

  const handleSearchPartner = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    params.append('partnerType', 'SUPPLIER');

    try {
      const { data } = await axios.get(`${PARTNER_BASE_URL}?${params.toString()}`);
      setPartners(data.data);
    } catch (error) {
      console.log('error :', error);
    }
  };

  const handleSearchPurchaseInvoice = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    params.append('inStock', 'false');

    setPurchaseInvoiceError('');
    setIsLoadingPurchaseInvoice(true);
    try {
      const { data } = await axios.get(`${PURCHASE_INVOICE_BASE_URL}?${params.toString()}`);
      setPurchaseInvoices(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setIsLoadingPurchaseInvoice(false);
    }
  };

  const reset = () => {
    setCheckedProduct([]);
    setChecked([]);
    setPartner(dummyPartnerMinim);
    setWarehouse(dummyWareHouse);
    setDescription('');
  };

  const handleOnSubmit = async () => {
    resetValidation();

    if (!validation()) {
      setSubmit(false);
      return;
    }

    try {
      const { data } = await axios.post(STOCK_BASE_URL, {
        purchasesNumber: purchaseInvoice.invoiceNumber,
        enterDate: format(new Date(enterDate!), 'yyyy-MM-dd'),
        PartnerId: partner.id,
        description,
        totalPrice: checked.map(value => value.totalPrice).reduce((a, b) => a + b),
        WareHouseId: warehouse.id,
        totalItem: checked.length,
        items: checked
      });

      reset();
      setOpen(false);
      setPurchases(prevState => [data.data, ...prevState]);
      handleSnackBar(true, 'success', 'Stok berhasil ditambahkan');
    } catch (error) {
      console.log('error :', error);
      handleSnackBar(true, 'error', 'Stok gagal ditambahkan');
    } finally {
      setSubmit(false);
    }
  };

  const validation = (): boolean => {
    let valid = true;

    if (!enterDate) {
      setEnterDateMessage('Tanggal masuk tidak boleh kosong.');
      valid = false;
    }

    if (purchaseInvoice && purchaseInvoice.id === 0) {
      setPurchaseInvoiceError('Nomor pembelian tidak boleh kosong.');
      valid = false;
    }

    if (warehouse && warehouse.id === 0) {
      setWareHouseMessage('Gudang Tujuan tidak boleh kosong');
      valid = false;
    }
    return valid;
  };

  const resetValidation = () => {
    setEnterDateMessage('');
    setPurchaseInvoiceError('');
    setWareHouseMessage('');
  };

  const handleClickOpen = () => {
    setOpenProduct(true);
  };

  const handleClose = () => {
    setOpenProduct(false);
  };

  const handleCancelProduct = () => {
    setOpenProduct(false);
    setChecked([]);
    setCheckedProduct([]);
  };

  const individualCheck = (item: StockItemModel) => {
    setChecked(prevChecked => {
      if (prevChecked.some(value => value.ProductId === item.ProductId)) {
        setCheckedProduct(prevProduct => prevProduct.filter(value => value.id !== item.ProductId));
        return prevChecked.filter(value => value.ProductId !== item.ProductId);
      } else {
        setCheckedProduct(prevProduct => [...prevProduct, products.find(value => value.id === item.ProductId)!]);
        return [...prevChecked, item];
      }
    });
  };

  const individualQty = (item: StockItemModel) => {
    setChecked(prevState =>
      prevState.map(value => {
        if (value.ProductId === item.ProductId) {
          const [unitNetPrice, totalNet] = netPrice(item);

          value = {
            ...item,
            unitNetPrice,
            totalPrice: totalNet
          };
        } else {
          const [unitNetPrice, totalNet] = netPrice(value);
          value.unitNetPrice = unitNetPrice;
          value.totalPrice = totalNet;
        }
        return value;
      })
    );
  };

  useEffect(() => {
    if (logisticPrice < 1 && checked.length < 1) {
      return;
    }

    setChecked(
      checked.map(value => {
        const [unitNetPrice, totalNet] = netPrice(value);
        value.unitNetPrice = unitNetPrice;
        value.totalPrice = totalNet;
        return value;
      })
    );
  }, [logisticPrice, checkedProduct]);

  useEffect(() => {
    if (!isSubmit) {
      return;
    }

    handleOnSubmit();
  }, [isSubmit]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item lg={6} sm={6} md={6} xs={6}>
        <Autocomplete
          id='purchasesNumber'
          value={purchaseInvoice}
          options={purchaseInvoices}
          getOptionLabel={option => option.invoiceNumber}
          getOptionSelected={(option, value) => option.id === value.id}
          loading={isLoadingPurchaseInvoice}
          loadingText='Memuat...'
          onChange={(event: any, value: any) => {
            if (value) {
              setPurchaseInvoice(value);
              setPartner(value.Partner);
              setPartners([value.Partner]);
              setCheckedProduct(value.PurchaseInvoiceItem.map((_value: PurchaseInvoiceItemModel) => _value.Product));
              setLogisticPrice(value.logisticPrice);
              setChecked(
                value.PurchaseInvoiceItem.map((item: PurchaseInvoiceItemModel) => ({
                  ...dummyStockItem,
                  purchasePrice: item.price,
                  typeUnit: item.typeUnit,
                  totalPrice: item.totalPrice,
                  totalItem: item.totalItem,
                  ProductId: item.ProductId
                }))
              );
            }
          }}
          onOpen={() => handleSearchPurchaseInvoice('')}
          renderInput={params => (
            <TextField
              {...params}
              label='Pilih / Masukkan Nomor Pembelian'
              onChange={e => handleSearchPurchaseInvoice(e.target.value)}
              error={purchaseInvoiceError !== ''}
              helperText={purchaseInvoiceError}
            />
          )}
        />
      </Grid>

      <Grid item lg={6} sm={6} md={6} xs={6}>
        <TextField
          id='enterDate'
          required
          fullWidth
          label='Tanggal Masuk'
          value={enterDate ? format(enterDate, 'yyyy-MM-dd') : ''}
          type='date'
          onChange={event => setEnterDate(new Date(event.target.value))}
          error={enterDateMessage !== ''}
          helperText={enterDateMessage}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item lg={6} sm={6} md={6} xs={6}>
        <Autocomplete
          id='partner'
          options={partners}
          value={partner}
          getOptionLabel={option => option.name}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={(event: any, value: any) => {
            if (value) {
              setPartner(value);
            }
          }}
          onOpen={() => handleSearchPartner('')}
          renderInput={params => <TextField {...params} label=' Pilih Supplier' onChange={e => handleSearchPartner(e.target.value)} />}
        />
      </Grid>

      <Grid item lg={6} sm={6} md={6} xs={6}>
        <TextField
          multiline
          rows={4}
          id='description'
          fullWidth
          label='Deskripsi Produk'
          value={description}
          onChange={event => setDescription(event.target.value)}
        />
      </Grid>

      <Grid item lg={6} sm={6} md={6} xs={6}>
        <Autocomplete
          id='warehouse'
          className={classes.warehouse}
          options={warehouses}
          getOptionLabel={option => option.name}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={(event: any, value: any) => value && setWarehouse(value)}
          onOpen={() => handleSearchWareHouse('')}
          renderInput={params => (
            <TextField
              {...params}
              label='Gudang Tujuan'
              error={wareHouseMessage !== ''}
              helperText={wareHouseMessage}
              onChange={e => handleSearchWareHouse(e.target.value)}
            />
          )}
        />
      </Grid>

      <Grid item lg={6} sm={6} md={6} xs={6}>
        <TextField
          id='logisticPrice'
          fullWidth
          label='Harga Logistik'
          value={logisticPrice > 0 ? logisticPrice : ''}
          onChange={event => setLogisticPrice(+event.target.value)}
          helperText={
            logisticPrice > 0 ? (
              <NumberFormat
                value={logisticPrice === 0 ? 0 : Math.ceil(logisticPrice / checked.length)}
                prefix={'Rp'}
                thousandSeparator={true}
                displayType='text'
              />
            ) : (
              ''
            )
          }
          InputProps={{
            inputComponent: NumberFormatMask as any
          }}
        />
      </Grid>

      <Grid container item lg={12} sm={12} md={12} xs={12}>
        <Typography variant='h5'>Semua Produk</Typography>
        {checkedProduct.length === 0 ? (
          <Fragment>
            <img src={empty} className={classes.image} />
            <Typography variant='subtitle1' align='center'>
              Belum ada produk yang ditambahkan dalam paket ini :(
            </Typography>
          </Fragment>
        ) : (
          <TableContainer>
            <TableCustom>
              <TableHead>
                <HeaderRow
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  headers={[
                    { label: '', pR: '10px', pT: '7px', verticalAlign: 'top' },
                    { id: 'productName', label: 'Nama Produk', sort: true },
                    { id: 'purchasePrice', label: 'Harga Beli', sort: true },
                    { id: 'totalItem', label: 'Jumlah', sort: true },
                    { id: 'totalPrice', label: 'Subtotal', sort: true, align: 'center' },
                    { id: 'unitNetPrice', label: 'Modal Bersih', sort: true, align: 'center' },
                    { label: 'Total', align: 'center' }
                  ]}
                />
              </TableHead>
              <TableBody>
                {checkedProduct.length > 0 &&
                  checkedProduct.map((value, index) => (
                    <BodyRow
                      key={index + 1}
                      index={index}
                      item={checked[index]}
                      isEditable={true}
                      product={value}
                      isLoading={isLoadingData}
                      checked={checked}
                      handleIndividualCheck={individualCheck}
                      handleIndividualQty={individualQty}
                    />
                  ))}
              </TableBody>
            </TableCustom>
          </TableContainer>
        )}
      </Grid>

      <Grid item container lg={12} sm={12} md={12} xs={12} spacing={2} justify='flex-end'>
        <Button variant='text' onClick={handleClickOpen}>
          Tambahkan Produk Lainya
        </Button>
      </Grid>

      <ProductModal
        count={count}
        openProduct={openProduct}
        handleClose={handleClose}
        queryString={queryString}
        setQueryString={setQueryString}
        categories={categories}
        setCategory={setCategory}
        handleSearchCategory={handleSearchCategory}
        order={order}
        orderBy={orderBy}
        handleRequestSort={handleRequestSort}
        isLoadingData={isLoadingData}
        checked={checked}
        individualCheck={individualCheck}
        products={products}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        handleCancelProduct={handleCancelProduct}
      />
    </Grid>
  );
};

export default StockWizard;
