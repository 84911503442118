import React, { FC, useState, useEffect, useContext, useMemo } from 'react';
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
  TextField,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Menu,
  MenuItem
} from '@material-ui/core';
import { Page, Breadcrumb, StandardConfirmationDialog, NumberFormatMask } from 'components';
import { BLUE_PRIMARY, WHITE, GREEN, RED } from 'constants/colors';
import {
  GET_RETURN_INVOICE_DETAIL_BASE_URL,
  COMPANY_BASE_URL,
  RETURN_ITEM_BASE_URL,
  RETURN_INVOICE_BASE_URL,
  PARTNER_DETAIL_BASE_URL,
  GET_USER_DETAIL_URL,
  RETURN_BILL_BASE_URL
} from 'constants/url';
import { dummyInvoiceReturn, dummyCompany, dummyMiniReturnItem } from 'utils/dummy';
import axios from 'axios';
import useRouter from 'hooks/useRouter';
import ReturnDetail from './components/ReturnDetail';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import useRole from 'hooks/useRole';
import TypeUser from 'typings/enum/TypeUser';
import { CloudDownload, Create, DeleteForever, Description, ExpandMore, PostAddRounded, PrintRounded } from '@material-ui/icons';
import FlexBox from 'components/FlexBox';

const useStyles = makeStyles({
  containerAaction: {
    marginTop: '4em'
  },
  ButtonNew: {
    color: BLUE_PRIMARY,
    backgroundColor: WHITE
  },
  finish: {
    background: GREEN,
    color: WHITE
  },
  delete: {
    background: RED,
    color: WHITE
  }
});

const ReturnDetailPage: FC = () => {
  const { location, history } = useRouter();
  // eslint-disable-next-line
  const params: any = location.state;
  const classes = useStyles();
  const { currentUser } = useContext(CurrentUserContext);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN, TypeUser.ADMIN03]
  });
  const isAdminInvoice = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN03]
  });

  const isAdmin = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: ['ADMIN']
  });

  const [returnOrder, setReturnOrder] = useState<InvoiceReturnModel>(dummyInvoiceReturn);
  const [company, setCompany] = useState<CompanyModel>(dummyCompany);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [isLoadingCompany, setIsLoadingCompany] = useState<boolean>(true);
  const [isLoadingItem, setIsLoadingItem] = useState<boolean>(true);
  const [isLoadingPartner, setIsLoadingPartner] = useState<boolean>(true);
  const [id, setId] = useState<number>(0);
  const [returnOrderItem, setReturnOrderItem] = useState<InvoiceReturnItemModel[]>([]);
  const [item, setItem] = useState<{ id: number; totalDamage: number; totalMerge: number; totalReturned: number }>(dummyMiniReturnItem);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
  const [totalCredit, setTotalCredit] = useState<number>(0);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [isDelete, setDelete] = useState<boolean>(false);
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [isAction, setIsAction] = useState<null | HTMLElement>(null);
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);

  const handleOpenCollapse = (index: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);

    const current = returnOrderItem.find(value => value.id === index);

    if (openCollapse) {
      if (index === indexCollapse) {
        setOpenCollapse(false);
        setItem(dummyMiniReturnItem);
      } else {
        setOpenCollapse(true);

        if (current) {
          setItem({
            id: current.id,
            totalDamage: current.totalDamage,
            totalMerge: current.totalMerge,
            totalReturned: current.totalReturned || 0
          });
        }
      }
    } else {
      setOpenCollapse(true);

      if (current) {
        setItem({
          id: current.id,
          totalDamage: current.totalDamage,
          totalMerge: current.totalMerge,
          totalReturned: current.totalReturned || 0
        });
      }
    }
  };

  const hasReturned = returnOrderItem.some(item => item.totalReturned > 0);
  const hasSubmitAction = returnOrderItem.some(item => {
    return (item.totalMerge || item.totalReturned || item.totalDamage) > 0;
  });

  const hasValidationAllAction = useMemo(
    () =>
      returnOrderItem.every(item => {
        return (item.totalDamage || item.totalMerge || item.totalReturned) > 0;
      }),
    [returnOrderItem]
  );

  const handleConvetInvoiceTR = async () => {
    setIsLoadingData(true);
    const dataTemp = {
      partnerId: returnOrder.Partner && returnOrder.Partner.id,
      returnId: returnOrder.id,
      items: returnOrderItem
        .filter(item => item.totalReturned > 0)
        .map(item => ({
          id: 0,
          ProductId: item.ProductId,
          typeUnit: item.typeUnit,
          totalReturned: item.totalReturned,
          price: item.price
        }))
    };
    try {
      await axios.post(RETURN_BILL_BASE_URL, dataTemp);
      handleSnackBar(true, 'success', 'Return Penjualan berhasil dikonversi menjadi Invoice Tagihan Return');
    } catch (error) {
      console.log('error:', error);
      const err = error as { data: { message: string } };
      handleSnackBar(true, 'error', err.data.message);
      handleCloseAction();
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const fetchData = async () => {
    setIsLoadingData(true);

    try {
      const { data } = await axios.get(GET_RETURN_INVOICE_DETAIL_BASE_URL(params.id));
      setReturnOrder(data.data);
      setTotalCredit(data.data.totalCredit);
      setId(data.data.id);
      setIsLoadingData(false);
      fetchAll(data.data.PartnerId, params.id, data.data.SalesId);
    } catch (error) {
      console.log('error:', error);
    } finally {
    }
  };

  const fetchAll = (prtId: number, rtnId: number, slsId: number) => {
    Promise.all([
      axios.get(PARTNER_DETAIL_BASE_URL(prtId)).catch(() => undefined),
      axios.get(`${RETURN_INVOICE_BASE_URL}/${rtnId}/items`).catch(() => undefined),
      axios.get(GET_USER_DETAIL_URL(slsId)).catch(() => undefined),
      axios.get(COMPANY_BASE_URL).catch(() => undefined)
    ])
      .then((result: any) => {
        const [data0, data1, data2, data3] = result;

        if (data0) {
          setReturnOrder(prevState => ({ ...prevState, Partner: data0.data.data }));

          setIsLoadingPartner(false);
        }

        if (data1) {
          setReturnOrderItem(data1.data.data);
          setIsLoadingItem(false);
        }

        if (data2) {
          setReturnOrder(prevState => ({ ...prevState, Sales: data2.data.data }));
        }

        if (data3) {
          setCompany(data3.data.data);
          setIsLoadingCompany(false);
        }
      })
      .finally(() => {
        setIsLoadingPartner(false);
        setIsLoadingItem(false);
        setIsLoadingCompany(false);
      });
  };

  const handleClickAction = (event: React.MouseEvent<HTMLButtonElement>) => setIsAction(event.currentTarget);

  const handleCloseAction = () => setIsAction(null);

  const handleUpdateItem = async () => {
    setLoadingUpdate(true);
    try {
      const { data } = await axios.post(RETURN_ITEM_BASE_URL, item);
      setReturnOrderItem(prevState =>
        prevState.map(value => {
          if (value.id === data.data.id) {
            value = data.data;
          }

          return value;
        })
      );
      handleSnackBar(true, 'success', 'Return berhasil diperbaharui');
      setOpenCollapse(false);
      setIndexCollapse(-1);
    } catch (error) {
      console.log('error:', error);
      handleSnackBar(true, 'error', 'Return gagal diperbaharui');
    } finally {
      setLoadingUpdate(false);
    }
  };

  const handleUpdateReturn = async () => {
    try {
      const { data } = await axios.post(`${RETURN_INVOICE_BASE_URL}/finish-return`, {
        id: returnOrder.id,
        totalCredit,
        status: 'DONE'
      });
      setReturnOrder(data.data);
      setReturnOrderItem(data.data.InvoiceReturnItem);
      handleSnackBar(true, 'success', ' Return berhasil diupdate.');
    } catch (error) {
      handleSnackBar(true, 'error', ' Return gagal diupdate.');
      console.log('error:', error);
    } finally {
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    if (isDelete) {
      setDelete(false);
      history.push(returnOrder.typeReturn === 'CUSTOMER' ? '/return-penjualan' : '/return-pembelian');
    }
  };

  const handleConfirmationDelete = () => {
    setConfirmationDelete(true);
  };

  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  const deleteReturn = async () => {
    try {
      await axios.delete(`${RETURN_INVOICE_BASE_URL}/${id}`);
      handleSnackBar(true, 'success', 'Return berhasil dihapus.');
      setDelete(true);
    } catch (err) {
      console.log(err);
      handleSnackBar(true, 'error', 'Return gagal dihapus.');
    } finally {
      setConfirmationDelete(false);
    }
  };

  const printPdf = async () => {
    setIsLoadingData(true);
    try {
      const { data } = await axios.get(`${RETURN_INVOICE_BASE_URL}/print/${id}`, { responseType: 'blob' });

      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
      if (newwindow) {
        newwindow.focus();
      }
    } catch (error) {
      console.log('error :', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const downloadPdf = async () => {
    setIsLoadingData(true);
    try {
      const { data } = await axios.get(`${RETURN_INVOICE_BASE_URL}/print/${id}`, { responseType: 'blob' });

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'return.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log('error :', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleLink = () => {
    history.push(`/${returnOrder.typeReturn === 'CUSTOMER' ? 'return-penjualan/penjualan' : 'return-pembelian/pembelian'}`, { id: params.id });
  };

  useEffect(() => {
    fetchData();
  }, [params]);

  return (
    <Page title='Return Order'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <Typography variant='h1'> Return Order </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
          <Grid container justify='flex-end' alignItems='center' spacing={1} item lg={8} md={8} sm={12} xs={12}>
            {!isAdmin && (
              <Grid item>
                {returnOrder.status !== 'DONE' && returnOrder.typeReturn === 'SUPPLIER' && (
                  <TextField
                    label='Credit'
                    onChange={e => setTotalCredit(+e.target.value)}
                    value={totalCredit}
                    error={totalCredit === 0}
                    helperText={totalCredit === 0 ? 'Total kredit tidak boleh kosong' : ''}
                    InputProps={{
                      inputComponent: NumberFormatMask as any
                    }}
                  />
                )}
              </Grid>
            )}

            <Grid item>
              {returnOrder.status !== 'DONE' && returnOrder.typeReturn === 'SUPPLIER' && (
                <Button disabled={totalCredit === 0} onClick={handleUpdateReturn} className={classes.finish}>
                  Selesai
                </Button>
              )}
            </Grid>

            <Grid item>
              <Button
                className={classes.ButtonNew}
                onClick={handleLink}
                variant='outlined'
                disabled={returnOrder.totalUsage && returnOrder.totalUsage > 0 ? true : false || hasSubmitAction || isLoadingItem}
              >
                Edit Data
              </Button>
            </Grid>

            <Grid item>
              <Button aria-controls='simple-menu' aria-haspopup='true' onClick={handleClickAction} endIcon={<ExpandMore />} disabled={isLoadingData}>
                {isLoadingData ? <CircularProgress size={20} color='primary' /> : 'Pilih Aksi'}
              </Button>
              <Menu
                id='simple-menu'
                anchorEl={isAction}
                className={classes.containerAaction}
                keepMounted
                open={Boolean(isAction)}
                onClose={handleCloseAction}
              >
                {hasReturned && !!!returnOrder.hasConvert && (
                  <MenuItem
                    onClick={() => {
                      handleConvetInvoiceTR();
                      handleCloseAction();
                    }}
                    disabled={!hasValidationAllAction || isLoadingItem}
                  >
                    <FlexBox container columnGap={0.5}>
                      <PostAddRounded /> Konversi Invoice TR
                    </FlexBox>
                  </MenuItem>
                )}

                <MenuItem
                  onClick={() => {
                    downloadPdf();
                    handleCloseAction();
                  }}
                >
                  <FlexBox container columnGap={0.5}>
                    <CloudDownload /> Download
                  </FlexBox>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    printPdf();
                    handleCloseAction();
                  }}
                >
                  <FlexBox container columnGap={0.5}>
                    <PrintRounded /> Cetak
                  </FlexBox>
                </MenuItem>

                {isSuperAdmin && (
                  <MenuItem
                    onClick={() => {
                      handleConfirmationDelete();
                      handleCloseAction();
                    }}
                    disabled={returnOrder.totalUsage && returnOrder.totalUsage > 0 ? true : false}
                  >
                    <FlexBox container columnGap={0.5}>
                      <DeleteForever color='error' />
                      <Typography color='error'>Hapus</Typography>
                    </FlexBox>
                  </MenuItem>
                )}
              </Menu>
            </Grid>
          </Grid>
        </Grid>

        <ReturnDetail
          loadingUpdate={loadingUpdate}
          returnOrder={returnOrder}
          returnOrderItem={returnOrderItem}
          company={company}
          isLoadingData={isLoadingData}
          isLoadingCompany={isLoadingCompany}
          isLoadingItem={isLoadingItem}
          isAdminInvoice={isAdminInvoice}
          isLoadingPartner={isLoadingPartner}
          item={item}
          isAdmin={isAdmin}
          isSuperAdmin={isSuperAdmin}
          isSmall={isSmall}
          indexCollapse={indexCollapse}
          openCollapse={openCollapse}
          setItem={setItem}
          handleUpdateItem={handleUpdateItem}
          handleOpenCollapse={handleOpenCollapse}
        />
        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
          onConfirm={handleCloseSnackbar}
          noCancelButton={true}
        />
        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
          onConfirm={handleCloseSnackbar}
          noCancelButton={true}
        />
        <StandardConfirmationDialog
          variant={'danger'}
          titleMessage={'Delete'}
          message={'Apakah kamu yakin menghapus data ini?'}
          open={confirmationDelete}
          handleClose={handleCloseConfirmationDelete}
          onConfirm={deleteReturn}
        />
      </Container>
    </Page>
  );
};

export default ReturnDetailPage;
