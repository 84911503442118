import React, { FC } from 'react'
import NumberFormat from 'react-number-format';
import { Grid, Typography } from '@material-ui/core';

interface Props {
    returnNumber: string;
    totalItem: number;
    totalPrice: number;
};

const ReturnList: FC<Props> = props => {
    const { returnNumber, totalItem, totalPrice } = props;

    return (
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Typography variant='body1'>{returnNumber}</Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Typography variant='body1'>{totalItem}</Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Typography variant='body1'>
            <NumberFormat value={totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          </Typography>
        </Grid>
      </Grid>
    );
};

export default ReturnList;
