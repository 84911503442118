import React, { FC, Fragment } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Checkbox, TextField } from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart } from 'components/Table/TableCellCustom';
import TableRowCustom from 'components/Table/TableRowCustom';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import NumberFormat from 'react-number-format';
import NumberFormatMask from 'components/NumberFormatMask';

interface Props {
  product: ProductModel;
  item: StockItemModel;
  isLoading: boolean;
  checked: StockItemModel[];
  index: number;
  isEditable: boolean;
  handleIndividualCheck: (item: StockItemModel) => void;
  handleIndividualQty: (item: StockItemModel) => void;
}

const BodyRow: FC<Props> = props => {
  const { item, product, isLoading, checked, isEditable, handleIndividualCheck, handleIndividualQty, index } = props;
  const { id } = product;

  let isChecked = checked.some(checkedValue => checkedValue.ProductId === id);

  const handleSetId = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    handleIndividualCheck({ ...item, ProductId: id });
  };

  const handleSetQty = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleIndividualQty({
      ...item,
      totalItem: +event.target.value
    });
  };

  const handleSetPurchasePrice = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleIndividualQty({
      ...item,
      purchasePrice: +event.target.value
    });
  };

  return (
    <TableRowCustom key={index + 1}>
      {isEditable ? (
        <Fragment>
          <TableCellStart>
            {isLoading ? (
              <Skeleton variant='text' width={'100%'} height={25} />
            ) : (
              <Checkbox
                key={id}
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                edge='start'
                color='primary'
                checked={isChecked}
                tabIndex={-1}
                disableRipple
                onChange={handleSetId}
              />
            )}
          </TableCellStart>
          <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : product.productName}</TableCellMiddle>
        </Fragment>
      ) : (
        <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : product.productName}</TableCellMiddle>
      )}

      {isEditable ? (
        <TableCellMiddle>
          {isLoading ? (
            <Skeleton variant='text' width={'100%'} height={25} />
          ) : (
            <TextField
              disabled={!isChecked}
              label='Harga Beli'
              onChange={handleSetPurchasePrice}
              value={item.purchasePrice}
              error={isChecked && item.purchasePrice === 0}
              InputProps={{
                inputComponent: NumberFormatMask as any
              }}
            />
          )}
        </TableCellMiddle>
      ) : (
        <TableCellMiddle>
          {isLoading ? (
            <Skeleton variant='text' width={'100%'} height={25} />
          ) : (
            <NumberFormat value={item.purchasePrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>
      )}

      {isEditable ? (
        <TableCellMiddle>
          {isLoading ? (
            <Skeleton variant='text' width={'100%'} height={25} />
          ) : (
            <TextField
              disabled={!isChecked}
              type='number'
              label='Tambah Stok'
              onChange={handleSetQty}
              value={item.totalItem}
              error={isChecked && item.totalItem === 0}
            />
          )}
        </TableCellMiddle>
      ) : (
        <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : item.totalItem}</TableCellMiddle>
      )}

      <TableCellMiddle align='right'>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <NumberFormat value={item.totalItem * item.purchasePrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        )}
      </TableCellMiddle>

      <TableCellMiddle align='right'>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <NumberFormat value={item.unitNetPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        )}
      </TableCellMiddle>

      <TableCellEnd>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <NumberFormat value={item.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        )}
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRow;
