import React, { FC } from 'react';
import { Grid, makeStyles, Theme, Button, useMediaQuery, useTheme, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import { PaperCustom } from 'components';
import useRouter from 'hooks/useRouter';
import SalesOrderInfo from './components/SalesOrderInfo';
import SalesOrderList from './components/SalesOrderList';
import { MAP_URL } from 'constants/url';
import { WHITE, GREEN, RED, BLACK } from 'constants/colors';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ProduckRequest from './components/ProduckRequest';
interface Props {
  salesOrder: SalesOrderModel;
  salesOrderItem: SalesOrderItemModel[];
  company: CompanyModel;
  isLoadingData: boolean;
  value: number;
  isChange: boolean;
  hasInvoice: boolean;
  isChangeSubmit: boolean;
  isConvertAllowed: boolean;
  consistenMessage: string;
  currentWareHouse: string;
  totalWarehouse: { name: string; total: number }[];
  currentCheckWarehouse: string;
  anchorEl: any;
  buttonGroup: React.RefObject<HTMLDivElement>;
  totalApprove: number;
  isValid: boolean;
  openTooltip: boolean;
  indexTooltip: number;
  handleOpenTooltip: (index: number) => React.MouseEventHandler;
  handleCloseTooltip: () => void;
  handleOpenConfirmationDelete: () => void;
  handleChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  handleConfirm: (index: number) => React.MouseEventHandler;
  handleReject: (index: number) => React.MouseEventHandler;
  handleOnSubmit: () => void;
  handleReset: () => void;
  download: () => void;
  setCurrentCheckWarehouse: React.Dispatch<React.SetStateAction<string>>;
  openAlertPrice: boolean;
  indexAlertPrice: number;
  handleOpenAlertPrice: (index: number) => React.MouseEventHandler;
  handleCloseAlertPrice: () => void;

  openAlertDiscount: boolean;
  indexAlertDiscount: number;
  handleOpenAlertDiscount: (index: number) => React.MouseEventHandler;
  handleCloseAlertDiscount: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    position: 'fixed',
    top: 0,
    zIndex: 1,
    backgroundColor: WHITE
  },
  ButtonNew: {
    backgroundColor: WHITE
  },
  ButtonDelete: {
    color: WHITE,
    backgroundColor: RED
  },
  buttonDirect: {
    color: WHITE,
    backgroundColor: GREEN
  },

  mobile: {
    [theme.breakpoints.up('md')]: {
      display: ' none'
    }
  },

  desktop: {
    [theme.breakpoints.down('md')]: {
      display: ' none'
    }
  }
}));

const Content: FC<Props> = props => {
  const {
    salesOrder,
    isLoadingData,
    company,
    salesOrderItem,
    value,
    isChange,
    hasInvoice,
    isChangeSubmit,
    isConvertAllowed,
    consistenMessage,
    currentWareHouse,
    totalWarehouse,
    currentCheckWarehouse,
    anchorEl,
    buttonGroup,
    totalApprove,
    isValid,
    openTooltip,
    indexTooltip,
    handleOpenTooltip,
    handleCloseTooltip,
    setCurrentCheckWarehouse,
    handleOnSubmit,
    handleOpenConfirmationDelete,
    handleChange,
    handleConfirm,
    handleReject,
    handleReset,
    openAlertPrice,
    indexAlertPrice,
    handleOpenAlertPrice,
    handleCloseAlertPrice,
    openAlertDiscount,
    indexAlertDiscount,
    handleOpenAlertDiscount,
    handleCloseAlertDiscount
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const { history } = useRouter();
  const showComponent = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container direction='row' item lg={12} sm={12} md={12} xs={12}>
      <AppBar className={classes.header}>
        <Toolbar>
          <IconButton edge='start' aria-label='back'>
            <ArrowBackIcon onClick={() => history.goBack()} />
          </IconButton>
          <Typography variant='h6'>Detail Orderan Penjual</Typography>
        </Toolbar>
      </AppBar>
      <PaperCustom>
        <Grid container direction='row' spacing={2} item lg={12} sm={12} md={12} xs={12}>
          <SalesOrderInfo salesOrder={salesOrder} isLoadingData={isLoadingData} company={company} />

          <SalesOrderList
            isConvertAllowed={isConvertAllowed}
            salesOrderItem={salesOrderItem}
            salesOrder={salesOrder}
            company={company}
            value={value}
            isLoadingData={isLoadingData}
            isChange={isChange}
            hasInvoice={hasInvoice}
            isChangeSubmit={isChangeSubmit}
            consistenMessage={consistenMessage}
            currentWareHouse={currentWareHouse}
            totalWarehouse={totalWarehouse}
            anchorEl={anchorEl}
            buttonGroup={buttonGroup}
            totalApprove={totalApprove}
            isValid={isValid}
            handleChange={handleChange}
            handleConfirm={handleConfirm}
            handleReject={handleReject}
            handleOnSubmit={handleOnSubmit}
            handleReset={handleReset}
            currentCheckWarehouse={currentCheckWarehouse}
            setCurrentCheckWarehouse={setCurrentCheckWarehouse}
            indexTooltip={indexTooltip}
            openTooltip={openTooltip}
            handleOpenTooltip={handleOpenTooltip}
            handleCloseTooltip={handleCloseTooltip}
            openAlertPrice={openAlertPrice}
            indexAlertPrice={indexAlertPrice}
            handleOpenAlertPrice={handleOpenAlertPrice}
            handleCloseAlertPrice={handleCloseAlertPrice}
            openAlertDiscount={openAlertDiscount}
            indexAlertDiscount={indexAlertDiscount}
            handleOpenAlertDiscount={handleOpenAlertDiscount}
            handleCloseAlertDiscount={handleCloseAlertDiscount}
          />
        </Grid>
      </PaperCustom>

      {showComponent && (
        <PaperCustom>
          <Grid container spacing={2} item lg={12} sm={12} md={12} xs={12}>
            <ProduckRequest
              isConvertAllowed={isConvertAllowed}
              salesOrderItem={salesOrderItem}
              salesOrder={salesOrder}
              company={company}
              value={value}
              isLoadingData={isLoadingData}
              isChange={isChange}
              hasInvoice={hasInvoice}
              isChangeSubmit={isChangeSubmit}
              consistenMessage={consistenMessage}
              currentWareHouse={currentWareHouse}
              totalWarehouse={totalWarehouse}
              anchorEl={anchorEl}
              buttonGroup={buttonGroup}
              totalApprove={totalApprove}
              isValid={isValid}
              handleChange={handleChange}
              handleConfirm={handleConfirm}
              handleReject={handleReject}
              handleOnSubmit={handleOnSubmit}
              handleReset={handleReset}
              currentCheckWarehouse={currentCheckWarehouse}
              setCurrentCheckWarehouse={setCurrentCheckWarehouse}
              indexTooltip={indexTooltip}
              openTooltip={openTooltip}
              handleOpenTooltip={handleOpenTooltip}
              handleCloseTooltip={handleCloseTooltip}
              openAlertPrice={openAlertPrice}
              indexAlertPrice={indexAlertPrice}
              handleOpenAlertPrice={handleOpenAlertPrice}
              handleCloseAlertPrice={handleCloseAlertPrice}
              openAlertDiscount={openAlertDiscount}
              indexAlertDiscount={indexAlertDiscount}
              handleOpenAlertDiscount={handleOpenAlertDiscount}
              handleCloseAlertDiscount={handleCloseAlertDiscount}
            />
          </Grid>
        </PaperCustom>
      )}
    </Grid>
  );
};

export default Content;
