import React, { FC, useContext } from 'react';
import { TableRow, TableCell, Table, TableBody, Grid, Button, Typography, Tooltip, Divider } from '@material-ui/core';
import { TableRowCollapse } from 'components';
import NumberFormat from 'react-number-format';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import useRole from 'hooks/useRole';
import TypeUser from 'typings/enum/TypeUser';

interface Props {
  item: InvoiceReturnItemModel;
  isLoading: boolean;
  typeReturn: string;
  index: number;
  indexCollapse: number;
  currentIndex: number;
  openCollapse: boolean;
  isAdmin: boolean;
  handleUpdateItem: (index: number) => React.MouseEventHandler;
  handleDeleteItem: (index: number) => React.MouseEventHandler;
  handleCollapse: React.MouseEventHandler;
}

const BodyRow: FC<Props> = props => {
  const { item, index, indexCollapse, isAdmin, openCollapse, handleCollapse, handleUpdateItem, handleDeleteItem, typeReturn } = props;
  const { currentUser } = useContext(CurrentUserContext);

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: ['SUPERADMIN', TypeUser.ADMIN03]
  });
  return (
    <>
      <TableRow>
        <TableCell style={{ padding: '4px 0px 4px 0px' }}>
          <Grid container direction='column' spacing={1}>
            {typeReturn === 'CUSTOMER' && (
              <>
                <Grid item>
                  <Typography variant='h6'>{(item.Product && item.Product.productName) || '-'}</Typography>
                </Grid>

                <Grid item>
                  <NumberFormat value={item.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />

                  <span style={{ float: 'right' }}>{`${item.totalItem} ${item.typeUnit}`}</span>
                </Grid>

                <Grid item>
                  <Divider />
                </Grid>

                <Grid item>
                  Total
                  <span style={{ float: 'right' }}>
                    <NumberFormat value={item.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  </span>
                </Grid>

                <Grid item container justify='space-between' spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <Tooltip title='Hapus'>
                      <Button fullWidth color='inherit' variant='text' style={{ color: '#A9A9A9' }} onClick={handleDeleteItem(index)} size='small'>
                        <u> Hapus </u>
                      </Button>
                    </Tooltip>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <Tooltip title='Ubah'>
                      <Button fullWidth onClick={handleUpdateItem(index)} variant='text' size='small'>
                        <u> Ubah </u>
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              </>
            )}

            {typeReturn === 'SUPPLIER' && (
              <Grid item>
                <Typography align='center' variant='h6'>
                  {(item.Product && item.Product.productName) || '-'}
                </Typography>
              </Grid>
            )}

            {typeReturn === 'SUPPLIER' && !openCollapse && index !== indexCollapse && (
              <Grid item>
                <Button size='small' fullWidth onClick={handleCollapse} variant='text'>
                  <u style={{ textAlign: 'center' }}> Tampilkan lebih banyak</u>
                </Button>
              </Grid>
            )}
          </Grid>
        </TableCell>
      </TableRow>

      <TableRowCollapse open={openCollapse && index === indexCollapse} colSpan={1}>
        <Table size='small'>
          <TableBody>
            {typeReturn === 'SUPPLIER' && isSuperAdmin && (
              <TableRow>
                <TableCell>Harga</TableCell>
                <TableCell align='right'>
                  <NumberFormat value={item.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>Qty (Penjualan)</TableCell>
              <TableCell align='right'>{item.totalItem + ' ' + item.typeUnit}</TableCell>
            </TableRow>
            {typeReturn === 'SUPPLIER' && (
              <TableRow>
                <TableCell>Qty (Gudang)</TableCell>
                <TableCell align='right'>{item.additionalDamage + ' ' + item.typeUnit}</TableCell>
              </TableRow>
            )}
            {typeReturn === 'SUPPLIER' && isSuperAdmin && (
              <TableRow>
                <TableCell>Total Harga</TableCell>
                <TableCell align='right'>
                  <NumberFormat value={item.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </TableCell>
              </TableRow>
            )}
            {item.id == 0 && (
              <TableRow>
                <TableCell>
                  <Tooltip title='Hapus'>
                    <Button color='inherit' variant='text' style={{ color: '#A9A9A9' }} fullWidth onClick={handleDeleteItem(index)} size='small'>
                      <u> Hapus</u>
                    </Button>
                  </Tooltip>
                </TableCell>

                <TableCell>
                  <Tooltip title='Ubah'>
                    <Button fullWidth variant='text' onClick={handleUpdateItem(index)} size='small'>
                      <u>Ubah</u>
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableRowCollapse>

      {openCollapse && index === indexCollapse && (
        <TableRow>
          <TableCell align='center'>
            <Button fullWidth size='small' onClick={handleCollapse} variant='text'>
              <u style={{ textAlign: 'center' }}> Tampilkan lebih sedikit </u>
            </Button>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default BodyRow;
