import React, { FC } from 'react';
import { TableBody, TableHead, TableRow, TableCell, TableContainer, makeStyles } from '@material-ui/core';
import BodyRow from './components/BodyRow';
import { TableCustom } from 'components/Table';
import { dummyStockCheckItem } from 'utils/dummy';
interface Props {
  isLoadingData: boolean;
  items: StockCheckItemModel[];
  isFirstStock: boolean;
  itemAdjustment: StockCheckItemModel[];
  indexCollapse: number;
  openCollapse: boolean;
  isLoadingCollapse: boolean;
  term: string;
  handleOpenCollapse: (ProductId: number, StockCheckId: number) => React.MouseEventHandler;
}

const useStyles = makeStyles({
  container: {
    maxHeight: 440
  }
});

const ProductTable: FC<Props> = props => {
  const classes = useStyles();
  const { isLoadingData, items, isFirstStock, itemAdjustment, isLoadingCollapse, indexCollapse, openCollapse, term, handleOpenCollapse } = props;
  const search = new RegExp(term, 'i');

  return (
    <TableContainer className={classes.container}>
      <TableCustom stickyHeader size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Produk</TableCell>
            <TableCell align='center'>Satuan</TableCell>
            <TableCell align='center'>Stok Awal </TableCell>
            <TableCell align='center'>Stok Berjalan</TableCell>
            <TableCell align='center'>HPP</TableCell>
            <TableCell align='center'>Stock Adjustment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingData &&
            [0, 1, 2, 3].map(index => (
              <BodyRow
                key={index}
                index={index}
                isLoading={isLoadingData}
                isFirstStock={isFirstStock}
                item={dummyStockCheckItem}
                itemAdjustment={[]}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                isLoadingCollapse={isLoadingCollapse}
                handleOpenCollapse={handleOpenCollapse(0, 0)}
              />
            ))}
          {items
            .filter(value => term === '' || search.test(value.productName || ''))
            .map((value, index) => (
              <BodyRow
                key={index}
                index={index}
                isFirstStock={isFirstStock}
                isLoading={isLoadingData}
                item={value}
                itemAdjustment={itemAdjustment}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                isLoadingCollapse={isLoadingCollapse}
                handleOpenCollapse={handleOpenCollapse(value.ProductId, value.StockCheckId)}
              />
            ))}
          {items.length === 0 && !isLoadingData && (
            <TableRow>
              <TableCell colSpan={6} align='center'>
                Data Tidak Tersedia.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default ProductTable;
