import React, { memo, useState } from 'react';
import {
  DialogContent,
  Dialog,
  Grid,
  Typography,
  IconButton,
  TextField,
  makeStyles,
  Button,
  CircularProgress,
  MenuItem,
  Tabs,
  Tab
} from '@material-ui/core';
import { OPERATIONAL_BASE_URL } from 'constants/url';
import { Close, CloudDownload } from '@material-ui/icons';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { DialogTitle, NumberFormatMask } from 'components';
import { GREY, GREY_6, PRIMARY_MOBILE, WHITE } from 'constants/colors';
import { dummyOperasional } from 'utils/dummy';
import axios from 'axios';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LoyaltyIcon from '@material-ui/icons/Loyalty';

const useStyles = makeStyles({
  container: {
    marginTop: 10,
    columnGap: '1em'
  },

  iconLarge: {
    fontSize: '3.5em'
  },
  containerImage: {
    margin: '0.7em 0',
    rowGap: '0.7em'
  },
  uploadImage: {
    padding: '1em ',
    backgroundColor: GREY,
    border: '2px dashed ' + GREY_6
  },
  browser: {
    color: PRIMARY_MOBILE,
    cursor: 'pointer'
  },
  wrapperImage: {
    columnGap: '1em'
  },
  containerBoxImage: {
    position: 'relative'
  },
  image: {
    objectFit: 'contain',
    borderRadius: '0.5em',
    height: '4em'
  },
  closeImage: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  closeIcon: {
    backgroundColor: 'rgba(32, 42, 61, 0.88)',
    borderRadius: '50%',
    cursor: 'pointer',
    color: WHITE
  },
  tabPanel: {
    flexGrow: 1,
    marginBottom: '20px',
    display: 'flex',

    maxWidth: 500,
    justifyContent: 'center'
  },
  tab: {
    minWidth: 'auto', // Mengurangi lebar minimum Tab
    padding: '10px', // Mengurangi padding Tab
    margin: '0 4px' // Mengurangi jarak antar Tab
  }
});
interface FinancialAccountsModel {
  name: string;
}
interface Props {
  financialAccounts: AccountCategoryModel[];
  subAccountCategories: AccountCategoryModel[];
  open: boolean;
  handleCloseCreate: () => void;
  reFetchData: () => void;
}

const CreateLoan = ({ financialAccounts, subAccountCategories, open, handleCloseCreate, reFetchData }: Props) => {
  const classes = useStyles();
  const [image, setImage] = useState<{ path: string }[]>([]);
  const [imageBlob, setImageBlob] = useState<FileWithPath[]>([]);
  const [operasional, setOperasional] = useState<Operasional>(dummyOperasional);
  // const [financialAccounts, setFinancialAccounts] = useState<FinancialAccountsModel[]>([{ name: 'Kas Kecil' }, { name: 'Bank' }]);
  const [tab, setTab] = React.useState<number>(0);
  const [imageUrl, setImageUrl] = useState<string[]>([]);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const disabled = operasional.name === '' || operasional.financialAccount === '' || operasional.accountCategories === 0 || operasional.amount === 0;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setOperasional(prev => ({ ...prev, [id]: value }));
  };

  const handleTabs = (event: React.ChangeEvent<{}>, newValue: number): void => {
    setTab(newValue);
  };
  const handleSubmit = async () => {
    setIsSubmit(true);
    const dataFinal = {
      ...operasional,
      image
    };

    try {
      const { data } = await axios.post(OPERATIONAL_BASE_URL, dataFinal);

      if (image.length > 0) {
        let i = 0;
        data.data.image.map(async (value: any, index: number) => {
          if (image.filter(img => img.path === value.path).length) {
            // @ts-ignore
            const newImageKey = value.path;
            const fileExtension = newImageKey.split('.').pop();

            const myHeaders = new Headers();
            myHeaders.append('Content-Type', `image/${fileExtension}`);

            const config: RequestInit = {
              method: 'PUT',
              headers: myHeaders,
              body: imageBlob[i]
            };

            await fetch(value.url, config)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));

            i++;
          }
        });
      }

      reFetchData();
      reMounted();
    } catch (error) {
      console.log(error);
    }
  };

  const reMounted = () => {
    setOperasional(dummyOperasional);
    setIsSubmit(false);
    setImage([]);
    setImageBlob([]);
    setImageUrl([]);
  };
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpeg', 'image/png'],
    maxFiles: 5,
    onDrop: acceptedFiles => {
      setImage([]);
      setImageBlob([]);
      uploadImage(acceptedFiles);
    }
  });

  const uploadImage = (files: FileWithPath[]) => {
    files.map((value: any) => {
      setImageUrl([...imageUrl, URL.createObjectURL(value)]);
      setImage(prevState => [...prevState, { path: `${operasional.name}${new Date().getTime()}.${value.name.split('.').pop()}` }]);
      setImageBlob(prevState => [...prevState, value]);
    });
  };

  const handleCloseCreates = () => {
    reMounted();
    handleCloseCreate();
  };
  const handleDeleteImage = (index: number) => {
    setImageUrl(imageUrl.filter((val, key) => index !== key));
    setImage(image.filter((val, key) => index !== key));
    setImageBlob(imageBlob.filter((val, key) => index !== key));
  };

  return (
    <Grid container direction='row'>
      <Grid xs={12}>
        <Grid container justify='space-between' spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <TextField
              id='name'
              placeholder='cth. Biaya bensin, biaya parkir, biaya gaji karyawan'
              required
              fullWidth
              autoComplete='off'
              label='Nama Biaya'
              value={operasional.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <TextField
              id='date'
              label='Tanggal Transaksi'
              type='date'
              value={operasional.createdAt}
              onChange={event => setOperasional(prev => ({ ...prev, date: event.target.value }))}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <TextField
          id='financialAccount'
          required
          placeholder='Tidak ada akun keuangan yang dipilih'
          label='Akun Keuangan'
          value={operasional.financialAccount}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOperasional(prev => ({ ...prev, ['financialAccount']: event.target.value }))}
          select
          fullWidth
        >
          <MenuItem value={-1} disabled>
            Tidak ada akun beban yang dipilih
          </MenuItem>
          {financialAccounts.length > 0 &&
            financialAccounts.map((value, index) => (
              <MenuItem key={index} value={value.name}>
                {value.name}
              </MenuItem>
            ))}
        </TextField>

        <TextField
          id='amount'
          required
          fullWidth
          placeholder='Isi nominal transaksi'
          name='amount'
          autoComplete='off'
          label='Jumlah'
          value={operasional.amount}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOperasional(prev => ({ ...prev, ['amount']: +event.target.value }))}
          InputProps={{
            inputComponent: NumberFormatMask as any
          }}
        />

        <TextField
          id='description'
          label='Keterangan'
          placeholder='cth. parkir di mall,  gaji karyawan 1 2 3'
          multiline
          rows={4}
          variant='outlined'
          fullWidth
          value={operasional.description}
          onChange={handleChange}
        />
      </Grid>

      <Grid container item lg={12} md={12} sm={12} xs={12} justify='flex-end' className={classes.container}>
        <Button color='secondary' onClick={handleCloseCreates}>
          Batal
        </Button>

        <Button onClick={handleSubmit} disabled={isSubmit || disabled}>
          {isSubmit ? <CircularProgress color='primary' size={20} /> : 'Simpan'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default memo(CreateLoan);
