import React, { FC, Fragment, useEffect, useState, useContext } from 'react';
import axios, { CancelTokenSource } from 'axios';
import {
  Theme,
  Grid,
  Container,
  Typography,
  Button,
  Tooltip,
  IconButton,
  CircularProgress,
  makeStyles,
  Menu,
  MenuItem,
  Fade
} from '@material-ui/core';
import {
  PURCHASE_PAYMENT_BASE_URL,
  GET_INVOICE_PAYMENT_RETURN_BASE_URL,
  RETURN_INVOICE_BASE_URL,
  EXPORT_PURCHASE_PAYMENT_BASE_URL,
  ZONE_BASE_URL
} from 'constants/url';
import { Page, PaperCustom, Breadcrumb, StandardConfirmationDialog } from 'components';
import useRouter from 'hooks/useRouter';
import useRole from 'hooks/useRole';
import { format, startOfMonth } from 'date-fns';
import ArrowIcon from '@material-ui/icons/ArrowDropDown';
import CalendarIcon from '@material-ui/icons/EventNote';
import RefreshIcon from '@material-ui/icons/Refresh';
import Pagination from '@material-ui/lab/Pagination';
import _ from 'lodash';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { GREEN, WHITE, BLUE_PRIMARY } from 'constants/colors';
import { dummyZone } from 'utils/dummy';
import DateRangeFilter from 'components/DateRangeFilter';
import PaymentTable from './components/PaymentTable';
import PaymentGiroModal from './components/PaymentGiroModal';
import { AddBox } from '@material-ui/icons';
import { months } from 'utils';
import TypeUser from 'typings/enum/TypeUser';
import PaginationCustom from 'components/PaginationCustom';
const useStyles = makeStyles((theme: Theme) => ({
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  },
  calendarIcon: {
    fontSize: 30,
    color: theme.palette.primary.main
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  actionButton: {
    color: WHITE,
    background: BLUE_PRIMARY,
    width: 100,
    align: 'center'
  },
  menuList: {
    minHeight: 30
  }
}));

const PurchasePaymentPage: FC = () => {
  const { history } = useRouter();
  const classes = useStyles();

  const { currentUser } = useContext(CurrentUserContext);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [invoicePayments, setInvoicePayments] = useState<PurchaseInvoicePaymentModel[]>([]);
  const [invoicePaymentReturn, setInvoicePaymentReturn] = useState<InvoiceReturnModel[]>([]);

  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [name, setName] = useState<string>('');
  const [salesName, setSalesName] = useState<string>('');
  const [giroNumber, setGiroNumber] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [totalPay, setTotalPay] = useState<string>('');
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [payDate, setPayDate] = useState<Date>(new Date());
  const [paymentMethod, setPaymentMethod] = useState<string>('');
  const [zones, setZones] = useState<ZoneModel[]>([dummyZone]);
  const [selectedZone, setSelectedZone] = useState<number[]>([]);
  const [giroMonthDue, setGiroMonthDue] = useState<{ total: Number; month: string }[]>([]);

  const [total, setTotal] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [from, setFrom] = useState<number>(0);

  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(0);
  const [isLoadingCollapse, setLoadingCollapse] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [openGiroModal, setOpenGiroModal] = useState<boolean>(false);
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);

  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const isSuperVisor = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERVISOR]
  });

  const isSales = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SALES]
  });

  const salesRoute = currentUser && currentUser.SalesRoute ? currentUser.SalesRoute.map(value => value.ZoneId) : [];
  let cancelToken: CancelTokenSource = axios.CancelToken.source();

  const getQueryParams = () => {
    const params = new URLSearchParams();

    if (name) {
      params.append('partnerName', name);
    }

    if (code) {
      params.append('paymentNumber', code);
    }

    if (invoiceNumber) {
      params.append('invoiceNumber', invoiceNumber);
    }

    if (status) {
      params.append('status', status);
    }

    if (salesName) {
      params.append('sales', salesName);
    }

    if (giroNumber) {
      params.append('giroNumber', giroNumber);
    }

    if (startDate && endDate) {
      params.append('startDate', startDate);
      params.append('endDate', endDate);
    }

    if (paymentMethod) {
      params.append('method', paymentMethod);
    }

    if (selectedZone.length > 0) {
      params.append('Zone', selectedZone.join(','));
    } else if (currentUser && isSuperVisor) {
      params.append('Zone', (currentUser.SalesRoute && currentUser.SalesRoute.map(value => value.ZoneId).join(',')) || '');
    }

    if (currentUser && isSales) {
      params.append('SalesId', currentUser.id.toString());
    }

    if (orderBy || order) {
      params.append('orderBy', orderBy);
      params.append('ordered', order);
    }

    params.append('page', currentPage.toString());
    return params.toString();
  };

  const fetchData = async () => {
    setIsLoadingData(true);

    try {
      const { data } = await axios.get(`${PURCHASE_PAYMENT_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelToken.token });
      setInvoicePayments(data.data.map((val: any) => ({ ...val, ['ReturnIdMultiple']: val.ReturnId })));
      setCount(data.meta.last_page);
      setTotal(data.meta.total);
      setTo(data.meta.to);
      setFrom(data.meta.from);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const loadAll = async () => {
    const params = new URLSearchParams();
    params.append('route', salesRoute.join(','));
    params.append('orderBy', 'name');
    params.append('ordered', 'asc');
    params.append('perPage', '1000');

    try {
      const result = await Promise.all([
        axios.get(`${ZONE_BASE_URL}?${params.toString()}`),
        axios.get(`${PURCHASE_PAYMENT_BASE_URL}/giro-month-due`)
      ]);
      const [zn, gr] = result;
      setZones(zn.data.data);
      setGiroMonthDue(
        (gr.data.data.length > 0 &&
          gr.data.data.map((value: any) => ({
            total: value.total,
            month: months[value.month_number - 1]
          }))) ||
          []
      );
    } catch (error) {
      console.log('error :', error);
    } finally {
    }
  };

  const fetchReturn = async (id: number) => {
    setLoadingCollapse(true);
    const returnId =
      (invoicePayments.filter(val => val.id === id).length > 0 && invoicePayments.filter(val => val.id === id)[0].ReturnIdMultiple) || [];
    if (returnId.length < 1) return;
    try {
      const invoiceReturn = await axios.get(`${RETURN_INVOICE_BASE_URL}?id=${returnId.map((val: any) => val.ReturnId).join(',')}`);
      setInvoicePaymentReturn(invoiceReturn.data.data);
    } catch (e) {
      console.log('error', e);
    } finally {
      setLoadingCollapse(false);
    }
  };

  const onRefresh = () => {
    setCount(0);
    setCurrentPage(1);
    setInvoicePayments([]);
    setInvoicePaymentReturn([]);
    setOrder('desc');
    setName('');
    setCode('');
    setTotal(0);
    setTo(0);
    setFrom(0);
    setOpenCollapse(false);
    setLoadingCollapse(false);

    fetchData();
  };

  const handleCreatePayment = () => {
    history.push('/pembayaran-pembelian/tambah');
  };

  const handleOpenCollapse = (index: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    setOpenCollapse(prevState => (prevState ? (index === indexCollapse ? false : true) : true));
    fetchReturn(index);
  };

  const handleCalendarFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenCalendarFilter(!openCalendarFilter);
  };

  const handleUpdatePaymentIndividual = (id: number, data: PurchaseInvoicePaymentModel) => {
    const currentInvoicePayments = [...invoicePayments];

    const findIndex = currentInvoicePayments.findIndex(invoice => invoice.id === id);
    currentInvoicePayments[findIndex] = data;
    setInvoicePayments(currentInvoicePayments);
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
  };

  const handleOpenGiroModal = () => {
    setOpenGiroModal(true);
  };

  const handleCloseGiroModal = () => {
    setOpenGiroModal(false);
  };

  const handleAction = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadExcel = async () => {
    setLoadingExport(true);

    try {
      const { data } = await axios.get(`${EXPORT_PURCHASE_PAYMENT_BASE_URL}?${getQueryParams()}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `Pembayaran-${format(new Date(), 'dd/MM/yyyy-HH:mm:ss')}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadingExport(false);
    }
  };

  useEffect(() => {
    const debouncedFetchHits = _.debounce(fetchData, 500);
    debouncedFetchHits();
    return () => cancelToken.cancel('No longer latest query');
  }, [
    orderBy,
    order,
    currentPage,
    name,
    code,
    selectedZone,
    invoiceNumber,
    status,
    paymentMethod,
    startDate,
    endDate,
    salesName,
    giroNumber,
    isSuperVisor,
    isSales
  ]);

  useEffect(() => {
    if (!name && !code && !selectedZone && !invoiceNumber && !status && !startDate && !endDate && !paymentMethod && !salesName && !giroNumber) {
      return;
    }

    setCurrentPage(1);
  }, [name, code, selectedZone, invoiceNumber, status, paymentMethod, startDate, endDate, salesName, giroNumber]);

  useEffect(() => {
    loadAll();
  }, []);

  const isAddAllowed = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: ['ADMIN', 'SUPERADMIN', 'SALES', TypeUser.ADMIN04]
  });

  return (
    <Page title='Pembayaran Pembelian'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'>Pembayaran Pembelian</Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>
        <PaperCustom>
          <Grid container direction='row' alignItems='center' item lg={12} md={12} sm={12} xs={12} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={6} xs={6}>
              <Tooltip title='Memuat Ulang'>
                <Button onClick={onRefresh} color='inherit' className={classes.refresh}>
                  <RefreshIcon />
                </Button>
              </Tooltip>
            </Grid>

            <Grid item xl={5} lg={5} md={5} sm={6} xs={6}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography>{`Menampilkan ${total || 0} pembayaran pembelian (${from || 0} - ${to || 0} dari ${total || 0})`}</Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                {startDate && endDate && (
                  <Typography variant='body2' style={{ fontSize: 12, color: BLUE_PRIMARY }}>
                    Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid item container justify='space-between' alignItems='center' spacing={2} xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid item sm={12} xs={12} container justify='flex-end'>
                <Tooltip title='Calendar filter' placement='top'>
                  <IconButton size='small' onClick={event => handleCalendarFilterClick(event)}>
                    <CalendarIcon className={classes.calendarIcon} />
                  </IconButton>
                </Tooltip>

                {isAddAllowed && (
                  <Fragment>
                    <Button disabled={isLoadingData} className={classes.actionButton} onClick={handleAction}>
                      Aksi <ArrowIcon />
                      {isLoadingButton ? (
                        <Fade
                          in={isLoadingButton}
                          style={{
                            transitionDelay: '0ms'
                          }}
                          unmountOnExit
                        >
                          <CircularProgress size={24} className={classes.buttonProgress} />
                        </Fade>
                      ) : (
                        ''
                      )}
                    </Button>
                    <Menu
                      id='list-menu'
                      anchorEl={anchorEl}
                      keepMounted
                      elevation={1}
                      getContentAnchorEl={null}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                      }}
                    >
                      <MenuItem className={classes.menuList} onClick={handleCreatePayment}>
                        Buat Pembayaran
                      </MenuItem>
                      <MenuItem className={classes.menuList} onClick={handleOpenGiroModal}>
                        Pencairan Giro
                      </MenuItem>
                      <MenuItem className={classes.menuList} onClick={downloadExcel}>
                        {loadingExport ? <CircularProgress color='inherit' size={20} /> : 'Export'}
                      </MenuItem>
                    </Menu>
                  </Fragment>
                )}
                {isSuperVisor && (
                  <Button onClick={handleCreatePayment} disabled={isLoadingData}>
                    <AddBox fontSize='small' /> &nbsp; Buat Pembayaran
                  </Button>
                )}
              </Grid>
            </Grid>

            <Grid container justify='center' item xl={12} lg={12} md={12} sm={12} xs={12}>
              <PaymentTable
                isLoadingData={isLoadingData}
                invoicePayments={invoicePayments}
                order={order}
                orderBy={orderBy}
                name={name}
                salesName={salesName}
                setSalesName={setSalesName}
                giroNumber={giroNumber}
                setGiroNumber={setGiroNumber}
                code={code}
                invoiceNumber={invoiceNumber}
                totalPay={totalPay}
                status={status}
                payDate={payDate}
                zones={zones}
                selectedZone={selectedZone}
                setSelectedZone={setSelectedZone}
                setInvoiceNumber={setInvoiceNumber}
                setName={setName}
                setCode={setCode}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                setTotalPay={setTotalPay}
                setStatus={setStatus}
                setPayDate={setPayDate}
                setPaymentMethod={setPaymentMethod}
                paymentMethod={paymentMethod}
                invoicePaymentReturn={invoicePaymentReturn}
                isLoadingCollapse={isLoadingCollapse}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                handleOpenCollapse={handleOpenCollapse}
              />
            </Grid>

            <Grid container justify='center' item xl={12} md={12} sm={12}>
              <PaginationCustom
                marginTop='-.4em '
                show={invoicePayments.length > 0}
                sxPagination={{
                  count,
                  boundaryCount: 2,
                  variant: 'outlined',
                  shape: 'rounded',
                  onChange: (event, page) => {
                    setCurrentPage(page);
                  },
                  page: currentPage
                }}
                sxPopover={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  }
                }}
                customPageProps={{
                  defaultValue: currentPage,
                  maxValue: count,
                  onSubmit(value) {
                    setCurrentPage(value);
                  }
                }}
              />
            </Grid>
          </Grid>
        </PaperCustom>
      </Container>

      <DateRangeFilter
        openCalendarFilter={openCalendarFilter}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleClose={() => {
          setOpenCalendarFilter(false);
        }}
      />

      <PaymentGiroModal
        giroMonthDue={giroMonthDue}
        setOpenSnackbar={setOpenSnackbar}
        openModal={openGiroModal}
        handleCloseModal={handleCloseGiroModal}
        handleSnackBar={handleSnackBar}
        handleUpdatePaymentIndividual={handleUpdatePaymentIndividual}
      />

      <StandardConfirmationDialog
        open={openSnackbar}
        variant={snackbarVariant}
        titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
        message={snackbarMessage}
        handleClose={handleCloseSnackbar}
        noCancelButton={true}
      />
    </Page>
  );
};

export default PurchasePaymentPage;
