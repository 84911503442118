import React, { FC, Fragment, useState, useEffect, useCallback, useContext } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { makeStyles, TextField, Button, Typography, Grid, TableContainer, TableBody, TableHead, MenuItem, FormHelperText } from '@material-ui/core';
import { PRODUCT_BASE_URL, CATEGORY_BASE_URL, MUTATION_BASE_URL, WAREHOUSE_BASE_URL } from 'constants/url';
import { HeaderRow, TableCustom } from 'components/Table';
import { dummyCategory, dummyWareHouse, dummyProduct } from 'utils/dummy';
import { format } from 'date-fns';
import ProductModal from './ProductModal';
import BodyRow from './BodyRow';
import empty from 'images/empty.svg';
import { CurrentUserContext } from 'contexts/CurrentUserContext';

interface Props {
  isSubmit: boolean;
  mutation: MutationModel;
  isUpdate: boolean;
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  setMutations: React.Dispatch<React.SetStateAction<MutationModel[]>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const useStyles = makeStyles({
  image: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
});

const StockWizard: FC<Props> = props => {
  const classes = useStyles();
  const { isSubmit, setOpen, handleSnackBar, setSubmit, setMutations, mutation, isUpdate, setUpdate } = props;
  const { currentUser } = useContext(CurrentUserContext);

  const [mutationNumber, setMutationNumber] = useState<string>(new Date().getTime().toString());
  const [description, setDescription] = useState<string>('');

  const [checked, setChecked] = useState<MutationItemModel[]>([]);
  const [checkedProduct, setCheckedProduct] = useState<ProductModel[]>([]);

  const [category, setCategory] = useState<CategoryModel>(dummyCategory);
  const [categories, setCategories] = useState<CategoryModel[]>([dummyCategory]);

  const [warehouses, setWarehouses] = useState<WareHouseModel[]>([]);
  const [origin, setOrigin] = useState<WareHouseModel>(dummyWareHouse);
  const [destination, setDestination] = useState<WareHouseModel>(dummyWareHouse);

  const [mutationDate, setMutationDate] = useState<Date | null>(new Date());
  const [mutationDateMessage, setMutationDateMessage] = useState<string>('');

  const [originMessage, setOriginMessage] = useState<string>('');
  const [destinationMessage, setDestinationMessage] = useState<string>('');
  const [itemMessage, setItemMessage] = useState<string>('');

  const [openProduct, setOpenProduct] = useState<boolean>(false);
  const [products, setProducts] = useState<ProductModel[]>([dummyProduct]);
  const [count, setCount] = useState<number>(0);
  const [queryString, setQueryString] = useState<string>('');
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('keyword', queryString);
      }

      if (orderBy || order) {
        params.append('orderBy', orderBy);
        params.append('ordered', order);
      }

      if (category && category.id > 0) {
        params.append('CategoryId', String(category.id));
      }

      params.append('page', currentPage.toString());
      return params.toString();
    };

    try {
      const { data } = await axios.get(`${PRODUCT_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });
      setProducts(data.data);
      setCount(data.meta.last_page);
      setCurrentPage(data.meta.current_page);
    } catch (error) {
      console.log('error: ', error);
    }
    setIsLoadingData(false);
    return () => {
      cancelTokenSource.cancel();
    };
  }, [queryString, orderBy, order, currentPage, category]);

  const handleSearchCategory = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);

    try {
      const { data } = await axios.get(`${CATEGORY_BASE_URL}?${params.toString()}`);
      setCategories(data.data);
    } catch (error) {
      console.log('error :', error);
    }
  };

  const handleOnSubmit = async () => {
    resetValidation();

    if (!validation()) {
      setSubmit(false);
      return;
    }

    try {
      const { data } = await axios.post(MUTATION_BASE_URL, {
        id: mutation.id,
        mutationNumber,
        mutationDate: format(new Date(mutationDate!), 'yyyy-MM-dd'),
        status: 'PENDING',
        totalItem: checked.length,
        totalQuantity: checked.map(value => value.totalItem).reduce((a, b) => a + b),
        DestinationId: destination.id,
        OriginId: origin.id,
        mutationBy: (currentUser && currentUser.id) || 0,
        acceptedBy: 0,
        notes: description,
        items: checked
      });

      handleSnackBar(true, 'success', 'Mutasi berhasil ditambahkan');
      setOpen(false);
      if (!isUpdate) {
        setMutations(prevState => [data.data, ...prevState]);
      } else {
        setMutations(prevState => {
          prevState[prevState.findIndex(value => value.id === mutation.id)] = data.data;
          return prevState;
        });
      }
    } catch (error) {
      console.log('error :', error);
      handleSnackBar(true, 'error', 'Mutasi gagal ditambahkan');
    } finally {
      setSubmit(false);
      setUpdate(false);
      reset();
    }
  };

  const validation = (): boolean => {
    let valid = true;
    if (!mutationDate) {
      setMutationDateMessage('Tanggal tidak boleh kosong');
      valid = false;
    }

    if (origin && origin.id === 0) {
      setOriginMessage('Gudang Asal tidak boleh kosong');
      valid = false;
    }

    if (destination && destination.id === 0) {
      setDestinationMessage('Gudang Tujuan tidak boleh kosong');
      valid = false;
    }

    if (checked.length === 0) {
      setItemMessage('Barang tidak boleh kosong');
      valid = false;
    }

    return valid;
  };

  const resetValidation = () => {
    setMutationDateMessage('');
    setMutationDateMessage('');
    setOriginMessage('');
    setDestinationMessage('');
    setItemMessage('');
  };

  const reset = () => {
    setMutationNumber('');
    setDescription('');
    setCheckedProduct([]);
    setChecked([]);
    setOrigin(dummyWareHouse);
    setDestination(dummyWareHouse);
    setMutationDate(new Date());
  };

  const handleClickOpen = () => {
    setOpenProduct(true);
  };

  const handleClose = () => {
    setOpenProduct(false);
  };

  const handleCancelProduct = () => {
    setOpenProduct(false);
    setChecked([]);
    setCheckedProduct([]);
  };

  const individualCheck = (item: MutationItemModel) => {
    const newChecked = [...checked];
    const newCheckedProduct = [...checkedProduct];

    const countElement = newChecked.some(newCheckedValue => newCheckedValue.ProductId === item.ProductId);
    if (!countElement) {
      newChecked.push(item);
      newCheckedProduct.push(products.filter(value => value.id === item.ProductId)[0]);
    } else {
      newChecked.splice(
        newChecked.findIndex(newCheckedValue => newCheckedValue.ProductId === item.ProductId),
        1
      );
      newCheckedProduct.splice(
        newCheckedProduct.findIndex(newCheckedValue => newCheckedValue.id === item.ProductId),
        1
      );
    }

    setChecked(newChecked);
    setCheckedProduct(newCheckedProduct);
  };

  const handleSearchWarehouse = async () => {
    try {
      const { data } = await axios.get(WAREHOUSE_BASE_URL);
      setWarehouses(data.data);
    } catch (error) {
      console.log('error :', error);
    }
  };

  const individualQty = (item: MutationItemModel) => {
    setChecked(
      checked.map(value => {
        if (value.ProductId === item.ProductId) {
          value = item;
        }
        return value;
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    handleSearchWarehouse();
  }, []);

  useEffect(() => {
    if (!isSubmit) {
      return;
    }

    handleOnSubmit();
  }, [isSubmit]);

  useEffect(() => {
    if (!isUpdate) {
      return;
    }
    setMutationNumber(mutation.mutationNumber);
    setDescription(mutation.notes);
    setCheckedProduct((mutation.MutationItem && mutation.MutationItem.map(value => value.Product)) || []);
    setChecked(mutation.MutationItem || []);
    setOrigin(mutation.Origin || dummyWareHouse);
    setDestination(mutation.Destination || dummyWareHouse);
    setMutationDate(new Date(mutation.mutationDate));
  }, [isUpdate]);

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        <TextField
          id='orderDate'
          required
          fullWidth
          type='date'
          label='Tanggal Hari Ini'
          value={mutationDate && format(mutationDate, 'yyyy-MM-dd')}
          onChange={e => setMutationDate(new Date(e.target.value))}
          error={mutationDateMessage !== ''}
          helperText={mutationDateMessage}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
        <TextField
          fullWidth
          label='Gudang Asal'
          value={origin.id}
          error={originMessage !== ''}
          helperText={originMessage}
          onChange={e => {
            const originWarehouse = warehouses.find(value => value.id === +e.target.value);
            if (originWarehouse) {
              setOrigin(originWarehouse);
            }
          }}
          select
        >
          <MenuItem key={-1}>Pilih Gudang</MenuItem>
          {warehouses.map((value, index) => (
            <MenuItem key={index} value={value.id}>
              {value.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
        <TextField
          fullWidth
          label='Gudang Tujuan'
          value={destination.id}
          error={destinationMessage !== ''}
          helperText={destinationMessage}
          onChange={e => {
            const destinationWarehouse = warehouses.find(value => value.id === +e.target.value);
            if (destinationWarehouse && destinationWarehouse.id !== origin.id) {
              setDestination(destinationWarehouse);
            }
          }}
          select
        >
          <MenuItem key={-1}>Pilih Gudang</MenuItem>
          {warehouses.map((value, index) => (
            <MenuItem key={index} value={value.id}>
              {value.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item lg={12} sm={12} md={12} xs={12}>
        <TextField
          id='Deskripsi'
          rows={4}
          multiline
          fullWidth
          size='small'
          variant='outlined'
          label='Deskripsi'
          value={description}
          onChange={event => setDescription(event.target.value)}
        />
      </Grid>

      <Grid item lg={12} sm={12} md={12} xs={12}>
        <Typography variant='h5'>Masukkan Produk</Typography>
        <FormHelperText error={itemMessage !== ''}>{itemMessage}</FormHelperText>
        {checkedProduct.length === 0 ? (
          <Fragment>
            <img src={empty} className={classes.image} />
            <Typography variant='subtitle1' align='center'>
              Belum ada produk yang ditambahkan dalam paket ini :(
            </Typography>
          </Fragment>
        ) : (
          <TableContainer>
            <TableCustom size='small'>
              <TableHead>
                <HeaderRow
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  headers={[
                    { label: '', pR: '10px', pT: '7px', verticalAlign: 'top' },
                    { id: 'productCode', label: 'id', sort: true },
                    { id: 'productName', label: 'Nama Produk', sort: true },
                    { id: 'unit', label: 'Kategori', sort: true },
                    { id: 'stok', label: 'Jumlah ', sort: true }
                  ]}
                />
              </TableHead>
              <TableBody>
                {checkedProduct.length > 0 &&
                  checkedProduct.map((value, index) => (
                    <BodyRow
                      key={index + 1}
                      index={index}
                      item={checked[index]}
                      isEditable={true}
                      product={value}
                      isLoading={isLoadingData}
                      checked={checked}
                      handleIndividualCheck={individualCheck}
                      handleIndividualQty={individualQty}
                    />
                  ))}
              </TableBody>
            </TableCustom>
          </TableContainer>
        )}
      </Grid>

      <Grid item container lg={12} sm={12} md={12} xs={12} spacing={2} justify='flex-end'>
        <Button variant='text' onClick={handleClickOpen}>
          Tambahkan Produk Lainnya
        </Button>
      </Grid>

      <ProductModal
        count={count}
        openProduct={openProduct}
        handleClose={handleClose}
        queryString={queryString}
        setQueryString={setQueryString}
        categories={categories}
        setCategory={setCategory}
        handleSearchCategory={handleSearchCategory}
        order={order}
        orderBy={orderBy}
        handleRequestSort={handleRequestSort}
        isLoadingData={isLoadingData}
        checked={checked}
        individualCheck={individualCheck}
        products={products}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        handleCancelProduct={handleCancelProduct}
      />
    </Grid>
  );
};

export default StockWizard;
