import { GridProps, makeStyles, Typography } from '@material-ui/core';
import FlexBox from 'components/FlexBox';
import React, { ReactNode } from 'react';

interface Props extends GridProps {
  title: string;
  children: ReactNode;
  alertContent?: ReactNode;
  alertIcon?: ReactNode;
  showIcon?: boolean;
}
const useStyles = makeStyles({
  container: {
    position: 'relative'
  }
});
const TitleSection = ({ title, children, alertContent, alertIcon, showIcon }: Props) => {
  const classes = useStyles();
  return (
    <FlexBox container rowGap={1} direction='column' className={classes.container}>
      <FlexBox container columnGap={1} alignItems='center'>
        <Typography variant='h1' component='h1'>
          {title}
        </Typography>
        {showIcon && alertIcon}
      </FlexBox>
      {children}
      {alertContent}
    </FlexBox>
  );
};

export default TitleSection;
