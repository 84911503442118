import React, { FC } from 'react'
import NumberFormat from 'react-number-format';
import { Grid, Typography } from '@material-ui/core';
import TypePiece from 'typings/enum/TypePiece';

interface Props {
    type: string;
    note: string;
    price: number;
};

const PieceList: FC<Props> = props => {
    const { type, note, price } = props;

    return (
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Typography variant='body1'>{type === 'SHIPPING' ? TypePiece.SHIPPING : TypePiece.OTHER}</Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Typography variant='body1'>
            <NumberFormat value={price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Typography variant='body1'>{note}</Typography>
        </Grid>
      </Grid>
    );
};

export default PieceList;
