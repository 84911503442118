import { Grid, GridProps, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

interface Props extends GridProps {
  rowGap?: number;
  columnGap?: number;
  className?: string;
  children: ReactNode;
}
interface StyleProps {
  rowGap: number;
  columnGap: number;
}

const useStyles = makeStyles<Theme, StyleProps>({
  gap: {
    rowGap: ({ rowGap }) => `${rowGap}em`,
    columnGap: ({ columnGap }) => `${columnGap}em`
  }
});

const FlexBox = ({ rowGap = 0, columnGap = 0, children, className, ...props }: Props) => {
  const classes = useStyles({
    rowGap,
    columnGap
  });
  return (
    <Grid {...props} className={clsx(className && className, classes.gap)}>
      {children}
    </Grid>
  );
};

export default FlexBox;
