import { Button, Grid, IconButton, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import { Create, Delete, DeleteOutline, EditOutlined, Visibility, AttachFile, VisibilityOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom } from 'components';

import { BLACK, PRIMARY_MOBILE, WHITE } from 'constants/colors';
import React from 'react';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    cursor: 'pointer',
    '&:hover': {
      color: PRIMARY_MOBILE
    }
  },
  cellStart: {
    width: '3%'
  },
  cellMiddle: {
    width: '14%'
  },
  cellMiddleDesc: {
    width: '15%'
  },
  cellEnd: {
    width: '15%'
  },
  descriptionContainer: {
    backgroundColor: WHITE,
    borderRadius: '6px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    border: '1px solid #E0E0E0'
  },
  description: {
    color: BLACK
  }
}));
interface Props {
  incomeStatement: IncomeStatement;
  handleAction: (isOpen: boolean, id: number, action: 'delete' | 'update' | 'detail') => void;
}
const IncomeStatementBody = ({ incomeStatement, handleAction }: Props) => {
  const classes = useStyles();

  const formatProfitLoss = (value: number | null | undefined) => {
    // Pastikan value adalah angka, jika tidak, set ke 0
    if (value === null || value === undefined) {
      value = 0;
    }

    // Pastikan value adalah angka sebelum melakukan format
    const numericValue = Number(value); // Mengonversi ke angka
    if (isNaN(numericValue)) {
      return 'Rp 0'; // Atau sesuai kebutuhan
    }

    // Format sesuai dengan nilai
    const formattedValue = numericValue.toLocaleString(); // Format angka dengan pemisah ribuan

    // Cek jika nilainya negatif
    if (numericValue < 0) {
      return `Rp (${Math.abs(numericValue).toLocaleString()})`; // Format negatif dalam tanda kurung
    }

    return `Rp ${formattedValue}`; // Format normal untuk nilai positif
  };
  return (
    <TableRowCustom key={incomeStatement.id}>
      <TableCellStart className={classes.cellMiddle}>{format(new Date(incomeStatement.createdAt), 'dd MMM yyyy')}</TableCellStart>
      <TableCellMiddle className={classes.cellMiddle}>
        {format(new Date(incomeStatement.startDate), 'dd MMM yyyy') + ' s/d ' + format(new Date(incomeStatement.endDate), 'dd MMM yyyy')}
      </TableCellMiddle>
      <TableCellMiddle className={classes.cellMiddle} align='center'>
        <span>{formatProfitLoss(incomeStatement.profitLoss)}</span>
      </TableCellMiddle>
      <TableCellEnd className={classes.cellEnd} align='right'>
        <>
          <Tooltip title='Detail Laba Rugi'>
            <IconButton size='small' onClick={() => handleAction(true, incomeStatement.id, 'update')}>
              {/* <EditOutlined fontSize='small' /> */}
              <VisibilityOutlined fontSize='small' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Hapus Laba Rugi'>
            <IconButton size='small' onClick={() => handleAction(true, incomeStatement.id, 'delete')}>
              <DeleteOutline fontSize='small' />
            </IconButton>
          </Tooltip>
        </>
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default IncomeStatementBody;
