import { Grid, makeStyles, PropTypes, Typography } from '@material-ui/core';
import FlexBox from 'components/FlexBox';
import { format } from 'date-fns';
import React from 'react';
import NumberFormat from 'react-number-format';
import './invoicePrint.css';
import Logo from 'images/NewLogo.jpg';
interface Props {
  invoiceReportPrint: InvoiceReportPrint;
}

const useStyles = makeStyles({
  invoiceList: {
    color: 'black',
    fontSize: '.9rem'
  },
  line: {
    borderTop: `1.5px solid black`
  },
  watermax: {
    position: 'relative',
    height: 'max-content',
    '&::before': {
      position: 'absolute',
      content: '""',
      opacity: 0.1,
      height: '100%',
      width: '100vw',
      backgroundImage: `url(${Logo})`,
      backgroundPosition: '80% 20%',
      backgroundRepeat: 'repeat-y',
      backgroundSize: '70vw 90vh'
    }
  }
});
const GridFlex = {
  small: 0.25,
  normal: 0.25,
  large: 0.25
};

/**
 * It's a type that is an object with a key property that is a union of the literal string
 * 'remainingPay' and a keyof the intersection of the Required type of the Pick type of the
 * InvoiceModel type with the union of the literal strings 'id', 'orderDate', 'invoiceNumber',
 * 'dueDate', 'totalPrice', 'totalPay', a label property that is a string, a flex property that is a
 * keyof the GridFlex type, and an align property that is a PropTypes.Alignment.
 * @property {'remainingPay' | keyof Required<Pick<InvoiceModel, 'id' | 'orderDate' | 'invoiceNumber' |
 * 'dueDate' | 'totalPrice' | 'totalPay'>>} key - The key of the property in the model.
 * @property {string} label - string;
 * @property flex - keyof typeof GridFlex;
 * @property align - PropTypes.Alignment;
 */
type tableProperties = {
  key: 'remainingPay' | keyof Required<Pick<InvoiceModel, 'id' | 'orderDate' | 'invoiceNumber' | 'dueDate' | 'totalPrice' | 'totalPay'>>;
  label: string;
  flex: keyof typeof GridFlex;
  align: PropTypes.Alignment;
};
/* An array of objects. Each object has a key, label, flex, and align property. */
const tableProperties: tableProperties[] = [
  { key: 'orderDate', label: 'Tanggal Invoice', flex: 'small', align: 'left' },
  { key: 'invoiceNumber', label: 'Nomor Invoice', flex: 'large', align: 'left' },
  { key: 'dueDate', label: 'Jatuh Tempo', flex: 'small', align: 'right' },
  { key: 'remainingPay', label: 'Sisa', flex: 'normal', align: 'right' }
];

/* A function that takes an object with a property called invoiceReportPrint and returns a React
component. */
const InvoicePrint = ({ invoiceReportPrint }: Props) => {
  const classes = useStyles();

  /**
   * Get the total of all invoices, but if the invoice status includes the word 'Return', subtract the
   * total price from the total.
   * @returns The total of all the invoices.
   */
  const getTotal = () => {
    const sumTotal = invoiceReportPrint.partners.map(partner =>
      partner.invoices.reduce((total, item) => {
        if (item.status.includes('Return')) {
          return total - (item.totalPrice - item.totalPay);
        }
        return total + (item.totalPrice - item.totalPay);
      }, 0)
    );

    const totalFinal = sumTotal.reduce((total, curr) => total + curr, 0);
    return totalFinal;
  };

  /**
   * It takes an index number and returns the sum of the totalPrice property of each invoice in the
   * invoices array of the partner at that index
   * @param {number} index - number - the index of the partner in the array
   * @returns The total of the invoices for a partner.
   */
  const getTotalPartner = (index: number) => {
    const sumTotal = invoiceReportPrint.partners[index].invoices.reduce((total, item) => {
      if (item.status.includes('Return')) {
        return total - (item.totalPrice - item.totalPay);
      }
      return total + (item.totalPrice - item.totalPay);
    }, 0);

    return sumTotal;
  };

  return (
    <Grid xs={12} container justify='center'>
      <FlexBox xs={12} container rowGap={1} direction='row' className={classes.watermax}>
        <Grid xs={12}>
          <Typography variant='h5' align='center'>
            LAPORAN SISA PIUTANG
          </Typography>
          <Typography variant='h6' align='center'>
            (SEMUA NOTA)
          </Typography>
          <Typography variant='h6'> {invoiceReportPrint.zoneName}</Typography>
        </Grid>

        <Grid xs={12} className={classes.line} />
        <FlexBox container rowGap={1}>
          <Grid xs={12} container>
            {tableProperties.map(property => (
              <Grid style={{ flex: GridFlex[property.flex] }}>
                <Typography variant='h6' align={property.align}>
                  {property.label}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Grid xs={12} className={classes.line} />

          {invoiceReportPrint.partners.map((partner, partnerKey) => (
            <FlexBox xs={12} key={partnerKey} container rowGap={1}>
              <FlexBox xs={12} container columnGap={1.2}>
                <Typography variant='h6'>{partnerKey + 1}.</Typography>
                <Typography variant='h6'>{partner.partnerName}</Typography>
              </FlexBox>
              <Grid xs={12} className={classes.line} />
              {partner.invoices.map((invoice, invoceKey) => (
                <Grid xs={12} key={invoceKey} container>
                  {tableProperties.map(property => (
                    <Grid style={{ flex: GridFlex[property.flex] }} key={property.key}>
                      {property.key === 'invoiceNumber' ? (
                        <Typography className={classes.invoiceList} align={property.align}>{`#${invoice[property.key]}`}</Typography>
                      ) : property.key === 'orderDate' ? (
                        <Typography className={classes.invoiceList} align={property.align} style={{ marginLeft: '2em' }}>
                          {invoice[property.key] ? format(new Date(invoice[property.key].split(' ')[0]), 'dd MMM yyyy') : '-'}
                        </Typography>
                      ) : property.key === 'dueDate' ? (
                        <Typography className={classes.invoiceList} align={property.align}>
                          {invoice[property.key] ? format(new Date(invoice[property.key].split(' ')[0]), 'dd MMM yyyy') : '-'}
                        </Typography>
                      ) : property.key === 'remainingPay' ? (
                        <Typography className={classes.invoiceList} align={property.align}>
                          <NumberFormat
                            value={invoice['totalPrice'] - invoice['totalPay']}
                            prefix={invoice['status'].includes('Return') ? '(Rp' : 'Rp'}
                            suffix={invoice['status'].includes('Return') ? ')' : ''}
                            thousandSeparator={true}
                            displayType='text'
                          />
                        </Typography>
                      ) : (
                        <Typography className={classes.invoiceList} align={property.align}>
                          {invoice[property.key]}
                        </Typography>
                      )}
                    </Grid>
                  ))}
                </Grid>
              ))}
              <FlexBox xs={12} container justify='flex-end' rowGap={1}>
                <Grid xs={8} className={classes.line} />
                <Grid xs={8} container justify='space-between' alignItems='center'>
                  <Typography variant='h6'>TOTAL SISA</Typography>
                  <Typography variant='h6'>
                    <NumberFormat value={getTotalPartner(partnerKey)} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  </Typography>
                </Grid>
              </FlexBox>
            </FlexBox>
          ))}
          <FlexBox xs={12} container rowGap={1}>
            <Grid xs={12} className={classes.line} />
            <Grid xs={12} container justify='space-between' alignItems='center'>
              <Typography variant='h6'>TOTAL</Typography>
              <Typography variant='h6'>
                <NumberFormat value={getTotal()} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              </Typography>
            </Grid>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Grid>
  );
};

export default InvoicePrint;
