import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';

interface Props {
  title: string;
  isVisibility?: boolean;
  isFlex: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontSize: '12px',
    padding: '1em 0 1.4em',
    lineHeight: '14px',
    letterSpacing: '1.12px',
    textTransform: 'uppercase'
  }
}));
const HeaderRow: React.FC<Props> = props => {
  const { title, isVisibility, isFlex } = props;
  const classes = useStyles();

  return (
    <Grid item style={{ flex: isFlex }} container alignItems='center'>
      <Typography className={classes.title}>{title}</Typography>

      {!isVisibility && <ExpandMore />}
    </Grid>
  );
};

export default HeaderRow;
