import React, { FC, useState } from 'react';
import { Tooltip, makeStyles, IconButton, Table, Typography, TableCell, Switch, Grid } from '@material-ui/core';
import { EditOutlined, DeleteOutline } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import { TableCellMiddle, TableCellEnd, TableCellStart } from 'components/Table/TableCellCustom';
import TableRowCustom from 'components/Table/TableRowCustom';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { AntSwitch, TableRowCollapse } from 'components';
import { BLACK_2, GREY } from 'constants/colors';
import theme from 'theme';

const useStyles = makeStyles({
  customerName: {
    fontSize: '15px',
    fontWeight: 500,
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    lineHeight: '22px'
  },
  size: {
    hover: 'none'
  },
  firstRow: {
    border: `1px solid ${GREY}`,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderRight: 'none',
    color: BLACK_2,
    fontWeight: 500,
    paddingRight: theme.spacing(0),
    fontSize: '15px',
    lineHeight: '18px'
  },
  midleRow: {
    border: `1px solid ${GREY}`,
    borderRight: 'none',
    paddingRight: theme.spacing(0),
    borderLeft: 'none'
  },
  endRow: {
    border: `1px solid ${GREY}`,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderLeft: 'none'
  }
});

interface Props {
  setId: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  indexCollapse: number;
  openCollapse: boolean;
  accountCategorie: AccountCategoryModel;
  isLoading: boolean;
  onDelete: React.MouseEventHandler;
  onUpdate: React.MouseEventHandler;
  onStatusChange: React.MouseEventHandler;
  handleOpenCollapse: React.MouseEventHandler;
}

const BodyRow: FC<Props> = props => {
  const { onStatusChange, handleOpenCollapse, setId, indexCollapse, openCollapse, index, accountCategorie, isLoading, onDelete, onUpdate } = props;

  const classes = useStyles();
  const [data, setData] = useState<AccountCategoryModel>(accountCategorie);

  const active = () => {
    setData(prevData => ({
      ...prevData,
      isActive: !prevData.isActive
    }));
  };

  return (
    <>
      <TableRowCustom>
        <TableCell width={'5%'} align='right' className={classes.firstRow}>
          {isLoading ? (
            <Skeleton variant='text' height={25} />
          ) : (
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={event => {
                setId(accountCategorie.id);
                handleOpenCollapse(event);
              }}
            >
              {openCollapse && index === indexCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>

        <TableCell width={'80%'} align='left' className={classes.midleRow}>
          {isLoading ? (
            <Skeleton />
          ) : (
            <Typography variant='inherit' component='p' className={classes.customerName}>
              <>
                {accountCategorie.name}
                <br />
                {accountCategorie.number}
              </>
            </Typography>
          )}
        </TableCell>
        <TableCell width={'45%'} className={classes.endRow} align='right'>
          {isLoading ? (
            <Skeleton variant='text' width={'100%'} height={25} />
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Tooltip title='Perbaharui Bagan Akun'>
                <IconButton
                  size='small'
                  onClick={onUpdate}
                  disabled={!accountCategorie.isEditable || [1000, 2000, 3000, 4000, 5000, 6000].includes(accountCategorie.number)}
                >
                  <EditOutlined
                    fontSize='small'
                    color={
                      !accountCategorie.isEditable || [1000, 2000, 3000, 4000, 5000, 6000].includes(accountCategorie.number) ? undefined : 'primary'
                    }
                  />
                  <span style={{ marginLeft: '4px', fontSize: '0.875rem' }}>Ubah</span>
                </IconButton>
              </Tooltip>
              <Typography component='div' style={{ paddingLeft: '.6em', fontSize: '0.875rem' }}>
                <Grid component='label' container alignItems='center' spacing={1}>
                  <Grid item>
                    <AntSwitch checked={accountCategorie.isActive} onClick={onStatusChange} />
                  </Grid>
                  <Grid item>{accountCategorie.isActive === true ? 'On' : 'Off'}</Grid>
                </Grid>
              </Typography>
            </div>
          )}
        </TableCell>
      </TableRowCustom>
    </>
  );
};

export default BodyRow;
