import React, { FC } from 'react';
import clx from 'clsx';
import Skeleton from '@material-ui/lab/Skeleton';
import CCATMP from 'images/NewLogo.jpg';
import ListProductMobile from '../ListProductMobile';
import ListProduct from '../ListProduct';
import NumberFormat from 'react-number-format';
import { Grid, makeStyles, Typography, Table, TableRow, TableCell, TableBody, TableContainer } from '@material-ui/core';
import { format } from 'date-fns';
import { BLACK } from 'constants/colors';
import { PaperCustom } from 'components';

interface Props {
  returnOrder: InvoiceReturnModel;
  returnOrderItem: InvoiceReturnItemModel[];
  company: CompanyModel;
  isLoadingData: boolean;
  isLoadingCompany: boolean;
  isLoadingItem: boolean;
  isLoadingPartner: boolean;
  loadingUpdate: boolean;
  isSuperAdmin: boolean;
  isAdminInvoice: boolean;
  isAdmin: boolean;
  isSmall: boolean;
  item: {
    id: number;
    totalDamage: number;
    totalMerge: number;
    totalReturned: number;
  };
  indexCollapse: number;
  openCollapse: boolean;
  setItem: React.Dispatch<
    React.SetStateAction<{
      id: number;
      totalDamage: number;
      totalMerge: number;
      totalReturned: number;
    }>
  >;
  handleUpdateItem: () => void;
  handleOpenCollapse: (index: number) => React.MouseEventHandler;
}

const useStyles = makeStyles({
  cellOrder: {
    marginBottom: 1,
    padding: '2px 0px 2px 2px',
    border: 'none'
  },
  cellOrderRight: {
    width: '35%',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontStyle: 'normal',
    color: BLACK
  },
  cellOrderLeft: {
    width: '30%'
  },
  titleOrder: {
    color: BLACK,
    marginBottom: '16px'
  },
  cellSummaryRight: {
    border: 'none',
    padding: 4,
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontStyle: 'normal',
    color: BLACK
  },
  cellSummaryLeft: {
    width: '35%',
    border: 'none',
    padding: 4,
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal',
    color: BLACK
  },
  cellInfoRight: {
    padding: '2px 0px 2px 2px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  cellInfoLeft: {
    padding: '2px 0px 2px 0px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  cellFinalRight: {
    marginRight: 65
  },
  spaceTop: {
    marginTop: 10,
    paddingTop: 10
  },
  cellFinalText: {
    marginRight: 105,
    marginTop: 10,
    paddingTop: 10
  },
  cellFinalRightText: {
    marginRight: 65,
    marginBottom: 10,
    paddingBottom: 10
  }
});

const ReturnDetail: FC<Props> = props => {
  const classes = useStyles();
  const {
    returnOrder,
    isLoadingData,
    isLoadingCompany,
    isLoadingItem,
    isLoadingPartner,
    loadingUpdate,
    isAdminInvoice,
    company,
    returnOrderItem,
    item,
    isAdmin,
    isSuperAdmin,
    isSmall,
    indexCollapse,
    openCollapse,
    setItem,
    handleUpdateItem,
    handleOpenCollapse
  } = props;

  return (
    <Grid container direction='row' item lg={12} sm={12} md={12} xs={12}>
      <PaperCustom>
        <Grid container direction='row' spacing={2} item lg={12} sm={12} md={12} xs={12}>
          {!isSmall && (
            <Grid item lg={8} sm={12} md={12} xs={12}>
              <img src={CCATMP} width='236px' height='236' />
            </Grid>
          )}

          <Grid container item lg={4} sm={12} md={12} xs={12} justify='flex-end'>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell align={isSmall ? 'left' : 'right'} colSpan={2} className={classes.cellOrder}>
                    <Typography variant='h1' className={classes.titleOrder}>
                      Return Order
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                    <Typography variant='subtitle2'>Nomor Return</Typography>
                  </TableCell>
                  <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                    {isLoadingData ? <Skeleton variant='text' width='100%' /> : returnOrder.returnNumber}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                    <Typography variant='subtitle2'>Tanggal Return</Typography>
                  </TableCell>
                  <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                    {isLoadingData ? (
                      <Skeleton variant='text' width='100%' />
                    ) : returnOrder.returnDate ? (
                      format(new Date(returnOrder.returnDate), 'dd-MM-yyyy')
                    ) : (
                      ''
                    )}
                  </TableCell>
                </TableRow>

                {returnOrder.typeReturn === 'CUSTOMER' && (
                  <TableRow>
                    <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                      <Typography variant='subtitle2'>Rute</Typography>
                    </TableCell>
                    <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                      {isLoadingData ? <Skeleton variant='text' width='100%' /> : returnOrder.zoneName || '-'}
                    </TableCell>
                  </TableRow>
                )}

                {returnOrder.typeReturn === 'CUSTOMER' && (
                  <TableRow>
                    <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                      <Typography variant='subtitle2'>Sales</Typography>
                    </TableCell>
                    <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                      {isLoadingData ? <Skeleton variant='text' width='100%' /> : returnOrder.salesName || '-'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>

          <Grid item lg={6} sm={12} md={12} xs={12}>
            <Typography variant='h5' align='left'>
              Nama Perusahaan
            </Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align='left' style={{ fontWeight: 500, color: BLACK }} className={classes.cellInfoLeft}>
                      {isLoadingCompany ? <Skeleton variant='text' width='100%' /> : company.name || '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='left' className={classes.cellInfoLeft}>
                      {isLoadingCompany ? <Skeleton variant='text' width='100%' /> : company.address || '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='left' className={classes.cellInfoLeft}>
                      {isLoadingCompany ? (
                        <Skeleton variant='text' width='100%' />
                      ) : (
                        'Telp: ' + (company.phoneNumber || '-') + '/' + (company.cellPhoneNumber || '-')
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align='left' className={classes.cellInfoLeft}>
                      {isLoadingCompany ? <Skeleton variant='text' width='100%' /> : 'Email: ' + (company.email || '-')}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {isSmall ? (
            <Grid item lg={6} sm={12} md={12} xs={12}>
              <Typography variant='h5' align='left'>
                Return {`${returnOrder.typeReturn === 'CUSTOMER' ? 'Dari' : 'Kepada'}`}
              </Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell align='left' style={{ fontWeight: 500, color: BLACK }} className={classes.cellInfoLeft}>
                        {isLoadingCompany ? <Skeleton variant='text' width='100%' /> : returnOrder.Partner ? returnOrder.Partner.name : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='left' className={classes.cellInfoLeft}>
                        {isLoadingCompany ? (
                          <Skeleton variant='text' width='100%' />
                        ) : returnOrder.Partner ? (
                          returnOrder.typeReturn === 'SUPPLIER' ? (
                            returnOrder.Partner.address
                          ) : (
                            `${returnOrder.Partner.address}, (${returnOrder.Partner.remarkAddress || '-'})`
                          )
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='left' className={classes.cellInfoLeft}>
                        {isLoadingCompany ? (
                          <Skeleton variant='text' width='100%' />
                        ) : (
                          'Telp: ' +
                          (returnOrder.Partner ? (returnOrder.Partner.phoneNumber || '-') + '/' + (returnOrder.Partner.cellPhoneNumber || '-') : '-')
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align='left' className={classes.cellInfoLeft}>
                        {isLoadingCompany ? <Skeleton variant='text' width='100%' /> : 'Email: ' + (company.email || '-')}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : (
            <Grid item lg={6} sm={12} md={12} xs={12}>
              <Typography variant='h5' align='right'>
                Return {`${returnOrder.typeReturn === 'CUSTOMER' ? 'Dari' : 'Kepada'}`}
              </Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell align='right' style={{ fontWeight: 500, color: BLACK }} className={classes.cellInfoRight}>
                        {isLoadingPartner ? <Skeleton variant='text' width='100%' /> : returnOrder.Partner ? returnOrder.Partner.name : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='right' className={classes.cellInfoRight}>
                        {isLoadingPartner ? (
                          <Skeleton variant='text' width='100%' />
                        ) : returnOrder.Partner ? (
                          returnOrder.typeReturn === 'SUPPLIER' ? (
                            returnOrder.Partner.address
                          ) : (
                            `${returnOrder.Partner.address}, (${returnOrder.Partner.remarkAddress || '-'})`
                          )
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='right' className={classes.cellInfoRight}>
                        {isLoadingPartner ? (
                          <Skeleton variant='text' width='100%' />
                        ) : returnOrder.Partner ? (
                          'Telp: ' + (returnOrder.Partner.phoneNumber || '-')
                        ) : (
                          ''
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align='right' className={classes.cellInfoRight}>
                        {isLoadingPartner ? (
                          <Skeleton variant='text' width='100%' />
                        ) : returnOrder.Partner ? (
                          'Email: ' + (returnOrder.Partner.email || '-')
                        ) : (
                          ''
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}

          <Grid item lg={12} sm={12} md={12} xs={12}>
            {isAdmin || isSuperAdmin || isAdminInvoice ? (
              <ListProduct
                loadingUpdate={loadingUpdate}
                item={item}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                setItem={setItem}
                isShow={returnOrder.typeReturn === 'CUSTOMER' || (returnOrder.typeReturn === 'SUPPLIER' && !isAdmin)}
                isLoadingData={isLoadingItem}
                returnOrderItem={returnOrderItem}
                withCollapse={returnOrder.typeReturn === 'CUSTOMER' && (isAdmin || isSuperAdmin || isAdminInvoice)}
                handleUpdateItem={handleUpdateItem}
                handleOpenCollapse={handleOpenCollapse}
              />
            ) : (
              <ListProductMobile typeReturn={returnOrder.typeReturn} returnOrderItem={returnOrderItem} isLoadingData={isLoadingItem} />
            )}
          </Grid>

          <Grid container item lg={12} sm={12} md={12} xs={12}>
            <Typography variant='body2'>Total Item : {returnOrderItem.length}</Typography>
            <br />
          </Grid>

          <Grid container item lg={6} xl={6} sm={12} md={6} xs={12}>
            <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
              <Typography variant='h5'>Keterangan</Typography>
              <div dangerouslySetInnerHTML={{ __html: returnOrder.notes || '-' }}></div>
            </Grid>
          </Grid>

          {returnOrder.typeReturn === 'SUPPLIER' && isSuperAdmin && (
            <Grid item lg={6} xl={6} sm={12} md={6} xs={12}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align='right' className={classes.cellSummaryRight}>
                      Subtotal
                    </TableCell>
                    <TableCell align='right' className={classes.cellSummaryLeft}>
                      <NumberFormat
                        value={returnOrder.totalPrice + returnOrder.totalDiscount}
                        prefix={'Rp'}
                        thousandSeparator={true}
                        displayType='text'
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align='right' className={classes.cellSummaryRight}>
                      Total Harga
                    </TableCell>

                    <TableCell align='right' className={classes.cellSummaryLeft}>
                      <NumberFormat value={returnOrder.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </TableCell>
                  </TableRow>

                  {returnOrder.typeReturn === 'SUPPLIER' && !isAdmin && (
                    <TableRow>
                      <TableCell align='right' className={classes.cellSummaryRight}>
                        Total Kredit
                      </TableCell>
                      <TableCell align='right' className={classes.cellSummaryLeft}>
                        <NumberFormat value={returnOrder.totalCredit} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Grid>
          )}

          {returnOrder.typeReturn === 'CUSTOMER' && (
            <Grid item lg={6} xl={6} sm={12} md={6} xs={12}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align='right' className={classes.cellSummaryRight}>
                      Subtotal
                    </TableCell>
                    <TableCell align='right' className={classes.cellSummaryLeft}>
                      <NumberFormat
                        value={returnOrder.totalPrice + returnOrder.totalDiscount}
                        prefix={'Rp'}
                        thousandSeparator={true}
                        displayType='text'
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align='right' className={classes.cellSummaryRight}>
                      Total Harga
                    </TableCell>

                    <TableCell align='right' className={classes.cellSummaryLeft}>
                      <NumberFormat value={returnOrder.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          )}
        </Grid>
      </PaperCustom>
    </Grid>
  );
};

export default ReturnDetail;
