import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import { Add, CreateOutlined, ExpandLessOutlined, ExpandMore, HighlightOff, Remove } from '@material-ui/icons';
import useRouter from 'hooks/useRouter';
import React, { FC, useEffect, useState } from 'react';
import { PRIMARY_MOBILE, WHITE } from 'constants/colors';
interface Props {
  idColly: number;
  idBagColly: number;
  openModalUpdate: boolean;
  handleUpdate: (totalItem: number) => void;
  collyResource: typeCollyModel[];
  setOpenModalUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'inline-block',
    width: '100vh',
    position: 'relative'
  },
  titleContainer: {
    fontWeight: 500,
    fontSize: '1.2rem'
  },
  button: {
    borderRadius: '15px',
    padding: '7px 0'
  },
  itemBody: {
    display: 'flex',
    gap: '1.4em',
    padding: '0.6em 0'
  },
  buttonAction: {
    background: PRIMARY_MOBILE,
    '&:hover': {
      background: '#008ABF'
    }
  },
  textFieldInput: {
    textAlign: 'center'
  },
  textStyle: {
    fontSize: '0.8rem'
  },
  wrapProductName: {
    padding: '0 1em'
  },
  containerItem: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',

    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: '0.4em 1.2em 0.4em 1.9em',
    marginLeft: '-30px',

    width: '45vh'
  },
  containerItemActive: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',

    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(2, 169, 234, 0.12)',
    position: 'absolute',
    padding: '0.4em 1.2em 0.4em 1.9em',
    marginLeft: '-30px',

    width: '45vh'
  },
  textSecondary: {
    fontSize: '0.8rem',
    color: 'grey'
  },
  textMobile: {
    fontSize: '14px',
    fontWeight: 500
  },
  cotainerAction: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: '1rem'
  }
}));
const CollyUpdate: FC<Props> = ({ openModalUpdate, setOpenModalUpdate, collyResource, handleUpdate, idColly, idBagColly }) => {
  const classes = useStyles();
  const { history } = useRouter();
  const [qty, setQty] = useState<number>(0);
  const [qtyBefore, setQtyBefore] = useState<number>(0);

  const getColly = collyResource.filter(value => value.id === idColly)[0];

  const getBagColly =
    (getColly && getColly.BagItem && collyResource.filter(value => value.id === idColly)[0].BagItem.find(value => value.id === idBagColly)) || '';

  useEffect(() => {
    setQty((getBagColly && getBagColly.totalItem) || 0);
    setQtyBefore((getBagColly && getBagColly.totalItem) || 0);
  }, [idBagColly]);
  const handleSubmit = () => {
    handleUpdate(qty);
  };
  return (
    <Dialog open={openModalUpdate} style={{ maxHeight: 80 + 'vh', marginTop: '60px' }} scroll='paper' fullWidth>
      <DialogTitle>
        <Grid item container direction='column' alignItems='center' xs={12}>
          <Typography className={classes.titleContainer}>Edit Quantity {(getColly && getColly.name) || ''}</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ overflowX: 'hidden', minHeight: '25vh' }}>
        <Grid xs={12} className={classes.itemBody}>
          <Grid xs={10}>
            <Typography className={classes.textMobile}>{(getBagColly && getBagColly.productName) || ''}</Typography>
            {/* <Typography className={classes.textSecondary}>Total Pengepakan: {getColly!.totalItem} Unit</Typography> */}
          </Grid>
          <Grid xs={2}>
            <img
              src={getBagColly && getBagColly.productImage}
              alt='gambar produk'
              width='60px'
              height='60px'
              style={{ borderRadius: '0.3rem', border: '1px solid rgba(0, 0, 0, 0.08)' }}
            />
          </Grid>
        </Grid>
        <Grid xs={12} className={classes.cotainerAction}>
          <IconButton
            aria-label='remove'
            component='div'
            className={classes.buttonAction}
            size='small'
            onClick={() => {
              setQty(qty - 1);
            }}
            disabled={qty < 1}
          >
            <Remove style={{ color: WHITE }} />
          </IconButton>
          <TextField
            value={qty}
            size='small'
            onChange={e => {
              setQty(Number(e.target.value));
            }}
            error={qty > qtyBefore}
            helperText={qty > qtyBefore && 'Jumlah melebihi qty pengepakan'}
            InputProps={{
              classes: {
                input: classes.textFieldInput
              },
              inputMode: 'numeric'
            }}
          />
          <IconButton
            aria-label='add'
            disableFocusRipple={false}
            disableRipple={true}
            component='div'
            disabled={qty >= qtyBefore}
            className={classes.buttonAction}
            onClick={() => {
              setQty(qty + 1);
            }}
            size='small'
          >
            <Add style={{ color: WHITE }} />
          </IconButton>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid xs={12} container justify='center'>
          <Grid xs={11} container direction='column' style={{ gap: '0.6rem' }}>
            <Button size='small' fullWidth className={classes.button} color='primary' onClick={() => handleSubmit()}>
              Konfirmasi
            </Button>
            <Button size='small' fullWidth variant='text' color='primary' onClick={() => setOpenModalUpdate(false)}>
              Tutup
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CollyUpdate;
