import React, { FC } from 'react';
import { TableBody, TableHead, TableRow, TableCell, TableContainer, makeStyles, TableFooter } from '@material-ui/core';
import BodyRow from './components/BodyRow';
import { HeaderRow, TableCustom } from 'components/Table';
import { dummyInvoice } from 'utils/dummy';
import NumberFormat from 'react-number-format';

interface Props {
  isLoadingData: boolean;
  count: number;
  currentPage: number;
  invoices: InvoiceModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  indexCollapse: number;
  openCollapse: boolean;
  statusPayment: string;
  invoiceId: string;
  totalPrice: number;
  totalRetur: number;
  totalNet: number;
  totalPay: number;
  setStatusPayment: React.Dispatch<React.SetStateAction<string>>;
  setInvoiceId: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  handleOpenCollapse: (id: number) => React.MouseEventHandler;
}

const useStyles = makeStyles({
  container: {
    maxHeight: 640
  },
  textFooter: {
    fontSize: '15px',
    color: '#000',
    fontWeight: 500
  }
});

const InvoiceTable: FC<Props> = props => {
  const classes = useStyles();
  const {
    isLoadingData,
    invoices,
    order,
    orderBy,
    indexCollapse,
    openCollapse,
    totalPrice,
    totalRetur,
    totalPay,
    setOrder,
    setOrderBy,
    handleOpenCollapse
  } = props;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer className={classes.container}>
      <TableCustom stickyHeader size='small'>
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={[
              { label: '' },
              { id: 'id', label: 'Nomor', align: 'center' },
              { id: 'createdAt', label: 'Tgl Terbit', align: 'center' },
              { id: 'totalPay', label: 'Jumlah', align: 'center' },
              { id: 'totalPiece', label: 'Retur', align: 'center' },
              { id: 'totalPrice', label: 'Net Jual', align: 'center' },
              { id: 'totalPay', label: 'Total Bayar', align: 'center' }
            ]}
          />
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow
                key={index}
                index={index}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                isLoading={isLoadingData}
                invoice={dummyInvoice}
                handleOpenCollapse={handleOpenCollapse(index)}
              />
            ))
          ) : invoices.length > 0 ? (
            invoices.map((value, index) => (
              <BodyRow
                key={index}
                index={index}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                isLoading={isLoadingData}
                invoice={value}
                handleOpenCollapse={handleOpenCollapse(index)}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={8} align='center'>
                Data Tidak Tersedia.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell className={classes.textFooter} colSpan={3}>
              <p>
                Total Invoice : {invoices.length} <br /> Total Lunas :
                {(invoices.length > 0 && invoices.filter(value => value.statusPayment === 'PAID').length) || 0} <br />
                Total Belum Lunas :{(invoices.length > 0 && invoices.filter(value => value.statusPayment === 'UNPAID').length) || 0}
              </p>
            </TableCell>
            <TableCell className={classes.textFooter} align='right'>
              <NumberFormat value={totalPrice + totalRetur} prefix={'Rp'} thousandSeparator={true} displayType='text' />
            </TableCell>
            <TableCell className={classes.textFooter} align='right'>
              <NumberFormat value={totalRetur} prefix={'Rp'} thousandSeparator={true} displayType='text' />
            </TableCell>
            <TableCell className={classes.textFooter} align='right'>
              <NumberFormat value={totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
            </TableCell>
            <TableCell className={classes.textFooter} colSpan={2} align='right'>
              <NumberFormat value={totalPay} prefix={'Rp'} thousandSeparator={true} displayType='text' />
            </TableCell>
          </TableRow>
        </TableFooter>
      </TableCustom>
    </TableContainer>
  );
};

export default InvoiceTable;
