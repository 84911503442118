import {
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Add, Today } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import axios, { CancelTokenSource } from 'axios';
import { Breadcrumb, Page, PaperCustom } from 'components';
import DateRangeFilter from 'components/DateRangeFilter';
import { INCOME_STATEMENT_BASE_URL } from 'constants/url';
import { format, startOfMonth } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { dummyMetaData } from 'utils/dummy';
import IncomeStatementTable from './components/IncomeStatementTable';
import IncomeStatementForm from './components/CreatePage';
import SearchInput from 'components/SearchInput';
import { GREEN, WHITE } from 'constants/colors';
import IncomeStatementDelete from './components/IncomeStatementDelete';

export type TypeIncomeStatement = {
  isLoading: boolean;
  data: IncomeStatement[];
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em'
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  }
}));

const IncomeStatementPage = () => {
  const classes = useStyles();
  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const [incomeStatement, setIncomeStatement] = useState<TypeIncomeStatement>({
    isLoading: false,
    data: []
  });
  const [incomeStatementId, setIncomeStatementId] = useState<IncomeStatement | null>(null);
  const [id, setId] = useState<number>(0);
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const handleOpenCreate = () => setIsCreate(true);
  const handleCloseCreate = useCallback(() => setIsCreate(false), []);
  const handleCloseUpdate = useCallback(() => setIsUpdate(false), []);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [meta, setMeta] = useState<MetaData>(dummyMetaData);
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [queryString, setQueryString] = useState<string>();
  const [isAction, setIsAction] = useState<{ isOpen: boolean; isLoading: boolean; id: number; action: 'delete' | 'update' | 'detail' }>({
    isOpen: false,
    isLoading: false,
    id: 0,
    action: 'delete'
  });

  const handleCalendarFilterClick = () => setOpenCalendarFilter(true);

  const fetchData = useCallback(async () => {
    setIncomeStatement(prev => ({ ...prev, isLoading: true }));
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('keyword', queryString);
      }
      params.append('page', currentPage.toString());
      // params.append('startDate', format(new Date(startDate), 'yyyy-MM-dd'));
      // params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));
      if (orderBy || order) {
        params.append('orderBy', orderBy);
        params.append('ordered', order);
      }
      return params;
    };

    try {
      const { data } = await axios.get(`${INCOME_STATEMENT_BASE_URL}?${getQueryParams()}`, {
        cancelToken
      });
      setIncomeStatement(prev => ({ ...prev, isLoading: false, data: data.data }));
      setMeta(data.meta);
    } catch (error) {
      console.log(error);
    }
  }, [queryString, order, orderBy, startDate, endDate, currentPage]);

  const handleAction = (isOpen: boolean, id: number, action: 'delete' | 'update' | 'detail' = 'delete') => {
    setIsAction(prev => ({ ...prev, isOpen, id, action }));

    console.log('isAction :', id);
    if (action === 'update') {
      handleOnUpdate(id);
      setId(id);
      setIsCreate(true);
    } else if (action === 'detail') {
      handleOnUpdate(id);
      setId(id);
      setIsCreate(true);
    }
  };

  const handleOnUpdate = async (id: number) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    try {
      const { data } = await axios.get(`${INCOME_STATEMENT_BASE_URL}/${id}`, { cancelToken: cancelTokenSource.token });
      setIncomeStatementId(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, isCreate]);

  const handleDelete = async () => {
    setIsAction(prev => ({ ...prev, isLoading: true }));
    try {
      const { data } = await axios.delete(`${INCOME_STATEMENT_BASE_URL}/${isAction.id}`);
      setIsAction(prev => ({ ...prev, isLoading: false, isOpen: false }));
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Page title='Laba Rugi'>
      {isCreate ? (
        <IncomeStatementForm setId={setId} id={id} open={isCreate} setIsCreate={setIsCreate} />
      ) : (
        <Container>
          <Grid xs={12}>
            <Typography variant='h1' component='h1'>
              Laba Rugi
            </Typography>
            <Breadcrumb />
          </Grid>

          <PaperCustom>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container direction='row' justify='flex-end'>
                {/* <Grid container item lg={6} sm={6} md={6} justify='flex-start' alignItems='center'>
                  <Grid item>
                    <FormControl fullWidth variant='outlined' hiddenLabel size='small'>
                      <SearchInput
                        withBorder
                        withTransition={false}
                        width={150}
                        placeHolder='Cari...'
                        iconColor='#989898'
                        tableSearchValue={query}
                        setTableSearchValue={setQuery}
                      />
                    </FormControl>
                  </Grid>
                </Grid> */}

                <Grid item xl={5} lg={5} md={12} sm={12} xs={12} container alignItems='center' justify='flex-end' style={{ columnGap: '1em' }}>
                  <Grid item>
                    {/* <Tooltip title='Calendar filter' placement='top'>
                      <IconButton color='primary' aria-label='filter date' component='span' onClick={handleCalendarFilterClick}>
                        <Today />
                      </IconButton>
                    </Tooltip> */}
                    <Tooltip title='Tambah data' placement='top'>
                      <Button onClick={handleOpenCreate} size='small' startIcon={<Add fontSize='small' />}>
                        Tambah Tutup Buku
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid container direction='row' spacing={2}>
              {' '}
              <Grid item xl={6} lg={1} md={1} sm={6} xs={6}>
                <Tooltip title='Memuat Ulang'>
                  <Button>
                    <RefreshIcon />
                  </Button>
                </Tooltip>
              </Grid>
              <Grid container alignItems='center' item xl={12} lg={8} md={12} sm={12} xs={12}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography variant='h6'>
                    {`Menampilkan ${meta.total || 0} Biaya   (${meta.from || 0} - ${meta.to || 0} dari ${meta.total || 0})`}
                  </Typography>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography color='primary'>
                    Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid> */}

            <Grid xs={12} container className={classes.container}>
              <Grid xs={12}>
                <IncomeStatementDelete
                  open={isAction.isOpen && isAction.action.includes('delete')}
                  handleClose={() => handleAction(false, 0, 'delete')}
                  handleSubmit={handleDelete}
                  isLoading={isAction.isLoading}
                />
                <IncomeStatementTable incomeStatement={incomeStatement} handleAction={handleAction} />
              </Grid>
              {incomeStatement.data.length > 0 && (
                <Grid xs={12} container>
                  <Pagination
                    count={meta.last_page}
                    onChange={(event, page) => setCurrentPage(page)}
                    page={meta.current_page}
                    boundaryCount={2}
                    variant='outlined'
                    shape='rounded'
                  />
                </Grid>
              )}
              <DateRangeFilter
                openCalendarFilter={openCalendarFilter}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                handleClose={() => setOpenCalendarFilter(false)}
              />
            </Grid>
          </PaperCustom>
        </Container>
      )}
    </Page>
  );
};

export default IncomeStatementPage;
