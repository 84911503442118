import React, { FC, useState } from 'react';
import { HeaderRow, TableCellHead, TableCustom } from 'components/Table';
import {
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Select,
  MenuItem,
  FormControl,
  TableContainer,
  Checkbox,
  ListItemText,
  Divider
} from '@material-ui/core';
import BodyRow from './components/BodyRow';
import { dummyPartner } from 'utils/dummy';

interface Props {
  isLoadingData: boolean;
  partners: PartnerModel[];
  isDelete: boolean;
  isHR: boolean;
  isBuyer: boolean;
  isSuperAdmin: boolean;
  name: string;
  zones: ZoneModel[];
  zone: number[];
  setZone: React.Dispatch<React.SetStateAction<number[]>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  type: string;
  setType: React.Dispatch<React.SetStateAction<string>>;
  partnerId: string;
  setPartnerId: React.Dispatch<React.SetStateAction<string>>;
  cellPhoneNumber: string;
  setCellPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  order: 'asc' | 'desc';
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  orderBy: string;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  handleConfirmationDelete: (id: number) => React.MouseEventHandler;
}

const PartnerTable: FC<Props> = props => {
  const {
    isLoadingData,
    partners,
    order,
    orderBy,
    isBuyer,
    name,
    isDelete,
    zones,
    zone,
    setZone,
    setName,
    isHR,
    isSuperAdmin,
    type,
    setType,
    partnerId,
    setPartnerId,
    cellPhoneNumber,
    setCellPhoneNumber,
    setOrder,
    setOrderBy,
    handleConfirmationDelete
  } = props;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const headerCell: any[] = [
    { id: 'partnerId', label: 'ID', sort: true },
    { id: 'name', label: 'NAMA MITRA', sort: true },
    { id: 'partnerType', label: 'TIPE', align: 'center', sort: true },
    { id: 'cellPhoneNumber', label: 'NO. HANDPHONE', sort: true },
    { label: '' }
  ];

  if (
    !isBuyer &&
    ((isSuperAdmin && type === '') ||
      (isSuperAdmin && type !== 'SUPPLIER') ||
      (!isSuperAdmin && type === '') ||
      (!isSuperAdmin && type !== 'SUPPLIER'))
  ) {
    headerCell.splice(3, 0, { id: 'ZoneId', label: 'Rute', sort: true });
  }

  const isShow =
    !isBuyer &&
    ((isSuperAdmin && type === '') ||
      (isSuperAdmin && type !== 'SUPPLIER') ||
      (!isSuperAdmin && type === '') ||
      (!isSuperAdmin && type !== 'SUPPLIER'));

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headers={headerCell} />
          <TableRow>
            <TableCellHead variant='head'>
              <TextField id='partnerId' placeholder='ID' value={partnerId} onChange={event => setPartnerId(event.target.value)} />
            </TableCellHead>
            <TableCellHead variant='head'>
              <TextField id='name' placeholder='Mitra' value={name} onChange={event => setName(event.target.value)} />
            </TableCellHead>

            <TableCellHead variant='head'>
              {(isSuperAdmin || isHR) && (
                <FormControl fullWidth variant='outlined' size='small'>
                  <Select id='type' value={type as string} placeholder='Tipe' onChange={event => setType(event.target.value as string)}>
                    <MenuItem key={1} value='SUPPLIER'>
                      Supplier
                    </MenuItem>
                    <MenuItem key={2} value='CUSTOMER'>
                      Customer
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </TableCellHead>

            {isShow && (
              <TableCellHead variant='head'>
                <Select
                  id='demo-mutiple-checkbox'
                  margin='dense'
                  multiple
                  variant='outlined'
                  fullWidth
                  value={zone}
                  onChange={e => setZone(e.target.value as number[])}
                  renderValue={selected =>
                    `${zones
                      .filter(value => (selected as number[]).includes(value.id as number))
                      .map(value => value.name)
                      .join(',')
                      .substr(0, 8)}...`
                  }
                >
                  <MenuItem key={0} value={0} disabled={zone.length > 0 && zone.some(val => val !== 0)}>
                    <Checkbox color='primary' checked={zone.some(_value => _value === 0)} />
                    <ListItemText primary={'Belum Ada rute'} />
                  </MenuItem>

                  <Divider />

                  {zones.map(value => (
                    <MenuItem key={value.id} value={value.id} disabled={zone.length > 0 && zone.some(val => val === 0)}>
                      <Checkbox color='primary' checked={zone.some(_value => _value === value.id)} />
                      <ListItemText primary={value.name} />
                    </MenuItem>
                  ))}
                </Select>
              </TableCellHead>
            )}

            <TableCellHead variant='head'>
              <TextField
                id='cellPhoneNumber'
                variant='outlined'
                placeholder='No HP'
                value={cellPhoneNumber}
                onChange={event => setCellPhoneNumber(event.target.value)}
                size='small'
              />
            </TableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow
                key={index}
                isLoading={isLoadingData}
                partner={dummyPartner}
                isShow={false}
                isDelete={false}
                onDelete={handleConfirmationDelete(0)}
              />
            ))
          ) : partners && partners.length > 0 ? (
            partners.map((value, index) => (
              <BodyRow
                key={index}
                isLoading={isLoadingData}
                partner={value}
                isDelete={isDelete}
                onDelete={handleConfirmationDelete(value.id)}
                isShow={isShow}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={isShow ? 7 : 6} align='center'>
                {'Data Tidak Tersedia.'}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default PartnerTable;
