import React, { FC } from 'react';
import { Button, Checkbox, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { format } from 'date-fns';
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: '1px solid #E0E0E0',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    padding: '0.9em 0',
    cursor: 'pointer',
    transition: 'all 1s ease',
    '&:hover': {
      boxShadow: '0 2px 5px rgb(0 0 0 / 14%)'
    }
  }
}));
interface Props {
  deliveryInvoice: DeliveryInvoice;
  handleOpenModalInfo: (invoiceId: number[]) => void;
}
export const BodyTable: FC<Props> = (props): JSX.Element => {
  const { deliveryInvoice, handleOpenModalInfo } = props;
  const classes = useStyles();

  return (
    <Grid xs={12} className={classes.container}>
      <Grid style={{ flex: 0.22, paddingLeft: '0.5em' }}>
        {/* <Checkbox
          color='primary'
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          value={deliveryInvoice.id}
          checked={deliveryInvoice.checked}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCheckListIndividual(deliveryInvoice.id, event.target.checked)}
        /> */}

        {Array.isArray(deliveryInvoice) ? (
          deliveryInvoice.map((val: { invoiceNumber: string }) => <Typography variant='h6'>{val.invoiceNumber}</Typography>)
        ) : (
          <Typography variant='h6'>{deliveryInvoice.invoiceNumber} </Typography>
        )}
      </Grid>
      <Grid style={{ flex: 0.11 }}>
        {Array.isArray(deliveryInvoice) ? (
          deliveryInvoice.map((val: { createdAt: string }) => (
            <Typography align='center'>{format(new Date(val.createdAt), 'dd MMM yyyy')}</Typography>
          ))
        ) : (
          <Typography align='center'>{format(new Date(deliveryInvoice.createdAt), 'dd MMM yyyy')}</Typography>
        )}
      </Grid>
      <Grid style={{ flex: 0.19 }}>
        {Array.isArray(deliveryInvoice) ? (
          deliveryInvoice.map((val: { partnerName: string }) => <Typography variant='h6'>{val.partnerName}</Typography>)
        ) : (
          <Typography variant='h6'>{deliveryInvoice.partnerName}</Typography>
        )}
      </Grid>
      <Grid style={{ flex: 0.08 }}>
        {Array.isArray(deliveryInvoice) ? (
          deliveryInvoice.map((val: { totalkoli: number }) => <Typography align='center'>{val.totalkoli} Koli</Typography>)
        ) : (
          <Typography align='center'>{deliveryInvoice.totalkoli} Koli</Typography>
        )}
      </Grid>
      <Grid style={{ flex: 0.09 }}>
        {Array.isArray(deliveryInvoice) ? (
          deliveryInvoice.map((val: { zoneName: string }) => <Typography align='center'>{val.zoneName}</Typography>)
        ) : (
          <Typography align='center'>{deliveryInvoice.zoneName}</Typography>
        )}
      </Grid>
      <Grid style={{ flex: 0.13 }}>
        {Array.isArray(deliveryInvoice) ? (
          deliveryInvoice.map((val: { salesName: string }) => <Typography variant='h6'>{val.salesName}</Typography>)
        ) : (
          <Typography variant='h6'>{deliveryInvoice.salesName}</Typography>
        )}
      </Grid>
      <Grid style={{ flex: 0.19, paddingRight: '0.5em' }} container justify='space-between' alignItems='center'>
        {Array.isArray(deliveryInvoice) ? (
          <Typography variant='h6'>{deliveryInvoice[0].warehouse}</Typography>
        ) : (
          <Typography variant='h6'>{deliveryInvoice.warehouse}</Typography>
        )}

        <Button
          variant='outlined'
          color='primary'
          onClick={() =>
            handleOpenModalInfo(Array.isArray(deliveryInvoice) ? deliveryInvoice.map((val: { id: number }) => val.id) : [deliveryInvoice.id])
          }
        >
          Buat SJ
        </Button>
      </Grid>
    </Grid>
  );
};
