import React, { FC, Fragment } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { EditOutlined, DeleteOutline } from '@material-ui/icons';
import { TableCellMiddle, TableCellEnd, TableCellStart, TableRowCustom } from 'components/Table';
import Skeleton from '@material-ui/lab/Skeleton';
import useRouter from 'hooks/useRouter';
import NumberFormat from 'react-number-format';

interface Props {
  commission: CommissionModel;
  isLoading: boolean;
  handleConfirmationDelete: (id: number) => React.MouseEventHandler;
}


const BodyRow: FC<Props> = props => {
  const { history } = useRouter();
  const { commission, isLoading, handleConfirmationDelete } = props;

  const handleUpdate = () => {
    history.push('/komisi/tambah', { id: commission.id, salesId: commission.SalesId });
  };

  return (
    <Fragment>
      <TableRowCustom>
        <TableCellStart>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : commission.month}</TableCellStart>

        <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : commission.year}</TableCellMiddle>
        <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : commission.totalInvoice}</TableCellMiddle>
        <TableCellMiddle>
          {isLoading ? (
            <Skeleton variant='text' width={'100%'} height={25} />
          ) : (
            <NumberFormat value={commission.totalBill} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>
        <TableCellMiddle>
          {isLoading ? (
            <Skeleton variant='text' width={'100%'} height={25} />
          ) : (
            <NumberFormat value={commission.totalCommission} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>

        <TableCellEnd align='right'>
          {isLoading ? (
            <Skeleton variant='text' width={'100%'} height={25} />
          ) : (
            <>
              <Tooltip title='Perbaharui Data'>
                <IconButton size='small' onClick={handleUpdate}>
                  <EditOutlined fontSize='small' />
                </IconButton>
              </Tooltip>

              <Tooltip title='Hapus'>
                <IconButton size='small' onClick={handleConfirmationDelete(commission.id)}>
                  <DeleteOutline fontSize='small' />
                </IconButton>
              </Tooltip>
            </>
          )}
        </TableCellEnd>
      </TableRowCustom>
    </Fragment>
  );
};

export default BodyRow;
