import React from 'react';
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  OutlinedInput,
  TableCell,
  Theme,
  Typography
} from '@material-ui/core';

import { ClearRounded, PhotoSizeSelectActualOutlined, SaveRounded } from '@material-ui/icons';
import {
  BLACK,
  GREEN_MOBILE,
  GREY,
  KONFIRMASI_STATUS_MOBILE,
  PACKING_STATUS_MOBILE,
  PREINVOICE_STATUS_MOBILE,
  PRIMARY_MOBILE,
  RED_MOBILE,
  TERTUNDA_STATUS_MOBILE,
  YELLOW_MOBILE
} from 'constants/colors';
import SalesOrderStatus from 'typings/enum/SalesOrderStatus';

interface Props {
  title?: string | number;
  isReject?: boolean;
  isDiscount?: boolean;
  tab?: number;
  isColly?: boolean;
  isLoadingDiskon?: boolean;
  status?: boolean;
  hasStatus?: string;
  qtyStatus?: string;
  hasAction?: boolean;
  checked?: boolean;
  isUpdateQty?: boolean;
  isLoadingCancel?: boolean;
  imageUrl?: string;
  isChecking?: boolean;
  isEditDiscount?: boolean;
  isCheckList?: boolean;
  handleOpenImage?: (imagePath: string) => void;
  typeUnit?: string;
  isAction?: boolean;
  isEdit?: boolean;
  isVisit?: boolean;
  valueCheckList?: number;
  newQty?: number;
  beforeTitle?: React.ReactNode;
  setNewQty?: React.Dispatch<React.SetStateAction<number>>;
  loadingReject?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  UpdateItemQty?: () => void;
  handleInvoice?: () => void;
  handleRejectAdmin?: () => void;
  handleCancelRejectAdmin?: () => void;
  onClick?: () => void;
  onClickEdit?: () => void;
  setAction?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  textStyle: {
    fontSize: '14px',
    lineHeight: '14px',
    marginLeft: '15px'
  },
  imageItem: {
    objectFit: 'contain',
    width: '44px',
    cursor: 'pointer'
  },
  noImage: {
    backgroundColor: GREY,
    height: '46px',
    width: '46px',
    borderRadius: '.5em',
    display: 'grid',
    placeItems: 'center'
  },

  statusAccept: {
    color: GREEN_MOBILE,
    opacity: 0.5,
    display: 'grid',
    fontSize: '14px',
    placeItems: 'center',

    padding: '5px 13px',
    borderRadius: '20px',
    border: '1px solid ' + GREEN_MOBILE
  },

  statusItem: {
    fontSize: '14px',
    fontWeight: 500
  },
  statusReject: {
    color: RED_MOBILE,
    opacity: 0.5,
    display: 'grid',
    fontSize: '14px',
    placeItems: 'center',
    padding: '5px 13px',
    borderRadius: '20px',
    border: '1px solid ' + RED_MOBILE
  },
  statusRejectSuper: {
    color: PRIMARY_MOBILE,
    opacity: 0.5,
    display: 'grid',
    fontSize: '14px',
    placeItems: 'center',
    padding: '5px 13px',
    borderRadius: '20px',
    border: '1px solid ' + PRIMARY_MOBILE
  }
}));
const BodyItem: React.FC<Props> = props => {
  const {
    title,
    imageUrl,
    isLoadingDiskon,
    isDiscount,
    handleInvoice,
    isLoadingCancel,
    isUpdateQty,
    typeUnit,
    onClick,
    beforeTitle,
    checked,
    isEditDiscount,
    isColly,
    isReject,
    UpdateItemQty,
    status,
    handleCancelRejectAdmin,
    hasAction,
    handleOpenImage,
    tab,
    handleChange,
    valueCheckList,
    isCheckList,
    handleRejectAdmin,
    loadingReject,
    isEdit,
    onClickEdit,
    isAction,
    hasStatus,
    setAction,
    isVisit,
    newQty,
    setNewQty,
    isChecking
  } = props;

  const classes = useStyles();
  const tabAction = () => {
    if (tab === 1) {
      return loadingReject && isLoadingCancel ? (
        <CircularProgress size={20} color='primary' />
      ) : (
        <Typography
          className={classes.statusAccept}
          style={{ padding: '0.4em 3em', opacity: 1, cursor: 'pointer' }}
          onClick={handleCancelRejectAdmin}
        >
          Batalkan
        </Typography>
      );
    }
  };

  return (
    <TableCell style={{ whiteSpace: 'nowrap' }}>
      {!tab ? (
        <>
          {isReject && tab === 0 && (
            <>
              {loadingReject && isLoadingDiskon ? (
                <CircularProgress size={20} color='primary' />
              ) : hasStatus === SalesOrderStatus.HAS_INVOICE ? (
                <Typography style={{ opacity: 1, cursor: 'pointer', color: PRIMARY_MOBILE }} align='center' onClick={handleInvoice}>
                  Lihat Invoice
                </Typography>
              ) : hasStatus === SalesOrderStatus.REJECT ? (
                <Typography className={classes.statusItem} style={{ color: RED_MOBILE }} align='center'>
                  Telah Ditolak
                </Typography>
              ) : hasAction ? (
                <Typography>-</Typography>
              ) : (
                <Typography
                  className={classes.statusReject}
                  style={{ padding: '0.4em 3em', opacity: 1, cursor: 'pointer' }}
                  onClick={handleRejectAdmin}
                >
                  Tolak
                </Typography>
              )}
            </>
          )}
          {isCheckList && <Checkbox color='primary' disabled={!isColly} onChange={e => handleChange!(e)} value={valueCheckList} checked={checked} />}

          {isEdit && (
            <>
              <FormControl variant='outlined' size='small' required error={isChecking}>
                <InputLabel htmlFor='outlined-adornment-amount'>Qty Request</InputLabel>
                <OutlinedInput
                  id='outlined-adornment-amount'
                  value={newQty}
                  endAdornment={
                    <InputAdornment position='end' style={{ color: BLACK }}>
                      {typeUnit}
                    </InputAdornment>
                  }
                  onChange={e => setNewQty!(Number(e.target.value))}
                  labelWidth={105}
                />
              </FormControl>
              {isChecking && <FormHelperText error={isChecking}>Qty melebihi stok tersedia</FormHelperText>}
            </>
          )}

          {hasStatus && status && (
            <div>
              {hasStatus === SalesOrderStatus.HAS_INVOICE ? (
                <Typography className={classes.statusItem} align='center' style={{ color: GREEN_MOBILE }}>
                  Invoice telah dibuat
                </Typography>
              ) : hasStatus === SalesOrderStatus.CHECKING ? (
                <Typography className={classes.statusItem} align='center' style={{ color: PREINVOICE_STATUS_MOBILE }}>
                  Checking
                </Typography>
              ) : hasStatus === SalesOrderStatus.CONFIRM ? (
                <Typography className={classes.statusItem} align='center' style={{ color: KONFIRMASI_STATUS_MOBILE }}>
                  Konfirmasi
                </Typography>
              ) : hasStatus === SalesOrderStatus.PACKING ? (
                <Typography className={classes.statusItem} align='center' style={{ color: PACKING_STATUS_MOBILE }}>
                  Packing
                </Typography>
              ) : hasStatus === SalesOrderStatus.PENDING ? (
                <Typography className={classes.statusItem} align='center' style={{ color: TERTUNDA_STATUS_MOBILE }}>
                  Sedang Menunggu
                </Typography>
              ) : hasStatus === SalesOrderStatus.PREVIEW ? (
                <Typography className={classes.statusItem} align='center' style={{ color: '#4FACC9' }}>
                  Peninjauan
                </Typography>
              ) : (
                <Typography className={classes.statusItem} align='center' style={{ color: RED_MOBILE }}>
                  Telah Ditolak
                </Typography>
              )}
            </div>
          )}

          {title && !isEdit && (
            <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-start', alignItems: 'center' }}>
              {beforeTitle}
              {imageUrl && imageUrl !== 'noimage' && (
                <Grid container alignItems='center'>
                  <img src={imageUrl} alt='image-item' className={classes.imageItem} loading='lazy' onClick={() => handleOpenImage!(imageUrl)} />
                </Grid>
              )}

              {imageUrl && imageUrl === 'noimage' && (
                <div className={classes.noImage}>
                  <PhotoSizeSelectActualOutlined />
                </div>
              )}

              <div style={{ flexGrow: 1 }}>
                <Typography
                  className={classes.textStyle}
                  style={{ fontWeight: imageUrl ? 500 : 'normal', color: (isDiscount && title !== '-') || isVisit ? YELLOW_MOBILE : BLACK }}
                >
                  {title}

                  {isDiscount && title != '-' && !isEditDiscount && (
                    <Typography color='primary' onClick={onClick} style={{ cursor: 'pointer' }}>
                      Edit
                    </Typography>
                  )}

                  {isUpdateQty && (
                    <Typography color='primary' style={{ cursor: 'pointer' }} onClick={onClickEdit}>
                      Edit
                    </Typography>
                  )}
                </Typography>
              </div>
            </div>
          )}

          {isAction && (
            <div>
              <IconButton
                component='span'
                onClick={UpdateItemQty}
                disabled={isChecking}
                style={{ color: isChecking ? GREY : GREEN_MOBILE, cursor: 'pointer' }}
              >
                <SaveRounded />
              </IconButton>
              <ClearRounded color='error' style={{ cursor: 'pointer' }} onClick={setAction} />
            </div>
          )}
        </>
      ) : (
        tabAction()
      )}
    </TableCell>
  );
};

export default BodyItem;
