import React, { FC, useState } from 'react';
import { Dialog, DialogContent, Button, DialogActions } from '@material-ui/core';
import { DialogTitle } from 'components/Dialog';
import StockWizard from './components/StockWizard';

interface Props {
  purchases: StockModel[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPurchases: React.Dispatch<React.SetStateAction<StockModel[]>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const StockModal: FC<Props> = props => {
  const { open, setOpen, setPurchases, handleSnackBar } = props;
  const [isSubmit, setSubmit] = useState<boolean>(false);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOnSubmit = () => {
    setSubmit(true);
  };

  return (
    <Dialog maxWidth={'lg'} disableBackdropClick={true} open={open}>
      <DialogTitle>Tambah Stok Produk</DialogTitle>

      <DialogContent>
        <StockWizard isSubmit={isSubmit} setSubmit={setSubmit} setOpen={setOpen} handleSnackBar={handleSnackBar} setPurchases={setPurchases} />
      </DialogContent>

      <DialogActions>
        <Button disabled={isSubmit} color='secondary' onClick={handleCancel}>
          Batal
        </Button>
        <Button disabled={isSubmit} onClick={handleOnSubmit}>
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StockModal;
