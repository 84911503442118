import React, { FC } from 'react';
import { createStyles, Theme, Typography, IconButton, WithStyles, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import CreateZoneForm from './components/CreateZoneForm';

interface Props {
  zones: ZoneModel[];
  zone: ZoneModel | null;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setZones: React.Dispatch<React.SetStateAction<ZoneModel[]>>;
  setZone: React.Dispatch<React.SetStateAction<ZoneModel | null>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1)
    }
  });

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose?: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const CreateZone: FC<Props> = props => {
  const { zones, open, setOpen, zone, setZones, setZone, handleSnackBar } = props;

  const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    setOpen(false);
  };

  return (
    <Dialog maxWidth={'sm'} disableBackdropClick={true} onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
      <DialogTitle id='customized-dialog-title'>
        <Typography id='modal-title' variant='h1' component='h1'>
          {zone ? 'Perbaharui Rute' : 'Tambah Rute'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <CreateZoneForm
          zone={zone}
          zones={zones}
          setZones={setZones}
          setZone={setZone}
          setOpen={setOpen}
          handleSnackBar={handleSnackBar}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CreateZone;
