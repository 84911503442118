import React, { FC } from 'react';
import { Dialog, DialogContent, Button, DialogActions } from '@material-ui/core';
import StockWizard from './components/StockWizard';
import { DialogTitle } from 'components/Dialog';

interface Props {
  mutation: MutationModel;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMutation: React.Dispatch<React.SetStateAction<MutationModel>>;
  setMutations: React.Dispatch<React.SetStateAction<MutationModel[]>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const TransferDetailModal: FC<Props> = props => {
  const { open, mutation, setOpen, setMutations, handleSnackBar, setMutation } = props;

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Dialog maxWidth={'md'} disableBackdropClick={true} open={open}>
      <DialogTitle>Detail Mutasi Barang</DialogTitle>
      <DialogContent>
        <StockWizard mutation={mutation} handleSnackBar={handleSnackBar} setMutations={setMutations} setMutation={setMutation} />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancel}>
          Keluar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferDetailModal;
