import React, { FC, Fragment } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom, TableRowCollapse } from 'components';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { IconButton } from '@material-ui/core';

interface Props {
  appLog: AppLogsModel;
  isLoading: boolean;
  openCollapse: boolean;
  index: number;
  indexCollapse: number;
  handleOpenCollapse: React.MouseEventHandler;
}

const BodyRow: FC<Props> = props => {
  const { appLog, isLoading, openCollapse, index, indexCollapse, handleOpenCollapse } = props;

  return (
    <Fragment>
      <TableRowCustom>
        {/* {isLoading ? (
            <Skeleton variant='text' width={60} height={25} />
          ) : (
            <>
              <IconButton aria-label='expand row' size='small' onClick={handleOpenCollapse}>
                {openCollapse && index === indexCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
              {appLog.initial}
            </>
          )} */}

        <TableCellStart>
          {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : appLog.User ? appLog.User.firstName + '' + appLog.User.lastName : '-'}
        </TableCellStart>

        <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={150} height={25} /> : appLog.description}</TableCellMiddle>

        <TableCellMiddle align='center'>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : appLog.createdAt}</TableCellMiddle>

        <TableCellEnd align='center'>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : appLog.time}</TableCellEnd>
      </TableRowCustom>

      <TableRowCollapse open={openCollapse && index === indexCollapse} colSpan={5}></TableRowCollapse>
    </Fragment>
  );
};

export default BodyRow;
