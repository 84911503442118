import React, { useState, useEffect, Fragment } from 'react';
import { Divider, makeStyles, Theme, Grid, Checkbox, Typography, Card, CardActionArea, CardMedia, CardContent, CardActions } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { BLACK, GREEN_MOBILE, RED_MOBILE, YELLOW_MOBILE, GREY, PRIMARY_MOBILE } from 'constants/colors';
import { InfoOutlined, PhotoSizeSelectActualOutlined } from '@material-ui/icons';
import TypeUser from 'typings/enum/TypeUser';
import SalesOrderStatus from 'typings/enum/SalesOrderStatus';
import { remarkCode } from 'constants/remarkCode';
import NumberFormat from 'react-number-format';
const useStyles = makeStyles((theme: Theme) => ({
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: ' none'
    }
  },

  mobileContainer: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
      '& > .MuiGrid-item': {
        padding: 0
      }
    }
  },
  control: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: '0.3px solid lightgrey'
  },

  desktop: {
    [theme.breakpoints.down('md')]: {
      display: ' none'
    }
  },
  fontMobile: {
    color: BLACK
  },
  note: {
    margin: '.7em 0'
  },
  fontSizeMobile: {
    fontSize: 0.875 + 'rem',
    lineHeight: 1.2 + 'rem'
  },
  fontBold: {
    fontWeight: 500,
    fontSize: 0.875 + 'rem',
    lineHeight: 1.2 + 'rem'
  },
  itemContainer: {
    border: '0.6px solid rgba(0, 0, 0, 0.08)',
    margin: theme.spacing(1),
    borderRadius: '6px',
    padding: theme.spacing(1)
  },
  fontColorAccept: {
    color: GREEN_MOBILE,
    fontWeight: 500,
    borderRadius: '20px',
    border: '1px solid' + GREEN_MOBILE,
    padding: '5px 10px',
    opacity: 0.5
  },
  borderAction: {
    borderSpacing: '1px',
    borderStyle: 'solid'
  },
  cardItem: {
    backgroundColor: '#f7f9fc',

    position: 'relative',

    minWidth: '100vw'
  },
  buttonColly: {
    borderRadius: '0.5em'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 345
  },
  media: {
    height: 200,
    display: 'grid',
    placeItems: 'center',
    padding: '0 1.2rem',
    backgroundColor: '#e0e0e0',
    '& .MuiCardMedia-img ': {
      objectFit: 'cover'
    }
  }
}));

interface Props {
  salesOrder: SalesOrderModel;
  rejectOrder: ItemAction[];
  salesOrderItem: SalesOrderItemModel;
  collyFinal: typeCollyFinal;

  isLoading: boolean;
  handleConfirm: React.MouseEventHandler;
  handleOpenImage: (imagePath: string) => void;
  handleReject: React.MouseEventHandler;
  addItemAction: (value: ItemAction) => void;
  tempItemColly: (data: typeColly) => void;
  deleteColly: (id: number) => void;
  tab: number;
  index: number;
  isAccess: string;
  hasInvoice: boolean;
  openCollapse: boolean;
  openTooltip: boolean;
  indexCollapse: number;
  indexTooltip: number;
  isConvertAllowed: boolean;
  currentWareHouse: string;
  isCan: boolean;
  setGetIdClick: React.Dispatch<React.SetStateAction<number>>;
  handleOpenCollapse: React.MouseEventHandler;
  handleOpenTooltip: React.MouseEventHandler;
  handleCloseTooltip: () => void;
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setHasOpenModal: React.Dispatch<React.SetStateAction<number>>;
  openAlertPrice: boolean;
  indexAlertPrice: number;
  handleOpenAlertPrice: React.MouseEventHandler;
  handleCloseAlertPrice: () => void;
  handleAcceptItem: (id: number) => void;
  handleRejectItem: (id: number) => void;
  handletRequestEdit: (id: number, productId: number, totalQty: number, remark: string, remarkCode: string) => void;
  openAlertDiscount: boolean;
  indexAlertDiscount: number;
  handleOpenAlertDiscount: React.MouseEventHandler;
  handleCloseAlertDiscount: () => void;
}
const BodyRowMobile: React.FC<Props> = props => {
  const {
    salesOrderItem,
    isLoading,
    rejectOrder,
    isAccess,
    collyFinal,
    index,
    handleAcceptItem,
    handleRejectItem,
    tempItemColly,
    deleteColly,
    isConvertAllowed,
    addItemAction,
    isCan,
    handletRequestEdit,
    handleReject,
    handleOpenImage,
    setGetIdClick,
    handleConfirm,
    setHasOpenModal,
    setOpenEdit,
    handleOpenCollapse,
    handleOpenTooltip,
    handleCloseTooltip,
    openCollapse,
    indexCollapse,
    indexTooltip,
    openTooltip,
    tab,
    salesOrder,
    currentWareHouse,
    openAlertPrice,
    indexAlertPrice,
    handleOpenAlertPrice,
    openAlertDiscount,
    indexAlertDiscount,
    handleOpenAlertDiscount
  } = props;
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(false);
  const [checked, setChecked] = React.useState<boolean>(false);

  const [newQty, setNewQty] = useState<number>(salesOrderItem.totalItem);
  const handleInvoice = (id: number) => {
    window.open(`/invoice/${id}`, '_blank');
  };

  const handleOpenModal = () => {
    setOpenEdit(true);
    setHasOpenModal(7);
  };

  useEffect(() => {
    setChecked(false);
  }, [collyFinal]);

  const handleOpenEdit = () => {
    setOpenEdit(true);
    setHasOpenModal(2);
    setGetIdClick(salesOrderItem.id);
  };

  const handleAccept = () => {
    if (!rejectOrder.some(val => val.id === salesOrderItem.id)) {
      addItemAction!({
        id: salesOrderItem.id,
        status: isAccess === TypeUser.ADMIN01 ? SalesOrderStatus.PACKING : isAccess === TypeUser.PICKER ? SalesOrderStatus.PREVIEW : '',
        remark: `${isAccess} telah melakukan pengecekan`,
        remarkCode: remarkCode.ACCEPT
      });
    }
    handleAcceptItem(salesOrderItem.id);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(event.target.checked);

    if (event.target.checked) {
      tempItemColly({
        id: 0,
        SalesOrderItemId: salesOrderItem.id,
        totalItem: salesOrderItem.totalItem - salesOrderItem.totalInBag,
        totalItemBefore: salesOrderItem.totalItem - salesOrderItem.totalInBag,
        imageUrl: salesOrderItem.productImage,
        productName: (salesOrderItem.productName && salesOrderItem.productName) || '',
        typeUnit: salesOrderItem.typeUnit,
        wareHouse: salesOrderItem.warehouse
      });
    } else {
      deleteColly(salesOrderItem.id);
    }
  };

  const handleRejectItemAction = () => {
    if (!rejectOrder.some(val => val.id === salesOrderItem.id)) {
      addItemAction!({ id: salesOrderItem.id, status: SalesOrderStatus.REJECT, remark: '', remarkCode: remarkCode.REJECT });
    }
    handleRejectItem(salesOrderItem.id);
  };

  const handleIsAction = () => {
    setIsEdit(false);
    setNewQty(salesOrderItem.totalItem);
  };
  const handleStatus = (status: string) => {
    if (tab == 0) {
      if ((isAccess === TypeUser.SALES || isAccess === TypeUser.SUPERVISOR) && salesOrderItem.status === SalesOrderStatus.PENDING) {
        return (
          <Fragment>
            <Typography
              className={classes.fontSizeMobile}
              style={{ fontWeight: 500, color: '#979797', border: '1px solid #979797', padding: '3px 20px', borderRadius: '15px' }}
              onClick={() => {
                setHasOpenModal(11);
                setGetIdClick(salesOrderItem.id);
                setOpenEdit(true);
              }}
            >
              HAPUS
            </Typography>
            <Divider orientation='vertical' flexItem />
            <Typography
              className={classes.fontSizeMobile}
              style={{ fontWeight: 500, border: '1px solid ' + PRIMARY_MOBILE, padding: '3px 20px', borderRadius: '15px' }}
              onClick={() => {
                setHasOpenModal(10);
                setGetIdClick(salesOrderItem.id);
                setOpenEdit(true);
              }}
              color='primary'
            >
              EDIT
            </Typography>
          </Fragment>
        );
      } else if (isAccess === TypeUser.ADMIN01 && (status === SalesOrderStatus.PREVIEW || status === SalesOrderStatus.PENDING)) {
        return (
          <Fragment>
            <Typography
              className={classes.fontSizeMobile}
              onClick={() => handleAccept()}
              style={{ fontWeight: 500, color: GREEN_MOBILE, border: '1px solid ' + GREEN_MOBILE, padding: '3px 10px', borderRadius: '9px' }}
            >
              Setujui
            </Typography>
            <Divider orientation='vertical' flexItem />
            <Typography
              className={classes.fontSizeMobile}
              color='error'
              style={{ fontWeight: 500, border: '1px solid ' + RED_MOBILE, padding: '3px 13px', borderRadius: '10px' }}
              onClick={() => {
                setHasOpenModal(2);
                setOpenEdit(true);
                setGetIdClick(salesOrderItem.id);
              }}
            >
              Tolak
            </Typography>
          </Fragment>
        );
      } else if (isAccess === TypeUser.ADMIN01 && status === SalesOrderStatus.PACKING) {
        return (
          <Typography
            className={classes.fontSizeMobile}
            style={{
              color: GREEN_MOBILE,
              fontWeight: 500,
              padding: '5px 10px',
              opacity: 0.5
            }}
          >
            Telah di Setujui
          </Typography>
        );
      } else if (isAccess === TypeUser.PICKER && status === SalesOrderStatus.PENDING) {
        return (
          salesOrder.isAccept &&
          !salesOrder.isPlafon && (
            <Fragment>
              <Typography
                className={classes.fontSizeMobile}
                onClick={() => handleAccept()}
                style={{ fontWeight: 500, color: GREEN_MOBILE, border: '1px solid ' + GREEN_MOBILE, padding: '3px 20px', borderRadius: '15px' }}
              >
                Setujui
              </Typography>
              <Divider orientation='vertical' flexItem />
              <Typography
                className={classes.fontSizeMobile}
                color='error'
                style={{ fontWeight: 500, border: '1px solid ' + RED_MOBILE, padding: '3px 20px', borderRadius: '15px' }}
                onClick={() => {
                  setHasOpenModal(2);
                  setGetIdClick(salesOrderItem.id);
                  setOpenEdit(true);
                }}
              >
                Tolak
              </Typography>
            </Fragment>
          )
        );
      } else if (isAccess === TypeUser.PICKER && status === SalesOrderStatus.PREVIEW) {
        return (
          <Typography
            className={classes.fontSizeMobile}
            style={{
              color: GREEN_MOBILE,
              fontWeight: 500,
              padding: '5px 10px',
              opacity: 0.5
            }}
          >
            Telah di Setujui
          </Typography>
        );
      } else if (status === SalesOrderStatus.REJECT) {
        return (
          <Grid xs={12}>
            <Typography
              className={classes.fontSizeMobile}
              color='error'
              style={{ fontWeight: 500, padding: '5px 10px', opacity: 0.5 }}
              align='center'
            >
              Telah di Tolak
            </Typography>
            <Typography className={classes.fontSizeMobile} style={{ fontWeight: 500 }} align='center'>
              Oleh: {salesOrderItem.rejectBy || '-'}
            </Typography>
          </Grid>
        );
      }
    } else if (tab === 1) {
      return (
        <Typography
          className={classes.fontSizeMobile}
          onClick={() => {
            // handleRejectItemAction()
            setHasOpenModal(2);
            setOpenEdit(true);
            setGetIdClick(salesOrderItem.id);
          }}
          color='error'
          style={{ fontWeight: 500, padding: '3px 20px' }}
        >
          Tolak
        </Typography>
      );
    } else {
      if (isCan) return;
      return (
        <Typography
          className={classes.fontSizeMobile}
          onClick={() => handleAccept()}
          style={{ fontWeight: 500, padding: '5px 10px', color: GREEN_MOBILE }}
        >
          Setujui
        </Typography>
      );
    }
  };

  const handleStatusText = (status: string) => {
    if (status === SalesOrderStatus.REJECT) {
      return 'Ditolak';
    } else if (status === SalesOrderStatus.CHECKING) {
      return 'Checking';
    } else if (status === SalesOrderStatus.CONFIRM) {
      return 'Konfirmasi';
    } else if (status === SalesOrderStatus.HAS_INVOICE) {
      return 'Invoice telah dibuat';
    } else if (status === SalesOrderStatus.PACKING) {
      return 'Packing';
    } else if (status === SalesOrderStatus.PENDING) {
      return 'Sedang Menunggu';
    } else if (status === SalesOrderStatus.PREVIEW) {
      return 'Preview';
    } else {
      return 'Tidak Ada Status';
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Grid container className={classes.itemContainer} xs={12}>
          {[0, 1, 2, 3, 4, 5].map(val => (
            <Grid xs={12}>
              <Skeleton variant='text' height={25} width='100%' />
            </Grid>
          ))}
        </Grid>
      ) : isAccess === TypeUser.ADMIN03 ? (
        <Grid item container direction='row' className={classes.itemContainer} xs={12}>
          <Grid item xs={2}>
            <img
              src={salesOrderItem.productImage}
              alt='Item Image'
              width='40px'
              height='40px'
              onClick={() => handleOpenImage(salesOrderItem.productImage || '')}
            />
          </Grid>
          <Grid xs={9} item>
            <Typography className={(classes.fontMobile, classes.fontSizeMobile)} style={{ fontWeight: 500, marginLeft: '4px' }}>
              {salesOrderItem.productName}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid xs={5} item style={{ marginTop: '5px' }}>
            <Typography className={(classes.fontMobile, classes.fontSizeMobile)} color='textSecondary'>
              Harga
            </Typography>
            <Typography className={(classes.fontMobile, classes.fontSizeMobile)} color='textSecondary'>
              Kuantitas
            </Typography>
            <Typography
              className={(classes.fontMobile, classes.fontSizeMobile)}
              color='textSecondary'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              Diskon {salesOrderItem.discount >= 1 && <InfoOutlined fontSize='small' style={{ color: YELLOW_MOBILE, marginLeft: '5px' }} />}
            </Typography>
            <Typography className={(classes.fontMobile, classes.fontSizeMobile)} color='textSecondary'>
              Total Harga
            </Typography>
          </Grid>

          <Grid xs={7} item container direction='column' alignItems='flex-end' style={{ marginTop: '5px' }}>
            <Typography className={(classes.fontMobile, classes.fontSizeMobile)}>Rp {salesOrderItem.price}</Typography>
            <Typography className={(classes.fontMobile, classes.fontSizeMobile)}>
              {salesOrderItem.totalItem} {salesOrderItem.typeUnit}
            </Typography>
            <Typography
              className={(classes.fontMobile, classes.fontSizeMobile)}
              style={{ display: 'flex', alignItems: 'center', color: salesOrderItem.discount > 0 ? YELLOW_MOBILE : '' }}
            >
              {salesOrderItem.discount > 0 && (
                <Typography
                  className={classes.fontSizeMobile}
                  color='primary'
                  style={{ fontWeight: 500, marginRight: '5px' }}
                  onClick={() => {
                    setOpenEdit(true);
                    setHasOpenModal(7);
                  }}
                >
                  Lihat
                </Typography>
              )}

              {salesOrderItem.discount > 1 ? (
                <NumberFormat value={salesOrderItem.discount} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              ) : (
                '-'
              )}
            </Typography>
            <Typography className={(classes.fontMobile, classes.fontSizeMobile)}>
              <NumberFormat value={salesOrderItem.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
            </Typography>
          </Grid>
        </Grid>
      ) : isAccess === TypeUser.ADMIN02 ? (
        <>
          <Grid
            className={classes.control}
            container
            direction='row'
            justify='flex-start'
            xs={12}
            style={{ backgroundColor: salesOrderItem.totalInBag && salesOrderItem.totalInBag >= salesOrderItem.totalItem ? GREY : '' }}
          >
            <Grid item xs={2} container direction='row' justify='center'>
              <Checkbox
                size='small'
                checked={checked}
                disabled={(salesOrderItem.totalInBag && salesOrderItem.totalInBag >= salesOrderItem.totalItem) || false}
                onChange={e => handleChange(e)}
                value={salesOrderItem.ProductId}
                color='primary'
              />
            </Grid>
            <Grid item xs={5}>
              <Typography className={(classes.fontMobile, classes.fontSizeMobile)} style={{ fontWeight: 500 }}>
                Nama Produk
              </Typography>
              <Typography className={(classes.fontMobile, classes.fontSizeMobile)}>{salesOrderItem.productName}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={(classes.fontMobile, classes.fontSizeMobile)} style={{ fontWeight: 500 }}>
                Qty Pengepakan
              </Typography>
              <Typography className={(classes.fontMobile, classes.fontSizeMobile)}>
                {salesOrderItem.totalItem.toString().concat(` ${salesOrderItem.typeUnit}`)}
              </Typography>
            </Grid>
            <Grid item xs={2} container direction='column' alignItems='center' justify='space-between'>
              <img
                src={salesOrderItem.productImage}
                alt='Item Image'
                width='40px'
                height='40px'
                onClick={() => handleOpenImage(salesOrderItem.productImage || '')}
              />
              {salesOrderItem.totalInBag < salesOrderItem.totalItem && (
                <Typography
                  className={classes.fontSizeMobile}
                  color='primary'
                  style={{ fontWeight: 500, marginTop: '5px' }}
                  onClick={() => {
                    setOpenEdit(true);
                    setGetIdClick(salesOrderItem.id);
                    setHasOpenModal(1);
                  }}
                >
                  EDIT
                </Typography>
              )}
            </Grid>
          </Grid>
        </>
      ) : isAccess === TypeUser.ADMIN01 ? (
        <Grid xs={11} style={{ marginLeft: '0.3em' }} container alignItems='stretch'>
          <Grid xs={12} style={{ margin: '0.5em 0' }} container alignItems='stretch'>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={salesOrderItem.productImage}
                  title='Image Product'
                  onClick={() => handleOpenImage(salesOrderItem.productImage || '')}
                >
                  {!salesOrderItem.productImage && (
                    <Typography variant='h6' style={{ color: '#bdbdbd' }}>
                      {salesOrderItem.productName}
                    </Typography>
                  )}
                </CardMedia>
                <CardContent>
                  <Grid xs={12} container>
                    <Typography className={(classes.fontMobile, classes.fontSizeMobile)} style={{ fontWeight: 500, color: '#616161' }}>
                      {salesOrderItem.productName}
                    </Typography>
                    <Grid xs={12} style={{ borderTop: '1px solid lightgrey', margin: '0.4em 0' }} />

                    {salesOrderItem.status !== SalesOrderStatus.REJECT && (
                      <Grid xs={12} container justify='flex-end'>
                        <Typography
                          className={classes.fontSizeMobile}
                          color='primary'
                          style={{ fontWeight: 500 }}
                          align='right'
                          onClick={() => {
                            setGetIdClick(salesOrderItem.id);
                            setHasOpenModal(1);
                            setOpenEdit(true);
                          }}
                        >
                          EDIT
                        </Typography>
                      </Grid>
                    )}

                    <Grid xs={12}>
                      <Typography variant='subtitle2' style={{ fontSize: '1rem' }}>
                        Qty Pengecekan
                      </Typography>
                      <Typography style={{ fontSize: '1.2rem', fontWeight: 500 }}>
                        {salesOrderItem.totalItem} {salesOrderItem.typeUnit}
                      </Typography>
                    </Grid>

                    <Grid xs={12}>
                      <Typography variant='subtitle2' style={{ fontSize: '1rem' }}>
                        Stok Gudang
                      </Typography>

                      <Typography style={{ fontSize: '1.2rem', fontWeight: 500 }}>
                        {((salesOrderItem.salesStock && salesOrderItem.salesStock) || 0) +
                          ((salesOrderItem.holdStock && salesOrderItem.holdStock) || 0)}{' '}
                        {salesOrderItem.typeUnit}
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>

              <CardActions
                disableSpacing
                style={{
                  marginTop: 'auto'
                }}
              >
                <Grid xs={12} item container direction='row' justify='space-evenly' alignItems='center'>
                  {handleStatus(salesOrderItem.status)}
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      ) : isAccess === TypeUser.SUPERADMIN ? (
        <></>
      ) : (
        <React.Fragment>
          <Grid item container direction='row' className={classes.itemContainer} spacing={1} xs={12}>
            <Grid item xs={2}>
              {salesOrderItem.productImage ? (
                <img
                  src={salesOrderItem.productImage}
                  alt='Item Image'
                  width='40px'
                  height='40px'
                  onClick={() => handleOpenImage(salesOrderItem.productImage || '')}
                  style={{ borderRadius: '.5em' }}
                />
              ) : (
                <Grid style={{ backgroundColor: GREY, height: '41px', width: '41px', borderRadius: '.5em', display: 'grid', placeItems: 'center' }}>
                  <PhotoSizeSelectActualOutlined />
                </Grid>
              )}
            </Grid>
            <Grid xs={8} item>
              <Typography className={(classes.fontMobile, classes.fontSizeMobile)} style={{ fontWeight: 500, marginLeft: '4px' }}>
                {salesOrderItem.productName}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            {isAccess === TypeUser.SALES || isAccess === TypeUser.SUPERVISOR ? (
              <React.Fragment>
                <Grid item xs={8}>
                  <Typography variant='subtitle2' className={classes.fontSizeMobile}>
                    Qty Request
                  </Typography>

                  <Typography variant='subtitle2' className={classes.fontSizeMobile}>
                    Harga per Qty
                  </Typography>

                  <Typography variant='subtitle2' className={classes.fontSizeMobile}>
                    Diskon per Qty
                  </Typography>

                  <Typography variant='subtitle2' className={classes.fontSizeMobile}>
                    Total Harga (Sesudah diskon)
                  </Typography>
                </Grid>
                <Grid item xs={4} container direction='column' alignItems='flex-end'>
                  {isLoading ? (
                    [...new Array(8)].map(val => <Skeleton variant='text' width={100} height={25} />)
                  ) : (
                    <>
                      <Typography className={classes.fontBold}>
                        {salesOrderItem.totalItem} {salesOrderItem.typeUnit}
                      </Typography>
                      <Typography className={classes.fontBold}>
                        <NumberFormat value={salesOrderItem.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />{' '}
                      </Typography>
                      <Typography
                        className={classes.fontBold}
                        style={{ color: ((salesOrderItem && salesOrderItem.discountUnit) || 0) > 0 ? YELLOW_MOBILE : '' }}
                      >
                        {((salesOrderItem && salesOrderItem.discountUnit) || 0) > 0 ? (
                          <NumberFormat value={salesOrderItem.discountUnit} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                        ) : (
                          '-'
                        )}
                      </Typography>

                      <Typography className={classes.fontBold}>
                        <NumberFormat value={salesOrderItem.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                      </Typography>
                    </>
                  )}
                </Grid>
                <Grid xs={12}>
                  <Divider />
                </Grid>
                <Grid xs={12} className={classes.note}>
                  <Typography variant='subtitle2' className={classes.fontSizeMobile}>
                    Catatan
                  </Typography>
                  <Typography>{salesOrderItem.notes || 'Tidak ada catatan'}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='subtitle2' className={classes.fontSizeMobile}>
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={6} container direction='column' alignItems='flex-end'>
                  {isLoading ? (
                    <Skeleton variant='text' width={100} height={25} />
                  ) : (
                    <Typography className={classes.fontBold}>{handleStatusText(salesOrderItem.status)}</Typography>
                  )}
                </Grid>

                {salesOrderItem.status === SalesOrderStatus.REJECT && (
                  <>
                    <Grid xs={12}>
                      <Divider />
                    </Grid>
                    <Grid xs={12} className={classes.note}>
                      <Grid xs={12} container justify='space-between'>
                        <Typography variant='subtitle2' className={classes.fontSizeMobile}>
                          Alasan Penolakan:
                        </Typography>
                        <Typography className={classes.fontSizeMobile}>Oleh: {salesOrderItem.rejectBy}</Typography>
                      </Grid>
                      <Typography>{salesOrderItem.remark || 'Tidak ada alasan'}</Typography>
                    </Grid>
                  </>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Grid xs={12}>
                  {salesOrderItem.status !== SalesOrderStatus.REJECT && salesOrder.isAccept && !salesOrder.isPlafon && (
                    <Typography
                      className={classes.fontSizeMobile}
                      color='primary'
                      align='right'
                      style={{ fontWeight: 500, paddingRight: '10px' }}
                      onClick={() => {
                        setGetIdClick(salesOrderItem.id);
                        setHasOpenModal(1);
                        setOpenEdit(true);
                      }}
                    >
                      EDIT
                    </Typography>
                  )}
                </Grid>

                <Grid
                  item
                  xs={6}
                  container
                  direction='column'
                  alignItems='center'
                  style={{ borderRight: '1px solid rgba(0, 0, 0, 0.08)', marginLeft: '0.4em' }}
                >
                  <Typography variant='subtitle2' style={{ fontSize: '1rem' }}>
                    Qty Request
                  </Typography>

                  {isLoading ? (
                    <Skeleton variant='text' width={100} height={25} />
                  ) : (
                    <Typography style={{ fontSize: '1.3rem', fontWeight: 500 }}>
                      {salesOrderItem.totalItem} {salesOrderItem.typeUnit}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={5} container direction='column' alignItems='center'>
                  <Typography variant='subtitle2' style={{ fontSize: '1rem' }}>
                    Stok Gudang
                  </Typography>

                  {isLoading ? (
                    <Skeleton variant='text' width={100} height={25} />
                  ) : (
                    <Typography style={{ fontSize: '1.3rem', fontWeight: 500 }}>
                      {((salesOrderItem.salesStock && salesOrderItem.salesStock) || 0) +
                        ((salesOrderItem.holdStock && salesOrderItem.holdStock) || 0)}{' '}
                      {salesOrderItem.typeUnit}
                    </Typography>
                  )}
                </Grid>
                <Grid xs={12}>
                  <Divider />
                </Grid>
                <Grid xs={12} className={classes.note}>
                  <Typography variant='subtitle2' className={classes.fontSizeMobile}>
                    Catatan
                  </Typography>
                  <Typography>{salesOrderItem.notes || 'Tidak ada catatan'}</Typography>
                </Grid>
              </React.Fragment>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid xs={12} item container direction='row' justify='space-evenly' alignItems='center'>
              {handleStatus(salesOrderItem.status)}
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default BodyRowMobile;
