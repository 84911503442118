export const WHITE = '#FFFFFF';
export const WHITE_2 = '#F2F2F2';
export const BLUE_PRIMARY = '#02A9EA';
export const BLUE_SECONDARY = '#0088BD';
export const BLACK = '#333333';
export const BLACK_2 = '#2E384D';
export const BLACK_3 = '#222B45';
export const GREY = '#E0E0E0';
export const GRAY_3 = '#828282';
export const GREY_6 = '#F2F2F2';
export const GREEN = '#27ae60';
export const GREEN_LIGHT = '#06ec06';
export const BACKGROUND = '#F7F9FC';
export const GRADIENT_DRAWER_COLOR = 'linear-gradient(#0088BD 30%, #02A9EA 70%)';
export const ORANGE = '#F2994A';

export const RED = '#EB5757';
export const RED_SECONDARY = '#D42222';
export const WHITESEARCH = '#F7F9FC';
export const PENDING_COLOR = '#F2C94C';
export const CONFIRMATION_COLOR = '#27AE60';
export const YELLOW = '#FFF200';

export const ORANGE_MOBILE = '#EFA001';
export const PRIMARY_MOBILE = '#02A9EA';
export const GREEN_MOBILE = '#27AE60';
export const YELLOW_MOBILE = '#EFA001';
export const RED_MOBILE = '#CC1A1A';
export const PREINVOICE_STATUS_MOBILE = '#6991B7';
export const PACKING_STATUS_MOBILE = '#B98F70';
export const KONFIRMASI_STATUS_MOBILE = '#80B130';
export const TERTUNDA_STATUS_MOBILE = '#E4AE42';
export const PENINJAUAN_STATUS_MOBILE = '#EFA001';
