import React, { FC } from 'react';
import { TableBody, TableHead, TableRow, TableCell, TableContainer } from '@material-ui/core';
import BodyRow from './components/BodyRow';
import { TableCustom } from 'components/Table';
import Skeleton from '@material-ui/lab/Skeleton';

interface Props {
  isLoadingData: boolean;
  returnOrderItem: InvoiceReturnItemModel[];
  typeReturn: string;
}

const ListProductMobile: FC<Props> = props => {
  const { isLoadingData, returnOrderItem, typeReturn } = props;

  return (
    <TableContainer>
      <TableCustom size='small'>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>Nama Produk</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            <TableRow>
              <TableCell colSpan={2}>
                <Skeleton variant='text' width='100%' />
              </TableCell>
            </TableRow>
          ) : (
            returnOrderItem.length > 0 &&
            returnOrderItem.map(value => <BodyRow key={value.id} isLoading={isLoadingData} item={value} typeReturn={typeReturn} />)
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default ListProductMobile;
