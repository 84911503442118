import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { Add, RemoveRounded } from '@material-ui/icons';
import FlexBox from 'components/FlexBox';
import { GREY, PRIMARY_MOBILE, WHITE } from 'constants/colors';
import React, { ReactNode } from 'react';
import { TProduct } from '..';

interface Props {
  productReviewResources: (TProduct & { totalItem: number })[];
  topSection: ReactNode;
  bottomSection: ReactNode;
  handleGetCartQuantity: (id: number) => number;
  handleIncreaseCartQuantity: ({ id, PurchaseOrderId, ProductId, typeUnit, productName, productImage, productCode, totalStock }: TProduct) => void;
  handleDecreaseCartQuantity: (id: number) => void;
  handleOpenProductCartDelete: (id: number) => void;
  handleChangeQuantity: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOpenImage: (imagePath: string) => void;
}

const useStyles = makeStyles(() => ({
  products: {
    borderTop: '1px solid rgba(0, 0, 0, 0.08)',
    maxHeight: '100vh',
    overflowY: 'scroll',
    padding: '1em 1em 1em 0'
  },
  productContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '1.5em'
  },

  productCard: {
    boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
    borderRadius: '.4em',
    rowGap: '1em',
    '&:hover': {
      boxShadow: 'rgb(0 0 0 / 20%) 0px 6px 4px -4px, rgb(0 0 0 / 14%) 0px 4px 4px 0px, rgb(0 0 0 / 12%) 0px 5px 7px 0px'
    }
  },
  productImage: {
    objectFit: 'cover',
    height: '300px',
    display: 'grid',
    placeItems: 'center',
    backgroundColor: GREY
  },
  cardContent: {
    height: '9em'
  },
  button: {
    borderRadius: '15px'
  },
  iconButton: {
    background: PRIMARY_MOBILE,
    '&:hover': {
      background: '#008ABF'
    }
  },
  icon: {
    color: WHITE
  },
  emptyImage: {
    padding: '0 1em'
  }
}));
const ProductReview = ({
  productReviewResources,
  topSection,
  bottomSection,
  handleGetCartQuantity,
  handleIncreaseCartQuantity,
  handleDecreaseCartQuantity,
  handleOpenProductCartDelete,
  handleChangeQuantity,
  handleOpenImage
}: Props) => {
  const classes = useStyles();

  return (
    <FlexBox container xs={12} rowGap={1}>
      {topSection}
      <Grid xs={12} container className={classes.products}>
        <Grid xs={12} className={classes.productContainer}>
          {productReviewResources.map(productReviewResource => (
            <Card className={classes.productCard} key={productReviewResource.id}>
              <CardActionArea>
                <CardMedia
                  image={productReviewResource.productImage}
                  title={productReviewResource.productName}
                  className={classes.productImage}
                  onClick={() => handleOpenImage(productReviewResource.productImage)}
                >
                  {productReviewResource.productImage === '' && (
                    <Typography variant='h6' color='textSecondary' align='center' className={classes.emptyImage}>
                      {productReviewResource.productName}
                    </Typography>
                  )}
                </CardMedia>
                <CardContent className={classes.cardContent}>
                  <Typography variant='h6'>{productReviewResource.productName}</Typography>
                  <Typography color='textSecondary'>{productReviewResource.productCode}</Typography>
                  <Grid xs={12} container justify='space-between'>
                    <Grid xs={10}>
                      {' '}
                      <Typography color='textSecondary' component='p'>
                        Stok Jual: {(productReviewResource.netStock || 0) - (productReviewResource.holdItem || 0)}
                      </Typography>
                      <Typography color='textSecondary' component='p'>
                        Stok Tertahan: {productReviewResource.holdItem || 0}
                      </Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography component='p'>{productReviewResource.warehouse}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>

              <CardActions>
                <FlexBox
                  container
                  xs={12}
                  rowGap={1}
                  style={{
                    marginTop: '1em'
                  }}
                >
                  <Grid xs={12} container justify='space-around' alignItems='center'>
                    <IconButton
                      size='small'
                      color='primary'
                      disabled={handleGetCartQuantity(productReviewResource.ProductId) < 1}
                      className={classes.iconButton}
                      onClick={() => handleDecreaseCartQuantity(productReviewResource.ProductId)}
                    >
                      <RemoveRounded className={classes.icon} />
                    </IconButton>
                    <Grid xs={4}>
                      <TextField
                        id={String(productReviewResource.ProductId)}
                        variant='outlined'
                        autoFocus
                        inputProps={{ min: 0, style: { textAlign: 'center' }, inputMode: 'numeric' }}
                        size='small'
                        onChange={handleChangeQuantity}
                        fullWidth
                        value={handleGetCartQuantity(productReviewResource.ProductId)}
                      />
                    </Grid>
                    <IconButton
                      size='small'
                      color='primary'
                      className={classes.iconButton}
                      onClick={() =>
                        handleIncreaseCartQuantity({
                          ...productReviewResource
                        })
                      }
                    >
                      <Add className={classes.icon} />
                    </IconButton>
                  </Grid>
                  <Grid xs={12}>
                    <Divider />
                  </Grid>

                  <Grid xs={12} container justify='center'>
                    <Grid xs={11}>
                      <Button
                        fullWidth
                        variant='outlined'
                        size='small'
                        color='primary'
                        className={classes.button}
                        onClick={() => handleOpenProductCartDelete(productReviewResource.ProductId)}
                      >
                        Hapus
                      </Button>
                    </Grid>
                  </Grid>
                </FlexBox>
              </CardActions>
            </Card>
          ))}
        </Grid>
      </Grid>
      {bottomSection}
    </FlexBox>
  );
};

export default React.memo(ProductReview);
