import React, { FC } from 'react';
import { TableContainer, TableBody, TableHead, TableRow, TableCell, TextField, makeStyles } from '@material-ui/core';
import { HeaderRow, TableCellHead, TableCustom } from 'components/Table';
import { dummyItemInvoice } from 'utils/dummy';
import BodyRow from './components/BodyRow';

interface Props {
  isLoadingData: boolean;
  item: InvoiceItemModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  outDate: Date | null;
  invoiceNumber: string;
  setOutDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setInvoiceNumber: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles({
  cellTable: {
    width: '17%'
  }
});

const StockOutTable: FC<Props> = props => {
  const classes = useStyles();

  const { isLoadingData, item, order, orderBy, invoiceNumber, setInvoiceNumber, setOrder, setOrderBy } = props;

  const handleRequestSort = ({}, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={[
              { id: 'createdAt', label: 'Tanggal Keluar', sort: true },
              { id: 'invoiceNumber', label: 'No Invoice', sort: true },
              { id: 'productName', label: 'Nama Produk', sort: true },
              { id: 'totalItem', label: 'Jumlah', sort: true, align: 'center' },
              { id: 'WareHouseId', label: 'Lokasi Gudang', sort: true, align: 'center' }
            ]}
          />

          <TableRow>
            <TableCellHead variant='head' className={classes.cellTable}></TableCellHead>

            <TableCellHead variant='head' className={classes.cellTable}>
              <TextField placeholder='No Invoice' id='invoiceNumber' value={invoiceNumber} onChange={event => setInvoiceNumber(event.target.value)} />
            </TableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => <BodyRow key={index} isLoading={isLoadingData} item={dummyItemInvoice} />)
          ) : item && item.length > 0 ? (
            item.map((value, index) => <BodyRow key={index} isLoading={isLoadingData} item={value} />)
          ) : (
            <TableRow>
              <TableCell colSpan={5} align='center'>
                Data Tidak Tersedia.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default StockOutTable;
