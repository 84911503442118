import { Badge, Button, ButtonGroup, CircularProgress, Container, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Breadcrumb, Page, PaperCustom } from 'components';
import React, { useContext, useEffect, useState } from 'react';
import useRouter from 'hooks/useRouter';
import {
  COMPANY_BASE_URL,
  DELIVERY_ORDER_ALLOW_PRINT,
  DELIVERY_ORDER_DETAILS,
  DELIVERY_ORDER_PRINT,
  DELIVERY_ORDER_REQUEST_PRINT
} from 'constants/url';
import CCATMP from 'images/NewLogo.jpg';
import CompanyInfo from './CompanyInfo';
import DeliveryOrderTable from './DeliveryOrderTable';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import axios from 'axios';
import { dummyDelivery, dummyPartner, dummyCompany } from 'utils/dummy';
import { Skeleton } from '@material-ui/lab';
import QRCode from 'qrcode.react';
import useRole from 'hooks/useRole';
import TypeUser from 'typings/enum/TypeUser';
import { Print, PrintDisabled } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  containerBottom: {
    margin: '2em 0'
  },
  image: {
    objectFit: 'contain',
    height: '190px',
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  imageQr: {
    objectFit: 'contain',
    height: '120px',
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  linkInvoice: {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}));
const DeliveryOrdersDetail = () => {
  const classes = useStyles();
  const { match, history } = useRouter();
  const { currentUser } = useContext(CurrentUserContext);
  const canAuthorisation: string[] = [TypeUser.SUPERADMIN, TypeUser.ADMIN];
  const idDelivery = match.params.id;
  const [deliveryOrder, setDeliveryOrder] = useState<DeliveryOrders>(dummyDelivery);
  const [deliveryOrderItem, setDeliveryOrderItem] = useState<DeliveryOrderItem[]>([]);
  const [company, setCompany] = useState<CompanyModel>(dummyCompany);
  const [partner, setPartner] = useState<PartnerModel>(dummyPartner);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPrint, setIsPrint] = useState<boolean>(false);
  const [isLoadingAuth, setIsLoadingAuth] = useState<boolean>(false);

  const fetchCompany = async () => {
    try {
      const { data } = await axios.get(COMPANY_BASE_URL);
      setCompany(data.data);
    } catch (error) {
      console.log('error:', error);
    }
  };

  const fecthDelivery = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(DELIVERY_ORDER_DETAILS(+idDelivery));
      setPartner(data.data.Partner);
      setDeliveryOrder(data.data);
      setDeliveryOrderItem(data.data.DeliveryItem.Bag);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const deliveryPrint = async () => {
    setIsPrint(true);

    try {
      const { data } = await axios.get(DELIVERY_ORDER_PRINT(+idDelivery), { responseType: 'blob' });
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      setIsPrint(false);
      const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
      if (newwindow && window.focus) {
        newwindow.focus();
      }
      setDeliveryOrder({ ...deliveryOrder, canPrint: false });
    } catch (error) {
      console.log('error :', error);
    }
  };
  const handleLinkInvoice = (id: number) => {
    window.open(`/invoice/${id}`);
  };

  const handleAllowPrint = async () => {
    setIsLoadingAuth(true);
    try {
      const { data } = await axios.get(DELIVERY_ORDER_ALLOW_PRINT(+idDelivery));
      setDeliveryOrder({ ...deliveryOrder, requestPrint: data.data.requestPrint, canPrint: data.data.canPrint });
      setIsLoadingAuth(false);
    } catch (error) {
      console.log('error :', error);
    }
  };
  const handleRequestPrint = async () => {
    setIsLoadingAuth(true);
    try {
      const { data } = await axios.get(DELIVERY_ORDER_REQUEST_PRINT(+idDelivery));
      setDeliveryOrder({ ...deliveryOrder, requestPrint: data.data.requestPrint });
      setIsLoadingAuth(false);
    } catch (error) {
      console.log('error :', error);
    }
  };
  useEffect(() => {
    fetchCompany();
    fecthDelivery();
  }, []);

  return (
    <Page title='Surat Jalan Detail'>
      <Container>
        <Grid xs={12} container justify='space-between' alignItems='flex-end'>
          <Grid item>
            <Typography variant='h1' component='h1'>
              Surat Jalan
            </Typography>
            <Breadcrumb />
          </Grid>
          <Grid item>
            {canAuthorisation.includes((currentUser && currentUser.type) || '') && deliveryOrder.requestPrint && (
              <Button color='primary' onClick={handleAllowPrint} disabled={isLoadingAuth}>
                {isLoadingAuth ? <CircularProgress size={20} color='primary' /> : 'Izinkan Cetak'}
              </Button>
            )}
            {!canAuthorisation.includes((currentUser && currentUser.type) || '') && !deliveryOrder.canPrint && (
              <Badge
                color='error'
                variant='dot'
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                invisible={!deliveryOrder.requestPrint}
              >
                <Button color='primary' onClick={handleRequestPrint} disabled={isLoadingAuth || deliveryOrder.requestPrint}>
                  {isLoadingAuth ? <CircularProgress size={20} color='primary' /> : 'Meminta Cetak'}
                </Button>
              </Badge>
            )}
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid xs={12} container>
            <Grid xs={6}>
              <img className={classes.image} src={CCATMP} alt='Company Logo' />
            </Grid>
            <Grid xs={6} container>
              <Grid xs={12}>
                <Typography variant='h5' align='right'>
                  Surat Jalan
                </Typography>
              </Grid>
              <Grid xs={3}>
                {/* <Grid xs={3}> */}
                <Typography color='textSecondary'>No Surat Jalan</Typography>
                {deliveryOrder.InvoiceId.map((val, key) => (
                  <Typography color='textSecondary' key={key}>
                    Ref INV {deliveryOrder.InvoiceId.length > 1 && key + 1}
                  </Typography>
                ))}
                <Typography color='textSecondary'>Tanggal Order</Typography>
                <Typography color='textSecondary'>Rute</Typography>
                <Typography color='textSecondary'>Sales</Typography>
              </Grid>
              <Grid xs={9}>
                <Typography variant='h6' align='right'>
                  {isLoading ? <Skeleton /> : deliveryOrder.deliveryNumber}
                </Typography>
                {deliveryOrder.InvoiceId.map((val, key) => (
                  <Typography className={classes.linkInvoice} variant='h6' align='right' onClick={() => handleLinkInvoice(val.InvoiceId)} key={key}>
                    {val.invoiceNumber}
                  </Typography>
                ))}
                <Typography variant='h6' align='right'>
                  {isLoading ? <Skeleton /> : deliveryOrder.createdAt}
                </Typography>
                <Typography variant='h6' align='right'>
                  {isLoading ? <Skeleton /> : deliveryOrder.zoneName}
                </Typography>
                <Typography variant='h6' align='right'>
                  {isLoading ? <Skeleton /> : deliveryOrder.salesName}
                </Typography>
              </Grid>
              {/* <Grid xs={3} container justify='flex-end'>
                {isLoading ? (
                  <Grid xs={12} container justify='center'>
                    <CircularProgress size={50} style={{ marginTop: '20px' }} />
                  </Grid>
                ) : (
                  <QRCode className={classes.imageQr} value={deliveryOrder.deliveryNumber} />
                )}
              </Grid> */}
            </Grid>
          </Grid>
          <CompanyInfo company={company} partner={partner} isLoading={isLoading} />
          <DeliveryOrderTable deliveryOrderItem={deliveryOrderItem} isLoading={isLoading} />
          <Grid xs={12} className={classes.containerBottom}>
            {canAuthorisation.includes((currentUser && currentUser.type) || '') ? (
              <Button variant='contained' fullWidth disabled={isPrint} color='primary' style={{ borderRadius: '20px' }} onClick={deliveryPrint}>
                {isPrint ? <CircularProgress size={21} color='primary' /> : 'Cetak Surat Jalan'}
              </Button>
            ) : (
              <Button
                variant='contained'
                fullWidth
                disabled={isPrint || !deliveryOrder.canPrint}
                color='primary'
                style={{ borderRadius: '20px' }}
                onClick={deliveryPrint}
              >
                {isPrint ? <CircularProgress size={21} color='primary' /> : 'Cetak Surat Jalan'}
              </Button>
            )}
          </Grid>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default DeliveryOrdersDetail;
