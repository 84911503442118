import { Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { PRIMARY_MOBILE, WHITE } from 'constants/colors';
import React from 'react';
import { IList } from 'pages/DashboardPage';

const useStyles = makeStyles({
  container: {
    position: 'fixed',
    zIndex: 1,
    top: 30,
    right: 0,
    width: '3em',
    height: '100vh',
    rowGap: '1em'
  },
  textOrientation: {
    writingMode: 'vertical-rl',
    textOrientation: 'mixed'
  },
  list: {
    boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 3px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
    borderRadius: '.4em',
    background: `linear-gradient(to right, ${PRIMARY_MOBILE} 50%, ${WHITE} 50%)`,
    backgroundSize: '200% 100%',
    backgroundPosition: 'right bottom',
    transition: 'all .5s ease-in-out',
    '&:hover': {
      boxShadow: 'rgb(0 0 0 / 40%) 0px 1px 3px 0px, rgb(0 0 0 / 24%) 0px 1px 3px 0px, rgb(0 0 0 / 15%) 0px 1px 3px 0px'
    }
  },
  pointer: {
    cursor: 'pointer'
  },
  paddingSm: {
    padding: '.7em .4em'
  },

  isActive: {
    backgroundPosition: 'left bottom',
    '& > *': {
      color: WHITE
    }
  }
});

const SectionOption = ({ lists, handleClick }: { lists: IList[]; handleClick: (key: number) => void }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.container} container justify='center' alignItems='center' direction='column'>
      {lists.map((list, key) => (
        <Grid
          key={key}
          className={clsx(classes.list, classes.pointer, classes.paddingSm, list.isActive && classes.isActive)}
          onClick={() => handleClick(key)}
        >
          <Typography className={classes.textOrientation} variant='h6'>
            {list.title}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default SectionOption;
