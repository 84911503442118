import React, { FC } from 'react';
import { TableBody, TableHead, TableRow, TableCell, TableContainer } from '@material-ui/core';
import BodyRow from './components/BodyRow';
import { HeaderRow, TableCustom } from 'components/Table';
import { dummyAppLog } from 'utils/dummy';

interface Props {
  isLoadingData: boolean;
  logs: AppLogsModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  indexCollapse: number;
  openCollapse: boolean;
  handleOpenCollapse: (id: number) => React.MouseEventHandler;
}

const AppLogPageTable: FC<Props> = props => {
  const { isLoadingData, logs, order, orderBy, setOrder, setOrderBy, indexCollapse, openCollapse, handleOpenCollapse } = props;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={[
              // { id: 'id', label: '#', sort: true },
              { id: 'createdBy', label: 'Pengguna', sort: true },
              { id: 'description', label: 'Deskripsi', sort: true },
              { id: 'createdAt', label: 'Tanggal', sort: true },
              { label: 'Jam' }
            ]}
          />
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow
                key={index}
                isLoading={isLoadingData}
                appLog={dummyAppLog}
                index={index}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                handleOpenCollapse={handleOpenCollapse(index)}
              />
            ))
          ) : logs && logs.length > 0 ? (
            logs.map((value, index) => (
              <BodyRow
                key={index}
                isLoading={isLoadingData}
                appLog={value}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                index={index}
                handleOpenCollapse={handleOpenCollapse(index)}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align='center'>
                {'Data Tidak Tersedia.'}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default AppLogPageTable;
