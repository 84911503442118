import React, { FC, useEffect, useState, useContext, useRef } from 'react';
import { Theme, Grid, Container, Typography, makeStyles, Button, Tooltip, Badge, CircularProgress, Menu, MenuItem } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import CalendarIcon from '@material-ui/icons/EventNote';
import RefreshIcon from '@material-ui/icons/Refresh';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import useRole from 'hooks/useRole';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Pagination from '@material-ui/lab/Pagination';
import useDebounced from 'hooks/useDebounced';
import { Page, StandardConfirmationDialog, PaperCustom, Breadcrumb } from 'components';
import { INVOICE_BASE_URL, ZONE_BASE_URL, EXPORT_INVOICE_BASE_URL, PARTNER_BASE_URL } from 'constants/url';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import DateRangeFilter from 'components/DateRangeFilter';
import InvoiceTable from './components/InvoiceTable';
import useRouter from 'hooks/useRouter';
import { dummyZone } from 'utils/dummy';
import { GREEN, WHITE, BLUE_PRIMARY, BLUE_SECONDARY } from 'constants/colors';
import { format, startOfMonth } from 'date-fns';
import CardTotal from './components/CardTotal';
import TypeUser from 'typings/enum/TypeUser';
import PrintIcon from '@material-ui/icons/Print';
import GetAppIcon from '@material-ui/icons/GetApp';
import InvoicePrint from './components/InvoicePrint';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import InvoicePrintConfirm from './components/InvoicePrintConfirm';
import PaginationCustom from 'components/PaginationCustom';

const useStyles = makeStyles((theme: Theme) => ({
  spaceTop: {
    paddingBottom: 30
  },
  fourthGrid: {
    marginTop: 20
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  },
  calendarIcon: {
    fontSize: 30,
    color: theme.palette.primary.main
  },
  paper: {
    padding: '20px',
    boxShadow: '0px 5px 24px rgba(2, 169, 234, 0.12)',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  iconCard: {
    fontSize: 48,
    color: BLUE_SECONDARY
  },
  icon: {
    paddingTop: 5,
    marginTop: 5,
    fontSize: 48,
    color: BLUE_SECONDARY
  },
  gridPaper: {
    paddingTop: '24px'
  }
}));

const InvoicePage: FC = () => {
  const classes = useStyles();
  const { history } = useRouter();
  const { currentUser } = useContext(CurrentUserContext);
  const [count, setCount] = useState<number>(0);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [selectedId, setSelectedId] = useState<number>();
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [invoiceId, setInvoiceId] = useState<string>('');
  const [statusPayment, setStatusPayment] = useState<string>('UNPAID');
  const [statusPrint, setStatusPrint] = useState<number>(0);
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);
  const [loadingItem, setLoadingItem] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [from, setFrom] = useState<number>(0);
  const [isRequestEdit, setRequestEdit] = useState<boolean>(false);
  const [totalRequest, setTotalRequest] = useState<number>(0);
  const [loadingPrint, setLoadingPrint] = useState<boolean>(false);
  const [loadingPrintRequest, setLoadingPrintRequest] = useState<boolean>(false);
  const [totalNewEdit, setTotalNewEdit] = useState<number>(0);
  const [invoicePrint, setInvoicePrint] = useState<number>(0);
  const [zones, setZones] = useState<ZoneModel[]>([]);
  const [zoneLoading, setZoneLoading] = useState<boolean>(false);
  const [partners, setPartners] = useState<{ isLoading: boolean; data: Pick<PartnerModel, 'id' | 'name'>[] }>({
    isLoading: true,
    data: []
  });

  const [invoicePrintFilter, setInvoicePrintFilter] = useState<{
    zone: number;
    partner: number[];
  }>({
    zone: 0,
    partner: []
  });
  const [isPrintOptions, setIsPrintOptions] = useState<null | HTMLElement>(null);
  const [isPrintOptionConfirm, setIsPrintOptionConfirm] = useState<boolean>(false);

  const [zone, setZone] = useState<number[]>([]);
  const [salesName, setSalesName] = useState<string>('');
  const [requestPrint, setRequestPrint] = useState<boolean>(false);
  const [totalRequestPrint, setTotalRequestPrint] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const invoicePrintSectionRef = useRef<HTMLDivElement | null>(null);
  const [isInvoiceReportPrint, setIsInvoiceReportPrint] = useState<boolean>(false);
  const [invoiceReportPrint, setInvoiceReportPrint] = useState<InvoiceReportPrint>({
    zoneName: '',
    partners: []
  });

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN]
  });

  const isAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN]
  });

  const isSuperVisor = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERVISOR]
  });

  const isSales = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SALES]
  });

  const salesRoute = currentUser && currentUser.SalesRoute ? currentUser.SalesRoute.map(value => value.ZoneId) : [];
  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

  const getQueryParams = () => {
    const params = new URLSearchParams();

    if (invoiceId) {
      params.append('number', invoiceId);
    }

    if (name) {
      params.append('keyword', name);
    }

    if (statusPayment) {
      params.append('status', statusPayment);
    }

    if (salesName) {
      params.append('sales', salesName);
    }

    if (isRequestEdit) {
      params.append('isRequestEdit', String(isRequestEdit));
      params.append('isEdited', 'true');
    }

    if (requestPrint) {
      params.append('requestPrint', String(requestPrint));
    }

    if (isSales) {
      if (currentUser) {
        params.append('SalesId', currentUser.id.toString());
      }
    }

    if (isSuperVisor) {
      if (currentUser) {
        params.append('route', (currentUser.SalesRoute && currentUser.SalesRoute.map(value => value.ZoneId).join(',')) || '');
      }
    }

    if (zone.length > 0) {
      params.append('Zone', zone.join(','));
    }

    if (!invoiceId && !requestPrint) {
      params.append('startDate', startDate);
      params.append('endDate', endDate);
    }

    params.append('orderBy', orderBy);
    params.append('ordered', order);
    params.append('page', currentPage.toString());
    params.append('printed', statusPrint.toString());
    return params.toString();
  };

  const fetchData = async () => {
    setIsLoadingData(true);
    setIndexCollapse(-1);

    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });
      setInvoices(data.data);
      setCount(data.meta.last_page);
      setTotal(data.meta.total);
      setTo(data.meta.to);
      setFrom(data.meta.from);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const fecthZone = async () => {
    setZoneLoading(true);
    const params = new URLSearchParams();
    params.append('route', salesRoute.join(','));
    params.append('perPage', '1000');
    params.append('orderBy', 'name');
    params.append('ordered', 'asc');
    try {
      const { data } = await axios.get(`${ZONE_BASE_URL}?${params.toString()}`);
      setZones(data.data);
      setZoneLoading(true);
    } catch (error) {
      console.log('error :', error);
    }
  };

  const handleChangeFilter = <T,>(key: string, value: T) => {
    setInvoicePrintFilter(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const handleInvoiceReportPrint = async () => {
    setIsInvoiceReportPrint(true);
    const params = new URLSearchParams();
    params.append('zoneId', invoicePrintFilter.zone.toString());
    params.append('partnerId', invoicePrintFilter.partner.join(','));
    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/report-receivables?${params.toString()}`);
      const tempData = { ...data, partners: data.partners.flat() };

      setInvoiceReportPrint(tempData);
      handleInvoicePrintSection();
      setIsInvoiceReportPrint(false);
    } catch (error) {
      console.log('error :', error);
    }
  };

  const handleInvoicePrintSection = useReactToPrint({
    content: () => invoicePrintSectionRef.current,
    documentTitle: 'Invoice yang belum lunas',
    onAfterPrint: () => {
      setInvoicePrintFilter({ zone: 0, partner: [] });
      setPartners(prev => ({ ...prev, data: [] }));
      setIsPrintOptionConfirm(false);
      handleClosePrintOption();
      setInvoiceReportPrint({
        zoneName: '',
        partners: []
      });
    }
  });

  const fetchPartner = async () => {
    setPartners(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();
    params.append('orderBy', 'id');
    params.append('ZoneId', invoicePrintFilter.zone.toString());
    params.append('partnerType', 'CUSTOMER');

    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/selectPartner?${params}`);
      setPartners(prev => ({ ...prev, isLoading: false, data: data }));
    } catch (error) {
      console.log('error :', error);
    }
  };

  useEffect(() => {
    if (invoicePrintFilter.zone === 0) return;
    fetchPartner();
  }, [invoicePrintFilter.zone]);

  const onRefresh = () => {
    setOrderBy('id');
    setOrder('desc');
    setStatusPayment('');
    setStatusPrint(0);
    setName('');
    setInvoiceId('');
    setTotalRequest(0);
    setTotalNewEdit(0);
    setCurrentPage(1);
  };

  const onRequestEdit = () => {
    setStatusPayment('');
    setRequestEdit(prevState => {
      if (!prevState) {
        setOrderBy(isSuperAdmin ? 'requestAt' : 'updatedAt');
      } else {
        setOrderBy('id');
      }
      return !prevState;
    });
    setOrder('desc');
  };

  const onRequestPrint = () => {
    setRequestPrint(!requestPrint);
  };

  const fetchRequest = async () => {
    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/request`);
      setTotalRequest(data.total);
    } catch (error) {
      console.log('error: ', error);
    } finally {
    }
  };

  const fetchRequestPrint = async () => {
    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/total-request-print`);
      setTotalRequestPrint(data.data.total);
    } catch (error) {
      console.log('error: ', error);
    } finally {
    }
  };

  const fetchNewEdit = async () => {
    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/new-edit`);
      setTotalNewEdit(data.total);
    } catch (error) {
      console.log('error: ', error);
    } finally {
    }
  };

  const handleConfirmationDelete = (id: number): React.MouseEventHandler => () => {
    setSelectedId(id);
    setConfirmationDelete(true);
  };

  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  const deleteInvoice = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      await axios.get(`${INVOICE_BASE_URL}/get-total/status`);
      setInvoices(invoices.filter(value => value.id !== selectedId));
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmationDelete(false);
    }
  };
  const handlePrintRequest = async (id: number) => {
    setInvoicePrint(id);
    setLoadingPrintRequest(true);
    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/request-print/${id}`);
      const tempData = invoices.map(val => (val.id === id ? { ...val, requestPrint: true } : val));
      setInvoices(tempData);
      setLoadingPrintRequest(false);
    } catch (error) {
      console.log('error', error);
    }
  };
  const handlePrint = async (id: number) => {
    setInvoicePrint(id);
    setLoadingPrint(true);

    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/pdf/${id}`, { responseType: 'blob' });

      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      setLoadingPrint(false);

      const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
      if (newwindow) {
        newwindow.focus();
      }
      history.go(0);
    } catch (error) {
      console.log('error :', error);
      setLoadingPrint(false);
    }
  };

  const getData = async (id: number) => {
    setLoadingItem(true);
    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/${id}`);
      setInvoices(prevState =>
        prevState.map(value => {
          if (value.id === id) {
            value.InvoiceItem = data.data.InvoiceItem;
          }
          return value;
        })
      );
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoadingItem(false);
    }
  };

  const handleClosePrintOption = () => {
    setIsPrintOptions(null);
  };

  const handleOpenPrintOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsPrintOptions(event.currentTarget);
  };
  const handlePrintOptionConfirm = () => {
    handleClosePrintOption();
    setIsPrintOptionConfirm(true);
  };

  const handleOpenCollapse = (index: number, id: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    setOpenCollapse(openCollapse ? (index === indexCollapse ? false : true) : true);
    getData(id);
  };

  const handleCalendarFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenCalendarFilter(!openCalendarFilter);
  };

  const downloadExcel = async () => {
    setLoadingExport(true);

    try {
      const { data } = await axios.get(`${EXPORT_INVOICE_BASE_URL}?${getQueryParams()}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `Invoice-Penjualan-${format(new Date(), 'dd/MM/yyyy-HH:mm:ss')}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadingExport(false);
    }
  };

  const debouncedFetchHits = useDebounced(fetchData, 500);

  useEffect(() => {
    debouncedFetchHits();

    return () => cancelTokenSource.cancel('No longer latest query');
  }, [
    orderBy,
    order,
    currentPage,
    startDate,
    endDate,
    name,
    invoiceId,
    statusPayment,
    isSuperVisor,
    isRequestEdit,
    zone,
    isSales,
    statusPrint,
    salesName,
    requestPrint
  ]);

  useEffect(() => {
    fetchRequest();
    fetchNewEdit();
    fetchRequestPrint();
  }, [isSuperAdmin, isAdmin]);

  useEffect(() => {
    fecthZone();
  }, []);

  const getZone = () => {
    const list = invoices.map(val => val.zoneName);
    const unique = list.filter((value, index) => list.indexOf(value) === index);
    return unique;
  };

  return (
    <Page title='Invoice'>
      <Container>
        <Grid container direction='row' spacing={2}>
          <Grid item lg={2} md={12} sm={12} xs={12}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant='h1' component='h1'>
                Penjualan
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Breadcrumb />
            </Grid>
          </Grid>
          {(isSuperVisor || isSuperAdmin) && (
            <Grid item lg={10} md={12} sm={12} xs={12}>
              <CardTotal
                loading={isLoadingData}
                invoiceTotal={invoices.length}
                tagihanTotal={invoices.length > 0 ? invoices.map(val => val.totalPrice || 0).reduce((prev, curr) => prev + curr) : 0}
                rute={getZone().join(', ') || '-'}
                status={statusPayment === 'PAID' ? 'Lunas' : statusPayment === 'UNPAID' ? 'Belum Lunas' : statusPayment === 'CANCEL' ? 'Batal' : '-'}
              />
            </Grid>
          )}
        </Grid>

        <PaperCustom>
          <Grid container direction='row' spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={6} xs={6}>
              <Tooltip title='Memuat Ulang'>
                <Button onClick={onRefresh} color='inherit' className={classes.refresh}>
                  <RefreshIcon />
                </Button>
              </Tooltip>
            </Grid>

            <Grid container alignItems='center' item xl={12} lg={8} md={12} sm={12} xs={12}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography>{`Menampilkan ${total} ${isRequestEdit ? 'Permintaan Perubahan Invoice' : 'Invoice'} (${from || 0} - ${to ||
                  0} dari ${total || 0})`}</Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                {startDate && endDate && (
                  <Typography variant='body2' style={{ fontSize: 12, color: BLUE_PRIMARY }}>
                    Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid container alignItems='center' justify='flex-end' item lg={12} md={12} sm={12} xs={12}>
              <Tooltip title='Calendar filter' placement='top'>
                <Button color='inherit' onClick={event => handleCalendarFilterClick(event)}>
                  <CalendarIcon />
                </Button>
              </Tooltip>
              &nbsp; &nbsp;
              <Tooltip title='Download options' placement='top'>
                <Button color='default' aria-controls='simple-menu' onClick={handleOpenPrintOption} disabled={isLoadingData || loadingExport}>
                  {loadingExport ? <CircularProgress color='inherit' size={20} /> : <GetAppIcon />}
                </Button>
              </Tooltip>
              <Menu id='simple-menu' anchorEl={isPrintOptions} keepMounted open={Boolean(isPrintOptions)} onClose={handleClosePrintOption}>
                <MenuItem onClick={downloadExcel}>Cetak Daftar Invoice</MenuItem>
                <MenuItem onClick={handlePrintOptionConfirm}>Cetak Laporan Sisa Piutang</MenuItem>
              </Menu>
              &nbsp; &nbsp;
              {(isSuperAdmin || isAdmin) && (
                <Tooltip title={isRequestEdit ? 'Kembali' : isSuperAdmin ? 'Pemintaan Perbaharui Invoice' : 'Invoice Telah diperbaharui.'}>
                  <Badge badgeContent={isSuperAdmin ? totalRequest : isAdmin ? totalNewEdit : 0} color='primary'>
                    <Button color={isRequestEdit ? 'primary' : 'default'} onClick={onRequestEdit}>
                      {isRequestEdit ? <ArrowBackIcon /> : <BorderColorIcon />}
                    </Button>
                  </Badge>
                </Tooltip>
              )}
              &nbsp; &nbsp;
              {(isSuperAdmin || isAdmin) && (
                <Tooltip title={'Izin Cetak'}>
                  <Badge badgeContent={totalRequestPrint} color='primary'>
                    <Button color={requestPrint ? 'primary' : 'default'} onClick={onRequestPrint} disabled={isLoadingData}>
                      {loadingExport ? <CircularProgress color='inherit' size={20} /> : requestPrint ? <ArrowBackIcon /> : <PrintIcon />}
                    </Button>
                  </Badge>
                </Tooltip>
              )}
            </Grid>
            <Grid xs={12}>
              <div style={{ display: 'none' }}>
                <section ref={invoicePrintSectionRef}>
                  <InvoicePrint invoiceReportPrint={invoiceReportPrint} />
                </section>
              </div>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <InvoiceTable
                isRequestEdit={isRequestEdit}
                isSuperAdmin={isSuperAdmin}
                isAdmin={isAdmin}
                statusPayment={statusPayment}
                statusPrint={statusPrint}
                invoiceId={invoiceId}
                name={name}
                isLoadingData={isLoadingData}
                count={count}
                loadingPrint={loadingPrint}
                invoicePrint={invoicePrint}
                currentPage={currentPage}
                invoices={invoices}
                handlePrint={handlePrint}
                order={order}
                handlePrintRequest={handlePrintRequest}
                loadingPrintRequest={loadingPrintRequest}
                orderBy={orderBy}
                openCollapse={openCollapse}
                indexCollapse={indexCollapse}
                setName={setName}
                setInvoiceId={setInvoiceId}
                setStatusPayment={setStatusPayment}
                setStatusPrint={setStatusPrint}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                handleConfirmationDelete={handleConfirmationDelete}
                handleOpenCollapse={handleOpenCollapse}
                loadingItem={loadingItem}
                zone={zone}
                setZone={setZone}
                zones={zones}
                salesName={salesName}
                setSalesName={setSalesName}
              />
            </Grid>

            <Grid container item sm={12} xs={12} justify='flex-end'>
              <PaginationCustom
                marginTop='-.4em '
                show={invoices.length > 0}
                sxPagination={{
                  count,
                  boundaryCount: 2,
                  variant: 'outlined',
                  shape: 'rounded',
                  onChange: (event, page) => {
                    setCurrentPage(page);
                  },
                  page: currentPage
                }}
                sxPopover={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  }
                }}
                customPageProps={{
                  defaultValue: currentPage,
                  maxValue: count,
                  onSubmit(value) {
                    setCurrentPage(value);
                  }
                }}
              />
            </Grid>
          </Grid>
        </PaperCustom>

        <DateRangeFilter
          openCalendarFilter={openCalendarFilter}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleClose={() => {
            setOpenCalendarFilter(false);
          }}
        />

        <InvoicePrintConfirm
          isOpen={isPrintOptionConfirm}
          handleClose={() => setIsPrintOptionConfirm(false)}
          invoicePrintFilter={invoicePrintFilter}
          zone={{ isLoading: zoneLoading, data: zones }}
          partner={partners}
          handleChange={handleChangeFilter}
          handleAction={{ isLoading: isInvoiceReportPrint, submit: handleInvoiceReportPrint }}
        />

        <StandardConfirmationDialog
          variant={'danger'}
          titleMessage={'Hapus'}
          message={'Apakah kamu yakin menghapus data ini?'}
          open={confirmationDelete}
          handleClose={handleCloseConfirmationDelete}
          onConfirm={deleteInvoice}
        />
      </Container>
    </Page>
  );
};

export default InvoicePage;
