import React, { FC, useState, useEffect, lazy, Suspense, useCallback } from 'react';
import { Grid, makeStyles, Typography, Theme } from '@material-ui/core';
import axios from 'axios';
import { PaperCustom } from 'components';
import FlexBox from 'components/FlexBox';
import { PRICE_HISTORY_BASE_URL, PRODUCT_SEARCH } from 'constants/url';
import useDebounce from 'hooks/useDebounce';
import { dummyMetaData } from 'utils/dummy';
import { PriceHistoryFilters, PriceHistoryHeader } from './components';
import PaginationCustom from 'components/PaginationCustom';
import { format } from 'date-fns';
import monthNames from 'utils/month';
import { GREY } from 'constants/colors';
const PriceHistoryContent = lazy(() => import('./components/PriceHistoryContent'));
export type TListExpand = {
  id: number | string;
  productId: Number;
  action: 'year' | 'month';
};

export type TFilters = {
  keyWord: string;
  productId: number;
  productName: string;
  id: number;
  startDate: string;
  endDate: string;
};

export type TData<T> = {
  isLoading: boolean;
  data: T;
};

interface Props {
  partnerId: number;
}

/* Defining a type called TFilters and then creating a constant called dummyFilters that is of type
TFilters. */
const currentYear = new Date().getFullYear();
const startOfYear = new Date(currentYear, 0, 1);
const today = new Date();
export const dummyFilters: TFilters = {
  productName: '',
  productId: 0,
  keyWord: '',
  id: 0,
  startDate: format(startOfYear, 'yyyy-MM'),
  endDate: format(today, 'yyyy-MM')
};

const dummyData = {
  isLoading: true,
  data: []
};

const dummySummary: stockMovementSummary = {
  totalStockFirstStart: 0,
  totalStockDamageStart: 0,
  totalStockSalesStart: 0,
  totalStockEnd: 0,
  totalStockDamageEnd: 0,
  totalStockSalesEnd: 0
};

const useStyles = makeStyles((theme: Theme) => ({
  borderTop: {
    borderTop: `1px solid ${GREY}`
  },
  minHight: {
    minHeight: '25em'
  },
  logo: {
    width: '400px',
    height: '337px',
    marginTop: '2em'
  }
}));

const PriceHistoryTable: FC<Props> = props => {
  const partnerId = props.partnerId;
  const classes = useStyles();
  const [filters, setFilters] = useState<TFilters>(dummyFilters);
  const [productMonths, setProductMonths] = useState<TData<StockMovementProductMonth[]>>(dummyData);
  const [year, setYear] = useState<TData<PriceHistory[]>>(dummyData);
  const [month, setMonth] = useState<TData<PaymentHistoryMonth[]>>(dummyData);
  const [productMeta, setProductMeta] = useState<MetaData>(dummyMetaData);
  const [paymentHistory, setPaymentHistory] = useState<TData<InvoicePaymentModel[]>>(dummyData);
  const [priceHistoryDetail, setPriceHistoryDetail] = useState<TData<PriceHistoryDetail[]>>(dummyData);
  const [stockMovements, setStockMovements] = useState<TData<SalesHistory[]>>(dummyData);
  const [stockMovementSummary, setStockMovementSummary] = useState<TData<stockMovementSummary>>({
    isLoading: dummyData.isLoading,
    data: dummySummary
  });

  const [productExpand, setProductExpand] = useState<{ id: number; isOpen: boolean }>({
    id: 0,
    isOpen: false
  });
  const [monthExpand, setMonthExpand] = useState<{ product: string; month: string; isOpen: boolean }>({
    month: '',
    product: '',
    isOpen: false
  });
  const [yearExpand, setYearExpand] = useState<{ year: number; productId: number; isOpen: boolean }>({
    year: 0,
    productId: 0,
    isOpen: false
  });
  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const currentYear = new Date().getFullYear();
  const startOfYear = new Date(currentYear, 0, 1);
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(startOfYear);
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
  const [productSuggests, setProductSuggests] = useState<{ productName: string; id: number; wareHouse: string }[]>([]);
  const [loadProduct, setLoadProduct] = useState<boolean>(false);
  const [isShowSuggest, setIsShowSuggest] = React.useState(false);

  const fetchYear = async () => {
    setProductMonths(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();

    if (filters.id !== 0) {
      params.append('productId', filters.id.toString());
    }

    if (partnerId !== 0) {
      params.append('partnerId', partnerId.toString());
    } else {
      setProductMonths(prev => ({
        ...prev,
        isLoading: false
      }));
      return;
    }
    params.append('page', productMeta.current_page.toString());

    try {
      const { data } = await axios.get(`${PRICE_HISTORY_BASE_URL}?${params}`, {
        cancelToken
      });
      setYear(prev => ({
        isLoading: false,
        data: data.data
      }));
      setProductMeta(prev => ({ ...prev, ...data.meta }));
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeFilters = <T,>(key: keyof typeof dummyFilters, value: T) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  const fetchMonth = async (productId: string) => {
    setProductMonths(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();

    if (year) {
      params.append('productId', productId);
    }

    if (partnerId !== 0) {
      params.append('partnerId', partnerId.toString());
    } else {
      setProductMonths(prev => ({
        ...prev,
        isLoading: false
      }));
      return;
    }

    try {
      const { data } = await axios.get(`${PRICE_HISTORY_BASE_URL}/date?${params}`, {
        cancelToken
      });
      setMonth(prev => ({
        isLoading: false,
        data: data.data
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDetail = async (date: string, productId: string) => {
    setStockMovements(prev => ({ ...prev, isLoading: true }));

    const params = new URLSearchParams();

    if (year) {
      params.append('productId', productId);
    }

    params.append('date', date);
    if (partnerId !== 0) {
      params.append('partnerId', partnerId.toString());
    } else {
      setPriceHistoryDetail(prev => ({
        ...prev,
        isLoading: false
      }));
      return;
    }
    try {
      const { data } = await axios.get(`${PRICE_HISTORY_BASE_URL}/detail?${params}`, {
        cancelToken
      });
      setPriceHistoryDetail(prev => ({
        ...prev,
        isLoading: false,
        data: data.data
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleListExpand = useCallback(
    ({ id, productId, action }: TListExpand) => {
      if (action === 'month') {
        setMonthExpand(prev => ({
          month: String(id),
          product: String(productId),
          isOpen: prev.month === id ? !prev.isOpen : true
        }));
        const date = +id.toString().slice(5) < 10 ? `${new Date(id.toString()).getFullYear()}-0${id.toString().slice(5)}` : String(id);
        fetchDetail(date, String(productId));
      } else if (action === 'year') {
        setMonthExpand(prev => ({
          month: '',
          product: '',
          isOpen: false
        }));
        setYearExpand(prev => ({
          year: Number(id),
          productId: Number(productId),
          isOpen: prev.year === id ? !prev.isOpen : true
        }));
        const year = String(productId);
        fetchMonth(year);
      }
    },
    [productMonths]
  );

  const handleRefresh = () => {
    setFilters(dummyFilters);
    // fetchProducts();
    setProductMeta(prev => ({ ...prev, current_page: 1 }));
  };
  const handleClearKeyWord = () => {
    handleChangeFilters('keyWord', '');
    handleChangeFilters('id', 0);
    handleChangeFilters('productName', '');
    setProductSuggests([]);
  };
  const handleClickSuggest = (productName: string, id: number) => {
    handleChangeFilters('id', id);
    handleChangeFilters('keyWord', productName);
    handleChangeFilters('productName', productName);
    setProductMeta(prev => ({ ...prev, current_page: 1 }));
    setProductSuggests([]);
    setIsShowSuggest(false);
  };

  const handleSearchPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleChangeFilters('productName', filters.keyWord);
      setProductMeta(prev => ({ ...prev, current_page: 1 }));
      setProductSuggests([]);
    }
  };

  const handleOnClickHeader = {
    handleRefresh
  };

  const fetchProductSuggests = async () => {
    const params = new URLSearchParams();
    params.append('keyword', filters.keyWord);
    setLoadProduct(true);
    try {
      const { data } = await axios.get(`${PRODUCT_SEARCH}?${params}`, {
        cancelToken
      });
      setProductSuggests(data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadProduct(false);
    }
  };
  const productNameDebouce = useDebounce(filters.keyWord, 500);
  useEffect(() => {
    if (productNameDebouce.length < 1 || filters.productName === filters.keyWord) return;
    fetchProductSuggests();
    return () => source.cancel('Cancel Unmounted');
  }, [productNameDebouce]);

  useEffect(() => {
    fetchYear();
    //fetchMonth();
    //fetchProductMonths();
    return () => source.cancel('Cancel Unmounted');
  }, [productMeta.current_page, filters.productName]);

  return (
    <PaperCustom>
      <FlexBox container rowGap={1}>
        <PriceHistoryFilters
          loadProduct={loadProduct}
          isShowSuggest={isShowSuggest}
          filters={filters}
          setIsShowSuggest={setIsShowSuggest}
          handleChangeFilters={handleChangeFilters}
          productSuggests={productSuggests}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedStartDate={setSelectedStartDate}
          setSelectedEndDate={setSelectedEndDate}
          handleClickSuggest={handleClickSuggest}
          handleClearKeyWord={handleClearKeyWord}
          handleSearchPress={handleSearchPress}
        />
        <PriceHistoryHeader titleNode={<Typography variant='h6'>Menampilkan seluruh riwayat harga item</Typography>} onClick={handleOnClickHeader} />
        <Suspense
          fallback={
            <Grid xs={12} container justify='center' alignItems='center'>
              <Typography variant='h6'>Sedang Membuka...</Typography>
            </Grid>
          }
        >
          <PriceHistoryContent
            year={year}
            month={month}
            priceHistoryDetail={priceHistoryDetail}
            handleListExpand={handleListExpand}
            monthExpand={monthExpand}
            yearExpand={yearExpand}
          />
        </Suspense>
        {year.data.length != 0 ? (
          <Grid xs={12} justify='flex-end' container>
            <PaginationCustom
              marginTop='-.4em '
              show={true}
              sxPagination={{
                count: productMeta.last_page,
                boundaryCount: 2,
                variant: 'outlined',
                shape: 'rounded',
                onChange: (event, page) => {
                  setProductMeta(prev => ({ ...prev, current_page: page }));
                },
                page: productMeta.current_page
              }}
              sxPopover={{
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right'
                },
                transformOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right'
                }
              }}
              customPageProps={{
                defaultValue: productMeta.current_page,
                maxValue: productMeta.last_page,
                onSubmit(value) {
                  setProductMeta(prev => ({ ...prev, current_page: value }));
                }
              }}
            />
          </Grid>
        ) : null}
      </FlexBox>
    </PaperCustom>
  );
};

export default PriceHistoryTable;
