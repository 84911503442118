import React, { FC } from 'react';
import {
  makeStyles,
  TextField,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from '@material-ui/core';
import { DialogTitle } from 'components/Dialog';
import { HeaderRow, TableCustom } from 'components/Table';
import { dummyProduct, dummyMutationItem } from 'utils/dummy';
import BodyRowProduct from './BodyRowProduct';
import Pagination from '@material-ui/lab/Pagination';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface Props {
  count: number;
  openProduct: boolean;
  handleClose: () => void;
  queryString: string;
  setQueryString: React.Dispatch<React.SetStateAction<string>>;
  categories: CategoryModel[];
  setCategory: React.Dispatch<React.SetStateAction<CategoryModel>>;
  handleSearchCategory: (value: string) => void;
  order: 'asc' | 'desc';
  orderBy: string;
  handleRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  isLoadingData: boolean;
  checked: MutationItemModel[];
  individualCheck: (item: MutationItemModel) => void;
  products: ProductModel[];
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  handleCancelProduct: () => void;
}

const useStyles = makeStyles({
  dialogContent: {
    margin: '0 auto',
    width: 1031,
    height: 590,
    padding: '24px'
  },
  image: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
});

const StockWizard: FC<Props> = props => {
  const classes = useStyles();
  const {
    count,
    openProduct,
    handleClose,
    queryString,
    setQueryString,
    categories,
    setCategory,
    handleSearchCategory,
    order,
    orderBy,
    handleRequestSort,
    isLoadingData,
    checked,
    individualCheck,
    products,
    setCurrentPage,
    currentPage,
    handleCancelProduct
  } = props;

  return (
    <Dialog open={openProduct} classes={{ paper: classes.dialogContent }} onClose={handleClose} maxWidth={'lg'}>
      <DialogTitle>Pilih Produk</DialogTitle>
      <DialogContent>
        <Grid direction='row' container>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField id='query' label='Cari Barang disini' value={queryString} onChange={event => setQueryString(event.target.value)} />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Autocomplete
              id='category'
              options={categories}
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(event: any, value: any) => {
                if (value) {
                  setCategory(value);
                }
              }}
              onOpen={() => handleSearchCategory('')}
              renderInput={params => <TextField {...params} label='Kategori' onChange={e => handleSearchCategory(e.target.value)} />}
            />
          </Grid>
          <Grid item lg={12}>
            <TableContainer>
              <TableCustom size='small'>
                <TableHead>
                  <HeaderRow
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headers={[
                      { label: '', pR: '10px', pT: '7px', verticalAlign: 'top' },
                      { id: 'productCode', label: 'Kode Produk', sort: true },
                      { id: 'productName', label: 'Nama Produk', sort: true },
                      { id: 'CategoryId', label: 'Kategori', sort: true, align: 'center' },
                      { id: 'typeUnit', label: 'Satuan', sort: true, align: 'center' }
                    ]}
                  />
                </TableHead>
                <TableBody>
                  {isLoadingData ? (
                    [0, 1, 2, 3].map(index => (
                      <BodyRowProduct
                        key={index + 1}
                        index={index}
                        item={dummyMutationItem}
                        product={dummyProduct}
                        isLoading={isLoadingData}
                        checked={checked}
                        handleIndividualCheck={individualCheck}
                      />
                    ))
                  ) : products && products.length > 0 ? (
                    products.map((value, index) => (
                      <BodyRowProduct
                        key={index + 1}
                        index={index}
                        item={checked[index]}
                        product={value}
                        isLoading={isLoadingData}
                        checked={checked}
                        handleIndividualCheck={individualCheck}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align='center'>
                        {queryString ? 'No matching result' : 'Data Tidak Tersedia.'}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </TableCustom>
            </TableContainer>
          </Grid>

          <Grid container justify='center' item xl={12} md={12} sm={12}>
            {products && products.length > 0 && (
              <Pagination count={count} onChange={(event, page) => setCurrentPage(page)} page={currentPage} boundaryCount={2} variant='outlined' />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={handleCancelProduct}>
          Batal
        </Button>
        <Button onClick={handleClose}>Simpan</Button>
      </DialogActions>
    </Dialog>
  );
};

export default StockWizard;
