import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { TableCellMiddle, TableCellEnd, TableCellStart } from 'components/Table/TableCellCustom';
import TableRowCustom from 'components/Table/TableRowCustom';
import { format } from 'date-fns';
import { Tooltip, IconButton } from '@material-ui/core';
import { EditOutlined, DeleteOutline } from '@material-ui/icons';

interface Props {
  transfer: MutationModel;
  isLoading: boolean;
  onDelete: React.MouseEventHandler;
  onUpdate: React.MouseEventHandler;
  onDetail: React.MouseEventHandler;
}

const BodyRow: FC<Props> = props => {
  const { transfer, isLoading, onDelete, onUpdate, onDetail } = props;

  return (
    <TableRowCustom>
      <TableCellStart onClick={onDetail}>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : `#${transfer.mutationNumber}`}
      </TableCellStart>
      <TableCellMiddle align='center' onClick={onDetail}>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : format(new Date(transfer.mutationDate), 'dd/MM/yyyy')}
      </TableCellMiddle>
      <TableCellMiddle align='center' onClick={onDetail}>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : transfer.status}
      </TableCellMiddle>
      <TableCellMiddle align='center' onClick={onDetail}>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : transfer.totalItem}
      </TableCellMiddle>
      <TableCellMiddle align='center' onClick={onDetail}>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : (transfer.Origin && transfer.Origin.name) || '-'}
      </TableCellMiddle>
      <TableCellMiddle align='center' onClick={onDetail}>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : (transfer.Destination && transfer.Destination.name) || '-'}
      </TableCellMiddle>
      <TableCellEnd align='right'>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <>
            {transfer.status !== 'ACCEPTED' && (
              <Tooltip title='Perbaharui Mutasi'>
                <IconButton size='small' onClick={onUpdate}>
                  <EditOutlined fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title='Hapus Mutasi'>
              <IconButton size='small' onClick={onDelete}>
                <DeleteOutline fontSize='small' />
              </IconButton>
            </Tooltip>
          </>
        )}
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRow;
