import React, { FC, useEffect, useState } from 'react';
import { Grid, Container, Typography, Button, TextField, MenuItem, Tabs, Tab, Tooltip } from '@material-ui/core';
import { Page, StandardConfirmationDialog, PaperCustom, Breadcrumb } from 'components';
import { STOCK_CHECK_BASE_URL, WAREHOUSE_BASE_URL, DELETE_STOCK_CHECK_BASE_URL, EXPORT_STOCK_CHECK_BASE_URL, PRODUCT_BASE_URL } from 'constants/url';

import { a11yProps } from 'components';

import axios, { CancelTokenSource } from 'axios';
import StockTable from './components/StockTable';
import Pagination from '@material-ui/lab/Pagination';
import useRouter from 'hooks/useRouter';
import ExportModal from './components/ExportModal';
import { format, startOfMonth } from 'date-fns';
import _ from 'lodash';
import CalendarIcon from '@material-ui/icons/EventNote';
import DateRangeFilter from 'components/DateRangeFilter';

const StockCheckPage: FC = () => {
  const { history } = useRouter();

  let cancelToken: CancelTokenSource = axios.CancelToken.source();

  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [stock, setStock] = useState<StockCheckModel[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [selectedId, setSelectedId] = useState<number>(0);
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);

  const [warehouses, setWarehouses] = useState<WareHouseModel[]>([]);
  const [warehouse, setWarehouse] = useState<number>(0);
  const [warehouseExport, setWarehouseExport] = useState<number>(0);
  const [isFirst, setFirst] = useState<number>(0);
  const [value, setValue] = useState<number>(0);

  const [products, setProducts] = useState<ProductModel[]>([]);
  const [checkedProduct, setCheckedProduct] = useState<number[]>([]);
  const [page, setPage] = useState<number>(1);
  const [productName, setProductName] = useState<string>('');

  const [openExport, setOpenExport] = useState<boolean>(false);
  const [openAdvanced, setOpenAdvanced] = useState<boolean>(false);

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [loadingProduct, setLoadingProduct] = useState<boolean>(false);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<string>(format(new Date(startOfMonth(new Date())), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);

  const [startDates, setStartDates] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDates, setEndDates] = useState<string>(format(new Date(), 'yyyy-MM-dd'));

  const handleChange = ({}, newValue: number) => {
    setValue(newValue);
  };

  const fetchData = async () => {
    setIsLoadingData(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (warehouse > 0) {
        params.append('WareHouseId', warehouse.toString());
      }

      if (value === 0) {
        params.append('startDate', startDates);
        params.append('endDate', endDates);
      }

      params.append('orderBy', orderBy);
      params.append('ordered', order);
      params.append('isFirstStock', (value === 1).toString());
      params.append('page', currentPage.toString());
      return params.toString();
    };

    try {
      const { data } = await axios.get(`${STOCK_CHECK_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelToken.token });
      setStock(data.data);
      setCount(data.meta.last_page);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const deleteStock = async () => {
    try {
      await axios.get(DELETE_STOCK_CHECK_BASE_URL(selectedId));
      setStock(prevState => prevState.filter(value => value.id !== selectedId));
      setSelectedId(0);
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmationDelete(false);
    }
  };

  const download = async () => {
    const params = new URLSearchParams();
    if (warehouseExport > 0) {
      params.append('WareHouseId', warehouseExport.toString());
    }

    if (checkedProduct.length > 0) {
      params.append('ProductId', checkedProduct.join(','));
    }

    params.append('startDate', startDate);
    params.append('endDate', endDate);

    setLoadingExport(true);
    try {
      const { data } = await axios.get(`${EXPORT_STOCK_CHECK_BASE_URL}?${params.toString()}`, {
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;

      const date_ = format(new Date(), 'dd-MM-yyyy');
      link.setAttribute('download', `Stok-Produk-${warehouses.find(value => value.id === warehouseExport)!.name || 'NO_WAREHOUSE'}-${date_}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log('error :', error);
    } finally {
      setOpenExport(false);
      setLoadingExport(false);
      setCheckedProduct([]);
    }
  };

  const fetchProduct = async () => {
    const params = new URLSearchParams();

    if (productName !== '') {
      params.append('keyword', productName);
    }

    if (warehouseExport > 0) {
      params.append('WareHouseId', warehouseExport.toString());
    }

    params.append('page', page.toString());
    setLoadingProduct(true);
    try {
      const { data } = await axios.get(`${PRODUCT_BASE_URL}?${params.toString()}`, { cancelToken: cancelToken.token });
      setProducts(data.data);
      setPage(data.meta.current_page);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadingProduct(false);
    }
  };

  const fethcWareHouse = async () => {
    try {
      const { data } = await axios.get(WAREHOUSE_BASE_URL);
      setWarehouses(data.data);
      if (data.data.length > 0) {
        setWarehouseExport(data.data[0].id);
      }
    } catch (error) {
      console.log('error :', error);
    } finally {
    }
  };

  const handleConfirmationDelete = (id: number): React.MouseEventHandler => () => {
    setSelectedId(id);
    setConfirmationDelete(true);
  };

  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  const handleOpenExport = () => {
    setOpenExport(true);
    setCheckedProduct([]);
    setOpenAdvanced(false);
  };

  const handleCloseExport = () => {
    setOpenExport(false);
  };

  const handleOpenAdvanced = () => {
    setOpenAdvanced(prevState => !prevState);
  };

  const handleCheckedProduct = (id: number) => {
    setCheckedProduct(prevState => {
      if (prevState.some(value => value === id)) {
        return prevState.filter(value => value !== id);
      } else {
        return [...prevState, id];
      }
    });
  };

  const handleOnUpdate = (id: number): React.MouseEventHandler => () => {};

  const handleDateFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenCalendarFilter(!openCalendarFilter);
  };

  useEffect(() => {
    fethcWareHouse();
  }, []);

  useEffect(() => {
    if (!openAdvanced) return;
    setProducts([]);
    const debouncedFetchHits = _.debounce(fetchProduct, 500);
    debouncedFetchHits();
    return () => cancelToken.cancel('No longer latest query');
  }, [productName, openAdvanced, page, warehouseExport]);

  useEffect(() => {
    const debouncedFetchHits = _.debounce(fetchData, 500);
    debouncedFetchHits();
    return () => cancelToken.cancel('No longer latest query');
  }, [orderBy, order, currentPage, warehouse, value, startDates, endDates]);

  return (
    <Page title='Stok Masuk'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Pengecekan Stok </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid container direction='row' spacing={2}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} container justify='flex-start' alignContent='center' alignItems='center'>
              {startDate && endDate && (
                <Typography>
                  Data tanggal <b> {format(new Date(startDates), 'dd-MM-yyyy')} </b> s/d <b>{format(new Date(endDates), 'dd-MM-yyyy')} </b>
                </Typography>
              )}
            </Grid>

            <Grid container justify='flex-end' alignContent='center' alignItems='center' spacing={1} item xl={8} lg={8} md={8} sm={12} xs={12}>
              <Grid item>
                <TextField id='warehouse' fullWidth value={warehouse} onChange={e => setWarehouse(+e.target.value)} select>
                  <MenuItem key={''} value={0}>
                    Pilih Gudang
                  </MenuItem>
                  {warehouses.length > 0 &&
                    warehouses.map((value, index) => (
                      <MenuItem key={index} value={value.id}>
                        {value.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>

              <Grid item>
                <Tooltip title='Calendar filter' placement='top'>
                  <Button color='inherit' onClick={event => handleDateFilter(event)}>
                    <CalendarIcon />
                  </Button>
                </Tooltip>
              </Grid>

              <Grid item>
                <Button color='default' onClick={handleOpenExport} disabled={isLoadingData}>
                  Export
                </Button>
              </Grid>

              <Grid item>
                <Button onClick={() => history.push('/cek')} disabled={isLoadingData}>
                  Mulai Cek
                </Button>
              </Grid>
            </Grid>

            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Tabs value={value} indicatorColor='primary' onChange={handleChange} aria-label='disabled tabs example'>
                <Tab label='Stok Cek' {...a11yProps(0)} style={{ minWidth: 100 }} />
                <Tab label='Stok Awal' {...a11yProps(1)} style={{ minWidth: 100 }} />
              </Tabs>
            </Grid>
          </Grid>

          <Grid container direction='row'>
            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <StockTable
                isLoadingData={isLoadingData}
                stock={stock}
                order={order}
                orderBy={orderBy}
                isFirstStock={value > 0}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                handleConfirmationDelete={handleConfirmationDelete}
                handleOnUpdate={handleOnUpdate}
              />
            </Grid>

            <Grid container justify='center' item xl={12} md={12} sm={12}>
              {stock && stock.length > 0 && (
                <Pagination count={count} onChange={(event, page) => setCurrentPage(page)} page={currentPage} boundaryCount={2} variant='outlined' />
              )}
            </Grid>
          </Grid>
        </PaperCustom>

        <StandardConfirmationDialog
          variant={'danger'}
          titleMessage={'Hapus'}
          message={'Apakah kamu yakin menghapus data ini?'}
          open={confirmationDelete}
          handleClose={handleCloseConfirmationDelete}
          onConfirm={deleteStock}
        />

        <DateRangeFilter
          openCalendarFilter={openCalendarFilter}
          startDate={startDates}
          endDate={endDates}
          setStartDate={setStartDates}
          setEndDate={setEndDates}
          handleClose={() => {
            setOpenCalendarFilter(false);
          }}
        />

        <ExportModal
          openExport={openExport}
          openAdvanced={openAdvanced}
          warehouse={warehouseExport}
          warehouses={warehouses}
          products={products}
          productName={productName}
          checkedProduct={checkedProduct}
          loadingproduct={loadingProduct}
          loadingExport={loadingExport}
          setProductName={setProductName}
          isFirst={isFirst}
          setFirst={setFirst}
          startDate={startDate}
          endDate={endDate}
          minDate={''}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setWarehouse={setWarehouseExport}
          handleClose={handleCloseExport}
          handleOpenAdvanced={handleOpenAdvanced}
          handleCloseAdvanced={handleOpenAdvanced}
          handleExport={download}
          handleCheckedProduct={handleCheckedProduct}
        />
      </Container>
    </Page>
  );
};

export default StockCheckPage;
