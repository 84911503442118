import React, { FC } from 'react';
import { TableBody, TableHead, TableRow, TableCell, TableContainer } from '@material-ui/core';
import { TableCustom } from 'components/Table';
import BodyRow from './components/BodyRow';

interface Props {
  isLoadingData: boolean;
  purchaseOrderItem: PurchaseOrderItemModel[];
}

const ListProduct: FC<Props> = props => {
  const { isLoadingData, purchaseOrderItem } = props;

  return (
    <TableContainer>
      <TableCustom size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Nama Produk</TableCell>
            <TableCell>QTY</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {purchaseOrderItem.length > 0 &&
            purchaseOrderItem.map((value, index) => <BodyRow key={index} isLoading={isLoadingData} purchaseOrderItem={value} />)}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default ListProduct;
