import React, { FC, useState, useEffect } from 'react';
import { format } from 'date-fns';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
  CircularProgress
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { orange } from '@material-ui/core/colors';
import { blue } from '@material-ui/core/colors';
import { BLUE_PRIMARY, WHITE } from 'constants/colors';
import { PURCHASE_PAYMENT_BASE_URL } from 'constants/url';
import { dummyInvoicePayment } from 'utils/dummy';
import PaymentMethod from 'typings/enum/PaymentMethod';
import ReturnList from './components/ReturnList';
import PieceList from './components/PieceList';
import PaymentList from './components/PaymentList';

interface Props {
  isLoading?: boolean;
  open: boolean;
  editedPaymentId?: number;
  paymentMethod: string;
  partnerName: string;
  note: string;
  totalPay: number;
  totalPrice: number;
  totalReturn: number;
  payDate: Date;
  transferDate: Date;
  bankName: string;
  bankId: number;
  useReturn: boolean;
  giroBank?: string;
  giroDueDate?: Date;
  giroAmount?: number;
  giroNumber?: string;
  invoice: InvoiceModel[];
  invoiceReturn?: InvoiceReturnModel[];
  handleClose(): void;
  handleConfirm: React.MouseEventHandler<HTMLButtonElement>;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    width: 1031,
    height: 660,
    margin: '0 auto',
    padding: '24px'
  },
  warnigAvatarIcon: {
    fontSize: 60,
    color: orange[500]
  },
  box: {
    backgroundColor: WHITE,
    borderRadius: '5px',
    padding: 24,
    border: '1px solid #F2F2F2',
    boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.08)'
  },
  boxTitle: {
    marginBottom: 8,
    color: '#828282'
  },
  returnTitle: {
    color: BLUE_PRIMARY
  },
  cancelButton: {
    marginRight: 3,
    color: '#FFFF'
  },
  okButton: {
    backgroundColor: '#02A9EA',
    '&:hover': {
      backgroundColor: blue[700]
    }
  },
  dialogActions: {
    marginBottom: theme.spacing(2)
  },
  titleText: {
    paddingBottom: 2
  },
  messageText: {
    paddingBottom: theme.spacing(4)
  },
  iconGrid: {
    marginTop: 4
  }
}));

const ConfirmPaymentModal: FC<Props> = props => {
  const classes = useStyles(props);
  const { open, isLoading, handleClose, handleConfirm, invoice, invoiceReturn, editedPaymentId } = props;
  const {
    partnerName,
    paymentMethod,
    payDate,
    note,
    totalPrice,
    totalReturn,
    totalPay,
    bankName,
    bankId,
    useReturn,
    transferDate,
    giroBank,
    giroDueDate,
    giroAmount,
    giroNumber
  } = props;

  const [invoicePayment, setInvoicePayment] = useState<InvoicePaymentModel>(dummyInvoicePayment);
  const [allPayments, setAllPayments] = useState<InvoicePaymentModel[]>([]);
  const [loadingPayment, setLoadingPayment] = useState<boolean>(false);

  const fetchInvoicePayment = async () => {
    setLoadingPayment(true);
    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (editedPaymentId) {
        params.append('exceptId', String(editedPaymentId));
      }

      //backend
      params.append('InvoiceId', invoice.map(val => val.id).toString());
      return params.toString();
    };

    try {
      const { data } = await axios.get(`${PURCHASE_PAYMENT_BASE_URL}?${getQueryParams()}`);
      setAllPayments(data.data);
    } catch (e) {
      console.log('error', e);
      setLoadingPayment(false);
    } finally {
      setLoadingPayment(false);
    }
  };

  useEffect(() => {
    if (!open) {
      return;
    }

    const payment: InvoicePaymentModel = {
      id: 0,
      paymentNumber: '',
      InvoiceId: 0,
      PaymentId: 0,
      ReturnId: 0,
      useReturn,
      InvoicePiece: [],
      payDate,
      totalPrice,
      totalReturn,
      totalPay,
      paymentMethod,
      note,
      BankId: bankId,
      Invoice: invoice,
      invoiceNumber: '',
      partnerName,
      giroBank,
      giroDueDate,
      InvoicePaymentMultiple: [],
      giroAmount: String(giroAmount),
      giroNumber,
      SalesId: null
    };

    setInvoicePayment(payment);
    fetchInvoicePayment();
  }, [open]);

  return (
    <Dialog open={open} maxWidth={false} classes={{ paper: classes.dialogContent }}>
      <DialogTitle>
        <Typography variant='h5' component='p'>
          Ringkasan Pembayaran
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.messageText}>
        <Grid container justify='space-between'>
          <Grid container item lg={6} sm={12} md={6} xs={12}>
            <Grid container item lg={12} sm={12} md={12} xs={12}>
              <Grid item lg={6} sm={12} md={6} xs={12}>
                <Typography variant='h6' className={classes.boxTitle}>
                  NAMA SUPPLIER
                </Typography>
              </Grid>
              <Grid item lg={6} sm={12} md={6} xs={12}>
                <Typography variant='body1'>{partnerName}</Typography>
              </Grid>
            </Grid>

            <Grid container item lg={12} sm={12} md={12} xs={12}>
              <Grid item lg={6} sm={12} md={6} xs={12}>
                <Typography variant='h6' className={classes.boxTitle}>
                  INVOICE RUJUKAN
                </Typography>
              </Grid>
              <Grid item lg={6} sm={12} md={6} xs={12}>
                {invoice.map((invoice, key) => (
                  <Typography variant='body1' key={key}>
                    {invoice.invoiceNumber}
                  </Typography>
                ))}
              </Grid>
            </Grid>

            <Grid container item lg={12} sm={12} md={12} xs={12}>
              <Grid item lg={6} sm={12} md={6} xs={12}>
                <Typography variant='h6' className={classes.boxTitle}>
                  METODE PEMBAYARAN
                </Typography>
              </Grid>
              <Grid item lg={6} sm={12} md={6} xs={12}>
                <Typography variant='body1'>
                  {invoicePayment.paymentMethod}
                  {(invoicePayment.paymentMethod !== PaymentMethod.RETURN && invoicePayment.useReturn && ' + RETURN') || ''}
                </Typography>
              </Grid>
            </Grid>
            {invoicePayment.paymentMethod === PaymentMethod.BANK_TRANSFER && (
              <Grid container item lg={12} sm={12} md={12} xs={12}>
                <Grid item lg={6} sm={12} md={6} xs={12}>
                  <Typography variant='h6' className={classes.boxTitle}>
                    TANGGAL TRANSFER
                  </Typography>
                </Grid>
                <Grid item lg={6} sm={12} md={6} xs={12}>
                  <Typography variant='body1'>{format(new Date(transferDate), 'dd/MM/yyyy')} </Typography>
                </Grid>
              </Grid>
            )}

            <Grid container item lg={12} sm={12} md={12} xs={12}>
              <Grid item lg={6} sm={12} md={6} xs={12}>
                <Typography variant='h6' className={classes.boxTitle}>
                  TANGGAL PEMBAYARAN
                </Typography>
              </Grid>
              <Grid item lg={6} sm={12} md={6} xs={12}>
                <Typography variant='body1'>{format(new Date(invoicePayment.payDate), 'dd/MM/yyyy')} </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} sm={12} md={6} xs={12}>
            {invoicePayment.paymentMethod === PaymentMethod.BANK_TRANSFER && (
              <Grid container item lg={12} sm={12} md={12} xs={12}>
                <Grid item lg={6} sm={12} md={6} xs={12}>
                  <Typography variant='h6' className={classes.boxTitle}>
                    BANK
                  </Typography>
                </Grid>
                <Grid item lg={6} sm={12} md={6} xs={12}>
                  <Typography variant='body1'>{bankName}</Typography>
                </Grid>
              </Grid>
            )}

            {invoicePayment.paymentMethod === PaymentMethod.GIRO && (
              <Grid container>
                <Grid container item lg={12} sm={12} md={12} xs={12}>
                  <Grid item lg={6} sm={12} md={6} xs={12}>
                    <Typography variant='h6' className={classes.boxTitle}>
                      NO. GIRO
                    </Typography>
                  </Grid>
                  <Grid item lg={6} sm={12} md={6} xs={12}>
                    <Typography variant='body1'>{giroNumber}</Typography>
                  </Grid>
                </Grid>

                <Grid container item lg={12} sm={12} md={12} xs={12}>
                  <Grid item lg={6} sm={12} md={6} xs={12}>
                    <Typography variant='h6' className={classes.boxTitle}>
                      NOMINAL GIRO
                    </Typography>
                  </Grid>
                  <Grid item lg={6} sm={12} md={6} xs={12}>
                    <Typography variant='body1'>
                      <NumberFormat value={giroAmount} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item lg={12} sm={12} md={12} xs={12}>
                  <Grid item lg={6} sm={12} md={6} xs={12}>
                    <Typography variant='h6' className={classes.boxTitle}>
                      TGL JATUH TEMPO GIRO
                    </Typography>
                  </Grid>
                  <Grid item lg={6} sm={12} md={6} xs={12}>
                    <Typography variant='body1'>{giroDueDate && format(new Date(giroDueDate), 'dd/MM/yyyy')} </Typography>
                  </Grid>
                </Grid>

                <Grid container item lg={12} sm={12} md={12} xs={12}>
                  <Grid item lg={6} sm={12} md={6} xs={12}>
                    <Typography variant='h6' className={classes.boxTitle}>
                      BANK PENERBIT
                    </Typography>
                  </Grid>
                  <Grid item lg={6} sm={12} md={6} xs={12}>
                    <Typography variant='body1'>{giroBank}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid container item lg={6} sm={12} md={6} xs={12}>
            &nbsp;
          </Grid>
          <Grid container item lg={6} sm={12} md={6} xs={12}>
            {/* <Grid item lg={6} sm={12} md={6} xs={12}>
              <Typography variant='h6'>TOTAL TAGIHAN</Typography>
            </Grid>
            <Grid item lg={6} sm={12} md={6} xs={12}>
              {invoice.map((invoice, key) => (
                <Typography variant='h6' key={key}>
                  <NumberFormat value={invoice.originalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </Typography>
              ))}
            </Grid> */}
          </Grid>
        </Grid>

        <Grid item container direction='row' justify='space-between' spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h6' className={classes.returnTitle}>
              RETURN
            </Typography>
            <Divider />
          </Grid>
          <Grid container item>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Typography variant='h6'>Nomor Return</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Typography variant='h6'>Jumlah Barang</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Typography variant='h6'>Total Return</Typography>
              </Grid>
            </Grid>

            {invoiceReturn &&
              invoiceReturn.map(rtn => {
                return <ReturnList returnNumber={rtn.returnNumber} totalItem={rtn.totalItem} totalPrice={rtn.totalPrice} />;
              })}

            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <Typography variant='h6'>&nbsp;</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Typography variant='h6'>
                  <NumberFormat value={totalReturn} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container direction='row' justify='space-between' spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h6'>Potongan Lainnya</Typography>
            <Divider />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Typography variant='h6'>Jenis Potongan</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Typography variant='h6'>Jumlah Potongan</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Typography variant='h6'>Catatan</Typography>
              </Grid>
            </Grid>

            {invoice.map(
              val =>
                val.InvoicePiece &&
                val.InvoicePiece.map(piece => {
                  return <PieceList type={piece.type} price={piece.price} note={piece.note} />;
                })
            )}

            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Typography variant='h6'>&nbsp;</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Typography variant='h6'>
                  <NumberFormat
                    value={invoice.reduce((total, item) => total + item.totalPiece, 0)}
                    prefix={'Rp'}
                    thousandSeparator={true}
                    displayType='text'
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Divider />
          </Grid>
        </Grid>

        <Grid item container direction='row' justify='space-between' spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography variant='h6'>CATATAN :</Typography>
            <Typography variant='body1'>{note}</Typography>
          </Grid>
          <Grid container item lg={6} md={6} sm={12} xs={12}>
            <Grid container item lg={12} sm={12} md={12} xs={12}>
              <Grid item lg={6} sm={12} md={6} xs={12}>
                <Typography variant='h6' align='right'>
                  SUB TOTAL
                </Typography>
              </Grid>
              <Grid item lg={6} sm={12} md={6} xs={12}>
                <Typography variant='body1' align='right'>
                  <NumberFormat
                    value={invoice.reduce((total, item) => total + item.totalPrice, 0)}
                    prefix={'Rp'}
                    thousandSeparator={true}
                    displayType='text'
                  />
                </Typography>
              </Grid>
            </Grid>

            {/* <Grid container item lg={12} sm={12} md={12} xs={12}>
              <Grid item lg={6} sm={12} md={6} xs={12}>
                <Typography variant='h6' align='right'>
                  POTONGAN
                </Typography>
              </Grid>
              <Grid item lg={6} sm={12} md={6} xs={12}>
                <Typography variant='body1' align='right'>
                  <NumberFormat
                    value={totalReturn + invoice.reduce((total, item) => total + item.totalPiece, 0)}
                    prefix={'(Rp'}
                    suffix={')'}
                    displayType='text'
                    thousandSeparator={true}
                  />
                </Typography>
              </Grid>
            </Grid> */}

            <Grid container item lg={12} sm={12} md={12} xs={12}>
              {loadingPayment ? (
                <Skeleton variant='text' width={'100%'} height={25} />
              ) : (
                allPayments &&
                allPayments.length > 0 &&
                allPayments.map(payment => {
                  return (
                    <PaymentList
                      id={payment.id}
                      paymentNumber={payment.paymentNumber}
                      totalPay={payment.totalPay + (payment.totalReturn ? payment.totalReturn : 0)}
                    />
                  );
                })
              )}
            </Grid>

            <Grid container item lg={12} sm={12} md={12} xs={12}>
              <Grid item lg={6} sm={12} md={6} xs={12}>
                <Typography variant='h6' align='right'>
                  TOTAL
                </Typography>
              </Grid>
              <Grid item lg={6} sm={12} md={6} xs={12}>
                <Typography variant='body1' align='right'>
                  <NumberFormat value={totalPrice - totalReturn} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </Typography>
              </Grid>
            </Grid>

            <Grid container item lg={12} sm={12} md={12} xs={12}>
              <Grid item lg={6} sm={12} md={6} xs={12}>
                <Typography variant='h6' align='right'>
                  TOTAL BAYAR
                </Typography>
              </Grid>
              <Grid item lg={6} sm={12} md={6} xs={12}>
                <Typography variant='h6' align='right'>
                  <NumberFormat value={totalPay} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Grid container justify='center' alignItems='center'>
          <Button
            disableElevation
            variant='contained'
            color={'secondary'}
            disabled={isLoading}
            className={classes.cancelButton}
            onClick={handleClose}
          >
            Batal
          </Button>
          <Button disableElevation variant='contained' disabled={isLoading} className={classes.okButton} onClick={handleConfirm}>
            {isLoading ? <CircularProgress size={20} color='inherit' /> : 'Simpan'}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmPaymentModal;
