import {
  Button,
  Chip,
  ChipTypeMap,
  Divider,
  Grid,
  IconButton,
  Popover,
  PopoverProps,
  TextField,
  Theme,
  Typography,
  makeStyles
} from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { Add, ExpandMore, Remove } from '@material-ui/icons';
import { Pagination, PaginationProps } from '@material-ui/lab';
import { GREY, PRIMARY_MOBILE, WHITE } from 'constants/colors';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    width: '5em'
  },
  buttonAction: {
    background: PRIMARY_MOBILE,
    '&.Mui-disabled': {
      background: GREY
    },
    '&:hover': {
      background: '#008ABF'
    }
  }
}));
interface PaginationCustom {
  show: boolean;
  marginTop?: string;
  sxPagination?: PaginationProps;
  sxPopover?: Pick<PopoverProps, 'anchorOrigin' | 'transformOrigin'>;
  sxChip?: OverridableComponent<ChipTypeMap<{}, 'div'>>;
  customPageProps?: {
    defaultValue: number;
    onSubmit: (value: number) => void;
    maxValue: number;
  };
}
const PaginationCustom = (props: PaginationCustom) => {
  const { sxPagination, sxChip, show, customPageProps, sxPopover, marginTop } = props;
  const [value, setValue] = useState(customPageProps?.defaultValue ?? 0);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!customPageProps?.defaultValue) return;
    setValue(customPageProps?.defaultValue);
  }, [customPageProps?.defaultValue]);

  if (!show) return null;

  const handleSubmit = () => {
    setIsOpen(false);
    customPageProps?.onSubmit(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setIsOpen(false);
      customPageProps?.onSubmit(value);
    }
  };

  const decrementValue = () => {
    setValue(prev => {
      return prev - 1;
    });
  };

  const increaseValue = () => {
    setValue(prev => {
      return prev + 1;
    });
  };
  return (
    <div
      style={{
        display: 'flex',
        columnGap: '.2em'
      }}
    >
      <Pagination {...sxPagination} />
      <Chip
        {...sxChip}
        id='custom-page'
        variant='outlined'
        label={
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Typography>Ke Halaman</Typography>
            <ExpandMore />
          </div>
        }
        clickable
        onClick={event => {
          setIsOpen(true);
          setAnchorEl(event.currentTarget);
        }}
        size='medium'
        style={{
          borderRadius: '4px',
          fontSize: '.95em',
          padding: '.2em 0'
        }}
      />

      <Popover
        {...sxPopover}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => setIsOpen(false)}
        style={{
          marginTop: marginTop ?? '.4em'
        }}
      >
        <Grid
          container
          direction='column'
          style={{
            rowGap: '.6em',
            borderTop: `2px solid ${PRIMARY_MOBILE}`,
            padding: '.6em .7em'
          }}
        >
          <Typography variant='h6'>Ke Halaman</Typography>
          <Divider />
          <Grid
            container
            alignItems='center'
            direction='row'
            style={{
              columnGap: '.5em'
            }}
          >
            <IconButton component='div' className={classes.buttonAction} size='small' onClick={decrementValue} disabled={value < 2}>
              <Remove style={{ color: WHITE }} />
            </IconButton>
            <TextField
              value={value}
              className={classes.input}
              onKeyDown={handleKeyDown}
              onChange={event => {
                if (Number(event.target.value) === 0) {
                  setValue(0);
                  return;
                }
                if (Number(event.target.value) > (customPageProps?.maxValue ?? 0)) {
                  setValue(customPageProps?.maxValue ?? 0);
                  return;
                }
                setValue(Number(event.target.value));
              }}
              size='small'
              focused
              inputProps={{ min: 0, style: { textAlign: 'center', fontWeight: 500 }, inputMode: 'numeric' }}
            />

            <IconButton
              disableFocusRipple={false}
              disableRipple={true}
              component='div'
              className={classes.buttonAction}
              size='small'
              onClick={increaseValue}
              disabled={value >= (customPageProps?.maxValue ?? 0)}
            >
              <Add style={{ color: WHITE }} />
            </IconButton>

            <Button variant='contained' onClick={handleSubmit}>
              OKE
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </div>
  );
};

export default PaginationCustom;
