import React, { FC, Fragment } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Checkbox, TextField } from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart } from 'components/Table/TableCellCustom';
import TableRowCustom from 'components/Table/TableRowCustom';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

interface Props {
  product: ProductModel;
  item: MutationItemModel;
  isLoading: boolean;
  checked: MutationItemModel[];
  index: number;
  isEditable: boolean;
  handleIndividualCheck: (item: MutationItemModel) => void;
  handleIndividualQty: (item: MutationItemModel) => void;
}

const BodyRow: FC<Props> = props => {
  const { item, product, isLoading, checked, isEditable, handleIndividualCheck, handleIndividualQty, index } = props;
  const { id } = product;

  let isChecked = checked.some(checkedValue => checkedValue.ProductId === id);

  const handleSetId = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    handleIndividualCheck({ ...item, ProductId: id });
  };

  return (
    <TableRowCustom key={index + 1}>
      {isEditable ? (
        <Fragment>
          <TableCellStart>
            {isLoading ? (
              <Skeleton variant='text' width={'100%'} height={25} />
            ) : (
              <Checkbox
                key={id}
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                edge='start'
                color='primary'
                checked={isChecked}
                tabIndex={-1}
                disableRipple
                onChange={handleSetId}
              />
            )}
          </TableCellStart>
          <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : product.productCode}</TableCellMiddle>
        </Fragment>
      ) : (
        <TableCellStart>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : product.productCode}</TableCellStart>
      )}

      <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : product.productName}</TableCellMiddle>
      <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : product.typeUnit}</TableCellMiddle>
      <TableCellEnd>
        <TextField label='Jumlah Barang' value={item.totalItem} onChange={e => handleIndividualQty({ ...item, totalItem: +e.target.value })} />
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRow;
