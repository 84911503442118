import React, { FC, useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, makeStyles } from '@material-ui/core';
import { PRODUCT_BASE_URL } from 'constants/url';
import { dummyReturnItem, dummyProduct } from 'utils/dummy';
import BodyRow from './components/BodyRow';
import ReturnOrderItem from './components/ReturnOrderItem';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import useRole from 'hooks/useRole';
import TypeUser from 'typings/enum/TypeUser';
import useDebounce from 'hooks/useDebounce';
interface Props {
  returnOrderItem: InvoiceReturnItemModel[];
  historyPrice: number;
  productAdditionalDamage: number;
  isUpdate: boolean;
  loadUpdate: boolean;
  typeReturn: string;
  additionalDamage: number;
  indexCollapse: number;
  totalItem: number;
  setTotalItem: React.Dispatch<React.SetStateAction<number>>;
  openCollapse: boolean;
  isAdmin: boolean;
  totalQtyReturn: number;
  totalQtyReturnWarehouse: number;
  countAdditionalDamage: number;
  countTotalItem: number;
  handleOpenReturn: (isReturn: boolean) => void;
  handleOpenHistory: () => void;
  handleOpenCollapse: (index: number) => React.MouseEventHandler;
  setAdditionalDamage: React.Dispatch<React.SetStateAction<number>>;
  setProductAdditionalDamage: React.Dispatch<React.SetStateAction<number>>;
  setProductId: React.Dispatch<React.SetStateAction<number>>;
  setReturnOrderItem: React.Dispatch<React.SetStateAction<InvoiceReturnItemModel[]>>;
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteItem: (index: number) => React.MouseEventHandler;
  setAdditionalDamageFilter: React.Dispatch<React.SetStateAction<number>>;
  setTotalItemFilter: React.Dispatch<React.SetStateAction<number>>;
}

const useStyles = makeStyles({
  addProductCell: {
    border: 'none',
    padding: '4px 0px 0px 0px'
  }
});

const ProductTableMobile: FC<Props> = props => {
  const classes = useStyles();
  const {
    returnOrderItem,
    isUpdate,
    loadUpdate,
    historyPrice,
    additionalDamage,
    typeReturn,
    totalItem,
    setTotalItem,
    indexCollapse,
    openCollapse,
    handleOpenReturn,
    isAdmin,
    setTotalItemFilter,
    setAdditionalDamageFilter,
    productAdditionalDamage,
    totalQtyReturn,
    totalQtyReturnWarehouse,
    handleOpenHistory,
    handleOpenCollapse,
    setReturnOrderItem,
    setUpdate,
    handleDeleteItem,
    setProductId,
    setAdditionalDamage,
    setProductAdditionalDamage,
    countAdditionalDamage,
    countTotalItem
  } = props;
  const [price, setPrice] = useState<number>(0);

  const [typeUnit, setTypeUnit] = useState<string>('');
  const [discount, setDiscount] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [subTotalPrice, setSubTotalPrice] = useState<number>(0);
  const [product, setProduct] = useState<ProductModel>(dummyProduct);
  const [products, setProducts] = useState<ProductModel[]>([dummyProduct]);
  const [loadProduct, setLoadProduct] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [productMessage, setProductMessage] = useState<string>('');
  const [priceMessageError, setPriceMessageError] = useState<string>('');
  const [totalItemMessage, setTotalItemMessage] = useState<string>('');
  const [totalItemMessageSupplier, setTotalItemMessageSupplier] = useState<string>('');
  const [selectProduct, setSelectProduct] = useState<string>('');
  const { currentUser } = useContext(CurrentUserContext);

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: ['SUPERADMIN', TypeUser.ADMIN03]
  });

  const handleAddItem = () => {
    if (!validation()) {
      return;
    }

    setReturnOrderItem([
      ...returnOrderItem,
      {
        ...dummyReturnItem,
        price,
        typeUnit,
        discount,
        totalPrice,
        subTotalPrice,
        additionalDamage,

        totalItem,
        ProductId: product.id,
        Product: product
      }
    ]);
    reset();
  };

  const handleUpdateItem = (index: number): React.MouseEventHandler => () => {
    const item: InvoiceReturnItemModel = returnOrderItem[index];
    setUpdate(true);
    setCurrentIndex(index);
    setProduct(item.Product!);
    setAdditionalDamage(item.additionalDamage!);
    setTotalPrice(item.totalPrice);
    setDiscount(item.discount);
    setTotalItem(item.totalItem);
    setPrice(item.price);
    setTypeUnit(item.typeUnit);
    resetValidation();
    setAdditionalDamageFilter(item.additionalDamage!);
    setTotalItemFilter(item.totalItem);
  };

  const handleSaveUpdate = (index: number): React.MouseEventHandler => () => {
    if (!validation()) {
      return;
    }
    const item: InvoiceReturnItemModel = {
      ...returnOrderItem[index],
      price,
      typeUnit,
      discount,
      totalPrice,
      additionalDamage,
      subTotalPrice,
      totalItem,
      ProductId: product.id,
      Product: product
    };

    returnOrderItem[index] = item;
    setReturnOrderItem(returnOrderItem);
    setUpdate(false);
    setCurrentIndex(-1);
    reset();
  };

  const handleCloseUpdate = () => {
    setUpdate(false);
    setCurrentIndex(-1);
    reset();
    resetValidation();
  };

  const reset = () => {
    setProduct(dummyProduct);
    setPrice(0);
    setTotalItem(0);
    setDiscount(0);
    setAdditionalDamage(0);
    setTotalItemFilter(0);
    setAdditionalDamageFilter(0);
    setTotalPrice(0);
  };

  const validation = () => {
    let valid = true;
    resetValidation();

    if (!product || product.id === 0) {
      setProductMessage('Produk tidak boleh kosong');
      valid = false;
    }

    if (!totalItem || totalItem === 0) {
      setTotalItemMessage('Kuantitas tidak boleh kosong');
      valid = false;
    }

    // if (typeReturn === 'SUPPLIER' && product.totalDamage && totalItem > product.totalDamage) {
    //   setTotalItemMessageSupplier('Melebihi kuantitas return Penjualan');
    //   valid = false;
    // }

    if ((typeReturn === 'CUSTOMER' && price === 0) || (typeReturn === 'SUPPLIER' && isSuperAdmin && price === 0)) {
      setPriceMessageError('Harga tidak boleh 0');
      valid = false;
    }

    return valid;
  };

  const resetValidation = () => {
    setProductMessage('');
    setTotalItemMessage('');
    setTotalItemMessageSupplier('');
    setPriceMessageError('');
  };

  const debouncedValue = useDebounce(selectProduct, 500);

  const handleSearchProduct = useCallback(async () => {
    const params = new URLSearchParams();
    params.append('keyword', selectProduct);
    params.append('page', '1');
    params.append('ordered', 'desc');
    params.append('isProductPackage', 'false');
    params.append('route', (currentUser && currentUser.SalesRoute ? currentUser.SalesRoute.map(value => value.ZoneId) : []).join(','));
    setLoadProduct(true);

    try {
      const { data } = await axios.get(`${PRODUCT_BASE_URL}?${params.toString()}`);
      setProducts(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadProduct(false);
    }
  }, [debouncedValue]);

  useEffect(() => {
    handleSearchProduct();
  }, [handleSearchProduct]);

  useEffect(() => {
    setSubTotalPrice((additionalDamage + totalItem) * price);
    setTotalPrice((additionalDamage + totalItem) * price - discount);
  }, [totalItem, price, discount, additionalDamage]);

  useEffect(() => {
    if (historyPrice < 1) {
      setPrice(0);
      return;
    }

    setPrice(historyPrice);
  }, [historyPrice]);

  useEffect(() => {
    if (product.id === 0) {
      setProductId(0);
      setTypeUnit('');
      setPrice(0);
      return;
    }

    setProductId(product.id);
    setTypeUnit(product.typeUnit);
  }, [product]);

  return (
    <>
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='center' width='100%'>
                Nama Produk
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {returnOrderItem.length === 0 && (
              <TableRow>
                <TableCell align='center'>Belum ada Produk yang ditambahkan.</TableCell>
              </TableRow>
            )}

            {returnOrderItem.length > 0 &&
              returnOrderItem
                .filter(value => !value.isDeleted)
                .map((value, index) =>
                  isUpdate && index === currentIndex ? (
                    <ReturnOrderItem
                      countAdditionalDamage={countAdditionalDamage}
                      countTotalItem={countTotalItem}
                      product={product}
                      products={products}
                      price={price}
                      totalItem={totalItem}
                      typeUnit={typeUnit}
                      discount={discount}
                      totalPrice={totalPrice}
                      isUpdate={isUpdate}
                      typeReturn={typeReturn}
                      index={index}
                      currentIndex={currentIndex}
                      loadProduct={loadProduct}
                      productMessage={productMessage}
                      totalQtyReturn={totalQtyReturn}
                      totalQtyReturnWarehouse={totalQtyReturnWarehouse}
                      priceMessageError={priceMessageError}
                      handleOpenHistory={handleOpenHistory}
                      totalItemMessage={totalItemMessage}
                      additionalDamage={additionalDamage}
                      handleOpenReturn={handleOpenReturn}
                      setSelectProduct={setSelectProduct}
                      totalItemMessageSupplier={totalItemMessageSupplier}
                      isAdmin={isAdmin}
                      setAdditionalDamage={setAdditionalDamage}
                      setProduct={setProduct}
                      setPrice={setPrice}
                      setTotalItem={setTotalItem}
                      setDiscount={setDiscount}
                      setTypeUnit={setTypeUnit}
                      handleSearchProduct={handleSearchProduct}
                      handleSaveUpdate={handleSaveUpdate}
                      handleCloseUpdate={handleCloseUpdate}
                    />
                  ) : (
                    <BodyRow
                      key={index}
                      index={index}
                      typeReturn={typeReturn}
                      currentIndex={currentIndex}
                      item={value}
                      isLoading={loadUpdate}
                      indexCollapse={indexCollapse}
                      openCollapse={openCollapse}
                      isAdmin={isAdmin}
                      handleCollapse={handleOpenCollapse(index)}
                      handleUpdateItem={handleUpdateItem}
                      handleDeleteItem={handleDeleteItem}
                    />
                  )
                )}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer>
        <Table>
          <TableBody>
            {!isUpdate && (
              <ReturnOrderItem
                countAdditionalDamage={countAdditionalDamage}
                countTotalItem={countTotalItem}
                product={product}
                products={products}
                price={price}
                totalItem={totalItem}
                typeUnit={typeUnit}
                typeReturn={typeReturn}
                discount={discount}
                totalPrice={totalPrice}
                handleOpenHistory={handleOpenHistory}
                totalItemMessageSupplier={totalItemMessageSupplier}
                index={0}
                currentIndex={0}
                loadProduct={loadProduct}
                handleOpenReturn={handleOpenReturn}
                totalQtyReturn={totalQtyReturn}
                totalQtyReturnWarehouse={totalQtyReturnWarehouse}
                isUpdate={isUpdate}
                productMessage={productMessage}
                priceMessageError={priceMessageError}
                totalItemMessage={totalItemMessage}
                additionalDamage={additionalDamage}
                isAdmin={isAdmin}
                setAdditionalDamage={setAdditionalDamage}
                setProduct={setProduct}
                setPrice={setPrice}
                setTotalItem={setTotalItem}
                setSelectProduct={setSelectProduct}
                setDiscount={setDiscount}
                setTypeUnit={setTypeUnit}
                handleSearchProduct={handleSearchProduct}
                handleSaveUpdate={handleSaveUpdate}
                handleCloseUpdate={handleCloseUpdate}
              />
            )}

            {!isUpdate && (
              <TableRow>
                <TableCell align='right' className={classes.addProductCell}>
                  <Button
                    fullWidth
                    variant='outlined'
                    onClick={handleAddItem}
                    disabled={
                      isNaN(totalPrice) ||
                      totalPrice < 1 ||
                      additionalDamage > totalQtyReturnWarehouse ||
                      (typeReturn === 'SUPPLIER' && totalItem > totalQtyReturn)
                    }
                  >
                    Tambah Produk
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ProductTableMobile;
