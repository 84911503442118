import { Button, Divider, Grid, makeStyles, Radio, TextField, Theme, Typography } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { YELLOW_MOBILE } from 'constants/colors';
import React from 'react';
import TypeUser from 'typings/enum/TypeUser';

interface Props {
  hasFilter: string;
  selectedOptions: number[];
  setHasFilter: React.Dispatch<React.SetStateAction<string>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSalesName: React.Dispatch<React.SetStateAction<string>>;
  salesName: string;
  isAccess: string;
  zones: ZoneModel[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<number[]>>;
  handleFilter: () => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  containerDrawer: {
    width: '60vw',
    zIndex: 2,
    padding: theme.spacing(3),
    position: 'relative'
  },
  textContentMobile: {
    fontSize: '0.875rem',
    marginBottom: theme.spacing(1)
  },
  optionContainer: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2)
  },

  textContentBoldMobile: {
    fontSize: '1.1rem',
    fontWeight: 500
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 25,
    width: '70%',
    marginLeft: theme.spacing(1),
    display: 'grid',
    placeItems: 'center'
  },
  radioFilter: {
    '&.MuiRadio-root': {
      padding: 0
    }
  }
}));
const DrawerFilter: React.FC<Props> = props => {
  const { setOpen, hasFilter, setHasFilter, handleFilter, isAccess, zones, setSelectedOptions, selectedOptions, salesName, setSalesName } = props;
  const classes = useStyles();

  return (
    <Grid xs={12} className={classes.containerDrawer}>
      <Typography align='right' className={classes.textContentBoldMobile}>
        <CloseRounded onClick={() => setOpen(false)} />
      </Typography>
      <Grid className={classes.optionContainer} xs={12} container justify='space-between' alignItems='center'>
        <Typography className={classes.textContentBoldMobile}>Filter</Typography>
        <Typography
          className={classes.textContentBoldMobile}
          style={{ color: YELLOW_MOBILE }}
          onClick={() => {
            setOpen(false);
            setHasFilter('PENDING');
          }}
        >
          Reset
        </Typography>
      </Grid>

      <Divider className={classes.divider} />

      <Grid xs={12} container className={classes.optionContainer}>
        {isAccess !== TypeUser.PICKER && isAccess !== TypeUser.ADMIN01 && isAccess !== TypeUser.ADMIN02 && isAccess !== TypeUser.ADMIN02PLUS && (
          <>
            <Grid xs={12}>
              <Typography color='primary' className={classes.textContentMobile}>
                Status
              </Typography>
            </Grid>
            <Grid xs={12} container direction='row' alignItems='center' justify='space-between'>
              <Typography className={classes.textContentMobile}>Tertunda</Typography>
              <Radio
                checked={hasFilter === 'PENDING'}
                onChange={e => setHasFilter(e.target.value)}
                value='PENDING'
                color='primary'
                name='filter'
                size='small'
                className={classes.radioFilter}
              />
            </Grid>
            <Grid xs={12} container direction='row' alignItems='center' justify='space-between'>
              <Typography className={classes.textContentMobile}>Konfirmasi</Typography>
              <Radio
                checked={hasFilter === 'CONFIRMATION'}
                onChange={e => setHasFilter(e.target.value)}
                value='CONFIRMATION'
                color='primary'
                name='filter'
                size='small'
                className={classes.radioFilter}
              />
            </Grid>
            <Grid xs={12} container direction='row' alignItems='center' justify='space-between'>
              <Typography className={classes.textContentMobile}>Batal</Typography>
              <Radio
                checked={hasFilter === 'CANCEL'}
                onChange={e => setHasFilter(e.target.value)}
                value='CANCEL'
                color='primary'
                name='filter'
                size='small'
                className={classes.radioFilter}
              />
            </Grid>
            <Grid xs={12}>
              <Divider className={classes.divider} />
            </Grid>
          </>
        )}

        <Grid xs={12}>
          <Typography color='primary' className={classes.textContentMobile}>
            Tanggal Order
          </Typography>
        </Grid>
        <Grid xs={12} container direction='row' alignItems='center' justify='space-between'>
          <Typography className={classes.textContentMobile}>Tanggal Terlama</Typography>
          <Radio
            checked={hasFilter === 'asc'}
            onChange={e => setHasFilter(e.target.value)}
            value='asc'
            color='primary'
            name='filter'
            size='small'
            className={classes.radioFilter}
          />
        </Grid>

        <Grid xs={12} container direction='row' alignItems='center' justify='space-between'>
          <Typography className={classes.textContentMobile}>Tanggal Terbaru</Typography>
          <Radio
            checked={hasFilter === 'desc'}
            onChange={e => setHasFilter(e.target.value)}
            value='desc'
            color='primary'
            name='filter'
            size='small'
            className={classes.radioFilter}
          />
        </Grid>

        <Grid xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid xs={12}>
          <Typography color='primary' className={classes.textContentMobile}>
            Rute
          </Typography>
        </Grid>
        <Grid xs={12} container direction='row' alignItems='center' justify='space-between'>
          <Autocomplete
            id='zones'
            options={zones}
            onChange={(event, value) => {
              value.map(value => setSelectedOptions([...selectedOptions, value.id]));
            }}
            multiple
            size='small'
            getOptionLabel={option => option.name}
            style={{ width: 300 }}
            renderInput={params => <TextField {...params} variant='outlined' placeholder='Pilih Rute' />}
          />
        </Grid>

        <Grid xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid xs={12}>
          <TextField id='salesName' placeholder='Sales' value={salesName} onChange={event => setSalesName(event.target.value)} />
        </Grid>
      </Grid>
      <Grid xs={12} className={classes.buttonContainer}>
        <Button size='small' variant='contained' fullWidth style={{ borderRadius: '20px' }} onClick={handleFilter}>
          Filter
        </Button>
      </Grid>
    </Grid>
  );
};

export default DrawerFilter;
