import React, { FC, Fragment, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  makeStyles,
  TextField,
  MenuItem,
  Tabs,
  Tab,
  Button,
  Typography,
  Link,
  Select,
  Input,
  InputLabel,
  FormControl,
  ListItemText,
  Checkbox,
  Chip,
  Grid,
  CircularProgress,
  FormHelperText
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { PRODUCT_BASE_URL, CATEGORY_BASE_URL, PRODUCT_IMAGE_DELETE_BASE_URL, WAREHOUSE_BASE_URL } from 'constants/url';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { dummyCategory, dummyWareHouse } from 'utils/dummy';
import { TabPanel, a11yProps } from 'components';
import DropZone from './DropZone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TypeUnit from 'typings/enum/TypeUnit';
import NumberFormatMask from 'components/NumberFormatMask';
import ActionSnackBar from 'components/ActionSnackBar';
import ErrorIcon from '@material-ui/icons/Error';
import ReactQuill from 'react-quill';
import { RED } from 'constants/colors';
import { dynamicSort } from 'utils';
import useRole from 'hooks/useRole';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import TypeUser from 'typings/enum/TypeUser';

interface Props {
  product: ProductModel;
  zone: ZoneModel[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setProduct: React.Dispatch<React.SetStateAction<ProductModel>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', Message: string) => void;
}

const useStyles = makeStyles({
  notesGrid: {
    marginTop: 10
  },
  buttonGrid: {
    marginTop: 10
  },
  tabGrid: {
    marginBottom: 10
  },
  tabUpload: {
    width: '100%'
  },
  formControlRoute: {
    minWidth: '100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  menuItem: {
    width: 200,
    float: 'left'
  }
});

const ProductWizard: FC<Props> = props => {
  const classes = useStyles();
  const { currentUser } = useContext(CurrentUserContext);
  const { product, setOpen, setProduct, zone, handleSnackBar } = props;
  const [productName, setProductName] = useState<string>('');
  const [productCode, setProductCode] = useState<string>('');
  const [purchasePrice, setPurchasePrice] = useState<number>(0);
  const [typeUnit, setTypeUnit] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [category, setCategory] = useState<CategoryModel>(dummyCategory);
  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const [loadCategory, setLoadCategory] = useState<boolean>(false);
  const [value, setValue] = useState(0);
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [productNameMessage, setProductNameMessage] = useState<string>('');
  const [productCodeMessage, setProductCodeMessage] = useState<string>('');
  const [typeUnitMessage, setTypeUnitMessage] = useState<string>('');
  const [imageMessage, setImageMessage] = useState<string>('');
  const [categoryMessage, setCategoryMessage] = useState<string>('');
  const [image, setImage] = useState<{ path: string }[]>([]);
  const [imageBlob, setImageBlob] = useState<FileWithPath[]>([]);
  const [imageUrl, setImageUrl] = useState<ProductImageModel[]>([]);
  const [productPrice, setProductPrice] = useState<ProductPriceModel[]>([]);

  const [warehouses, setWarehouses] = useState<WareHouseModel[]>([dummyWareHouse]);
  const [warehouse, setWarehouse] = useState<number>(0);
  const [warehouseMessage, setWarehouseMessage] = useState<string>('');
  const [loadWarehouse, setLoadWarehouse] = useState<boolean>(false);

  const [price, setPrice] = useState<number>(0);
  const [selectedRouteId, setSelectedRouteId] = useState<number[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const isHeadAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN]
  });
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpeg', 'image/png'],
    maxFiles: 5,
    onDrop: acceptedFiles => {
      setImage([]);
      setImageBlob([]);
      uploadImage(acceptedFiles);
    }
  });

  const uploadImage = (files: FileWithPath[]) => {
    files.map((value: any) => {
      setImageUrl(prevState => [...prevState, { id: 0, path: '', url: URL.createObjectURL(value) }]);
      setImage(prevState => [...prevState, { path: `${productCode}${new Date().getTime()}.${value.name.split('.').pop()}` }]);
      setImageBlob(prevState => [...prevState, value]);
    });
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    resetValidation();

    if (!validation()) {
      return;
    }

    setValue(newValue);
  };

  const handleSearchCategory = async (value: string) => {
    setLoadCategory(true);
    const params = new URLSearchParams();
    params.append('keyword', value);

    try {
      const { data } = await axios.get(`${CATEGORY_BASE_URL}?${params.toString()}`);
      setCategories(data.data);
    } catch (error) {
      console.log('error :', error);
      setLoadCategory(false);
    }
  };

  const handleSearchWarehouse = async () => {
    setLoadWarehouse(true);
    try {
      const { data } = await axios.get(WAREHOUSE_BASE_URL);
      setWarehouses(data.data);
      setLoadWarehouse(false);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadWarehouse(false);
    }
  };

  console.log('image ----> :', image);

  const handleCheckCode = async (submitValidation: boolean) => {
    setProductCodeMessage('');
    try {
      const { data } = await axios.post(`${PRODUCT_BASE_URL}/validate-code`, {
        id: product.id,
        code: productCode
      });

      if (data.data.validate) {
        setProductCodeMessage('Kode Product sudah ada.');

        if (submitValidation) {
          setSubmit(false);
          setOpenSnackbar(true);
          return;
        }
      }
    } catch (error) {
      console.log('error :', error);
    }
  };

  const handleOnSubmit = async () => {
    resetValidation();

    if (!validation()) {
      setValue(0);
      setSubmit(false);
      return;
    }

    setSubmit(true);

    await handleCheckCode(true);

    try {
      const price = productPrice
        ? productPrice.map(val => {
            delete val.Zone;
            return val;
          })
        : [];

      const { data } = await axios.post(`${PRODUCT_BASE_URL}`, {
        id: product.id,
        productName,
        productCode,
        purchasePrice,
        description,
        typeUnit,
        WareHouseId: warehouse,
        CategoryId: category!.id,
        image: image,
        price,
        isProductPackage: false
      });

      if (data.data.ProductImages.length) {
        let i = 0;
        data.data.ProductImages.map(async (value: any, index: number) => {
          if (image.filter(img => img.path === value.path).length) {
            // @ts-ignore
            const newImageKey = value.path;
            const fileExtension = newImageKey.split('.').pop();

            const myHeaders = new Headers();
            myHeaders.append('Content-Type', `image/${fileExtension}`);

            const config: RequestInit = {
              method: 'PUT',
              headers: myHeaders,
              body: imageBlob[i]
            };

            await fetch(value.url, config)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));

            i++;
          }
        });
      }

      setProduct(data.data);
      setOpen(false);
      handleSnackBar(true, 'success', 'Produk berhasil diperbaharui');
    } catch (err) {
      const error = err as { data: { message: string } };
      if (error.data.message) {
        handleSnackBar(true, 'error', error.data.message);
      } else {
        handleSnackBar(true, 'error', 'Produk gagal diperbaharui');
      }
    } finally {
      setSubmit(false);
    }
  };

  const validation = (): boolean => {
    let valid = true;

    if (productName === '' || !productName) {
      setProductNameMessage('Nama Produk tidak boleh kosong.');
      valid = false;
    }

    if (typeUnit === '' && !typeUnit) {
      setTypeUnitMessage('Satuan Produk tidak boleh kosong.');
      valid = false;
    }

    if (!category) {
      setCategoryMessage('Kategori tidak boleh kosong');
      valid = false;
    }

    if (warehouse === 0) {
      setWarehouseMessage('Gudang tidak boleh kosong');
      valid = false;
    }

    return valid;
  };

  const resetValidation = () => {
    setProductNameMessage('');
    setProductCodeMessage('');
    setTypeUnitMessage('');
    setCategoryMessage('');
    setWarehouseMessage('');
  };

  const handleChangeRoute = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selected = event.target.value as number[];
    setSelectedRouteId(selected);
  };

  const handleAddMultiPrice = () => {
    const newPrice = [...productPrice];
    const selectedRoute = zone.filter(value => selectedRouteId.includes(value.id));
    selectedRoute.sort(dynamicSort('name'));
    selectedRoute
      .map(value => value.id)
      .map(zoneId => {
        const index = productPrice.findIndex(currentPrice => currentPrice.ZoneId == zoneId);
        newPrice[index].price = price;
      });

    setProductPrice(newPrice);
    setSelectedRouteId([]);
    setPrice(0);
  };

  const handleDeleteMultiPrice = (zoneId: number) => {
    const index = productPrice.findIndex(currentPrice => currentPrice.ZoneId == zoneId);
    const newPrice = [...productPrice];
    newPrice[index].price = 0;
    setProductPrice(newPrice);
  };

  const handleDeleteImage = (id: number): React.MouseEventHandler => () => {
    const deleteImage = async () => {
      try {
        await axios.delete(PRODUCT_IMAGE_DELETE_BASE_URL(id));
        setImageUrl(imageUrl.filter(value => value.id !== id));
      } catch (error) {
        console.log('error :', error);
      }
    };
    deleteImage();
  };

  useEffect(() => {
    if (productPrice.length === 0) {
      if (zone.length > 0) {
        setProductPrice(
          zone.map(value => {
            return {
              id: 0,
              price: 0,
              ProductId: product.id,
              ZoneId: value.id
            };
          })
        );
      }
      return;
    }

    let newPrice = [...productPrice];
    zone.map(value => {
      const priceIndex = newPrice.map(value => value.ZoneId).indexOf(value.id);
      if (priceIndex < 0) {
        setProductPrice([...newPrice, { id: 0, price: 0, ZoneId: value.id }]);
      }
    });
  }, [productPrice, product, zone]);

  useEffect(() => {
    handleSearchWarehouse();
  }, []);

  useEffect(() => {
    setProductName(product.productName);
    setProductCode(product.productCode);
    setPurchasePrice(product.purchasePrice);
    setTypeUnit(product.typeUnit);
    setDescription(product.description);
    setCategory(product.Category || dummyCategory);
    setImageUrl(product.ProductImages || []);
    setProductPrice(product.ProductPrice || []);
    setWarehouse(product.WareHouseId || 0);
  }, [product]);

  const isAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.ADMIN,
    allowed: [TypeUser.ADMIN]
  });

  console.log('product : --->', product);

  return (
    <Grid container direction='row'>
      <Grid container direction='row' justify='center' className={classes.tabGrid}>
        <Tabs value={value} indicatorColor='primary' textColor='inherit' onChange={handleChange}>
          <Tab label='Informasi' {...a11yProps(0)} />
          <Tab label='Unggah Gambar' {...a11yProps(1)} />
          {!isAdmin && <Tab label={'Harga Produk'} {...a11yProps(2)} />}
        </Tabs>
      </Grid>

      <Grid container direction='row'>
        <TabPanel value={value} index={0}>
          <Grid container direction='row' spacing={1} justify='space-between'>
            <Grid item lg={6} sm={12} md={6} xs={12}>
              <Autocomplete
                id='category'
                value={category}
                options={categories}
                disabled={isHeadAdmin}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={(event: any, value: any) => setCategory(value)}
                onOpen={e => handleSearchCategory('')}
                loading={loadCategory}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    label='Pilih Kategori Produk'
                    size='small'
                    onChange={e => handleSearchCategory(e.target.value)}
                    variant='outlined'
                    error={categoryMessage !== ''}
                    helperText={categoryMessage}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {loadCategory && <CircularProgress color='inherit' size={20} />}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      )
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} sm={12} md={6} xs={12}>
              <TextField
                id='productCode'
                disabled
                required
                fullWidth
                label='Kode Produk'
                value={productCode}
                onChange={event => setProductCode(event.target.value)}
                error={productCodeMessage !== ''}
                helperText={productCodeMessage}
                onBlur={event => handleCheckCode(false)}
              />
            </Grid>

            <Grid item lg={6} sm={12} md={6} xs={12}>
              <TextField
                id='productName'
                required
                fullWidth
                label='Nama Produk'
                disabled={isHeadAdmin}
                value={productName}
                onChange={event => setProductName(event.target.value)}
                error={productNameMessage !== ''}
                helperText={productNameMessage}
              />
            </Grid>

            <Grid item lg={3} sm={12} md={3} xs={12}>
              <TextField
                id='type'
                required
                fullWidth
                select
                value={typeUnit as string}
                label='Pilih Satuan Barang'
                disabled={isHeadAdmin}
                onChange={event => setTypeUnit(event.target.value as string)}
                error={typeUnitMessage !== ''}
                helperText={typeUnitMessage}
              >
                {Object.entries(TypeUnit).map(([value, key]) => (
                  <MenuItem key={key} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
              <TextField
                id='warehouse'
                fullWidth
                value={warehouse}
                error={warehouseMessage !== ''}
                disabled={isHeadAdmin}
                helperText={warehouseMessage}
                onChange={e => setWarehouse(+e.target.value)}
                label='Gudang'
                select
              >
                <MenuItem key={''} value={0} selected>
                  Pilih Gudang
                </MenuItem>
                {warehouses.length > 0 &&
                  warehouses.map((value, index) => (
                    <MenuItem key={index} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid item lg={12} sm={12} md={12} xs={12}>
              {!isHeadAdmin && <ReactQuill id='notes' value={description} onChange={(value: any) => setDescription(value)} placeholder='Catatan' />}
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1} className={classes.tabUpload}>
          <FormHelperText error={imageMessage !== ''}>{imageMessage}</FormHelperText>
          <DropZone
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            acceptedFiles={acceptedFiles}
            imageUrl={imageUrl}
            handleOnDelete={handleDeleteImage}
          />
        </TabPanel>

        <TabPanel value={value} index={2} className={classes.tabUpload}>
          <Grid direction='row' container spacing={2} item lg={12} sm={12} md={12} xs={12}>
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <TextField
                id={'productPrice'}
                required
                fullWidth
                disabled={isHeadAdmin}
                label='Harga Jual'
                value={price > 0 ? price : null}
                error={
                  (price !== null && price > 0 && price < product.purchasePrice) || (price !== null && price > 0 && price === product.purchasePrice)
                }
                helperText={
                  (price !== null &&
                    price > 0 &&
                    price < product.purchasePrice &&
                    `Harga Jual tidak boleh kecil dari HPP (Rp ${product.purchasePrice.toLocaleString()})`) ||
                  (price !== null &&
                    price > 0 &&
                    price === product.purchasePrice &&
                    `Harga Jual tidak boleh sama dengan HPP (Rp ${product.purchasePrice.toLocaleString()})`)
                }
                onChange={event => setPrice(+event.target.value)}
                InputProps={{
                  inputComponent: NumberFormatMask as any
                }}
              />
            </Grid>

            <Grid item lg={12} sm={12} md={12} xs={12}>
              <FormControl variant='outlined' className={classes.formControlRoute}>
                <InputLabel id='demo-mutiple-chip-label'>Pilih Rute</InputLabel>
                <Select
                  labelId='demo-mutiple-chip-label'
                  id='demo-mutiple-chip'
                  variant='outlined'
                  multiple
                  disabled={isHeadAdmin}
                  value={selectedRouteId}
                  onChange={handleChangeRoute}
                  input={<Input id='select-multiple-chip' />}
                  renderValue={selected => {
                    const selectedRoute = zone.filter(value => (selected as number[]).includes(value.id));
                    selectedRoute.sort(dynamicSort('name'));

                    return (
                      <div className={classes.chips}>
                        {selectedRoute
                          .map(value => value.id)
                          .map(value => {
                            const findZone = zone.find(zone => zone.id === value);
                            return findZone && <Chip key={findZone.name} label={findZone.name} className={classes.chip} />;
                          })}
                      </div>
                    );
                  }}
                >
                  {productPrice.length > 0 && zone.length > 0 ? (
                    zone.map((value, index) => (
                      <MenuItem key={index} value={value.id} className={classes.menuItem}>
                        <Checkbox color='primary' checked={selectedRouteId.indexOf(value.id) > -1} />
                        <ListItemText primary={value.name} />
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem key={0} value='' className={classes.menuItem}>
                      <Typography variant='inherit' align='center'>
                        Rute belum ada.
                      </Typography>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} alignItems='flex-end'>
              <Button
                variant='outlined'
                disabled={isSubmit || price <= product.purchasePrice || selectedRouteId.length == 0}
                onClick={handleAddMultiPrice}
                color='primary'
                style={{ float: 'right' }}
              >
                Tambahkan
              </Button>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <Typography variant='h6'>Harga Jual Produk per Rute</Typography>
            </Grid>
            <Grid container item lg={12} sm={12} md={12} xs={12} style={{ minHeight: 100 }}>
              {productPrice &&
                productPrice.map((price: ProductPriceModel) => {
                  return (
                    price.price > 0 && (
                      <Grid container item lg={12} sm={12} md={12} xs={12} spacing={3}>
                        <Grid item lg={4} sm={4} md={4} xs={4}>
                          <Typography variant='body1'>Harga Jual {price.Zone && price.Zone.name}</Typography>
                        </Grid>
                        <Grid item lg={3} sm={3} md={3} xs={3}>
                          <Typography variant='body1'>
                            <NumberFormat value={price.price} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />
                          </Typography>
                        </Grid>
                        <Grid item lg={2} sm={2} md={2} xs={2}>
                          {!isHeadAdmin && (
                            <Link
                              component='button'
                              variant='body2'
                              disabled={isSubmit}
                              onClick={() => handleDeleteMultiPrice(price.ZoneId)}
                              style={{ color: RED }}
                            >
                              Hapus
                            </Link>
                          )}
                        </Grid>
                      </Grid>
                    )
                  );
                })}
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>

      <Grid container item lg={12} md={12} sm={12} xs={12} justify='flex-end' className={classes.buttonGrid}>
        <Button color='secondary' disabled={isSubmit} onClick={() => setOpen(false)}>
          Batal
        </Button>
        &nbsp; &nbsp;
        {!isAdmin && (
          <Button value={value} onClick={event => (value < 2 ? handleChange(event, value + 1) : handleOnSubmit())}>
            {value < 2 ? 'Selanjutnya' : isSubmit ? <CircularProgress color='inherit' size={20} /> : 'Simpan'}
          </Button>
        )}
        {isAdmin && (
          <Button value={value} onClick={event => (value < 1 ? handleChange(event, value + 1) : handleOnSubmit())}>
            {value < 1 ? 'selanjutnya' : isSubmit ? <CircularProgress color='inherit' size={20} /> : 'Simpan'}
          </Button>
        )}
      </Grid>

      <ActionSnackBar
        variant={'error'}
        message={'Kode Produk sudah ada.'}
        open={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        Icon={ErrorIcon}
      />
    </Grid>
  );
};

export default ProductWizard;
