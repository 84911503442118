import React, { FC } from 'react';
import { TableBody, TableHead, TableRow, TableCell, TextField, TableContainer } from '@material-ui/core';
import BodyRow from './components/BodyRow';
import { HeaderRow, TableCellHead, TableCustom } from 'components/Table';
import { dummyUser } from 'utils/dummy';

interface Props {
  isLoadingData: boolean;
  sales: UserDetailsModel[];
  queryString?: string;
  order: 'asc' | 'desc';
  orderBy: string;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  address: string;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  salesId: string;
  setSalesId: React.Dispatch<React.SetStateAction<string>>;
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
}

const CommissionPageTable: FC<Props> = props => {
  const {
    isLoadingData,
    order,
    orderBy,
    name,
    setName,
    email,
    setEmail,
    salesId,
    sales,
    setSalesId,
    phoneNumber,
    setPhoneNumber,
    setOrder,
    setOrderBy,
    queryString
  } = props;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <TableCustom >  
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={[
              { id: 'salesId', label: 'ID', sort: true },
              { id: 'name', label: 'NAMA SALES', sort: true },
              { id: 'email', label: 'EMAIL', sort: true },
              { id: 'phoneNumber', label: 'NO.HANDPHONE', sort: true }
            ]}
          />
          <TableRow>
            <TableCellHead variant='head'>
              <TextField
                placeholder='ID'
                id='salesId'
                variant='outlined'
                value={salesId}
                onChange={event => setSalesId(event.target.value)}
                size='small'
              />
            </TableCellHead>
            <TableCellHead variant='head'>
              <TextField
                placeholder='Nama Sales'
                id='name'
                variant='outlined'
                value={name}
                onChange={event => setName(event.target.value)}
                size='small'
              />
            </TableCellHead>
            <TableCellHead variant='head'>
              <TextField
                placeholder='Email'
                id='email'
                variant='outlined'
                value={email}
                onChange={event => setEmail(event.target.value)}
                size='small'
              />
            </TableCellHead>

            <TableCellHead variant='head'>
              <TextField
                id='cellPhoneNumber'
                placeholder='No Handphone'
                variant='outlined'
                value={phoneNumber}
                onChange={event => setPhoneNumber(event.target.value)}
                size='small'
              />
            </TableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => <BodyRow key={index} isLoading={isLoadingData} sales={dummyUser} />)
          ) : sales && sales.length > 0 ? (
            sales.map((value, index) => <BodyRow key={index} isLoading={isLoadingData} sales={value} />)
          ) : (
            <TableRow>
              <TableCell colSpan={4} align='center'>
                {queryString ? 'No matching result' : 'Data Tidak Tersedia.'}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default CommissionPageTable;
