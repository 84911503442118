import React, { FC, Fragment, useContext } from 'react';
import { TableRow, Button, IconButton, makeStyles, Table, TableBody, TableCell, TextField, CircularProgress, Typography } from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart, TableRowCollapse } from 'components/Table';
import Skeleton from '@material-ui/lab/Skeleton';
import NumberFormat from 'react-number-format';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { YELLOW_MOBILE } from 'constants/colors';

interface Props {
  loadingUpdate: boolean;
  isShow: boolean;
  item: InvoiceReturnItemModel;
  isLoading: boolean;
  openCollapse: boolean;
  indexCollapse: number;
  withCollapse: boolean;
  handleOpenCollapse: React.MouseEventHandler;
  index: number;
  _item: {
    id: number;
    totalDamage: number;
    totalMerge: number;
    totalReturned: number;
  };
  setItem: React.Dispatch<
    React.SetStateAction<{
      id: number;
      totalDamage: number;
      totalMerge: number;
      totalReturned: number;
    }>
  >;
  handleUpdateItem: () => void;
}

const useStyles = makeStyles({
  cellCollapse: {
    border: 'none'
  }
});

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { currentUser } = useContext(CurrentUserContext);

  const {
    item,
    isLoading,
    loadingUpdate,
    openCollapse,
    withCollapse,
    index,
    indexCollapse,
    _item,
    isShow,
    setItem,
    handleUpdateItem,
    handleOpenCollapse
  } = props;

  const isValid = _item.totalDamage + _item.totalMerge + _item.totalReturned !== item.totalItem;
  const validation = _item.totalDamage === 0 && _item.totalMerge === 0 && _item.totalReturned === 0;
  const hasActionValidation = (item.totalDamage || item.totalMerge || item.totalReturned) > 0;

  return (
    <Fragment>
      <TableRow>
        <TableCellStart>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <Fragment>
              {withCollapse && (
                <IconButton
                  aria-label='expand row'
                  size='small'
                  onClick={handleOpenCollapse}
                  disabled={
                    openCollapse && index !== indexCollapse //&& !(_item.totalDamage === item.totalDamage && _item.totalMerge === item.totalMerge)
                  }
                >
                  {openCollapse && index === indexCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              )}
              {item.Product ? item.Product.productName : ''}
            </Fragment>
          )}
        </TableCellStart>

        {isShow && (
          <TableCellMiddle align='center'>
            {isLoading ? (
              <Skeleton variant='text' width={100} height={25} />
            ) : (
              <NumberFormat value={item.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
            )}
          </TableCellMiddle>
        )}

        <TableCellMiddle align='center'>
          {isLoading ? <Skeleton variant='text' width={100} height={25} /> : item.totalItem + ' ' + item.typeUnit}
        </TableCellMiddle>

        {!withCollapse && (
          <TableCellMiddle align='center'>
            {isLoading ? <Skeleton variant='text' width={100} height={25} /> : item.additionalDamage + ' ' + item.typeUnit}
          </TableCellMiddle>
        )}

        {isShow && (
          <TableCellEnd align='center'>
            {isLoading ? (
              <Skeleton variant='text' width={100} height={25} />
            ) : (
              <NumberFormat value={item.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
            )}
          </TableCellEnd>
        )}
      </TableRow>

      <TableRowCollapse open={openCollapse && index === indexCollapse} colSpan={4}>
        <Table size='small'>
          <TableBody>
            {!hasActionValidation && (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography variant='subtitle2' style={{ color: YELLOW_MOBILE }}>
                    Silahkan untuk memasukkan kuantitas hingga sisa kuantitas sama dengan 0 untuk menyimpan data
                    <span style={{ fontWeight: 600 }}>
                      {' '}
                      (Sisa Kuantitas: {item.totalItem - (_item.totalDamage + _item.totalMerge + _item.totalReturned)})
                    </span>
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell className={classes.cellCollapse}>
                <TextField
                  fullWidth
                  label='Barang Rusak'
                  value={_item.totalDamage}
                  disabled={hasActionValidation}
                  error={_item.totalDamage > item.totalItem - (_item.totalMerge + _item.totalReturned)}
                  helperText={_item.totalDamage > item.totalItem - (_item.totalMerge + _item.totalReturned) && 'Barang Rusak Melebihi total stok'}
                  onChange={e => {
                    setItem({ ..._item, totalDamage: +e.target.value });
                  }}
                />
              </TableCell>

              <TableCell className={classes.cellCollapse}>
                <TextField
                  fullWidth
                  label='Total Barang Masuk Ke Stok'
                  value={_item.totalMerge}
                  disabled={hasActionValidation}
                  error={_item.totalMerge > item.totalItem - (_item.totalDamage + _item.totalReturned)}
                  helperText={_item.totalMerge > item.totalItem - (_item.totalDamage + _item.totalReturned) && 'Masuk stok melebihi total stok'}
                  onChange={e => {
                    setItem({ ..._item, totalMerge: +e.target.value });
                  }}
                />
              </TableCell>

              <TableCell className={classes.cellCollapse}>
                <TextField
                  fullWidth
                  label='Kembali ke Customer'
                  value={_item.totalReturned}
                  disabled={hasActionValidation}
                  error={_item.totalReturned > item.totalItem - (_item.totalDamage + _item.totalMerge)}
                  helperText={
                    _item.totalReturned > item.totalItem - (_item.totalDamage + _item.totalMerge) && 'Kembali ke customer melebihi total stok'
                  }
                  onChange={e => {
                    setItem({ ..._item, totalReturned: +e.target.value });
                  }}
                />
              </TableCell>

              <TableCell align='center' className={classes.cellCollapse}>
                <Button fullWidth disabled={validation || isValid || hasActionValidation} size='small' onClick={handleUpdateItem}>
                  {openCollapse && index === indexCollapse && loadingUpdate ? <CircularProgress color='inherit' size={20} /> : 'Simpan'}
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              {hasActionValidation && (
                <TableCell colSpan={4}>
                  <Typography variant='subtitle2' style={{ color: YELLOW_MOBILE }}>
                    Data item sudah di simpan, dan sudah tidak bisa melakukan perubahan.
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableRowCollapse>
    </Fragment>
  );
};

export default BodyRow;
