import React, { FC, useEffect, useCallback, useState } from 'react';
import { Grid, Container, Typography, Button, IconButton, Tooltip, makeStyles, Theme } from '@material-ui/core';
import { Page, StandardConfirmationDialog, PaperCustom, Breadcrumb } from 'components';
import { AddBox } from '@material-ui/icons';
import axios, { CancelTokenSource } from 'axios';
import { MUTATION_BASE_URL, DELETE_MUTATION_BASE_URL } from 'constants/url';
import useDebounce from 'hooks/useDebounce';
import TransferTable from './components/TransferTable';
import TransferStockModal from './components/TransferStockModal';
import TransferDetailModal from './components/TransferDetailModal';
import Pagination from '@material-ui/lab/Pagination';
import { dummyMutation } from 'utils/dummy';
import CalendarIcon from '@material-ui/icons/EventNote';
import { BLUE_PRIMARY, GREEN, WHITE } from 'constants/colors';
import { format, startOfMonth } from 'date-fns';
import DateRangeFilter from 'components/DateRangeFilter';
import PaginationCustom from 'components/PaginationCustom';

const useStyles = makeStyles((theme: Theme) => ({
  calendarIcon: {
    fontSize: 30,
    color: theme.palette.primary.main
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  }
}));

const TransferPage: FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [query, setQuery] = useState<string>('');
  const [queryString, setQueryString] = useState<string>('');
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [mutations, setMutations] = useState<MutationModel[]>([]);
  const [mutation, setMutation] = useState<MutationModel>(dummyMutation);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [loadDelete, setLoadDelete] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));

  const handleConfirmationDelete = (id: number): React.MouseEventHandler => () => {
    setSelectedId(id);
    setConfirmationDelete(true);
  };

  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  const handleOnUpdate = (id: number): React.MouseEventHandler => () => {
    setOpen(true);
    setUpdate(true);
    const currentMutation = mutations.find(value => value.id === id);
    if (currentMutation) {
      setMutation(currentMutation);
    }
  };

  const deleteMutation = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setLoadDelete(true);
    try {
      await axios.delete(DELETE_MUTATION_BASE_URL(selectedId));
      setMutations(prevState => prevState.filter(value => value.id !== selectedId));
    } catch (err) {
      console.log(err);
    } finally {
      setLoadDelete(false);
      setConfirmationDelete(false);
    }
  };

  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('keyword', queryString);
      }

      if (orderBy || order) {
        params.append('orderBy', orderBy);
        params.append('ordered', order);
      }

      if (startDate || endDate) {
        params.append('startDate', startDate);
        params.append('endDate', endDate);
      }

      params.append('page', currentPage.toString());

      return params.toString();
    };

    try {
      const { data } = await axios.get(`${MUTATION_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });
      setMutations(data.data);
      setCount(data.meta.last_page);
    } catch (error) {}
    setIsLoadingData(false);
    return () => {
      cancelTokenSource.cancel();
    };
  }, [queryString, orderBy, order, currentPage, startDate, endDate]);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  const handleSearch = useCallback((searchQuery: string) => {
    performActionAndRevertPage(setQueryString, searchQuery);
  }, []);

  const debouncedSearchTerm = useDebounce(query, 500);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleOpenForm = (): void => {
    setOpen(true);
  };

  const handleOnDetail = (id: number): React.MouseEventHandler => () => {
    setOpenDetail(true);

    const currentMutation = mutations.find(value => value.id === id);
    if (currentMutation) {
      setMutation(currentMutation);
    }
  };

  const handleCalendarFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenCalendarFilter(!openCalendarFilter);
  };

  return (
    <Page title='Mutasi'>
      <Container>
        <Grid container direction='row'>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1' component='h1'>
              Mutasi Produk
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>
        <PaperCustom>
          <Grid container direction='row' spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container direction='row' justify='space-between'>
                <Grid container item lg={8} sm={8} md={8} justify='flex-start' alignItems='center'>
                  <Grid item>
                    <Typography variant='h5'>Riwayat Perpindahan Barang</Typography>
                    {startDate && endDate && (
                      <Typography variant='body2' style={{ fontSize: 12, color: BLUE_PRIMARY }}>
                        Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Grid container item lg={4} sm={4} md={4} justify='flex-end' alignItems='center'>
                  <Grid item>
                    <Tooltip title='Calendar filter' placement='top'>
                      <IconButton size='small' onClick={event => handleCalendarFilterClick(event)}>
                        <CalendarIcon className={classes.calendarIcon} />
                      </IconButton>
                    </Tooltip>

                    <Button disabled={isLoadingData} onClick={handleOpenForm}>
                      <AddBox fontSize='small' /> &nbsp; Mutasi Barang
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={6} sm={12} xs={12} md={6}>
              {/* <TextField
                id='Date'
                type='date'
                label='Tanggal'
                value={date && format(date, 'yyyy-MM-dd')}
                onChange={e => setDate(new Date(e.target.value))}
                helperText={dateMessage}
                InputLabelProps={{
                  shrink: true
                }}
              /> */}
            </Grid>

            <Grid container justify='center' item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TransferTable
                isLoadingData={isLoadingData}
                transfers={mutations}
                order={order}
                orderBy={orderBy}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                handleConfirmationDelete={handleConfirmationDelete}
                handleOnUpdate={handleOnUpdate}
                handleOnDetail={handleOnDetail}
              />
            </Grid>

            <Grid container justify='center' item xl={12} md={12} sm={12}>
              <PaginationCustom
                marginTop='-.4em '
                show={mutations.length > 0}
                sxPagination={{
                  count,
                  boundaryCount: 2,
                  variant: 'outlined',
                  shape: 'rounded',
                  onChange: (event, page) => {
                    setCurrentPage(page);
                  },
                  page: currentPage
                }}
                sxPopover={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  }
                }}
                customPageProps={{
                  defaultValue: currentPage,
                  maxValue: count,
                  onSubmit(value) {
                    setCurrentPage(value);
                  }
                }}
              />
            </Grid>
          </Grid>
        </PaperCustom>

        <TransferStockModal
          open={open}
          setOpen={setOpen}
          setMutations={setMutations}
          handleSnackBar={handleSnackBar}
          mutation={mutation}
          isUpdate={isUpdate}
          setUpdate={setUpdate}
        />

        <TransferDetailModal
          open={openDetail}
          setOpen={setOpenDetail}
          setMutations={setMutations}
          handleSnackBar={handleSnackBar}
          mutation={mutation}
          setMutation={setMutation}
        />

        <DateRangeFilter
          openCalendarFilter={openCalendarFilter}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleClose={() => {
            setOpenCalendarFilter(false);
          }}
        />

        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
          onConfirm={handleCloseSnackbar}
          noCancelButton={true}
        />

        <StandardConfirmationDialog
          variant={'danger'}
          titleMessage={'Menghapus Data'}
          message={'Apakah kamu yakin akan menghapus data ini?'}
          open={confirmationDelete}
          handleClose={handleCloseConfirmationDelete}
          onConfirm={deleteMutation}
          isLoading={loadDelete}
        />
      </Container>
    </Page>
  );
};

export default TransferPage;
