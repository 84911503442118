import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import {
  Add,
  Close,
  CreateOutlined,
  DeleteForeverRounded,
  ExpandLessOutlined,
  ExpandMore,
  HighlightOff,
  InfoOutlined,
  Remove
} from '@material-ui/icons';
import useRouter from 'hooks/useRouter';
import React, { FC, useEffect, useState } from 'react';
import { PRIMARY_MOBILE, WHITE } from 'constants/colors';
import collyName from './collyName';
interface Props {
  idColly: number;
  idBagColly: number;
  open: boolean;
  nameCollyTemp: collyName[];
  collyResource: typeCollyModel[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setNameCollyTemp: React.Dispatch<React.SetStateAction<collyName[]>>;
  handleMergeColly: (remark: string) => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'inline-block',
    width: '100vh',
    position: 'relative'
  },
  titleContainer: {
    fontWeight: 500,
    fontSize: '1.2rem'
  },
  containerContent: {
    overflowX: 'hidden',
    minHeight: '25vh',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  button: {
    borderRadius: '15px',
    padding: '7px 0'
  },
  itemBody: {
    display: 'flex',
    gap: '1.4em',
    padding: '0.6em 0'
  },
  containerInputColly: {
    flex: 0.9
  },
  containerAction: {
    flex: 0.1
  },
  buttonAction: {
    background: PRIMARY_MOBILE,
    '&:hover': {
      background: '#008ABF'
    }
  },
  textFieldInput: {
    textAlign: 'center'
  },
  textStyle: {
    fontSize: '0.8rem'
  },
  wrapProductName: {
    padding: '0 1em'
  },
  containerItem: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',

    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: '0.4em 1.2em 0.4em 1.9em',
    marginLeft: '-30px',

    width: '45vh'
  },
  containerItemActive: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',

    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(2, 169, 234, 0.12)',
    position: 'absolute',
    padding: '0.4em 1.2em 0.4em 1.9em',
    marginLeft: '-30px',
    width: '45vh'
  },
  containerInput: {
    flex: 0.48
  },
  alertWarning: {
    border: '1px solid #FFBF00',
    backgroundColor: 'rgba(255, 191, 0, 0.12)',
    padding: '0.8rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.8rem',
    borderRadius: '5px'
  },

  textSecondary: {
    fontSize: '0.75rem',
    color: 'grey'
  },
  textMobile: {
    fontSize: '14px',
    fontWeight: 500
  },
  cotainerAction: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: '1rem'
  }
}));
type collyName = {
  id: number;
  name: string;
};
const CollyCombine: FC<Props> = ({ open, setOpen, collyResource, idColly, idBagColly, handleMergeColly, nameCollyTemp, setNameCollyTemp }) => {
  const classes = useStyles();
  const { history } = useRouter();

  const [remark, setRemark] = useState<string>('');
  const getColly = collyResource.find(value => value.id === idColly);
  const getBagColly = getColly && getColly.BagItem.find(value => value.id === idBagColly);

  const disabled = nameCollyTemp.some(val => val.name === '') || remark === '';

  const hasColly = (name: string) => {
    const hasName = nameCollyTemp.some(val => val.name === name);
    return hasName;
  };
  const handleCountColly = () =>
    setNameCollyTemp([
      ...nameCollyTemp,
      {
        id: nameCollyTemp[nameCollyTemp.length - 1].id + 1,
        name: ''
      }
    ]);
  const handleRemoveColly = (id: number) => setNameCollyTemp(nameCollyTemp.filter(val => val.id !== id));

  const setCollyName = (id: number, name: string) => {
    setNameCollyTemp(nameCollyTemp.map(val => (val.id === id ? { ...val, name: name } : val)));
  };

  const handleSave = () => handleMergeColly(remark);

  return (
    <Dialog open={open} style={{ maxHeight: 80 + 'vh', marginTop: '60px' }} scroll='paper' fullWidth>
      <DialogTitle>
        <Grid container xs={12} justify='space-between' alignItems='center'>
          <Typography className={classes.titleContainer}>Pindah Sebagian Item</Typography>
          <Close fontSize='small' color='action' onClick={() => setOpen(false)} />
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.containerContent}>
        <Grid xs={12} container direction='column' style={{ rowGap: '0.3em' }}>
          <Grid xs={12} className={classes.alertWarning}>
            <InfoOutlined style={{ color: '#ff9800' }} />
            <Grid item>
              <Typography variant='h6' style={{ fontSize: '0.875rem' }}>
                Tempatkan item produk ke beberapa koli
              </Typography>
              <Typography className={classes.textSecondary}>
                Barang tertentu bisa terbagi dalam 1 set quantity yang besar sehingga 1 qty bisa terbagi kolinya
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} className={classes.itemBody}>
            <Grid xs={2}>
              <img
                src={getBagColly && getBagColly.productImage}
                alt='gambar produk'
                width='60px'
                height='60px'
                style={{ borderRadius: '0.3rem', border: '1px solid rgba(0, 0, 0, 0.08)' }}
              />
            </Grid>
            <Grid xs={10}>
              <Typography className={classes.textMobile}>{getBagColly && getBagColly.productName}</Typography>
            </Grid>
          </Grid>
          <Grid container justify='space-between' alignItems='center' xs={12}>
            <Grid className={classes.containerInput}>
              <TextField label='Koli Awal' variant='outlined' value={getColly && getColly.name} disabled />
            </Grid>
            <Grid className={classes.containerInput}>
              <TextField label='Quantity' variant='outlined' value={getBagColly && getBagColly.totalItem} disabled />
            </Grid>
          </Grid>
          <Grid xs={12} container direction='column' style={{ rowGap: '0.3em' }}>
            <Grid xs={12}>
              <Typography variant='h6'>Tambahkan Additional Koli</Typography>
            </Grid>
            {nameCollyTemp.map((val, index) => (
              <Grid xs={12} container justify='space-between' alignItems='center' key={index}>
                <Grid className={classes.containerInputColly}>
                  <TextField
                    id='name'
                    size='small'
                    required
                    select
                    label='Nama Koli'
                    InputProps={{
                      classes: {
                        // input: classes.fontSizeMobile
                      }
                    }}
                    fullWidth
                    value={val.name}
                    InputLabelProps={{ style: { fontSize: '1rem' } }}
                    onChange={e => setCollyName(val.id, e.target.value)}
                  >
                    {collyName
                      .filter(val => getColly && getColly.name !== val)
                      .map((val, key) => {
                        return (
                          <MenuItem
                            key={key}
                            value={val}
                            selected={key === 0}
                            // className={classes.fontSizeMobile}
                            disabled={key === 0}
                            style={{ backgroundColor: hasColly(val) ? 'rgba(2, 169, 234, 0.12)' : '' }}
                          >
                            {val}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </Grid>
                <Grid container justify='flex-end' className={classes.containerAction}>
                  {index < nameCollyTemp.length - 1 ? (
                    <DeleteForeverRounded
                      style={{ color: 'red', position: 'relative', left: 4 }}
                      fontSize='large'
                      onClick={() => handleRemoveColly(val.id)}
                    />
                  ) : (
                    <IconButton
                      aria-label='add'
                      disableFocusRipple={false}
                      disableRipple={true}
                      component='div'
                      className={classes.buttonAction}
                      onClick={handleCountColly}
                      size='small'
                    >
                      <Add style={{ color: WHITE }} fontSize='small' />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}
            <Grid xs={12}>
              <TextField
                id='outlined-multiline-static'
                label='Keterangan'
                multiline
                rows={4}
                defaultValue={remark}
                onChange={e => setRemark(e.target.value)}
                variant='outlined'
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid xs={12} container justify='center'>
          <Grid xs={11}>
            <Button size='small' fullWidth className={classes.button} color='primary' disabled={disabled} onClick={handleSave}>
              Simpan
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CollyCombine;
