import React, { FC, useState } from 'react';
import {
  Grid,
  makeStyles,
  Theme,
  Button,
  useMediaQuery,
  useTheme,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  ButtonGroup,
  Tooltip,
  Avatar,
  Drawer,
  SwipeableDrawer
} from '@material-ui/core';
import { PaperCustom } from 'components';
import SalesOrderInfo from './components/SalesOrderInfo';
import SalesOrderList from './components/SalesOrderList';
import { MAP_URL } from 'constants/url';
import { WHITE, GREEN, RED, PRIMARY_MOBILE } from 'constants/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import RoomIcon from '@material-ui/icons/Room';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ProductRequest from './components/ProductRequest';
import EditingHistory from './components/EditingHistory';
import TypeUser from 'typings/enum/TypeUser';
import { ArrowForwardIos, Close } from '@material-ui/icons';
import ModalBack from './components/ModalBack';
import SalesOrderStatus from 'typings/enum/SalesOrderStatus';
import SAMobileListProduct from '../SAMobileListProduct';
interface Props {
  salesOrder: SalesOrderModel;
  salesOrderItem: SalesOrderItemModel[];
  company: CompanyModel;
  isLoadingData: boolean;
  value: number;
  isChange: boolean;
  userName: string;
  hasInvoice: boolean;
  collyFinal: typeCollyFinal;
  itemColly: typeColly[];
  tempCollyFinalUpdate: (id: number) => void;
  handleSubmitConvet: () => void;
  loadingConvet: boolean;
  isChangeSubmit: boolean;
  addItemAction: (value: ItemAction) => void;
  handleAcceptItem: (id: number) => void;
  historyResource: HistoryType[];
  loadingHistoryResource: boolean;
  isConvertAllowed: boolean;
  handleOpenImage: (imagePath: string) => void;
  consistenMessage: string;
  currentWareHouse: string;
  totalWarehouse: { name: string; total: number }[];
  currentCheckWarehouse: string;
  rejectOrder: ItemAction[];
  setGetIdClick: React.Dispatch<React.SetStateAction<number>>;
  showComponent: boolean;
  anchorEl: any;
  buttonGroup: React.RefObject<HTMLDivElement>;
  totalApprove: number;
  isValid: boolean;
  openTooltip: boolean;
  indexTooltip: number;
  handleRejectItem: (id: number) => void;
  isAccess: string;
  tempItemColly: (data: typeColly) => void;
  deleteColly: (id: number) => void;
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
  validationChecker: number;
  validationPicker: number;
  idSalesOrder: number;
  setHasOpenModal: React.Dispatch<React.SetStateAction<number>>;
  handleOpenTooltip: (index: number) => React.MouseEventHandler;
  handleCloseTooltip: () => void;
  handleOpenConfirmationDelete: () => void;
  handleChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  handleConfirm: (index: number) => React.MouseEventHandler;
  handleReject: (index: number) => React.MouseEventHandler;

  handleReset: () => void;
  getWareHouse: number;
  download: () => void;
  setCurrentCheckWarehouse: React.Dispatch<React.SetStateAction<string>>;
  openAlertPrice: boolean;
  indexAlertPrice: number;
  handleCloseSO: () => void;
  loadingReject: boolean;
  handleRejectSuperAdmin: (value: ItemAction) => void;
  handleCancelRejectSuperAdmin: (value: ItemAction) => void;
  collyResource: typeCollyModel[];
  handleOpenAlertPrice: (index: number) => React.MouseEventHandler;
  handleCloseAlertPrice: () => void;
  handletRequestEdit: (id: number, productId: number, totalQty: number, remark: string, remarkCode: string) => void;
  openAlertDiscount: boolean;
  indexAlertDiscount: number;
  handleOpenAlertDiscount: (index: number) => React.MouseEventHandler;
  handleCloseAlertDiscount: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  ButtonNew: {
    backgroundColor: WHITE
  },
  ButtonDelete: {
    color: WHITE,
    backgroundColor: RED
  },
  buttonDirect: {
    color: WHITE,
    backgroundColor: GREEN
  },
  space: {
    paddingTop: theme.spacing(3)
  },
  header: {
    position: 'fixed',
    top: 0,
    zIndex: 99,
    backgroundColor: WHITE
  },

  mobile: {
    [theme.breakpoints.up('md')]: {
      display: ' none'
    }
  },

  desktop: {
    [theme.breakpoints.down('md')]: {
      display: ' none'
    }
  },
  mobileContainer: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
      '& > .MuiGrid-item': {
        padding: 0
      }
    }
  },
  paperMobile: {
    padding: 0,
    marginTop: '-4px',
    marginBottom: '15px'
  },
  container: {
    backgroundColor: WHITE,
    boxShadow: '0px 4px 4px hsla(0, 0%, 0%, 0.08)',
    padding: '20px 10px',
    marginBottom: theme.spacing(3)
  },
  historyText: {
    color: PRIMARY_MOBILE,
    fontSize: '0.9rem',
    fontWeight: 500
  },
  drawerPaper: {
    borderRadius: '0.5em 0.5em 0 0',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  containerDrawer: {
    maxHeight: '70vh',
    padding: '10px 10px 20px',
    position: 'relative'
  }
}));

const Content: FC<Props> = props => {
  const {
    salesOrder,
    isLoadingData,
    company,
    salesOrderItem,
    value,
    getWareHouse,
    isChange,
    hasInvoice,
    setGetIdClick,
    collyResource,
    handleOpenImage,
    isChangeSubmit,
    collyFinal,
    handletRequestEdit,
    isConvertAllowed,
    historyResource,
    loadingHistoryResource,
    consistenMessage,
    currentWareHouse,
    tempCollyFinalUpdate,
    loadingReject,
    handleRejectSuperAdmin,
    handleCancelRejectSuperAdmin,
    showComponent,
    totalWarehouse,
    currentCheckWarehouse,
    anchorEl,
    tempItemColly,
    deleteColly,
    handleAcceptItem,
    userName,
    buttonGroup,
    validationChecker,
    validationPicker,
    totalApprove,
    addItemAction,
    isValid,
    handleSubmitConvet,
    loadingConvet,
    itemColly,
    openTooltip,
    handleRejectItem,
    handleCloseSO,
    indexTooltip,
    handleOpenTooltip,
    handleCloseTooltip,
    setCurrentCheckWarehouse,

    handleOpenConfirmationDelete,
    rejectOrder,
    isAccess,
    setOpenEdit,
    setHasOpenModal,
    handleChange,
    handleConfirm,
    handleReject,
    handleReset,
    openAlertPrice,
    indexAlertPrice,
    handleOpenAlertPrice,
    handleCloseAlertPrice,
    openAlertDiscount,
    idSalesOrder,
    indexAlertDiscount,
    handleOpenAlertDiscount,
    handleCloseAlertDiscount
  } = props;

  const classes = useStyles();

  const [isHistory, setIsHistory] = useState<boolean>(false);
  const [openBack, setOpenBack] = useState<boolean>(false);
  const handleBack = () => {
    if (isAccess === TypeUser.SALES || isAccess === TypeUser.SUPERVISOR) {
      handleCloseSO();
    } else {
      setOpenBack(true);
    }
  };
  return (
    <React.Fragment>
      <Grid container direction='row' item lg={12} sm={12} md={12} xs={12}>
        {showComponent && (
          <AppBar className={classes.header}>
            <Toolbar>
              <IconButton edge='start' aria-label='back'>
                <ArrowBackIcon onClick={handleBack} />
              </IconButton>
              <Typography variant='h6'>Detail Orderan Penjual</Typography>
            </Toolbar>
          </AppBar>
        )}

        <PaperCustom className={showComponent ? classes.paperMobile : ''}>
          <Grid container item direction='row' xs={12}>
            {!isLoadingData && (
              <Grid container justify='flex-end' item lg={12} sm={12} xl={12} md={2} xs={12} className={classes.desktop}>
                <ButtonGroup>
                  {isConvertAllowed && (
                    <Tooltip title='Lihat Lokasi'>
                      <Button
                        disabled={salesOrder.latitude === 0 || salesOrder.longitude === 0}
                        target='_blank'
                        fullWidth
                        href={MAP_URL(salesOrder.latitude || 0, salesOrder.longitude || 0)}
                        className={classes.buttonDirect}
                      >
                        <RoomIcon />
                      </Button>
                    </Tooltip>
                  )}

                  {isConvertAllowed && salesOrderItem.every(value => value.status !== SalesOrderStatus.HAS_INVOICE) && (
                    <Tooltip title='Hapus SO'>
                      <Button fullWidth onClick={handleOpenConfirmationDelete} className={classes.ButtonDelete}>
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                  )}
                </ButtonGroup>
              </Grid>
            )}
          </Grid>

          <Grid
            container
            direction='row'
            spacing={2}
            item
            lg={12}
            sm={12}
            md={12}
            xs={12}
            classes={{
              root: showComponent ? classes.mobileContainer : undefined
            }}
          >
            {showComponent && TypeUser.SUPERADMIN === isAccess && (
              <Grid container justify='flex-end' item lg={12} sm={12} xl={12} md={2} xs={12} style={{ padding: '.8em' }}>
                <ButtonGroup>
                  {isConvertAllowed && (
                    <Tooltip title='Lihat Lokasi'>
                      <Button
                        disabled={salesOrder.latitude === 0 || salesOrder.longitude === 0}
                        target='_blank'
                        fullWidth
                        href={MAP_URL(salesOrder.latitude || 0, salesOrder.longitude || 0)}
                        className={classes.buttonDirect}
                      >
                        <RoomIcon />
                      </Button>
                    </Tooltip>
                  )}

                  {isConvertAllowed && salesOrderItem.every(value => value.status !== SalesOrderStatus.HAS_INVOICE) && (
                    <Tooltip title='Hapus SO'>
                      <Button fullWidth onClick={handleOpenConfirmationDelete} className={classes.ButtonDelete}>
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                  )}
                </ButtonGroup>
              </Grid>
            )}
            <SalesOrderInfo salesOrder={salesOrder} isLoadingData={isLoadingData} company={company} showComponent={showComponent} />

            <SalesOrderList
              isConvertAllowed={isConvertAllowed}
              salesOrderItem={salesOrderItem}
              salesOrder={salesOrder}
              userName={userName}
              collyFinal={collyFinal}
              handleSubmitConvet={handleSubmitConvet}
              loadingConvet={loadingConvet}
              loadingReject={loadingReject}
              handleRejectSuperAdmin={handleRejectSuperAdmin}
              handleCancelRejectSuperAdmin={handleCancelRejectSuperAdmin}
              collyResource={collyResource}
              handletRequestEdit={handletRequestEdit}
              setGetIdClick={setGetIdClick}
              handleOpenImage={handleOpenImage}
              historyResource={historyResource}
              validationChecker={validationChecker}
              validationPicker={validationPicker}
              loadingHistoryResource={loadingHistoryResource}
              company={company}
              rejectOrder={rejectOrder}
              itemColly={itemColly}
              handleRejectItem={handleRejectItem}
              isAccess={isAccess}
              tempCollyFinalUpdate={tempCollyFinalUpdate}
              tempItemColly={tempItemColly}
              deleteColly={deleteColly}
              addItemAction={addItemAction}
              setHasOpenModal={setHasOpenModal}
              setOpenEdit={setOpenEdit}
              value={value}
              isLoadingData={isLoadingData}
              isChange={isChange}
              hasInvoice={hasInvoice}
              isChangeSubmit={isChangeSubmit}
              consistenMessage={consistenMessage}
              currentWareHouse={currentWareHouse}
              totalWarehouse={totalWarehouse}
              anchorEl={anchorEl}
              buttonGroup={buttonGroup}
              handleAcceptItem={handleAcceptItem}
              totalApprove={totalApprove}
              isValid={isValid}
              showComponent={showComponent}
              handleChange={handleChange}
              handleConfirm={handleConfirm}
              handleReject={handleReject}
              handleReset={handleReset}
              currentCheckWarehouse={currentCheckWarehouse}
              setCurrentCheckWarehouse={setCurrentCheckWarehouse}
              indexTooltip={indexTooltip}
              openTooltip={openTooltip}
              handleOpenTooltip={handleOpenTooltip}
              handleCloseTooltip={handleCloseTooltip}
              openAlertPrice={openAlertPrice}
              indexAlertPrice={indexAlertPrice}
              handleOpenAlertPrice={handleOpenAlertPrice}
              handleCloseAlertPrice={handleCloseAlertPrice}
              openAlertDiscount={openAlertDiscount}
              indexAlertDiscount={indexAlertDiscount}
              handleOpenAlertDiscount={handleOpenAlertDiscount}
              handleCloseAlertDiscount={handleCloseAlertDiscount}
            />

            {showComponent && TypeUser.SUPERADMIN === isAccess && (
              <SAMobileListProduct
                salesOrderItem={salesOrderItem}
                salesOrder={salesOrder}
                loadingReject={loadingReject}
                handleRejectSuperAdmin={handleRejectSuperAdmin}
                handleCancelRejectSuperAdmin={handleCancelRejectSuperAdmin}
                setGetIdClick={setGetIdClick}
                handleOpenImage={handleOpenImage}
                historyResource={historyResource}
                loadingHistoryResource={loadingHistoryResource}
                isAccess={isAccess}
                setHasOpenModal={setHasOpenModal}
                setOpenEdit={setOpenEdit}
                value={value}
                isLoadingData={isLoadingData}
                totalWarehouse={totalWarehouse}
                anchorEl={anchorEl}
                buttonGroup={buttonGroup}
                isValid={isValid}
                showComponent={showComponent}
                handleChange={handleChange}
                currentCheckWarehouse={currentCheckWarehouse}
                setCurrentCheckWarehouse={setCurrentCheckWarehouse}
              />
            )}
          </Grid>
        </PaperCustom>

        <Drawer
          anchor='bottom'
          open={isHistory}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <Grid xs={12} className={classes.containerDrawer}>
            <Grid xs={12} container justify='space-between' style={{ padding: '8px 0' }}>
              <Typography variant='h6'>Riwayat Sales Order</Typography>
              <Close fontSize='small' onClick={() => setIsHistory(false)} />
            </Grid>
            <EditingHistory isAccess={isAccess} showComponent={showComponent} historyResource={historyResource} isLoading={loadingHistoryResource} />
            <Grid style={{ minHeight: '1vh' }} />
          </Grid>
        </Drawer>

        {showComponent && isAccess !== TypeUser.SUPERADMIN && (
          <React.Fragment>
            {(isAccess === TypeUser.ADMIN01 ||
              isAccess === TypeUser.ADMIN02 ||
              isAccess === TypeUser.ADMIN02PLUS ||
              isAccess === TypeUser.ADMIN03) && (
              <Grid xs={12} container justify='space-between' alignItems='center' className={classes.container}>
                <Typography variant='h6'>Riwayat Sales Order</Typography>
                <Grid container xs={6} justify='flex-end' alignItems='center' style={{ gap: '0.3rem' }} onClick={() => setIsHistory(true)}>
                  <Typography className={classes.historyText}>Lihat Riwayat</Typography>
                  <ArrowForwardIos className={classes.historyText} />
                </Grid>
              </Grid>
            )}

            <PaperCustom className={showComponent ? classes.paperMobile : ''} style={{ overflow: 'visible' }}>
              <Grid container item lg={12} sm={12} md={12} xs={12}>
                <ProductRequest
                  isConvertAllowed={isConvertAllowed}
                  salesOrderItem={salesOrderItem}
                  salesOrder={salesOrder}
                  tempItemColly={tempItemColly}
                  deleteColly={deleteColly}
                  setHasOpenModal={setHasOpenModal}
                  idSalesOrder={idSalesOrder}
                  getWareHouse={getWareHouse}
                  handleOpenImage={handleOpenImage}
                  userName={userName}
                  tempCollyFinalUpdate={tempCollyFinalUpdate}
                  handleRejectItem={handleRejectItem}
                  setOpenEdit={setOpenEdit}
                  company={company}
                  isAccess={isAccess}
                  collyFinal={collyFinal}
                  handleAcceptItem={handleAcceptItem}
                  rejectOrder={rejectOrder}
                  addItemAction={addItemAction}
                  showComponent={showComponent}
                  value={value}
                  handletRequestEdit={handletRequestEdit}
                  isLoadingData={isLoadingData}
                  setGetIdClick={setGetIdClick}
                  isChange={isChange}
                  hasInvoice={hasInvoice}
                  isChangeSubmit={isChangeSubmit}
                  consistenMessage={consistenMessage}
                  currentWareHouse={currentWareHouse}
                  totalWarehouse={totalWarehouse}
                  anchorEl={anchorEl}
                  buttonGroup={buttonGroup}
                  totalApprove={totalApprove}
                  isValid={isValid}
                  handleChange={handleChange}
                  handleConfirm={handleConfirm}
                  handleReject={handleReject}
                  handleReset={handleReset}
                  currentCheckWarehouse={currentCheckWarehouse}
                  setCurrentCheckWarehouse={setCurrentCheckWarehouse}
                  indexTooltip={indexTooltip}
                  openTooltip={openTooltip}
                  handleOpenTooltip={handleOpenTooltip}
                  handleCloseTooltip={handleCloseTooltip}
                  openAlertPrice={openAlertPrice}
                  indexAlertPrice={indexAlertPrice}
                  handleOpenAlertPrice={handleOpenAlertPrice}
                  handleCloseAlertPrice={handleCloseAlertPrice}
                  openAlertDiscount={openAlertDiscount}
                  indexAlertDiscount={indexAlertDiscount}
                  handleOpenAlertDiscount={handleOpenAlertDiscount}
                  handleCloseAlertDiscount={handleCloseAlertDiscount}
                />
              </Grid>
            </PaperCustom>
          </React.Fragment>
        )}

        <ModalBack
          open={openBack}
          setOpen={setOpenBack}
          message='Jika kamu tekan keluar, maka semua perubahan yang kamu lakukan tidak akan tersimpan'
          textOkButton='Keluar'
          handleClick={handleCloseSO}
        />
      </Grid>
    </React.Fragment>
  );
};

export default Content;
