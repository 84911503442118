import React, { FC, Fragment, useState } from 'react';
import {
  Grid,
  makeStyles,
  Theme,
  Typography,
  TableContainer,
  Tabs,
  Tab,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  ButtonGroup,
  CircularProgress,
  FormHelperText,
  Checkbox,
  Popper,
  Tooltip,
  Divider
} from '@material-ui/core';
import ListProduct from './ListProduct';
import { Page, Breadcrumb, FormPinDialog } from 'components';
import Skeleton from '@material-ui/lab/Skeleton';
import NumberFormat from 'react-number-format';
import { BLACK, RED, WHITE, GREEN, PACKING_STATUS_MOBILE, PREINVOICE_STATUS_MOBILE, PRIMARY_MOBILE, YELLOW_MOBILE } from 'constants/colors';
import { TabPanel, a11yProps, StandardConfirmationDialog, PaperCustom } from 'components';
import { CHANGE_STATUS_SALES_ORDER_ITEM, SET_CONFIRM_PLAFON, SET_CONFIRM_SO } from 'constants/url';
import ItemStatus from 'typings/enum/ItemStatus';
import clx from 'clsx';
import PartnerModal from './PartnerModal';
import useConfirmationPin from 'hooks/useConfirmationPin';
import { dummyPartner } from 'utils/dummy';
import axios from 'axios';
import TypeUser from 'typings/enum/TypeUser';
import EditingHistory from './EditingHistory';
import KoliItem from './ListProduct/components/KoliItem';
import SalesOrderStatus from 'typings/enum/SalesOrderStatus';
import ModalConvert from './ModalConvert';
import { remarkCode } from 'constants/remarkCode';
import { WarningRounded } from '@material-ui/icons';

interface Props {
  salesOrder: SalesOrderModel;
  company: CompanyModel;
  isLoadingData: boolean;
  salesOrderItem: SalesOrderItemModel[];
  value: number;
  rejectOrder: ItemAction[];
  userName: string;
  collyFinal: typeCollyFinal;
  itemColly: typeColly[];
  handleSubmitConvet: () => void;
  handleOpenImage: (imagePath: string) => void;
  validationChecker: number;
  validationPicker: number;
  loadingConvet: boolean;
  isAccess: string;
  isChange: boolean;
  hasInvoice: boolean;
  isChangeSubmit: boolean;
  historyResource: HistoryType[];
  setGetIdClick: React.Dispatch<React.SetStateAction<number>>;
  collyResource: typeCollyModel[];
  showComponent: boolean;
  isConvertAllowed: boolean;
  consistenMessage: string;
  currentWareHouse: string;
  totalWarehouse: { name: string; total: number }[];
  currentCheckWarehouse: string;
  anchorEl: any;
  buttonGroup: React.RefObject<HTMLDivElement>;
  totalApprove: number;
  setCurrentCheckWarehouse: React.Dispatch<React.SetStateAction<string>>;
  isValid: boolean;
  indexTooltip: number;
  tempItemColly: (data: typeColly) => void;
  deleteColly: (id: number) => void;
  openTooltip: boolean;
  addItemAction: (value: ItemAction) => void;
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setHasOpenModal: React.Dispatch<React.SetStateAction<number>>;
  handleOpenTooltip: (index: number) => React.MouseEventHandler;
  handleAcceptItem: (id: number) => void;
  loadingReject: boolean;
  handleRejectSuperAdmin: (value: ItemAction) => void;
  handleCancelRejectSuperAdmin: (value: ItemAction) => void;
  handleCloseTooltip: () => void;
  tempCollyFinalUpdate: (id: number) => void;
  handletRequestEdit: (id: number, productId: number, totalQty: number, remark: string, remarkCode: string) => void;
  handleChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  handleConfirm: (index: number) => React.MouseEventHandler;
  handleReject: (index: number) => React.MouseEventHandler;

  handleReset: () => void;
  openAlertPrice: boolean;

  loadingHistoryResource: boolean;
  indexAlertPrice: number;
  handleOpenAlertPrice: (index: number) => React.MouseEventHandler;
  handleCloseAlertPrice: () => void;
  handleRejectItem: (id: number) => void;
  openAlertDiscount: boolean;
  indexAlertDiscount: number;
  handleOpenAlertDiscount: (index: number) => React.MouseEventHandler;
  handleCloseAlertDiscount: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  cellSummaryRight: {
    border: 'none',
    padding: 4,
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontStyle: 'normal',
    color: BLACK
  },
  cellSummaryLeft: {
    width: '26%',
    border: 'none',
    padding: 4,
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal',
    color: BLACK
  },
  cellInfoRight: {
    padding: '2px 0px 2px 2px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  cellInfoLeft: {
    padding: '2px 0px 2px 0px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  cellFinalRight: {},
  cellFinalLeft: {},
  spaceTop: {
    marginTop: 10
  },
  cellFinalText: {
    marginRight: 105,
    marginTop: 10,
    paddingTop: 10
  },
  cellFinalRightText: {
    marginRight: 65,
    marginBottom: 10,
    paddingBottom: 10
  },
  paper: {
    border: '1px solid #ccc',
    padding: 2,
    backgroundColor: WHITE,
    marginTop: 4,
    borderRadius: 8
  },
  bigger: {
    backgroundColor: '#ffb400',
    color: WHITE,
    transform: 'scale(1.5) perspective(1px)'
  },
  cellOrderRightUnderline: {
    padding: '2px 0px 2px 2px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal',
    color: BLACK,
    textDecorationLine: 'underline',
    cursor: 'pointer'
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    borderRadius: '20px',
    '&:hover': {
      backgroundColor: GREEN
    }
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: ' none'
    }
  },

  desktop: {
    [theme.breakpoints.down('md')]: {
      display: ' none'
    }
  },
  fontMobile: {
    fontSize: 0.875 + 'rem',
    lineHeight: 1.2 + 'rem'
  },
  titleFontSize: {
    fontSize: 1 + 'rem',
    lineHeight: 1.5 + 'rem'
  },
  gridContainer: {
    [theme.breakpoints.down('md')]: {
      margin: 10 + 'px'
    }
  },

  warningText: {
    color: WHITE,
    fontSize: '0.9rem',

    background: '#EFA101',
    borderRadius: '7px',
    display: 'flex',
    gap: '0.7em',
    alignItem: 'center',
    padding: theme.spacing(2)
  },
  fontStyleBoldWDesktop: {
    fontWeight: 500
  },
  fontStyleDesktop: {
    fontSize: '0.9rem'
  },
  containerTitle: {
    padding: '0 10px'
  },
  containerPosition: {
    margin: '1em 0 1em'
  },
  containerTab: {
    height: '10vh',
    [theme.breakpoints.down('md')]: {
      display: ' none'
    }
  },
  tabsItem: {
    backgroundColor: ' #F7F9FC',
    position: 'absolute',
    width: '158vh',
    marginLeft: '-1.1em',
    '&& .Mui-selected': {
      backgroundColor: PRIMARY_MOBILE,
      color: WHITE
    }
  }
}));

const SalesOrderList: FC<Props> = props => {
  const classes = useStyles();
  const {
    salesOrder,
    isLoadingData,
    userName,
    company,
    salesOrderItem,
    value,
    handleSubmitConvet,
    loadingConvet,
    loadingReject,
    rejectOrder,
    addItemAction,
    setGetIdClick,
    handleCancelRejectSuperAdmin,
    handletRequestEdit,
    collyResource,
    historyResource,
    isAccess,
    setOpenEdit,
    collyFinal,
    itemColly,
    setHasOpenModal,
    isChange,
    handleOpenImage,
    hasInvoice,
    handleAcceptItem,
    tempItemColly,
    deleteColly,
    validationChecker,
    validationPicker,
    loadingHistoryResource,
    isChangeSubmit,
    isConvertAllowed,
    consistenMessage,
    currentWareHouse,
    totalWarehouse,
    currentCheckWarehouse,
    anchorEl,
    buttonGroup,
    handleRejectItem,
    showComponent,
    totalApprove,
    tempCollyFinalUpdate,
    isValid,
    indexTooltip,
    openTooltip,
    handleOpenTooltip,
    handleRejectSuperAdmin,
    handleCloseTooltip,
    setCurrentCheckWarehouse,

    handleChange,
    handleConfirm,
    handleReject,
    handleReset,
    openAlertPrice,
    indexAlertPrice,
    handleOpenAlertPrice,
    handleCloseAlertPrice,
    openAlertDiscount,
    indexAlertDiscount,
    handleOpenAlertDiscount,
    handleCloseAlertDiscount
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [isTab, setIsTab] = useState<number>(0);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [loadingConfirmation, setLoadingConfirmation] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [openModalSuccess, setOpenModalSuccess] = useState<boolean>(false);
  const [pin, setPin] = useState<number | null>(null);
  const [isComplete, setComplete] = useState<boolean>(false);

  console.log('isAccess -->', isAccess);

  const pinMessage = useConfirmationPin({
    pin,
    isCompletePin: isComplete,
    handlePlafon: () => confirmationSO(),
    confirmationPlafon: true,
    setLoading: setLoading,
    setOpenConfirmation: setOpenConfirmation,
    setCompletePin: setComplete,
    setPin: setPin
  });

  const handleCheck = () => {
    if (!salesOrder.isAccept && salesOrder.isPlafon) {
      setOpenConfirmation(true);
    } else if (salesOrder.isPlafon) {
      setOpenConfirmation(true);
    } else {
      confirmationSO();
    }
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
    setPin(null);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleConfirmationSnackbar = () => {
    setOpenSnackbar(false);
    setOpen(false);
  };

  const handleOnClose = () => {
    setOpen(false);
  };
  const handleTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setIsTab(newValue);
  };

  const getDataFilter = () => {
    if (isAccess === TypeUser.PICKER) {
      return SalesOrderStatus.PREVIEW;
    } else if (isAccess === TypeUser.ADMIN01) {
      return SalesOrderStatus.PACKING;
    } else if (isAccess === TypeUser.SALES || isAccess === TypeUser.SUPERADMIN) {
      return SalesOrderStatus.HAS_INVOICE;
    } else {
      return '';
    }
  };

  const getStatusItem = () => {
    if (isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS) {
      const stats = salesOrderItem.every(value => value.status === SalesOrderStatus.HAS_INVOICE);

      if (!stats) return;
      return (
        <Typography className={classes.fontMobile} style={{ color: YELLOW_MOBILE }}>
          Buat Invoice
        </Typography>
      );
    }
  };

  const getFilterData = (value: string) => {
    if (isAccess === TypeUser.PICKER) {
      return value === SalesOrderStatus.PENDING || value === SalesOrderStatus.PREVIEW || value === SalesOrderStatus.REJECT;
    } else if (isAccess === TypeUser.ADMIN01) {
      return value === SalesOrderStatus.PACKING || value === SalesOrderStatus.PREVIEW || value === SalesOrderStatus.REJECT;
    } else if (isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS) {
      return (
        value === SalesOrderStatus.PACKING ||
        value === SalesOrderStatus.CHECKING ||
        value === SalesOrderStatus.CONFIRM ||
        value === SalesOrderStatus.HAS_INVOICE
      );
    } else {
      return (
        SalesOrderStatus.PENDING ||
        SalesOrderStatus.CHECKING ||
        SalesOrderStatus.CONFIRM ||
        SalesOrderStatus.PACKING ||
        SalesOrderStatus.PREVIEW ||
        SalesOrderStatus.REJECT
      );
    }
  };

  const fetchData = () => {
    if (isAccess === TypeUser.SALES || isAccess === TypeUser.SUPERVISOR || isAccess === TypeUser.SUPERADMIN) {
      const data = currentCheckWarehouse
        ? salesOrderItem.filter(value => value.warehouse && value.warehouse === currentCheckWarehouse && getFilterData(value.status))
        : salesOrderItem.filter(value => getFilterData(value.status));
      return data;
    } else {
      const data = salesOrderItem.filter(value => value.warehouse && value.warehouse === currentCheckWarehouse && getFilterData(value.status));
      return data;
    }
  };

  const sumSubtotalPrice = salesOrderItem
    .filter(val => val.status !== SalesOrderStatus.REJECT)
    .map(value => value.subTotalPrice)
    .reduce((total, price) => total + price, 0);
  const sumDiscount = salesOrderItem
    .filter(val => val.status !== SalesOrderStatus.REJECT)
    .map(value => value.discount)
    .reduce((total, price) => total + price, 0);
  const sumTotalPrice = salesOrderItem
    .filter(val => val.status !== SalesOrderStatus.REJECT)
    .map(value => value.totalPrice)
    .reduce((total, price) => total + price, 0);

  const confirmationSO = async () => {
    setLoadingConfirmation(true);
    try {
      await axios.get(SET_CONFIRM_SO(salesOrder.id));
      await axios.get(SET_CONFIRM_PLAFON(salesOrder.id));

      setLoadingConfirmation(false);
      setOpenModalSuccess(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      {showComponent && (
        <Grid item lg={6} sm={12} md={6} xs={12}>
          <Divider className={classes.mobile} style={{ marginBottom: `10px` }} />
        </Grid>
      )}

      <Grid item lg={6} sm={12} md={6} xs={12} className={classes.gridContainer}>
        {!showComponent ? (
          <Typography variant='h5' align='left'>
            Nama Perusahaan
          </Typography>
        ) : (
          <Typography className={classes.titleFontSize} style={{ fontWeight: 'bold', paddingBottom: '0.4em' }}>
            Permintaan Dari
          </Typography>
        )}
        <TableContainer>
          <Table>
            {!showComponent ? (
              <TableBody>
                <TableRow>
                  <TableCell align='left' style={{ fontWeight: 500, color: BLACK }} className={classes.cellInfoLeft}>
                    {isLoadingData ? <Skeleton variant='text' width='100%' /> : company.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='left' className={classes.cellInfoLeft}>
                    {isLoadingData ? <Skeleton variant='text' width='100%' /> : company.address || '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='left' className={classes.cellInfoLeft}>
                    {isLoadingData ? <Skeleton variant='text' width='100%' /> : 'Telp: ' + company.phoneNumber}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='left' className={classes.cellInfoLeft}>
                    {isLoadingData ? <Skeleton variant='text' width='100%' /> : 'No Hp: ' + (company.cellPhoneNumber || '-')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='left' className={classes.cellInfoLeft}>
                    {isLoadingData ? <Skeleton variant='text' width='100%' /> : 'Email: ' + (company.email || '-')}
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell width={'47%'} align='left' className={classes.cellInfoLeft} style={{ verticalAlign: 'top' }}>
                    <Typography className={classes.fontMobile}>Nama Mitra</Typography>
                  </TableCell>
                  <TableCell width={'53%'} align='left' className={classes.cellInfoLeft}>
                    {isLoadingData ? (
                      <Skeleton variant='text' width='100%' />
                    ) : (
                      <Typography
                        className={classes.fontMobile}
                        onClick={() => setOpen(true)}
                        color='primary'
                        style={{ fontWeight: 500, cursor: 'pointer' }}
                      >
                        {salesOrder.partnerName}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align='left' className={classes.cellInfoLeft}>
                    <Typography className={classes.fontMobile}>Email</Typography>
                  </TableCell>
                  <TableCell align='left' className={classes.cellInfoLeft}>
                    {isLoadingData ? (
                      <Skeleton variant='text' width='100%' />
                    ) : (
                      <Typography className={classes.fontMobile}>{salesOrder.Partner!.email || '-'}</Typography>
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align='left' className={classes.cellInfoLeft}>
                    <Typography className={classes.fontMobile}>No. Telp</Typography>
                  </TableCell>
                  <TableCell align='left' className={classes.cellInfoLeft}>
                    {isLoadingData ? (
                      <Skeleton variant='text' width='100%' />
                    ) : (
                      <Typography className={classes.fontMobile}>{salesOrder.Partner!.phoneNumber || '-'} </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='left' className={classes.cellInfoLeft}>
                    <Typography className={classes.fontMobile}>No. Hp</Typography>
                  </TableCell>
                  <TableCell align='left' className={classes.cellInfoLeft}>
                    {isLoadingData ? (
                      <Skeleton variant='text' width='100%' />
                    ) : (
                      <Typography className={classes.fontMobile}>{salesOrder.Partner!.cellPhoneNumber || '-'}</Typography>
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align='left' className={classes.cellInfoLeft}>
                    <Typography className={classes.fontMobile}>Alamat</Typography>
                  </TableCell>
                  <TableCell align='left' className={classes.cellInfoLeft}>
                    {isLoadingData ? (
                      <Skeleton variant='text' width='100%' />
                    ) : (
                      <Typography className={classes.fontMobile}>{salesOrder.Partner!.address || '-'}</Typography>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid>

      <Grid item lg={6} sm={12} md={6} xs={12} className={classes.desktop}>
        <Typography variant='h5' align='right'>
          Order Penjualan dari
        </Typography>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  align='right'
                  style={{ fontWeight: 500, color: BLACK }}
                  className={classes.cellOrderRightUnderline}
                  onClick={() => setOpen(true)}
                >
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : salesOrder.partnerName ? salesOrder.partnerName : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='right' className={classes.cellInfoRight}>
                  {isLoadingData ? (
                    <Skeleton variant='text' width='100%' />
                  ) : salesOrder.Partner ? (
                    `${salesOrder.Partner.address} ${salesOrder.Partner.remarkAddress || '-'}`
                  ) : (
                    ''
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='right' className={classes.cellInfoRight}>
                  {isLoadingData ? (
                    <Skeleton variant='text' width='100%' />
                  ) : salesOrder.Partner ? (
                    'Telp: ' + (salesOrder.Partner.phoneNumber || '-')
                  ) : (
                    '-'
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='right' className={classes.cellInfoRight}>
                  {isLoadingData ? (
                    <Skeleton variant='text' width='100%' />
                  ) : salesOrder.Partner ? (
                    'No Hp: ' + (salesOrder.Partner.cellPhoneNumber || '-')
                  ) : (
                    ''
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='right' className={classes.cellInfoRight}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : salesOrder.Partner ? 'Email: ' + (salesOrder.Partner.email || '-') : ''}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid container item lg={12} sm={12} md={12} xs={12} justify='space-between' className={classes.desktop}>
        <Grid item lg={6} sm={6} md={6} xs={6}>
          {isAccess === TypeUser.SUPERADMIN && !isLoadingData && (!salesOrder.isAccept || salesOrder.isPlafon) && (
            <Grid container alignContent='center'>
              <Typography className={classes.warningText}>
                <WarningRounded style={{ color: WHITE, fontSize: '1.4rem' }} />
                {!salesOrder.isAccept && salesOrder.isPlafon
                  ? 'Mohon konfirmasi discount produk dan plafon mitra ini'
                  : !salesOrder.isAccept
                  ? 'Mohon konfirmasi discount produk mitra ini'
                  : salesOrder.isPlafon && 'Mohon konfirmasi plafon mitra ini'}
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid item lg={6} sm={6} md={6} xs={6} container justify='flex-end'>
          {isAccess === TypeUser.SALES || isAccess === TypeUser.SUPERVISOR || isAccess === TypeUser.SUPERADMIN ? (
            <ButtonGroup disabled={isValid} ref={buttonGroup} size='small'>
              {totalWarehouse.map((value, index) => (
                <Button
                  key={index}
                  size='small'
                  onClick={e =>
                    setCurrentCheckWarehouse(prevState => (prevState !== value.name ? value.name : prevState === value.name ? '' : value.name))
                  }
                >
                  <Checkbox value={value.name} checked={currentCheckWarehouse === value.name} size='small' />
                  {`${value.name} (${value.total})`}
                </Button>
              ))}
            </ButtonGroup>
          ) : (
            <ButtonGroup disabled={isValid} ref={buttonGroup} size='small'>
              {totalWarehouse.map((value, index) => (
                <Button
                  key={index}
                  size='small'
                  onClick={e =>
                    setCurrentCheckWarehouse(prevState => (prevState !== value.name ? value.name : prevState === value.name ? '' : value.name))
                  }
                >
                  <Checkbox value={value.name} checked={index === 0} size='small' />
                  {`${value.name} (${value.total})`}
                </Button>
              ))}
            </ButtonGroup>
          )}
          <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} className={classes.paper}>
            <Typography style={{ padding: 8, color: RED }}>Pilih Salah satu gudang untuk membuat Invoice</Typography>
          </Popper>
        </Grid>

        {isAccess === TypeUser.ADMIN01 && (
          <Grid container lg={12} sm={12} md={12} xs={12} className={classes.containerTitle}>
            <Grid xs={6} item>
              <Typography className={(classes.fontStyleDesktop, classes.fontStyleBoldWDesktop)}>Daftar Permintaan Produk</Typography>
              <Typography color='primary' className={classes.fontStyleDesktop}>
                Peninjauan
              </Typography>
            </Grid>
            <Grid xs={6} container direction='column' justify='flex-end'>
              <Typography align='right' className={(classes.fontStyleDesktop, classes.fontStyleBoldWDesktop)}>
                Disiapkan Oleh
              </Typography>
              <Typography align='right' color='primary' className={classes.fontStyleDesktop}>
                Gudang Man 01
              </Typography>
            </Grid>
          </Grid>
        )}

        {(isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS) && (
          <Grid container lg={12} sm={12} md={12} xs={12} className={classes.containerTitle}>
            <Grid xs={6} item>
              <Typography className={(classes.fontStyleDesktop, classes.fontStyleBoldWDesktop)}>Daftar Permintaan Produk</Typography>
              <Typography className={classes.fontStyleDesktop} style={{ color: PACKING_STATUS_MOBILE }}>
                Packing
              </Typography>
              {getStatusItem()}
            </Grid>
            <Grid xs={3} container direction='column' justify='flex-end'>
              <Typography align='right' className={(classes.fontStyleDesktop, classes.fontStyleBoldWDesktop)}>
                Disiapkan Oleh
              </Typography>

              <Typography align='right' color='primary' className={classes.fontStyleDesktop}>
                Gudang Man 01
              </Typography>
              <Typography align='right' className={classes.fontStyleDesktop} style={{ color: 'lightgray' }}>
                30-07-2021 15:27:04
              </Typography>
            </Grid>
            <Grid xs={3} container direction='column' justify='flex-end'>
              <Typography align='right' className={(classes.fontStyleDesktop, classes.fontStyleBoldWDesktop)}>
                Dikonfirmasi Oleh
              </Typography>

              <Typography align='right' color='primary' className={classes.fontStyleDesktop}>
                Admin 01
              </Typography>
              <Typography align='right' className={classes.fontStyleDesktop} style={{ color: 'lightgray' }}>
                30-07-2021 15:27:04
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid xs={12} className={classes.containerTab}>
        <Grid xs={12} className={classes.tabsItem}>
          <Tabs value={isTab} textColor='primary' onChange={handleTab} variant='scrollable' scrollButtons='auto'>
            <Tab label={'Daftar Permintaan Produk'} {...a11yProps(0)} />
            <Tab label={'Riwayat Aksi'} {...a11yProps(1)} />
          </Tabs>
        </Grid>
      </Grid>
      <Grid xs={12} className={classes.desktop}>
        <TabPanel value={isTab} index={0}>
          <Grid item lg={12} sm={12} md={12} xs={12} className={classes.desktop}>
            {isAccess !== TypeUser.ADMIN02 && isAccess !== TypeUser.ADMIN02PLUS && isAccess !== TypeUser.ADMIN03 && isAccess !== TypeUser.SUPERADMIN && (
              <Tabs
                value={value}
                indicatorColor='primary'
                textColor='inherit'
                onChange={handleChange}
                variant='scrollable'
                scrollButtons='auto'
                style={{ borderBottom: '1px solid #E0E0E0' }}
              >
                <Tab label={`Semua Produk (${salesOrderItem.filter(value => getFilterData(value.status)).length})`} {...a11yProps(0)} />
                <Tab label={`Disetujui (${salesOrderItem.filter(value => value.status === getDataFilter().toString()).length})`} {...a11yProps(1)} />
                <Tab label={`Ditolak (${salesOrderItem.filter(value => value.status === ItemStatus.REJECT).length})`} {...a11yProps(2)} />
              </Tabs>
            )}

            {(isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS) && (
              <Tabs
                value={value}
                indicatorColor='primary'
                textColor='inherit'
                onChange={handleChange}
                variant='scrollable'
                scrollButtons='auto'
                style={{ borderBottom: '1px solid #E0E0E0' }}
              >
                <Tab label={`Semua Produk (${salesOrderItem.filter(value => getFilterData(value.status)).length})`} {...a11yProps(0)} />
                <Tab label={`Koli(${collyResource.length})`} {...a11yProps(1)} />
              </Tabs>
            )}

            {isAccess === TypeUser.SUPERADMIN && (
              <Tabs
                value={value}
                indicatorColor='primary'
                textColor='inherit'
                onChange={handleChange}
                variant='scrollable'
                scrollButtons='auto'
                style={{ borderBottom: '1px solid #E0E0E0' }}
              >
                <Tab label={`Semua Produk (${salesOrderItem.filter(value => getFilterData(value.status)).length})`} {...a11yProps(0)} />
                <Tab
                  label={`Ditolak (${salesOrderItem.filter(value => value.status === ItemStatus.REJECT && value.rejectBy === userName).length})`}
                />
              </Tabs>
            )}

            <TabPanel value={value} index={0}>
              <ListProduct
                key={1}
                tab={value}
                showComponent={showComponent}
                setHasOpenModal={setHasOpenModal}
                handletRequestEdit={handletRequestEdit}
                tempItemColly={tempItemColly}
                deleteColly={deleteColly}
                addItemAction={addItemAction}
                handleOpenImage={handleOpenImage}
                collyFinal={collyFinal}
                setOpenEdit={setOpenEdit}
                handleRejectSuperAdmin={handleRejectSuperAdmin}
                rejectOrder={rejectOrder}
                handleRejectItem={handleRejectItem}
                isAccess={isAccess}
                loadingReject={loadingReject}
                handleCancelRejectSuperAdmin={handleCancelRejectSuperAdmin}
                setGetIdClick={setGetIdClick}
                handleConfirm={handleConfirm}
                handleReject={handleReject}
                isLoadingData={isLoadingData}
                salesOrderItem={fetchData()}
                hasInvoice={hasInvoice}
                isConvertAllowed={isConvertAllowed}
                salesOrder={salesOrder}
                currentWareHouse={currentWareHouse}
                indexTooltip={indexTooltip}
                openTooltip={openTooltip}
                handleAcceptItem={handleAcceptItem}
                handleOpenTooltip={handleOpenTooltip}
                handleCloseTooltip={handleCloseTooltip}
                openAlertPrice={openAlertPrice}
                indexAlertPrice={indexAlertPrice}
                handleOpenAlertPrice={handleOpenAlertPrice}
                handleCloseAlertPrice={handleCloseAlertPrice}
                openAlertDiscount={openAlertDiscount}
                indexAlertDiscount={indexAlertDiscount}
                handleOpenAlertDiscount={handleOpenAlertDiscount}
                handleCloseAlertDiscount={handleCloseAlertDiscount}
              />
            </TabPanel>

            {isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS ? (
              <TabPanel value={value} index={1}>
                <KoliItem
                  showComponent={showComponent}
                  setGetIdClick={setGetIdClick}
                  collyResource={collyResource}
                  setHasOpenModal={setHasOpenModal}
                  setOpenEdit={setOpenEdit}
                  tempCollyFinalUpdate={tempCollyFinalUpdate}
                />
              </TabPanel>
            ) : isAccess === TypeUser.SUPERADMIN ? (
              <TabPanel value={value} index={1}>
                <ListProduct
                  key={2}
                  tab={value}
                  setHasOpenModal={setHasOpenModal}
                  setOpenEdit={setOpenEdit}
                  setGetIdClick={setGetIdClick}
                  handleAcceptItem={handleAcceptItem}
                  handletRequestEdit={handletRequestEdit}
                  addItemAction={addItemAction}
                  loadingReject={loadingReject}
                  isAccess={isAccess}
                  handleRejectItem={handleRejectItem}
                  handleOpenImage={handleOpenImage}
                  showComponent={showComponent}
                  collyFinal={collyFinal}
                  handleCancelRejectSuperAdmin={handleCancelRejectSuperAdmin}
                  handleConfirm={handleConfirm}
                  handleReject={handleReject}
                  tempItemColly={tempItemColly}
                  deleteColly={deleteColly}
                  rejectOrder={rejectOrder}
                  isLoadingData={isLoadingData}
                  salesOrderItem={salesOrderItem.filter(value => value.status === ItemStatus.REJECT && value.rejectBy === userName)}
                  hasInvoice={hasInvoice}
                  isConvertAllowed={isConvertAllowed}
                  salesOrder={salesOrder}
                  currentWareHouse={currentWareHouse}
                  indexTooltip={indexTooltip}
                  openTooltip={openTooltip}
                  handleRejectSuperAdmin={handleRejectSuperAdmin}
                  handleOpenTooltip={handleOpenTooltip}
                  handleCloseTooltip={handleCloseTooltip}
                  openAlertPrice={openAlertPrice}
                  indexAlertPrice={indexAlertPrice}
                  handleOpenAlertPrice={handleOpenAlertPrice}
                  handleCloseAlertPrice={handleCloseAlertPrice}
                  openAlertDiscount={openAlertDiscount}
                  indexAlertDiscount={indexAlertDiscount}
                  handleOpenAlertDiscount={handleOpenAlertDiscount}
                  handleCloseAlertDiscount={handleCloseAlertDiscount}
                />
              </TabPanel>
            ) : (
              <TabPanel value={value} index={1}>
                <ListProduct
                  key={2}
                  tab={value}
                  setHasOpenModal={setHasOpenModal}
                  setOpenEdit={setOpenEdit}
                  setGetIdClick={setGetIdClick}
                  handleAcceptItem={handleAcceptItem}
                  handletRequestEdit={handletRequestEdit}
                  addItemAction={addItemAction}
                  loadingReject={loadingReject}
                  isAccess={isAccess}
                  handleRejectItem={handleRejectItem}
                  handleOpenImage={handleOpenImage}
                  showComponent={showComponent}
                  collyFinal={collyFinal}
                  handleConfirm={handleConfirm}
                  handleReject={handleReject}
                  tempItemColly={tempItemColly}
                  deleteColly={deleteColly}
                  rejectOrder={rejectOrder}
                  isLoadingData={isLoadingData}
                  salesOrderItem={salesOrderItem.filter(value => value.status === getDataFilter().toString())}
                  hasInvoice={hasInvoice}
                  isConvertAllowed={isConvertAllowed}
                  salesOrder={salesOrder}
                  currentWareHouse={currentWareHouse}
                  indexTooltip={indexTooltip}
                  openTooltip={openTooltip}
                  handleRejectSuperAdmin={handleRejectSuperAdmin}
                  handleOpenTooltip={handleOpenTooltip}
                  handleCloseTooltip={handleCloseTooltip}
                  openAlertPrice={openAlertPrice}
                  indexAlertPrice={indexAlertPrice}
                  handleOpenAlertPrice={handleOpenAlertPrice}
                  handleCloseAlertPrice={handleCloseAlertPrice}
                  openAlertDiscount={openAlertDiscount}
                  handleCancelRejectSuperAdmin={handleCancelRejectSuperAdmin}
                  indexAlertDiscount={indexAlertDiscount}
                  handleOpenAlertDiscount={handleOpenAlertDiscount}
                  handleCloseAlertDiscount={handleCloseAlertDiscount}
                />
              </TabPanel>
            )}

            <TabPanel value={value} index={2}>
              <ListProduct
                key={3}
                tab={value}
                showComponent={showComponent}
                handleRejectItem={handleRejectItem}
                setHasOpenModal={setHasOpenModal}
                setOpenEdit={setOpenEdit}
                handletRequestEdit={handletRequestEdit}
                setGetIdClick={setGetIdClick}
                handleOpenImage={handleOpenImage}
                handleCancelRejectSuperAdmin={handleCancelRejectSuperAdmin}
                addItemAction={addItemAction}
                handleAcceptItem={handleAcceptItem}
                isAccess={isAccess}
                handleConfirm={handleConfirm}
                handleReject={handleReject}
                isLoadingData={isLoadingData}
                salesOrderItem={salesOrderItem.filter(value => value.status === ItemStatus.REJECT)}
                rejectOrder={rejectOrder}
                hasInvoice={hasInvoice}
                collyFinal={collyFinal}
                loadingReject={loadingReject}
                tempItemColly={tempItemColly}
                deleteColly={deleteColly}
                isConvertAllowed={isConvertAllowed}
                salesOrder={salesOrder}
                currentWareHouse={currentWareHouse}
                handleRejectSuperAdmin={handleRejectSuperAdmin}
                indexTooltip={indexTooltip}
                openTooltip={openTooltip}
                handleOpenTooltip={handleOpenTooltip}
                handleCloseTooltip={handleCloseTooltip}
                openAlertPrice={openAlertPrice}
                indexAlertPrice={indexAlertPrice}
                handleOpenAlertPrice={handleOpenAlertPrice}
                handleCloseAlertPrice={handleCloseAlertPrice}
                openAlertDiscount={openAlertDiscount}
                indexAlertDiscount={indexAlertDiscount}
                handleOpenAlertDiscount={handleOpenAlertDiscount}
                handleCloseAlertDiscount={handleCloseAlertDiscount}
              />
            </TabPanel>
          </Grid>
        </TabPanel>
        <TabPanel value={isTab} index={1}>
          <Grid container item lg={12} sm={12} md={12} xs={12}>
            <EditingHistory isAccess={isAccess} showComponent={showComponent} historyResource={historyResource} isLoading={loadingHistoryResource} />
          </Grid>
        </TabPanel>
      </Grid>

      <Grid xl={12} lg={12} md={12} container className={classes.desktop}>
        <Grid lg={4} xl={4}></Grid>
        <Grid lg={8} xl={8} container justify='flex-end' style={{ marginBottom: '1em' }}>
          <Grid>
            {(isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS) && (
              <Button
                color='primary'
                onClick={() => {
                  setOpenEdit(true);
                  setHasOpenModal(5);
                }}
                disabled={itemColly.length < 1}
                variant='outlined'
                style={{ borderRadius: '20px', width: '170px', height: '30px', marginRight: '10px' }}
              >
                {`Masuk Koli (${itemColly.length})`}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>

      {(isAccess === TypeUser.ADMIN03 || isAccess === TypeUser.SUPERADMIN) && (
        <Fragment>
          <Grid container item lg={6} sm={12} md={6} xs={12} className={classes.desktop}>
            <Grid item lg={10} sm={12} md={12} xs={12}></Grid>
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.desktop} style={{ marginTop: '30px' }}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align='right' className={classes.cellSummaryRight}>
                      Subtotal
                    </TableCell>
                    <TableCell align='right' className={classes.cellSummaryLeft}>
                      <NumberFormat value={sumSubtotalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='right' className={classes.cellSummaryRight}>
                      Diskon
                    </TableCell>
                    <TableCell align='right' className={classes.cellSummaryLeft}>
                      <NumberFormat value={sumDiscount} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='right' className={classes.cellSummaryRight}>
                      Total Harga
                    </TableCell>
                    <TableCell align='right' className={classes.cellSummaryLeft}>
                      <NumberFormat value={sumTotalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='right' className={classes.cellSummaryRight}>
                      Jumlah Tertagih
                    </TableCell>
                    <TableCell align='right' className={classes.cellSummaryLeft}>
                      <NumberFormat value={sumTotalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Fragment>
      )}

      {!isLoadingData && isAccess === TypeUser.SUPERADMIN && (
        <Grid xs={12} className={(classes.containerPosition, classes.desktop)}>
          {(!salesOrder.isAccept || salesOrder.isPlafon) && (
            <Button variant='contained' fullWidth color='primary' style={{ borderRadius: '20px' }} onClick={() => handleCheck()}>
              {loadingConfirmation ? <CircularProgress size={20} color='secondary' /> : 'Konfirmasi Sales Order'}
            </Button>
          )}
        </Grid>
      )}

      {isAccess === TypeUser.PICKER && (
        <Grid xs={12} className={(classes.containerPosition, classes.desktop)}>
          <Button
            variant='contained'
            fullWidth
            disabled={rejectOrder.length < 1 || rejectOrder.length !== validationPicker}
            color='primary'
            style={{ borderRadius: '20px' }}
            onClick={() => {
              setOpenEdit(true);
              setHasOpenModal(3);
            }}
          >
            {'Konfirmasi Stok'.concat(` (${rejectOrder.length})`)}
          </Button>
        </Grid>
      )}

      {isAccess === TypeUser.ADMIN01 && (
        <Grid xs={12} className={(classes.containerPosition, classes.desktop)}>
          <Button
            variant='contained'
            fullWidth
            color='primary'
            disabled={rejectOrder.length < 1 || rejectOrder.length !== validationChecker}
            style={{ borderRadius: '20px' }}
            onClick={() => {
              setOpenEdit(true);
              setHasOpenModal(3);
            }}
          >
            Konfirmasi Packing
          </Button>
        </Grid>
      )}

      <PartnerModal partners={salesOrder} open={open} showComponent={showComponent} setOpen={setOpen} onCancel={handleOnClose} />

      <FormPinDialog
        open={openConfirmation}
        pinMessage={pinMessage}
        isComplete={isComplete}
        setComplete={setComplete}
        setPin={setPin}
        handleClose={handleCloseConfirmation}
      />
      <StandardConfirmationDialog
        variant={snackbarVariant}
        titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
        message={message}
        open={openSnackbar}
        handleClose={snackbarVariant === 'success' ? handleConfirmationSnackbar : handleCloseSnackbar}
        onConfirm={handleConfirmationSnackbar}
        noCancelButton={true}
      />
      <ModalConvert open={openModalSuccess} />
    </Fragment>
  );
};

export default SalesOrderList;
