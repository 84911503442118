import React, { FC, Fragment, useContext } from 'react';
import { TableRow, TableCell, IconButton, Tooltip } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import Skeleton from '@material-ui/lab/Skeleton';
import EditItem from 'images/icon/EditItem.svg';
import CloseCircleItem from 'images/icon/CloseCircleItem.svg';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import useRole from 'hooks/useRole';
import TypeUser from 'typings/enum/TypeUser';

interface Props {
  item: InvoiceReturnItemModel;
  isLoading: boolean;
  typeReturn: string;
  index: number;
  currentIndex: number;
  isAdmin: boolean;
  handleUpdateItem: (index: number) => React.MouseEventHandler;
  handleDeleteItem: (index: number) => React.MouseEventHandler;
}

const BodyRow: FC<Props> = props => {
  const { item, index, isLoading, isAdmin, handleUpdateItem, handleDeleteItem, typeReturn } = props;
  const { currentUser } = useContext(CurrentUserContext);

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: ['SUPERADMIN', TypeUser.ADMIN03]
  });

  return (
    <TableRow>
      <TableCell align='center'>{isLoading ? <Skeleton variant='text' width='100%' /> : item.Product ? item.Product.productName : '-'}</TableCell>

      {(typeReturn === 'CUSTOMER' || (typeReturn === 'SUPPLIER' && isSuperAdmin)) && (
        <TableCell align='center' width='20%'>
          {isLoading ? (
            <Skeleton variant='text' width='100%' />
          ) : (
            <NumberFormat value={item.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCell>
      )}

      <TableCell align='center'>{isLoading ? <Skeleton variant='text' width='100%' /> : item.totalItem + ' ' + item.typeUnit}</TableCell>

      {typeReturn === 'SUPPLIER' && (
        <TableCell align='center'>{isLoading ? <Skeleton variant='text' width='100%' /> : item.additionalDamage + ' ' + item.typeUnit}</TableCell>
      )}

      {(typeReturn === 'CUSTOMER' || (typeReturn === 'SUPPLIER' && isSuperAdmin)) && (
        <TableCell align='center' >
          {isLoading ? (
            <Skeleton variant='text' width='100%' />
          ) : (
            <NumberFormat value={item.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCell>
      )}

      <TableCell align='right' >
        {isLoading ? (
          <Skeleton variant='text' width='100%' />
        ) : (
          <Fragment>
            <Tooltip title='Ubah'>
              <IconButton onClick={handleUpdateItem(index)} size='small'>
                <img src={EditItem} />
              </IconButton>
            </Tooltip>
            <Tooltip title='Hapus'>
              <IconButton onClick={handleDeleteItem(index)} size='small'>
                <img src={CloseCircleItem} />
              </IconButton>
            </Tooltip>
          </Fragment>
        )}
      </TableCell>
    </TableRow>
  );
};

export default BodyRow;



// import React, { FC, Fragment, useContext } from 'react';
// import { TableRow, TableCell, IconButton, Tooltip } from '@material-ui/core';
// import NumberFormat from 'react-number-format';
// import Skeleton from '@material-ui/lab/Skeleton';
// import EditItem from 'images/icon/EditItem.svg';
// import CloseCircleItem from 'images/icon/CloseCircleItem.svg';
// import { CurrentUserContext } from 'contexts/CurrentUserContext';
// import useRole from 'hooks/useRole';
// import TypeUser from 'typings/enum/TypeUser';

// interface Props {
//   item: InvoiceReturnItemModel;
//   isLoading: boolean;
//   typeReturn: string;
//   index: number;
//   currentIndex: number;
//   isAdmin: boolean;
//   handleUpdateItem: (index: number) => React.MouseEventHandler;
//   handleDeleteItem: (index: number) => React.MouseEventHandler;
// }

// const BodyRow: FC<Props> = props => {
//   const { item, index, isLoading, isAdmin, handleUpdateItem, handleDeleteItem, typeReturn } = props;
//   const { currentUser } = useContext(CurrentUserContext);

//   const isSuperAdmin = useRole({
//     type: (currentUser && currentUser.type) || 'SALES',
//     allowed: ['SUPERADMIN', TypeUser.ADMIN03]
//   });

//   return (
//     <TableRow>
//       <TableCell align='center'>{isLoading ? <Skeleton variant='text' width='100%' /> : item.Product ? item.Product.productName : '-'}</TableCell>

//       {(typeReturn === 'CUSTOMER' || (typeReturn === 'SUPPLIER' && isSuperAdmin)) && (
//         <TableCell align='center'>
//           {isLoading ? (
//             <Skeleton variant='text' width='100%' />
//           ) : (
//             <NumberFormat value={item.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
//           )}
//         </TableCell>
//       )}

//       <TableCell align='center'>{isLoading ? <Skeleton variant='text' width='100%' /> : item.totalItem + ' ' + item.typeUnit}</TableCell>

//       {typeReturn === 'SUPPLIER' && (
//         <TableCell align='center'>{isLoading ? <Skeleton variant='text' width='100%' /> : item.additionalDamage + ' ' + item.typeUnit}</TableCell>
//       )}

//       {(typeReturn === 'CUSTOMER' || (typeReturn === 'SUPPLIER' && isSuperAdmin)) && (
//         <TableCell align='center'>
//           {isLoading ? (
//             <Skeleton variant='text' width='100%' />
//           ) : (
//             <NumberFormat value={item.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
//           )}
//         </TableCell>
//       )}
//       {item.id == 0 && typeReturn === 'SUPPLIER' && (
//         <TableCell align='right' width='20%'>
//           {isLoading ? (
//             <Skeleton variant='text' width='100%' />
//           ) : (
//             <Fragment>
//               <Tooltip title='Ubah'>
//                 <IconButton onClick={handleUpdateItem(index)} size='small'>
//                   <img src={EditItem} />
//                 </IconButton>
//               </Tooltip>
//               <Tooltip title='Hapus'>
//                 <IconButton onClick={handleDeleteItem(index)} size='small'>
//                   <img src={CloseCircleItem} />
//                 </IconButton>
//               </Tooltip>
//             </Fragment>
//           )}
//         </TableCell>
//       )}
//     </TableRow>
//   );
// };

// export default BodyRow;
