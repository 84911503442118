import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { YELLOW_MOBILE, PRIMARY_MOBILE } from 'constants/colors';
import React from 'react';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    width: '3em',
    height: '2em',
    transformStyle: 'preserve-3d'
  },
  cirle: {
    position: 'absolute',
    borderRadius: '50%'
  },

  small: {
    width: '.8em',
    height: '.8em'
  },
  normal: {
    width: '1.2em',
    height: '1.2em'
  },

  one: {
    backgroundColor: PRIMARY_MOBILE,
    left: '0%',
    animation: `$cirleOne 1.3s linear infinite forwards`
  },
  two: {
    backgroundColor: YELLOW_MOBILE,
    left: '50%',
    animation: `$cirleTwo 1.3s linear infinite forwards`
  },

  '@keyframes cirleOne': {
    '25%': {
      transform: 'translateZ(1px) scale(1.2)',
      left: '25%'
    },

    '50%': {
      transform: 'translateZ(0px) scale(1)',
      left: '50%'
    },
    '75%': {
      transform: 'translateZ(-1px) scale(.8)',
      left: '25%'
    }
  },
  '@keyframes cirleTwo': {
    '25%': {
      transform: 'translateZ(-1px) scale(.8)',
      left: '25%'
    },

    '50%': {
      transform: 'translateZ(0px) scale(1)',
      left: '0%'
    },
    '75%': {
      transform: 'translateZ(1px) scale(1.2)',
      left: '25%'
    }
  }
});

type TSize = 'small' | 'normal';

interface Props {
  size?: TSize;
}
const Loading = ({ size = 'normal' }: Props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <Grid className={clsx(classes.cirle, classes.one, classes[size])} />
      <Grid className={clsx(classes.cirle, classes.two, classes[size])} />
    </Grid>
  );
};

export default Loading;
