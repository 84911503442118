import React, { useCallback, useEffect, useState } from 'react';
import { DialogTitle, DialogContent, Dialog, Tabs, Tab } from '@material-ui/core';
import axios from 'axios';
import { isValidEmail } from '../../../../utils';
import { USER_BASE_URL } from '../../../../constants/url';
import { TabPanel, a11yProps } from 'components';
import CreateUserForm from './components/CreateUserForm';
import RouteForm from './components/RouteForm';
import TypeUser from 'typings/enum/TypeUser';

interface Props {
  open: boolean;
  zone: ZoneModel[];
  roles: RoleModel[];
  addNewUser(user: UserDetailsModel): void;
  warehouses: WareHouseModel[];
  handleCancel(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const CreateUserModal: React.FC<Props> = props => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [contactNumber, setContactNumber] = useState<string>('');
  const [type, setType] = useState<string>('SALES');
  const [typeError, setTypeError] = useState<string>('');

  const [emailError, setEmailError] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [contactNumberError, setContactNumberError] = useState<string>('');
  const [wareHouse, setWareHouse] = useState<number[]>([]);
  const [wareHouseError, setWareHouseError] = useState('');
  const { open, addNewUser, handleCancel, setOpenSnackbar, handleSnackBar, zone, roles } = props;

  const [salesRoute, setSalesRoute] = useState<SalesRoute[]>([]);
  const [value, setValue] = useState<number>(0);

  const isAllowMultiSelect: Array<string> = [TypeUser.ACCOUNTING, TypeUser.SUPERADMIN];
  const handleChangeValue = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (!validateForm()) return;
    setValue(newValue);
  };

  const resetFormValues = useCallback(() => {
    clearFormValue();
    clearFormErrors();
  }, [open]);

  useEffect(() => {
    resetFormValues();
  }, [resetFormValues]);

  const handleClose = () => {
    handleCancel();
    clearFormValue();
    clearFormErrors();

    setValue(0);
  };

  const clearFormValue = () => {
    setEmail('');
    setFirstName('');
    setContactNumber('');
    setType('SALES');
    setWareHouseError('');
    setWareHouse([]);
  };

  const clearFormErrors = () => {
    setEmailError('');
    setFirstNameError('');
    setContactNumberError('');
    setTypeError('');
    setWareHouseError('');
  };

  const validateForm = () => {
    let ret = true;
    clearFormErrors();

    if (!email || !email.trim()) {
      setEmailError('Please enter email');
      ret = false;
    } else if (!isValidEmail(email)) {
      setEmailError('Please enter an valid email');
      ret = false;
    }

    if (!firstName || !firstName.trim()) {
      setFirstNameError('Please enter First Name');
      ret = false;
    }

    if (!contactNumber || !contactNumber.trim()) {
      setContactNumberError('Please enter Contact Number ');
      ret = false;
    }

    if (contactNumber.length < 8) {
      setContactNumberError('Nomor Kontak minimal 8 digit.');
      ret = false;
    }

    if (contactNumber && contactNumber.length < 10) {
      setContactNumberError('Contact Number Min 10 Character');
      ret = false;
    }

    if (!type || !type.trim()) {
      setTypeError('Tipe pengguna tidak boleh kosong');
      ret = false;
    }
    if (wareHouse.length < 1) {
      setWareHouseError('Silahkan pilih gudang');

      ret = false;
    }

    return ret;
  };

  const handleOnSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      const { data } = await axios.post(USER_BASE_URL, {
        firstName,
        email,
        contactNumber,
        lastName,
        password: '123456',
        route: salesRoute,
        wareHouse: wareHouse,
        typeUser: type
      });

      addNewUser(data.data);
      setOpenSnackbar(true);
      handleSnackBar(true, 'success', 'User berhasil ditambahkan');
      setLoading(false);
      handleClose();
    } catch (error) {
      const err = error as { data: any };
      handleSnackBar(true, 'error', err.data && err.data.message);
      setLoading(false);
    }
  };

  const handleChangeRoute = (ZoneId: number) => {
    setSalesRoute(prevState => {
      if (prevState.some(value => value.ZoneId === ZoneId)) {
        return prevState.filter(value => value.ZoneId !== ZoneId);
      } else {
        return [
          ...prevState,
          {
            SalesId: 0,
            ZoneId
          }
        ];
      }
    });
  };

  const handleSelectAll = () => {
    const _zone: SalesRoute[] = zone.map(val => ({ SalesId: 0, ZoneId: val.id }));
    setSalesRoute(prevState => _zone.filter(value => !prevState.includes(value)));
  };

  const handleClearAll = () => {
    setSalesRoute(zone.map(value => ({ SalesId: 0, ZoneId: value.id })));
  };

  const handleChangeWareHouse = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (isAllowMultiSelect.includes(type)) {
        setWareHouse(prev => prev.concat(+event.target.value));
      } else {
        setWareHouse([+event.target.value]);
      }
    } else {
      setWareHouse(prev => prev.filter(value => value !== +event.target.value));
    }
  };

  useEffect(() => {
    if (value > 0 && !validateForm()) {
      setValue(0);
      return;
    }
  }, [value]);

  useEffect(() => {
    if (type !== TypeUser.ADMIN && zone.length !== 0) {
      setSalesRoute([]);
      return;
    }
    setSalesRoute(zone.map(value => ({ SalesId: 0, ZoneId: value.id })));
  }, [type, zone]);

  useEffect(() => {
    setWareHouse([]);
  }, [type]);

  return (
    <Dialog open={open} onClose={handleClose} scroll={'body'} aria-labelledby='scroll-dialog-title' aria-describedby='scroll-dialog-description'>
      <DialogTitle id='alert-dialog-title'>{'Tambah Pengguna'}</DialogTitle>
      <DialogContent>
        <Tabs value={value} indicatorColor='primary' onChange={handleChangeValue} aria-label='disabled tabs example'>
          <Tab label='Informasi' {...a11yProps(0)} />
          <Tab label='Route' {...a11yProps(1)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <CreateUserForm
            email={email}
            setEmail={setEmail}
            emailError={emailError}
            firstName={firstName}
            wareHouse={wareHouse}
            setFirstName={setFirstName}
            firstNameError={firstNameError}
            lastName={lastName}
            setLastName={setLastName}
            contactNumber={contactNumber}
            setContactNumber={setContactNumber}
            contactNumberError={contactNumberError}
            handleChangeWareHouse={handleChangeWareHouse}
            wareHouseError={wareHouseError}
            setValue={setValue}
            warehouses={props.warehouses}
            type={type}
            setType={setType}
            typeError={typeError}
            roles={roles}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <RouteForm
            isLoading={isLoading}
            salesRoute={salesRoute}
            zone={zone}
            handleChangeRoute={handleChangeRoute}
            handleOnSubmit={handleOnSubmit}
            handleOnCancel={handleClose}
            handleSelectAll={handleSelectAll}
            handleClearAll={handleClearAll}
          />
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

export default CreateUserModal;
