import {
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Add, Search, Today } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import axios, { CancelTokenSource } from 'axios';
import { Breadcrumb, Page, PaperCustom } from 'components';
import DateRangeFilter from 'components/DateRangeFilter';
import FlexBox from 'components/FlexBox';
import { ACCOUNT_CATEGORY_BASE_URL, OPERATIONAL_BASE_URL } from 'constants/url';
import { format, startOfMonth } from 'date-fns';
import useDebounce from 'hooks/useDebounce';
import React, { useCallback, useEffect, useState } from 'react';
import { dummyMetaData, dummyProductImage } from 'utils/dummy';
import OperationalCarousel from './components/OperationalCarousel';
import TransactionCreate from './components/TransactionCreate';
import OperationalDelete from './components/OperationalDelete';
import OperationalDetail from './components/OperationalDetail';
import OperationalTable from './components/OperationalTable';
import OperationalUpdate from './components/OperationalUpdate';
import SearchInput from 'components/SearchInput';
import RefreshIcon from '@material-ui/icons/Refresh';
import { GREEN, WHITE } from 'constants/colors';

export type TypeOperasionalResource = {
  isLoading: boolean;
  data: Operasional[];
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em'
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  }
}));

const TransactionJurnalPage = () => {
  const classes = useStyles();
  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const [operasionalResource, setOperasionalResource] = useState<TypeOperasionalResource>({
    isLoading: false,
    data: []
  });
  const [operasionalResourceId, setOperasionalResourceId] = useState<Operasional | null>(null);

  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const handleOpenCreate = () => setIsCreate(true);
  const handleCloseCreate = useCallback(() => setIsCreate(false), []);
  const handleCloseUpdate = useCallback(() => setIsUpdate(false), []);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [meta, setMeta] = useState<MetaData>(dummyMetaData);
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [query, setQuery] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [subAccountCategories, setSubAccountCategories] = useState<AccountCategoryModel[]>([]);
  const [financialAccounts, setFinancialAccounts] = useState<AccountCategoryModel[]>([]);
  const [openCarousel, setOpenCarousel] = useState<{ isOpen: boolean; id: number }>({
    isOpen: false,
    id: 0
  });
  const [isAction, setIsAction] = useState<{ isOpen: boolean; isLoading: boolean; id: number; action: 'delete' | 'update' | 'detail' }>({
    isOpen: false,
    isLoading: false,
    id: 0,
    action: 'delete'
  });
  const [tab, setTab] = React.useState<number>(0);
  const [isPackage, setPackage] = useState<boolean>(false);

  const handleCalendarFilterClick = () => setOpenCalendarFilter(true);

  const handleOpenCarousel = (isOpen: boolean = true, id: number) => setOpenCarousel(prev => ({ ...prev, isOpen, id }));

  const handleTabs = (event: React.ChangeEvent<{}>, newValue: number): void => {
    setTab(newValue);

    setPackage(newValue === 1);
  };
  const getDataAccount = useCallback(async () => {
    try {
      const { data } = await axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/cost`, { cancelToken });
      setSubAccountCategories(data.data);
    } catch (error) {
      console.log(error);
    }
  }, [cancelToken, ACCOUNT_CATEGORY_BASE_URL]);

  const getFinancialAccounts = useCallback(async () => {
    try {
      const { data } = await axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/financial`, { cancelToken });
      setFinancialAccounts(data.data);
    } catch (error) {
      console.log(error);
    }
  }, [cancelToken, ACCOUNT_CATEGORY_BASE_URL]);

  const fetchData = useCallback(async () => {
    setOperasionalResource(prev => ({ ...prev, isLoading: true }));
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('keyword', queryString);
      }
      params.append('page', currentPage.toString());
      params.append('startDate', format(new Date(startDate), 'yyyy-MM-dd'));
      params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));
      if (orderBy || order) {
        params.append('orderBy', orderBy);
        params.append('ordered', order);
      }
      return params;
    };

    try {
      const { data } = await axios.get(`${OPERATIONAL_BASE_URL}?${getQueryParams()}`, {
        cancelToken
      });
      setOperasionalResource(prev => ({ ...prev, isLoading: false, data: data.data }));
      setMeta(data.meta);
    } catch (error) {
      console.log(error);
    }
  }, [queryString, order, orderBy, startDate, endDate, currentPage]);

  console.log('masuk sini --->');

  const handleAction = (isOpen: boolean, id: number, action: 'delete' | 'update' | 'detail' = 'delete') => {
    setIsAction(prev => ({ ...prev, isOpen, id, action }));

    if (action === 'update') {
      setIsUpdate(true);
      handleOnUpdate(id);
    } else if (action === 'detail') {
      setIsDetail(true);
      handleOnUpdate(id);
    }
  };

  const handleOnUpdate = async (id: number) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    try {
      const { data } = await axios.get(`${OPERATIONAL_BASE_URL}/${id}`, { cancelToken: cancelTokenSource.token });
      setOperasionalResourceId(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log('action', isAction);
  console.log('isDetail', isDetail);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  useEffect(() => {
    fetchData();
    getDataAccount();
    getFinancialAccounts();
  }, [fetchData, queryString]);

  const handleSearch = useCallback(
    (searchQuery: string) => {
      performActionAndRevertPage(setQueryString, searchQuery);
    },
    [performActionAndRevertPage]
  );

  const debouncedSearchTerm = useDebounce(query, 500);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  const reFetchData = () => {
    setIsCreate(false);
    setIsUpdate(false);
    fetchData();
  };

  const handleDelete = async () => {
    setIsAction(prev => ({ ...prev, isLoading: true }));
    try {
      const { data } = await axios.delete(`${OPERATIONAL_BASE_URL}/${isAction.id}`);
      setIsAction(prev => ({ ...prev, isLoading: false, isOpen: false }));
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const onRefresh = () => {
    setOrderBy('id');
    setOrder('desc');
    setQueryString('');
    setCurrentPage(1);
  };

  return (
    <Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container direction='row' justify='flex-end'>
          <Grid item xl={5} lg={5} md={12} sm={12} xs={12} container alignItems='center' justify='flex-end' style={{ columnGap: '1em' }}>
            <Grid item>
              <Tooltip title='Calendar filter' placement='top'>
                <IconButton color='primary' aria-label='filter date' component='span' onClick={handleCalendarFilterClick}>
                  <Today />
                </IconButton>
              </Tooltip>
              <Tooltip title='Tambah data' placement='top'>
                <Button onClick={handleOpenCreate} size='small' startIcon={<Add fontSize='small' />}>
                  Tambah Transaksi
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} container className={classes.container}>
        <Grid container direction='row' spacing={2}>
          {' '}
          <Grid item xl={6} lg={1} md={1} sm={6} xs={6}>
            <Tooltip title='Memuat Ulang'>
              <Button onClick={onRefresh} color='inherit' className={classes.refresh}>
                <RefreshIcon />
              </Button>
            </Tooltip>
          </Grid>
          <Grid container alignItems='center' item xl={12} lg={8} md={12} sm={12} xs={12}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant='h6'>
                {`Menampilkan ${meta.total || 0} Transaksi (${meta.from || 0} - ${meta.to || 0} dari ${meta.total || 0})`}
              </Typography>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography color='primary'>
                Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Divider />
        </Grid>
        {/* 
        {/* <Grid xs={12}>
              <Divider />
            </Grid> */}
        <Grid xs={12}>
          <OperationalTable operasionalResource={operasionalResource} handleAction={handleAction} handleOpenCarousel={handleOpenCarousel} />
        </Grid>
        {operasionalResource.data.length > 0 && (
          <Grid xs={12} container>
            <Pagination
              count={meta.last_page}
              onChange={(event, page) => setCurrentPage(page)}
              page={meta.current_page}
              boundaryCount={2}
              variant='outlined'
              shape='rounded'
            />
          </Grid>
        )}
        <DateRangeFilter
          openCalendarFilter={openCalendarFilter}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleClose={() => setOpenCalendarFilter(false)}
        />
      </Grid>

      <TransactionCreate
        financialAccounts={financialAccounts}
        subAccountCategories={subAccountCategories}
        open={isCreate}
        handleCloseCreate={handleCloseCreate}
        reFetchData={reFetchData}
      />

      {/* <OperationalUpdate
        financialAccounts={financialAccounts}
        subAccountCategories={subAccountCategories}
        operasionalResourceId={operasionalResourceId}
        open={isUpdate}
        handleCloseUpdate={handleCloseUpdate}
        reFetchData={reFetchData}
      />
      <OperationalDelete
        open={isAction.isOpen && isAction.action.includes('delete')}
        handleClose={() => handleAction(false, 0, 'delete')}
        handleSubmit={handleDelete}
        isLoading={isAction.isLoading}
      />
      <OperationalDetail open={isDetail} handleAction={handleAction} operasionalResourceId={operasionalResourceId} />
      <OperationalCarousel
        open={openCarousel.isOpen}
        handleClose={() => setOpenCarousel(prev => ({ ...prev, isOpen: false }))}
        images={
          operasionalResource.data.filter(operasionalResource => operasionalResource.id === openCarousel.id).length > 0
            ? operasionalResource.data.filter(operasionalResource => operasionalResource.id === openCarousel.id)[0].image
            : [dummyProductImage]
        }
      /> */}
    </Grid>
  );
};

export default TransactionJurnalPage;
