import React, { FC } from 'react';
import { dummyStockItem } from 'utils/dummy';
import { Checkbox } from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart, TableRowCustom } from 'components/Table';
import Skeleton from '@material-ui/lab/Skeleton';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

interface Props {
  product: ProductModel;
  isLoading: boolean;
  checked: StockItemModel[];
  item: StockItemModel;
  index: number;
  handleIndividualCheck: (item: StockItemModel) => void;
}

const BodyRowProduct: FC<Props> = props => {
  const { product, isLoading, checked, handleIndividualCheck } = props;
  const { id, typeUnit } = product;

  const handleSetId = () => {
    handleIndividualCheck({ ...dummyStockItem, ProductId: id, typeUnit });
  };

  return (
    <TableRowCustom>
      <TableCellStart>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <Checkbox
            key={id}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<CheckBoxIcon />}
            edge='start'
            color='primary'
            checked={checked.some(checkedValue => checkedValue.ProductId === id)}
            tabIndex={-1}
            disableRipple
            onChange={handleSetId}
          />
        )}
      </TableCellStart>

      <TableCellMiddle>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <>
            {product.productName}
            <br />
            {product.productCode}
          </>
        )}
      </TableCellMiddle>
      <TableCellMiddle align='center'>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : product.Category ? product.Category.name : '-'}
      </TableCellMiddle>
      <TableCellEnd>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : product.typeUnit}</TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRowProduct;
