import * as Components from '@material-ui/core';
import { Autocomplete, Pagination, Skeleton, TimelineDot } from '@material-ui/lab';
import axios from 'axios';
import { Breadcrumb, Page, PaperCustom } from 'components';
import * as URL from 'constants/url';
import React, { useEffect, useState, useMemo, useRef } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { DateRange, InfoRounded, RefreshOutlined, SortRounded } from '@material-ui/icons';
import { GREEN, PRIMARY_MOBILE, WHITE, YELLOW_MOBILE } from 'constants/colors';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import FlexBox from 'components/FlexBox';
import * as Pages from './components';
import { format } from 'date-fns';
import { dummyMetaData, dummyWareHouse } from 'utils/dummy';
import useDebounce from 'hooks/useDebounce';
import useIsInViewport from 'hooks/useIsInViewport';
import monthNames from 'utils/month';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';
const useStyles = Components.makeStyles({
  refreshButton: {
    backgroundColor: GREEN,
    color: WHITE,
    padding: '.4em',
    borderRadius: '5px',
    transition: 'all .3s',
    '&:hover': {
      transform: 'scale(1.2)',
      backgroundColor: '#19A48F'
    }
  },
  wrapperHeader: {
    columnGap: '1em'
  },
  containerSelected: {
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxShadow: ' 0px 4px 24px rgba(50, 50, 50, 0.16)',
    borderRadius: '5px',
    marginTop: '0.5em'
  },

  wrapperInput: {
    backgroundColor: WHITE
  },

  boxShadow: {
    boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 3px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
    borderRadius: '.4em'
  },
  statisticContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '1.5em'
  },

  listContainer: {
    padding: '1em',
    cursor: 'pointer',
    backgroundColor: '#ffffff10',
    transition: 'all .3s ease',
    '&:hover': {
      transform: 'translateY(-7px)',
      border: `2px solid ${PRIMARY_MOBILE}`
    }
  },
  listActive: {
    border: `2px solid ${PRIMARY_MOBILE}`
  },
  colorGreen: {
    color: GREEN
  },
  infoIcon: {
    cursor: 'pointer',
    fontSize: '1.7rem',
    color: YELLOW_MOBILE,
    transition: 'all .3s ease-in-out',
    '&:hover': {
      scale: 1.2
    }
  },

  infoIconAnimation: {
    animation: `$iconAlert 1s infinite`
  },
  '@keyframes iconAlert': {
    '0%': {
      transform: 'scale(1) rotate(50deg)'
    },

    '10%': {
      transform: 'scale(1.1) rotate(-50deg)'
    },
    '15%': {
      transform: 'scale(1.1) rotate(50deg)'
    },
    '20%': {
      transform: 'scale(1) rotate(-50deg)'
    },
    '25%': {
      transform: 'scale(1.1) rotate(50deg)'
    },
    '30%': {
      transform: 'scale(1) rotate(0deg)'
    }
  },
  alertContent: {
    opacity: 0,
    position: 'absolute',
    boxShadow: `${YELLOW_MOBILE} 0px 1px 3px 0px, ${YELLOW_MOBILE} 0px 1px 3px 0px, ${YELLOW_MOBILE} 0px 1px 3px 0px`,
    background: '#fff4e5',
    padding: '1em',
    borderRadius: '.4em',
    bottom: 200,
    transition: 'all .5s cubic-bezier(.68, -0.55, .265, 1.55)',
    left: 220,
    '&::before': {
      content: '""',
      position: 'absolute',
      borderBottom: `1px solid ${YELLOW_MOBILE}`,
      borderLeft: `1px solid ${YELLOW_MOBILE}`,
      background: '#fff4e5',
      height: '1em',
      width: '1em',
      left: 0,
      bottom: 8,
      transform: 'translateX(-50%) rotate(45deg)'
    },
    '& > *': {
      color: '#663c00'
    }
  },
  showAlertContent: {
    opacity: 1,
    bottom: 40
  }
});

export interface IProperties<T> {
  id: keyof T;
  label: string;
  sort: boolean;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
}

export interface IPropertiesCollapse<T> {
  id: keyof T;
  label: string;
}

export interface IList {
  title: string;
  isActive: boolean;
}

export const titleCards = ['Tertunda oleh Gudang', 'Peninjauan oleh Pengecekan', 'Packing oleh Pengepakan', 'Tertunda oleh Invoice'];
const titleInvoiceCards = ['Belum dikonversi Invoice', 'Sudah dikonversi Invoice', 'Invoice belum Dicetak', 'Invoice sudah Dicetak'];
const titleManifestCards = ['Belum ada Surat Jalan', 'Sudah Ada Surat Jalan', 'Surat Jalan Belum Dicetak', 'Surat Jalan Sudah Dicetak'];
const listOptionResources = [
  {
    title: 'Sales Order',
    isActive: true
  },
  {
    title: 'Overview Penolakan Item',
    isActive: false
  },
  {
    title: 'Statistik Invoice',
    isActive: false
  },
  {
    title: 'Statistik Surat Jalan',
    isActive: false
  }
];
const dummyDashboard = { isLoading: true, data: [] };
const dummyTotalSalesOrder = {
  isShow: false,
  total: 0
};

const Dashboard = () => {
  const classes = useStyles();
  const [cardResource, setCardResource] = useState<IDashboard<DashboardCard[]>>(dummyDashboard);
  const [chartResource, setChartResource] = useState<IDashboard<DashboardChart[]>>(dummyDashboard);
  const [productResource, setProductResource] = useState<IDashboard<DashboardProduct[]> & { meta: MetaData }>({
    ...dummyDashboard,
    meta: dummyMetaData
  });

  const salesOrderSectionRef = useRef<HTMLDivElement | null>(null);
  const productSectionRef = useRef<HTMLDivElement | null>(null);
  const invoiceSectionRef = useRef<HTMLDivElement | null>(null);
  const deleverySectionRef = useRef<HTMLDivElement | null>(null);

  /* Checking if the element is in the viewport. */
  const productSectionInviewport = useIsInViewport(productSectionRef, '-100px');
  const invoiceSectionInviewport = useIsInViewport(invoiceSectionRef, '-100px');
  const deliverySectionInviewport = useIsInViewport(deleverySectionRef, '-100px');
  const [isTotalSalesOrder, setIsTotalSalesOrder] = useState<{ isShow: boolean; total: number }>(dummyTotalSalesOrder);
  const [productDetailResource, setProductDetailResource] = useState<IDashboard<DashboardProductDetail[]>>(dummyDashboard);
  const [invoiceStatistic, setInvoiceStatistic] = useState<IDashboard<(DashboardStatistic & { totalPrice: number })[]>>(dummyDashboard);
  const [manifestStatistic, setManifestStatistic] = useState<IDashboard<(DashboardStatistic & { totalColly: number })[]>>(dummyDashboard);
  const [chartInvoiceStatistic, setChartInvoiceStatistic] = useState<IDashboard<DashboardChartStatistic[]>>(dummyDashboard);
  const [chartManifestStatistic, setChartManifestStatistic] = useState<IDashboard<DashboardChartStatistic[]>>(dummyDashboard);
  const [wareHouses, setWareHouses] = useState<IDashboard<WareHouseModel[]>>(dummyDashboard);
  const [wareHouseSelected, setWareHousesSelected] = useState<WareHouseModel>(dummyWareHouse);
  const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
  const [productName, setProductName] = useState<string>('');
  const [productPage, setProductPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState<string>('7');
  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const [listOptions, setListOprions] = useState<IList[]>(listOptionResources);
  /**
   * This function is used to fetch data from the server and then set the data to the state
   */
  const fetchDashboarCard = async () => {
    setCardResource(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();
    if (wareHouseSelected) {
      params.append('warehouse', String(wareHouseSelected.id));
    }
    params.append('date', format(selectedDate, 'yyyy-MM'));
    try {
      const { data } = await axios.get(`${URL.DASHBOARD_BASE_URL}/card?${params}`, {
        cancelToken
      });
      setCardResource({
        isLoading: false,
        data: data.map((value: DashboardCard) => ({ ...value, isActive: value.statusOrder.includes('Tertunda') }))
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDashboarChart = async () => {
    setChartResource(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();
    if (wareHouseSelected) {
      params.append('warehouse', String(wareHouseSelected.id));
    }

    params.append('date', format(selectedDate, 'yyyy-MM'));

    const index = cardResource.data.findIndex(card => card.isActive);
    params.append('statusOrder', cardResource.data[index].statusOrder);

    try {
      const { data } = await axios.get(`${URL.DASHBOARD_BASE_URL}/chart-so?${params}`, {
        cancelToken
      });

      setChartResource({
        isLoading: false,
        data: data.map((value: DashboardChart) => ({ ...value, date: format(new Date(value.date), 'dd'), dateFull: value.date }))
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChageProductPage = (event: React.ChangeEvent<unknown>, page: number) => {
    setProductPage(page);
    handleScrollProductSection();
  };

  const handleChangeSortBy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSortBy(event.target.value);
  };

  const fetchDashboarProducts = async () => {
    setProductResource(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();
    if (wareHouseSelected) {
      params.append('warehouse', String(wareHouseSelected.id));
    }
    if (productName) {
      params.append('productName', productName);
    }
    params.append('date', format(selectedDate, 'yyyy-MM'));
    params.append('page', String(productPage));
    params.append('day', sortBy);
    params.append('ordered', 'desc');

    try {
      const { data } = await axios.get(`${URL.DASHBOARD_BASE_URL}/item-rejection?${params}`, {
        cancelToken
      });

      setProductResource(prev => ({
        ...prev,
        isLoading: false,
        data: data.data.map((value: DashboardProduct) => ({ ...value, isExpand: false })),
        meta: data.meta
      }));
    } catch (error) {
      console.log(error);
    }
  };
  const fetchDashboarProductDetail = async (id: number) => {
    setProductDetailResource(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();
    if (wareHouseSelected) {
      params.append('warehouse', String(wareHouseSelected.id));
    }

    params.append('date', format(selectedDate, 'yyyy-MM'));
    params.append('productId', String(id));

    try {
      const { data } = await axios.get(`${URL.DASHBOARD_BASE_URL}/item-rejection-detail?${params}`, {
        cancelToken
      });

      setProductDetailResource({
        isLoading: false,
        data
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSectionPage = (index: number) => {
    const scrollIntoViews: Record<number, any> = {
      0: 0,
      1: productSectionRef.current && productSectionRef.current.offsetTop - 90,
      2: invoiceSectionRef.current && invoiceSectionRef.current.offsetTop - 90,
      3: deleverySectionRef.current && deleverySectionRef.current.offsetTop - 90
    };

    window.scrollTo({ top: scrollIntoViews[index], behavior: 'smooth' });
  };
  const fetchDashboarInvoiceStatistic = async () => {
    setInvoiceStatistic(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();
    if (wareHouseSelected) {
      params.append('warehouse', String(wareHouseSelected.id));
    }

    params.append('date', format(selectedDate, 'yyyy-MM'));

    try {
      const { data } = await axios.get(`${URL.DASHBOARD_BASE_URL}/invoice-statistics?${params}`, {
        cancelToken
      });
      if (data.length > 0) {
        const {
          invoiceConfirm,
          invoiceConfirmNominal,
          invoiceNotConfirm,
          invoiceNotConfirmNominal,
          invoiceNotPrinted,
          invoiceNotPrintedNominal,
          invoicePrinted,
          invoicePrintedNominal
        } = data[0];
        const tempData: (DashboardStatistic & { totalPrice: number })[] = [
          {
            total: invoiceNotConfirm,
            totalPrice: invoiceNotConfirmNominal,
            isActive: true
          },
          {
            total: invoiceConfirm,
            totalPrice: invoiceConfirmNominal,
            isActive: false
          },
          {
            total: invoiceNotPrinted,
            totalPrice: invoiceNotPrintedNominal,
            isActive: false
          },
          {
            total: invoicePrinted,
            totalPrice: invoicePrintedNominal,
            isActive: false
          }
        ];

        setInvoiceStatistic({
          isLoading: false,
          data: tempData
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  /**
   * This function fetches data from the server and sets the state of the component.
   */
  const fetchDashboarManifestStatistic = async () => {
    setManifestStatistic(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();
    if (wareHouseSelected) {
      params.append('warehouse', String(wareHouseSelected.id));
    }

    params.append('date', format(selectedDate, 'yyyy-MM'));

    try {
      const { data } = await axios.get(`${URL.DASHBOARD_BASE_URL}/manifest-statistics?${params}`, {
        cancelToken
      });

      if (data.length > 0) {
        const {
          manifestAlreadyExist,
          manifestAlreadyExistColi,
          manifestAlreadyPrint,
          manifestAlreadyPrintColi,
          manifestNotAlreadyExist,
          manifestNotAlreadyExistColi,
          manifestNotPrint,
          manifestNotPrintColi
        } = data[0];

        const tempData: (DashboardStatistic & { totalColly: number })[] = [
          {
            total: manifestNotAlreadyExist,
            totalColly: manifestNotAlreadyExistColi,
            isActive: true
          },
          {
            total: manifestAlreadyExist,
            totalColly: manifestAlreadyExistColi,
            isActive: false
          },
          {
            total: manifestNotPrint,
            totalColly: manifestNotPrintColi,
            isActive: false
          },
          {
            total: manifestAlreadyPrint,
            totalColly: manifestAlreadyPrintColi,
            isActive: false
          }
        ];

        setManifestStatistic({
          isLoading: false,
          data: tempData
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDashboarChartInvoice = async () => {
    setChartInvoiceStatistic(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();
    if (wareHouseSelected) {
      params.append('warehouse', String(wareHouseSelected.id));
    }

    const status: Record<number, string> = {
      0: 'invoiceNotConfirm',
      1: 'invoiceConfirm',
      2: 'invoiceNotPrinted',
      3: 'invoicePrinted'
    };

    params.append('date', format(selectedDate, 'yyyy-MM'));
    params.append('status', status[invoiceStatistic.data.findIndex(invoice => invoice.isActive)]);

    try {
      const { data } = await axios.get(`${URL.DASHBOARD_BASE_URL}/chart-invoice-statistics?${params}`, {
        cancelToken
      });

      setChartInvoiceStatistic({
        isLoading: false,
        data: data.map((value: DashboardChartStatistic) => ({ ...value, date: format(new Date(value.date), 'dd'), dateFull: value.date }))
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDashboarChartManifest = async () => {
    setChartManifestStatistic(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();
    if (wareHouseSelected) {
      params.append('warehouse', String(wareHouseSelected.id));
    }

    const status: Record<number, string> = {
      0: 'manifestNotAlreadyExist',
      1: 'manifestAlreadyExist',
      2: 'manifestNotAlreadyPrint',
      3: 'manifestAlreadyPrint'
    };

    params.append('date', format(selectedDate, 'yyyy-MM'));
    params.append('status', status[manifestStatistic.data.findIndex(manifest => manifest.isActive)]);

    try {
      const { data } = await axios.get(`${URL.DASHBOARD_BASE_URL}/chart-manifest-statistics?${params}`, {
        cancelToken
      });

      setChartManifestStatistic({
        isLoading: false,
        data: data.map((value: DashboardChartStatistic) => ({ ...value, date: format(new Date(value.date), 'dd'), dateFull: value.date }))
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTotalSalesOrder = async () => {
    setIsTotalSalesOrder(dummyTotalSalesOrder);
    const params = new URLSearchParams();
    if (wareHouseSelected) {
      params.append('warehouse', String(wareHouseSelected.id));
    }
    const date =
      selectedDate.getMonth() > 0
        ? `${selectedDate.getFullYear()}-${('0' + selectedDate.getMonth()).slice(-2)}`
        : `${selectedDate.getFullYear() - 1}-12`;
    params.append('date', date);

    try {
      const { data } = await axios.get(`${URL.DASHBOARD_BASE_URL}/total-sales-order?${params}`, {
        cancelToken
      });

      setIsTotalSalesOrder(() => ({ total: data, isShow: true }));
    } catch (error) {
      console.log(error);
      setIsTotalSalesOrder(dummyTotalSalesOrder);
    }
  };

  const fecthWareHouses = async () => {
    setWareHouses(prev => ({ ...prev, isLoading: true }));
    try {
      const { data } = await axios.get(URL.WAREHOUSE_BASE_URL);
      setWareHouses(prev => ({
        ...prev,
        isLoading: false,
        data: data.data
      }));
      if (data.data.length > 0) {
        setWareHousesSelected(data.data.at(0));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleWarehouseSelected = (dataWareHouse: WareHouseModel) => {
    setWareHousesSelected(dataWareHouse);
  };

  const handleRefresh = () => {
    fetchDashboarCard();
    fetchDashboarProducts();
    fetchDashboarInvoiceStatistic();
    fetchDashboarManifestStatistic();
  };

  useEffect(() => {
    fecthWareHouses();
  }, []);

  const handleScrollProductSection = () => {
    if (!productSectionRef.current) return;
    const getOffsetTop = productSectionRef.current && productSectionRef.current.offsetTop - 90;
    window.scrollTo({ top: getOffsetTop, behavior: 'smooth' });
  };

  useEffect(() => {
    if (wareHouseSelected.id === 0) return;
    fetchDashboarCard();
    return () => source.cancel('Cancel Unmounted');
  }, [wareHouseSelected, selectedDate]);

  useEffect(() => {
    if (wareHouseSelected.id === 0) return;
    fetchTotalSalesOrder();
    return () => source.cancel('Cancel Unmounted');
  }, [wareHouseSelected, selectedDate]);

  useEffect(() => {
    if (wareHouseSelected.id === 0 || !invoiceSectionInviewport) return;
    fetchDashboarInvoiceStatistic();

    return () => source.cancel('Cancel Unmounted');
  }, [wareHouseSelected, selectedDate, invoiceSectionInviewport]);

  useEffect(() => {
    if (wareHouseSelected.id === 0 || !deliverySectionInviewport) return;
    fetchDashboarManifestStatistic();
    return () => source.cancel('Cancel Unmounted');
  }, [wareHouseSelected, selectedDate, deliverySectionInviewport]);

  const productNameDedounce = useDebounce(productName, 1000);

  useEffect(() => {
    if (wareHouseSelected.id === 0 || !productSectionInviewport) return;
    fetchDashboarProducts();
    return () => {
      source.cancel('Cancel Unmounted');
    };
  }, [wareHouseSelected, selectedDate, productPage, productNameDedounce, productSectionInviewport, sortBy]);

  useEffect(() => {
    if (wareHouseSelected.id === 0 || cardResource.data.length < 1) return;
    fetchDashboarChart();
    return () => source.cancel('Cancel Unmounted');
  }, [cardResource.data]);

  useEffect(() => {
    if (wareHouseSelected.id === 0 || invoiceStatistic.data.length < 1 || !invoiceSectionInviewport) return;

    fetchDashboarChartInvoice();
    return () => source.cancel('Cancel Unmounted');
  }, [invoiceStatistic.data, invoiceSectionInviewport]);

  useEffect(() => {
    if (wareHouseSelected.id === 0 || manifestStatistic.data.length < 1 || !deliverySectionInviewport) return;
    fetchDashboarChartManifest();
    return () => source.cancel('Cancel Unmounted');
  }, [manifestStatistic.data, deliverySectionInviewport]);

  const getProperties = useMemo(() => {
    const propertyMap: IProperties<DashboardProduct>[] = [
      {
        id: 'productId',
        label: '#',
        sort: false,
        align: 'center'
      },
      {
        id: 'productName',
        label: 'Nama Produk',
        sort: false
      },
      {
        id: 'totalQty',
        label: 'Total quantity',
        sort: false
      },
      {
        id: 'totalSoReferrals',
        label: 'total SO rujukan',
        sort: false
      }
    ];
    return propertyMap;
  }, []);

  const titleInvoiceCardActive = titleInvoiceCards[invoiceStatistic.data.findIndex(item => item.isActive)];

  const titleManifestCardActive = titleManifestCards[manifestStatistic.data.findIndex(item => item.isActive)];

  const getPropertiesCollapse = useMemo(() => {
    const propertyCollapseMap: IPropertiesCollapse<DashboardProductDetail>[] = [
      {
        id: 'orderNumber',
        label: 'NO ORDER'
      },
      {
        id: 'customerName',
        label: 'NAMA CUSTOMER'
      },
      {
        id: 'qty',
        label: 'KUANTITAS'
      },
      {
        id: 'orderDate',
        label: 'TGL ORDER'
      },
      {
        id: 'zoneName',
        label: 'RUTE'
      },
      {
        id: 'rejectBy',
        label: 'DITOLAK OLEH'
      },
      {
        id: 'remark',
        label: 'ALASAN'
      }
    ];
    return propertyCollapseMap;
  }, []);

  const totalSectionItems = cardResource.data.map(value => ({
    title: value.statusOrder,
    totalItem: value.totalSO,
    totalItemToday: value.totalDone,
    totalPrice: value.totalPrice,
    totalPriceToday: value.totalPriceDone,
    isActive: value.isActive
  }));

  const handleTotalCardClick = (key: number) => {
    const data = cardResource.data.map((value, index) => ({
      ...value,
      isActive: key === index
    }));
    setCardResource(prev => ({
      ...prev,
      data
    }));
  };

  const handleExpandProduct = (id: number) => {
    const data = productResource.data.map(value => ({
      ...value,
      isExpand: value.isExpand ? false : id === value.productId
    }));

    setProductResource(prev => ({
      ...prev,
      data
    }));
    fetchDashboarProductDetail(id);
  };

  const handleInvoiceStatisticClick = (index: number) => {
    const data = invoiceStatistic.data.map((invoice, key) => ({
      ...invoice,
      isActive: key === index
    }));

    setInvoiceStatistic(prev => ({
      ...prev,
      data
    }));
  };

  const handleManifestStatisticClick = (index: number) => {
    const data = manifestStatistic.data.map((manifest, key) => ({
      ...manifest,
      isActive: key === index
    }));

    setManifestStatistic(prev => ({
      ...prev,
      data
    }));
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (!productSectionRef.current || !invoiceSectionRef.current || !deleverySectionRef.current) return;
      const offsetTops = {
        0: productSectionRef.current && productSectionRef.current.offsetTop - 115,
        1: invoiceSectionRef.current && invoiceSectionRef.current.offsetTop - 115,
        2: deleverySectionRef.current && deleverySectionRef.current.offsetTop - 115
      };

      const tempScrollY: Record<number, boolean> = {
        0: window.scrollY >= 0 && window.scrollY < offsetTops[0],
        1: window.scrollY >= offsetTops[0] && window.scrollY < offsetTops[1],
        2: window.scrollY > offsetTops[1] && window.scrollY < offsetTops[2],
        3: window.scrollY >= offsetTops[2]
      };

      setListOprions(prev => {
        return prev.map((listOption, key) => {
          return { ...listOption, isActive: tempScrollY[key] };
        });
      });
    });
  }, []);

  return (
    <Page title='Dashboard'>
      <Components.Container>
        <section ref={salesOrderSectionRef} />
        <FlexBox xs={12} container rowGap={1}>
          <Components.Grid xs={12} container alignItems='center'>
            <Components.Grid xs={3}>
              <Components.Typography variant='h1' component='h1'>
                Dashboard
              </Components.Typography>
              <Breadcrumb />
            </Components.Grid>
            <Components.Grid xs={9} container justify='flex-end' className={classes.wrapperHeader} alignItems='center'>
              <Components.IconButton
                aria-label='refresh'
                className={classes.refreshButton}
                disableRipple={false}
                size='small'
                onClick={handleRefresh}
              >
                <RefreshOutlined />
              </Components.IconButton>
              <Components.Grid xs={2}>
                <Autocomplete
                  value={wareHouseSelected}
                  onChange={(event, value, reason) => {
                    if (reason === 'clear' || reason === 'remove-option') {
                      handleWarehouseSelected(dummyWareHouse);
                    }
                    if (value) {
                      handleWarehouseSelected(value);
                    }
                  }}
                  loading={wareHouses.isLoading}
                  loadingText={<Components.LinearProgress />}
                  disableListWrap
                  getOptionLabel={option => option.name}
                  options={wareHouses.data}
                  PaperComponent={({ children }) => <Components.Grid className={classes.containerSelected}>{children}</Components.Grid>}
                  renderInput={params => <Components.TextField {...params} required label='Pilih Gudang' variant='outlined' color='primary' />}
                />
              </Components.Grid>

              <Components.Grid xs={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin='dense'
                    id='periode'
                    label='Periode'
                    required
                    format='MMMM yyyy'
                    views={['year', 'month']}
                    value={selectedDate}
                    onChange={date => {
                      if (date) {
                        setSelectedDate(date);
                      }
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'periode'
                    }}
                    InputProps={{
                      className: classes.wrapperInput
                    }}
                    keyboardIcon={<DateRange fontSize='small' />}
                  />
                </MuiPickersUtilsProvider>
              </Components.Grid>
            </Components.Grid>
          </Components.Grid>
          <Components.Grid xs={12}>
            <Components.Divider />
          </Components.Grid>

          <Pages.TitleSection
            title='Sales Order'
            showIcon={isTotalSalesOrder.total > 0}
            alertIcon={
              <InfoRounded
                className={clsx(classes.infoIcon, isTotalSalesOrder.total > 0 && !isTotalSalesOrder.isShow && classes.infoIconAnimation)}
                onMouseOver={() => setIsTotalSalesOrder(prev => ({ ...prev, isShow: true }))}
                onMouseOut={() => setIsTotalSalesOrder(prev => ({ ...prev, isShow: false }))}
              />
            }
            alertContent={
              <Components.Grid className={clsx(classes.alertContent, isTotalSalesOrder.isShow && classes.showAlertContent)}>
                <Components.Typography variant='h6'>Informasi Bulan Sebelumnya:</Components.Typography>
                <Components.Typography variant='h6'>
                  Total Sales Order {isTotalSalesOrder.total}{' '}
                  {selectedDate.getMonth() > 0
                    ? `(${monthNames[selectedDate.getMonth() - 1]} ${selectedDate.getFullYear()})`
                    : `(${monthNames[11]} ${selectedDate.getFullYear() - 1})`}
                </Components.Typography>
              </Components.Grid>
            }
          >
            <Components.Typography color='textSecondary'>
              Periode: {`${monthNames[selectedDate.getMonth()]} ${selectedDate.getFullYear()}`}, Gudang {wareHouseSelected.name}
            </Components.Typography>
          </Pages.TitleSection>
        </FlexBox>

        <PaperCustom style={{ overflow: 'visible' }}>
          <FlexBox xs={12} container rowGap={1}>
            <Pages.TotalSection isLoading={cardResource.isLoading} items={totalSectionItems} handleClick={handleTotalCardClick} />
            <Pages.ChartSection chartTitle={titleCards[totalSectionItems.findIndex(value => value.isActive)]} chartResource={chartResource} />
          </FlexBox>
        </PaperCustom>

        <section ref={productSectionRef} />
        <Pages.TitleSection title='Overview Penolakan Item'>
          <Components.Typography color='textSecondary'>
            Periode: {`${monthNames[selectedDate.getMonth()]} ${selectedDate.getFullYear()}`}, Gudang {wareHouseSelected.name}
          </Components.Typography>
        </Pages.TitleSection>

        <PaperCustom>
          <Components.Grid xs={12} container alignItems='center' justify='flex-end'>
            <Components.Grid xs={3}>
              <Components.TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <FlexBox container columnGap={1}>
                      <SortRounded />
                      <Components.Typography variant='h6'>Urutkan:</Components.Typography>
                    </FlexBox>
                  )
                }}
                value={sortBy}
                onChange={handleChangeSortBy}
                select
              >
                <Components.MenuItem key={1} value='3'>
                  3 Hari
                </Components.MenuItem>
                <Components.MenuItem key={2} value='5'>
                  5 Hari
                </Components.MenuItem>
                <Components.MenuItem key={3} value='7'>
                  7 Hari
                </Components.MenuItem>
                <Components.MenuItem key={4} value='ALL'>
                  Semua
                </Components.MenuItem>
              </Components.TextField>
            </Components.Grid>
          </Components.Grid>

          <Pages.ProductTable
            properties={getProperties}
            propertiesCollapse={getPropertiesCollapse}
            productResource={productResource}
            productDetailResource={productDetailResource}
            handleExpand={handleExpandProduct}
            productName={productName}
            setProductName={setProductName}
          />
          <Components.Grid xs={12} container justify='flex-end'>
            <Pagination
              count={productResource.meta.last_page}
              onChange={handleChageProductPage}
              page={productResource.meta.current_page}
              boundaryCount={2}
              variant='outlined'
              shape='rounded'
            />
          </Components.Grid>
        </PaperCustom>

        <section ref={invoiceSectionRef} />
        <Components.Grid xs={12}>
          <Pages.TitleSection title='Statistik Invoice'>
            <Components.Typography color='textSecondary'>
              Periode: {`${monthNames[selectedDate.getMonth()]} ${selectedDate.getFullYear()}`}, Gudang {wareHouseSelected.name}
            </Components.Typography>
          </Pages.TitleSection>

          <PaperCustom>
            <Pages.StatisticSection
              tooltipTitle={titleInvoiceCardActive}
              dataChart={chartInvoiceStatistic}
              contentCard={
                <Components.Grid xs={12} className={classes.statisticContainer}>
                  {invoiceStatistic.isLoading
                    ? [...new Array(4)].map((val, key) => (
                        <Components.Grid className={clsx(classes.listContainer, classes.boxShadow)} key={val}>
                          <Components.Typography>{titleInvoiceCards[key]}</Components.Typography>
                          <Components.Typography>
                            <Skeleton animation='wave' />
                          </Components.Typography>
                        </Components.Grid>
                      ))
                    : invoiceStatistic.data.map((item, key) => (
                        <Components.Grid
                          className={clsx(classes.listContainer, classes.boxShadow, item.isActive && classes.listActive)}
                          onClick={() => handleInvoiceStatisticClick(key)}
                        >
                          <Components.Typography>{titleInvoiceCards[key]}</Components.Typography>
                          <Components.Typography variant='h6'>
                            {item.total}{' '}
                            <span className={classes.colorGreen}>
                              (Nominal: <NumberFormat value={item.totalPrice || 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />)
                            </span>
                          </Components.Typography>
                        </Components.Grid>
                      ))}
                </Components.Grid>
              }
              chartHeader={
                <FlexBox container columnGap={1} alignItems='center' xs={12}>
                  <TimelineDot color='primary' />
                  <Components.Typography>Total {titleInvoiceCardActive}</Components.Typography>
                </FlexBox>
              }
            />
          </PaperCustom>
        </Components.Grid>

        <Components.Grid xs={12}>
          <section ref={deleverySectionRef} />
          <Pages.TitleSection title='Statistik Surat Jalan'>
            <Components.Typography color='textSecondary'>
              Periode: {`${monthNames[selectedDate.getMonth()]} ${selectedDate.getFullYear()}`}, Gudang {wareHouseSelected.name}
            </Components.Typography>
          </Pages.TitleSection>

          <PaperCustom>
            <Pages.StatisticSection
              tooltipTitle={titleManifestCardActive}
              dataChart={chartManifestStatistic}
              contentCard={
                <Components.Grid xs={12} className={classes.statisticContainer}>
                  {manifestStatistic.isLoading
                    ? [...new Array(4)].map((val, key) => (
                        <Components.Grid className={clsx(classes.listContainer, classes.boxShadow)} key={val}>
                          <Components.Typography>{titleManifestCards[key]}</Components.Typography>
                          <Components.Typography>
                            <Skeleton animation='wave' />
                          </Components.Typography>
                        </Components.Grid>
                      ))
                    : manifestStatistic.data.map((item, key) => (
                        <Components.Grid
                          className={clsx(classes.listContainer, classes.boxShadow, item.isActive && classes.listActive)}
                          onClick={() => handleManifestStatisticClick(key)}
                        >
                          <Components.Typography>{titleManifestCards[key]}</Components.Typography>
                          <Components.Typography variant='h6'>
                            {item.total}
                            <span className={classes.colorGreen}> (Total Koli: {item.totalColly})</span>
                          </Components.Typography>
                        </Components.Grid>
                      ))}
                </Components.Grid>
              }
              chartHeader={
                <FlexBox container columnGap={1} alignItems='center' xs={12}>
                  <TimelineDot color='primary' />
                  <Components.Typography>Total {titleManifestCardActive}</Components.Typography>
                </FlexBox>
              }
            />
          </PaperCustom>
        </Components.Grid>

        <Pages.SectionOption lists={listOptions} handleClick={handleSectionPage} />
      </Components.Container>
    </Page>
  );
};

export default Dashboard;
