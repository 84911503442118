import React, { useState } from 'react';
import { Button, Divider, Grid, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { GREY, YELLOW_MOBILE } from 'constants/colors';
import NumberFormat from 'react-number-format';
import ModalDelete from './ModalDelete';
import { ExpandLess, ExpandMore, PhotoSizeSelectActualOutlined } from '@material-ui/icons';
import { format } from 'date-fns';

type typeDiscount = {
  discountUnit: number;
  orderDate: string;
  price: number;
};

interface Props {
  imageUrl: string;
  productName: string;
  note: string;
  idClickDiscount: number;
  expandHistory: boolean;
  discountResource: typeDiscount[];
  handleChangeNote: (id: number, note: string) => void;
  onClickEdit: () => void;
  onClickDelete: () => void;
  handleOpenImage: (imagePath: string) => void;
  handleShowHistory: (id: number) => void;
  price: number;
  productId: number;
  qtyRequest: number;
  discountUnit: number;
  typeUnit: string;
  setExpandHistory: React.Dispatch<React.SetStateAction<boolean>>;
}
const useStyles = makeStyles((theme: Theme) => ({
  button: {
    borderRadius: '15px',
    padding: '3px 0'
  },
  fontText: {
    fontSize: '0.9rem'
  }
}));
const ProductReview: React.FC<Props> = props => {
  const classes = useStyles();
  const {
    imageUrl,
    onClickEdit,
    productName,
    onClickDelete,
    price,
    handleOpenImage,
    discountResource,
    handleShowHistory,
    qtyRequest,
    idClickDiscount,
    discountUnit,
    handleChangeNote,
    expandHistory,
    setExpandHistory,
    productId,
    typeUnit,

    note
  } = props;
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [isDiscount, setIsDiscount] = useState<boolean>(false);
  return (
    <Grid xs={12} container>
      <Grid xs={3}>
        {imageUrl ? (
          <img
            src={imageUrl}
            alt='product image'
            width={50}
            height={50}
            loading='lazy'
            onClick={() => handleOpenImage(imageUrl)}
            style={{ borderRadius: '.5em' }}
          />
        ) : (
          <Grid style={{ backgroundColor: GREY, height: '51px', width: '51px', borderRadius: '.5em', display: 'grid', placeItems: 'center' }}>
            <PhotoSizeSelectActualOutlined />
          </Grid>
        )}
      </Grid>
      <Grid xs={9}>
        <Typography gutterBottom variant='h6' component='h6' className={classes.fontText} style={{ marginLeft: '0.3em' }}>
          {productName}
        </Typography>
      </Grid>

      <Grid xs={12} container>
        <Grid xs={6} container alignItems='flex-start' direction='column'>
          <Typography gutterBottom className={classes.fontText} component='h6' color='textSecondary'>
            Qty Request
          </Typography>
          <Typography gutterBottom className={classes.fontText} component='h6' color='textSecondary'>
            Harga/item
          </Typography>

          <Typography gutterBottom className={classes.fontText} component='h6' color='textSecondary'>
            Diskon/item
          </Typography>
          <Typography gutterBottom className={classes.fontText} component='h6' color='textSecondary'>
            Total Harga
          </Typography>
        </Grid>
        <Grid xs={6} container alignItems='flex-end' direction='column'>
          <Typography gutterBottom className={classes.fontText} component='h6'>
            {qtyRequest} {typeUnit}
          </Typography>
          <Typography gutterBottom className={classes.fontText} component='h6'>
            <NumberFormat value={price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          </Typography>
          <Typography gutterBottom className={classes.fontText} component='h6' style={{ color: YELLOW_MOBILE }}>
            <NumberFormat value={discountUnit} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          </Typography>
          <Typography gutterBottom className={classes.fontText} component='h6'>
            <NumberFormat value={price * qtyRequest - discountUnit * qtyRequest} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          </Typography>
        </Grid>
      </Grid>
      <Grid xs={12} style={{ marginBottom: '0.5em' }}>
        <Divider />
      </Grid>
      <Grid xs={12} container alignItems='center' justify='space-between'>
        <Grid xs={5}>
          <Button
            size='small'
            fullWidth
            className={classes.button}
            variant='outlined'
            onClick={() => setOpenDelete(true)}
            style={{ color: 'grey', border: '1px solid grey' }}
          >
            Hapus
          </Button>
        </Grid>

        <Grid xs={5}>
          <Button size='small' fullWidth className={classes.button} variant='outlined' color='primary' onClick={onClickEdit}>
            EDIT
          </Button>
        </Grid>
      </Grid>
      <ModalDelete
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        deleteSalesOrderItem={() => {
          onClickDelete();
          setOpenDelete(false);
        }}
      />
      <Grid xs={12} style={{ margin: '0.5em 0' }}>
        <Divider />
      </Grid>
      <Grid xs={12}>
        <TextField
          label='Catatan'
          placeholder='Masukkan catatan disini'
          multiline
          fullWidth
          onChange={event => handleChangeNote(productId, event.target.value)}
          rows={4}
          value={note}
          defaultValue='Default Value'
          variant='outlined'
        />
      </Grid>
      <Grid xs={12} style={{ margin: '0.5em 0' }}>
        <Divider />
      </Grid>
      <Grid xs={12} container justify='space-between' alignItems='center'>
        <Typography variant='h6'>Riwayat Diskon/Item</Typography>
        {isDiscount && idClickDiscount === productId ? (
          <ExpandLess style={{ cursor: 'poiner' }} onClick={() => setIsDiscount(false)} />
        ) : (
          <ExpandMore
            style={{ cursor: 'poiner' }}
            onClick={() => {
              setIsDiscount(true);
              handleShowHistory(productId);
              setExpandHistory(!expandHistory);
            }}
          />
        )}
      </Grid>
      {isDiscount && idClickDiscount === productId ? (
        discountResource.length > 0 ? (
          <Grid xs={12} container>
            <Grid xs={6}>
              <Typography>Diskon/Item & Tanggal</Typography>
            </Grid>
            <Grid xs={6}>
              <Typography align='right'>Harga Setelah Diskon</Typography>
            </Grid>

            {discountResource.map((value, key) => (
              <Grid xs={12} key={key} container>
                <Grid xs={6}>
                  <Typography style={{ color: YELLOW_MOBILE }}>
                    <NumberFormat value={value.discountUnit} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  </Typography>
                  <Typography style={{ color: 'lightgrey', fontSize: '12px' }}>{format(new Date(value.orderDate), 'dd-MM-yyyy')}</Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography align='right'>
                    <NumberFormat value={value.price - value.discountUnit} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid xs={12}>
            <Typography>Tidak ada riwayat diskon</Typography>
          </Grid>
        )
      ) : null}
    </Grid>
  );
};

export default ProductReview;
