import React, { FC } from 'react';
import { Checkbox, Grid, LinearProgress, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { BodyTable } from './DeliveryInvoiceTable';
import { Autocomplete, Skeleton } from '@material-ui/lab';

interface Props {
  deliveryInvoice: DeliveryInvoice[];
  isLoading: boolean;
  isLoadingPartner: boolean;
  partner: string;
  wareHouse: string;
  wareHouses: WareHouseModel[];
  partners: { id: number; namePartner: string; route: string }[];
  salesName: string;
  invoiceNumber: string;
  handleOpenModalInfo: (invoiceId: number[]) => void;
  setSalesName: React.Dispatch<React.SetStateAction<string>>;
  setPartner: React.Dispatch<React.SetStateAction<string>>;
  setWareHouse: React.Dispatch<React.SetStateAction<string>>;
  setInvoiceNumber: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  containerBox: {
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    borderRadius: '5px',
    marginTop: '0.5em'
  }
}));

const BodySection: FC<Props> = (props): JSX.Element => {
  const {
    deliveryInvoice,
    isLoading,
    partner,
    salesName,
    invoiceNumber,
    wareHouses,
    wareHouse,
    setSalesName,
    setInvoiceNumber,
    setPartner,
    partners,
    isLoadingPartner,
    setWareHouse,
    handleOpenModalInfo
  } = props;
  const classes = useStyles();
  return (
    <Grid xs={12}>
      <Grid container xs={12} style={{ display: 'flex' }}>
        <Grid style={{ flex: 0.22 }}>
          <Typography color='textSecondary'>No Invoice</Typography>
          <TextField
            placeholder='No Invoice'
            value={invoiceNumber}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInvoiceNumber(event.target.value)}
          />
          {/* <Grid container style={{ columnGap: '5px' }}>
            <Checkbox
              color='primary'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              checked={isCheckedAll}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCheckListAll(event.target.checked)}
            />
            <TextField
              placeholder='No Invoice'
              value={invoiceNumber}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInvoiceNumber(event.target.value)}
            />
          </Grid> */}
        </Grid>
        <Grid style={{ flex: 0.11 }}>
          <Typography color='textSecondary' align='center'>
            Tanggal Invoice
          </Typography>
        </Grid>
        <Grid style={{ flex: 0.19 }}>
          <Typography color='textSecondary'>Customer</Typography>
          <Autocomplete
            onChange={(event, value) => setPartner(value || '')}
            loading={isLoadingPartner}
            loadingText={<LinearProgress />}
            disableListWrap
            options={partners.map(val => val.namePartner)}
            PaperComponent={({ children }) => <Grid className={classes.containerBox}>{children}</Grid>}
            renderInput={params => <TextField {...params} label='Pilih Customer' variant='outlined' />}
          />
        </Grid>
        <Grid style={{ flex: 0.08 }}>
          <Typography color='textSecondary'>Total Koli</Typography>
        </Grid>
        <Grid style={{ flex: 0.09 }}>
          <Typography color='textSecondary' align='center'>
            Rute
          </Typography>
        </Grid>
        <Grid style={{ flex: 0.13 }}>
          <Typography color='textSecondary'>Nama Sales</Typography>
          <TextField
            placeholder='Nama Sales'
            fullWidth
            value={salesName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSalesName(event.target.value)}
          />
        </Grid>
        <Grid style={{ flex: 0.19, paddingLeft: '0.2em' }}>
          <Typography color='textSecondary'>Gudang</Typography>
          <Autocomplete
            // value={value}
            // onChange={(event, newValue) => {
            //   setValue(newValue);
            // }}
            inputValue={wareHouse}
            onInputChange={(event, newInputValue) => {
              setWareHouse(newInputValue);
            }}
            // id='controllable-states-demo'
            options={wareHouses.map(val => val.name)}
            PaperComponent={({ children }) => <Grid className={classes.containerBox}>{children}</Grid>}
            // style={{ width: 300 }}
            renderInput={params => <TextField {...params} label='Gudang' variant='outlined' />}
          />
        </Grid>
      </Grid>

      <Grid xs={12}>
        {isLoading ? (
          <Grid xs={12}>
            {[...new Array(3)].map(val => (
              <Skeleton height={70} key={val} />
            ))}
          </Grid>
        ) : deliveryInvoice.length ? (
          deliveryInvoice.map((deliveryInvoice, key) => (
            <Grid xs={12} container direction='column' style={{ marginTop: '0.7em' }}>
              <BodyTable deliveryInvoice={deliveryInvoice} key={key} handleOpenModalInfo={handleOpenModalInfo} />
            </Grid>
          ))
        ) : (
          <Grid xs={12}>
            <Grid xs={12} container direction='column' alignItems='center' justify='center' style={{ minHeight: '25vh' }}>
              <Typography variant='h6'>Data Tidak Tersedia</Typography>
              {/* <Typography>Pilih Customer/Gudang untuk menampilkan invoice</Typography> */}
            </Grid>
          </Grid>
        )}
      </Grid>

      {/* <TableContainer>
        <TableCustom>
          <TableHead>
            <HeaderRow
              // order={order}
              // orderBy={orderBy}
              // onRequestSort={handleRequestSort}
              headers={[
                { id: 'doNumber', label: 'Nomor Surat Jalan', sort: true },
                { id: 'createdAt', label: 'Tgl Surat Jalan', sort: true },
                { id: 'name', label: 'Nama Customer', sort: true },
                { id: 'zone', label: 'RUTE', sort: true, align: 'center' },
                { id: 'totalColly', label: 'Total Koli', sort: true },
                { id: 'invoiceNumber', label: 'Invoice Rujukan', sort: true },
                { id: 'nameSales', label: 'NAMA SALES', sort: true },
                { label: '' }
              ]}
            />
            <TableRow>
              <TableCellHead variant='head' className={classes.cellTable1}>
                <TextField placeholder='Nomor surat jalan' />
              </TableCellHead>
              <TableCellHead variant='head' className={classes.cellTable1} />
              <TableCellHead variant='head' className={classes.cellTable1}>
                <TextField placeholder='Nama Customer' />
              </TableCellHead>
              <TableCellHead variant='head' className={classes.cellTable3}>
                <Select
                  id='demo-mutiple-checkbox'
                  margin='dense'
                  style={{ marginTop: '0.4em' }}
                  multiple
                  variant='outlined'
                  fullWidth
                  value={zone}
                  onChange={e => setZone(e.target.value as number[])}
                  renderValue={selected =>
                    `${zones
                      .filter(value => (selected as number[]).includes(value.id as number))
                      .map(value => value.name)
                      .join(',')
                      .substr(0, 8)}...`
                  }
                >
                  {zones.map(value => (
                    <MenuItem key={value.id} value={value.id}>
                      <Checkbox color='primary' checked={zone.indexOf(value.id) > -1} />
                      <ListItemText primary={value.name} />
                    </MenuItem>
                  ))}
                </Select>
              </TableCellHead>

              <TableCellHead variant='head' className={classes.cellTable2} />
              <TableCellHead variant='head' className={classes.cellTable2} />
              <TableCellHead variant='head' className={classes.cellTable}>
                <TextField placeholder='Nama Sales' />
              </TableCellHead>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <h6>Loading</h6>
            ) : deliveryOrders.length > 0 ? (
              deliveryOrders.map((deliveryOrder, key) => <BodyTable deliveryOrder={deliveryOrder} />)
            ) : (
              <TableRow>
                <TableCell colSpan={7} align='center'>
                  Data Tidak Tersedia.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </TableCustom>
      </TableContainer> */}
    </Grid>
  );
};

export default BodySection;
