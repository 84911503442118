import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { GREEN, PRIMARY_MOBILE, WHITE, YELLOW_MOBILE } from 'constants/colors';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { titleCards } from 'pages/DashboardPage';
import { InfoRounded } from '@material-ui/icons';

interface item {
  title: string;
  totalItem: number;
  totalItemToday: number;
  totalPrice: number;
  totalPriceToday: number;
  isActive: boolean;
}
interface Props {
  isLoading: boolean;
  items: item[];
  handleClick: (key: number) => void;
}

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '1.5em'
  },

  boxShadow: {
    boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 20%) 0px 1px 3px 0px'
  },

  tooltipWrapper: {
    position: 'absolute',
    background: WHITE,
    left: 20,
    bottom: 500,
    opacity: 0,
    borderRadius: '.4em',
    transition: 'all .5s cubic-bezier(.68, -0.55, .265, 1.55)',
    padding: '.7em 1em',
    '&::after': {
      content: '""',
      position: 'absolute',
      borderBottom: '1px solid rgb(0 0 0 / 20%)',
      borderRight: '1px solid rgb(0 0 0 / 20%)',
      height: '1em',
      width: '1em',
      background: WHITE,
      right: '2em',
      bottom: '-8px',
      transform: 'translateX(-50%) rotate(45deg)'
    }
  },

  tooltipShow: {
    opacity: 1,
    bottom: 150
  },
  listContainer: {
    position: 'relative',
    borderRadius: '.4em',
    padding: '1em',
    cursor: 'pointer',
    backgroundColor: '#ffffff10',
    transition: 'all .3s ease',
    '&:hover': {
      transform: 'translateY(-7px)',
      border: `2px solid ${PRIMARY_MOBILE}`
    }
  },
  listActive: {
    border: `2px solid ${PRIMARY_MOBILE}`
  },
  colorGreen: {
    color: GREEN
  },
  infoIcon: {
    transition: 'all .3s ease-in-out',
    '&:hover': {
      scale: 1.2
    }
  }
});

const jobInformations = [
  'SO dan nominalnya yang baru dibuat oleh sales namun masih belum di dikerjakan oleh picker',
  'SO dan nominalnya yang sudah dikerjakan oleh picker namun masih belum dikerjakan oleh checker',
  'SO dan nominalnya yang sudah di kerjakan oleh checker namun masih belum di kerjakan oleh packer',
  'SO yang sudah dilakukan proses pengepakannya dan sudah memiliki invoice'
];

const TotalSection = ({ isLoading, items, handleClick }: Props) => {
  const classes = useStyles();

  const [isShowInformation, setIsShowInformation] = useState<{ isOpen: boolean; key: number }>({
    isOpen: false,
    key: 0
  });

  const tooltipContent = (key: number) => {
    return (
      <Grid
        container
        direction='column'
        key={key}
        className={clsx(classes.tooltipWrapper, classes.boxShadow, isShowInformation.isOpen && isShowInformation.key === key && classes.tooltipShow)}
        xs={12}
      >
        <Typography variant='h6'>Keterangan Pekerjaan: </Typography>
        <Divider />
        <Typography align='justify'>{jobInformations[key]}</Typography>
      </Grid>
    );
  };
  return (
    <Grid xs={12} className={classes.container}>
      {isLoading
        ? [...new Array(4)].map((val, key) => (
            <Grid className={clsx(classes.listContainer, classes.boxShadow)} key={val}>
              {[...new Array(5)].map((skelaton, index) =>
                index === 0 ? (
                  <Typography>{titleCards[key]}</Typography>
                ) : (
                  <Typography>
                    <Skeleton animation='wave' />
                  </Typography>
                )
              )}
            </Grid>
          ))
        : items.map((item, key) => (
            <Grid
              className={clsx(classes.listContainer, classes.boxShadow, item.isActive && classes.listActive)}
              key={titleCards[key]}
              onClick={() => handleClick(key)}
            >
              <Grid xs={12} container justify='space-between' alignItems='center'>
                <Typography>{titleCards[key]}</Typography>

                <InfoRounded
                  className={classes.infoIcon}
                  color='secondary'
                  onMouseOver={() => {
                    setIsShowInformation(prev => ({ ...prev, isOpen: true, key }));
                  }}
                  onMouseOut={() => {
                    setIsShowInformation(prev => ({ ...prev, isOpen: false }));
                  }}
                />
              </Grid>
              <Typography variant='h6'>
                {item.totalItem}{' '}
                <span className={classes.colorGreen}>
                  (
                  <NumberFormat value={item.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />)
                </span>
              </Typography>
              <Typography color='textSecondary'>{item.totalItemToday} SO SELESAI HARI INI</Typography>
              <Typography color='textSecondary'>
                Total:
                <span className={classes.colorGreen}>
                  <NumberFormat value={item.totalPriceToday} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </span>
              </Typography>

              {tooltipContent(key)}
            </Grid>
          ))}
    </Grid>
  );
};

export default TotalSection;
