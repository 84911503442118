import React, { FC, useState, useEffect, useContext } from 'react';
import axios, { CancelTokenSource } from 'axios';
import useRouter from 'hooks/useRouter';
import { Button, CircularProgress, Container, Grid, makeStyles, Tooltip, Typography, Link } from '@material-ui/core';
import { Page, Breadcrumb, PaperCustom } from 'components';
import { WHITE } from 'constants/colors';
import {
  GET_PURCHASE_INVOICE_PAYMENT_DETAIL_BASE_URL,
  COMPANY_BASE_URL,
  GET_PURCHASE_INVOICE_DETAIL_BASE_URL,
  RETURN_INVOICE_BASE_URL,
  PURCHASE_PAYMENT_BASE_URL
} from 'constants/url';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { dummyCompany, dummyPurchaseInvoicePayment } from 'utils/dummy';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';
import RefreshIcon from '@material-ui/icons/Refresh';
import PaymentMethod from 'typings/enum/PaymentMethod';
import Content from './components/content';
import DetailPage from './components/DetailPage';
import DetailBank from './components/DetailBank';
import { CloudDownloadRounded } from '@material-ui/icons';
import TypeUser from 'typings/enum/TypeUser';
import useRole from 'hooks/useRole';

const useStyles = makeStyles({
  box: {
    backgroundColor: WHITE,
    borderRadius: '5px',
    padding: 24,
    border: '1px solid #F2F2F2',
    boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.08)'
  },
  boxTitle: {
    marginBottom: 8
  },
  pymentImages: {
    objectFit: 'cover',
    width: '100%'
  },
  containerDownloadAction: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.5em',
    marginBottom: '0.5em'
  }
});

const PurchasePaymentDetailPage: FC = () => {
  const { match, history } = useRouter();
  // eslint-disable-next-line
  const params: any = match.params;
  const classes = useStyles();

  const [invoicePayment, setInvoicePayment] = useState<PurchaseInvoicePaymentModel>(dummyPurchaseInvoicePayment);

  const [invoicePiece, setInvoicePiece] = useState<InvoicePieceModel[]>([]);
  const { currentUser } = useContext(CurrentUserContext);
  const [invoiceReturn, setInvoiceReturn] = useState<InvoiceReturnModel[]>([]);
  const [company, setCompany] = useState<CompanyModel>(dummyCompany);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [isLoadingPdf, setIsLoadingPdf] = useState<boolean>(false);
  const [totalPay, setTotalPay] = useState<number>(0);
  const [id, setId] = useState<number>(0);

  const isAccounting = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: [TypeUser.ACCOUNTING]
  });
  const fetchData = async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);
    try {
      const { data } = await axios.get(GET_PURCHASE_INVOICE_PAYMENT_DETAIL_BASE_URL(params.id), { cancelToken: cancelTokenSource.token });
      setInvoicePayment(data.data);
      setId(data.data.id);

      await fetchInvoice(data.data.PurchaseInvoicePaymentMultiple.map((val: any) => val.PurchaseInvoiceId));

      if (data.data.useReturn) {
        await fetchReturn(data.data.ReturnId.map((val: any) => val.ReturnId));
      }
    } catch (error) {
      console.log('error:', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const fetchReturn = async (id: number[]) => {
    try {
      const invoiceReturn = await axios.get(`${RETURN_INVOICE_BASE_URL}?id=${id.join(',')}`);
      setInvoiceReturn(invoiceReturn.data.data);
    } catch (e) {
      console.log('error', e);
    } finally {
    }
  };

  const fetchInvoice = async (id: number[]) => {
    id.forEach(async val => {
      try {
        const { data } = await axios.get(GET_PURCHASE_INVOICE_DETAIL_BASE_URL(val));
        data.data.InvoicePiece.length > 0 && setInvoicePiece([...invoicePiece, data.data.InvoicePiece].flat());
      } catch (e) {
        console.log('error', e);
      }
    });
  };

  const fetchCompany = async () => {
    try {
      const { data } = await axios.get(COMPANY_BASE_URL);
      setCompany(data.data);
    } catch (e) {
      console.log('error', e);
    }
  };

  const downloadPdf = async () => {
    setIsLoadingPdf(true);
    try {
      const { data } = await axios.get(`${PURCHASE_PAYMENT_BASE_URL}/download/${id}`, { responseType: 'blob' });

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'kwitansi.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log('error :', error);
      setIsLoadingPdf(false);
    } finally {
      setIsLoadingPdf(false);
    }
  };

  const handleEdit = () => {
    history.push('/pembayaran-penjualan/tambah', { id: invoicePayment.id });
  };

  useEffect(() => {
    if (invoicePayment.id === 0) return;

    setTotalPay(invoicePayment.totalPay + (invoicePayment.totalReturn || 0));
  }, [invoicePayment]);

  useEffect(() => {
    if (!params || params.id < 1 || isLoadingData) {
      return;
    }

    fetchData();
    fetchCompany();
  }, [params]);

  return (
    <Page title='Detail Pembayaran Pembelian'>
      <Container>
        <Grid container direction='row'>
          <Grid item lg={6} sm={6} md={6} xs={6}>
            <Typography variant='h1'> Detail Pembayaran Pembelian </Typography>
          </Grid>
          <Grid container item lg={6} sm={6} md={6} xs={6} spacing={1} justify='flex-end'>
            <Grid item>
              <Tooltip title='Refresh'>
                <Button onClick={() => fetchData()} color='inherit'>
                  <RefreshIcon />
                </Button>
              </Tooltip>
            </Grid>
            {!isAccounting && (
              <Grid item>
                <Button onClick={downloadPdf}>{isLoadingPdf ? <CircularProgress size={20} color='inherit' /> : 'Download PDF'}</Button>
              </Grid>
            )}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <Grid container justify='space-between' className={classes.box}>
          <Grid item>
            <Typography align='center' variant='h5' className={classes.boxTitle}>
              Nama Customer
            </Typography>
            <Typography align='center' variant='body2'>
              {invoicePayment.partnerName || '-'}
            </Typography>
          </Grid>

          <Grid item>
            <Typography align='center' variant='h5' className={classes.boxTitle}>
              Tanggal Terbit
            </Typography>
            <Typography align='center' variant='body2'>
              {format(new Date(invoicePayment.payDate), 'dd/MM/yyyy')}
            </Typography>
          </Grid>

          <Grid item>
            <Typography align='center' variant='h5' className={classes.boxTitle}>
              Metode Pembayaran
            </Typography>
            <Typography align='center' variant='body2'>
              {invoicePayment.paymentMethod}
              {(invoicePayment.paymentMethod !== PaymentMethod.RETURN && invoicePayment.useReturn && ' + RETURN') || ''}
            </Typography>
          </Grid>

          <Grid item>
            <Typography align='center' variant='h5' className={classes.boxTitle}>
              Total Jumlah
            </Typography>
            <Typography variant='body2' align='right'>
              <NumberFormat value={totalPay} prefix={'Rp'} thousandSeparator={true} displayType='text' />
            </Typography>
          </Grid>
          <Grid item>
            <Typography align='center' variant='h5' className={classes.boxTitle}>
              Diterima Oleh
            </Typography>
            <Typography variant='body2' align='right'>
              {(invoicePayment.createdByName && invoicePayment.createdByName) || '-'}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container direction='row' justify='space-between' spacing={2}>
          <Grid item lg={9} sm={12} md={9} xs={12}>
            <Content
              invoicePayment={invoicePayment}
              company={company}
              isLoadingData={isLoadingData}
              invoiceReturn={invoiceReturn}
              invoicePiece={invoicePiece}
            />
          </Grid>
          {invoicePayment.paymentMethod === PaymentMethod.GIRO && (
            <Grid item lg={3} sm={12} md={3} xs={12}>
              <DetailPage invoicePayment={invoicePayment} company={company} isLoadingData={isLoadingData} />
            </Grid>
          )}
          {invoicePayment.paymentMethod === PaymentMethod.BANK_TRANSFER && (
            <Grid item lg={3} sm={12} md={3} xs={12}>
              <PaperCustom>
                <Typography variant='h5'>Semua Media</Typography>
                {invoicePayment.PurchaseInvoicePaymentImages && invoicePayment.PurchaseInvoicePaymentImages.length > 0 ? (
                  invoicePayment.PurchaseInvoicePaymentImages.map((val, key) => (
                    <Grid xs={12} key={key}>
                      <img src={val.url} alt={`image payment ${key}`} loading='lazy' className={classes.pymentImages} />
                      <Typography variant='h6' className={classes.containerDownloadAction}>
                        <CloudDownloadRounded color='primary' />
                        <Link href={val.url} download='Bukti Tranfer.jpg'>
                          Download Gambar
                        </Link>
                      </Typography>
                    </Grid>
                  ))
                ) : (
                  <Grid xs={12}>
                    <Typography variant='h6' align='center'>
                      No Media
                    </Typography>
                  </Grid>
                )}
              </PaperCustom>
            </Grid>
          )}

          {invoicePayment.paymentMethod === PaymentMethod.BANK_TRANSFER && invoicePayment.useReturn && (
            <Grid item lg={3} sm={12} md={3} xs={12}>
              <DetailBank isLoadingData={isLoadingData} invoicePayment={invoicePayment} />
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
};
export default PurchasePaymentDetailPage;
