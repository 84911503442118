import React, { FC, Fragment, useEffect, useState, useContext } from 'react';
import axios, { CancelTokenSource } from 'axios';
import {
  Grid,
  Container,
  Typography,
  makeStyles,
  Button,
  TextField,
  CircularProgress,
  Theme,
  FormHelperText,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Fade,
  RadioGroup,
  Radio,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { Page, StandardConfirmationDialog, PaperCustom, Breadcrumb, CardCustomer } from 'components';
import {
  PARTNER_BASE_URL,
  PURCHASE_INVOICE_BASE_URL,
  PURCHASE_PAYMENT_BASE_URL,
  GET_INVOICE_PAYMENT_DETAIL_BASE_URL,
  BANK_BASE_URL,
  RETURN_INVOICE_BASE_URL,
  GET_INVOICE_DETAIL_BASE_URL,
  PARTNER_DETAIL_BASE_URL,
  GET_INVOICE_PAYMENT_RETURN_BASE_URL,
  RETURN_BILL_BASE_URL
} from 'constants/url';
import { format } from 'date-fns';
import { dummyPartner, dummyBank } from 'utils/dummy';
import { BACKGROUND, BLUE_PRIMARY, GRAY_3, GREEN, PRIMARY_MOBILE, RED_MOBILE, WHITE } from 'constants/colors';
import { diff } from 'utils';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import useRouter from 'hooks/useRouter';
import Autocomplete, { AutocompleteInputChangeReason } from '@material-ui/lab/Autocomplete';
import NumberFormat from 'react-number-format';
import PaymentMethod from 'typings/enum/PaymentMethod';
import PaymentStatus from 'typings/enum/PaymentStatus';
import InvoiceModal from './components/InvoiceModal';
import InvoiceTable from './components/InvoiceTable';
import InvoiceReturnTable from './components/InvoiceReturnTable';
import InvoiceReturnModal from './components/InvoiceReturnModal';
import ConfirmPaymentModal from './components/ConfirmPaymentModal';
import NumberFormatMask from 'components/NumberFormatMask';
import _ from 'lodash';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { AddRounded, CloseRounded, CloudUpload } from '@material-ui/icons';
import Close from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  },
  dropZone: {
    margin: '0 0.4em',
    padding: '1em',
    borderRadius: 2,
    border: `2px dashed ${BLUE_PRIMARY} `,
    backgroundColor: BACKGROUND,
    color: GRAY_3,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    verticalAlign: 'middle',
    textAlign: 'center'
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  thumb: {
    position: 'relative',
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginRight: 20,
    width: 150,
    height: 150,
    boxSizing: 'border-box'
  },
  closeIcon: {
    position: 'absolute',
    top: -10,
    right: -10,
    fontSize: '2rem',
    backgroundColor: '#fff',
    borderRadius: '10em',
    cursor: 'pointer',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)'
  },
  addIcon: {
    fontSize: '4rem',
    backgroundColor: '#fff',
    borderRadius: '10em',
    cursor: 'pointer',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  iconUpload: {
    fontSize: 100,
    color: BLUE_PRIMARY
  }
}));

const PurchasePaymentCreatePage: FC = () => {
  const classes = useStyles();
  const { history, location } = useRouter();
  const { currentUser } = useContext(CurrentUserContext);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  // eslint-disable-next-line
  const params: any = location.state;

  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const [partner, setPartner] = useState<PartnerModel>(dummyPartner);
  const [partners, setPartners] = useState<PartnerModel[]>([dummyPartner]);

  const [queryString, setQueryString] = useState<string>('');
  const [returnNumber, setReturnNumber] = useState<string>('');
  const [partnerName, setPartnerName] = useState<string>('');
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('id');

  const [paymentId, setPaymentId] = useState<number>(0);
  const [payDate, setPayDate] = useState<Date>(new Date());
  const [transferDate, setTransferDate] = useState<Date>(new Date());
  const [paymentMethod, setPaymentMethod] = useState<string>('');
  const [giroBank, setGiroBank] = useState<string>('');
  const [giroNumber, setGiroNumber] = useState<string>('');
  const [giroAmount, setGiroAmount] = useState<number>(0);
  const [giroDueDate, setGiroDueDate] = useState<Date>(new Date());
  const [note, setNote] = useState<string>('');
  const [fullPayment, setFullPayment] = useState<boolean>(false);
  const [useReturn, setUseReturn] = useState<boolean>(false);
  const [totalPay, setTotalPay] = useState<number>(0);
  const [currentTotalPay, setCurrentTotalPay] = useState<number>(0);
  const [currentTotalReturn, setCurrentTotalReturn] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalReturn, setTotalReturn] = useState<number>(0);

  const [image, setImage] = useState<{ path: string }[]>([]);
  const [imageBlob, setImageBlob] = useState<FileWithPath[]>([]);
  const [imageUrl, setImageUrl] = useState<string[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [isLoadingReturn, setLoadingReturn] = useState<boolean>(false);
  const [loadPartner, setLoadPartner] = useState<boolean>(false);
  const [loadingBank, setLoadingBank] = useState<boolean>(false);

  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [isFullReturn, setFullReturn] = useState<boolean>(false);

  const [totalPayError, setTotalPayError] = useState<string>('');
  const [paymentMessageError, setPaymentMessageError] = useState<string>('');
  const [paymentMethodMessage, setPaymentMethodMessage] = useState<string>('');
  const [paymentMethodImageMessage, setPaymentMethodImageMessage] = useState<string>('');
  const [payDateMessage, setPayDateMessage] = useState<string>('');

  const [giroBankMessage, setGiroBankMessage] = useState<string>('');
  const [giroNumberMessage, setGiroNumberMessage] = useState<string>('');
  const [giroAmountMessage, setGiroAmountMessage] = useState<string>('');
  const [giroDueDateMessage, setGiroDueDateMessage] = useState<string>('');

  const [openInvoice, setOpenInvoice] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<(InvoiceModel & { type: 'return' | 'invoice' })[]>([]);
  const [invoiceId, setInvoiceId] = useState<number>(0);
  const [invoiceReturn, setInvoiceReturn] = useState<InvoiceReturnModel[]>([]);

  const [checked, setChecked] = useState<InvoicePaymentModel[]>([]);
  const [checkedInvoice, setCheckedInvoice] = useState<(InvoiceModel & { type: 'return' | 'invoice' })[]>([]);
  const [checkedReturn, setCheckedReturn] = useState<InvoicePaymentModel[]>([]);
  const [checkedInvoiceReturn, setCheckedInvoiceReturn] = useState<InvoiceReturnModel[]>([]);

  const [partnerMessage, setPartnerMessage] = useState<string>('');
  const [openReturn, setOpenReturn] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [isRefresh, setRefresh] = useState<boolean>(false);
  const [disableMethod, setDisableMethod] = useState<boolean>(false);

  const [bank, setBank] = useState<BankModel>(dummyBank);
  const [banks, setBanks] = useState<BankModel[]>([dummyBank]);

  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpeg', 'image/png'],
    maxFiles: 5,
    onDrop: (acceptedFiles: FileWithPath[]) => {
      setImageUrl([]);
      setImage([]);
      setImageBlob([]);
      uploadImage(acceptedFiles);
    }
  });
  const uploadImage = (files: FileWithPath[]) => {
    files.map((value: any) => {
      setImageUrl([...imageUrl, URL.createObjectURL(value)]);
      setImage([...image, { path: `${partnerName}${new Date().getTime()}.${value.name.split('.').pop()}` }]);
      setImageBlob([...imageBlob, value]);
    });
  };

  const handleDeleteImage = (index: number): void => {
    setImageUrl(imageUrl.filter((val, key) => key !== index));
  };
  const handleSearchBank = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    try {
      setLoadingBank(true);
      const { data } = await axios.get(`${BANK_BASE_URL}?${params.toString()}`);
      setBanks(data.data);
    } catch (error) {
      console.log('error', error);
      handleSnackBar(true, 'error', 'Potongan telah gagal');
    } finally {
      setLoadingBank(false);
    }
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const fetchData = async () => {
    setIsLoadingData(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('number', queryString);
      }

      if (orderBy || order) {
        params.append('orderBy', orderBy);
        params.append('ordered', order);
      }

      if (partner) {
        params.append('partnerId', String(partner.id));
      }
      params.append('status', PaymentStatus.UNPAID);
      params.append('page', currentPage.toString());
      params.append('isPiece', 'true');
      return params.toString();
    };

    try {
      const { data } = await axios.get(`${PURCHASE_INVOICE_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });
      const { data: dataReturn } = await axios.get(`${RETURN_BILL_BASE_URL}?partnerId=${String(partner.id)}&status=UNPAID`, {
        cancelToken: cancelTokenSource.token
      });

      const newData = [
        ...data.data.map((value: any) => ({ ...value, type: 'invoice' })),
        ...dataReturn.data.map((value: any) => ({ ...value, type: 'return' }))
      ];
      setInvoices(newData);
      setCount(data.meta.last_page);
      setCurrentPage(data.meta.current_page);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const fetchReturnInvoice = async () => {
    setLoadingReturn(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (returnNumber) {
        params.append('number', returnNumber);
      }

      params.append('partnerId', partner.id.toString());
      params.append('usageStatus', 'false');
      return params.toString();
    };

    try {
      const { data } = await axios.get(`${RETURN_INVOICE_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });
      setInvoiceReturn(data.data);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoadingReturn(false);
    }
  };

  const fecthInvoicePayment = async () => {
    setIsLoadingData(true);

    try {
      const { data } = await axios.get(GET_INVOICE_PAYMENT_DETAIL_BASE_URL(params.id));

      setInvoiceId(data.data.InvoiceId);
      setChecked([data.data]);
      setPaymentMethod(data.data.paymentMethod);
      setPayDate(new Date(data.data.payDate));
      setNote(data.data.note);
      setUpdate(true);
      setTotalPay(data.data.totalPay);
      setCurrentTotalPay(data.data.totalPay);
      setFullReturn(data.data.paymentMethod === PaymentMethod.RETURN);
      setUseReturn(data.data.useReturn);
      fetchAll(
        data.data.PartnerId,
        data.data.InvoiceId,
        params.id,
        data.data.invoicePaymentReturn.map((value: any) => value.ReturnId as number)
      );
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const fetchAll = (prtId: number, invId: number, id: number, rtnId: number[]) => {
    Promise.all([
      axios.get(PARTNER_DETAIL_BASE_URL(prtId)).catch(() => undefined),
      axios.get(GET_INVOICE_DETAIL_BASE_URL(invId)).catch(() => undefined),
      axios.get(GET_INVOICE_PAYMENT_RETURN_BASE_URL(id)).catch(() => undefined),
      axios.get(`${RETURN_INVOICE_BASE_URL}?id=${rtnId.join(',')}`).catch(() => undefined)
    ]).then((result: any) => {
      const [data0, data1, data2, data3] = result;

      if (data0) {
        setPartner(data0.data.data);
        setPartners([data0.data.data]);
      }

      if (data1) {
        setCheckedInvoice([data1.data.data]);
        // setInvoicePiece(data1.data.data.InvoicePiece);
      }

      if (data2) {
        const _totalReturn = data2.data.data.length > 0 ? data2.data.data.map((value: any) => value.totalPay).reduce((a: any, b: any) => a + b) : 0;
        setCheckedReturn(data2.data.data);
        setCurrentTotalReturn(_totalReturn);
      }

      if (data3) {
        setCheckedInvoiceReturn(data3.data.data);
      }
    });
  };

  const fetchDeleteReturn = async (id: number) => {
    try {
      await axios.delete(GET_INVOICE_PAYMENT_RETURN_BASE_URL(id));
    } catch (e) {
      console.log('error', e);
    } finally {
      setRefresh(true);
      setPaymentMessageError('MOHON UNTUK MELAKUKAN MUAT ULANG.');
    }
  };

  const handleSearchPartner = async () => {
    const params = new URLSearchParams();
    params.append('keyword', partnerName);
    params.append('partnerType', 'SUPPLIER');

    setLoadPartner(true);
    setPartnerMessage('');

    try {
      const { data } = await axios.get(`${PARTNER_BASE_URL}?${params.toString()}`);
      setPartners(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadPartner(false);
    }
  };

  const handleOnRefresh = () => {
    setCheckedReturn([]);
    setCheckedInvoiceReturn([]);
    setCheckedInvoice([]);
    setChecked([]);
    setUseReturn(false);
    setPaymentMethod('');
    setNote('');
    setPartner(dummyPartner);
    setTotalPay(0);
    setTotalPrice(0);
    setTotalReturn(0);
    setFullReturn(false);
    setCurrentTotalPay(0);
    setCurrentTotalReturn(0);
    setInvoiceId(0);
    setRefresh(false);
    setPaymentMessageError('');

    fecthInvoicePayment();
  };

  const validation = () => {
    let valid = true;

    if (!isFullReturn) {
      if (!totalPay) {
        setTotalPayError('Jumlah Bayar tidak boleh 0.');
        valid = false;
      }

      if (totalPay < 0) {
        setTotalPayError('Jumlah Bayar tidak boleh kurang dari 0.');
        valid = false;
      }

      if (totalPay > totalPrice - totalReturn) {
        setTotalPayError('Jumlah Bayar tidak boleh lebih dari Total Tertagih.');
        valid = false;
      }
    }

    // if (paymentMethod === PaymentMethod.BANK_TRANSFER && image.length < 1) {
    //   setPaymentMethodImageMessage('lampiran tidak boleh kosong.');
    //   valid = false;
    // }

    if (paymentMethod === '' || !paymentMethod) {
      setPaymentMethodMessage('Metode Pembayaran tidak boleh kosong.');
      valid = false;
    }

    if (!payDate) {
      setPayDateMessage('Tanggal Pembayaran tidak boleh kosong.');
      valid = false;
    }

    if (!partner || partner.id === 0) {
      setPartnerMessage('Supplier tidak boleh kosong');
      valid = false;
    }

    if (checked.length === 0) {
      setPaymentMessageError('Invoice tidak boleh kosong');
      valid = false;
    }

    if (useReturn && checkedReturn.length === 0) {
      setPaymentMessageError('Jika menggunakan return mohon pilih invoice return terlebih dahulu');
      valid = false;
    }

    if (paymentMethod === PaymentMethod.GIRO) {
      if (!giroBank) {
        setGiroBankMessage('Bank Penerbit Giro tidak boleh kosong.');
        valid = false;
      }

      if (!giroNumber) {
        setGiroNumberMessage('Nomor Giro tidak boleh kosong.');
        valid = false;
      }

      if (!giroAmount) {
        setGiroAmountMessage('Nominal Giro tidak boleh kosong.');
        valid = false;
      }

      if (!giroDueDate) {
        setGiroDueDateMessage('Tanggal Jatuh Tempo Giro tidak boleh kosong.');
        valid = false;
      }
    }

    if (isFullReturn || paymentMethod === PaymentMethod.RETURN) {
      if (totalPrice !== totalReturn) {
        setPaymentMessageError('Jika Pembayaran penuh menggunakan RETURN total tagihan harus sesuai dengan jumlah Return');
        valid = false;
      }
    }

    return valid;
  };

  const resetValidation = () => {
    setTotalPayError('');
    setPaymentMethodMessage('');
    setPaymentMethodImageMessage('');
    setPayDateMessage('');
    setPartnerMessage('');
    setPaymentMessageError('');
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
    handleCloseConfirm();
  };

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
    history.push(`/pembayaran-pembelian/${paymentId}`);
  };

  const handleCancel = () => {
    history.push('/pembayaran-pembelian');
  };

  const individualCheck = (item: InvoicePaymentModel) => {
    setChecked(prevState => {
      if (prevState.some(value => value.InvoiceId === item.InvoiceId)) {
        setCheckedInvoice(prev => prev.filter(value => value.id !== item.InvoiceId));
        setInvoiceId(0);

        return prevState.filter(value => value.InvoiceId !== item.InvoiceId);
      } else {
        const _invoice = invoices.find(value => value.id === item.InvoiceId);

        if (_invoice) {
          setCheckedInvoice([...checkedInvoice, _invoice]);
        }

        return [...prevState, item];
      }
    });

    setCheckedReturn([]);
    setCheckedInvoiceReturn([]);
    setUseReturn(false);
    setFullReturn(false);
    setFullPayment(false);
    setTotalPay(0);

    if (isFullReturn) {
      resetForm();
    }
  };

  const handleReturnInvoiceCheck = (item: InvoiceReturnModel) => {
    handleNotFullPayment();

    setCheckedInvoiceReturn(prevState => {
      if (prevState.some(value => value.id === item.id)) {
        const _return = checkedReturn.find(_ret => _ret.ReturnId === item.id);
        if (_return && _return.id > 0) {
          fetchDeleteReturn(_return.id);
        }

        setCheckedReturn(prevReturn => prevReturn.filter(_ret => _ret.ReturnId !== item.id));
        return prevState.filter(value => value.id !== item.id);
      } else {
        const _totalPrice = isUpdate ? totalPrice - totalPay - totalReturn : totalPrice - totalReturn;
        setCheckedReturn([
          ...checkedReturn,
          {
            id: 0,
            paymentNumber: '',
            PaymentId: null,
            ReturnId: item.id,
            useReturn: true,
            InvoiceId: invoiceId,
            paymentMethod: PaymentMethod.RETURN,
            payDate: new Date(),
            InvoicePaymentMultiple: [],
            InvoicePiece: [],
            note: '',
            BankId: null,
            SalesId: null,
            totalPay:
              item.totalPrice - (item.totalUsage || 0) <= _totalPrice
                ? item.totalPrice - (item.totalUsage || 0)
                : item.totalPrice - (item.totalUsage || 0) - diff(_totalPrice, item.totalPrice - (item.totalUsage || 0))
          }
        ]);
        return [...prevState, item];
      }
    });
  };

  const handleUseReturn = () => {
    setCheckedInvoiceReturn([]);
    setCheckedReturn([]);

    setUseReturn(prevState => {
      if (prevState && isFullReturn) {
        setPaymentMethod('');
        resetForm();
      }
      return !prevState;
    });

    setFullReturn(false);
    handleNotFullPayment();
  };

  const handleClickOpenInvoiceModal = () => {
    setOpenInvoice(true);
  };

  const handleCloseInvoiceModal = () => {
    setOpenInvoice(false);
  };

  const handleCancelInvoice = () => {
    setChecked([]);
    setCheckedInvoice([]);
    setOpenInvoice(false);
  };

  const handleOnSubmit = () => {
    resetValidation();

    if (!validation()) {
      return;
    }

    handleOpenConfirm();
  };

  const handleDoSubmit = async () => {
    setSubmit(true);

    try {
      const parseReturn =
        (checkedReturn.length > 0 &&
          checkedReturn.map(value => ({
            id: value.id,
            paymentNumber: value.paymentNumber,
            PaymentId: value.PaymentId,
            ReturnId: value.ReturnId,
            useReturn: value.useReturn,
            PurchaseInvoiceId: value.InvoiceId,
            paymentMethod: value.paymentMethod,
            payDate: value.payDate,
            note: value.note,
            BankId: value.BankId && value.BankId > 0 ? value.BankId : null,
            totalPay: value.totalPay
          }))) ||
        [];

      const { data } = await axios.post(PURCHASE_PAYMENT_BASE_URL, {
        PurchaseInvoiceId: checkedInvoice.map(val => ({ id: val.id, type: val.type })),
        BankId: bank.id && bank.id > 0 ? bank.id : null,
        paymentMethod,
        note,
        totalPay,
        useReturn: checkedReturn.length > 0,
        returns: parseReturn,
        payDate,
        giroBank,
        transferDate: paymentMethod === PaymentMethod.BANK_TRANSFER ? transferDate : null,
        giroDueDate,
        image: image || [],
        giroAmount,
        giroNumber,
        status: paymentMethod === PaymentMethod.GIRO ? PaymentStatus.UNPAID : PaymentStatus.PAID
      });

      setPaymentId(data.data[0].id);

      if (data.data.length > 0) {
        data.data[0].PurchaseInvoicePaymentImages.map(async (value: any, index: number) => {
          if (image.filter(img => img.path === value.path).length) {
            const newImageKey = value.path;
            const fileExtension = newImageKey.split('.').pop();
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', `image/${fileExtension}`);

            const config: RequestInit = {
              method: 'PUT',
              headers: myHeaders,
              body: imageBlob[index]
            };

            await fetch(value.url, config)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));
          }
        });
      }

      if (data.data.length > 0) {
        handleSnackBar(true, 'success', 'Pembayaran berhasil dibuat');
      } else {
        handleSnackBar(true, 'error', data.message);
      }
    } catch (error) {
      console.log('error :', error);
      handleSnackBar(true, 'error', '500 Error internal server');
    } finally {
      setSubmit(false);
    }
  };

  const handleFullPayment = () => {
    setFullPayment(true);
    setTotalPay(totalPrice - totalReturn);
    setTotalPayError('');
  };

  const handleNotFullPayment = () => {
    setFullPayment(false);
    setTotalPay(0);
    setTotalPayError('');
  };

  const handleOpenReturn = () => {
    setOpenReturn(true);
  };

  const handleCloseReturn = () => {
    setOpenReturn(false);
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const resetForm = () => {
    setNote('');
    setPaymentMethod('');
    setBank(dummyBank);
    setGiroBank('');
    setGiroNumber('');
    setGiroAmount(0);
    setGiroDueDate(new Date());
  };

  const resetValue = () => {
    setInvoices([]);
    setCheckedReturn([]);
    setCheckedInvoiceReturn([]);
    setCheckedInvoice([]);
    setChecked([]);
    setInvoiceId(0);
    setTotalPay(0);
    setUseReturn(false);
    setFullReturn(false);
  };

  useEffect(() => {
    if (checkedInvoice.length === 0) {
      setTotalPrice(0);
      return;
    }

    const _totalPrice =
      checkedInvoice.length > 0
        ? checkedInvoice
            .map(value => value.totalPrice - value.totalPay + (isUpdate ? currentTotalPay + currentTotalReturn : 0))
            .reduce((a, b) => a + b)
        : 0;

    setTotalPrice(_totalPrice);
  }, [checkedInvoice, isUpdate, currentTotalPay, currentTotalReturn]);

  useEffect(() => {
    if (checkedReturn.length === 0) {
      setTotalReturn(0);
      return;
    }

    const _totalReturn = checkedReturn.length > 0 ? checkedReturn.map(value => value.totalPay).reduce((a, b) => a + b) : 0;
    if (!isFullReturn && _totalReturn === totalPrice) {
      resetForm();
    }

    setTotalReturn(_totalReturn);
    setFullReturn(_totalReturn === totalPrice);
    setPaymentMethod(prevState => {
      if (_totalReturn === totalPrice) {
        setTotalPay(_totalReturn);
        return PaymentMethod.RETURN;
      } else {
        if (prevState === PaymentMethod.RETURN) {
          setTotalPay(0);
          return '';
        } else {
          return prevState;
        }
      }
    });
  }, [checkedReturn, totalPrice, isUpdate]);

  useEffect(() => {
    if (!params) return;

    fecthInvoicePayment();
  }, [params]);

  useEffect(() => {
    if (!partner) return;

    if (partner.id < 1) {
      if (isFullReturn) {
        resetForm();
        resetValue();
      } else {
        resetValue();
      }
    }
  }, [partner]);

  useEffect(() => {
    setDisableMethod(false);

    if (isFullReturn === true) {
      setDisableMethod(true);
    }
  }, [isFullReturn]);

  useEffect(() => {
    if (!openInvoice) return;

    const debouncedFetchHits = _.debounce(fetchData, 500);
    debouncedFetchHits();
    return () => cancelTokenSource.cancel('No longer latest query');
  }, [queryString, orderBy, order, currentPage, openInvoice]);

  useEffect(() => {
    if (!openReturn) return;

    const debouncedFetchHits = _.debounce(fetchReturnInvoice, 500);
    debouncedFetchHits();
    return () => cancelTokenSource.cancel('No longer latest query');
  }, [openReturn, returnNumber]);

  useEffect(() => {
    const debouncedFetchHits = _.debounce(handleSearchPartner, 500);
    debouncedFetchHits();
    return () => cancelTokenSource.cancel('No longer latest query');
  }, [partnerName]);

  return (
    <Page title='Buat Pembayaran Pembelian'>
      <Container>
        <Grid container direction='row'>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Buat Pembayaran Pembelian</Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid container direction='row' spacing={2}>
            <Grid container direction='row' spacing={1} item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                <Autocomplete
                  id='partner'
                  value={partner}
                  options={partners}
                  getOptionLabel={option => option.name}
                  getOptionSelected={(option, value) => {
                    return value.id === option.id;
                  }}
                  onChange={(event: any, value: any) => {
                    if (value) {
                      setPartner(value);
                      resetValue();
                    }
                  }}
                  onOpen={e => setPartnerName('')}
                  onInputChange={(event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
                    if (reason === 'clear') {
                      setPartner(dummyPartner);
                      setPartners([dummyPartner]);
                    }
                  }}
                  loading={loadPartner}
                  renderOption={(option, state) => (
                    <CardCustomer partner={option} isLoading={loadPartner} isSelected={option.id === partner.id && !loadPartner} />
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label='Pilih Supplier'
                      onChange={e => {
                        setPartnerName(e.target.value);
                      }}
                      error={partnerMessage !== ''}
                      helperText={partnerMessage}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {loadPartner && <CircularProgress color='inherit' size={20} />}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        )
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xl={paymentMethod === PaymentMethod.BANK_TRANSFER || paymentMethod === PaymentMethod.GIRO ? 3 : 6}
                lg={paymentMethod === PaymentMethod.BANK_TRANSFER || paymentMethod === PaymentMethod.GIRO ? 3 : 6}
                md={paymentMethod === PaymentMethod.BANK_TRANSFER || paymentMethod === PaymentMethod.GIRO ? 3 : 6}
                xs={12}
                sm={12}
              >
                <TextField
                  id='paymentMethod'
                  required
                  label='Metode Pembayaran'
                  disabled={disableMethod}
                  value={paymentMethod as string}
                  error={paymentMethodMessage !== ''}
                  helperText={paymentMethodMessage}
                  onChange={e => {
                    resetForm();
                    setPaymentMethod(e.target.value as string);
                    setFullReturn(e.target.value === PaymentMethod.RETURN);
                  }}
                  select
                  fullWidth
                >
                  <MenuItem key={'1'}>Metode Pembayaran</MenuItem>
                  {isFullReturn ? (
                    <MenuItem key={'2'} value={PaymentMethod.RETURN}>
                      {PaymentMethod.RETURN}
                    </MenuItem>
                  ) : (
                    Object.entries(PaymentMethod).map(([key, value]) => (
                      <MenuItem key={key} value={value}>
                        {value}
                      </MenuItem>
                    ))
                  )}
                </TextField>
              </Grid>

              {paymentMethod === PaymentMethod.BANK_TRANSFER && (
                <Grid item xl={3} lg={3} md={3} xs={12} sm={12}>
                  <Autocomplete
                    id='bank'
                    value={bank}
                    options={banks}
                    getOptionLabel={option => option.bankName}
                    getOptionSelected={(option, value) => {
                      return value.id === option.id;
                    }}
                    onChange={({}, value: any) => {
                      if (value) {
                        setBank(value);
                        setNote(`Transfer Rekening: (${value.bankCode}) ${value.bankName} - No Rek : ${value.bankNumber} / ${value.bankAccount}`);
                      }
                    }}
                    onOpen={e => handleSearchBank('')}
                    loading={loadingBank}
                    disabled={isUpdate}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label='Pilih Bank'
                        onChange={e => handleSearchBank(e.target.value)}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {loadingBank && <CircularProgress color='inherit' size={20} />}
                              {params.InputProps.endAdornment}
                            </Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
              )}

              {paymentMethod === PaymentMethod.GIRO && (
                <Grid item xl={3} lg={3} md={3} xs={12} sm={12}>
                  <TextField
                    id='bank'
                    required
                    fullWidth
                    type='text'
                    label='Bank Penerbit'
                    placeholder=''
                    value={giroBank}
                    onChange={e => setGiroBank(e.target.value)}
                    error={giroBankMessage !== ''}
                    helperText={giroBankMessage}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              )}

              <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                {paymentMethod === PaymentMethod.BANK_TRANSFER && (
                  <TextField
                    id='payDate'
                    required
                    fullWidth
                    type='date'
                    label='Tanggal Transfer'
                    placeholder=''
                    value={format(transferDate, 'yyyy-MM-dd')}
                    onChange={e => setTransferDate(new Date(e.target.value))}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
                <TextField
                  id='payDate'
                  required
                  fullWidth
                  type='date'
                  label='Tanggal Kwitansi'
                  placeholder=''
                  value={format(payDate, 'yyyy-MM-dd')}
                  onChange={e => setPayDate(new Date(e.target.value))}
                  error={payDateMessage !== ''}
                  helperText={payDateMessage}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              {paymentMethod === PaymentMethod.GIRO && (
                <Grid item xl={3} lg={3} md={3} xs={12} sm={12}>
                  <TextField
                    id='bank'
                    required
                    fullWidth
                    type='text'
                    label='Nomor Giro'
                    placeholder=''
                    value={giroNumber}
                    onChange={e => setGiroNumber(e.target.value)}
                    error={giroNumberMessage !== ''}
                    helperText={giroNumberMessage}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              )}

              {paymentMethod === PaymentMethod.GIRO && (
                <Grid item xl={3} lg={3} md={3} xs={12} sm={12}>
                  <TextField
                    id='bank'
                    required
                    fullWidth
                    type='text'
                    label='Nominal Giro'
                    placeholder=''
                    value={giroAmount}
                    onChange={e => setGiroAmount(+e.target.value)}
                    error={giroAmountMessage !== ''}
                    helperText={giroAmountMessage}
                    InputProps={{ inputComponent: NumberFormatMask as any }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              )}

              {paymentMethod === PaymentMethod.GIRO && (
                <Grid container spacing={1}>
                  <Grid item xl={6} lg={6} md={6} xs={12} sm={12}>
                    &nbsp;
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} xs={12} sm={12}>
                    <TextField
                      id='giroDueDate'
                      required
                      fullWidth
                      type='date'
                      label='Tanggal Jatuh Tempo Giro'
                      placeholder=''
                      value={format(giroDueDate, 'yyyy-MM-dd')}
                      onChange={e => setGiroDueDate(new Date(e.target.value))}
                      error={giroDueDateMessage !== ''}
                      helperText={giroDueDateMessage}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                </Grid>
              )}

              {paymentMethod === PaymentMethod.GIRO && (
                <Grid item xl={6} lg={6} md={6} xs={12} sm={12}>
                  &nbsp;
                </Grid>
              )}
              <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                <TextField fullWidth id='note' label='Catatan' multiline rows={4} value={note} onChange={e => setNote(e.target.value)} />
              </Grid>
              {paymentMethod === PaymentMethod.BANK_TRANSFER && (
                <Grid xs={12} {...getRootProps({ className: classes.dropZone })}>
                  {imageUrl.length < 3 && <input {...getInputProps()} />}
                  {imageUrl.length > 0 ? (
                    <aside className={classes.thumbsContainer}>
                      {imageUrl.map((value, index) => (
                        <div className={classes.thumb} key={index}>
                          <div className={classes.thumbInner}>
                            <img src={value} key={index} className={classes.img} />
                          </div>
                          <CloseRounded className={classes.closeIcon} color='error' onClick={() => handleDeleteImage(index)} />
                        </div>
                      ))}
                      {imageUrl.length > 0 && imageUrl.length < 3 && <AddRounded className={classes.addIcon} color='primary' />}
                    </aside>
                  ) : (
                    <>
                      <CloudUpload className={classes.iconUpload} />
                      <Typography align='center'> Geser dan lepaskan disini untuk mengupload gambar PNG, JPEG, JPG, Max 5 *</Typography>
                    </>
                  )}
                </Grid>
              )}
              {paymentMethodImageMessage !== '' && <Typography color='error'>{paymentMethodImageMessage}</Typography>}
            </Grid>

            <Grid container direction='row' item xl={12} md={12} sm={12}>
              <FormHelperText error={paymentMessageError !== ''}>{paymentMessageError}</FormHelperText>

              <InvoiceTable
                isLoadingData={isLoadingData}
                checked={checked}
                isUpdate={isUpdate}
                checkedInvoice={checkedInvoice}
                totalPay={isFullReturn ? totalPay - totalReturn : currentTotalPay + currentTotalReturn - totalPay - totalReturn}
                handleIndividualCheck={individualCheck}
              />
            </Grid>

            <Grid container direction='row' item xl={12} md={12} sm={12} spacing={1}>
              <Grid item>
                <Button disabled={partner.id === 0 || isUpdate} variant='outlined' onClick={handleClickOpenInvoiceModal}>
                  Pilih Invoice Rujukan
                </Button>
              </Grid>

              <Grid item>
                {isUpdate && (
                  <Button onClick={handleOnRefresh} className={classes.refresh}>
                    Memuat Ulang
                  </Button>
                )}
              </Grid>
            </Grid>

            <Grid item lg={12} sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!(partner.totalReturn && partner.totalReturn > 0) || checked.length === 0}
                    checked={useReturn}
                    onChange={handleUseReturn}
                    color='primary'
                  />
                }
                label={`Gunakan Invoice Return ${(partner.totalReturn && partner.totalReturn > 0 && '(Supplier Ini memiliki Jumlah Return)') || ''}`}
              />
            </Grid>

            <Fade in={useReturn}>
              <Grid container direction='row' item xl={12} md={12} sm={12}>
                <InvoiceReturnTable
                  isLoadingData={isLoadingData}
                  checked={checkedInvoiceReturn}
                  invoicePayment={checkedReturn}
                  handleIndividualCheck={handleReturnInvoiceCheck}
                />
              </Grid>
            </Fade>

            <Fade in={useReturn}>
              <Grid container direction='row' item xl={12} md={12} sm={12} spacing={1}>
                <Grid item>
                  <Button disabled={!useReturn} variant='outlined' onClick={handleOpenReturn}>
                    Tambahkan Return
                  </Button>
                </Grid>
              </Grid>
            </Fade>

            <Grid container direction='row' justify='flex-end' alignItems='center' alignContent='center' item xl={12} md={12} sm={12}>
              <Grid item>
                <Typography variant='subtitle2'>Jumlah Tertagih :</Typography>
              </Grid>

              <Grid item>
                <NumberFormat value={totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              </Grid>
            </Grid>

            <Grid container direction='row' justify='flex-end' alignItems='center' alignContent='center' item xl={12} md={12} sm={12}>
              <Grid item>
                <Typography variant='subtitle2'>Jumlah Return :</Typography>
              </Grid>

              <Grid item>
                <NumberFormat value={totalReturn} prefix={'-Rp'} thousandSeparator={true} displayType='text' />
              </Grid>
            </Grid>

            <Grid container direction='row' justify='flex-end' alignItems='center' alignContent='center' item xl={12} md={12} sm={12}>
              <Grid item>
                <Typography variant='subtitle2'>Total Tertagih :</Typography>
              </Grid>
              <Grid item>
                <NumberFormat value={totalPrice - totalReturn} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              </Grid>
            </Grid>

            <Grid container direction='row' justify='flex-end' alignItems='center' alignContent='center' item xl={12} md={12} sm={12}>
              <Fade in={!isFullReturn}>
                <RadioGroup row aria-label='position' name='position' defaultValue='end'>
                  <FormControlLabel
                    onChange={handleFullPayment}
                    value='1'
                    control={<Radio color='primary' checked={fullPayment} />}
                    label='Bayar Full'
                    labelPlacement='end'
                  />
                  <FormControlLabel
                    onChange={handleNotFullPayment}
                    value='0'
                    control={<Radio color='primary' checked={!fullPayment} />}
                    label='Bayar Sebagian'
                    labelPlacement='end'
                  />
                </RadioGroup>
              </Fade>
            </Grid>

            <Grid container direction='row' justify='flex-end' alignItems='center' alignContent='center' item xl={12} md={12} sm={12}>
              <Fade in={!isFullReturn}>
                <TextField
                  label='Jumlah Bayar'
                  fullWidth={isSmall}
                  disabled={fullPayment}
                  value={totalPay > 0 ? totalPay : ''}
                  helperText={totalPayError}
                  error={totalPayError !== ''}
                  onChange={e => {
                    setTotalPay(+e.target.value);
                  }}
                  InputProps={{
                    inputComponent: NumberFormatMask as any
                  }}
                />
              </Fade>
            </Grid>

            <Grid container direction='row' justify={isSmall ? 'space-between' : 'flex-end'} spacing={2} item xl={12} md={12} sm={12}>
              <Grid item xl={2} lg={2} md={12} sm={12} xs={12}>
                <Button variant={isSmall ? 'outlined' : 'contained'} onClick={handleCancel} fullWidth style={{ color: '#bdbdbd' }} color='inherit'>
                  Batal
                </Button>
              </Grid>
              <Grid item xl={2} lg={2} md={12} sm={12} xs={12}>
                <Button disabled={isSubmit || isRefresh} fullWidth onClick={handleOnSubmit}>
                  {isSubmit ? <CircularProgress color='inherit' size={20} /> : 'Simpan'}
                </Button>
              </Grid>
            </Grid>

            <StandardConfirmationDialog
              variant={snackbarVariant}
              titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
              message={message}
              open={openSnackbar}
              handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
              onConfirm={handleConfirmSnackbar}
              noCancelButton={true}
            />

            <InvoiceModal
              checked={checked}
              openInvoice={openInvoice}
              queryString={queryString}
              paymentMessageError={paymentMessageError}
              order={order}
              orderBy={orderBy}
              isLoadingData={isLoadingData}
              invoices={invoices}
              count={count}
              currentPage={currentPage}
              handleClose={handleCloseInvoiceModal}
              setQueryString={setQueryString}
              handleRequestSort={handleRequestSort}
              setCurrentPage={setCurrentPage}
              handleIndividualCheck={individualCheck}
              handleCancelInvoice={handleCancelInvoice}
            />

            <InvoiceReturnModal
              checked={checkedInvoiceReturn}
              openInvoice={openReturn}
              paymentMessageError={''}
              isLoadingData={isLoadingReturn}
              invoices={invoiceReturn}
              queryString={returnNumber}
              setQueryString={setReturnNumber}
              handleClose={handleCloseReturn}
              handleIndividualCheck={handleReturnInvoiceCheck}
            />

            <ConfirmPaymentModal
              open={openConfirm}
              editedPaymentId={params && params.id ? params.id : 0}
              isLoading={isSubmit}
              handleClose={handleCloseConfirm}
              transferDate={transferDate}
              handleConfirm={handleDoSubmit}
              invoice={checkedInvoice}
              invoiceReturn={checkedInvoiceReturn}
              payDate={payDate}
              bankId={bank.id}
              bankName={bank.bankName}
              partnerName={partner.name}
              paymentMethod={paymentMethod}
              note={note}
              totalPay={totalPay}
              totalPrice={totalPrice}
              totalReturn={totalReturn}
              useReturn={checkedReturn.length > 0}
              giroBank={giroBank}
              giroDueDate={giroDueDate}
              giroAmount={giroAmount}
              giroNumber={giroNumber}
            />
          </Grid>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default PurchasePaymentCreatePage;
