import { Badge, Button, Container, Grid, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import { AddBox, ArrowBack, NotificationsActiveOutlined, Today } from '@material-ui/icons';
import Refresh from '@material-ui/icons/Refresh';
import { Pagination } from '@material-ui/lab';

import axios, { CancelTokenSource } from 'axios';
import { Breadcrumb, Page, PaperCustom } from 'components';
import DateRangeFilter from 'components/DateRangeFilter';
import { GREEN_LIGHT, GREEN_MOBILE, WHITE } from 'constants/colors';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { DELIVERY_ORDER_BASE_URL, DELIVERY_ORDER_NOTIF, ZONE_BASE_URL } from 'constants/url';
import { format, startOfMonth } from 'date-fns';
import useDebounced from 'hooks/useDebounced';
import useRouter from 'hooks/useRouter';
import React, { useContext, useEffect, useState } from 'react';
import { dummyMetaData } from 'utils/dummy';
import DeliveryOrderTable from './DeliveryOrderTable';
import TypeUser from 'typings/enum/TypeUser';
import PaginationCustom from 'components/PaginationCustom';

const useStyles = makeStyles((theme: Theme) => ({
  refresh: {
    backgroundColor: GREEN_MOBILE,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN_MOBILE,
      transform: 'scale(1.1)'
    }
  }
}));
const DeliveryOrders = () => {
  const classes = useStyles();
  const { history } = useRouter();
  let cancelToken: CancelTokenSource = axios.CancelToken.source();
  const [deliveryOrders, setDeliveryOrders] = useState<DeliveryOrders[]>([]);
  const [metaDelivery, setMetaDelivery] = useState<MetaData>(dummyMetaData);
  const [deliveryNumber, setDeliveryNumber] = useState('');
  const [zone, setZone] = useState<number[]>([]);
  const [salesName, setSalesName] = useState<string>('');
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [partnerName, setPartnerName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [zones, setZones] = useState<ZoneModel[]>([]);
  const [totalNotif, setTotalNotif] = useState<number>(0);
  const [isRequest, setIsRequest] = useState<boolean>(false);

  const { currentUser } = useContext(CurrentUserContext);
  const canAccept: string[] = [TypeUser.SUPERADMIN, TypeUser.ADMIN];
  const handleCreateDO = () => history.push('/surat-jalan/tambah');

  const handleRefresh = () => {
    setCurrentPage(1);
    setPartnerName('');
    setSalesName('');
    setDeliveryNumber('');
    setZone([]);
    setIsRequest(false);
  };

  const handleCalendarFilterClick = () => setOpenCalendarFilter(!openCalendarFilter);

  const fecthDeliveryOrder = async () => {
    setIsLoading(true);
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (deliveryNumber) {
        params.append('deliveryNumber', deliveryNumber);
      }

      if (partnerName) {
        params.append('partnerName', partnerName);
      }
      if (salesName) {
        params.append('salesName', salesName);
      }

      if (zone.length > 0) {
        params.append('Zone', zone.join(',') || '');
      }
      params.append('requestPrint', String(isRequest));
      params.append('page', currentPage.toString());
      if (!isRequest) {
        params.append('startDate', startDate);
        params.append('endDate', endDate);
      }
      return params;
    };
    try {
      const { data } = await axios.get(`${DELIVERY_ORDER_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelToken.token });
      setMetaDelivery(data.meta);
      setDeliveryOrders(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedFetchHits = useDebounced(fecthDeliveryOrder, 500);

  useEffect(() => {
    debouncedFetchHits();
    return () => cancelToken.cancel('No longer latest query');
  }, [currentPage, zone, partnerName, salesName, deliveryNumber, startDate, endDate, isRequest]);

  const fecthZone = async () => {
    const params = new URLSearchParams();
    params.append('orderBy', 'name');
    params.append('ordered', 'asc');
    params.append('perPage', '1000');
    try {
      const { data } = await axios.get(`${ZONE_BASE_URL}?${params.toString()}`);
      setZones(data.data);
    } catch (error) {
      console.log('error :', error);
    }
  };
  const fecthTotalNotif = async () => {
    try {
      const { data } = await axios.get(`${DELIVERY_ORDER_NOTIF}`);
      setTotalNotif(data.data.total);
    } catch (error) {
      console.log('error :', error);
    }
  };
  const handleRequestPrint = () => setIsRequest(!isRequest);
  useEffect(() => {
    fecthZone();
  }, []);

  useEffect(() => {
    fecthTotalNotif();
  }, []);

  return (
    <Page title='Surat Jalan'>
      <Container>
        <Grid xs={12}>
          <Typography variant='h1' component='h1'>
            Surat Jalan
          </Typography>
          <Breadcrumb />
        </Grid>

        <PaperCustom>
          <Grid xs={12} container style={{ rowGap: '1em' }}>
            <Grid xs={12} container alignItems='center'>
              <Grid xs={7} container style={{ columnGap: '1em' }} alignItems='center'>
                <Grid item>
                  <Tooltip title='Memuat Ulang'>
                    <Button size='small' onClick={handleRefresh} color='inherit' className={classes.refresh}>
                      <Refresh fontSize='small' />
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant='h6'>
                    {`Menampilkan ${metaDelivery.total || 0} Surat Jalan (${metaDelivery.from || 0} - ${metaDelivery.to ||
                      0} dari ${metaDelivery.total || 0})`}
                  </Typography>

                  <Typography color='primary'>
                    Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={5} container justify='flex-end' style={{ columnGap: '1em' }}>
                <Tooltip title='Calendar filter' placement='top'>
                  <Button color='primary' variant='outlined' onClick={handleCalendarFilterClick}>
                    <Today />
                  </Button>
                </Tooltip>
                <Button
                  onClick={handleCreateDO}
                  size='small'
                  // disabled={isLoadingData}
                  // style={{ marginLeft: isAddAllowed ? '2em' : '', marginRight: isAddAllowed ? '1em' : '' }}
                >
                  <AddBox fontSize='small' /> Surat Jalan
                </Button>
                {canAccept.includes((currentUser && currentUser.type) || '') && (
                  <Tooltip title={'Izin Cetak Surat Jalan'}>
                    <Badge badgeContent={totalNotif} color='error'>
                      <Button onClick={handleRequestPrint} disabled={isLoading} color='secondary'>
                        {!isRequest ? <NotificationsActiveOutlined /> : <ArrowBack />}
                      </Button>
                    </Badge>
                  </Tooltip>
                )}
              </Grid>
            </Grid>

            <DeliveryOrderTable
              zone={zone}
              setZone={setZone}
              zones={zones}
              deliveryOrders={deliveryOrders}
              isLoading={isLoading}
              deliveryNumber={deliveryNumber}
              setDeliveryNumber={setDeliveryNumber}
              partnerName={partnerName}
              setPartnerName={setPartnerName}
              salesName={salesName}
              setSalesName={setSalesName}
            />

            <Grid container item sm={12} xs={12} justify='flex-end'>
              <PaginationCustom
                marginTop='-.4em '
                show={deliveryOrders.length > 0}
                sxPagination={{
                  count: metaDelivery.last_page,
                  boundaryCount: 2,
                  variant: 'outlined',
                  shape: 'rounded',
                  onChange: (event, page) => {
                    setCurrentPage(page);
                  },
                  page: currentPage
                }}
                sxPopover={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  }
                }}
                customPageProps={{
                  defaultValue: currentPage,
                  maxValue: metaDelivery.last_page,
                  onSubmit(value) {
                    setCurrentPage(value);
                  }
                }}
              />
            </Grid>
          </Grid>
          <DateRangeFilter
            openCalendarFilter={openCalendarFilter}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            handleClose={() => setOpenCalendarFilter(false)}
          />
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default DeliveryOrders;
