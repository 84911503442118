import { useEffect, useMemo, useState, RefObject } from 'react';

const useIsInViewport = (element: RefObject<HTMLDivElement> | null, rootMargin: string) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        setIsIntersecting(prev => (!prev ? entry.isIntersecting : true));
      }),
    [rootMargin]
  );

  useEffect(() => {
    if (element && element.current) {
      observer.observe(element.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [element, observer]);

  return isIntersecting;
};

export default useIsInViewport;
