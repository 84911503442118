import { Button, Drawer, Grid, makeStyles, MenuItem, TextField, Theme, Typography } from '@material-ui/core';
import { Add, CloseRounded, ExpandLessOutlined, ExpandMore, InfoOutlined, InfoRounded } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import EmptyIcon from 'images/icon/EmptyIcon.svg';
import React, { FC, useState } from 'react';
import CollyItem from './CollyItem';

interface Props {
  open: boolean;
  handleCloseModal: () => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    borderRadius: '0.5em 0.5em 0 0',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  buttonContainerMobile: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 4,
    backgroundColor: '#fff',
    padding: '13px 10px',
    boxShadow: '0px 0px 17px rgba(0, 0, 0, 0.08)'
  },
  container: {
    rowGap: '1em',
    backgroundColor: '#fff'
  },
  header: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    padding: '1em',
    gap: '1em'
  },
  section: {
    padding: '0 1em',
    rowGap: '1em'
  }
}));

const CollyModalNotif: FC<Props> = props => {
  const { open, handleCloseModal } = props;
  const classes = useStyles();

  return (
    <Drawer
      anchor='bottom'
      open={open}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <Grid xs={12} className={classes.container} container direction='column'>
        <Grid xs={12} container className={classes.header}>
          <Typography variant='h5'>Informasi Penting</Typography>
        </Grid>
        <Grid xs={12} className={classes.section} container>
          <Typography>Produk ini memiliki invoice yang berbeda dari produk lainnya pada koli ini</Typography>
        </Grid>
        <Grid xs={12} style={{ minHeight: '3.4em' }}>
          <Grid xs={12} className={classes.buttonContainerMobile}>
            <Button color='primary' style={{ borderRadius: '20px' }} fullWidth onClick={handleCloseModal}>
              Tutup
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default CollyModalNotif;
