import React, { FC, useState, useEffect, useCallback } from 'react';
import axios, { CancelTokenSource } from 'axios';
import useRouter from 'hooks/useRouter';
import UserTable from './components/UserTable';
import CreateUserModal from './components/CreateUserModal';
import EditUserModal from './components/EditUserModal';
import SearchInput from 'components/SearchInput';
import useDebounce from 'hooks/useDebounce';
import { Page, StandardConfirmationDialog } from 'components';
import { Button, Grid, Typography, Breadcrumbs, Link, Container, Backdrop, CircularProgress } from '@material-ui/core';
import { USER_BASE_URL, ZONE_BASE_URL, ROLES_BASE_URL, WAREHOUSE_BASE_URL } from 'constants/url';
import { PaperCustom } from 'components';
import { Pagination } from '@material-ui/lab';
import PaginationCustom from 'components/PaginationCustom';

const UserPage: FC = () => {
  const { history } = useRouter();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [users, setUsers] = useState<UserDetailsModel[]>([]);
  const [query, setQuery] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [openEditUser, setOpenEditUser] = useState<boolean>(false);
  const [openCreateUserModal, setOpenCreateUserModal] = useState<boolean>(false);
  const [currentEditingUserIndex, setCurrentEditingUserIndex] = useState<number>(-1);
  const [zones, setZones] = useState<ZoneModel[]>([]);
  const [wareHouses, setWareHouses] = useState<WareHouseModel[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<RoleModel[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [count, setCount] = useState<number>(0);
  const [isUpdateStatus, setUpdateStatus] = useState<boolean>(false);

  const handleGetZone = async () => {
    const params = new URLSearchParams();
    params.append('perPage', '-1');

    try {
      const { data } = await axios.get(`${ZONE_BASE_URL}?${params.toString()}`);
      setZones(data.data);
    } catch (error) {
      console.log('error', error);
    } finally {
    }
  };

  const handleGetWareHouse = async () => {
    try {
      const { data } = await axios.get(WAREHOUSE_BASE_URL);
      setWareHouses(data.data);
    } catch (error) {
      console.log('error', error);
    } finally {
    }
  };

  const getRole = async () => {
    try {
      const { data } = await axios.get(ROLES_BASE_URL);
      setRoles(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getRole();
    handleGetZone();
    handleGetWareHouse();
  }, []);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    action(actionParam);
    setCurrentPage(0);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const addNewUser = (user: UserDetailsModel) => {
    user.new = true;
    users.unshift(user);
    setUsers([...users]);
  };

  const handleCancelEditUser = () => {
    setOpenEditUser(false);
  };

  const handleStatus = async (id: number) => {
    setUpdateStatus(true);
    try {
      const { data } = await axios.get(`${USER_BASE_URL}/status/${id}`);
      setUsers(prevState =>
        prevState.map(value => {
          if (id === value.id) {
            value.isActive = data.data.isActive;
          }
          return value;
        })
      );
      handleSnackBar(true, 'success', data.data.isActive ? 'User berhasil di Aktifkan' : 'User berhasil di Non Aktifkan');
    } catch (err) {
      console.log('error', err);
    } finally {
      setUpdateStatus(false);
    }
  };

  const handleOpenEditUser = (userIndex: number): React.MouseEventHandler => () => {
    setCurrentEditingUserIndex(userIndex);
    setOpenEditUser(true);
  };

  const handleOpenCreateUser = () => {
    setOpenCreateUserModal(true);
  };

  const handleCancelCreateUser = () => {
    setOpenCreateUserModal(false);
  };

  const handleSearch = useCallback((searchQuery: string) => {
    performActionAndRevertPage(setQueryString, searchQuery);
  }, []);

  // Search Client whenever rowsPerPage, currentPage, queryString, user, and filterby changes
  const fetchData = useCallback(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('keyword', queryString);
      }

      params.append('page', currentPage.toString());

      return params.toString();
    };

    const searchUser = async () => {
      setLoading(true);
      try {
        const url = `${USER_BASE_URL}?${getQueryParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });
        setUsers(data.data);
        setCount(data.meta.last_page);
      } catch (err) {
        console.log('error', err);
      } finally {
        setLoading(false);
      }
    };

    searchUser();

    return () => {
      cancelTokenSource.cancel();
    };
  }, [queryString, currentPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const debouncedSearchTerm = useDebounce(query, 500);
  // Load User data to populate on search list
  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  const updateIndividualUser = (userIndex: number) => {
    return (updatedUserProperties: Partial<UserDetailsModel>) => {
      setUsers(
        users!.map((user, index) => {
          if (index !== userIndex) {
            return user;
          }
          return Object.assign({}, user, updatedUserProperties);
        })
      );
    };
  };

  const onClick = (path: string): React.MouseEventHandler => event => {
    event.preventDefault();
    history.push(path);
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleConfirmationSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Page title='User'>
      <Container>
        <Backdrop style={{ zIndex: 9999, color: '#fff' }} open={isUpdateStatus}>
          <Typography variant='inherit'>Memperbaharui status</Typography>
          <CircularProgress size='small' style={{ color: '#fff' }} color='inherit' />
        </Backdrop>
        <Grid container direction='row'>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Pengaturan </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumbs key={1} aria-label='breadcrumb'>
              <Link key={99} color='inherit' href='/' onClick={onClick('/')}>
                Beranda
              </Link>
              <Link color='inherit' href='/' onClick={onClick('/pengaturan')}>
                Pengaturan
              </Link>
              <Typography color='inherit'>User</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <SearchInput
                withBorder
                withTransition={false}
                width={150}
                placeHolder='Pencarian'
                iconColor='#989898'
                tableSearchValue={query}
                setTableSearchValue={setQuery}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Button
                color='primary'
                size='medium'
                onClick={() => {
                  handleOpenCreateUser();
                }}
              >
                Tambah User Baru
              </Button>
            </Grid>

            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
              <UserTable
                isLoadingData={isLoading}
                isUpdateStatus={isUpdateStatus}
                users={users}
                handleStatus={handleStatus}
                handleOpenEditUser={handleOpenEditUser}
                handleCancelEditUser={handleCancelEditUser}
                setOpenSnackbar={setOpenSnackbar}
                setSnackbarVarient={setSnackbarVarient}
                updateIndividualUser={updateIndividualUser}
              />
            </Grid>
            <Grid container justify='flex-end' item xl={12} md={12} sm={12}>
              <PaginationCustom
                marginTop='-.4em '
                show={true}
                sxPagination={{
                  count,
                  boundaryCount: 2,
                  variant: 'outlined',
                  shape: 'rounded',
                  onChange: (event, page) => {
                    setCurrentPage(page);
                  },
                  page: currentPage
                }}
                sxPopover={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  }
                }}
                customPageProps={{
                  defaultValue: currentPage,
                  maxValue: count,
                  onSubmit(value) {
                    setCurrentPage(value);
                  }
                }}
              />
            </Grid>
          </Grid>

          <CreateUserModal
            open={openCreateUserModal}
            handleCancel={handleCancelCreateUser}
            addNewUser={addNewUser}
            setOpenSnackbar={setOpenSnackbar}
            warehouses={wareHouses}
            handleSnackBar={handleSnackBar}
            zone={zones}
            roles={roles}
          />
        </PaperCustom>

        <EditUserModal
          open={openEditUser}
          user={users[currentEditingUserIndex]}
          handleCancel={handleCancelEditUser}
          setOpenSnackbar={setOpenSnackbar}
          updateIndividualUser={updateIndividualUser(currentEditingUserIndex)}
          handleSnackBar={handleSnackBar}
          setUsers={setUsers}
          warehouses={wareHouses}
          zone={zones}
          roles={roles}
        />

        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={snackbarVariant === 'success' ? handleConfirmationSnackbar : handleCloseSnackbar}
          onConfirm={handleCloseSnackbar}
          noCancelButton={true}
        />
      </Container>
    </Page>
  );
};

export default UserPage;
