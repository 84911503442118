import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  LinearProgress,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import FlexBox from 'components/FlexBox';
import React from 'react';

const useStyles = makeStyles({
  box: {
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxShadow: '0 2px 5px rgb(0 0 0 / 20%)',
    borderRadius: '5px',
    marginTop: '0.5em'
  }
});

/* A type of props that will be used in the component. */
interface Props {
  isOpen: boolean;
  handleClose: () => void;
  invoicePrintFilter: {
    zone: number;
    partner: number[];
  };
  handleChange: <T>(key: string, value: T) => void;
  handleAction: { isLoading: boolean; submit: () => void };
  zone: { isLoading: boolean; data: ZoneModel[] };
  partner: { isLoading: boolean; data: Pick<PartnerModel, 'id' | 'name'>[] };
}
/* A function that takes in an object of type Props and returns a JSX element. */
const InvoicePrintConfirm = ({ isOpen, handleClose, zone, partner, handleAction, handleChange, invoicePrintFilter }: Props) => {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth='xs'>
      <DialogContent>
        <FlexBox container xs={12} rowGap={1}>
          <Grid xs={12}>
            <Typography variant='h5'>Filter Laporan Piutang</Typography>
            <Divider />
          </Grid>

          <FlexBox xs={12} container justify='space-between' rowGap={0.5}>
            <Grid xs={12}>
              <Autocomplete
                onChange={(event, value, reason) => {
                  if (reason === 'clear') {
                    handleChange<number>('zone', 0);
                    handleChange<number[]>('partner', []);
                  }
                  if (value) {
                    handleChange<number>('zone', value.id);
                    handleChange<number[]>('partner', []);
                  }
                }}
                loading={zone.isLoading}
                loadingText={<LinearProgress />}
                disableListWrap
                fullWidth
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={<CheckBoxOutlineBlank fontSize='small' />}
                      checkedIcon={<CheckBox fontSize='small' color='primary' />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />

                    {option.name}
                  </React.Fragment>
                )}
                getOptionLabel={option => option.name}
                options={zone.data}
                PaperComponent={({ children }) => <Grid className={classes.box}>{children}</Grid>}
                renderInput={params => <TextField {...params} label='Rute' variant='outlined' fullWidth placeholder='Pilih Rute' required />}
              />
            </Grid>
            <Grid xs={12}>
              <Autocomplete
                onChange={(event, value, reason) => {
                  if (reason === 'clear') {
                    handleChange<number[]>('partner', []);
                  }
                  if (value) {
                    handleChange<number[]>(
                      'partner',
                      value.map(value => value.id)
                    );
                  }
                }}
                loading={partner.isLoading}
                loadingText={<LinearProgress />}
                disableListWrap
                fullWidth
                multiple
                limitTags={2}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={<CheckBoxOutlineBlank fontSize='small' />}
                      checkedIcon={<CheckBox fontSize='small' color='primary' />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />

                    {option.name}
                  </React.Fragment>
                )}
                getOptionLabel={option => option.name}
                options={partner.data}
                disabled={invoicePrintFilter.zone === 0}
                PaperComponent={({ children }) => <Grid className={classes.box}>{children}</Grid>}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Customer'
                    variant='outlined'
                    fullWidth
                    placeholder='Pilih Customer'
                    required
                    helperText={invoicePrintFilter.zone === 0 && 'Silahkan pilih rute terlebih dahulu'}
                  />
                )}
              />
            </Grid>
          </FlexBox>

          <FlexBox xs={12} container justify='flex-end' alignItems='center' columnGap={1}>
            <Button variant='outlined' onClick={handleClose}>
              Batal
            </Button>
            <Button
              color='primary'
              onClick={handleAction.submit}
              disabled={handleAction.isLoading || invoicePrintFilter.zone === 0 || invoicePrintFilter.partner.length < 1}
            >
              {handleAction.isLoading ? <CircularProgress size={20} /> : 'Cetak Laporan'}
            </Button>
          </FlexBox>
        </FlexBox>
      </DialogContent>
    </Dialog>
  );
};

export default InvoicePrintConfirm;
