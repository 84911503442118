import React, { FC } from 'react';
import { Grid, Typography, Divider, makeStyles } from '@material-ui/core';
import { PaperCustom } from 'components';
import Skeleton from '@material-ui/lab/Skeleton';

interface Props {
  isLoadingData: boolean;
  invoicePayment: PurchaseInvoicePaymentModel;
}

const useStyles = makeStyles({
  leftCell: {
    border: 'none',
    width: '165px'
  },
  contentPayment: {
    fontWeight: 500
  },
  textDetail: {
    paddingBottom: 5,
    marginBottom: 5
  }
});

const DetailBank: FC<Props> = props => {
  const { isLoadingData, invoicePayment } = props;
  const classes = useStyles();

  return (
    <PaperCustom>
      <Grid item container direction='row' spacing={2} justify='space-between' lg={12} sm={12} md={12} xs={12}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography className={classes.textDetail} variant='h6'>
            Detail
          </Typography>
        </Grid>
      </Grid>

      <Divider />

      <Grid item container direction='row' spacing={2} justify='space-between' lg={12} sm={12} md={12} xs={12}>
        <Grid item>
          <Typography variant='subtitle1'>Nama Bank</Typography>
          <Typography variant='h6'>
            {isLoadingData ? <Skeleton variant='text' width={'100%'} height={25} /> : invoicePayment.Bank ? invoicePayment.Bank.bankName : '-'}
          </Typography>
        </Grid>
        <Divider />
      </Grid>
    </PaperCustom>
  );
};

export default DetailBank;
