import { Grid, IconButton, makeStyles } from '@material-ui/core';
import { RefreshOutlined } from '@material-ui/icons';
import FlexBox from 'components/FlexBox';
import { GREEN, WHITE } from 'constants/colors';
import React, { ReactNode } from 'react';

const useStyles = makeStyles({
  container: {
    rowGap: '1em'
  },
  refreshButton: {
    backgroundColor: GREEN,
    color: WHITE,
    padding: '.4em',
    borderRadius: '5px',
    transition: 'all .3s',
    '&:hover': {
      transform: 'scale(1.2)',
      backgroundColor: '#27ae60'
    }
  }
});

/* Defining the props that the component will receive. */
interface Props {
  titleNode1: ReactNode;
  titleNode2: ReactNode;
  onClick: { handleRefresh: () => void };
}

/* A function that takes in two parameters, titleNode and onClick. */
const SalesHistoryHeader = ({ titleNode1, titleNode2, onClick }: Props) => {
  const classes = useStyles();
  return (
    <Grid xs={12} container className={classes.container}>
      <Grid xs={6}>
        <FlexBox container columnGap={1} alignItems='center' alignContent='flex-start' xs={12}>
          <IconButton aria-label='refresh' className={classes.refreshButton} disableRipple={false} size='small' onClick={onClick.handleRefresh}>
            <RefreshOutlined />
          </IconButton>
          <FlexBox direction='column'>
            {titleNode1}
            {titleNode2}
          </FlexBox>
        </FlexBox>
      </Grid>
    </Grid>
  );
};

/* Exporting the component. */
export default SalesHistoryHeader;
