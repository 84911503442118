import React, { FC } from 'react';
import { Grid, Typography, Divider, makeStyles } from '@material-ui/core';
import { PaperCustom } from 'components';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';
import Skeleton from '@material-ui/lab/Skeleton';

interface Props {
  invoicePayment: InvoicePaymentModel;
  company: CompanyModel;
  isLoadingData: boolean;
}

const useStyles = makeStyles({
  leftCell: {
    border: 'none',
    width: '165px'
  },
  contentPayment: {
    fontWeight: 500
  },
  textDetail: {
    paddingBottom: 5,
    marginBottom: 5
  }
});

const DetailPage: FC<Props> = props => {
  const { invoicePayment, isLoadingData } = props;
  const classes = useStyles();

  return (
    <PaperCustom>
      <Grid item container direction='row' spacing={2} justify='space-between' lg={12} sm={12} md={12} xs={12}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography className={classes.textDetail} variant='h6'>
            Detail
          </Typography>
        </Grid>
      </Grid>

      <Divider />

      <Grid item container direction='row' spacing={2} justify='space-between' lg={12} sm={12} md={12} xs={12}>
        <Grid item>
          <Typography variant='subtitle1'>Nomor Giro</Typography>
          <Typography variant='h6'>{isLoadingData ? <Skeleton variant='text' width={'100%'} height={25} /> : invoicePayment.giroNumber}</Typography>
        </Grid>

        <Grid item>
          <Typography variant='subtitle1'>Nominal</Typography>
          <Typography variant='h6'>
            <NumberFormat value={invoicePayment.giroAmount} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant='subtitle1'>Tgl Jatuh Tempo</Typography>
          <Typography variant='h6'>
            {isLoadingData ? (
              <Skeleton variant='text' width='100%' />
            ) : invoicePayment.giroDueDate ? (
              format(new Date(invoicePayment.giroDueDate), 'dd/MM/yyyy')
            ) : (
              '-'
            )}
          </Typography>
        </Grid>
        <Divider />
        <Grid item>
          <Typography variant='subtitle1'>Bank Penerbit</Typography>
          <Typography variant='h6'>{isLoadingData ? <Skeleton variant='text' width={'100%'} height={25} /> : invoicePayment.giroBank}</Typography>
        </Grid>
        <Divider />
      </Grid>
    </PaperCustom>
  );
};

export default DetailPage;
