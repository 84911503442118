import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { IconButton, Tooltip, Chip } from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart, TableRowCustom } from 'components/Table';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import useRouter from 'hooks/useRouter';

interface Props {
  stock: StockCheckModel;
  isLoading: boolean;
  isFirstStock: boolean;
  isCurrent?: boolean;

  onDelete: React.MouseEventHandler;
  onUpdate: React.MouseEventHandler;
}

const BodyRow: FC<Props> = props => {
  const { history } = useRouter();
  const { stock, isLoading, onDelete, isCurrent } = props;

  const handleLink = () => {
    history.push('/cek-detail', { id: stock.id });
  };
  return (
    <TableRowCustom>
      <TableCellStart onClick={handleLink}>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : stock.checkDate}</TableCellStart>

      <TableCellMiddle align='center' onClick={handleLink}>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : stock.WareHouse && stock.WareHouse.name}
      </TableCellMiddle>

      <TableCellMiddle align='center' onClick={handleLink}>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          (isCurrent && <Chip color='primary' label={stock.periode || '-'} />) || '-'
        )}
      </TableCellMiddle>

      <TableCellEnd align='center'>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <>
            <Tooltip title='Hapus'>
              <IconButton size='small' onClick={onDelete}>
                <DeleteOutline fontSize='small' />
              </IconButton>
            </Tooltip>
          </>
        )}
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRow;
