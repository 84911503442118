import React, { FC } from 'react';
import {
  Button,
  makeStyles,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  ListItemText,
  Select,
  InputLabel,
  FormControl,
  Collapse,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  Checkbox,
  CircularProgress,
  FormHelperText
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { DialogTitle } from 'components/Dialog';
import { GREEN, WHITE } from 'constants/colors';
import { format } from 'date-fns';

interface Props {
  openExport: boolean;
  warehouse: number;
  warehouses: WareHouseModel[];
  openAdvanced: boolean;
  isFirst: number;
  products: ProductModel[];
  productName: string;
  checkedProduct: number[];
  loadingproduct: boolean;
  loadingExport: boolean;
  startDate: string;
  endDate: string;
  minDate: string;
  setProductName: React.Dispatch<React.SetStateAction<string>>;
  setFirst: React.Dispatch<React.SetStateAction<number>>;
  setWarehouse: React.Dispatch<React.SetStateAction<number>>;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  handleClose: () => void;
  handleExport: () => void;
  handleOpenAdvanced: () => void;
  handleCloseAdvanced: () => void;
  handleCheckedProduct: (id: number) => void;
}

const useStyles = makeStyles({
  dialogContent: {
    margin: '0 auto',
    padding: 12,
    overflow: 'auto'
  },
  next: {
    color: WHITE,
    backgroundColor: GREEN
  }
});

const ExportModal: FC<Props> = props => {
  const classes = useStyles();
  const {
    openExport,
    warehouse,
    warehouses,
    openAdvanced,
    products,
    productName,
    checkedProduct,
    loadingproduct,
    loadingExport,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    handleCheckedProduct,
    setProductName,
    setWarehouse,
    handleClose,
    handleExport,
    handleOpenAdvanced
  } = props;

  return (
    <Dialog open={openExport} onClose={handleClose} maxWidth='md' classes={{ paper: classes.dialogContent }}>
      <DialogTitle>Export Stok</DialogTitle>
      <DialogContent>
        <Grid direction='row' container justify='space-between' spacing={1}>
          <Grid item lg={12} md={12} sm={12}>
            <Collapse in={checkedProduct.length > 0}>
              <FormHelperText error={checkedProduct.length > 0}>
                Anda memilih {(checkedProduct.length > 0 && `(${checkedProduct.length}) produk untuk di export.`) || ''}
              </FormHelperText>
            </Collapse>
          </Grid>

          <Grid item lg={6} md={6} sm={6}>
            <Collapse in={!openAdvanced}>
              <TextField
                fullWidth
                label='Tanggal Mulai'
                value={startDate}
                onChange={e => setStartDate(format(new Date(e.target.value), 'yyyy-MM-dd'))}
                type='date'
                InputLabelProps={{ shrink: true }}
              />
            </Collapse>
          </Grid>

          <Grid item lg={6} md={6} sm={6}>
            <Collapse in={!openAdvanced}>
              <TextField
                fullWidth
                label='Tanggal Akhir'
                value={endDate}
                onChange={e => setEndDate(format(new Date(e.target.value), 'yyyy-MM-dd'))}
                type='date'
                error={startDate !== '' && endDate === ''}
                helperText={startDate !== '' && endDate === '' && 'Tanggal berakhir tidak boleh kosong.'}
                InputLabelProps={{ shrink: true }}
              />
            </Collapse>
          </Grid>

          <Grid item lg={12} md={12} sm={12}>
            <Collapse in={!openAdvanced}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel id='demo-mutiple-checkbox-label'>Gudang</InputLabel>
                <Select
                  id='demo-mutiple-checkbox'
                  fullWidth
                  variant='outlined'
                  margin='dense'
                  value={warehouse}
                  label='Gudang'
                  onChange={e => setWarehouse(e.target.value as number)}
                >
                  {warehouses.map(value => (
                    <MenuItem key={value.id} value={value.id}>
                      <ListItemText primary={value.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Collapse>
          </Grid>

          <Grid item container direction='row' lg={12} md={12} sm={12}>
            <Grid item lg={12} md={12} sm={12}>
              <Button variant='text' onClick={handleOpenAdvanced}>
                Pilihan Lanjutan {(checkedProduct.length > 0 && `(${checkedProduct.length})`) || ''}
              </Button>
            </Grid>

            <Grid item lg={12} md={12} sm={12}>
              <Collapse in={openAdvanced}>
                <TableContainer style={{ maxHeight: 440 }}>
                  <Table stickyHeader size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell align='center'>#</TableCell>
                        <TableCell align='center'>Name</TableCell>
                        <TableCell align='center'>Gudang</TableCell>
                        <TableCell align='center'>Stok</TableCell>
                        <TableCell align='center'>HPP</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <TextField
                            margin='dense'
                            variant='standard'
                            label='Nama Produk'
                            value={productName}
                            onChange={e => setProductName(e.target.value)}
                          />
                        </TableCell>
                        <TableCell align='center'></TableCell>
                        <TableCell align='center'> </TableCell>
                        <TableCell align='center'> </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {loadingproduct ? (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Skeleton variant='text' width='100%' height={25} />
                          </TableCell>
                        </TableRow>
                      ) : (
                        products.length > 0 &&
                        products.map((value, index) => (
                          <TableRow key={index}>
                            <TableCell align='center'>
                              <Checkbox
                                color='primary'
                                value={value.id}
                                checked={checkedProduct.some(_value => _value === value.id)}
                                onChange={() => handleCheckedProduct(value.id)}
                              />
                            </TableCell>

                            <TableCell>{value.productName}</TableCell>

                            <TableCell align='center'>{value.warehouse || '-'}</TableCell>

                            <TableCell align='center'>
                              {value.totalStock} {value.typeUnit}
                            </TableCell>

                            <TableCell align='right'>{value.purchasePrice}</TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={handleClose}>
          Keluar
        </Button>
        <Button disabled={startDate !== '' && endDate === ''} onClick={handleExport} className={classes.next}>
          {loadingExport ? <CircularProgress color='inherit' size={20} /> : 'Export'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportModal;
