import { Divider, Grid, makeStyles, Theme, Tooltip, Typography, Fade } from '@material-ui/core';
import { ExpandMoreRounded, InfoRounded } from '@material-ui/icons';
import FlexBox from 'components/FlexBox';
import React, { memo } from 'react';
import { TData, TListExpand } from 'pages/PartnerDetailPage/components/SalesHistoryPage';
import { GRAY_3, GREY, PRIMARY_MOBILE, WHITE, BLACK_2 } from 'constants/colors';
import clsx from 'clsx';
import SalesHistoryContainer from './SalesHistoryContainer';
import { Skeleton } from '@material-ui/lab';
import monthNames from 'utils/month';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';
import Loading from 'components/Loading';
import useRouter from 'hooks/useRouter';

const size = {
  small: 0.1,
  medium: 0.15,
  large: 0.25,
  extralarge: 0.45
} as const;

type TTableProperty = {
  key: keyof SalesHistory;
  title: string;
  size: keyof typeof size;
  align: 'left' | 'center' | 'right';
};
const tableProperties: TTableProperty[] = [
  {
    key: 'createdAt',
    title: 'TANGGAL',
    size: 'medium',
    align: 'left'
  },
  {
    key: 'productName',
    title: 'Produk',
    size: 'extralarge',
    align: 'left'
  },

  {
    key: 'invoiceNumber',
    title: 'NOMOR INVOICE',
    size: 'large',
    align: 'left'
  },
  {
    key: 'totalItem',
    title: 'QTY',
    size: 'medium',
    align: 'center'
  },

  {
    key: 'subTotalPrice',
    title: 'HARGA JUAL SATUAN',
    size: 'large',
    align: 'left'
  }
];

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: '50vh'
  },
  titleTable: {
    whiteSpace: 'pre-wrap' /* or pre-line */,
    color: GRAY_3
  },
  fontSizeNormal: {
    fontSize: '.9rem'
  },
  fontBold: {
    fontWeight: 500
  },
  padding: {
    padding: '1em 1em .8em'
  },

  fontStyle: {
    color: PRIMARY_MOBILE
  },

  borderTop: {
    borderTop: `1px solid ${GREY}`
  },
  itemWrapper: {
    border: `1px solid ${GREY}`,
    borderRadius: '.4em',
    '&:hover': {
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 14%)'
    }
  },

  link: {
    transition: 'all .3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      color: PRIMARY_MOBILE
    }
  },
  linkInvoice: {
    transition: 'all .3s ease-in-out',
    cursor: 'pointer',
    color: PRIMARY_MOBILE,
    '&:hover': {
      color: BLACK_2
    }
  },
  icon: {
    padding: '.15em',
    fontSize: '2rem',
    cursor: 'pointer',
    transition: 'rotate .3s',
    '&:hover': {
      background: GREY,
      borderRadius: '50%'
    }
  },
  tableWrapper: {
    backgroundColor: '#F7F9FC'
  },
  iconRotate: {
    rotate: '180deg'
  },
  skelaton: {
    height: '4em',
    background: GREY
  },
  empty: {
    background: GREY,
    borderRadius: '.4em'
  },
  positionRelative: {
    position: 'relative'
  },
  tooltipWrapper: {
    backgroundColor: WHITE,
    borderRadius: '6px',
    boxShadow: '0px 2px 2px 1px rgb(0 0 0 / 20%)',
    zIndex: 1,
    padding: '1.5em'
  },
  contentInfo: {
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      bottom: 7,
      zIndex: 2,
      width: '1.3em',
      height: '1.3em',
      transform: 'translateX(-50%) rotate(45deg)',
      borderRight: '1px solid #9F9F9F',
      borderBottom: '1px solid #9F9F9F',
      backgroundColor: WHITE
    },
    '& > *': {
      color: '#000'
    }
  },
  pointer: {
    cursor: 'pointer'
  },
  colorGrey: {
    color: '#9F9F9F'
  },
  iconHover: {
    transition: 'all .3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.2)',
      color: '#9F9F9F'
    }
  },
  minHight: {
    minHeight: '25em'
  }
}));

/* Defining the props that the component will receive. */
interface Props {
  handleListExpand: ({ id, action }: TListExpand) => void;
  products: TData<StockMovementProduct[]>;
  stockMovements: TData<SalesHistory[]>;
  productMonths: TData<StockMovementProductMonth[]>;
  productExpand: { id: number; isOpen: boolean };
  monthExpand: { month: string; isOpen: boolean };
}

/* A function component. */

const SalesHistoryContent = ({ handleListExpand, stockMovements, productMonths, monthExpand }: Props) => {
  const classes = useStyles();
  const { history } = useRouter();
  const handleOpenLink = (path: string) => window.open(path);
  const dateParsing = (date: string): number => {
    const temp = +date.slice(5) - 1;
    return temp;
  };

  return (
    <FlexBox container xs={12} rowGap={1} className={classes.container} alignItems='flex-start' alignContent='flex-start'>
      {productMonths.isLoading ? (
        [...new Array(7)].map(value => <Grid xs={12} className={clsx(classes.skelaton, classes.itemWrapper)} />)
      ) : productMonths.data.length > 0 ? (
        productMonths.data.map(date => (
          <FlexBox xs={12} container key={date.date} className={classes.itemWrapper} alignItems='center'>
            <FlexBox
              container
              alignItems='center'
              columnGap={1}
              className={classes.padding}
              onClick={() =>
                handleListExpand({
                  id: date.date,
                  action: 'months'
                })
              }
            >
              <ExpandMoreRounded className={clsx(classes.icon, monthExpand.month === date.date && monthExpand.isOpen && classes.iconRotate)} />
              <Typography
                variant='h6'
                color={monthExpand.month === date.date && monthExpand.isOpen ? 'primary' : 'initial'}
                className={clsx(classes.fontSizeNormal, classes.link)}
              >
                {`${monthNames[dateParsing(date.date)]} ${new Date(date.date).getFullYear()}`}
              </Typography>
            </FlexBox>
            <SalesHistoryContainer isExpand={monthExpand.month === date.date && monthExpand.isOpen} key={date.date}>
              <FlexBox container xs={12} rowGap={0.8} className={classes.padding}>
                <Grid xs={12}>
                  <Divider />
                </Grid>

                <FlexBox xs={12} container columnGap={0.3} alignItems='center'>
                  {tableProperties.map(tableProperty => (
                    <Grid key={tableProperty.key} style={{ flex: size[tableProperty.size] }}>
                      <Typography align={tableProperty.align} className={clsx(classes.fontSizeNormal, classes.fontBold, classes.titleTable)}>
                        {tableProperty.title.toLocaleUpperCase()}
                      </Typography>
                    </Grid>
                  ))}
                </FlexBox>
                {stockMovements.isLoading ? (
                  <Grid xs={12}>
                    {[...new Array(5)].map(value => (
                      <Skeleton height={50} />
                    ))}
                  </Grid>
                ) : (
                  stockMovements.data.map((SalesHistory, index) => (
                    <FlexBox xs={12} container columnGap={0.8}>
                      {tableProperties.map(tableProperty => (
                        <Grid key={tableProperty.key} style={{ flex: size[tableProperty.size] }}>
                          {tableProperty.key === 'createdAt' ? (
                            <Typography align={tableProperty.align} className={classes.fontSizeNormal}>
                              {format(new Date(SalesHistory[tableProperty.key]), 'dd MMM yyyy')}
                            </Typography>
                          ) : tableProperty.key === 'productName' ? (
                            <Typography align={tableProperty.align} className={classes.fontSizeNormal}>
                              {SalesHistory[tableProperty.key]}
                            </Typography>
                          ) : tableProperty.key === 'invoiceNumber' ? (
                            <>
                              <Typography
                                align={tableProperty.align}
                                className={(classes.fontSizeNormal, classes.fontStyle, classes.linkInvoice)}
                                onClick={() => {
                                  history.push('/invoice-pembelian/detail', { id: SalesHistory.id });
                                }}
                              >
                                {SalesHistory[tableProperty.key]}
                              </Typography>

                              {SalesHistory.invoiceReturn &&
                                SalesHistory.invoiceReturn.length > 0 &&
                                SalesHistory.invoiceReturn.map((returnNumber, index) => (
                                  <Typography
                                    key={`invoiceReturn-${index}`}
                                    align={tableProperty.align}
                                    className={(classes.fontSizeNormal, classes.fontStyle, classes.linkInvoice)}
                                    onClick={() => {
                                      history.push('/return-pembelian/detail', { id: returnNumber.id });
                                    }}
                                  >
                                    {returnNumber.returnNumber}
                                  </Typography>
                                ))}

                              {SalesHistory.invoiceReturnBill &&
                                SalesHistory.invoiceReturnBill.length > 0 &&
                                SalesHistory.invoiceReturnBill.map((returnBill, index) => (
                                  <Typography
                                    key={`invoiceReturnBill-${index}`}
                                    align={tableProperty.align}
                                    className={(classes.fontSizeNormal, classes.fontStyle, classes.linkInvoice)}
                                    onClick={() => {
                                      handleOpenLink(`/tagihan-return/${returnBill.id}`);
                                    }}
                                  >
                                    {returnBill.invoiceNumber}
                                  </Typography>
                                ))}
                            </>
                          ) : tableProperty.key === 'totalItem' ? (
                            <Typography align={tableProperty.align} className={classes.fontSizeNormal}>
                              {SalesHistory[tableProperty.key]}
                            </Typography>
                          ) : tableProperty.key === 'subTotalPrice' ? (
                            <Typography align={tableProperty.align} className={classes.fontSizeNormal}>
                              <NumberFormat
                                value={SalesHistory[tableProperty.key].toFixed()}
                                prefix={'Rp'}
                                thousandSeparator={true}
                                displayType='text'
                              />
                            </Typography>
                          ) : (
                            <Typography align={tableProperty.align} className={classes.fontSizeNormal}>
                              {SalesHistory[tableProperty.key]}
                            </Typography>
                          )}
                        </Grid>
                      ))}
                    </FlexBox>
                  ))
                )}
              </FlexBox>
            </SalesHistoryContainer>
          </FlexBox>
        ))
      ) : (
        <Grid xs={12} container justify='center' alignItems='center' direction='column' className={clsx(classes.minHight)}>
          <Typography>Data Tidak Tersedia.</Typography>
        </Grid>
      )}
    </FlexBox>
  );
};

export default memo(SalesHistoryContent);
