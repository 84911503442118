import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import useRouter from 'hooks/useRouter';
import { TableCellMiddle, TableCellEnd, TableCellStart, TableRowCustom } from 'components/Table';

interface Props {
  sales: UserDetailsModel;
  isLoading: boolean;
}

const BodyRow: FC<Props> = props => {
  const { sales, isLoading } = props;
  const { history } = useRouter();

  const handleLink = () => {
    history.push('/komisi/detail', { id: sales.id });
  };

  return (
    <TableRowCustom onClick={handleLink}>
      <TableCellStart>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : sales.id}</TableCellStart>
      <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={150} height={25} /> : sales.name}</TableCellMiddle>
      <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : sales.email}</TableCellMiddle>
      <TableCellEnd>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : sales.contactNumber}</TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRow;
