import { useState, useEffect } from 'react';

interface Props {
  type: string;
  allowed: string[];
}

const useRole = (props: Props) => {
  const { allowed, type } = props;
  const [isAllowed, setAllowed] = useState<boolean>(false);

  useEffect(() => {
    setAllowed(allowed.some(value => value === type));
  }, [type, allowed]);

  return isAllowed;
};

export default useRole;
