import React, { FC, useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import { DialogTitle } from 'components/Dialog';
import { HeaderRow, TableCustom } from 'components/Table';
import { dummyProduct, dummyStockItem } from 'utils/dummy';
import BodyRowProduct from './BodyRowProduct';
import Pagination from '@material-ui/lab/Pagination';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useDebounce from 'hooks/useDebounce';
import axios from 'axios';
import { PRODUCT_SEARCH } from 'constants/url';
import { SearchRounded } from '@material-ui/icons';

interface Props {
  count: number;
  openProduct: boolean;
  handleClose: () => void;
  queryString: string;
  setQueryString: React.Dispatch<React.SetStateAction<string>>;
  categories: CategoryModel[];
  setCategory: React.Dispatch<React.SetStateAction<CategoryModel>>;
  handleSearchCategory: (value: string) => void;
  order: 'asc' | 'desc';
  orderBy: string;
  handleRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  isLoadingData: boolean;
  checked: StockItemModel[];
  individualCheck: (item: StockItemModel) => void;
  products: ProductModel[];
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  handleCancelProduct: () => void;
}

const useStyles = makeStyles({
  cellTable: {
    width: '20%'
  },
  containerSugestSearch: {
    position: 'absolute',
    backgroundColor: '#fff',

    overflowY: 'scroll',
    borderRadius: '0 0 0.5em 0.5em',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    top: 48,
    zIndex: 2,
    // [theme.breakpoints.down('md')]: {
    //   width: '100%',
    //   maxHeight: '40vh'
    // },
    // [theme.breakpoints.up('md')]: {
    width: '100%',
    maxHeight: '30vh'
    // }
  }
});

const StockWizard: FC<Props> = props => {
  const {
    count,
    openProduct,
    handleClose,
    queryString,
    setQueryString,
    categories,
    setCategory,
    handleSearchCategory,
    order,
    orderBy,
    handleRequestSort,
    isLoadingData,
    checked,
    individualCheck,
    products,
    setCurrentPage,
    currentPage,
    handleCancelProduct
  } = props;

  const classes = useStyles();
  const [keyWordResource, setKeyWordResource] = useState<{ productName: string }[]>([]);
  const [keyWord, setKeyWord] = useState<string>('');
  const [isKeyWord, setIsKeyWord] = useState<boolean>(false);
  const [searchTemp, setSearchTemp] = useState<string>('');
  const debouncedSearchTerm = useDebounce(keyWord, 500);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      dispatchProductSearch();
    }
  }, [debouncedSearchTerm]);

  const dispatchProductSearch = async () => {
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (keyWord) {
        params.append('keyword', keyWord);
      }

      return params;
    };
    try {
      const { data } = await axios.get(`${PRODUCT_SEARCH}?${getQueryParams()}`);
      setKeyWordResource(data.data);

      data.data.length > 0 && setIsKeyWord(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setQueryString(searchTemp);
      setIsKeyWord(false);
    }
  };

  return (
    <Dialog open={openProduct} onClose={handleClose} maxWidth={'lg'}>
      <DialogTitle>Pilih Produk</DialogTitle>
      <DialogContent>
        <Grid direction='row' container>
          <Grid item lg={6} md={6} sm={12} xs={12} style={{ position: 'relative' }}>
            <TextField
              id='query'
              label='Cari Barang disini'
              value={searchTemp}
              onChange={event => {
                setSearchTemp(event.target.value);
                setKeyWord(event.target.value);
              }}
              onKeyDown={handleKeyDown}
              autoComplete='off'
            />
            {isKeyWord && keyWordResource.length > 0 && (
              <Grid className={classes.containerSugestSearch}>
                <List>
                  {keyWordResource.map((value, key) => (
                    <ListItem
                      button
                      key={key}
                      onClick={() => {
                        setIsKeyWord(false);
                        setSearchTemp(value.productName);
                        setQueryString(value.productName);
                      }}
                    >
                      <SearchRounded style={{ paddingRight: '0.3em', color: 'grey' }} fontSize='default' />
                      <ListItemText primary={value.productName} style={{ color: '#000' }} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Autocomplete
              id='category'
              options={categories}
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(event: any, value: any) => {
                if (value) {
                  setCategory(value);
                }
              }}
              onOpen={() => handleSearchCategory('')}
              renderInput={params => <TextField {...params} label='Kategori' onChange={e => handleSearchCategory(e.target.value)} />}
            />
          </Grid>
          <Grid container item lg={12}>
            <TableContainer>
              <TableCustom>
                <TableHead>
                  <HeaderRow
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headers={[
                      { label: '', pR: '10px', pT: '7px', verticalAlign: 'top' },
                      { id: 'productName', label: 'Nama Produk', sort: true },
                      { id: 'CategoryId', label: 'Kategori', sort: true },
                      { id: 'typeUnit', label: 'Satuan', sort: true, align: 'center' }
                    ]}
                  />
                </TableHead>
                <TableBody>
                  {isLoadingData ? (
                    [0, 1, 2, 3].map(index => (
                      <BodyRowProduct
                        key={index + 1}
                        index={index}
                        item={dummyStockItem}
                        product={dummyProduct}
                        isLoading={isLoadingData}
                        checked={checked}
                        handleIndividualCheck={individualCheck}
                      />
                    ))
                  ) : products && products.length > 0 ? (
                    products.map((value, index) => (
                      <BodyRowProduct
                        key={index + 1}
                        index={index}
                        item={checked[index]}
                        product={value}
                        isLoading={isLoadingData}
                        checked={checked}
                        handleIndividualCheck={individualCheck}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align='center'>
                        {queryString ? 'Hasil tidak ditemukan.' : 'Data Tidak Tersedia.'}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </TableCustom>
            </TableContainer>
          </Grid>

          <Grid container justify='center' item xl={12} md={12} sm={12}>
            {products && products.length > 0 && (
              <Pagination count={count} onChange={(event, page) => setCurrentPage(page)} page={currentPage} boundaryCount={2} variant='outlined' />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={handleCancelProduct}>
          Batal
        </Button>
        <Button onClick={handleClose}>Simpan</Button>
      </DialogActions>
    </Dialog>
  );
};

export default StockWizard;
