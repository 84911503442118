import React, { FC, useEffect } from 'react';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom, TableRowCollapse, ButtonCollapse } from 'components';
import { TablePaymentReturn } from 'components/TableCollapse';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';
import { makeStyles, Typography, Theme, Grid, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import useRouter from 'hooks/useRouter';
import PaymentStatus from 'typings/enum/PaymentStatus';
import PaymentMethod from 'typings/enum/PaymentMethod';
import { BLUE_PRIMARY, CONFIRMATION_COLOR, PENDING_COLOR, WHITE } from 'constants/colors';

const useStyles = makeStyles((theme: Theme) => ({
  accept: {
    padding: '5px 12px 5px 12px',
    borderRadius: '100px',
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '22px',
    color: WHITE,
    background: BLUE_PRIMARY,
    textAlign: 'center',
    width: 120
  },
  unpaid: {
    padding: '5px 12px 5px 12px',
    borderRadius: '100px',
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '22px',
    color: WHITE,
    background: PENDING_COLOR,
    textAlign: 'center',
    width: 120
  },
  paid: {
    padding: '5px 12px 5px 12px',
    borderRadius: '100px',
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '22px',
    color: WHITE,
    background: CONFIRMATION_COLOR,
    textAlign: 'center',
    width: 120
  },
  cancel: {
    background: '#ccc',
    color: '#000'
  }
}));

interface Props {
  invoice: Pick<InvoiceModel, 'id' | 'invoiceNumber' | 'dueDate' | 'orderDate' | 'totalPay' | 'totalPrice'> & { status: string };
  isLoading: boolean;
}

const BodyRowPiutang: FC<Props> = props => {
  const classes = useStyles();
  const { isLoading, invoice } = props;

  return (
    <TableRowCustom>
      <TableCellStart>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <Typography>{format(new Date(invoice.orderDate), 'dd MMM yyyy')}</Typography>
        )}
      </TableCellStart>
      <TableCellMiddle>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : <Typography>#{invoice.invoiceNumber}</Typography>}
      </TableCellMiddle>
      <TableCellMiddle>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <Typography>{invoice.dueDate ? format(new Date(invoice.dueDate), 'dd MMM yyyy') : '-'}</Typography>
        )}
      </TableCellMiddle>

      <TableCellEnd align='right'>
        {isLoading ? (
          <Skeleton className={classes.cancel} variant='text' width={'100%'} height={25} />
        ) : (
          <NumberFormat value={invoice.totalPrice - invoice.totalPay} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        )}
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRowPiutang;
