import React, { FC, Fragment } from 'react';
import { makeStyles, IconButton, TableHead, TableBody, TableRow, TableCell, Table } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom, TableRowCollapse } from 'components';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

interface Props {
  product: ProductModel;
  isLoading: boolean;
  openCollapse: boolean;
  index: number;
  indexCollapse: number;
  loadingItem: boolean;
  checked: InvoiceReturnItemModel[];
  returnNumber:
    | {
        returnNumber: string;
        returnDate: string;
        totalDamage: number;
      }[]
    | null;
  handleChecked: (item: InvoiceReturnItemModel) => void;
  handleOpenCollapse: React.MouseEventHandler;
}

const useStyles = makeStyles({
  tableCell: {
    width: 100
  },
  tableCellAction: {
    width: 30
  },
  tableCollapse: {
    width: '100%'
  },
  cellCollapse: {
    border: 'none'
  }
});

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { product, isLoading, openCollapse, indexCollapse, index, loadingItem, returnNumber, handleOpenCollapse } = props;

  return (
    <Fragment>
      <TableRowCustom>
        <TableCellStart>
          {isLoading ? (
            <Skeleton variant='text' width={60} height={25} />
          ) : (
            <IconButton aria-label='expand row' size='small' onClick={handleOpenCollapse}>
              {openCollapse && index === indexCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCellStart>

        <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={100} height={25} /> : product.productName || '-'}</TableCellMiddle>

        <TableCellMiddle className={classes.tableCell} align='center'>
          {isLoading ? <Skeleton variant='text' width={100} height={25} /> : product.Category!.name || '-'}
        </TableCellMiddle>

        <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={100} height={25} /> : '-'}</TableCellMiddle>

        <TableCellEnd align='center' className={classes.tableCellAction}>
          {isLoading ? <Skeleton variant='text' width={100} height={25} /> : `${product.totalDamage || 0} ${product.typeUnit}`}
        </TableCellEnd>
      </TableRowCustom>

      <TableRowCollapse open={openCollapse && product.id === indexCollapse && !isLoading} colSpan={5}>
        <Table className={classes.tableCollapse} size='small'>
          <TableHead>
            <TableRow>
              <TableCell> Nomor Invoice </TableCell>
              <TableCell>Kuantitas</TableCell>
              <TableCell align='center'>Tanggal Masuk</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loadingItem ? (
              <TableRow>
                <TableCell colSpan={3}>
                  <Skeleton variant='text' height={25} />
                </TableCell>
              </TableRow>
            ) : (
              returnNumber &&
              returnNumber.length > 0 &&
              returnNumber.map((value, index) => (
                <TableRow>
                  <TableCell>{value.returnNumber}</TableCell>
                  <TableCell align='center'>{value.totalDamage}</TableCell>
                  <TableCell align='center'>{value.returnDate}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableRowCollapse>
    </Fragment>
  );
};

export default BodyRow;
