import React, { FC, useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import axios from 'axios';
import {
  PARTNER_BASE_URL,
  PARTNER_LAST_ID_BASE_URL,
  WILAYAH_PROVINSI_BASE_URL,
  WILAYAH_KABUPATEN_BASE_URL,
  WILAYAH_KECAMATAN_BASE_URL,
  ZONE_BASE_URL
} from 'constants/url';
import { dummyRegion, dummyZone } from 'utils/dummy';
import PartnerWizard from './components/PartnerWizard';
import { DialogTitle } from 'components/Dialog';

interface Props {
  partners: PartnerModel[];
  open: boolean;
  typeUser: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPartners: React.Dispatch<React.SetStateAction<PartnerModel[]>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const PartnerModal: FC<Props> = props => {
  const { partners, open, typeUser, setOpen, setPartners, handleSnackBar } = props;

  const [partnerId, setPartnerId] = useState<string>('');
  const [route, setRoute] = useState<ZoneModel[]>([dummyZone]);
  const [region, setRegion] = useState<RegionModel[]>([dummyRegion]);
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [value, setValue] = useState(0);
  const [loadProvinsi, setLoadProvinsi] = useState<boolean>(false);
  const [loadKabupaten, setLoadKabupaten] = useState<boolean>(false);
  const [loadKecamatan, setLoadKecamatan] = useState<boolean>(false);
  const [loadZone, setLoadZone] = useState<boolean>(false);

  const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    setOpen(false);
  };

  useEffect(() => {
    fetch();
  }, [open]);

  const fetch = async () => {
    try {
      const { data } = await axios.get(PARTNER_LAST_ID_BASE_URL);
      setPartnerId(data.data.partnerId);
    } catch (error) {
      console.log('error :', error);
    }
  };

  const handleSearchProvinsi = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    setLoadProvinsi(true);
    try {
      const { data } = await axios.get(`${WILAYAH_PROVINSI_BASE_URL}?${params.toString()}`);
      setRegion(data.data);
      setLoadProvinsi(false);
    } catch (error) {
      console.log('error :', error);
      setLoadProvinsi(false);
    }
  };

  const handleSearchKabupaten = async (code: string, value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    setRegion([dummyRegion]);
    setLoadKabupaten(true);
    try {
      const { data } = await axios.get(`${WILAYAH_KABUPATEN_BASE_URL(code)}?${params.toString()}`);
      setRegion(data.data);
      setLoadKabupaten(false);
    } catch (error) {
      console.log('error :', error);
      setLoadKabupaten(false);
    }
  };

  const handleSearchZone = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    params.append('perPage', '100');
    setLoadZone(true);
    try {
      const { data } = await axios.get(`${ZONE_BASE_URL}?${params.toString()}`);
      setRoute(data.data);
      setLoadZone(false);
    } catch (error) {
      console.log('error :', error);
      setLoadZone(true);
    }
  };

  const handleSearchKecamatan = async (code: string, value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    setRegion([dummyRegion]);
    setLoadKecamatan(true);
    try {
      const { data } = await axios.get(`${WILAYAH_KECAMATAN_BASE_URL(code)}?${params.toString()}`, {});
      setRegion(data.data);
      setLoadKecamatan(false);
    } catch (error) {
      console.log('error :', error);
      setLoadKecamatan(false);
    }
  };

  const onSubmit = async (partner: PartnerModel, reset: () => void, resetValidation: () => void) => {
    try {
      const { data } = await axios.post(PARTNER_BASE_URL, partner);
      setPartners([data.data, ...partners]);
      setSubmit(false);
      reset();
      resetValidation();
      handleSnackBar(true, 'success', 'Mitra berhasil ditambahkan');
    } catch (error) {
      console.log('error :', error);
      setSubmit(false);
    }
  };

  return (
    <Dialog maxWidth={'md'} fullWidth={true} disableBackdropClick={true} onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
      <DialogTitle id='customized-dialog-title'>Tambah Mitra Baru</DialogTitle>
      <DialogContent>
        <PartnerWizard
          open={open}
          setOpen={setOpen}
          isSubmit={isSubmit}
          partnerId={partnerId}
          region={region}
          route={route}
          value={value}
          typeUser={typeUser}
          loadZone={loadZone}
          loadProvinsi={loadProvinsi}
          loadKabupaten={loadKabupaten}
          loadKecamatan={loadKecamatan}
          setValue={setValue}
          setSubmit={setSubmit}
          onSubmit={onSubmit}
          handleSearchProvinsi={handleSearchProvinsi}
          handleSearchKabupaten={handleSearchKabupaten}
          handleSearchZone={handleSearchZone}
          handleSearchKecamatan={handleSearchKecamatan}
        />
      </DialogContent>
    </Dialog>
  );
};

export default PartnerModal;
