import React, { FC } from 'react';
import {
  Button,
  makeStyles,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  MenuItem,
  Checkbox,
  ListItemText,
  Select,
  InputLabel
} from '@material-ui/core';
import { DialogTitle } from 'components/Dialog';
import { GREEN, WHITE } from 'constants/colors';

interface Props {
  openExport: boolean;
  date: string;
  dateStart: string;
  dateEnd: string;
  status: string;
  zone: number[];
  zones: ZoneModel[];
  setZone: React.Dispatch<React.SetStateAction<number[]>>;
  setDate: React.Dispatch<React.SetStateAction<string>>;
  setDateStart: React.Dispatch<React.SetStateAction<string>>;
  setDateEnd: React.Dispatch<React.SetStateAction<string>>;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  handleClose: () => void;
  handleExport: () => void;
}

const useStyles = makeStyles({
  dialogContent: {
    margin: '0 auto',
    padding: '24px'
  },
  next: {
    color: WHITE,
    backgroundColor: GREEN
  }
});

const ExportModal: FC<Props> = props => {
  const classes = useStyles();

  const {
    openExport,
    date,
    dateStart,
    dateEnd,
    status,
    zone,
    zones,
    setZone,
    setDate,
    setDateStart,
    setDateEnd,
    setStatus,
    handleClose,
    handleExport
  } = props;

  return (
    <Dialog open={openExport} onClose={handleClose} maxWidth='sm' classes={{ paper: classes.dialogContent }}>
      <DialogTitle>Export SO Penjualan</DialogTitle>
      <DialogContent>
        <Grid direction='row' container justify='space-between' spacing={1}>
          <Grid item lg={12} md={12} sm={12}>
            <TextField
              fullWidth
              label='Tanggal'
              value={date}
              type='date'
              InputLabelProps={{ shrink: true }}
              onChange={e => setDate(e.target.value)}
              disabled={dateStart !== '' || dateEnd !== ''}
            />
          </Grid>

          <Grid item lg={12} md={12} sm={12}>
            <TextField id='status' fullWidth label='Pilih Status' value={status} onChange={e => setStatus(e.target.value as string)} select>
              <MenuItem key={0} selected>
                Pilih Status
              </MenuItem>

              <MenuItem value={'PENDING'} key={1}>
                Tertunda
              </MenuItem>

              <MenuItem value={'CONFIRMATION'} key={2}>
                Konfirmasi
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item lg={12} md={12} sm={12}>
            <InputLabel id='demo-mutiple-checkbox-label'>Rute</InputLabel>
            <Select
              id='demo-mutiple-checkbox'
              multiple
              fullWidth
              variant='outlined'
              margin='dense'
              value={zone}
              onChange={e => setZone(e.target.value as number[])}
              renderValue={selected =>
                zones
                  .filter(value => (selected as number[]).includes(value.id as number))
                  .map(value => value.name)
                  .join(',')
              }
            >
              {zones.map(value => (
                <MenuItem key={value.id} value={value.id}>
                  <Checkbox checked={zone.indexOf(value.id) > -1} />
                  <ListItemText primary={value.name} />
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item lg={6} md={6} sm={6}>
            <TextField
              fullWidth
              label='Tanggal Mulai'
              value={dateStart}
              InputLabelProps={{ shrink: true }}
              type='date'
              onChange={e => setDateStart(e.target.value)}
              disabled={date !== ''}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6}>
            <TextField
              fullWidth
              label='Tanggal Berakhit'
              value={dateEnd}
              type='date'
              InputLabelProps={{ shrink: true }}
              onChange={e => setDateEnd(e.target.value)}
              disabled={date !== ''}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={handleClose}>
          Batal
        </Button>
        <Button
          disabled={dateStart === '' && dateEnd === '' && date === '' && status === '' && zone.length > 0}
          onClick={handleExport}
          className={classes.next}
        >
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportModal;
