import React, { FC, useContext, useState } from 'react';
import { AppBar, Avatar, Badge, Grid, IconButton, makeStyles, Theme, Toolbar, Typography, Hidden, useTheme, useMediaQuery } from '@material-ui/core';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { format } from 'date-fns';
import { getCurrentUserId } from 'selectors';
import { WHITE, BLACK, GRAY_3 } from 'constants/colors';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ActionSnackBar from './ActionSnackBar';
import ErrorIcon from '@material-ui/icons/Error';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import UpdateProfileModal from './ProfileModal/UpdateProfileModal';
import MenuIcon from '@material-ui/icons/Menu';
import { dummyCurrentUser } from 'utils/dummy';
import TypeUser from 'typings/enum/TypeUser';
import { BarChart, NotificationsNone } from '@material-ui/icons';
import useRole from 'hooks/useRole';

interface Props {
  open: boolean;
  openMobile: boolean;
  handleDrawerOpen(): void;
  handleDrawerOpenMobile: () => void;
}

const { REACT_APP_DRAWER_WIDTH = '240' } = process.env;
const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    margin: '10px 0px 10p 0px',
    width: 25,
    height: 25
  },
  toolbar: {},
  appBar: {
    background: WHITE,
    boxShadow: '0px 5px 8px 0px #000000 8%',
    filter: 'drop-shadow(0px 5px 8px rgba(0, 0, 0, 0.08))',
    [theme.breakpoints.between('md', 'xl')]: {
      marginLeft: +REACT_APP_DRAWER_WIDTH,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    [theme.breakpoints.down('md')]: {
      zIndex: 2,
      backgroundColor: WHITE
    }
  },
  colorNotif: {
    color: BLACK
  },
  greeting: {
    marginLeft: theme.spacing(1),
    color: BLACK,
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '24px',
    cursor: 'pointer'
  },
  date: {
    marginLeft: theme.spacing(1),
    color: GRAY_3,
    fontSize: 12,
    lineHeight: '16px'
  },
  appBarShift: {
    [theme.breakpoints.between('md', 'xl')]: {
      marginLeft: REACT_APP_DRAWER_WIDTH,
      width: `calc(100% - ${REACT_APP_DRAWER_WIDTH}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    }
  },
  desktop: {
    [theme.breakpoints.down('md')]: {
      display: ' none'
    }
  },
  headerMobile: {
    backgroundColor: WHITE
  },
  userImgMobile: {
    fontSize: '0.875rem',
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
}));

const AppHeader: FC<Props> = props => {
  const classes = useStyles();
  const theme = useTheme();
  const showComponent = useMediaQuery(theme.breakpoints.down('md'));
  const { currentUser } = useContext(CurrentUserContext);
  const { firstName, isNew, type } = currentUser ? currentUser : dummyCurrentUser;

  const isAllow = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.PICKER, TypeUser.ADMIN01, TypeUser.ADMIN02]
  });

  const userProfileId = getCurrentUserId(currentUser);
  const { open, handleDrawerOpen, handleDrawerOpenMobile } = props;
  const [user, setUser] = useState(currentUser);
  const [currentEditingUserProfileId, setCurrentEditingUserProfileId] = useState<number>(0);
  const [openProfileModal, setOpenProfileModal] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');

  const handleOpenProfileModal = (userProfileId: number): React.MouseEventHandler => () => {
    setCurrentEditingUserProfileId(userProfileId);
    setOpenProfileModal(true);
  };

  const detailDate = format(new Date(), 'cccc, dd MMMM yyyy, HH:mm:ss');

  const handleCancelProfileModal = () => {
    setOpenProfileModal(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <AppBar position='fixed' className={clsx(classes.appBar, open && classes.appBarShift)}>
      <Toolbar className={classes.desktop}>
        <Hidden mdUp lgUp xlUp>
          <IconButton edge='start' color='inherit' aria-label='Open drawer' onClick={handleDrawerOpenMobile}>
            <MenuIcon color='primary' />
          </IconButton>
        </Hidden>

        <Hidden smDown xsDown>
          {!open && (
            <IconButton edge='start' color='inherit' aria-label='Open drawer' onClick={handleDrawerOpen}>
              <MenuIcon color='primary' />
            </IconButton>
          )}
        </Hidden>

        <IconButton size='small' color='inherit' onClick={handleOpenProfileModal(userProfileId)}>
          <Avatar>
            {currentUser && currentUser.displayName ? currentUser.displayName.slice(0, 1).toUpperCase() : `${firstName.slice(0, 1).toUpperCase()}`}
          </Avatar>
        </IconButton>

        <Hidden smDown xsDown>
          <Grid item md={8} lg={8} xl={8}>
            <Typography component='p' display='block' className={classes.greeting} onClick={handleOpenProfileModal(userProfileId)}>
              Hi {currentUser && currentUser.displayName ? currentUser.displayName : `${firstName}`}
            </Typography>

            <Typography display='block' component='p' className={classes.date}>
              {detailDate}
            </Typography>
          </Grid>
        </Hidden>

        <Grid container direction='row' item xs={10} sm={10} md={4} lg={4} xl={4} alignItems='center'>
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}></Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
            <IconButton color='inherit'>
              <Badge color='primary'>
                <NotificationsNoneIcon className={classes.colorNotif} />
              </Badge>
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>

      {showComponent && !isAllow && (
        <AppBar className={classes.headerMobile}>
          <Toolbar>
            <IconButton edge='start' aria-label='back'>
              <BarChart onClick={handleDrawerOpenMobile} style={{ transform: 'rotate(90deg)' }} />
            </IconButton>
            <Typography variant='h6' style={{ flexGrow: 1 }}>
              Hi {currentUser && currentUser.displayName ? currentUser.displayName : `${firstName}`}
            </Typography>
            <NotificationsNone style={{ color: 'grey' }} />
            <Avatar className={classes.userImgMobile} alt='User' onClick={handleOpenProfileModal(userProfileId)}>
              {currentUser && currentUser.displayName ? currentUser.displayName.slice(0, 1).toUpperCase() : `${firstName.slice(0, 1).toUpperCase()}`}
            </Avatar>
          </Toolbar>
        </AppBar>
      )}

      <ActionSnackBar
        variant={snackbarVarient}
        message={snackbarVarient === 'success' ? 'Update is successful' : 'User failed'}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />

      <UpdateProfileModal
        open={openProfileModal || isNew}
        userId={currentEditingUserProfileId}
        user={user}
        isNew={isNew}
        isSales={type === TypeUser.SALES}
        handleCancel={handleCancelProfileModal}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
      />
    </AppBar>
  );
};

export default AppHeader;
