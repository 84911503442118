import React, { FC } from 'react';
import { TableContainer, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import { HeaderRow, TableCustom } from 'components/Table';
import BodyRow from './components/BodyRow';
import { dummyMutation } from 'utils/dummy';

interface Props {
  isLoadingData: boolean;
  transfers: MutationModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  handleConfirmationDelete: (id: number) => React.MouseEventHandler;
  handleOnUpdate: (id: number) => React.MouseEventHandler;
  handleOnDetail: (id: number) => React.MouseEventHandler;
}

const TransferTable: FC<Props> = props => {
  const { isLoadingData, transfers, order, orderBy, setOrder, setOrderBy, handleConfirmationDelete, handleOnUpdate, handleOnDetail } = props;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={[
              { id: 'mutationNumber', label: 'Nomor', sort: true },
              { id: 'mutationDate', label: 'Tanggal', sort: true, align: 'center' },
              { id: 'status', label: 'Status', sort: true, align: 'center' },
              { id: 'totalItem', label: 'Jumlah Barang', sort: true, align: 'center' },
              { id: 'OriginId', label: 'Gudang Asal', sort: true, align: 'center' },
              { id: 'DestinationId', label: 'Gudang Tujuan', sort: true, align: 'center' },
              { label: ' ', align: 'right' }
            ]}
          />
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow
                key={index + 1}
                isLoading={isLoadingData}
                transfer={dummyMutation}
                onDelete={handleConfirmationDelete(0)}
                onUpdate={handleOnUpdate(0)}
                onDetail={handleOnDetail(0)}
              />
            ))
          ) : transfers && transfers.length > 0 ? (
            transfers.map((value, index) => (
              <BodyRow
                key={index + 1}
                isLoading={isLoadingData}
                transfer={value}
                onDelete={handleConfirmationDelete(value.id)}
                onUpdate={handleOnUpdate(value.id)}
                onDetail={handleOnDetail(value.id)}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={7}>Data tidak tersedia</TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default TransferTable;
