import React, { FC, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText
} from '@material-ui/core';
import { DialogTitle } from 'components/Dialog';
import { BLACK, RED, WHITE, GREEN_MOBILE, BLUE_PRIMARY, YELLOW_MOBILE, ORANGE_MOBILE, PRIMARY_MOBILE, GREY } from 'constants/colors';
import Close from '@material-ui/icons/Close';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
type typeDiscount = {
  discountUnit: number;
  orderDate: string;
  price: number;
};

interface Props {
  openEdit: boolean;
  showComponent: boolean;
  productSelectedItem: OrderItems[];
  discountResource: typeDiscount[];
  idClick: number;
  qtyEdit: number;
  discount: number;
  price: number;
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setQtyEdit: React.Dispatch<React.SetStateAction<number>>;
  setDiscount: React.Dispatch<React.SetStateAction<number>>;
  setPrice: React.Dispatch<React.SetStateAction<number>>;

  handleEditItem: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    borderRadius: '15px',
    padding: '7px 0'
  },

  titleContainer: {
    fontWeight: 500,
    fontSize: '1.2rem',
    color: BLACK,
    paddingLeft: '6px'
  },
  containerContent: {
    [theme.breakpoints.up('md')]: {
      width: '70vh'
    }
  },
  containerAction: {
    marginBottom: theme.spacing(1)
  },
  spacing: {
    marginTop: theme.spacing(2)
  }
}));

const ModalDiscount: FC<Props> = props => {
  const classes = useStyles();

  const {
    openEdit,
    setOpenEdit,
    showComponent,
    handleEditItem,
    idClick,
    productSelectedItem,
    qtyEdit,
    price,
    discount,
    discountResource,
    setQtyEdit,
    setPrice,
    setDiscount
  } = props;

  const getItem = productSelectedItem && productSelectedItem.find(value => value.ProductId === idClick);
  const [hasDiscount, setHasDiscount] = useState(false);
  return (
    <Dialog
      open={openEdit}
      onClose={() => {
        setOpenEdit(false);
      }}
      fullWidth={showComponent ? true : false}
      style={{ maxHeight: 80 + 'vh', marginTop: showComponent ? '60px' : '0' }}
      scroll='paper'
    >
      <DialogTitle>
        <Grid item container direction='row' justify='space-between' alignItems='center' xs={12}>
          <React.Fragment>
            <Typography className={classes.titleContainer}>Edit Qty & Discount</Typography>
            <Close
              fontSize='small'
              color='action'
              onClick={() => {
                setOpenEdit(false);
                setHasDiscount(false);
              }}
              style={{ cursor: 'pointer' }}
            />
          </React.Fragment>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.containerContent}>
        <Grid xs={12}>
          <FormControl variant='outlined' size='small' required fullWidth>
            <InputLabel htmlFor='outlined-adornment-amount'>Qty Request</InputLabel>
            <OutlinedInput
              id='outlined-adornment-amount'
              value={qtyEdit}
              autoComplete='off'
              inputProps={{
                inputMode: 'numeric'
              }}
              onChange={e => setQtyEdit(Number(e.target.value))}
              error={qtyEdit > ((getItem && getItem.qtyAvailable) || 0) || qtyEdit < 1}
              endAdornment={
                <InputAdornment position='end' style={{ color: BLACK }}>
                  {(getItem && getItem.typeUnit) || '-'}
                </InputAdornment>
              }
              labelWidth={100}
            />
          </FormControl>

          {qtyEdit > ((getItem && getItem.qtyAvailable) || 0) && (
            <Grid xs={12} container>
              <FormHelperText error={true}>Qty melebihi stok jual ({getItem && getItem.qtyAvailable})</FormHelperText>
            </Grid>
          )}
          {qtyEdit < 1 && (
            <Grid xs={12} container>
              <FormHelperText error={true}>Qty tidak boleh kurang dari 1</FormHelperText>
            </Grid>
          )}
        </Grid>

        <Grid xs={12} className={classes.spacing}>
          <FormControl variant='outlined' size='small' required fullWidth>
            <InputLabel htmlFor='outlined-adornment-amount'>Discount per Item</InputLabel>
            <OutlinedInput
              id='outlined-adornment-amount'
              startAdornment={<InputAdornment position='start'>Rp</InputAdornment>}
              labelWidth={140}
              inputProps={{
                inputMode: 'numeric'
              }}
              autoComplete='off'
              error={discount > ((getItem && getItem.price) || 0)}
              value={Number.isNaN(discount) ? 0 : new Intl.NumberFormat('id-ID').format(discount)}
              onFocus={() => setHasDiscount(true)}
              onChange={e => {
                if (e.target.value === '' && Number.isNaN(discount)) {
                  setDiscount(0);
                } else {
                  setDiscount(
                    Number(
                      e.target.value
                        .split('.')
                        .join('')
                        .replace(/\,/g, '')
                    )
                  );
                }
              }}
            />
          </FormControl>
          {discount >= ((getItem && getItem.price) || 0) && (
            <FormHelperText error={true}>Diskon tidak boleh melebihi atau sama dengan harga produk</FormHelperText>
          )}
        </Grid>
        {/* {hasDiscount && discountResource.length > 0 && (
          <Grid xs={12} style={{ margin: '0.5em 0' }}>
            <Typography variant='h6' style={{ color: YELLOW_MOBILE, fontWeight: 500 }}>
              Riwayat diskon sebelumnya:
            </Typography>
            <Grid xs={12} container justify='space-between'>
              <Grid xs={4}>
                <Typography style={{ color: 'lightgrey' }}>Harga/item sebelum disc</Typography>
              </Grid>
              <Grid xs={3}>
                <Typography style={{ color: 'lightgrey' }}>Diskon/</Typography>
                <Typography style={{ color: 'lightgrey' }}>item</Typography>
              </Grid>
              <Grid xs={4}>
                <Typography style={{ color: 'lightgrey' }}>Harga/item sesudah disc</Typography>
              </Grid>
              {discountResource.map((value, key) => (
                <Grid xs={12} container justify='space-between' key={key}>
                  <Grid xs={4}>
                    <Typography>
                      <NumberFormat value={value.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </Typography>
                    <Typography style={{ color: 'lightgrey', fontSize: '12px' }}>{format(new Date(value.orderDate), 'dd-MM-yyyy')}</Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Typography>
                      <NumberFormat value={value.discountUnit} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography>
                      <NumberFormat value={value.price - value.discountUnit} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )} */}
        <Grid xs={12} className={classes.spacing}>
          <FormControl variant='outlined' size='small' required fullWidth>
            <InputLabel htmlFor='outlined-adornment-amount'>Total Discount</InputLabel>
            <OutlinedInput
              id='outlined-adornment-amount'
              startAdornment={<InputAdornment position='start'>Rp</InputAdornment>}
              labelWidth={90}
              disabled
              value={Number.isNaN(discount) ? 0 : new Intl.NumberFormat('id-ID').format(discount * qtyEdit)}
            />
          </FormControl>
        </Grid>

        <Grid xs={12} className={classes.spacing}>
          <FormControl variant='outlined' size='small' required fullWidth>
            <InputLabel htmlFor='outlined-adornment-amount'>Harga per Item</InputLabel>
            <OutlinedInput
              id='outlined-adornment-amount'
              startAdornment={<InputAdornment position='start'>Rp</InputAdornment>}
              labelWidth={140}
              inputProps={{
                inputMode: 'numeric'
              }}
              autoComplete='off'
              error={price < ((getItem && getItem.price) || 0)}
              value={Number.isNaN(price) ? 0 : new Intl.NumberFormat('id-ID').format(price)}
              //onFocus={() => setHasDiscount(true)}
              onChange={e => {
                if (e.target.value === '' && Number.isNaN(price)) {
                  setPrice(0);
                } else {
                  setPrice(
                    Number(
                      e.target.value
                        .split('.')
                        .join('')
                        .replace(/\,/g, '')
                    )
                  );
                }
              }}
            />
          </FormControl>
          {price < ((getItem && getItem.price) || 0) && (
            <FormHelperText error={true}>Harga per produk tidak boleh kecil dari harga rute</FormHelperText>
          )}
        </Grid>

        <Grid xs={12} className={classes.spacing}>
          <FormControl variant='outlined' size='small' required fullWidth>
            <InputLabel htmlFor='outlined-adornment-amount'>Total Harga</InputLabel>
            <OutlinedInput
              id='outlined-adornment-amount'
              startAdornment={<InputAdornment position='start'>Rp</InputAdornment>}
              labelWidth={90}
              disabled
              value={Number.isNaN(price) ? 0 : new Intl.NumberFormat('id-ID').format(price * qtyEdit - discount * qtyEdit)}
            />
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.containerAction}>
        <Grid item container direction='row' justify='space-evenly' alignItems='center'>
          <Grid xs={11}>
            <Button
              size='small'
              fullWidth
              className={classes.button}
              color='primary'
              onClick={handleEditItem}
              disabled={
                qtyEdit > ((getItem && getItem.qtyAvailable) || 0) ||
                discount >= ((getItem && getItem.price) || 0) ||
                qtyEdit < 1 ||
                price < ((getItem && getItem.price) || 0)
              }
            >
              Simpan
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDiscount;
