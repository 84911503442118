import React, { useEffect, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import _ from 'lodash';

interface Props {
  query: string;
  url: string;
  token: CancelTokenSource;
}

const useSearch = ({ query, url, token }: Props): [boolean, any] => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${url}?${query}`, { cancelToken: token.token });
      setData(data.data);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedHits = _.debounce(getData, 500);

  useEffect(() => {
    debouncedHits();

    return () => token.cancel('request has been cancel.');
  }, [query]);

  return [isLoading, data];
};

export default useSearch;
