import { useState } from 'react';

const useToggle = (): [boolean, () => void] => {
  const [toggle, setToggle] = useState<boolean>(false);

  const onToggle = () => {
    setToggle(prevState => !prevState);
  };
  return [toggle, onToggle];
};

export default useToggle;
