import React, { FC } from 'react';
import {
  makeStyles,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { DialogTitle } from 'components/Dialog';
import { TableCustom } from 'components/Table';
import { dummyInvoiceReturn } from 'utils/dummy';
import BodyRow from './components/BodyRow';

interface Props {
  openInvoice: boolean;
  paymentMessageError: string;
  isLoadingData: boolean;
  checked: InvoiceReturnModel[];
  invoices: InvoiceReturnModel[];
  queryString: string;
  setQueryString: React.Dispatch<React.SetStateAction<string>>;
  handleClose: () => void;
  handleIndividualCheck: (item: InvoiceReturnModel) => void;
}

const useStyles = makeStyles({
  dialogContent: {
    margin: '0 auto',
    width: 1031,
    height: 590,
    padding: '24px'
  },
  bordered: {
    border: `1px solid ${red[500]}`
  }
});

const InvoiceModal: FC<Props> = props => {
  const classes = useStyles();
  const {
    checked,
    openInvoice,
    paymentMessageError,
    isLoadingData,
    invoices,
    queryString,
    setQueryString,
    handleClose,
    handleIndividualCheck
  } = props;

  return (
    <Dialog open={openInvoice} onClose={handleClose} maxWidth={false} classes={{ paper: classes.dialogContent }}>
      <DialogTitle>Pilih Invoice </DialogTitle>
      <DialogContent>
        <Grid direction='row' container>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField id='query' label='Cari Invoice Disini' value={queryString} onChange={event => setQueryString(event.target.value)} />
          </Grid>

          <Grid item lg={12}>
            <TableContainer className={paymentMessageError && classes.bordered}>
              <TableCustom size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell align='center'></TableCell>
                    <TableCell>Nomor Invoice</TableCell>
                    <TableCell>Keterangan</TableCell>
                    <TableCell align='center'>Jumlah Barang</TableCell>
                    <TableCell align='right'>Jumlah Return</TableCell>
                    <TableCell align='right'>Sisa Return</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoadingData ? (
                    [0, 1, 2, 3].map(index => (
                      <BodyRow
                        key={index}
                        invoice={dummyInvoiceReturn}
                        isLoading={isLoadingData}
                        checked={checked}
                        index={index}
                        handleIndividualCheck={handleIndividualCheck}
                      />
                    ))
                  ) : invoices && invoices.length > 0 ? (
                    invoices.map((value, index) => (
                      <BodyRow
                        key={index}
                        invoice={value}
                        isLoading={isLoadingData}
                        checked={checked}
                        index={index}
                        handleIndividualCheck={handleIndividualCheck}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align='center'>
                        Data Tidak Tersedia.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </TableCustom>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Selesai</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceModal;
