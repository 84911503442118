import React, { FC } from 'react';
import {
  makeStyles,
  TextField,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { DialogTitle } from 'components/Dialog';
import { HeaderRow, TableCustom } from 'components/Table';
import BodyRow from './components/BodyRow';
import Pagination from '@material-ui/lab/Pagination';
import { dummyInvoice } from 'utils/dummy';

interface Props {
  checked: InvoicePaymentModel[];
  openInvoice: boolean;
  queryString: string;
  paymentMessageError: string;
  order: 'asc' | 'desc';
  orderBy: string;
  isLoadingData: boolean;
  invoices: InvoiceModel[];
  count: number;
  currentPage: number;
  handleClose: () => void;
  setQueryString: React.Dispatch<React.SetStateAction<string>>;
  handleRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  handleIndividualCheck: (item: InvoicePaymentModel) => void;
  handleCancelInvoice: () => void;
}

const useStyles = makeStyles({
  dialogContent: {
    margin: '0 auto',
    width: 1031,
    height: 590,
    padding: '24px'
  },
  image: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  bordered: {
    border: `1px solid ${red[500]}`
  }
});

const InvoiceModal: FC<Props> = props => {
  const classes = useStyles();
  const {
    checked,
    openInvoice,
    queryString,
    paymentMessageError,
    order,
    orderBy,
    isLoadingData,
    invoices,
    currentPage,
    count,
    setCurrentPage,
    handleRequestSort,
    setQueryString,
    handleClose,
    handleCancelInvoice,
    handleIndividualCheck
  } = props;

  return (
    <Dialog open={openInvoice} onClose={handleClose} maxWidth={false} classes={{ paper: classes.dialogContent }}>
      <DialogTitle>Pilih Invoice Rujukan</DialogTitle>
      <DialogContent>
        <Grid direction='row' container>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField id='query' label='Cari Invoice ID' value={queryString} onChange={event => setQueryString(event.target.value)} />
          </Grid>
          <Grid item lg={12}>
            <TableContainer className={paymentMessageError && classes.bordered}>
              <TableCustom size='small'>
                <TableHead>
                  <HeaderRow
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headers={[
                      { label: 'CheckBox', pR: '10px', pT: '7px', verticalAlign: 'top' },
                      { id: 'id', label: 'Invoice ID', sort: true },
                      { id: 'orderDate', label: 'Tanggal Invoice', sort: true },
                      { id: 'CategoryId', label: 'Status Invoice', sort: true },
                      { label: 'Jumlah Total', align: 'center' },
                      { label: 'Jumlah Terhutang', align: 'center' },
                      { label: 'Tanggal Pelunasan' }
                    ]}
                  />
                </TableHead>
                <TableBody>
                  {isLoadingData ? (
                    [0, 1, 2, 3].map(index => (
                      <BodyRow
                        key={index}
                        invoice={dummyInvoice}
                        isLoading={isLoadingData}
                        checked={checked}
                        handleIndividualCheck={handleIndividualCheck}
                      />
                    ))
                  ) : invoices && invoices.length > 0 ? (
                    invoices.map((value, index) => (
                      <BodyRow
                        key={index}
                        invoice={value}
                        isLoading={isLoadingData}
                        checked={checked}
                        handleIndividualCheck={handleIndividualCheck}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align='center'>
                        {queryString ? 'No matching result' : 'Data Tidak Tersedia.'}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </TableCustom>
            </TableContainer>
          </Grid>

          <Grid container justify='center' item xl={12} md={12} sm={12}>
            {invoices && invoices.length > 0 && (
              <Pagination count={count} onChange={(event, page) => setCurrentPage(page)} page={currentPage} boundaryCount={2} variant='outlined' />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={handleCancelInvoice}>
          Batal
        </Button>
        <Button onClick={handleClose}>Simpan</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceModal;
