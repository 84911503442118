import React, { FC, Fragment, useContext } from 'react';
import { TableRow, TableCell, TextField, makeStyles, CircularProgress, Button, Tooltip, Grid, Theme } from '@material-ui/core';
import { Skeleton, Autocomplete } from '@material-ui/lab';
import { CardProduct } from 'components';
import NumberFormatMask from 'components/NumberFormatMask';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import useRole from 'hooks/useRole';
import { InfoOutlined } from '@material-ui/icons';
import { YELLOW_MOBILE } from 'constants/colors';
import TypeUser from 'typings/enum/TypeUser';

interface Props {
  product: ProductModel;
  products: ProductModel[];
  price: number;
  totalItem: number;
  typeUnit: string;
  typeReturn: string;
  discount: number;
  totalPrice: number;
  loadProduct: boolean;
  index: number;
  currentIndex: number;
  isUpdate: boolean;
  id?: number;
  productMessage: string;
  totalItemMessage: string;
  priceMessageError: string;
  totalItemMessageSupplier: string;
  additionalDamage: number;
  countAdditionalDamage: number;
  countTotalItem: number;
  totalQtyReturn: number;
  totalQtyReturnWarehouse: number;
  isAdmin: boolean;
  handleOpenReturn: (isReturn: boolean) => void;
  handleOpenHistory: () => void;
  setProduct: React.Dispatch<React.SetStateAction<ProductModel>>;
  setPrice: React.Dispatch<React.SetStateAction<number>>;
  setAdditionalDamage: React.Dispatch<React.SetStateAction<number>>;
  setTotalItem: React.Dispatch<React.SetStateAction<number>>;
  setTypeUnit: React.Dispatch<React.SetStateAction<string>>;
  setDiscount: React.Dispatch<React.SetStateAction<number>>;
  handleSearchProduct: (value: string) => void;
  handleSaveUpdate?: (index: number) => React.MouseEventHandler;
  setSelectProduct: React.Dispatch<React.SetStateAction<string>>;
  handleCloseUpdate?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  cellQty: { width: '24%' },
  tableQty: { minWidth: 65 },
  cellTable: {
    padding: 4,
    border: 0,

    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'block'
    }
  },
  qtyDekstop: {
    padding: 4,
    border: 0,
    display: 'block',
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'none'
    }
  },
  qtyMobile: {
    padding: 4,
    border: 0,
    display: 'none',
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'block'
    }
  },
  qtyInput: {
    borderRadius: 0,
    backgroundColor: '#FFF',
    padding: 0,
    textAlign: 'center',
    outline: 'none',
    border: 'none'
  },
  outline: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e2e2e2'
    }
  }
}));

const ReturnOrderItem: FC<Props> = props => {
  const classes = useStyles();
  const {
    product,
    products,
    price,
    totalItemMessageSupplier,
    totalItem,
    typeUnit,
    totalPrice,
    isUpdate,
    setSelectProduct,
    totalQtyReturn,
    totalQtyReturnWarehouse,
    index,
    id,
    currentIndex,
    handleOpenReturn,
    loadProduct,
    typeReturn,
    productMessage,
    totalItemMessage,
    priceMessageError,
    additionalDamage,
    isAdmin,
    countAdditionalDamage,
    countTotalItem,
    handleOpenHistory,
    setAdditionalDamage,
    setProduct,
    setPrice,
    setTotalItem,
    handleSearchProduct,
    handleSaveUpdate,
    handleCloseUpdate
  } = props;

  const { currentUser } = useContext(CurrentUserContext);

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: ['SUPERADMIN', TypeUser.ADMIN03]
  });

  return (
    <TableRow>
      <TableCell style={{ padding: 0 }}>
        <Grid container direction='column'>
          <Grid item>
            <Autocomplete
              id='product'
              fullWidth
              value={product}
              options={products}
              getOptionLabel={option => option.productName}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(event: any, value: any) => {
                if (value) {
                  setProduct(value);
                }
              }}
              onOpen={e => handleSearchProduct('')}
              loading={loadProduct}
              renderOption={(option: ProductModel, state) =>
                loadProduct ? <Skeleton variant='text' width='100%' /> : <CardProduct product={option} />
              }
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  label='Pilih Produk'
                  onChange={e => setSelectProduct(e.target.value)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>
                        {loadProduct && <CircularProgress color='inherit' size={20} />}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    )
                  }}
                  error={productMessage !== ''}
                  helperText={productMessage}
                />
              )}
            />
          </Grid>

          {(typeReturn === 'CUSTOMER' || (typeReturn === 'SUPPLIER' && isSuperAdmin)) && (
            <Grid container alignItems='center'>
              <Grid xs={typeReturn === 'CUSTOMER' ? 10 : 12}>
                <TextField
                  required
                  fullWidth
                  label='Harga'
                  value={price}
                  onChange={event => setPrice(+event.target.value)}
                  InputProps={{
                    inputComponent: NumberFormatMask as any
                  }}
                  error={priceMessageError !== ''}
                  helperText={priceMessageError}
                />
              </Grid>
              {typeReturn === 'CUSTOMER' && (
                <Grid xs={2} container justify='center'>
                  <InfoOutlined style={{ color: YELLOW_MOBILE, cursor: 'pointer' }} onClick={handleOpenHistory} fontSize='large' />
                </Grid>
              )}
            </Grid>
          )}

          <Grid container alignItems='center'>
            <Grid xs={typeReturn === 'SUPPLIER' ? 10 : 12}>
              <TextField
                required
                fullWidth
                label={`Kuantitas ${(typeReturn === 'SUPPLIER' && '(dari Penjualan)') || ''} `}
                value={totalItem}
                onChange={e => setTotalItem(+e.target.value)}
                error={totalItem < 0 || typeReturn === 'SUPPLIER' ? totalItem > totalQtyReturn - countTotalItem : totalItemMessage !== ''}
                helperText={
                  (totalItem < 0 && 'Qty tidak boleh kecil dari 0') ||
                  (typeReturn === 'SUPPLIER' ? totalItem > totalQtyReturn - countTotalItem && 'Melebihi batas qty return' : totalItemMessage)
                }
                InputProps={{
                  endAdornment: ' ' + (typeUnit || '___')
                }}
              />
            </Grid>
            {typeReturn === 'SUPPLIER' && (
              <Grid xs={2} container justify='center'>
                <InfoOutlined style={{ color: YELLOW_MOBILE, cursor: 'pointer' }} onClick={() => handleOpenReturn(false)} fontSize='large' />
              </Grid>
            )}
          </Grid>
          {typeReturn === 'SUPPLIER' && (
            <Grid xs={12} container alignItems='center'>
              <Grid xs={10}>
                {/* error={(additionalDamage > 0 && additionalDamage > totalQtyReturnWarehouse - countAdditionalDamage) || additionalDamage < 0} */}
                <TextField
                  fullWidth
                  label='Kuantitas (dari Gudang)'
                  value={additionalDamage}
                  error={(additionalDamage > 0 && additionalDamage > totalQtyReturnWarehouse - countAdditionalDamage) || additionalDamage < 0}
                  helperText={
                    (additionalDamage < 0 && 'Qty tidak boleh kecil dari 0') ||
                    (additionalDamage > totalQtyReturnWarehouse - countAdditionalDamage && `Melebihi batas qty return gudang`)
                  }
                  onChange={e => setAdditionalDamage(+e.target.value)}
                  InputProps={{
                    endAdornment: ' ' + (typeUnit || '___')
                  }}
                />
              </Grid>
              <Grid xs={2} container justify='center'>
                <InfoOutlined style={{ color: YELLOW_MOBILE, cursor: 'pointer' }} onClick={() => handleOpenReturn(true)} fontSize='large' />
              </Grid>
            </Grid>
          )}

          {(typeReturn === 'CUSTOMER' || (typeReturn === 'SUPPLIER' && isSuperAdmin)) && (
            <Grid item>
              <TextField
                required
                disabled
                fullWidth
                label='Total Harga'
                value={totalPrice}
                InputProps={{
                  inputComponent: NumberFormatMask as any
                }}
              />
            </Grid>
          )}

          {isUpdate && index === currentIndex && (
            <Grid item container justify='space-between' spacing={2}>
              <Grid item xs={6} sm={6}>
                <Tooltip title='Hapus'>
                  <Button fullWidth color='inherit' variant='text' style={{ color: '#A9A9A9' }} onClick={handleCloseUpdate!} size='small'>
                    <u>Batal</u>
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Tooltip title='Ubah'>
                  <Button
                    fullWidth
                    variant='text'
                    onClick={handleSaveUpdate!(index)}
                    size='small'
                    disabled={
                      isNaN(totalPrice) ||
                      (typeReturn === 'SUPPLIER' && totalItem > totalQtyReturn) ||
                      (typeReturn === 'SUPPLIER' && totalItem > totalQtyReturn) ||
                      (typeReturn === 'SUPPLIER' && additionalDamage > totalQtyReturnWarehouse) ||
                      (typeReturn === 'SUPPLIER' && additionalDamage < 0)
                    }
                  >
                    <u>Simpan</u>
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default ReturnOrderItem;
