import React, { FC } from 'react';
import { TableBody, TableHead, TableRow, TableCell, TextField, TableContainer, MenuItem } from '@material-ui/core';
import { HeaderRow, TableCustom } from 'components/Table';
import { dummyProduct } from 'utils/dummy';
import BodyRow from './components/BodyRow';

interface Props {
  isLoadingData: boolean;
  loadingItem: boolean;
  products: ProductModel[];
  checked: InvoiceReturnItemModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  indexCollapse: number;
  openCollapse: boolean;
  returnNumber:
    | {
        returnNumber: string;
        returnDate: string;
        totalDamage: number;
      }[]
    | null;
  categories: CategoryModel[];
  productName: string;
  category: number;
  handleCheckAll: (checked: boolean) => void;
  setCategory: React.Dispatch<React.SetStateAction<number>>;
  setProductName: React.Dispatch<React.SetStateAction<string>>;
  handleChecked: (item: InvoiceReturnItemModel) => void;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  handleOpenCollapse: (id: number) => React.MouseEventHandler;
}

const ProductTable: FC<Props> = props => {
  const {
    isLoadingData,
    products,
    order,
    orderBy,
    indexCollapse,
    openCollapse,
    loadingItem,
    checked,
    returnNumber,
    categories,
    productName,
    category,
    setCategory,
    setProductName,
    handleChecked,
    setOrder,
    setOrderBy,
    handleOpenCollapse
  } = props;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <TableCustom size='small'>
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={[
              { label: '', align: 'center' },
              { id: 'productName', label: 'Nama Barang', sort: true, align: 'center' },
              { id: 'CategoryId', label: 'Kategori', sort: true, align: 'center' },
              { id: 'nameSales', label: 'Supplier', sort: true, align: 'center' },
              { label: 'Jumlah' }
            ]}
          />
          <TableRow>
            <TableCell align='left'></TableCell>
            <TableCell>
              <TextField placeholder='Nama Produk' onChange={e => setProductName(e.target.value)} value={productName} />
            </TableCell>
            <TableCell>
              <TextField placeholder='Kategori' value={category} onChange={e => setCategory(+e.target.value)} select>
                <MenuItem key={0} value={0}>
                  Pilih Kategori
                </MenuItem>
                {categories.map(value => (
                  <MenuItem key={value.id} value={value.id}>
                    {value.name}
                  </MenuItem>
                ))}
              </TextField>
            </TableCell>
            <TableCell>
              <TextField placeholder='Supplier' />
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow
                key={index}
                isLoading={isLoadingData}
                product={dummyProduct}
                index={index}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                checked={[]}
                returnNumber={returnNumber}
                handleChecked={handleChecked}
                handleOpenCollapse={handleOpenCollapse(0)}
                loadingItem={loadingItem}
              />
            ))
          ) : products && products.length > 0 ? (
            products.map((value, index) => (
              <BodyRow
                key={index}
                isLoading={isLoadingData}
                product={value}
                index={index}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                checked={checked}
                returnNumber={returnNumber}
                handleChecked={handleChecked}
                handleOpenCollapse={handleOpenCollapse(value.id)}
                loadingItem={loadingItem}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align='center'>
                Data Tidak Tersedia.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default ProductTable;
