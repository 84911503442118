import React, { FC, Fragment } from 'react';
import {
  TableRow,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Checkbox,
  Zoom,
  Fade,
  TableContainer,
  Divider,
  Typography,
  TextField,
  InputBase,
  CircularProgress,
  Tooltip
} from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart, TableRowCollapse } from 'components/Table';
import Skeleton from '@material-ui/lab/Skeleton';
import NumberFormat from 'react-number-format';
import { RED, WHITE, GREEN } from 'constants/colors';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import NumberFormatMask from 'components/NumberFormatMask';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import clx from 'clsx';
import TypeUser from 'typings/enum/TypeUser';
import Error from '@material-ui/icons/Error';

interface Props {
  invoiceItem: InvoiceItemModel;
  isCanceledInvoice: boolean;
  rute: string;

  isLoading: boolean;
  index: number;
  isRequestEdit: boolean;
  indexCollapse: number;
  indexEdit: number;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  item: { id: number; notes: string; isRequestEdit: boolean }[];
  type: string;
  loadingEdit: boolean;
  onDeleteItem: (id: number) => void;
  onUpdate: () => void;
  individualEdit: (item: InvoiceItemModel) => void;
  handleEdit: (index: number, type: string) => void;
  handleOpenCollapse: React.MouseEventHandler;
  handleRequestNote: (item: { id: number; notes: string }) => void;
  handleRequestCheck: (item: { id: number; notes: string; isRequestEdit: boolean }) => void;
}

const useStyles = makeStyles({
  confirm: {
    background: WHITE,
    color: GREEN
  },
  reject: {
    background: WHITE,
    color: RED
  },
  tableCollapse: {
    width: '100%'
  },
  cellCollapse: {
    border: 'none'
  },
  titlePackage: {
    fontWeight: 'bold'
  },
  price: {
    padding: '4px 4px 4px 4px',
    borderBottom: '2px dotted #272727',
    backgroundColor: 'rgba(1, 136, 189, 0)',
    '&:hover': {
      padding: '4px 4px 4px 4px',
      backgroundColor: 'rgba(1, 136, 189, 0.05)',
      borderRadius: 8,
      borderBottom: '2px solid white'
    }
  }
});

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const {
    isCanceledInvoice,
    invoiceItem,
    isLoading,
    isRequestEdit,
    indexCollapse,
    indexEdit,
    item,
    type,

    rute,
    loadingEdit,
    isAdmin,
    isSuperAdmin,
    onDeleteItem,
    onUpdate,
    individualEdit,
    handleEdit,
    handleOpenCollapse,
    handleRequestNote,
    handleRequestCheck
  } = props;

  const reqEdit = item.find(value => value.id === invoiceItem.id) || { id: 0, notes: '', isRequestEdit: false };
  const isChecked = item.some(value => value.id === invoiceItem.id);

  return (
    <Fragment>
      <TableRow>
        <TableCellStart>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <Fragment>
              {isRequestEdit || invoiceItem.isRequestEdit ? (
                <>
                  {!isCanceledInvoice && isAdmin && isRequestEdit && (
                    <Zoom in={isRequestEdit} style={{ padding: 0, marginRight: 4 }}>
                      <Checkbox
                        color='primary'
                        checked={invoiceItem.isRequestEdit ? reqEdit.isRequestEdit : isChecked}
                        onChange={e => handleRequestCheck({ id: invoiceItem.id, notes: '', isRequestEdit: true })}
                        size='small'
                      />
                    </Zoom>
                  )}

                  <Zoom in={isRequestEdit || invoiceItem.isRequestEdit} style={{ padding: 0, marginRight: 4 }}>
                    {invoiceItem.id === indexCollapse ? (
                      <KeyboardArrowUpIcon style={{ verticalAlign: 'middle', cursor: 'pointer' }} onClick={handleOpenCollapse} fontSize='small' />
                    ) : (
                      <KeyboardArrowDownIcon style={{ verticalAlign: 'middle', cursor: 'pointer' }} onClick={handleOpenCollapse} fontSize='small' />
                    )}
                  </Zoom>
                </>
              ) : invoiceItem.Product && invoiceItem.Product.isProductPackage ? (
                <IconButton aria-label='expand row' size='small' onClick={handleOpenCollapse}>
                  {invoiceItem.id === indexCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              ) : invoiceItem.editLog && invoiceItem.editLog.length > 0 ? (
                <IconButton aria-label='expand row' size='small' onClick={handleOpenCollapse}>
                  {invoiceItem.id === indexCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              ) : (
                ''
              )}
              {invoiceItem.productName || '-'}
            </Fragment>
          )}
        </TableCellStart>

        <TableCellMiddle align='right'>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : !isCanceledInvoice && indexEdit === invoiceItem.id && type === 'PRICE' ? (
            <Fade in={indexEdit === invoiceItem.id && type === 'PRICE'}>
              <InputBase
                value={invoiceItem.price}
                autoFocus
                onBlur={onUpdate}
                onChange={e => individualEdit({ ...invoiceItem, price: +e.target.value })}
                inputComponent={NumberFormatMask as any}
                inputProps={{
                  style: { textAlign: 'center' }
                }}
                endAdornment={loadingEdit && type === 'PRICE' && indexEdit === invoiceItem.id && <CircularProgress color='primary' size={20} />}
                style={{ borderBottom: !isCanceledInvoice ? '2px dashed #000' : 'none' }}
                disabled={isCanceledInvoice}
              />
            </Fade>
          ) : (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {invoiceItem.upPrice && (
                <Tooltip
                  title={`Harga produk diatas harga ${rute} - Rp ${new Intl.NumberFormat('id-ID').format(invoiceItem.routePrice || 0)}`}
                  style={{ marginLeft: '0.3em' }}
                >
                  <Error fontSize='small' color='action' />
                </Tooltip>
              )}
              <NumberFormat
                onClick={() => !isCanceledInvoice && isSuperAdmin && invoiceItem.isRequestEdit && handleEdit(invoiceItem.id, 'PRICE')}
                value={invoiceItem.price}
                prefix={'Rp'}
                thousandSeparator={true}
                displayType='text'
                disabled={isCanceledInvoice}
                className={clx(!isCanceledInvoice && isSuperAdmin && invoiceItem.isRequestEdit && classes.price)}
              />
            </span>
          )}
        </TableCellMiddle>

        <TableCellMiddle align='center'>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : !isCanceledInvoice && indexEdit === invoiceItem.id && type === 'QTY' ? (
            <Fade in={indexEdit === invoiceItem.id && type === 'QTY'}>
              <InputBase
                value={invoiceItem.totalItem}
                autoFocus
                onChange={e => individualEdit({ ...invoiceItem, totalItem: +e.target.value })}
                onBlur={onUpdate}
                inputProps={{
                  style: { textAlign: 'center' }
                }}
                endAdornment={
                  invoiceItem.typeUnit ||
                  (loadingEdit && type === 'QTY' && indexEdit === invoiceItem.id && <CircularProgress color='primary' size={20} />)
                }
                style={{ borderBottom: !isCanceledInvoice ? '2px dashed #000' : 'none', width: 80 }}
                disabled={isCanceledInvoice}
              />
            </Fade>
          ) : (
            <span
              onClick={() => isSuperAdmin && invoiceItem.isRequestEdit && handleEdit(invoiceItem.id, 'QTY')}
              className={clx(!isCanceledInvoice && isSuperAdmin && invoiceItem.isRequestEdit && classes.price)}
            >
              {invoiceItem.totalItem + ' ' + invoiceItem.typeUnit}
            </span>
          )}
        </TableCellMiddle>

        <TableCellMiddle align='center'>
          {isLoading ? <Skeleton variant='text' width={100} height={25} /> : (invoiceItem.warehouse && invoiceItem.warehouse) || '-'}
        </TableCellMiddle>

        <TableCellMiddle align='right'>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : !isCanceledInvoice && indexEdit === invoiceItem.id && type === 'DISCOUNT_UNIT' ? (
            <Fade in={indexEdit === invoiceItem.id && type === 'DISCOUNT_UNIT'}>
              <InputBase
                value={invoiceItem.discountUnit}
                autoFocus
                onChange={e => individualEdit({ ...invoiceItem, discountUnit: +e.target.value })}
                onBlur={onUpdate}
                inputComponent={NumberFormatMask as any}
                inputProps={{
                  style: { textAlign: 'center' }
                }}
                style={{ borderBottom: !isCanceledInvoice ? '2px dashed #000' : 'none' }}
                disabled={isCanceledInvoice}
                endAdornment={
                  loadingEdit && type === 'DISCOUNT_UNIT' && indexEdit === invoiceItem.id && <CircularProgress color='primary' size={20} />
                }
              />
            </Fade>
          ) : (
            <NumberFormat
              onClick={() => isSuperAdmin && invoiceItem.isRequestEdit && handleEdit(invoiceItem.id, 'DISCOUNT_UNIT')}
              value={invoiceItem.discountUnit}
              prefix={'Rp'}
              thousandSeparator={true}
              displayType='text'
              className={clx(!isCanceledInvoice && isSuperAdmin && invoiceItem.isRequestEdit && classes.price)}
              disabled={isCanceledInvoice}
            />
          )}
        </TableCellMiddle>

        <TableCellMiddle align='center'>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <NumberFormat
              // onClick={() => isSuperAdmin && invoiceItem.isRequestEdit && handleEdit(invoiceItem.id, 'DISCOUNT')}
              value={((invoiceItem && invoiceItem.discountUnit) || 0) * invoiceItem.totalItem}
              prefix={'Rp'}
              thousandSeparator={true}
              displayType='text'
              //className={clx(!isCanceledInvoice && isSuperAdmin && invoiceItem.isRequestEdit && classes.price)}
              disabled={isCanceledInvoice}
            />
          )}
        </TableCellMiddle>

        <TableCellEnd align='right'>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <>
              <NumberFormat value={invoiceItem.totalPrice} prefix={'Rp'} disabled={isCanceledInvoice} thousandSeparator={true} displayType='text' />
              {!isCanceledInvoice && invoiceItem.isRequestEdit && isSuperAdmin && (
                <IconButton onClick={() => onDeleteItem(invoiceItem.id)} size='small' style={{ marginRight: 4, color: RED }}>
                  {loadingEdit && type === 'DELETE' && indexEdit === invoiceItem.id ? (
                    <CircularProgress color='primary' size={20} />
                  ) : (
                    <DeleteIcon fontSize='small' />
                  )}
                </IconButton>
              )}
            </>
          )}
        </TableCellEnd>
      </TableRow>

      {(isAdmin || isSuperAdmin) && (
        <TableRowCollapse open={invoiceItem.id === indexCollapse} colSpan={7}>
          <TextField
            disabled={isCanceledInvoice ? true : invoiceItem.isRequestEdit ? (isRequestEdit && isChecked ? false : true) : !isChecked}
            value={invoiceItem.isRequestEdit ? (isRequestEdit ? reqEdit.notes : invoiceItem.notes) : reqEdit.notes}
            onChange={e => handleRequestNote({ id: invoiceItem.id, notes: e.target.value })}
            multiline
            rows={3}
            fullWidth
            placeholder='Catatan'
          />
        </TableRowCollapse>
      )}

      {(isAdmin || isSuperAdmin) && (
        <TableRowCollapse open={(invoiceItem.id === indexCollapse && invoiceItem.editLog && invoiceItem.editLog.length > 0) || false} colSpan={7}>
          <TableContainer>
            <Table size='small'>
              <TableBody>
                {invoiceItem.editLog &&
                  invoiceItem.editLog.map((value, index) => (
                    <TableRow key={index}>
                      <TableCell>{value.label}</TableCell>
                      <TableCell>
                        {value.old} <ArrowForwardIcon fontSize='small' style={{ verticalAlign: 'middle' }} /> {value.new}
                      </TableCell>
                      <TableCell align='right'>{value.date}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TableRowCollapse>
      )}

      <TableRowCollapse open={(invoiceItem.id === indexCollapse && invoiceItem.Product && invoiceItem.Product.isProductPackage) || false} colSpan={7}>
        <Typography align='center' className={classes.titlePackage}>
          Produk Paket
        </Typography>
        <Divider />
        <TableContainer>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Nama</TableCell>
                <TableCell align='center'>Kuantitas</TableCell>
                <TableCell align='center'>Bonus Kuantitas</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {invoiceItem.Product &&
                invoiceItem.Product.ProductPackage &&
                invoiceItem.Product.ProductPackage.ProductItem &&
                invoiceItem.Product.ProductPackage.ProductItem.length > 0 &&
                invoiceItem.Product.ProductPackage.ProductItem.map((value, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cellCollapse}>{value.Product ? value.Product.productName : '-'}</TableCell>
                    <TableCell align='center' className={classes.cellCollapse}>{`${value.minimumItem || 0}/${
                      value.Product ? value.Product.typeUnit : ''
                    }`}</TableCell>
                    <TableCell align='center' className={classes.cellCollapse}>
                      {`${value.bonusItem || 0}/${value.Product ? value.Product.typeUnit : ''}`}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableRowCollapse>
    </Fragment>
  );
};

export default BodyRow;
