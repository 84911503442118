import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import {
  Add,
  Close,
  CreateOutlined,
  DeleteForeverRounded,
  ExpandLessOutlined,
  ExpandMore,
  HighlightOff,
  InfoOutlined,
  Remove
} from '@material-ui/icons';
import useRouter from 'hooks/useRouter';
import React, { FC, memo } from 'react';
import { PRIMARY_MOBILE, WHITE } from 'constants/colors';
interface Props {
  idColly: number;
  idBagColly: number;
  salesOrderItemId: number;
  open: boolean;
  collyResource: typeCollyModel[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'inline-block',
    width: '100vh',
    position: 'relative'
  },
  titleContainer: {
    fontWeight: 500,
    fontSize: '1.2rem'
  },
  containerContent: {
    overflowX: 'hidden',
    minHeight: '25vh',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  button: {
    borderRadius: '15px',
    padding: '7px 0'
  },
  itemBody: {
    display: 'flex',
    gap: '1.4em',
    padding: '0.6em 0'
  },
  containerInputColly: {
    flex: 0.9
  },
  containerAction: {
    flex: 0.1
  },
  buttonAction: {
    background: PRIMARY_MOBILE,
    '&:hover': {
      background: '#008ABF'
    }
  },
  textFieldInput: {
    textAlign: 'center'
  },
  textStyle: {
    fontSize: '0.8rem'
  },
  wrapProductName: {
    padding: '0 1em'
  },
  containerItem: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',

    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: '0.4em 1.2em 0.4em 1.9em',
    marginLeft: '-30px',

    width: '45vh'
  },
  containerItemActive: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',

    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(2, 169, 234, 0.12)',
    position: 'absolute',
    padding: '0.4em 1.2em 0.4em 1.9em',
    marginLeft: '-30px',
    width: '45vh'
  },
  containerInput: {
    flex: 0.48
  },
  alertWarning: {
    border: '1px solid #FFBF00',
    backgroundColor: 'rgba(255, 191, 0, 0.12)',
    padding: '0.8rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.8rem',
    borderRadius: '5px'
  },

  textSecondary: {
    fontSize: '0.75rem',
    color: 'grey'
  },
  textMobile: {
    fontSize: '14px',
    fontWeight: 500
  },
  cotainerAction: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: '1rem'
  }
}));

const CollyCombineDetails: FC<Props> = ({ open, setOpen, collyResource, idColly, idBagColly, salesOrderItemId }) => {
  const classes = useStyles();
  const { history } = useRouter();
  const getColly = collyResource.find(value => value.id === idColly);
  const getBagColly = getColly && getColly.BagItem.find(value => value.id === idBagColly);

  return (
    <Dialog open={open} style={{ maxHeight: 80 + 'vh', marginTop: '60px' }} scroll='paper' fullWidth>
      <DialogTitle>
        <Grid container xs={12} alignItems='center'>
          <Typography className={classes.titleContainer}>Informasi Penting</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.containerContent}>
        <Grid xs={12} container direction='column' style={{ rowGap: '0.3em' }}>
          <Grid xs={12} className={classes.alertWarning}>
            <InfoOutlined style={{ color: '#ff9800' }} />
            <Grid item>
              <Typography variant='h6' style={{ fontSize: '0.875rem' }}>
                Tempatkan item produk ke beberapa koli
              </Typography>
              <Typography className={classes.textSecondary}>
                Barang tertentu bisa terbagi dalam 1 set quantity yang besar sehingga 1 qty bisa terbagi kolinya
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} className={classes.itemBody}>
            <Grid xs={2}>
              <img
                src={getBagColly && getBagColly.productImage}
                alt='gambar produk'
                width='60px'
                height='60px'
                style={{ borderRadius: '0.3rem', border: '1px solid rgba(0, 0, 0, 0.08)' }}
              />
            </Grid>
            <Grid xs={10}>
              <Typography className={classes.textMobile}>{getBagColly && getBagColly.productName}</Typography>
            </Grid>
          </Grid>

          <Grid xs={12} container direction='column' style={{ rowGap: '0.3em' }}>
            <Grid xs={12}>
              <Typography variant='h6'>Pembagian Koli</Typography>
            </Grid>
            {collyResource
              .filter(val => val.BagItem.some(val => val.SalesOrderItemId === salesOrderItemId && val.remark !== ''))
              .map((val, key) => (
                <Grid container justify='space-between' alignItems='center' xs={12}>
                  <TextField label={key === 0 ? 'Koli Awal' : 'Additional Koli'} variant='outlined' value={val.name} disabled fullWidth />
                </Grid>
              ))}

            <Grid xs={12}>
              <Typography color='textSecondary'>Keterangan</Typography>
              <Typography>{getBagColly && getBagColly.remark}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid xs={12} container justify='center'>
          <Grid xs={11}>
            <Button size='small' fullWidth className={classes.button} color='primary' onClick={() => setOpen(false)}>
              Tutup
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default memo(CollyCombineDetails);
