import React, { FC, useEffect, useState } from 'react';
import { Grid, makeStyles, Typography, Theme, TextField, MenuItem, Button } from '@material-ui/core';
import { WHITE } from 'constants/colors';
import { CloseRounded, InfoOutlined } from '@material-ui/icons';
import CollyItem from './CollyItem';
import EmptyIcon from 'images/icon/EmptyIcon.svg';
import { Alert } from '@material-ui/lab';
import collyName from './collyName';
import clsx from 'clsx';
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    zIndex: 999,
    top: 0,
    left: 0,

    right: 0,

    minHeight: '100vh',
    width: '100vw',
    gap: '0.5rem',
    backgroundColor: WHITE,
    animation: `$up 500ms ease-in`
  },
  '@keyframes up': {
    '0%': {
      top: 400
    },

    '100%': {
      top: 0
    }
  },
  container: {
    padding: '1rem',
    backgroundColor: WHITE
  },
  fullHieght: {
    minHeight: '80vh'
  },
  header: {
    display: 'flex',
    justifyItems: 'center',
    marginBottom: theme.spacing(2),
    gap: '1rem'
  },
  containerTop: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
  },
  containerItem: {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
    border: '1px solid #D5D5D5',
    borderRadius: '5px',
    padding: '1rem',
    marginTop: theme.spacing(2)
  },
  containerBody: {},
  alertWarning: {
    border: '1px solid #FFBF00',
    backgroundColor: 'rgba(255, 191, 0, 0.12)',
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
    borderRadius: '5px'
  },
  textSecondary: {
    fontSize: '0.7rem',
    lineHeight: '1.2rem'
  },
  fontSizeMobile: {
    fontSize: '0.8rem'
  },
  buttonContainerMobile: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 4,
    backgroundColor: '#fff',
    padding: '13px 10px',
    boxShadow: '0px 0px 17px rgba(0, 0, 0, 0.08)'
  },
  containerEmpty: {
    minHeight: '250px',
    padding: '0 1em'
  }
}));

interface Props {
  salesOrderId: number;
  setHasAddColly: React.Dispatch<React.SetStateAction<boolean>>;
  salesOrderItem: SalesOrderItemModel[];

  collyResource: typeCollyModel[];
  handleSubmitColly: (value: typeCollyModel) => void;
}

const CollyCreate: FC<Props> = ({ setHasAddColly, salesOrderItem, handleSubmitColly, collyResource, salesOrderId }) => {
  const classes = useStyles();
  const [nameColly, setNameColly] = useState<string>('');
  const [validation, setValidation] = useState<boolean>(false);

  const handleSubmit = () => {
    if (
      salesOrderItem &&
      salesOrderItem
        .filter(value => value.totalItem > getTotalBag(value.id))
        .some(value => value.totalInBag + getTotalBag(value.id) > value.totalItem)
    ) {
      setValidation(true);
    } else {
      const bagItemTemp =
        salesOrderItem &&
        salesOrderItem
          .filter(value => value.totalInBag > 0 && value.totalItem > getTotalBag(value.id))
          .map((value, key) => ({
            id: Date.now() + key,
            SalesOrderItemId: value.id,
            productName: value.productName,
            totalItem: value.totalInBag,
            productImage: value.productImage,
            remark: ''
          }));

      if (bagItemTemp.length > 0) {
        handleSubmitColly({
          id: Date.now(),
          SalesOrderId: salesOrderId,
          name: nameColly,
          packName: '',
          packCode: 'all',
          BagItem: bagItemTemp,
          totalItem: 0,
          checked: false
        });
      } else {
        setValidation(true);
      }
    }
  };

  const getTotalBag = (id: number) => {
    const total = collyResource.map(value =>
      value.BagItem.filter(value => value.SalesOrderItemId === id)
        .map(value => value.totalItem)
        .reduce((a, b) => a + b, 0)
    );
    return Number(total.reduce((a, b) => a + b, 0));
  };

  const hasColly = (name: string) => {
    const hasName = collyResource.some(val => val.name === name);
    return hasName;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Grid className={classes.root}>
      <Grid className={classes.container}>
        <Grid xs={12} className={classes.header}>
          <CloseRounded fontSize='small' onClick={() => setHasAddColly(false)} />
          <Typography variant='h6'>Tambahkan Koli Baru</Typography>
        </Grid>

        <Grid xs={12} className={classes.containerTop}>
          <Grid xs={12} className={classes.alertWarning}>
            <InfoOutlined style={{ color: '#ff9800' }} />
            <Grid item>
              <Typography variant='h6'>Informasi Penting!</Typography>
              <Typography className={classes.textSecondary}>
                Nama barang setiap koli diinputkan di akhir setelah semua produk selesai dipacking kedalam koli.
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <TextField
              id='name'
              size='small'
              required
              select
              label='Nama Koli'
              InputProps={{
                classes: {
                  input: classes.fontSizeMobile
                }
              }}
              fullWidth
              value={nameColly}
              InputLabelProps={{ style: { fontSize: '1rem' } }}
              onChange={e => setNameColly(e.target.value)}
            >
              {collyName.map((val, key) => {
                return (
                  <MenuItem
                    key={key}
                    value={val}
                    selected={key === 0}
                    className={classes.fontSizeMobile}
                    disabled={key === 0}
                    style={{ backgroundColor: hasColly(val) ? 'rgba(2, 169, 234, 0.12)' : '' }}
                  >
                    {val}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
        </Grid>
      </Grid>
      {validation && (
        <Grid xs={12}>
          <Alert severity='error' className={classes.textSecondary}>
            Silahkan masukkan qty pengepakan terlebih dahulu atau jumlah melebihi qty pengepakan
          </Alert>
        </Grid>
      )}
      <Grid className={clsx(classes.container, classes.fullHieght)}>
        <Grid xs={12}>
          <Typography variant='h6'>Pilih produk dimasukkan kedalam koli ini</Typography>

          {salesOrderItem.filter(value => value.totalItem > getTotalBag(value.id)).length > 0 ? (
            salesOrderItem
              .filter(value => value.totalItem > getTotalBag(value.id))
              .map(val => (
                <Grid xs={12} className={classes.containerItem}>
                  <CollyItem items={val} totalBag={getTotalBag(val.id)} />
                </Grid>
              ))
          ) : (
            <Grid xs={12} container direction='column' alignItems='center' justify='center' className={classes.containerEmpty}>
              <img src={EmptyIcon} alt='Data Kosong' width='65px' height='65px' />

              <Typography variant='h6' align='center' style={{ marginTop: '0.4em' }}>
                Sudah tidak ada produk!
              </Typography>
              <Typography align='center' className={classes.textSecondary}>
                Silahkan untuk melihat preview surat jalan
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid xs={12} style={{ minHeight: '3.4em' }}>
        <Grid xs={12} className={classes.buttonContainerMobile}>
          <Button color='primary' style={{ borderRadius: '20px' }} fullWidth onClick={() => handleSubmit()} disabled={nameColly === ''}>
            Simpan Koli
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CollyCreate;
