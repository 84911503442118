enum TypeUser {
  SALES = 'SALES',
  ADMIN = 'HEAD_ADMIN',
  SUPERADMIN = 'SUPERADMIN',
  SUPERVISOR = 'SALES_SPV',
  DRIVER = 'DRIVER',
  PICKER = 'GUDANG',
  ADMIN01 = 'CHECKER',
  ADMIN02 = 'PENGEPAKAN',
  ADMIN03 = 'ADMIN_INVOICE',
  ADMIN02PLUS = 'PENGEPAKAN PEMBELIAN',
  ADMIN04 = 'ADMIN_KEUANGAN',
  ADMINWAREHOUSE = 'ADMINWAREHOUSE',
  ADMIN_RETURN = 'ADMIN_RETURN',
  BUYER = 'PEMBELIAN',
  ACCOUNTING = 'AKUNTING',
  HR = 'HR'
}

export default TypeUser;
