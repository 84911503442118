import React, { FC } from 'react';
import { TableContainer, TableBody, TableHead, TableRow, TableCell} from '@material-ui/core';
import { HeaderRow, TableCustom } from 'components/Table';
import { dummyStockCheck } from 'utils/dummy';
import BodyRow from './components/BodyRow';

interface Props {
  isLoadingData: boolean;
  stock: StockCheckModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  isFirstStock: boolean;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  handleConfirmationDelete: (id: number) => React.MouseEventHandler;
  handleOnUpdate: (id: number) => React.MouseEventHandler;
}

const StockTable: FC<Props> = props => {
  const { isLoadingData, stock, order, orderBy, isFirstStock, setOrder, setOrderBy, handleConfirmationDelete, handleOnUpdate } = props;

  const handleRequestSort = ({}, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <TableCustom size='small'>
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={[
              { id: 'checkDate', label: 'Tanggal Cek', sort: true },
              { id: 'WareHouseId', label: 'Gudang', sort: true, align: 'center' },
              { label: 'Ket', align: 'center' },
              { label: '', align: 'center' }
            ]}
          />
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow
                key={index + 1}
                isLoading={isLoadingData}
                stock={dummyStockCheck}
                isFirstStock={isFirstStock}
                onDelete={handleConfirmationDelete(0)}
                onUpdate={handleOnUpdate(0)}
              />
            ))
          ) : stock && stock.length > 0 ? (
            stock.map((value, index) => (
              <BodyRow
                key={index + 1}
                isLoading={isLoadingData}
                stock={value}
                isFirstStock={isFirstStock}
                isCurrent={value.isCurrent}
                onDelete={handleConfirmationDelete(value.id)}
                onUpdate={handleOnUpdate(index)}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align='center'>
                Data Tidak Tersedia.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default StockTable;
