import React, { FC, useState, useEffect, useContext, useRef } from 'react';
import { useTheme, Container, Grid, makeStyles, Theme, Typography, Backdrop, CircularProgress, useMediaQuery } from '@material-ui/core';
import { Page, StandardConfirmationDialog, Breadcrumb, FormPinDialog } from 'components';
import { BLUE_PRIMARY, WHITE } from 'constants/colors';
import {
  GET_SALES_ORDER_DETAIL_BASE_URL,
  INVOICE_ITEM_URL,
  COMPANY_BASE_URL,
  SALES_ORDER_BASE_URL,
  INVOICE_BASE_URL,
  CHECK_SALES_ORDER_BASE_URL
} from 'constants/url';
import { dummySalesOrder, dummyCompany, dummyPartner } from 'utils/dummy';
import { format, addDays } from 'date-fns';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import ItemStatus from 'typings/enum/ItemStatus';
import Preview from './components/Preview';
import axios from 'axios';
import useRouter from 'hooks/useRouter';
import useRole from 'hooks/useRole';
import Content from './components/content';
import Alert from '@material-ui/lab/Alert';
import useConfirmationPin from 'hooks/useConfirmationPin';
import TypeUser from 'typings/enum/TypeUser';
const useStyles = makeStyles((theme: Theme) => ({
  ButtonNew: {
    color: BLUE_PRIMARY,
    backgroundColor: WHITE
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: ' none'
    }
  },

  desktop: {
    [theme.breakpoints.down('md')]: {
      display: ' none'
    }
  },
  mobileContainer: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
      '& > .MuiGrid-item': {
        padding: 0
      }
    }
  }
}));

const SalesOrderDetailPage: FC = () => {
  const { match, history } = useRouter();
  const theme = useTheme();

  const showComponent = useMediaQuery(theme.breakpoints.down('md'));

  const params = match.params.id;
  const classes = useStyles();

  const { currentUser } = useContext(CurrentUserContext);

  const [salesOrder, setSalesOrder] = useState<SalesOrderModel>(dummySalesOrder);
  const [company, setCompany] = useState<CompanyModel>(dummyCompany);

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');

  const [salesOrderItem, setSalesOrderItem] = useState<SalesOrderItemModel[]>([]);
  const [invoiceItem, setInvoiceItem] = useState<SalesOrderItemModel[]>([]);
  const [value, setValue] = useState(0);
  const [id, setId] = useState<number>(0);
  const [invoiceId, setInvoceId] = useState<number>(0);

  const [partnerMessage, setPartnerMessage] = useState<string>('');
  const [pin, setPin] = useState<number | null>(null);

  const [currentItem, setCurrentItem] = useState<SalesOrderItemModel[]>([]);
  const [currentWareHouse, setCurrentWareHouse] = useState<string>('');
  const [totalItemWareHouse, setTotalItemWareHouse] = useState<{ name: string; total: number }[]>([]);
  const [currentCheckWarehouse, setCurrentCheckWarehouse] = useState<string>('');

  const [currentStatus, setCurrentStatus] = useState<{ id: number; status: string; currentStatus: string }[]>([]);

  const [convertMessage, setConvertMessage] = useState<string>('');
  const [consistenMessage, setConsistenMessage] = useState<string>('');

  const [anchorEl, setAnchorEl] = useState(null);
  const [indexTooltip, setIndexTooltip] = useState<number>(-1);
  const [indexAlertPrice, setIndexAlertPrice] = useState<number>(-1);
  const [indexAlertDiscount, setIndexAlertDiscount] = useState<number>(-1);

  const [isDeny, setDeny] = useState<boolean>(false);
  const [isComplete, setComplete] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [isChange, setChange] = useState<boolean>(false);
  const [isRedudant, setRedudant] = useState<boolean>(false);
  const [isValid, setValid] = useState<boolean>(false);
  const [isChangeSubmit, setChangeSubmit] = useState<boolean>(false);
  const [isDelete, setDelete] = useState<boolean>(false);

  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [confirmationInvoice, setConfirmationInvoice] = useState<boolean>(false);
  const [hasInvoice, setHasInvoice] = useState<boolean>(false);
  const [converting, setConverting] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const [openAlertPrice, setOpenAlertPrice] = useState<boolean>(false);
  const [openAlertDiscount, setOpenAlertDiscount] = useState<boolean>(false);

  const isAllowed = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN, TypeUser.SUPERADMIN, TypeUser.SALES, TypeUser.SUPERVISOR]
  });

  const isConvertAllowed = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN, TypeUser.SUPERADMIN]
  });

  let isNotAdminAllowed = true;
  if (currentUser && (currentUser.type === TypeUser.SALES || currentUser.type === TypeUser.SUPERVISOR)) {
    isNotAdminAllowed = false;
    if (currentUser.id == salesOrder.SalesId) {
      isNotAdminAllowed = true;
    }
  }

  const buttonGroup = useRef<HTMLDivElement>(null);

  const pinMessage = useConfirmationPin({
    pin,
    confirmationDelete: confirmationDelete,
    confirmationCreate: confirmationInvoice,
    isCompletePin: isComplete,
    handleOnDelete: deleteSalesOrder,
    handleOnCreate: handleOnCreateInvoice,
    setOpenConfirmation: setOpenConfirmation,
    setLoading: setLoadingDelete,
    setCompletePin: setComplete,
    setPin: setPin
  });

  const fetchData = async () => {
    setIsLoadingData(true);
    try {
      const { data } = await axios.get(GET_SALES_ORDER_DETAIL_BASE_URL(Number(params)));
      setSalesOrder(data.data);
      setSalesOrderItem(data.data.SalesOrderItem);
      setId(data.data.id);
      setHasInvoice(data.data.statusOrder === 'CONFIRMATION');
    } catch (error) {
      console.log('error:', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const fetchCompany = async () => {
    try {
      const { data } = await axios.get(COMPANY_BASE_URL);
      setCompany(data.data);
    } catch (error) {
      console.log('error:', error);
    }
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleConfirmSnackbar = () => {
    setOpenSnackbar(false);
    if (invoiceId > 0) {
      window.open(`/invoice/${invoiceId}`, '_blank');
      setInvoceId(0);
    }

    if (isDelete) {
      setDelete(false);
      history.push('/penjualan');
    }
  };

  const handleUpdate = () => {
    history.push('/penjualan/tambah', { id: params });
  };

  const handleOnSubmit = async () => {
    setChangeSubmit(true);
    setConsistenMessage('');

    if (!checkConsisten()) {
      setConsistenMessage('Hanya boleh mengkonfirmasi 1 gudang dalam melakukan perubahan.');
      setChangeSubmit(false);
      return;
    }

    try {
      const checkResponse = await axios.post(CHECK_SALES_ORDER_BASE_URL, {
        items: currentStatus
      });

      if (!checkResponse.data.valid) {
        setValid(!checkResponse.data.valid);
        setCurrentItem([]);
        setCurrentStatus([]);
        setInvoiceItem([]);
        setChangeSubmit(false);
        throw new Error('');
      }

      const { data } = await axios.post(`${SALES_ORDER_BASE_URL}?expand=Item,Sales,Partner`, {
        id: salesOrder.id,
        orderNumber: salesOrder.orderNumber,
        orderDate: salesOrder.orderDate,
        totalPrice: salesOrder.totalPrice,
        totalDiscount: salesOrder.totalDiscount,
        PartnerId: salesOrder.PartnerId,
        SalesId: salesOrder.SalesId,
        totalItem: currentItem.length,
        dueDate: salesOrder.dueDate,
        items: currentItem,
        notes: salesOrder.notes,
        terms: salesOrder.terms,
        signaturePath: salesOrder.signaturePath!
      });

      setChange(false);
      setSalesOrder(data.data);
      setInvoiceItem(currentItem.filter(value => value.warehouse && value.warehouse === currentWareHouse && value.status === ItemStatus.CONFIRM));
      handleSnackBar(true, 'success', 'Orderan berhasil diperbaharui');
    } catch (error) {
      console.log('error :', error);
      handleSnackBar(true, 'error', 'Orderan gagal diperbaharui');
    } finally {
      setChangeSubmit(false);
    }
  };

  const checkConsisten = () => {
    const data = currentItem
      .filter(value => value.status !== ItemStatus.PENDING && value.status !== ItemStatus.HAS_INVOICE)
      .map(value => value.warehouse || 'NO_WAREHOUSE')
      .filter((v, i, a) => a.indexOf(v) === i);

    return data.length === 1;
  };

  async function handleOnCreateInvoice(idle?: boolean) {
    setConverting(true);
    setOpenPreview(false);

    if (hasInvoice) {
      setConverting(false);
      viewPdf();
      return;
    }

    if (confirmationInvoice) setConfirmationInvoice(false);

    let totalprice = invoiceItem
      .filter(value => value.status === ItemStatus.CONFIRM)
      .map(value => value.totalPrice)
      .reduce((a, b) => a + b);

    let totalDiscount = invoiceItem
      .filter(value => value.status === ItemStatus.CONFIRM)
      .map(value => value.discount)
      .reduce((a, b) => a + b);

    let items = invoiceItem
      .filter(value => value.status === ItemStatus.CONFIRM)
      .map(value => {
        value.itemId = value.id;
        value.id = 0;
        return value;
      });

    try {
      const { data } = await axios.post(INVOICE_BASE_URL, {
        id: 0,
        orderDate: salesOrder.orderDate,
        paymentDue: format(
          addDays(new Date(salesOrder.dueDate), (salesOrder.Partner && salesOrder.Partner.Zone && salesOrder.Partner.Zone.repaymentDay) || 0),
          'yyyy-MM-dd'
        ),
        SalesId: salesOrder.SalesId,
        SalesOrderId: salesOrder.id,
        PartnerId: salesOrder.PartnerId,
        totalPrice: totalprice,
        totalDiscount: totalDiscount,
        totalItem: invoiceItem.filter(value => value.status === ItemStatus.CONFIRM).length,
        items: items,
        dueDate: salesOrder.dueDate,
        notes: salesOrder.notes,
        terms: salesOrder.terms,
        typeInvoice: idle ? 'ALLOWED' : 'GENERAL'
      });
      await axios.post(INVOICE_ITEM_URL, {
        invoiceId: data.data.id,
        items: items
      });

      setInvoceId(data.data.id);
      setHasInvoice(false);
      handleSnackBar(true, 'success', 'Invoice berhasil dibuat');
    } catch (error) {
      console.log('error :', error);
      handleSnackBar(true, 'error', 'Invoice gagal dibuat');
    } finally {
      setConverting(false);
      resetAction();
    }
  }

  const viewPdf = async () => {
    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/pdf/${invoiceId}`, { responseType: 'blob' });
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
      if (newwindow && window.focus) {
        newwindow.focus();
      }
    } catch (error) {
      console.log('error :', error);
    }
  };

  const downloadPdf = async () => {
    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/download/${invoiceId}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoice.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log('error :', error);
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleConfirm = (index: number): React.MouseEventHandler => () => {
    const currentItems = salesOrderItem.find(value => value.id === index);

    if (currentItems) {
      const item = {
        ...currentItems,
        status: ItemStatus.CONFIRM
      };

      setCurrentWareHouse(prevState => (prevState === '' ? item.warehouse || 'NO_WAREHOUSE' : prevState));

      setCurrentItem(prevState => {
        if (prevState.some(value => value.id === index)) {
          return prevState.map(value => {
            if (value.id === index) {
              value = item;
            }

            return value;
          });
        } else {
          return [...prevState, item];
        }
      });

      setCurrentStatus(prevState => {
        if (prevState.some(value => value.id === index)) {
          return prevState.map(value => {
            if (value.id === index) {
              value = {
                id: item.id,
                status: currentItems.status,
                currentStatus: item.status
              };
            }

            return value;
          });
        } else {
          return [
            ...prevState,
            {
              id: item.id,
              status: currentItems.status,
              currentStatus: item.status
            }
          ];
        }
      });

      if (currentItems.status === ItemStatus.CONFIRM) return;

      setSalesOrderItem(
        salesOrderItem.map(value => {
          if (value.id === item.id) {
            value = item;
          }
          return value;
        })
      );

      setSalesOrder(prevState => {
        prevState.totalPrice = prevState.totalPrice + item.totalPrice;
        prevState.totalDiscount = prevState.totalDiscount + item.discount;
        return prevState;
      });

      setChange(true);
    }
  };

  const handleReject = (index: number): React.MouseEventHandler => () => {
    const currentItems = salesOrderItem.find(value => value.id === index);

    if (currentItems) {
      const item = {
        ...currentItems,
        status: ItemStatus.REJECT
      };

      setCurrentWareHouse(prevState => (prevState === '' ? item.warehouse || 'NO_WAREHOUSE' : prevState));

      setCurrentItem(prevState => {
        if (prevState.some(value => value.id === index)) {
          return prevState.map(value => {
            if (value.id === index) {
              value = item;
            }

            return value;
          });
        } else {
          return [...prevState, item];
        }
      });

      setCurrentStatus(prevState => {
        if (prevState.some(value => value.id === index)) {
          return prevState.map(value => {
            if (value.id === index) {
              value = {
                id: item.id,
                status: currentItems.status,
                currentStatus: item.status
              };
            }

            return value;
          });
        } else {
          return [
            ...prevState,
            {
              id: item.id,
              status: currentItems.status,
              currentStatus: item.status
            }
          ];
        }
      });

      if (currentItems.status === ItemStatus.REJECT) return;

      setSalesOrderItem(
        salesOrderItem.map(value => {
          if (value.id === item.id) {
            value = item;
          }
          return value;
        })
      );

      if (currentItems.status === ItemStatus.CONFIRM) {
        setSalesOrder(prevState => {
          prevState.totalPrice = prevState.totalPrice - item.totalPrice;
          prevState.totalDiscount = prevState.totalDiscount - item.discount;
          return prevState;
        });
      }

      setChange(true);
    }
  };

  const handleOpenConfirmationDelete = () => {
    setConfirmationDelete(true);
  };

  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  const handleConfirmationInvoice = () => {
    setConfirmationInvoice(true);
  };

  const handleCloseConfirmationInvoice = () => {
    setConfirmationInvoice(false);
    if (salesOrderItem.length > 0) {
      if (currentCheckWarehouse !== '') {
        setInvoiceItem(
          salesOrderItem.filter(value => value.warehouse && value.warehouse === currentCheckWarehouse && value.status === ItemStatus.CONFIRM)
        );
      } else {
        setInvoiceItem(salesOrderItem.filter(value => value.status === ItemStatus.CONFIRM));
      }
    }
  };

  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
    setPin(null);
  };

  const handleOpenPreview = () => {
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const handleOpenTooltip = (index: number): React.MouseEventHandler => () => {
    setOpenTooltip(prevState => (!prevState ? true : indexTooltip !== index ? true : false));
    setIndexTooltip(index);
  };

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
    setIndexTooltip(-1);
  };

  const handleOpenAlertPrice = (index: number): React.MouseEventHandler => () => {
    setOpenAlertPrice(prevState => (!prevState ? true : indexAlertPrice !== index ? true : false));
    setIndexAlertPrice(index);
  };

  const handleCloseAlertPrice = () => {
    setOpenAlertPrice(false);
    setIndexAlertPrice(-1);
  };

  const handleOpenAlertDiscount = (index: number): React.MouseEventHandler => () => {
    setOpenAlertDiscount(prevState => (!prevState ? true : indexAlertDiscount !== index ? true : false));
    setIndexAlertDiscount(index);
  };

  const handleCloseAlertDiscount = () => {
    setOpenAlertDiscount(false);
    setIndexAlertDiscount(-1);
  };

  const handleCheckIndividualPreview = (item: SalesOrderItemModel) => {
    setInvoiceItem(prevState => {
      if (prevState.some(value => value.id === item.id)) {
        return prevState.filter(value => value.id !== item.id);
      } else {
        return prevState;
      }
    });
  };

  async function deleteSalesOrder() {
    try {
      await axios.delete(GET_SALES_ORDER_DETAIL_BASE_URL(id));
      setDelete(true);
      handleSnackBar(true, 'success', 'Order berhasil dihapus');
    } catch (err) {
      console.log(err);
      handleSnackBar(true, 'error', 'Order gagal dihapus');
    } finally {
      setConfirmationDelete(false);
    }
  }

  useEffect(() => {
    const partner: PartnerModel = salesOrder.Partner || dummyPartner;
    const totalBill = partner.totalBill && partner.totalBill > 0 ? salesOrder.totalPrice + partner.totalBill : 0;
    const plafon = partner.plafon || 0;

    if (totalBill > plafon && plafon > 0) {
      setPartnerMessage('Customer ini memiliki jumlah total tagihan belum terbayarkan.');
      setDeny(true);
    }
  }, [salesOrder]);

  useEffect(() => {
    fetchData();
    fetchCompany();
  }, []);

  useEffect(() => {
    if (salesOrderItem.length < 1 && totalItemWareHouse.length > 1) return;
    let totalWareHouse = salesOrderItem.map((value: any) => value.warehouse || 'NO_WAREHOUSE').filter((v: any, i: any, a: any) => a.indexOf(v) === i);
    setTotalItemWareHouse(
      totalWareHouse.map((value: any) => {
        return {
          name: value,
          total: salesOrderItem.filter((_value: any) => _value.warehouse && _value.warehouse === value).length
        };
      })
    );
  }, [salesOrderItem]);

  useEffect(() => {
    setConvertMessage('');

    if (salesOrderItem.length === 0) return;

    if (currentCheckWarehouse === '') {
      let totalWareHouse = salesOrderItem
        .filter(value => value.status === ItemStatus.CONFIRM)
        .map((value: any) => value.warehouse || 'NO_WAREHOUSE')
        .filter((v: any, i: any, a: any) => a.indexOf(v) === i);

      setRedudant(totalWareHouse.length > 1);
      setConvertMessage(totalWareHouse.length > 1 ? 'Akan terdapat 2 gudang dalam 1 Invoice mohon, untuk memilih satu gudang.' : '');

      if (totalWareHouse.length > 1) {
        setAnchorEl(buttonGroup.current as any);
      }
      return;
    }

    let totalWareHouse = salesOrderItem
      .filter(value => value.status === ItemStatus.CONFIRM && value.warehouse === currentCheckWarehouse)
      .map((value: any) => value.warehouse || 'NO_WAREHOUSE')
      .filter((v: any, i: any, a: any) => a.indexOf(v) === i);

    setAnchorEl(null);
    setRedudant(totalWareHouse.length > 1);
  }, [salesOrderItem, currentCheckWarehouse]);

  useEffect(() => {
    if (salesOrderItem.length === 0) return;

    if (currentCheckWarehouse !== '') {
      setInvoiceItem(
        salesOrderItem.filter(value => value.warehouse && value.warehouse === currentCheckWarehouse && value.status === ItemStatus.CONFIRM)
      );
    } else {
      setInvoiceItem(salesOrderItem.filter(value => value.status === ItemStatus.CONFIRM));
    }
  }, [salesOrderItem, currentCheckWarehouse]);

  const resetAction = () => {
    setInvoiceItem([]);
    setCurrentItem([]);
    setCurrentWareHouse('');
    setCurrentCheckWarehouse('');
    setChange(false);
    fetchData();
    setAnchorEl(null);
    setValid(false);
  };

  return (
    <Page title='Detail Orderan Penjualan'>
      <Container>
        <Backdrop className={classes.backdrop} open={converting}>
          <CircularProgress color='inherit' /> &nbsp; Membuat Invoice...
        </Backdrop>

        <Grid container direction='row' className={classes.desktop}>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <Typography variant='h1'> Penjualan </Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <Grid container direction='row' spacing={showComponent ? 0 : 2} justify='space-between' classes={{ root: classes.mobileContainer }}>
          <Content
            salesOrder={salesOrder}
            salesOrderItem={salesOrderItem}
            company={company}
            isLoadingData={isLoadingData}
            value={value}
            isChange={isChange}
            consistenMessage={consistenMessage}
            totalWarehouse={totalItemWareHouse}
            handleChange={handleChange}
            handleConfirm={handleConfirm}
            handleReject={handleReject}
            handleOnSubmit={handleOnSubmit}
            handleReset={resetAction}
            handleOpenConfirmationDelete={handleOpenConfirmationDelete}
            download={downloadPdf}
            isValid={isValid}
            hasInvoice={hasInvoice}
            isChangeSubmit={isChangeSubmit}
            isConvertAllowed={isConvertAllowed}
            currentWareHouse={currentWareHouse}
            anchorEl={anchorEl}
            buttonGroup={buttonGroup}
            currentCheckWarehouse={currentCheckWarehouse}
            totalApprove={currentItem.length}
            setCurrentCheckWarehouse={setCurrentCheckWarehouse}
            indexTooltip={indexTooltip}
            openTooltip={openTooltip}
            handleOpenTooltip={handleOpenTooltip}
            handleCloseTooltip={handleCloseTooltip}
            openAlertPrice={openAlertPrice}
            indexAlertPrice={indexAlertPrice}
            handleOpenAlertPrice={handleOpenAlertPrice}
            handleCloseAlertPrice={handleCloseAlertPrice}
            openAlertDiscount={openAlertDiscount}
            indexAlertDiscount={indexAlertDiscount}
            handleOpenAlertDiscount={handleOpenAlertDiscount}
            handleCloseAlertDiscount={handleCloseAlertDiscount}
          />
        </Grid>

        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
          onConfirm={handleCloseSnackbar}
          noCancelButton={true}
        />

        <StandardConfirmationDialog
          variant={'danger'}
          titleMessage={'Menghapus Data'}
          message={'Apakah kamu yakin akan menghapus data ini?'}
          open={confirmationDelete}
          handleClose={handleCloseConfirmationDelete}
          onConfirm={salesOrder.statusOrder !== 'CONFIRMATION' ? deleteSalesOrder : handleOpenConfirmation}
          isLoading={loadingDelete}
        />

        <StandardConfirmationDialog
          variant={'success'}
          titleMessage={'Konfirmasi Invoice'}
          message={'Apakah kamu yakin akan melanjutkan Order ini?'}
          open={confirmationInvoice}
          handleClose={handleCloseConfirmationInvoice}
          onConfirm={() => (isDeny ? handleOpenConfirmation() : handleOpenPreview())}
          isLoading={loadingDelete}
        />

        <FormPinDialog
          open={openConfirmation}
          pinMessage={pinMessage}
          isComplete={isComplete}
          setComplete={setComplete}
          setPin={setPin}
          handleClose={handleCloseConfirmation}
        />

        <Preview
          items={invoiceItem}
          openPreview={openPreview}
          count={invoiceItem.length}
          handleClose={handleClosePreview}
          handleIndividualCheck={handleCheckIndividualPreview}
          handleOnCreateInvoice={handleOnCreateInvoice}
        />
      </Container>
    </Page>
  );
};

export default SalesOrderDetailPage;
