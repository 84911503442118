import React from 'react';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Tooltip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  foreground: {
    borderRadius: '50%',
    boxShadow: `inset 0 0 0 ${(3.6 / 44) * 40}px #d1d1d1`
  }
}));

export default ({ value, total, note, ...props }: CircularProgressProps & { value: number; total: number; note?: string }) => {
  const classes = useStyles();
  return (
    <Tooltip title={note || `Diproses ${value || 0} dari ${total || 0}`}>
      <Box position='relative' display='inline-flex'>
        <CircularProgress variant='determinate' {...props} value={Math.round((value / total) * 100)} className={classes.foreground} />
        <Box top={0} left={0} bottom={0} right={0} position='absolute' display='flex' alignItems='center' justifyContent='center'>
          <Typography variant='caption' component='div' color='textSecondary'>
            {value || 0}/{total || 0}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
};
