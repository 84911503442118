import React, { FC, useEffect, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { Grid, Container, Typography, Button } from '@material-ui/core';
import { AddBox } from '@material-ui/icons';
import { Page, PaperCustom, Breadcrumb } from 'components';
import { PRODUCT_BASE_URL, CATEGORY_BASE_URL, RETURN_ITEM_BASE_URL } from 'constants/url';
import useRouter from 'hooks/useRouter';
import ProductTable from './components/ProductTable';
import Pagination from '@material-ui/lab/Pagination';
import RefreshIcon from '@material-ui/icons/Refresh';
import _ from 'lodash';
import PaginationCustom from 'components/PaginationCustom';

const ProductDamagePage: FC = () => {
  const { history } = useRouter();
  const [count, setCount] = useState<number>(0);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const [category, setCategory] = useState<number>(0);
  const [productName, setProductName] = useState<string>('');

  const [loadingItem, setLoadingItem] = useState<boolean>(true);
  const [checked, setChecked] = useState<InvoiceReturnItemModel[]>([]);
  const [returnNumber, setReturnNumber] = useState<{ returnNumber: string; returnDate: string; totalDamage: number }[] | null>(null);

  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('id');

  const cancelToken: CancelTokenSource = axios.CancelToken.source();

  const fetchData = async () => {
    setIsLoadingData(true);
    setIndexCollapse(-1);

    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (productName) {
        params.append('keyword', productName);
      }

      if (category > 0) {
        params.append('CategoryId', category.toString());
      }

      params.append('damage', 'true');
      params.append('page', currentPage.toString());

      return params.toString();
    };

    try {
      const { data } = await axios.get(`${PRODUCT_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelToken.token });
      setProducts(data.data);
      setCount(data.meta.last_page);
      setCurrentPage(data.meta.current_page);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const onRefresh = async () => {
    fetchData();
    setChecked([]);
    setIndexCollapse(-1);
  };

  const getData = async (id: number) => {
    setLoadingItem(true);
    try {
      const { data } = await axios.get(`${RETURN_ITEM_BASE_URL}/get-item/${id}`);
      setReturnNumber(data.data);
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoadingItem(false);
    }
  };

  const getCategory = async () => {
    try {
      const { data } = await axios.get(CATEGORY_BASE_URL);
      setCategories(data.data);
    } catch (err) {
      console.log('err', err);
    } finally {
    }
  };

  const handleOpenCollapse = (id: number): React.MouseEventHandler => () => {
    setIndexCollapse(id);
    setOpenCollapse(openCollapse ? (id === indexCollapse ? false : true) : true);
    getData(id);
  };

  const handleChecked = (item: InvoiceReturnItemModel) => {
    setChecked(prevState => {
      if (prevState.some(value => value.ProductId === item.ProductId)) {
        return prevState.filter(value => value.ProductId !== item.ProductId);
      } else {
        return [...prevState, item];
      }
    });
  };

  const handleCheckAll = (checked: boolean) => {
    if (checked) {
      products.map(value => {
        const item = {
          id: 0,
          InvoiceReturnId: 0,
          ProductId: value.id,
          typeUnit: value.typeUnit || '-',
          totalItem: value.totalDamage || 0,
          price: value.purchasePrice,
          subTotalPrice: (value.totalDamage || 0) * value.purchasePrice,
          totalPrice: (value.totalDamage || 0) * value.purchasePrice,
          discount: 0,
          totalDamage: 0,
          totalReturned: 0,
          totalMerge: 0
        };

        setChecked(prevState => {
          if (prevState.some(value => value.ProductId === item.ProductId)) {
            return prevState;
          } else {
            return [...prevState, item];
          }
        });
      });
    } else {
      setChecked([]);
    }
  };

  const handleCreateReturnOrder = () => {
    history.push('/return-pembelian/pembelian');
  };

  useEffect(() => {
    // const debouncedFetchHits = _.debounce(fetchData, 500);
    // debouncedFetchHits();
    fetchData();
    return () => cancelToken.cancel('No longer latest query');
  }, [orderBy, order, currentPage, productName, category]);

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <Page title='Return Supplier'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1' component='h1'>
              Return Supplier
            </Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid container direction='row' spacing={1}>
            <Grid item container lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Button onClick={onRefresh} color='inherit'>
                  <RefreshIcon />
                </Button>
              </Grid>
              <Grid item lg={2} md={3} sm={2} xs={2}></Grid>
              <Grid item lg={2} md={3} sm={2} xs={2}></Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}></Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}></Grid>

              <Grid item container justify='flex-end' lg={3} md={2} sm={3} xs={3}>
                <Button onClick={handleCreateReturnOrder} disabled={isLoadingData}>
                  <AddBox fontSize='small' /> &nbsp; Return Supplier
                </Button>
              </Grid>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ProductTable
                handleCheckAll={handleCheckAll}
                returnNumber={returnNumber}
                isLoadingData={isLoadingData}
                loadingItem={loadingItem}
                products={products}
                order={order}
                orderBy={orderBy}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                categories={categories}
                productName={productName}
                category={category}
                setCategory={setCategory}
                setProductName={setProductName}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                handleChecked={handleChecked}
                checked={checked}
                handleOpenCollapse={handleOpenCollapse}
              />
            </Grid>

            <Grid container justify='flex-end' item xl={12} md={12} sm={12}>
              <PaginationCustom
                marginTop='-.4em '
                show={true}
                sxPagination={{
                  count,
                  boundaryCount: 2,
                  variant: 'outlined',
                  shape: 'rounded',
                  onChange: (event, page) => {
                    setCurrentPage(page);
                  },
                  page: currentPage
                }}
                sxPopover={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  }
                }}
                customPageProps={{
                  defaultValue: currentPage,
                  maxValue: count,
                  onSubmit(value) {
                    setCurrentPage(value);
                  }
                }}
              />
            </Grid>
          </Grid>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default ProductDamagePage;
