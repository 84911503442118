import React, { FC, Fragment } from 'react';
import { Grid, makeStyles, Theme, Typography, TableContainer, Table, TableRow, TableCell, TableBody } from '@material-ui/core';
import ListProduct from './ListProduct';
import Skeleton from '@material-ui/lab/Skeleton';
import { BLACK } from 'constants/colors';
import { format } from 'date-fns';

interface Props {
  stock: StockModel;
  isLoadingData: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  cellSummaryRight: {
    border: 'none',
    padding: 4,
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontStyle: 'normal',
    color: BLACK
  },
  cellSummaryLeft: {
    width: '26%',
    border: 'none',
    padding: '4px 59px 4px 4px',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal',
    color: BLACK
  },
  cellInfoRight: {
    padding: '2px 0px 2px 2px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  cellInfoLeft: {
    padding: '2px 0px 2px 0px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  cellFinalRight: {
    marginRight: 65
  },
  text: {
    paddingTop: 60,
    marginLeft: 8,
    paddingLeft: 8
  },
  cellFinalLeft: {
    marginLeft: 65
  }
}));

const StockInfo: FC<Props> = props => {
  const classes = useStyles();
  const { stock, isLoadingData } = props;

  return (
    <Fragment>
      <Grid item container spacing={2} justify={'space-between'}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align='right'>{isLoadingData ? <Skeleton variant='text' width='100%' /> : stock.id || '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tanggal Masuk</TableCell>
                  <TableCell align='right'>
                    {isLoadingData ? (
                      <Skeleton variant='text' width='100%' />
                    ) : stock.enterDate ? (
                      format(new Date(stock.enterDate), 'dd/MM/yyyy')
                    ) : (
                      '-'
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Supplier</TableCell>
                  <TableCell align='right'>{isLoadingData ? <Skeleton variant='text' width='100%' /> : stock.Partner ? stock.Partner.name : '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Gudang</TableCell>
                  <TableCell align='right'>{isLoadingData ? <Skeleton variant='text' width='100%' /> : stock.WareHouse ? stock.WareHouse.name : '-' }</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Deskripsi</TableCell>
                  <TableCell align='right'>{isLoadingData ? <Skeleton variant='text' width='100%' /> : stock.description || '-'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography variant='h5' className={classes.text}>
          Semua Produk
        </Typography>
      </Grid>
      <Grid item lg={12} sm={12} md={12} xs={12}>
        <ListProduct isLoadingData={isLoadingData} stockItem={stock.StockItem ? stock.StockItem : []} />
      </Grid>
    </Fragment>
  );
};

export default StockInfo;
