import React, { FC, useEffect, useCallback, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { Grid, Container, Typography, Tooltip, Button, IconButton, makeStyles, Theme } from '@material-ui/core';
import { Page, PaperCustom, Breadcrumb } from 'components';
import { INVOICE_ITEM_URL } from 'constants/url';
import { dummyItemInvoice } from 'utils/dummy';
import StockOutTable from './components/StockOutTable';
import Pagination from '@material-ui/lab/Pagination';
import DateRangeFilter from 'components/DateRangeFilter';
import CalendarIcon from '@material-ui/icons/EventNote';
import RefreshIcon from '@material-ui/icons/Refresh';
import { BLUE_PRIMARY, GREEN, WHITE } from 'constants/colors';
import { format, startOfMonth } from 'date-fns';
import PaginationCustom from 'components/PaginationCustom';

const useStyles = makeStyles((theme: Theme) => ({
  calendarIcon: {
    fontSize: 30,
    color: theme.palette.primary.main
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  }
}));

const StockOutPage: FC = () => {
  const classes = useStyles();
  const [count, setCount] = useState<number>(0);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [items, setItems] = useState<InvoiceItemModel[]>([dummyItemInvoice]);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [outDate, setOutDate] = useState<Date | null>(null);
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [from, setFrom] = useState<number>(0);

  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (invoiceNumber) {
        params.append('keyword', invoiceNumber);
      }

      if (orderBy || order) {
        params.append('orderBy', orderBy);
        params.append('ordered', order);
      }

      if (startDate || endDate) {
        params.append('startDate', startDate);
        params.append('endDate', endDate);
      }

      params.append('page', currentPage.toString());
      return params.toString();
    };

    try {
      const { data } = await axios.get(`${INVOICE_ITEM_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });
      setItems(data.data);
      setCount(data.meta.last_page);
      setCurrentPage(data.meta.current_page);
      setTotal(data.meta.total);
      setTo(data.meta.to);
      setFrom(data.meta.from);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoadingData(false);
    }
    return () => {
      cancelTokenSource.cancel();
    };
  }, [orderBy, order, outDate, invoiceNumber, currentPage, startDate, endDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCalendarFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenCalendarFilter(!openCalendarFilter);
  };

  return (
    <Page title='Stok'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Stok Keluar </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid container direction='row' spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={6} xs={6}>
              <Tooltip title='Memuat Ulang'>
                <Button onClick={() => fetchData()} color='inherit' className={classes.refresh}>
                  <RefreshIcon />
                </Button>
              </Tooltip>
            </Grid>

            <Grid container alignItems='center' item xl={8} lg={8} md={8} sm={6} xs={6}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography>{`Menampilkan ${total} Stok Keluar (${from} - ${to} dari ${total})`}</Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                {startDate && endDate && (
                  <Typography variant='body2' style={{ fontSize: 12, color: BLUE_PRIMARY }}>
                    Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid container alignItems='center' justify='flex-end' item xl={3} lg={3} md={3} sm={6} xs={6}>
              <Tooltip title='Calendar filter' placement='top'>
                <IconButton size='small' onClick={event => handleCalendarFilterClick(event)}>
                  <CalendarIcon className={classes.calendarIcon} />
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid container justify='center' item xl={12} lg={12} md={12} sm={12} xs={12}>
              <StockOutTable
                isLoadingData={isLoadingData}
                item={items}
                order={order}
                orderBy={orderBy}
                outDate={outDate}
                setOutDate={setOutDate}
                invoiceNumber={invoiceNumber}
                setInvoiceNumber={setInvoiceNumber}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
              />
            </Grid>
            <DateRangeFilter
              openCalendarFilter={openCalendarFilter}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              handleClose={() => {
                setOpenCalendarFilter(false);
              }}
            />
            <Grid container justify='flex-end' item xl={12} md={12} sm={12}>
              <PaginationCustom
                marginTop='-.4em '
                show={items.length > 0}
                sxPagination={{
                  count,
                  boundaryCount: 2,
                  variant: 'outlined',
                  shape: 'rounded',
                  onChange: (event, page) => {
                    setCurrentPage(page);
                  },
                  page: currentPage
                }}
                sxPopover={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  }
                }}
                customPageProps={{
                  defaultValue: currentPage,
                  maxValue: count,
                  onSubmit(value) {
                    setCurrentPage(value);
                  }
                }}
              />
            </Grid>
          </Grid>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default StockOutPage;
