enum SalesOrderStatus {
  PENDING = 'PENDING',
  PREVIEW = 'PREVIEW', //Gudang
  PACKING = 'PACKING', //Admin1
  CHECKING = 'CHECKING', //Admin2
  CONFIRM = 'CONFIRM', //Admin3
  REJECT = 'REJECT', //
  HAS_INVOICE = 'HAS_INVOICE'
}
export default SalesOrderStatus;
