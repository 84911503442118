import React, { FC } from 'react';
import {
  Button,
  makeStyles,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  FormHelperText
} from '@material-ui/core';
import { DialogTitle } from 'components/Dialog';
import { TableCustom } from 'components/Table';
import BodyRow from './components/BodyRow';
import { GREEN, WHITE } from 'constants/colors';
import NumberFormat from 'react-number-format';

interface Props {
  items: SalesOrderItemModel[];
  openPreview: boolean;
  count: number;
  handleClose: () => void;
  handleIndividualCheck: (item: SalesOrderItemModel) => void;
  handleOnCreateInvoice: (idle?: boolean | undefined) => Promise<void>;
}

const useStyles = makeStyles({
  dialogContent: {
    margin: '0 auto',
    width: 1031,
    height: 590,
    padding: '24px'
  },
  next: {
    color: WHITE,
    backgroundColor: GREEN
  }
});

const Preview: FC<Props> = props => {
  const classes = useStyles();
  const { items, openPreview, count, handleClose, handleIndividualCheck, handleOnCreateInvoice } = props;

  return (
    <Dialog open={openPreview} onClose={handleClose} maxWidth={false} classes={{ paper: classes.dialogContent }}>
      <DialogTitle>Pratinjau</DialogTitle>
      <DialogContent>
        <Grid direction='row' container>
          <Grid item lg={12}>
            <FormHelperText error={count === 0}>{count === 0 && 'Item Tidak Boleh Kosong'}</FormHelperText>
          </Grid>

          <Grid item lg={3}>
            Total Item : {count}
          </Grid>

          <Grid item lg={3}>
            Total Harga :{' '}
            <NumberFormat
              value={items.length > 0 ? items.map(value => value.totalPrice).reduce((a, b) => a + b) : 0}
              prefix={'Rp'}
              thousandSeparator={true}
              displayType='text'
            />
          </Grid>

          <Grid item lg={6}>
            <FormHelperText error>Mohon untuk memeriksa kembali item yang akan di konversi kedalam Nota</FormHelperText>
          </Grid>

          <Grid item lg={12}>
            <TableContainer>
              <TableCustom size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>Nama Produk</TableCell>
                    <TableCell align='center'>Harga</TableCell>
                    <TableCell align='center'>Kuantitas</TableCell>
                    <TableCell align='center'>Gudang</TableCell>
                    <TableCell align='center'>Diskon</TableCell>
                    <TableCell align='center'>Total Harga</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items && items.length > 0 ? (
                    items.map((value, index) => <BodyRow key={index} item={value} checked={items} handleIndividualCheck={handleIndividualCheck} />)
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align='center'>
                        Produk item tidak boleh kosong.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </TableCustom>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={handleClose}>
          Batal
        </Button>
        <Button disabled={count === 0} onClick={() => handleOnCreateInvoice(false)} className={classes.next}>
          Konversi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Preview;
