import { Dialog, DialogContent, Divider, Grid, makeStyles, Modal, Theme, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { FC } from 'react';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em',
    padding: '1em'
  },
  titleContainer: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)'
  }
}));
interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  historyResource: { createdAt: string; price: number }[];
}
const ModalHistory: FC<Props> = ({ open, setOpen, historyResource }) => {
  const classes = useStyles();
  return (
    <Dialog open={open}>
      <Grid xs={12} container className={classes.container}>
        <Grid xs={12} container justify='space-between' alignItems='center' className={classes.titleContainer}>
          <Typography variant='h5'>Riwayat Harga</Typography>
          <Close style={{ cursor: 'pointer' }} onClick={() => setOpen(false)} />
        </Grid>
        <Grid xs={12}>
          {historyResource.length > 0 ? (
            historyResource.map((history, key) => (
              <Grid xs={12} container key={key}>
                <Grid xs={6}>
                  <Typography>
                    {key + 1} . {format(new Date(history.createdAt), 'dd MMM yyyy')}
                  </Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography align='right'>
                    <NumberFormat value={history.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  </Typography>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid xs={12}>
              <Typography>Riwayat harga tidak ada</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ModalHistory;
