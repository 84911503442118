import React, { FC, useState, useEffect } from 'react';
import { Container, Grid, Typography, TableContainer, Table, TableRow, TableCell, TableBody } from '@material-ui/core';
import { Page, StandardConfirmationDialog, Breadcrumb, PaperCustom } from 'components';
import { GET_STOCK_DETAIL_BASE_URL } from 'constants/url';
import { dummyStock } from 'utils/dummy';
import { format } from 'date-fns';
import axios from 'axios';
import useRouter from 'hooks/useRouter';
import Skeleton from '@material-ui/lab/Skeleton';
import ListProduct from './components/ListProduct';

const StockInDetailPage: FC = () => {
  const { location } = useRouter();
  // eslint-disable-next-line
  const params: any = location.state;

  const [stock, setStock] = useState<StockModel>(dummyStock);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    setIsLoadingData(true);
    const fetchData = async () => {
      try {
        const { data } = await axios.get(GET_STOCK_DETAIL_BASE_URL(params.id));
        setStock(data.data);
      } catch (error) {
        console.log('error:', error);
      } finally {
        setIsLoadingData(false);
      }
    };
    fetchData();
  }, []);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Page title='Stok Detail'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <Typography variant='h1'> Stok </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid container direction='row' spacing={2}>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Nomor Pembelian</TableCell>
                      <TableCell align='right'>{isLoadingData ? <Skeleton variant='text' width='100%' /> : stock.purchasesNumber || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Tanggal Masuk</TableCell>
                      <TableCell align='right'>
                        {isLoadingData ? (
                          <Skeleton variant='text' width='100%' />
                        ) : stock.enterDate ? (
                          format(new Date(stock.enterDate), 'dd/MM/yyyy')
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Supplier</TableCell>
                      <TableCell align='right'>
                        {isLoadingData ? <Skeleton variant='text' width='100%' /> : stock.Partner ? stock.Partner.name : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Gudang</TableCell>
                      <TableCell align='right'>
                        {isLoadingData ? <Skeleton variant='text' width='100%' /> : stock.WareHouse ? stock.WareHouse.name : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Deskripsi</TableCell>
                      <TableCell align='right'>{isLoadingData ? <Skeleton variant='text' width='100%' /> : stock.description || '-'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant='h5'>Semua Produk</Typography>
            </Grid>

            <Grid container item lg={12} sm={12} md={12} xs={12}>
              <ListProduct isLoadingData={isLoadingData} stockItem={stock.StockItem ? stock.StockItem : []} />
            </Grid>
          </Grid>
        </PaperCustom>

        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
          onConfirm={handleCloseSnackbar}
          noCancelButton={true}
        />
      </Container>
    </Page>
  );
};

export default StockInDetailPage;
