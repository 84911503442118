import React, { FC, useContext } from 'react';
import { TableRow, Typography, Grid, TableCell, Divider } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import useRole from 'hooks/useRole';

interface Props {
  item: InvoiceReturnItemModel;
  typeReturn: string;
  isLoading: boolean;
}

const BodyRow: FC<Props> = props => {
  const { item, typeReturn } = props;

  const { currentUser } = useContext(CurrentUserContext);

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: ['SUPERADMIN']
  });

  return (
    <TableRow>
      <TableCell style={{ padding: '4px 0px 4px 0px' }}>
        <Grid container direction='column' spacing={1}>
          <Grid item>
            <Typography variant='h6'>{(item.Product && item.Product.productName) || '-'}</Typography>
          </Grid>
          <Grid item>
            {(typeReturn === 'CUSTOMER' || (typeReturn === 'SUPPLIER' && isSuperAdmin)) && (
              <NumberFormat value={item.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
            )}
            <span style={{ float: 'right' }}>{`${item.totalItem + (item.additionalDamage || 0)} ${item.typeUnit}`}</span>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            {(typeReturn === 'CUSTOMER' || (typeReturn === 'SUPPLIER' && isSuperAdmin)) && (
              <>
                Total
                <span style={{ float: 'right' }}>
                  <NumberFormat value={item.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </span>
              </>
            )}
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default BodyRow;
