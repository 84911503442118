import React, { FC, Fragment, useEffect, useState } from 'react';
import {
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  makeStyles,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  Checkbox,
  Button
} from '@material-ui/core';
import { HeaderRow, TableCellHead, TableCustom } from 'components/Table';
import { dummyStokAllItem } from 'utils/dummy';
import BodyRow from './component/BodyRow';
import { CloseRounded, SearchRounded } from '@material-ui/icons';
import { GREY, RED_MOBILE } from 'constants/colors';
interface Props {
  isLoadingData: boolean;
  isKeyWord: boolean;
  allStocks: StockAllItemModel[];
  keyWordResource: { productName: string, wareHouse: string }[];
  order: 'asc' | 'desc';
  keyWord: string;
  orderBy: string;
  isChecker: boolean;
  searchTemp: string;
  productName: string;
  partners: PartnerModel[];
  isLoadingPartners: boolean;
  partner: number[];
  category: CategoryModel;
  categories: CategoryModel[];
  anchorEl: null | HTMLElement;
  openOptions: boolean;
  stockId: number;
  handlePartnerLoadMore: () => void;
  setStockId: React.Dispatch<React.SetStateAction<number>>;
  handleCloseOption: React.MouseEventHandler;
  handleOpenOption: React.MouseEventHandler;
  handleStockSales: React.MouseEventHandler;
  handleStockDamaged: React.MouseEventHandler;
  loadCategory: boolean;
  setPartner: React.Dispatch<React.SetStateAction<number[]>>;
  setSearchTemp: React.Dispatch<React.SetStateAction<string>>;
  setProductName: React.Dispatch<React.SetStateAction<string>>;

  setKeyWord: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  setIsKeyWord: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  handleSearchCategory: (value: string) => void;
  indexCollapse: number;
  openCollapse: boolean;
  loadingItem: boolean;
  handleOpenCollapse: (index: number, id: number) => React.MouseEventHandler;
}

const useStyles = makeStyles({
  cellTable: {
    width: '20%'
  },
  containerSugestSearch: {
    position: 'absolute',
    backgroundColor: '#fff',
    overflowY: 'scroll',
    borderRadius: '0 0 0.5em 0.5em',
    border: '2px solid rgba(0, 0, 0, 0.08)',
    top: 48,
    zIndex: 2,
    width: '196%',
    maxHeight: '30vh'
  },
  iconClose: {
    position: 'absolute',
    right: 55,
    top: 15,
    cursor: 'pointer',
    color: RED_MOBILE,
    fontWeight: 500,
    backgroundColor: '#fff',
    borderRadius: '1em',
    border: '2px solid ' + GREY,
    zIndex: 5
  }
});

const AllStockTable: FC<Props> = props => {
  const classes = useStyles();
  const {
    isLoadingData,
    allStocks,
    stockId,
    setStockId,
    order,
    orderBy,
    keyWord,
    category,
    isChecker,
    categories,
    partners,
    productName,
    searchTemp,
    setSearchTemp,
    anchorEl,
    openOptions,
    handlePartnerLoadMore,
    handleCloseOption,
    handleOpenOption,
    setIsKeyWord,
    setKeyWord,
    setCurrentPage,
    keyWordResource,
    isKeyWord,
    loadCategory,
    isLoadingPartners,
    partner,
    setPartner,
    handleStockSales,
    handleStockDamaged,
    setProductName,
    setOrder,
    setOrderBy,
    indexCollapse,
    openCollapse,
    loadingItem,
    handleOpenCollapse,
    handleSearchCategory
  } = props;

  const handleRequestSort = ({}, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setProductName(searchTemp);
      setIsKeyWord(false);
      setCurrentPage(1);
    }
  };

  const handleClose = () => {
    setSearchTemp('');
    setIsKeyWord(false);
    setProductName('');
  };
  const handleSearchProduct = () => {
    setIsKeyWord(false);
    setCurrentPage(1);
  };

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={[
              { label: '#' },
              { id: 'productName', label: 'Nama Produk', sort: true },
              { id: 'supplier', label: 'Nama Supplier', sort: true, align: 'center' },
              { id: 'saleStock', label: 'Stok Jual', align: 'center', sort: true },
              { label: 'Stok Tertahan', align: 'center' },
              { label: 'Stok Rusak Gudang', align: 'center' },
              { label: 'Stok Bersih', align: 'center' },
              { label: 'Gudang', sort: true, align: 'center' },
              { label: !isChecker ? 'Aksi' : '', sort: true, align: 'center' }
            ]}
          />
          <TableRow>
            <TableCellHead variant='head' className={classes.cellTable} style={{ position: 'relative' }} colSpan={2}>
              {searchTemp !== '' && <CloseRounded className={classes.iconClose} onClick={handleClose} />}
              <TextField
                id='salesOrderId'
                placeholder='Nama Produk'
                value={searchTemp}
                onChange={event => {
                  setSearchTemp(event.target.value);
                  setKeyWord(event.target.value);
                }}
                onKeyDown={handleKeyDown}
                autoComplete='off'
              />
              {isKeyWord && keyWordResource.length > 0 && (
                <Grid className={classes.containerSugestSearch}>
                  <List>
                    {keyWordResource.map((value, key) => (
                      <ListItem
                        button
                        key={key}
                        onClick={() => {
                          handleSearchProduct();
                          setIsKeyWord(false);
                          setSearchTemp(value.productName);
                          setProductName(value.productName);
                        }}
                      >
                        <SearchRounded style={{ paddingRight: '0.3em', color: 'grey' }} fontSize='default' />
                        <ListItemText primary={`${value.productName} (${value.wareHouse})`} style={{ color: '#000' }} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              )}
            </TableCellHead>

            <TableCellHead variant='head' className={classes.cellTable}>
              <Select
                id='demo-mutiple-checkbox'
                margin='dense'
                multiple
                variant='outlined'
                placeholder='Nama Supplier'
                fullWidth
                value={partner}
                onChange={e => {
                  const tempPartners = e.target.value as number[];
                  if (!tempPartners[tempPartners.length - 1]) return;
                  setPartner(tempPartners.filter(value => value) as number[]);
                }}
                renderValue={selected =>
                  `${partners
                    .filter(value => (selected as number[]).includes(value.id as number))
                    .map(value => value.name)
                    .join(', ')
                    .substr(0, 8)}...`
                }
              >
                {partners.map(value => (
                  <MenuItem key={value.id} value={value.id}>
                    <Checkbox color='primary' checked={partner.indexOf(value.id) > -1} />
                    <ListItemText primary={value.name} />
                  </MenuItem>
                ))}
                <Button variant='text' fullWidth color='primary' onClick={handlePartnerLoadMore} disabled={isLoadingPartners}>
                  {isLoadingPartners ? 'Loading...' : 'Load More'}
                </Button>
              </Select>
            </TableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow
                index={index}
                isLoading={isLoadingData}
                allStock={dummyStokAllItem}
                handleCloseOption={handleCloseOption}
                handleOpenOption={handleOpenOption}
                stockId={stockId}
                isChecker={isChecker}
                handleStockSales={handleStockSales}
                handleStockDamaged={handleStockDamaged}
                setStockId={setStockId}
                openOptions={openOptions}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                handleOpenCollapse={handleOpenCollapse(index, index)}
                loadingItem={loadingItem}
                anchorEl={anchorEl}
              />
            ))
          ) : allStocks && allStocks.length > 0 ? (
            allStocks.map((value, index) => (
              <BodyRow
                index={index}
                isLoading={isLoadingData}
                allStock={value}
                stockId={stockId}
                setStockId={setStockId}
                isChecker={isChecker}
                handleStockSales={handleStockSales}
                handleStockDamaged={handleStockDamaged}
                handleCloseOption={handleCloseOption}
                handleOpenOption={handleOpenOption}
                openOptions={openOptions}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                handleOpenCollapse={handleOpenCollapse(index, value.id)}
                loadingItem={loadingItem}
                anchorEl={anchorEl}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align='center'>
                Data Tidak Tersedia.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default AllStockTable;
