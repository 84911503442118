import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import React, { FC } from 'react';
import { CreateOutlined, ExpandLessOutlined, ExpandMore, HighlightOff, WarningRounded } from '@material-ui/icons';
import { YELLOW_MOBILE } from 'constants/colors';
import useRouter from 'hooks/useRouter';

interface Props {
  open: boolean;
  message: string;
  textOkButton: string;
  handleClick: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    fontWeight: 500,
    fontSize: '1.2rem'
  },
  button: {
    borderRadius: '15px',
    padding: '7px 0'
  }
}));
const ModalBack: FC<Props> = ({ open, setOpen, message, textOkButton, handleClick }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} style={{ maxHeight: 80 + 'vh', marginTop: '60px' }} scroll='paper' fullWidth>
      <DialogTitle>
        <Grid item container direction='column' alignItems='center' xs={12}>
          <WarningRounded style={{ color: YELLOW_MOBILE, fontSize: '3.5rem' }} />
          <Typography className={classes.titleContainer}>Informasi Penting</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid xs={12}>
          <Typography align='center'>{message}</Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid xs={12} container justify='center'>
          <Grid xs={11} container direction='column' style={{ gap: '0.6rem' }}>
            <Button size='small' fullWidth className={classes.button} color='primary' onClick={handleClick}>
              {textOkButton}
            </Button>
            <Button size='small' fullWidth variant='text' color='primary' onClick={() => setOpen(false)}>
              Batalkan
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModalBack;
