import { Grid, InputAdornment, LinearProgress, ListItem, makeStyles, TextField, Typography } from '@material-ui/core';
import { CloseRounded, SearchRounded } from '@material-ui/icons';
import clsx from 'clsx';
import FlexBox from 'components/FlexBox';
import { GRAY_3, GREY, WHITE } from 'constants/colors';
import React, { useState } from 'react';
import { dummyFilters, TData, TFilters } from 'pages/StockMovementPage';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles({
  productWrapper: {
    position: 'relative'
  },
  containerBox: {
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    borderRadius: '5px',
    marginTop: '0.5em'
  },
  absolute: {
    position: 'absolute',
    zIndex: 1,
    top: 50,
    width: '100vw'
  },
  scrollY: {
    maxHeight: '50vh',
    overflowY: 'scroll'
  },
  searchIcon: {
    padding: '.1em',
    fontSize: '1.7rem',
    color: '#323232',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: GREY,
      borderRadius: '50%'
    }
  },
  colorGrey: {
    color: GRAY_3
  },
  wrapperInput: {
    backgroundColor: WHITE
  }
});
/* Defining the props that the component will receive. */
interface Props {
  filters: TFilters;
  productSuggests: { productName: string, wareHouse: string }[];
  productCategories: TData<CategoryModel[]>;
  handleChangeFilters: <T>(key: keyof typeof dummyFilters, value: T) => void;
  handleClickSuggest: (productName: string) => void;
  handleClearKeyWord: () => void;
  handleSearchPress: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

/* A function that returns a component. */
const StockMovementFilters = ({
  filters,
  productSuggests,
  productCategories,
  handleChangeFilters,
  handleClickSuggest,
  handleClearKeyWord,
  handleSearchPress
}: Props) => {
  const classes = useStyles();
  /* A state that is used to show or hide the suggest list. */
  const [isShowSuggest, setisShowSuggest] = useState<boolean>(false);

  return (
    <FlexBox xs={12} container columnGap={1}>
      <Grid xs={6} className={classes.productWrapper}>
        <TextField
          placeholder='Cari Nama Produk'
          id='keyWord'
          value={filters.keyWord}
          onFocus={() => setisShowSuggest(() => true)}
          onBlur={() =>
            setTimeout(() => {
              setisShowSuggest(() => false);
            }, 500)
          }
          autoComplete='off'
          fullWidth
          onKeyDown={handleSearchPress}
          onChange={event => handleChangeFilters('keyWord', event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchRounded className={classes.colorGrey} fontSize='small' />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                {filters.keyWord.length > 0 && <CloseRounded className={classes.searchIcon} fontSize='small' onClick={handleClearKeyWord} />}
              </InputAdornment>
            )
          }}
        />
        {isShowSuggest && productSuggests.length > 0 && (
          <Grid className={clsx(classes.containerBox, classes.absolute, classes.scrollY)} xs={12}>
            {productSuggests.map(productSugest => (
              <ListItem button onClick={() => handleClickSuggest(productSugest.productName)}>
                <FlexBox container columnGap={1} alignItems='center'>
                  <SearchRounded fontSize='default' />
                  <Typography>{productSugest.productName} ({productSugest.wareHouse})</Typography>
                </FlexBox>
              </ListItem>
            ))}
          </Grid>
        )}
      </Grid>
      <Grid xs={3}>
        <Autocomplete
          loading={productCategories.isLoading}
          loadingText={<LinearProgress />}
          disableListWrap
          options={productCategories.data}
          getOptionLabel={option => option.name}
          getOptionSelected={(option, value) => {
            return value.id === option.id;
          }}
          onChange={(event, value, reason) => {
            if (reason === 'clear') {
              handleChangeFilters('categoryId', 0);
            }
            if (value) {
              handleChangeFilters('categoryId', value.id);
            }
          }}
          PaperComponent={({ children }) => <Grid className={classes.containerBox}>{children}</Grid>}
          renderInput={params => <TextField {...params} label='Filter berdasarkan kategori' variant='outlined' fullWidth />}
        />
      </Grid>
    </FlexBox>
  );
};

/* Exporting the component. */
export default StockMovementFilters;
