import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom } from 'components';
import { ORANGE, RED } from 'constants/colors';
import Skeleton from '@material-ui/lab/Skeleton';
import clx from 'clsx';
import NumberFormat from 'react-number-format';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

interface Props {
  item: StockCheckItemModel;
  isLoading: boolean;
  index: number;
  isUpdatePrice: boolean;
  isFirstStock: boolean;
}

const useStyles = makeStyles({
  danger: {
    color: RED
  },
  warning: {
    color: ORANGE
  },
  ok: {
    color: '#27AE60'
  },
  bgInfo: {
    backgroundColor: '#FFFFE0',
    fontWeight: 'bold'
  }
});

const BodyRow: FC<Props> = props => {
  const classes = useStyles();

  const { item, isLoading, isUpdatePrice, isFirstStock } = props;

  const handleDiff = () => {
    let diff = item.adjustItem - item.currentItem;

    return item.adjustItem !== item.currentItem ? (
      <span className={clx(item.adjustItem > item.currentItem && classes.warning, item.adjustItem < item.currentItem && classes.danger)}>
        ({item.adjustItem > item.currentItem ? '+' : ''} {diff})
      </span>
    ) : (
      ''
    );
  };

  let firstStock = 0;

  if (isFirstStock) {
    firstStock = item.adjustItem - item.firstStock;
  }

  return (
    <TableRowCustom>
      <TableCellStart align='left'>{isLoading ? <Skeleton variant='text' width={100} height={25} /> : item.productName || '-'}</TableCellStart>

      <TableCellMiddle align='center'>{isLoading ? <Skeleton variant='text' width={100} height={25} /> : item.typeUnit || '-'}</TableCellMiddle>

      <TableCellMiddle align='center'>
        {isLoading ? <Skeleton variant='text' width={100} height={25} /> : item.firstStock || 0}
        {isFirstStock && !isLoading && <ArrowForwardIcon fontSize='small' style={{ verticalAlign: 'middle' }} />}
        {isFirstStock && !isLoading && item.adjustItem}
        {isFirstStock && !isLoading && (
          <span className={clx(item.adjustItem > item.firstStock && classes.warning, item.adjustItem < item.firstStock && classes.danger)}>
            {`(${item.adjustItem > item.firstStock ? '+' : ''}${firstStock})`}
          </span>
        )}
      </TableCellMiddle>

      <TableCellMiddle align='center'>
        {isLoading ? <Skeleton variant='text' width={100} height={25} /> : item.currentItem || 0}
        {!isLoading && <ArrowForwardIcon fontSize='small' style={{ verticalAlign: 'middle' }} />}

        {isFirstStock && !isLoading && item.currentItem + firstStock}
        {!isFirstStock && !isLoading && item.adjustItem}

        {isFirstStock && !isLoading && (
          <span className={clx(firstStock > 0 && classes.warning, firstStock < 0 && classes.danger)}>
            {`(${firstStock > 0 ? '+' : ''}${firstStock})`}
          </span>
        )}

        {!isFirstStock && !isLoading && handleDiff()}
      </TableCellMiddle>

      <TableCellMiddle align='center'>
        {isLoading ? (
          <Skeleton variant='text' width={120} height={25} />
        ) : (
          <>
            <NumberFormat value={item.purchasePrice || 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
            {isUpdatePrice && (
              <>
                <ArrowForwardIcon fontSize='small' style={{ verticalAlign: 'middle' }} />
                <NumberFormat
                  value={(item.adjustPrice && item.adjustPrice) || 0}
                  prefix={'Rp'}
                  thousandSeparator={true}
                  displayType='text'
                  className={clx(
                    item.adjustPrice && item.adjustPrice > item.purchasePrice && classes.warning,
                    item.adjustPrice && item.adjustPrice < item.purchasePrice && classes.danger
                  )}
                />
              </>
            )}
          </>
        )}
      </TableCellMiddle>

      <TableCellEnd align='center'>{isLoading ? <Skeleton variant='text' width={120} height={25} /> : item.adjustItem}</TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRow;
