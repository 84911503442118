import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { PaperCustom } from 'components';
import StockInfo from './components/StockInfo';

interface Props {
  isLoadingData: boolean;
  stock: StockModel;
}

const Content: FC<Props> = props => {
  const { stock, isLoadingData } = props;

  return (
    <PaperCustom>
      <Grid container direction='row' spacing={2} item lg={12} sm={12} md={12} xs={12}>
        <StockInfo stock={stock} isLoadingData={isLoadingData} />
      </Grid>
    </PaperCustom>
  );
};

export default Content;
