import React, { FC, useReducer, useState, useEffect } from 'react';
import {
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Divider,
  TableContainer,
  makeStyles,
  Theme,
  Grid,
  CardMedia,
  Typography,
  Modal
} from '@material-ui/core';
import BodyRow from './components/BodyRow';
import { BLACK, RED, WHITE, GREEN, BLUE_PRIMARY, ORANGE_MOBILE } from 'constants/colors';
import { TableCustom } from 'components/Table';
import BodyRowMobile from './components/BodyRowMobile';
import HeaderRow from './components/HeaderRow';
import TypeUser from 'typings/enum/TypeUser';
import Loading from 'components/Loading';

interface Props {
  isLoadingData: boolean;
  salesOrder: SalesOrderModel;
  salesOrderItem: SalesOrderItemModel[];
  hasInvoice: boolean;
  currentWareHouse: string;
  indexTooltip: number;
  rejectOrder: ItemAction[];
  handleRejectSuperAdmin: (value: ItemAction) => void;
  handleCancelRejectSuperAdmin: (value: ItemAction) => void;
  isAccess: string;
  handleOpenImage: (imagePath: string) => void;
  handletRequestEdit: (id: number, productId: number, totalQty: number, remark: string, remarkCode: string) => void;
  openTooltip: boolean;
  showComponent: boolean;
  setGetIdClick: React.Dispatch<React.SetStateAction<number>>;
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
  tempItemColly: (data: typeColly) => void;
  deleteColly: (id: number) => void;
  loadingReject: boolean;
  setHasOpenModal: React.Dispatch<React.SetStateAction<number>>;
  handleConfirm: (index: number) => React.MouseEventHandler;
  handleReject: (index: number) => React.MouseEventHandler;
  handleAcceptItem: (id: number) => void;
  collyFinal: typeCollyFinal;
  handleRejectItem: (id: number) => void;
  handleOpenTooltip: (index: number) => React.MouseEventHandler;
  handleCloseTooltip: () => void;
  openAlertPrice: boolean;
  isCan?: boolean;
  indexAlertPrice: number;
  handleOpenAlertPrice: (index: number) => React.MouseEventHandler;
  handleCloseAlertPrice: () => void;
  addItemAction: (value: ItemAction) => void;
  openAlertDiscount: boolean;
  indexAlertDiscount: number;
  handleOpenAlertDiscount: (index: number) => React.MouseEventHandler;
  handleCloseAlertDiscount: () => void;
  tab: number;
  isConvertAllowed: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  containerHeader: {
    margin: theme.spacing(2),
    display: 'flex'
  },
  itemContainer: {
    border: '0.6px solid rgba(0, 0, 0, 0.08)',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: '6px'
  },
  titleHeader: {
    fontSize: '1rem',
    lineHeight: '1.7rem'
  },
  titleHeaderBold: {
    fontWeight: 500
  },
  contentMobile: {
    paddingRight: theme.spacing(2),
    width: '90vw',
    overflow: 'hidden'
  },

  wrapperLoading: {
    minHeight: '30vh'
  }
}));

const ListProduct: FC<Props> = props => {
  const {
    isLoadingData,
    salesOrderItem,
    tab,
    hasInvoice,
    collyFinal,
    handleRejectItem,
    rejectOrder,
    setGetIdClick,
    isAccess,
    setOpenEdit,
    setHasOpenModal,
    handleCancelRejectSuperAdmin,
    handleOpenImage,
    handleRejectSuperAdmin,
    tempItemColly,
    deleteColly,
    isConvertAllowed,
    showComponent,
    handleAcceptItem,
    handleConfirm,
    addItemAction,
    handleReject,
    isCan,
    salesOrder,
    handletRequestEdit,
    currentWareHouse,
    indexTooltip,
    openTooltip,
    handleOpenTooltip,
    handleCloseTooltip,
    openAlertPrice,
    indexAlertPrice,
    handleOpenAlertPrice,
    handleCloseAlertPrice,
    openAlertDiscount,
    indexAlertDiscount,
    handleOpenAlertDiscount,
    handleCloseAlertDiscount,
    loadingReject
  } = props;
  const classes = useStyles();
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);

  const handleOpenCollapse = (index: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    setOpenCollapse(openCollapse ? (index === indexCollapse ? false : true) : true);
  };

  return (
    <>
      {!showComponent && (
        <div>
          {isAccess === TypeUser.SALES || isAccess === TypeUser.SUPERVISOR || isAccess === TypeUser.ADMIN || isAccess === TypeUser.ACCOUNTING ? (
            <TableContainer>
              <TableCustom size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>Nama Produk</TableCell>
                    <TableCell>Harga</TableCell>
                    <TableCell>Kuantitas</TableCell>
                    <TableCell>Gudang</TableCell>
                    <TableCell>Diskon</TableCell>
                    <TableCell>Total Harga</TableCell>
                    <TableCell align='center'>Aksi</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {salesOrderItem.length > 0 &&
                    salesOrderItem.map((value, index) => (
                      <BodyRow
                        key={index}
                        tab={tab}
                        isLoading={isLoadingData}
                        tempItemColly={tempItemColly}
                        collyFinal={collyFinal}
                        deleteColly={deleteColly}
                        handletRequestEdit={handletRequestEdit}
                        setGetIdClick={setGetIdClick}
                        handleRejectItem={handleRejectItem}
                        loadingReject={loadingReject}
                        handleRejectSuperAdmin={handleRejectSuperAdmin}
                        handleCancelRejectSuperAdmin={handleCancelRejectSuperAdmin}
                        handleOpenImage={handleOpenImage}
                        isAccess={isAccess}
                        rejectOrder={rejectOrder}
                        salesOrderItem={value}
                        addItemAction={addItemAction}
                        setHasOpenModal={setHasOpenModal}
                        setOpenEdit={setOpenEdit}
                        hasInvoice={hasInvoice}
                        handleReject={handleReject(value.id)}
                        handleConfirm={handleConfirm(value.id)}
                        handleAcceptItem={handleAcceptItem}
                        indexCollapse={indexCollapse}
                        openCollapse={openCollapse}
                        handleOpenCollapse={handleOpenCollapse(index)}
                        index={index}
                        isConvertAllowed={isConvertAllowed}
                        salesOrder={salesOrder}
                        currentWareHouse={currentWareHouse}
                        openTooltip={openTooltip}
                        indexTooltip={indexTooltip}
                        handleOpenTooltip={handleOpenTooltip(index)}
                        handleCloseTooltip={handleCloseTooltip}
                        openAlertPrice={openAlertPrice}
                        indexAlertPrice={indexAlertPrice}
                        handleOpenAlertPrice={handleOpenAlertPrice(index)}
                        handleCloseAlertPrice={handleCloseAlertPrice}
                        openAlertDiscount={openAlertDiscount}
                        indexAlertDiscount={indexAlertDiscount}
                        handleOpenAlertDiscount={handleOpenAlertDiscount(index)}
                        handleCloseAlertDiscount={handleCloseAlertDiscount}
                      />
                    ))}
                </TableBody>
              </TableCustom>
            </TableContainer>
          ) : (
            <Grid xl={12} lg={12} container className={classes.containerHeader}>
              {isAccess === TypeUser.SUPERADMIN && (
                <>
                  <HeaderRow title='nama produk' isFlex='0.33' />
                  <HeaderRow title='harga' isFlex='0.1' />
                  <HeaderRow title='qty diambil' isFlex='0.15' />
                  <HeaderRow title='diskon' isFlex='0.14' />
                  <HeaderRow title='total harga' isFlex='0.16' />
                  <HeaderRow title={'gudang'} isVisibility={true} isFlex='0.12' />
                  <HeaderRow title={'aksi'} isVisibility={true} isFlex='0.12' />
                </>
              )}

              {isAccess === TypeUser.ADMIN03 && (
                <>
                  <HeaderRow title='nama produk' isFlex='0.33' />
                  <HeaderRow title='harga' isFlex='0.1' />
                  <HeaderRow title='qty diambil' isFlex='0.15' />
                  <HeaderRow title='diskon' isFlex='0.14' />
                  <HeaderRow title='total harga' isFlex='0.16' />
                  <HeaderRow title={'gudang'} isVisibility={true} isFlex='0.12' />
                </>
              )}

              {isAccess === TypeUser.PICKER && (
                <>
                  <HeaderRow title='nama produk' isFlex='0.45' />
                  <HeaderRow title='Qty request' isFlex='0.23' />
                  <HeaderRow title='Gudang' isFlex='0.23' isVisibility={true} />
                  <HeaderRow title='aksi' isFlex='0.15' isVisibility={true} />
                </>
              )}

              {isAccess === TypeUser.ADMIN01 && (
                <>
                  <HeaderRow title='nama produk' isFlex='0.45' />
                  <HeaderRow title='Qty request' isFlex='0.23' />
                  <HeaderRow title='Gudang' isFlex='0.23' isVisibility={true} />
                  <HeaderRow title='aksi' isFlex='0.15' isVisibility={true} />
                </>
              )}

              {(isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS) && (
                <>
                  <HeaderRow title='nama produk' isFlex='0.45' />
                  <HeaderRow title='Qty request' isFlex='0.23' />
                  <HeaderRow title='Gudang' isFlex='0.23' isVisibility={true} />
                  <HeaderRow title='aksi' isFlex='0.15' isVisibility={true} />
                </>
              )}
              {salesOrderItem.length > 0 &&
                salesOrderItem.map((value, index) => (
                  <BodyRow
                    key={index}
                    tab={tab}
                    isLoading={isLoadingData}
                    handletRequestEdit={handletRequestEdit}
                    rejectOrder={rejectOrder}
                    collyFinal={collyFinal}
                    handleOpenImage={handleOpenImage}
                    tempItemColly={tempItemColly}
                    deleteColly={deleteColly}
                    loadingReject={loadingReject}
                    handleRejectSuperAdmin={handleRejectSuperAdmin}
                    isAccess={isAccess}
                    salesOrderItem={value}
                    handleAcceptItem={handleAcceptItem}
                    setGetIdClick={setGetIdClick}
                    setHasOpenModal={setHasOpenModal}
                    addItemAction={addItemAction}
                    setOpenEdit={setOpenEdit}
                    handleCancelRejectSuperAdmin={handleCancelRejectSuperAdmin}
                    hasInvoice={hasInvoice}
                    handleReject={handleReject(value.id)}
                    handleConfirm={handleConfirm(value.id)}
                    handleRejectItem={handleRejectItem}
                    indexCollapse={indexCollapse}
                    openCollapse={openCollapse}
                    handleOpenCollapse={handleOpenCollapse(index)}
                    index={index}
                    isConvertAllowed={isConvertAllowed}
                    salesOrder={salesOrder}
                    currentWareHouse={currentWareHouse}
                    openTooltip={openTooltip}
                    indexTooltip={indexTooltip}
                    handleOpenTooltip={handleOpenTooltip(index)}
                    handleCloseTooltip={handleCloseTooltip}
                    openAlertPrice={openAlertPrice}
                    indexAlertPrice={indexAlertPrice}
                    handleOpenAlertPrice={handleOpenAlertPrice(index)}
                    handleCloseAlertPrice={handleCloseAlertPrice}
                    openAlertDiscount={openAlertDiscount}
                    indexAlertDiscount={indexAlertDiscount}
                    handleOpenAlertDiscount={handleOpenAlertDiscount(index)}
                    handleCloseAlertDiscount={handleCloseAlertDiscount}
                  />
                ))}
            </Grid>
          )}
        </div>
      )}

      {showComponent && (
        <Grid xs={12} container justify='space-between'>
          {isLoadingData ? (
            <Grid xs={12} className={classes.wrapperLoading} container justify='center' alignItems='center'>
              <Grid container xs={12} direction='column' justify='center' alignItems='center'>
                <Loading size='small' />
                <Typography color='textSecondary'>Sedang membuka...</Typography>
              </Grid>
            </Grid>
          ) : (
            salesOrderItem.length > 0 &&
            salesOrderItem.map((value, index) => (
              <Grid
                className={isAccess !== TypeUser.ADMIN01 ? classes.contentMobile : ''}
                xs={isAccess !== TypeUser.ADMIN01 ? 12 : 6}
                direction='row'
                container
                style={{
                  alignItems: 'stretch'
                }}
              >
                <BodyRowMobile
                  key={index}
                  tab={tab}
                  isLoading={isLoadingData}
                  handletRequestEdit={handletRequestEdit}
                  rejectOrder={rejectOrder}
                  isCan={isCan || false}
                  collyFinal={collyFinal}
                  tempItemColly={tempItemColly}
                  deleteColly={deleteColly}
                  isAccess={isAccess}
                  salesOrderItem={value}
                  handleAcceptItem={handleAcceptItem}
                  handleOpenImage={handleOpenImage}
                  setGetIdClick={setGetIdClick}
                  setHasOpenModal={setHasOpenModal}
                  addItemAction={addItemAction}
                  setOpenEdit={setOpenEdit}
                  hasInvoice={hasInvoice}
                  handleReject={handleReject(value.id)}
                  handleConfirm={handleConfirm(value.id)}
                  handleRejectItem={handleRejectItem}
                  indexCollapse={indexCollapse}
                  openCollapse={openCollapse}
                  handleOpenCollapse={handleOpenCollapse(index)}
                  index={index}
                  isConvertAllowed={isConvertAllowed}
                  salesOrder={salesOrder}
                  currentWareHouse={currentWareHouse}
                  openTooltip={openTooltip}
                  indexTooltip={indexTooltip}
                  handleOpenTooltip={handleOpenTooltip(index)}
                  handleCloseTooltip={handleCloseTooltip}
                  openAlertPrice={openAlertPrice}
                  indexAlertPrice={indexAlertPrice}
                  handleOpenAlertPrice={handleOpenAlertPrice(index)}
                  handleCloseAlertPrice={handleCloseAlertPrice}
                  openAlertDiscount={openAlertDiscount}
                  indexAlertDiscount={indexAlertDiscount}
                  handleOpenAlertDiscount={handleOpenAlertDiscount(index)}
                  handleCloseAlertDiscount={handleCloseAlertDiscount}
                />
              </Grid>
            ))
          )}
        </Grid>
      )}
    </>
  );
};

export default ListProduct;
