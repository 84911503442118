import React, { FC, Fragment, useMemo } from 'react';
import {
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  TableContainer,
  makeStyles,
  MenuItem,
  Theme,
  Checkbox,
  Select,
  ListItemText
} from '@material-ui/core';
import { HeaderRow, TableCellHead, TableCustom } from 'components/Table';
import { dummySalesOrder } from 'utils/dummy';
import BodyRow from './components/BodyRow';
import TypeUser from 'typings/enum/TypeUser';

interface Props {
  isLoadingData: boolean;
  loadingItem: boolean;
  showComponent: boolean;
  salesOrders: SalesOrderModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  name: string;
  zones: ZoneModel[];
  setName: React.Dispatch<React.SetStateAction<string>>;
  salesName: string;
  handleOpenImage: (imagePath: string) => void;
  setSalesName: React.Dispatch<React.SetStateAction<string>>;
  statusOrder: string;
  openModalDiscount: (productName: string, totalQty: number, discount: number, totalDiscount: number) => void;
  zone: number[];
  handleOpenSO: (id: number) => void;
  setZone: React.Dispatch<React.SetStateAction<number[]>>;
  setStatusOrder: React.Dispatch<React.SetStateAction<string>>;
  salesOrderId: string;
  setSalesOrderId: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  handleConfirmationDelete: (id: number) => React.MouseEventHandler;
  indexCollapse: number;
  openCollapse: boolean;
  isAccess: string;
  handleOpenCollapse: (index: number, id: number) => React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 650
  },
  cellStartId: {
    width: '10%'
  },
  cellTable: {
    width: '17%'
  },
  cellTable3: {
    width: '13%'
  }
}));

const SalesOrderTable: FC<Props> = props => {
  const classes = useStyles();
  const {
    isLoadingData,
    salesOrders,
    order,
    orderBy,
    name,
    setName,
    isAccess,
    openModalDiscount,
    statusOrder,
    setStatusOrder,
    salesOrderId,
    setSalesOrderId,
    setOrder,
    setOrderBy,
    handleOpenSO,
    showComponent,
    handleOpenImage,
    handleConfirmationDelete,
    salesName,
    setSalesName,
    zone,
    setZone,
    indexCollapse,
    openCollapse,
    loadingItem,
    zones,
    handleOpenCollapse
  } = props;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          {isAccess === TypeUser.PICKER && (
            <HeaderRow
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={[
                { label: '#' },
                { id: 'salesOrderId', label: 'Nomor Order', sort: true },
                { id: 'name', label: 'NAMA CUSTOMER', sort: true },
                { id: 'statusOrder', label: 'STATUS', sort: true, align: 'center' },
                { id: 'orderDate', label: 'TGL ORDER', sort: true },
                { id: 'stock', label: 'STOCK DIAMBIL', sort: true }
              ]}
            />
          )}
          {(isAccess === TypeUser.SUPERADMIN || isAccess === TypeUser.SUPERVISOR) && (
            <HeaderRow
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={[
                { label: '#' },
                { id: 'salesOrderId', label: 'Nomor Order', sort: true },
                { id: 'name', label: 'NAMA CUSTOMER', sort: true },
                { id: 'statusOrder', label: 'STATUS', sort: true, align: 'center' },
                { id: 'zone', label: 'RUTE', sort: true, align: 'center' },
                { id: 'nameSales', label: 'NAMA SALES', sort: true },
                { id: 'orderDate', label: 'TGL ORDER', sort: true },
                { id: 'totalAmount', label: 'TOTAL HARGA', sort: true }
              ]}
            />
          )}

          {(isAccess === TypeUser.SALES || isAccess === TypeUser.ADMIN || isAccess === TypeUser.ACCOUNTING) && (
            <HeaderRow
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={[
                { label: '#' },
                { id: 'salesOrderId', label: 'Nomor Order', sort: true },
                { id: 'name', label: 'NAMA CUSTOMER', sort: true },
                { id: 'statusOrder', label: 'STATUS', sort: true, align: 'center' },
                { id: 'zone', label: 'RUTE', sort: true, align: 'center' },
                { id: 'nameSales', label: 'NAMA SALES', sort: true },
                { id: 'orderDate', label: 'TGL ORDER', sort: true },
                { id: 'totalAmount', label: 'TOTAL HARGA', sort: true },
                { label: '' }
              ]}
            />
          )}

          {isAccess === TypeUser.ADMIN01 && (
            <HeaderRow
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={[
                { label: '#' },
                { id: 'salesOrderId', label: 'Nomor Order', sort: true },
                { id: 'name', label: 'NAMA CUSTOMER', sort: true },
                { id: 'statusOrder', label: 'STATUS', sort: true, align: 'center' },
                { id: 'orderDate', label: 'TGL ORDER', sort: true },
                { id: 'stock', label: 'STOCK DIAMBIL', sort: true }
              ]}
            />
          )}

          {(isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS) && (
            <HeaderRow
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={[
                { label: '#' },
                { id: 'salesOrderId', label: 'Nomor Order', sort: true },
                { id: 'name', label: 'NAMA CUSTOMER', sort: true },
                { id: 'statusOrder', label: 'STATUS', sort: true, align: 'center' },
                { id: 'orderDate', label: 'TGL ORDER', sort: true },
                { id: 'stock', label: 'STOCK DIAMBIL', sort: true }
              ]}
            />
          )}

          {isAccess === TypeUser.ADMIN03 && (
            <HeaderRow
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={[
                { label: '#' },
                { id: 'salesOrderId', label: 'Nomor Order', sort: true },
                { id: 'name', label: 'NAMA CUSTOMER', sort: true },
                { id: 'statusOrder', label: 'STATUS', sort: true, align: 'center' },
                { id: 'orderDate', label: 'TGL ORDER', sort: true },
                { id: 'discount', label: 'DISKON', sort: true },
                { id: 'totalAmount', label: 'TOTAL HARGA', sort: true }
              ]}
            />
          )}

          <TableRow>
            <TableCellHead variant='head' className={classes.cellStartId} colSpan={2}>
              <TextField
                id='salesOrderId'
                placeholder='No Order'
                autoComplete='off'
                value={salesOrderId}
                onChange={event => setSalesOrderId(event.target.value)}
              />
            </TableCellHead>
            <TableCellHead variant='head' className={classes.cellTable}>
              <TextField id='name' placeholder='Nama' value={name} onChange={event => setName(event.target.value)} />
            </TableCellHead>
            <TableCellHead align='center' variant='head' className={classes.cellTable3}>
              <TextField
                select
                id='statusOrder'
                value={statusOrder}
                autoComplete='off'
                onChange={event => setStatusOrder(event.target.value as string)}
              >
                <MenuItem key={1} value='' selected>
                  Pilih Status
                </MenuItem>
                <MenuItem key={2} value='PENDING'>
                  Tertunda
                </MenuItem>
                <MenuItem key={3} value='CONFIRMATION'>
                  Konfirmasi
                </MenuItem>
                <MenuItem key={4} value='CANCEL'>
                  Batal
                </MenuItem>
              </TextField>
            </TableCellHead>

            {(isAccess === TypeUser.SUPERADMIN || isAccess === TypeUser.SUPERVISOR) && (
              <Fragment>
                <TableCellHead variant='head' className={classes.cellTable}>
                  <Select
                    id='demo-mutiple-checkbox'
                    margin='dense'
                    multiple
                    variant='outlined'
                    fullWidth
                    value={zone}
                    onChange={e => setZone(e.target.value as number[])}
                    renderValue={selected =>
                      `${zones
                        .filter(value => (selected as number[]).includes(value.id as number))
                        .map(value => value.name)
                        .join(',')
                        .substr(0, 8)}...`
                    }
                  >
                    {zones.map(value => (
                      <MenuItem key={value.id} value={value.id}>
                        <Checkbox color='primary' checked={zone.indexOf(value.id) > -1} />
                        <ListItemText primary={value.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </TableCellHead>

                <TableCellHead variant='head' className={classes.cellTable}>
                  <TextField id='salesName' placeholder='Sales' value={salesName} onChange={event => setSalesName(event.target.value)} />
                </TableCellHead>
              </Fragment>
            )}

            {(isAccess === TypeUser.SALES || isAccess === TypeUser.ADMIN) && (
              <Fragment>
                <TableCellHead variant='head' className={classes.cellTable}>
                  <Select
                    id='demo-mutiple-checkbox'
                    margin='dense'
                    multiple
                    variant='outlined'
                    fullWidth
                    value={zone}
                    onChange={e => setZone(e.target.value as number[])}
                    renderValue={selected =>
                      `${zones
                        .filter(value => (selected as number[]).includes(value.id as number))
                        .map(value => value.name)
                        .join(',')
                        .substr(0, 8)}...`
                    }
                  >
                    {zones.map(value => (
                      <MenuItem key={value.id} value={value.id}>
                        <Checkbox color='primary' checked={zone.indexOf(value.id) > -1} />
                        <ListItemText primary={value.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </TableCellHead>

                <TableCellHead variant='head' className={classes.cellTable}>
                  <TextField id='salesName' placeholder='Sales' value={salesName} onChange={event => setSalesName(event.target.value)} />
                </TableCellHead>
              </Fragment>
            )}
            {isAccess === TypeUser.PICKER && (
              <Fragment>
                <TableCellHead variant='head' className={classes.cellTable}></TableCellHead>
                <TableCellHead variant='head' className={classes.cellTable}>
                  <TextField id='Stock' placeholder='Stock' />
                </TableCellHead>
              </Fragment>
            )}

            {isAccess === TypeUser.ADMIN01 && (
              <Fragment>
                <TableCellHead variant='head' className={classes.cellTable}></TableCellHead>
                <TableCellHead variant='head' className={classes.cellTable}>
                  <TextField id='Stock' placeholder='Stock' />
                </TableCellHead>
              </Fragment>
            )}

            {(isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS) && (
              <Fragment>
                <TableCellHead variant='head' className={classes.cellTable}></TableCellHead>
                <TableCellHead variant='head' className={classes.cellTable}>
                  <TextField id='Stock' placeholder='Stock' />
                </TableCellHead>
              </Fragment>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingData
            ? [0, 1, 2, 3].map(index => (
                <BodyRow
                  key={index}
                  isAccess={isAccess}
                  isLoading={isLoadingData}
                  salesOrder={dummySalesOrder}
                  onDelete={handleConfirmationDelete(0)}
                  openModalDiscount={openModalDiscount}
                  handleOpenSO={() => console.log('')}
                  index={index}
                  indexCollapse={indexCollapse}
                  handleOpenImage={handleOpenImage}
                  openCollapse={openCollapse}
                  handleOpenCollapse={handleOpenCollapse(index, 0)}
                  loadingItem={loadingItem}
                />
              ))
            : salesOrders && salesOrders.length > 0
            ? salesOrders.map((value, index) => (
                <BodyRow
                  key={index}
                  isAccess={isAccess}
                  isLoading={isLoadingData}
                  salesOrder={value}
                  onDelete={handleConfirmationDelete(value.id)}
                  index={index}
                  handleOpenSO={handleOpenSO}
                  handleOpenImage={handleOpenImage}
                  openModalDiscount={openModalDiscount}
                  indexCollapse={indexCollapse}
                  openCollapse={openCollapse}
                  handleOpenCollapse={handleOpenCollapse(index, value.id)}
                  loadingItem={loadingItem}
                />
              ))
            : isLoadingData && (
                <TableRow>
                  <TableCell colSpan={9} align='center'>
                    Data Tidak Tersedia.
                  </TableCell>
                </TableRow>
              )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default SalesOrderTable;
