import React, { FC, useState } from 'react';
import { Dialog, DialogContent, Button, DialogActions,  CircularProgress } from '@material-ui/core';
import StockWizard from './components/StockWizard';
import { DialogTitle } from 'components/Dialog';

interface Props {
  mutation: MutationModel;
  isUpdate: boolean;
  open: boolean;
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMutations: React.Dispatch<React.SetStateAction<MutationModel[]>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const TransferStockModal: FC<Props> = props => {
  const { open, mutation, isUpdate, setUpdate, setOpen, setMutations, handleSnackBar } = props;
  const [isSubmit, setSubmit] = useState<boolean>(false);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOnSubmit = () => {
    setSubmit(true);
  };

  return (
    <Dialog maxWidth={'md'} disableBackdropClick={true} open={open}>
      <DialogTitle>Mutasi Stok Barang</DialogTitle>
      <DialogContent>
        <StockWizard
          isSubmit={isSubmit}
          setSubmit={setSubmit}
          mutation={mutation}
          isUpdate={isUpdate}
          setUpdate={setUpdate}
          setOpen={setOpen}
          handleSnackBar={handleSnackBar}
          setMutations={setMutations}
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={isSubmit} color='secondary' onClick={handleCancel}>
          Batal
        </Button>
        <Button disabled={isSubmit} onClick={handleOnSubmit}>
          {isSubmit ? <CircularProgress color='inherit' size={20} /> : 'Simpan'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferStockModal;
