import React, { FC, Fragment, useContext } from 'react';
import { makeStyles, Table, TableBody, TableRow, withStyles, Typography, Divider, TableContainer } from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import NumberFormat from 'react-number-format';
import Skeleton from '@material-ui/lab/Skeleton';
import useRole from 'hooks/useRole';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import TypeUser from 'typings/enum/TypeUser';
import { YELLOW_MOBILE } from 'constants/colors';
interface Props {
  product: ProductModel;
  isLoading: boolean;
  isSales: boolean;
}

const useStyles = makeStyles({
  cell: {
    width: '50%',
    paddingLeft: 0
  },
  label: {
    color: '#828282',
    fontWeight: 400,
    fontSize: 15,
    marginBottom: 4
  },
  text: {
    fontWeight: 700,
    color: '#000000',
    fontSize: 15
  },
  price: {
    fontWeight: 700,
    color: '#F2994A',
    fontSize: 15
  },
  tableDetail: {
    marginTop: 10
  },
  titleDetail: {
    marginTop: 20
  },
  priceTable: {
    maxHeight: 400
  }
});

const TableCell = withStyles({
  root: {
    borderBottom: 'none'
  }
})(MuiTableCell);

const ProductDetail: FC<Props> = props => {
  const { currentUser } = useContext(CurrentUserContext);
  const classes = useStyles();
  const { product, isLoading, isSales } = props;

  const isSuperVisor = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: [TypeUser.SUPERVISOR]
  });

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: [TypeUser.SUPERADMIN]
  });

  return (
    <Fragment>
      <Table size='small'>
        <TableBody>
          <TableRow>
            <TableCell size='small' className={classes.cell}>
              <Typography component='p' className={classes.label}>
                Nama Produk
              </Typography>
              <Typography component='p' className={classes.text}>
                {isLoading ? <Skeleton variant='text' width='100%' /> : product.productName}
              </Typography>
            </TableCell>
            <TableCell size='small'>
              <Typography component='p' className={classes.label}>
                Satuan
              </Typography>
              <Typography component='p' className={classes.text}>
                {isLoading ? <Skeleton variant='text' width='100%' /> : product.typeUnit}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell size='small' className={classes.cell}>
              <Typography component='p' className={classes.label}>
                Kode Produk
              </Typography>
              <Typography component='p' className={classes.text}>
                {isLoading ? <Skeleton variant='text' width='100%' /> : product.productCode}
              </Typography>
            </TableCell>
            <TableCell size='small'>
              {isSuperAdmin && (
                <>
                  <Typography component='p' className={classes.label}>
                    Harga Beli
                  </Typography>
                  <Typography component='p' className={classes.text}>
                    {isLoading ? (
                      <Skeleton variant='text' width='100%' />
                    ) : (
                      <NumberFormat value={product.purchasePrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    )}
                  </Typography>
                </>
              )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell size='small' className={classes.cell}>
              <Typography component='p' className={classes.label}>
                Kategori Produk
              </Typography>
              <Typography component='p' className={classes.text}>
                {product.Category ? product.Category.name : '-'}
              </Typography>
            </TableCell>
            <TableCell size='small'>
              <Typography component='p' className={classes.label}>
                Deskripsi
              </Typography>
              {isLoading ? <Skeleton variant='text' width='100%' /> : <div dangerouslySetInnerHTML={{ __html: product.description }}></div>}
            </TableCell>
          </TableRow>
          {!isSales && (
            <TableRow>
              <TableCell size='small' className={classes.cell}>
                <Typography component='p' className={classes.label}>
                  Gudang
                </Typography>
              </TableCell>
              <TableCell size='small'>
                <Typography component='p' className={classes.text}>
                  {isLoading ? <Skeleton variant='text' width='100%' /> : `${product.warehouse || ''}`}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Divider />

      <Typography component='p' variant='h5' className={classes.titleDetail}>
        Keterangan Stok
      </Typography>

      <Table size='small'>
        <TableBody>
          <TableRow>
            <TableCell>
              {(isSales || isSuperVisor) && (
                <p>
                  <b>Total Stok Jual</b> <span style={{ float: 'right' }}> ({(product.netStock || 0) - (product.holdItem || 0)})</span>
                </p>
              )}
              {!isSales && !isSuperVisor && (
                <>
                  <p style={{ borderBottom: '1px solid lightgrey' }}>
                    <b>Total Stok Bersih</b>{' '}
                    <span style={{ float: 'right' }}>
                      <b>{product.netStock || 0}</b>
                    </span>
                    <br />
                    {/* <code style={{ color: 'red' }}>
                      <small>Tanpa Barang Rusak dan Termasuk Stok Tertahan.</small>
                    </code> */}
                  </p>
                  <p>
                    - Total Stok Jual <span style={{ float: 'right' }}> ({(product.netStock || 0) - (product.holdItem || 0)})</span>
                  </p>
                  <p>
                    - Stok Tertahan <span style={{ float: 'right' }}>({product.holdItem || 0})</span>
                    <br />
                    {/* <code style={{ color: 'red' }}>
                      <small>Tidak masuk kedalam stok dijual.</small>
                    </code> */}
                  </p>
                  <p style={{ borderBottom: '1px solid lightgrey' }}>
                    <b>Total Stok Kotor</b>{' '}
                    <span style={{ float: 'right' }}>
                      {/* <b>{(product.holdItem || 0) + product.totalStock}</b> */}
                      <b>{product.totalStock}</b>
                    </span>
                  </p>
                  <p>
                    - Total Stok Jual <span style={{ float: 'right' }}>({(product.netStock || 0) - (product.holdItem || 0)})</span>
                  </p>

                  <p>
                    - Stok Tertahan <span style={{ float: 'right' }}>({product.holdItem || 0})</span>
                    <br />
                    {/* <code style={{ color: 'red' }}>
                      <small>Tidak masuk kedalam stok dijual.</small>
                    </code> */}
                  </p>

                  <p style={{ color: YELLOW_MOBILE }}>
                    - Barang Rusak Return Penjualan<span style={{ float: 'right' }}>({product.totalDamage || 0})</span>
                    <br />
                    {/* <code style={{ color: 'red' }}>
                      <small>Sudah masuk kedalam total stok kotor.</small>
                    </code> */}
                  </p>
                  <p style={{ color: YELLOW_MOBILE }}>
                    - Barang Rusak Gudang (dari Edit Stock) <span style={{ float: 'right' }}>({product.totalDamageEdit || 0})</span>
                    <br />
                    {/* <code style={{ color: 'red' }}>
                      <small>Sudah masuk kedalam total stok kotor.</small>
                    </code> */}
                  </p>
                </>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Divider />

      {!isSuperVisor && !isSales && (
        <>
          <Typography component='p' variant='h5' className={classes.titleDetail}>
            Details Harga Jual
          </Typography>

          <TableContainer className={classes.priceTable}>
            <Table size='small' className={classes.tableDetail}>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell size='small' className={classes.cell}>
                      <Skeleton variant='text' width='100%' />
                    </TableCell>
                  </TableRow>
                ) : product.ProductPrice && product.ProductPrice.length > 0 ? (
                  product.ProductPrice.map((value, index) => {
                    return (
                      value.price > 0 && (
                        <TableRow key={index}>
                          <TableCell size='small' className={classes.cell}>
                            <Typography component='p' className={classes.label}>
                              {value.Zone && value.Zone.name}
                            </Typography>
                            <Typography component='p' className={classes.price}>
                              {isLoading ? (
                                <Skeleton variant='text' width='100%' />
                              ) : (
                                <NumberFormat value={value.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell size='small'>Harga Jual Belum ditentukan</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Fragment>
  );
};

export default ProductDetail;
