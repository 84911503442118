import React, { FC } from 'react';
import { Drawer, Grid, IconButton, InputBase, makeStyles, Theme, Typography } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import { Pagination, Skeleton } from '@material-ui/lab';
import EmptyIcon from 'images/icon/EmptyIcon.svg';
import useRouter from 'hooks/useRouter';
interface Props {
  open: boolean;
  search: string;
  isLoading: boolean;
  authorisations: Authorisation[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  metaAuthorisation: MetaData;
  handleSearchAuthorisation: () => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100vh',
    borderRadius: '5px 5px 0',
    padding: '1em',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },

  searchingContainer: {
    padding: '0px 10px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    borderRadius: '5px'
  },
  iconButton: {
    color: '#616161'
  },
  containerItem: {
    display: 'flex',
    padding: '0.7em',
    alignItems: 'center',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    borderRadius: '8px',
    boxShadow: '0 2px 3px rgb(0 0 0 / 14%)'
  },
  containerItemHasDiscount: {
    display: 'flex',
    padding: '0.7em',
    alignItems: 'center',
    backgroundColor: '#FFFAEF',
    border: '1px solid #E4AE42',
    borderRadius: '8px',
    boxShadow: '0 2px 3px rgb(0 0 0 / 14%)'
  },
  containerStatus: {
    backgroundColor: '#E4AE42',
    borderRadius: '89px',
    padding: '0.5em 0.7em',
    color: '#fff'
  },
  containerEmpty: {
    minHeight: '200px',
    padding: '0 1em',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    borderRadius: '8px',
    boxShadow: '0 2px 3px rgb(0 0 0 / 14%)'
  }
}));
const AuthorisationList: FC<Props> = ({
  open,
  setOpen,
  authorisations,
  metaAuthorisation,
  isLoading,
  handleSearchAuthorisation,
  search,
  setSearch
}): JSX.Element => {
  const classes = useStyles();
  const { history } = useRouter();

  return (
    <Drawer anchor='bottom' open={open} classes={{ paper: classes.container }}>
      <Grid xs={12} container justify='flex-start' alignContent='flex-start' style={{ rowGap: '0.7em' }}>
        <Grid xs={12} container alignItems='center' style={{ columnGap: '0.7em' }}>
          <Close fontSize='small' onClick={() => setOpen(false)} />
          <Typography variant='h6'>SO Pending Plafon & Discount</Typography>
        </Grid>
        <Grid xs={12} className={classes.searchingContainer}>
          <InputBase
            //   classes={{
            //     input: classes.textContentMobile
            //   }}
            style={{ flex: 1 }}
            placeholder='Cari nama atau no. order'
            inputProps={{ 'aria-label': 'search' }}
            value={search}
            onChange={e => setSearch(e.target.value)}
          />

          <IconButton type='submit' className={classes.iconButton} aria-label='search' onClick={() => handleSearchAuthorisation()}>
            <Search fontSize='small' />
          </IconButton>
        </Grid>
        <Grid xs={12} container justify='flex-end'>
          <Pagination
            count={metaAuthorisation.last_page}
            //  onChange={(event, page) => setCurrentPage(page)}
            page={metaAuthorisation.current_page}
            boundaryCount={2}
            variant='outlined'
            shape='rounded'
            color='primary'
            size='small'
          />
        </Grid>
        <Grid xs={12} container style={{ gap: '0.7em' }}>
          {isLoading ? (
            <Grid xs={12}>
              {[...new Array(6)].map(val => (
                <Skeleton height={70} key={val} />
              ))}
            </Grid>
          ) : authorisations.length > 0 ? (
            authorisations.map((val, key) => (
              <Grid
                xs={12}
                className={!val.isAccept ? classes.containerItem : classes.containerItemHasDiscount}
                key={key}
                onClick={() => history.push(`/penjualan/${val.id}`)}
              >
                <Grid xs={5}>
                  <Typography>{'#'.concat(val.orderNumber)}</Typography>
                  <Typography variant='h6'>{val.partnerName}</Typography>
                </Grid>
                <Grid xs={4}>
                  <Typography>{val.orderDate}</Typography>
                </Grid>
                <Grid xs={3} container justify='center'>
                  <Typography align='center' className={classes.containerStatus}>
                    Tertunda
                  </Typography>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid xs={12} container direction='column' alignItems='center' justify='center' className={classes.containerEmpty}>
              <img src={EmptyIcon} alt='Data Kosong' width='100px' height='100px' />

              <Typography variant='h6' align='center' style={{ marginTop: '0.4em' }}>
                Tidak ada data
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default AuthorisationList;
