import { Divider, Grid, makeStyles, Theme, Tooltip, Typography, Fade } from '@material-ui/core';
import { ExpandMoreRounded, InfoRounded } from '@material-ui/icons';
import FlexBox from 'components/FlexBox';
import React, { memo } from 'react';
import { TData, TListExpand } from 'pages/StockMovementPage';
import { GRAY_3, GREY, PRIMARY_MOBILE, WHITE } from 'constants/colors';
import clsx from 'clsx';
import StockMovementContainer from './StockMovementContainer';
import { Skeleton } from '@material-ui/lab';
import monthNames from 'utils/month';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';
import Loading from 'components/Loading';

const size = {
  small: 0.1,
  medium: 0.15,
  large: 0.25
} as const;

type TTableProperty = {
  key: keyof StockMovement;
  title: string;
  size: keyof typeof size;
  align: 'left' | 'center' | 'right';
};
const tableProperties: TTableProperty[] = [
  {
    key: 'createdAt',
    title: 'Tanggal',
    size: 'medium',
    align: 'left'
  },
  {
    key: 'activity',
    title: 'Aktifitas\nNo.Transaksi',
    size: 'large',
    align: 'left'
  },

  {
    key: 'firstStock',
    title: 'Total Stok',
    size: 'small',
    align: 'right'
  },
  {
    key: 'mutation',
    title: 'Stok Mutasi',
    size: 'small',
    align: 'right'
  },
  {
    key: 'holdStock',
    title: 'Stok Tertahan',
    size: 'small',
    align: 'right'
  },

  {
    key: 'damageStock',
    title: 'Stok Rusak',
    size: 'small',
    align: 'right'
  },
  {
    key: 'salesStock',
    title: 'Stok\nJual',
    size: 'small',
    align: 'right'
  },
  {
    key: 'price',
    title: 'Harga Beli/Jual',
    size: 'medium',
    align: 'right'
  },
  {
    key: 'nettHpp',
    title: 'Nett Hpp',
    size: 'medium',
    align: 'right'
  },
  {
    key: 'totalNettHpp',
    title: 'Total\nNett Hpp',
    size: 'medium',
    align: 'right'
  }
];

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: '50vh'
  },
  titleTable: {
    whiteSpace: 'pre-wrap' /* or pre-line */,
    color: GRAY_3
  },
  fontSizeNormal: {
    fontSize: '.9rem'
  },
  fontBold: {
    fontWeight: 500
  },
  padding: {
    padding: '1em 1em .8em'
  },

  borderTop: {
    borderTop: `1px solid ${GREY}`
  },
  itemWrapper: {
    border: `1px solid ${GREY}`,
    borderRadius: '.4em',
    '&:hover': {
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 14%)'
    }
  },

  link: {
    transition: 'all .3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      color: PRIMARY_MOBILE
    }
  },
  icon: {
    padding: '.15em',
    fontSize: '2rem',
    cursor: 'pointer',
    transition: 'rotate .3s',
    '&:hover': {
      background: GREY,
      borderRadius: '50%'
    }
  },
  tableWrapper: {
    backgroundColor: '#F7F9FC'
  },
  iconRotate: {
    rotate: '180deg'
  },
  skelaton: {
    height: '4em',
    background: GREY
  },
  empty: {
    background: GREY,
    borderRadius: '.4em'
  },
  positionRelative: {
    position: 'relative'
  },
  tooltipWrapper: {
    backgroundColor: WHITE,
    borderRadius: '6px',
    boxShadow: '0px 2px 2px 1px rgb(0 0 0 / 20%)',
    zIndex: 1,
    padding: '1.5em'
  },
  contentInfo: {
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      bottom: 7,
      zIndex: 2,
      width: '1.3em',
      height: '1.3em',
      transform: 'translateX(-50%) rotate(45deg)',
      borderRight: '1px solid #9F9F9F',
      borderBottom: '1px solid #9F9F9F',
      backgroundColor: WHITE
    },
    '& > *': {
      color: '#000'
    }
  },
  pointer: {
    cursor: 'pointer'
  },
  colorGrey: {
    color: '#9F9F9F'
  },
  iconHover: {
    transition: 'all .3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.2)',
      color: '#9F9F9F'
    }
  },
  minHight: {
    minHeight: '25em'
  }
}));

/* Defining the props that the component will receive. */
interface Props {
  handleListExpand: ({ id, action }: TListExpand) => void;
  products: TData<StockMovementProduct[]>;
  stockMovements: TData<StockMovement[]>;
  stockMovementSummary: TData<stockMovementSummary>;
  productMonths: TData<StockMovementProductMonth[]>;
  productExpand: { id: number; isOpen: boolean };
  monthExpand: { month: string; isOpen: boolean };
}

/* A function component. */

const StockMovementContent = ({
  handleListExpand,
  products,
  stockMovements,
  productMonths,
  productExpand,
  monthExpand,
  stockMovementSummary
}: Props) => {
  const classes = useStyles();
  const { ...stockSummary } = stockMovementSummary.data;

  /**
   * It takes a string and returns a number.
   * @param {string} date - string - the date that is being parsed
   * @returns The month of the date.
   */
  const dateParsing = (date: string): number => {
    const temp = +date.slice(5) - 1;
    return temp;
  };

  const getStockMovement = (index: number, optionts: keyof StockMovement): string => {
    const stockMovementBefore = stockMovements.data[index > 0 ? index - 1 : 0][optionts];
    const stockMovementAfter = stockMovements.data[index][optionts];
    const stockMovement = Number(stockMovementAfter) - Number(stockMovementBefore);
    return stockMovement > 0 ? `+${stockMovement.toString()}` : stockMovement.toString();
  };

  return (
    <FlexBox container xs={12} rowGap={1} className={classes.container} alignItems='flex-start'>
      {/* A ternary operator.  */}
      {products.isLoading
        ? [...new Array(7)].map(value => <Grid xs={12} className={clsx(classes.skelaton, classes.itemWrapper)} />)
        : products.data.map(({ id, productName, warehouse }) => (
            <FlexBox xs={12} container key={id} className={classes.itemWrapper} alignItems='center'>
              <FlexBox
                container
                alignItems='center'
                columnGap={1}
                onClick={() => handleListExpand({ id, action: 'products' })}
                className={classes.padding}
              >
                <ExpandMoreRounded className={clsx(classes.icon, productExpand.id === id && productExpand.isOpen && classes.iconRotate)} />
                <Typography
                  variant='h6'
                  color={productExpand.id === id && productExpand.isOpen ? 'primary' : 'initial'}
                  className={clsx(classes.fontSizeNormal, classes.link)}
                >
                  {productName} ({warehouse})
                </Typography>
              </FlexBox>

              <StockMovementContainer isExpand={productExpand.id === id && productExpand.isOpen}>
                <FlexBox xs={12} className={clsx(classes.tableWrapper)} container>
                  {productMonths.isLoading ? (
                    <FlexBox xs={12} container rowGap={1} className={clsx(classes.padding, classes.borderTop)}>
                      <Grid
                        xs={12}
                        className={clsx(classes.tableWrapper, classes.minHight)}
                        container
                        alignItems='center'
                        justify='center'
                        direction='column'
                      >
                        <Loading />
                        <Typography variant='h6' className={classes.fontSizeNormal}>
                          Sedang Membuka...
                        </Typography>
                      </Grid>
                    </FlexBox>
                  ) : productMonths.data.length > 0 ? (
                    productMonths.data.map(({ date }) => (
                      <FlexBox xs={12} container key={date} className={clsx(classes.borderTop, classes.padding)}>
                        <FlexBox
                          alignItems='center'
                          columnGap={1}
                          container
                          onClick={() =>
                            handleListExpand({
                              id: date,
                              action: 'months'
                            })
                          }
                        >
                          <ExpandMoreRounded className={clsx(classes.icon, monthExpand.month === date && monthExpand.isOpen && classes.iconRotate)} />

                          <Typography
                            variant='h6'
                            color={monthExpand.month === date && monthExpand.isOpen ? 'primary' : 'initial'}
                            className={clsx(classes.fontSizeNormal, classes.link)}
                          >
                            {`${monthNames[dateParsing(date)]} ${new Date(date).getFullYear()}`}
                          </Typography>
                        </FlexBox>
                        <StockMovementContainer isExpand={monthExpand.month === date && monthExpand.isOpen} key={date}>
                          <FlexBox container xs={12} rowGap={0.8} className={classes.padding}>
                            <Grid xs={12}>
                              <Divider />
                            </Grid>

                            <StockMovementContainer isExpand={stockMovementSummary.isLoading}>
                              <Grid container justify='center' className={classes.padding}>
                                <Loading size='small' />
                              </Grid>
                            </StockMovementContainer>

                            <Grid xs={12} container alignItems='center'>
                              <Grid xs={4} container alignItems='center'>
                                <Grid xs={7}>
                                  <Typography variant='h6' className={classes.fontSizeNormal}>
                                    Total Stok Awal
                                  </Typography>
                                </Grid>
                                <Grid xs={5}>
                                  <Typography variant='h6' className={classes.fontSizeNormal}>
                                    : {stockSummary.totalStockFirstStart}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid xs={8} container alignItems='center'>
                                <Grid xs={3}>
                                  <Typography variant='h6' className={classes.fontSizeNormal}>
                                    Total Stok Akhir
                                  </Typography>
                                </Grid>
                                <Grid xs={9}>
                                  <Typography variant='h6' className={classes.fontSizeNormal}>
                                    : {stockSummary.totalStockEnd}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid xs={12} container alignItems='center'>
                              <Grid xs={4} container alignItems='center'>
                                <Grid xs={7}>
                                  <Typography className={classes.fontSizeNormal}>Total Stok Rusak</Typography>
                                </Grid>
                                <Grid xs={5}>
                                  <Typography className={classes.fontSizeNormal}>: {stockSummary.totalStockDamageStart}</Typography>
                                </Grid>
                              </Grid>
                              <Grid xs={8} container alignItems='center'>
                                <Grid xs={3}>
                                  <Typography className={classes.fontSizeNormal}>Total Stok Rusak</Typography>
                                </Grid>
                                <Grid xs={9}>
                                  <Typography className={classes.fontSizeNormal}>: {stockSummary.totalStockDamageEnd}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid xs={12} container alignItems='center'>
                              <Grid xs={4} container alignItems='center'>
                                <Grid xs={7}>
                                  <Typography className={classes.fontSizeNormal}>Total Stok Jual</Typography>
                                </Grid>
                                <Grid xs={5}>
                                  <Typography className={classes.fontSizeNormal}>: {stockSummary.totalStockSalesStart}</Typography>
                                </Grid>
                              </Grid>
                              <Grid xs={8} container alignItems='center'>
                                <Grid xs={3}>
                                  <Typography className={classes.fontSizeNormal}>Total Stok Jual</Typography>
                                </Grid>
                                <Grid xs={9}>
                                  <Typography className={classes.fontSizeNormal}>: {stockSummary.totalStockSalesEnd}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid xs={12}>
                              <Divider />
                            </Grid>
                            <FlexBox xs={12} container columnGap={0.3} alignItems='center'>
                              {tableProperties.map(tableProperty => (
                                <Grid key={tableProperty.key} style={{ flex: size[tableProperty.size] }}>
                                  <Typography
                                    align={tableProperty.align}
                                    className={clsx(classes.fontSizeNormal, classes.fontBold, classes.titleTable)}
                                  >
                                    {tableProperty.title.toLocaleUpperCase()}
                                  </Typography>
                                </Grid>
                              ))}
                            </FlexBox>
                            {console.log('cek >', stockMovements?.data.length)}
                            {stockMovements.isLoading ? (
                              <Grid xs={12}>
                                {[...new Array(5)].map(value => (
                                  <Skeleton height={50} />
                                ))}
                              </Grid>
                            ) : (
                              stockMovements.data.map((stockMovement, index) => (
                                <FlexBox xs={12} container columnGap={0.3}>
                                  {tableProperties.map(tableProperty => (
                                    <Grid key={tableProperty.key} style={{ flex: size[tableProperty.size] }}>
                                      {tableProperty.key === 'activity' ? (
                                        <Typography align={tableProperty.align} className={classes.fontSizeNormal}>
                                          {stockMovement['activity']}
                                          <Typography align={tableProperty.align} className={clsx(classes.fontSizeNormal, classes.fontBold)}>
                                            {stockMovement['number'] || '-'}
                                          </Typography>
                                        </Typography>
                                      ) : tableProperty.key === 'createdAt' ? (
                                        <Typography align={tableProperty.align} className={classes.fontSizeNormal}>
                                          {format(new Date(stockMovement[tableProperty.key]), 'dd MMM yyyy')}
                                        </Typography>
                                      ) : tableProperty.key === 'price' || tableProperty.key === 'nettHpp' || tableProperty.key === 'totalNettHpp' ? (
                                        <Typography align={tableProperty.align} className={classes.fontSizeNormal}>
                                          <NumberFormat
                                            value={stockMovement[tableProperty.key].toFixed()}
                                            prefix={'Rp'}
                                            thousandSeparator={true}
                                            displayType='text'
                                          />
                                        </Typography>
                                      ) : tableProperty.key === 'mutation' ? (
                                        <FlexBox
                                          container
                                          alignItems='center'
                                          justify='flex-end'
                                          columnGap={0.4}
                                          className={classes.positionRelative}
                                        >
                                          <Typography align={tableProperty.align} className={classes.fontSizeNormal}>
                                            {stockMovement[tableProperty.key]}
                                          </Typography>
                                          <Tooltip
                                            classes={{ tooltip: classes.tooltipWrapper }}
                                            placement='top'
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={
                                              <Grid className={classes.contentInfo}>
                                                <Typography variant='h6' color='textSecondary'>
                                                  Mutasi: {format(new Date(stockMovement.createdAt), 'dd MMM yyyy')}
                                                </Typography>
                                                <Divider variant='fullWidth' />
                                                <Typography>Stok On Hold: {getStockMovement(index, 'holdStock')}</Typography>
                                                <Typography>Stok Rusak: {getStockMovement(index, 'damageStock')}</Typography>
                                                <Typography>Stok Jual: {getStockMovement(index, 'salesStock')}</Typography>
                                              </Grid>
                                            }
                                          >
                                            <InfoRounded className={clsx(classes.colorGrey, classes.pointer, classes.iconHover)} fontSize='small' />
                                          </Tooltip>
                                        </FlexBox>
                                      ) : (
                                        <Typography align={tableProperty.align} className={classes.fontSizeNormal}>
                                          {stockMovement[tableProperty.key]}
                                        </Typography>
                                      )}
                                    </Grid>
                                  ))}
                                </FlexBox>
                              ))
                            )}
                          </FlexBox>
                        </StockMovementContainer>
                      </FlexBox>
                    ))
                  ) : (
                    <Grid xs={12} container justify='center' alignItems='center' className={clsx(classes.minHight, classes.borderTop)}>
                      <Typography variant='h6'>Produk tidak ada pergerakan.</Typography>
                    </Grid>
                  )}
                </FlexBox>
              </StockMovementContainer>
            </FlexBox>
          ))}
    </FlexBox>
  );
};

export default memo(StockMovementContent);
