import React, { FC, useState, useEffect, Fragment, useContext } from 'react';
import { TextField, Typography, Grid, CircularProgress, makeStyles } from '@material-ui/core';
import { PARTNER_BASE_URL, WILAYAH_PROVINSI_BASE_URL, WILAYAH_KABUPATEN_BASE_URL, WILAYAH_KECAMATAN_BASE_URL, ZONE_BASE_URL } from 'constants/url';
import { isValidEmail } from 'utils';
import { PaperCustom } from 'components';
import { dummyRegion, dummyZone } from 'utils/dummy';
import TypeUser from 'typings/enum/TypeUser';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import NumberFormatMask from 'components/NumberFormatMask';
import ReactQuill from 'react-quill';
import { CurrentUserContext } from 'contexts/CurrentUserContext';

const useStyles = makeStyles({
  EditorNotes: {
    PaddingTop: 12,
    marginTop: 12
  }
});

interface Props {
  partner: PartnerModel;
  setPartner: React.Dispatch<React.SetStateAction<PartnerModel>>;
  isSubmit: boolean;
  typeUser: string;
  setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

enum PartnerType {
  SUPPLIER = 'SUPPLIER',
  CUSTOMER = 'CUSTOMER'
}

const PartnerWizard: FC<Props> = props => {
  const classes = useStyles();
  const { partner, setPartner, isSubmit, typeUser, setSubmit, setUpdate, handleSnackBar } = props;
  const [partnerId, setPartnerId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [cellPhoneNumber, setCellPhoneNumber] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [website, setWebsite] = useState<string>('');
  const [virtualAccount, setVirtualAccount] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [postalCode, setPostalCode] = useState<number | null>(null);
  const [partnerType, setPartnerType] = useState<'SUPPLIER' | 'CUSTOMER' | null>();
  const [businessEntity, setBusinessEntity] = useState<string>('');
  const [provinsi, setProvinsi] = useState<RegionModel>(dummyRegion);
  const [kabupaten, setKabupaten] = useState<RegionModel>(dummyRegion);
  const [kecamatan, setKecamatan] = useState<RegionModel>(dummyRegion);
  const [zone, setZone] = useState<ZoneModel>(dummyZone);
  const [route, setRoute] = useState<ZoneModel[]>([dummyZone]);
  const [region, setRegion] = useState<RegionModel[]>([dummyRegion]);
  const [nameMessage, setNameMessage] = useState<string>('');
  const [remark, setRemark] = useState<string>('');
  const [cellPhoneNumberMessage, setCellPhoneNumberMessage] = useState<string>('');
  const [phoneNumberMessage, setPhoneNumberMessage] = useState<string>('');
  const [emailMessage, setEmailMessage] = useState<string>('');
  const [addressMessage, setAddressMessage] = useState<string>('');
  const [provinsiMessage, setProvinsiMessage] = useState<string>('');
  const [kabupatenMessage, setKabupatenMessage] = useState<string>('');
  const [kecamatanMessage, setKecamatanMessage] = useState<string>('');
  const [postalCodeMessage, setPostalCodeMessage] = useState<string>('');
  const [zoneMessage, setZoneMessage] = useState<string>('');
  const [dueDate, setDueDate] = useState<number | null>(null);
  const [dueDateMessage, setDueDateMessage] = useState<string>('');
  const [loadProvinsi, setLoadProvinsi] = useState<boolean>(false);
  const [loadKabupaten, setLoadKabupaten] = useState<boolean>(false);
  const [loadKecamatan, setLoadKecamatan] = useState<boolean>(false);
  const [loadZone, setLoadZone] = useState<boolean>(false);
  const [plafon, setPlafon] = useState<number | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState<number>(100);
  const { currentUser } = useContext(CurrentUserContext);

  useEffect(() => {
    if (!partner) {
      return;
    }

    setName(partner.name);
    setPartnerId(partner.partnerId);
    setPhoneNumber(partner.phoneNumber);
    setCellPhoneNumber(partner.cellPhoneNumber);
    setPartnerType(partner.partnerType);
    setZone(partner.Zone ? partner.Zone : dummyZone);
    setAddress(partner.address);
    setEmail(partner.email);
    setWebsite(partner.website);
    setBusinessEntity(partner.businessEntity);
    setVirtualAccount(partner.virtualAccount);
    setNotes(partner.notes);
    setPostalCode(partner.postalCode ? partner.postalCode : null);
    setProvinsi(partner.Provinsi ? partner.Provinsi : dummyRegion);
    setKabupaten(partner.Kabupaten ? partner.Kabupaten : dummyRegion);
    setKecamatan(partner.Kecamatan ? partner.Kecamatan : dummyRegion);
    setDueDate(partner.dueDate);
    setPlafon(partner.plafon ? partner.plafon : 0);
  }, [partner]);

  useEffect(() => {
    if (isSubmit) {
      resetValidation();
      if (!validation()) {
        setSubmit(false);
        return;
      }
      onSubmit();
    }
  }, [isSubmit, setSubmit]);

  const handleSearchProvinsi = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    setLoadProvinsi(true);
    try {
      const { data } = await axios.get(`${WILAYAH_PROVINSI_BASE_URL}?${params.toString()}`);
      setRegion(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadProvinsi(false);
    }
  };

  const handleSearchKabupaten = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    setLoadKabupaten(true);
    try {
      const { data } = await axios.get(`${WILAYAH_KABUPATEN_BASE_URL(provinsi.code)}?${params.toString()}`);
      setRegion(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadKabupaten(false);
    }
  };

  const handleSearchZone = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    params.append('perPage', rowsPerPage.toString());
    setLoadZone(true);
    try {
      const { data } = await axios.get(`${ZONE_BASE_URL}?${params.toString()}`);
      setRoute(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadZone(false);
    }
  };

  const handleSearchKecamatan = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    setLoadKecamatan(true);
    try {
      const { data } = await axios.get(`${WILAYAH_KECAMATAN_BASE_URL(kabupaten.code)}?${params.toString()}`);
      setRegion(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadKecamatan(false);
    }
  };

  const onSubmit = async () => {
    try {
      setSubmit(true);
      const region = provinsi.name + ', ' + kabupaten.name + ', ' + kecamatan.name;
      const { data } = await axios.post(PARTNER_BASE_URL, {
        id: partner.id,
        partnerId,
        name,
        partnerType,
        phoneNumber,
        cellPhoneNumber,
        email,
        address,
        notes,
        website,
        postalCode,
        areaCode: kecamatan.code,
        regionName: region,
        ZoneId: zone && zone.id ? zone.id : null,
        businessEntity,
        virtualAccount,
        dueDate,
        plafon,
        remarkAddress: remark
      });
      setPartner(data.data);
      setUpdate(false);
      setSubmit(false);
      handleSnackBar(true, 'success', 'Mitra berhasil diperbaharui');
    } catch (error) {
      console.log('error :', error);
      const err = error as { data: { message: string } };
      setSubmit(false);
      handleSnackBar(true, 'error', err.data.message);
    }
  };

  const validation = (): boolean => {
    let valid = true;

    if (name === '' || !name) {
      setNameMessage('Nama tidak boleh kosong.');
      valid = false;
    }

    if (!isValidEmail(email) && email !== '') {
      setEmailMessage('Email tidak valid.');
      valid = false;
    }

    if (address === '' && !addressMessage) {
      setAddressMessage('Alamat tidak boleh kosong,');
      valid = false;
    }

    if (provinsi.name === '' || provinsi.code === '') {
      setProvinsiMessage('Asal provinsi tidak boleh kosong');
      valid = false;
    }

    if (cellPhoneNumber === '') {
      setCellPhoneNumberMessage('No handphone tidak boleh kosong');
      valid = false;
    }

    if (phoneNumber === '') {
      setPhoneNumberMessage('No telpon tidak boleh kosong');
      valid = false;
    }
    if (kabupaten.name === '' || kabupaten.code === '') {
      setKabupatenMessage('Asal provinsi tidak boleh kosong');
      valid = false;
    }

    if (kecamatan.name === '' || kecamatan.code === '') {
      setKecamatanMessage('Asal provinsi tidak boleh kosong');
      valid = false;
    }

    if (partnerType === PartnerType.CUSTOMER) {
      if (!dueDate) {
        setDueDateMessage('Jatuh tempo tidak boleh kosong');
        valid = false;
      }
    }
    return valid;
  };

  const resetValidation = () => {
    setNameMessage('');
    setEmailMessage('');
    setAddressMessage('');
    setProvinsiMessage('');
    setKabupatenMessage('');
    setKecamatanMessage('');
    setPostalCodeMessage('');
  };

  useEffect(() => {
    if (name !== '') {
      setNameMessage('');
    }

    if (isValidEmail(email)) {
      setEmailMessage('');
    }

    if (partnerType === PartnerType.CUSTOMER) {
      if (dueDate) {
        setDueDateMessage('');
      }

      if ((zone && zone.id !== 0) || (zone && zone.name !== '')) {
        setZoneMessage('');
      }
    }

    if (address !== '') {
      setAddressMessage('');
    }

    if (provinsi.name !== '' || provinsi.code !== '') {
      setProvinsiMessage('');
    }

    if (kabupaten.name !== '' || kabupaten.code !== '') {
      setKabupatenMessage('');
    }

    if (kecamatan.name !== '' || kecamatan.code !== '') {
      setKecamatanMessage('');
    }
  }, [name, email, partnerType, dueDate, zone, partnerType, address, provinsi, kabupaten, kecamatan]);

  return (
    <Grid container direction='row' justify='space-between'>
      <Grid container item lg={6} sm={12} md={6} xs={12} spacing={2}>
        <PaperCustom>
          <Typography variant='h5'>Informasi</Typography>

          <Grid item lg={12} sm={12} md={12} xs={12}>
            <TextField
              id='name'
              name='name'
              required
              fullWidth
              placeholder='Nama'
              value={name}
              onChange={event => setName(event.target.value)}
              error={nameMessage !== ''}
              helperText={nameMessage}
            />
          </Grid>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <TextField
              id='partnerId'
              required
              fullWidth
              placeholder='ID Mitra'
              value={partnerId}
              onChange={event => setPartnerId(event.target.value)}
              disabled
            />
          </Grid>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <TextField
              id='email'
              name='email'
              required
              fullWidth
              placeholder='Email '
              value={email}
              onChange={event => setEmail(event.target.value)}
              error={emailMessage !== ''}
              helperText={emailMessage}
            />
          </Grid>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <TextField
              id='phoneNumber'
              fullWidth
              placeholder='No Telepon'
              value={phoneNumber}
              error={phoneNumberMessage !== ''}
              helperText={phoneNumberMessage}
              onChange={event => setPhoneNumber(event.target.value)}
              inputProps={{
                maxLength: 13
              }}
            />
          </Grid>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <TextField
              id='cellPhoneNumber'
              fullWidth
              placeholder='No Handphone '
              value={cellPhoneNumber}
              onChange={event => setCellPhoneNumber(event.target.value)}
              inputProps={{
                maxLength: 13
              }}
              error={cellPhoneNumberMessage !== ''}
              helperText={cellPhoneNumberMessage}
            />
          </Grid>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <TextField
              id='website'
              required
              fullWidth
              placeholder='Laman Website'
              value={website}
              onChange={event => setWebsite(event.target.value)}
            />
          </Grid>

          <Grid item lg={12} sm={12} md={12} xs={12}>
            <TextField
              id='virtualAccount'
              required
              fullWidth
              placeholder='No Akun Virtual'
              value={virtualAccount}
              onChange={event => setVirtualAccount(event.target.value)}
            />
          </Grid>

          {partnerType === PartnerType.CUSTOMER && (
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <TextField
                id='plafon'
                required
                fullWidth
                placeholder='Plafon'
                value={plafon}
                onChange={event => setPlafon(+event.target.value)}
                InputProps={{
                  inputComponent: NumberFormatMask as any
                }}
              />
            </Grid>
          )}

          <Grid item lg={12} sm={12} md={12} xs={12}>
            <TextField
              id='dueDate'
              required
              fullWidth
              label='Set Jatuh Tempo'
              value={dueDate}
              onChange={event => setDueDate(+event.target.value)}
              error={dueDateMessage !== ''}
              helperText={dueDateMessage}
            />
          </Grid>
        </PaperCustom>
      </Grid>

      <Grid container item lg={6} sm={12} md={6} xs={12} spacing={2}>
        <PaperCustom>
          <Typography variant='h5'>Detail Alamat</Typography>

          <Grid item lg={12} sm={12} md={12} xs={12}>
            <TextField
              id='address'
              required
              fullWidth
              placeholder='Alamat'
              value={address}
              onChange={event => setAddress(event.target.value)}
              error={addressMessage !== ''}
              helperText={addressMessage}
            />
          </Grid>

          {partnerType === PartnerType.CUSTOMER && currentUser && (currentUser.type === 'ADMIN' || currentUser.type === 'SUPERADMIN') && (
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <TextField id='remarkAddress' fullWidth placeholder='Remark Alamat' value={remark} onChange={event => setRemark(event.target.value)} />
            </Grid>
          )}

          <Grid item lg={12} sm={12} md={12} xs={12}>
            <Autocomplete
              id='provinsi'
              fullWidth
              value={provinsi}
              options={region}
              getOptionLabel={option => option.name}
              onOpen={e => handleSearchProvinsi('')}
              onChange={(event: any, value: any) => {
                if (value) {
                  setProvinsi(value);
                  setRegion([dummyRegion]);
                }
              }}
              loading={loadProvinsi}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder='Provinsi'
                  onChange={e => handleSearchProvinsi(e.target.value)}
                  error={provinsiMessage !== ''}
                  helperText={provinsiMessage}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>
                        {loadProvinsi && <CircularProgress color='inherit' size={20} />}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    )
                  }}
                />
              )}
            />
          </Grid>

          <Grid item lg={12} sm={12} md={12} xs={12}>
            <Autocomplete
              id='kabupaten'
              fullWidth
              value={kabupaten}
              options={region}
              getOptionLabel={option => option.name}
              onChange={(event: any, value: any) => {
                if (value) {
                  setKabupaten(value);
                  setRegion([dummyRegion]);
                }
              }}
              loading={loadKabupaten}
              onOpen={() => handleSearchKabupaten('')}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder='Kabupaten'
                  onChange={e => handleSearchKabupaten(e.target.value)}
                  error={kabupatenMessage !== ''}
                  helperText={kabupatenMessage}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>
                        {loadKabupaten && <CircularProgress color='inherit' size={20} />}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    )
                  }}
                />
              )}
            />
          </Grid>

          <Grid item lg={12} sm={12} md={12} xs={12}>
            <Autocomplete
              id='kecamatan'
              fullWidth
              value={kecamatan}
              options={region}
              getOptionLabel={option => option.name}
              onChange={(event: any, value: any) => {
                setKecamatan(value);
                setRegion([dummyRegion]);
              }}
              onOpen={() => handleSearchKecamatan('')}
              loading={loadKecamatan}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder='Kecamatan'
                  onChange={e => handleSearchKecamatan(e.target.value)}
                  error={kecamatanMessage !== ''}
                  helperText={kecamatanMessage}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>
                        {loadKecamatan && <CircularProgress color='inherit' size={20} />}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    )
                  }}
                />
              )}
            />
          </Grid>

          {partnerType === PartnerType.CUSTOMER && typeUser !== TypeUser.SALES && (
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <Autocomplete
                id='zone'
                fullWidth
                value={zone}
                options={route}
                getOptionLabel={option => option.name}
                onChange={(event: any, value: any) => value && setZone(value)}
                onOpen={() => handleSearchZone('')}
                loading={loadZone}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder='Route'
                    onChange={e => handleSearchZone(e.target.value)}
                    error={zoneMessage !== ''}
                    helperText={zoneMessage}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {loadZone && <CircularProgress color='inherit' size={20} />}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      )
                    }}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item lg={12} sm={12} md={12} xs={12}>
            <TextField
              id='postalCode'
              fullWidth
              placeholder='Kode Pos '
              value={postalCode}
              onChange={event => setPostalCode(+event.target.value)}
              error={postalCodeMessage !== ''}
              helperText={postalCodeMessage}
            />
          </Grid>

          <Grid item lg={12} sm={12} md={12} xs={12}>
            <ReactQuill className={classes.EditorNotes} id='notes' value={notes} onChange={(value: any) => setNotes(value)} placeholder='Catatan' />
          </Grid>
        </PaperCustom>
      </Grid>
    </Grid>
  );
};

export default PartnerWizard;
