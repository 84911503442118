import React, { FC } from 'react';
import { TableBody, TableHead, TableRow, TableCell, TableContainer } from '@material-ui/core';
import BodyRow from './components/BodyRow';
import { TableCustom } from 'components/Table';
interface Props {
  isLoadingData: boolean;
  invoiceItem: ReturnBillItem[];
  rute: string;

  isRequestEdit: boolean;
  item: { id: number; notes: string; isRequestEdit: boolean }[];
  indexCollapse: number;
  indexEdit: number;
  type: string;
  loadingEdit: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  onDeleteItem: (id: number) => void;
  onUpdate: () => void;
  individualEdit: (item: InvoiceItemModel) => void;
  handleOpenCollapse: (index: number) => React.MouseEventHandler;
  handleEdit: (index: number, type: string) => void;
  handleRequestNote: (item: { id: number; notes: string }) => void;
  handleRequestCheck: (item: { id: number; notes: string; isRequestEdit: boolean }) => void;
}

const ListProduct: FC<Props> = props => {
  const {
    isLoadingData,
    invoiceItem,
    isRequestEdit,
    indexCollapse,
    indexEdit,
    item,
    rute,
    type,

    isAdmin,
    isSuperAdmin,
    loadingEdit,
    onDeleteItem,
    onUpdate,
    individualEdit,
    handleEdit,
    handleRequestNote,
    handleRequestCheck,
    handleOpenCollapse
  } = props;

  return (
    <TableContainer>
      <TableCustom size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Nama Produk</TableCell>
            <TableCell align='center'>Type Unit</TableCell>
            <TableCell align='center'>Kuantitas</TableCell>
            <TableCell align='center'>Harga</TableCell>
            <TableCell align='center'>Total Harga</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceItem.length > 0 &&
            invoiceItem.map((value, index) => (
              <BodyRow
                key={index}
                isRequestEdit={isRequestEdit}
                isLoading={isLoadingData}
                invoiceItem={value}
                indexCollapse={indexCollapse}
                indexEdit={indexEdit}
                loadingEdit={loadingEdit}
                isAdmin={isAdmin}
                isSuperAdmin={isSuperAdmin}
                onDeleteItem={onDeleteItem}
                individualEdit={individualEdit}
                handleEdit={handleEdit}
                onUpdate={onUpdate}
                handleOpenCollapse={handleOpenCollapse(value.id)}
                index={index}
                item={item}
                rute={rute}
                type={type}
                handleRequestNote={handleRequestNote}
                handleRequestCheck={handleRequestCheck}
              />
            ))}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default ListProduct;
