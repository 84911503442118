import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Checkbox, TextField, makeStyles } from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart } from 'components/Table/TableCellCustom';
import TableRowCustom from 'components/Table/TableRowCustom';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { dummyProductItem } from 'utils/dummy';
import NumberFormatMask from 'components/NumberFormatMask';

interface Props {
  product: ProductModel;
  item: ProductItemModel;
  isLoading: boolean;
  checked: ProductItemModel[];
  index: number;
  handleIndividualCheck: (item: ProductItemModel) => void;
  handleIndividualQty: (item: ProductItemModel) => void;
  handleIndividualBonusQty: (item: ProductItemModel) => void;
  handlePromo: (price: number, ProductId: number) => void;
}

const useStyles = makeStyles({
  fieldQty: {
    width: 80
  },
  fieldPromo: {
    width: 120
  }
});

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { item, product, isLoading, checked, handleIndividualCheck, handleIndividualQty, handleIndividualBonusQty, handlePromo } = props;
  const { id } = product;

  let isChecked = checked.some(value => value.ProductId === id);

  const handleSetId = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    handleIndividualCheck({ ...dummyProductItem, ProductId: id });
  };

  const handleSetQty = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleIndividualQty({ ...item, minimumItem: +event.target.value });
  };

  const handleSetBonusQty = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleIndividualBonusQty({ ...item, bonusItem: +event.target.value });
  };

  const handleSetHargaPromo = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handlePromo(+event.target.value, id);
  };

  return (
    <TableRowCustom>
      <TableCellStart>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <Checkbox
            key={id}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<CheckBoxIcon />}
            edge='start'
            color='primary'
            checked={isChecked}
            tabIndex={-1}
            disableRipple
            onChange={handleSetId}
            disabled
          />
        )}
      </TableCellStart>

      <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : product.productCode}</TableCellMiddle>

      <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : product.productName}</TableCellMiddle>

      <TableCellMiddle>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : product.Category ? product.Category.name : '-'}
      </TableCellMiddle>

      <TableCellMiddle align='center'>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : product.typeUnit}</TableCellMiddle>

      <TableCellMiddle>
        <TextField label='Qty' onChange={handleSetQty} value={item.minimumItem} className={classes.fieldQty} />
      </TableCellMiddle>

      <TableCellMiddle>
        <TextField label='Bonus Qty' onChange={handleSetBonusQty} value={item.bonusItem} className={classes.fieldQty} />
      </TableCellMiddle>

      <TableCellEnd>
        <TextField
          disabled={!isChecked}
          label='Harga Promo'
          onChange={handleSetHargaPromo}
          className={classes.fieldPromo}
          InputProps={{ inputComponent: NumberFormatMask as any }}
        />
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRow;
