import React from 'react';
import { Grid, Button, FormControl, FormControlLabel, Checkbox, Typography, CircularProgress } from '@material-ui/core';

interface Props {
  salesRoute: SalesRoute[];
  zone?: ZoneModel[];
  isSuperAdmin: boolean;
  isLoading: boolean;
  handleChangeRoute: (ZoneId: number) => void;
  handleOnSubmit: () => void;
  handleOnCancel: React.MouseEventHandler;
}

const RouteForm: React.FC<Props> = props => {
  const { salesRoute, zone, isSuperAdmin, handleChangeRoute, handleOnSubmit, handleOnCancel, isLoading } = props;

  return (
    <form>
      <Grid container direction='row'>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ul style={{ listStyle: 'none', columnCount: 3 }}>
            {zone && zone.length > 0 ? (
              zone.map((value, index) => (
                <li key={index}>
                  <FormControl component='fieldset'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => handleChangeRoute(value.id)}
                          color='primary'
                          name={value.name}
                          checked={salesRoute.some(_value => _value.ZoneId === value.id)}
                          disabled={!isSuperAdmin}
                        />
                      }
                      label={value.name}
                    />
                  </FormControl>
                </li>
              ))
            ) : (
              <Typography> Data Belum Ada</Typography>
            )}
          </ul>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container direction='row' justify='flex-end' spacing={2}>
          <Grid item>
            <Button color='secondary' onClick={handleOnCancel}>
              Keluar
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={handleOnSubmit} disabled={isLoading}>
              {isLoading ? <CircularProgress size={20} /> : 'Simpan Perubahan'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default RouteForm;
