import React, { FC } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

interface Props {
  name: string;
  isLoading: boolean;
  isOpen: boolean;
  useCollapse: boolean;
  handleOpenCollapse: React.MouseEventHandler;
  handleNameLink: () => void;
}

const ButtonCollapse: FC<Props> = props => {
  const { name, isLoading, isOpen, useCollapse, handleNameLink, handleOpenCollapse } = props;

  return isLoading ? (
    <Skeleton variant='text' width={'100%'} height={25} />
  ) : (
    <Grid style={{ display: 'flex', columnGap: '0.3em', alignItems: ' center' }}>
      {useCollapse && (
        <IconButton aria-label='expand row' size='small' onClick={handleOpenCollapse}>
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      )}
      <span onClick={handleNameLink}>{name}</span>
    </Grid>
  );
};

export default ButtonCollapse;
