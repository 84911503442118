import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { dummyMutationItem } from 'utils/dummy';
import { Checkbox } from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart } from 'components/Table/TableCellCustom';
import TableRowCustom from 'components/Table/TableRowCustom';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

interface Props {
  product: ProductModel;
  isLoading: boolean;
  checked: MutationItemModel[];
  item: MutationItemModel;
  index: number;
  handleIndividualCheck: (item: MutationItemModel) => void;
}

const BodyRowProduct: FC<Props> = props => {
  const { product, isLoading, checked, handleIndividualCheck } = props;
  const { id, typeUnit } = product;

  let isChecked = checked.some(checkedValue => checkedValue.ProductId === id);

  const handleSetId = () => {
    handleIndividualCheck({ ...dummyMutationItem, ProductId: id, typeUnit });
  };

  return (
    <TableRowCustom>
      <TableCellStart>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <Checkbox
            key={id}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<CheckBoxIcon />}
            edge='start'
            color='primary'
            checked={isChecked}
            tabIndex={-1}
            disableRipple
            onChange={handleSetId}
          />
        )}
      </TableCellStart>

      <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : product.productCode}</TableCellMiddle>
      <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : product.productName}</TableCellMiddle>
      <TableCellMiddle align='center'>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : product.Category ? product.Category.name : '-'}
      </TableCellMiddle>
      <TableCellEnd align='center'>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : product.typeUnit}</TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRowProduct;
