import React, { FC } from 'react';
import { TableBody, TableHead, TableRow, TableCell, TableContainer, makeStyles, Theme } from '@material-ui/core';
import BodyRow from './BodyRow';
import { TableCustom } from 'components/Table';
import Loading from 'components/Loading';
interface Props {
  isLoadingData: boolean;
  salesOrder: SalesOrderModel;
  salesOrderItem: SalesOrderItemModel[];
  tab: number;
  isAccess: string;
  loadingReject: boolean;
  setGetIdClick: React.Dispatch<React.SetStateAction<number>>;
  handleRejectSuperAdmin: (value: ItemAction) => void;
  handleCancelRejectSuperAdmin: (value: ItemAction) => void;
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setHasOpenModal: React.Dispatch<React.SetStateAction<number>>;
  handleOpenImage: (imagePath: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({}));

const ListProduct: FC<Props> = props => {
  const {
    isLoadingData,
    loadingReject,
    salesOrderItem,
    tab,
    isAccess,
    salesOrder,
    setGetIdClick,
    setOpenEdit,
    setHasOpenModal,
    handleCancelRejectSuperAdmin,
    handleOpenImage,
    handleRejectSuperAdmin
  } = props;
  const classes = useStyles();

  return (
    <TableContainer>
      <TableCustom size='small'>
        <TableHead>
          <TableRow>
            <TableCell component={'th'}>Nama Produk</TableCell>
            <TableCell component={'th'}>Harga</TableCell>
            <TableCell component={'th'}>Qty Diambil</TableCell>
            <TableCell component={'th'}>Diskon</TableCell>
            <TableCell component={'th'}>Total Harga</TableCell>
            <TableCell component={'th'}>Gudang</TableCell>
            <TableCell component={'th'} align='center'>
              Aksi
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoadingData ? (
            <TableRow>
              <TableCell align='center' colSpan={7}>
                <Loading size='small' />
              </TableCell>
            </TableRow>
          ) : null}

          {!isLoadingData &&
            salesOrderItem.length > 0 &&
            salesOrderItem.map((value, index) => (
              <BodyRow
                key={index}
                tab={tab}
                handleOpenImage={handleOpenImage}
                loadingReject={loadingReject}
                handleRejectSuperAdmin={handleRejectSuperAdmin}
                isAccess={isAccess}
                salesOrderItem={value}
                setGetIdClick={setGetIdClick}
                setHasOpenModal={setHasOpenModal}
                setOpenEdit={setOpenEdit}
                handleCancelRejectSuperAdmin={handleCancelRejectSuperAdmin}
                salesOrder={salesOrder}
              />
            ))}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default ListProduct;
