import React, { FC } from 'react';
import { format } from 'date-fns';
import { TableCellMiddle, TableCellEnd, TableCellStart } from 'components/Table/TableCellCustom';
import TableRowCustom from 'components/Table/TableRowCustom';
import NumberFormat from 'react-number-format';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Checkbox, Typography, makeStyles, Theme } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

interface Props {
  isLoading: boolean;
  invoicePayment: InvoicePaymentModel;
  checked: number[];
  handleIndividualCheck: (id: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  linkText: {
    textDecorationLine: 'underline',
    cursor: 'pointer'
  }
}));

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { invoicePayment, isLoading, checked, handleIndividualCheck } = props;
  const { id } = invoicePayment;

  const checkedCheckbox = checked.filter(checkedValue => checkedValue === id).length;
  const isChecked = checkedCheckbox === 0 ? false : true;

  const handleLink = (id: Number) => {
    window.open(`/invoice/${id}`);
  };

  return (
    <TableRowCustom>
      <TableCellStart>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <Checkbox
            key={id}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<CheckBoxIcon />}
            edge='start'
            color='primary'
            checked={isChecked}
            tabIndex={-1}
            disableRipple
            disabled={invoicePayment.giroDueDate && new Date(invoicePayment.giroDueDate) > new Date()}
            onChange={event => handleIndividualCheck && handleIndividualCheck(id)}
          />
        )}
      </TableCellStart>
      <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : invoicePayment.giroNumber}</TableCellMiddle>
      <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : invoicePayment.partnerName || '-'}</TableCellMiddle>
      <TableCellMiddle>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          invoicePayment.giroDueDate && format(new Date(invoicePayment.giroDueDate), 'dd MMM yyyy')
        )}
      </TableCellMiddle>
      <TableCellMiddle>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <NumberFormat value={invoicePayment.giroAmount || 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        )}
      </TableCellMiddle>
      <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : invoicePayment.giroBank}</TableCellMiddle>
      <TableCellEnd>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          invoicePayment.Invoice &&
          invoicePayment.Invoice.map((invoice, key) => (
            <Typography variant='body1' className={classes.linkText} onClick={() => invoicePayment.Invoice && handleLink(invoice.id)}>
              {invoicePayment.invoiceNumber}
            </Typography>
          ))
        )}
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <Typography variant='body2'>
            <NumberFormat value={invoicePayment.totalPay || 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          </Typography>
        )}
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRow;
