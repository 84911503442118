import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { TableCellMiddle, TableCellEnd, TableCellStart } from 'components/Table/TableCellCustom';
import TableRowCustom from 'components/Table/TableRowCustom';

interface Props {
  item: MutationItemModel;
  isLoading: boolean;
  index: number;
}

const BodyRow: FC<Props> = props => {
  const { item, isLoading, index } = props;

  return (
    <TableRowCustom key={index + 1}>
      <TableCellStart>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : (item.Product && item.Product.productName) || '-'}
      </TableCellStart>
      <TableCellMiddle>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          (item.Product && item.Product.Category && item.Product.Category.name) || '-'
        )}
      </TableCellMiddle>
      <TableCellEnd align='center'>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : `${item.totalItem} ${item.typeUnit}`}
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRow;
