import { withStyles, createStyles, TableRow } from '@material-ui/core';

const TableRowCustom = withStyles(theme =>
  createStyles({
    root: {
      border: '',
      '&:hover': {
        background: '#F7F9FC'
      }
    }
  })
)(TableRow);

export default TableRowCustom;
