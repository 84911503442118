import React, { FC, Fragment, useState } from 'react';
import { CardMedia, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import NumberFormat from 'react-number-format';
import { BLACK, RED, WHITE, PRIMARY_MOBILE, ORANGE_MOBILE, RED_MOBILE, YELLOW_MOBILE } from 'constants/colors';
import { format } from 'date-fns';
import { ArrowRightAlt, ArrowRightAltRounded, ErrorOutlineRounded, ExpandLessRounded, ExpandMoreRounded } from '@material-ui/icons';
import TypeUser from 'typings/enum/TypeUser';
import { remarkCode } from 'constants/remarkCode';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@material-ui/lab';

interface Props {
  isAccess: string;
  showComponent: boolean;
  isLoading: boolean;
  historyResource: HistoryType[];
}

const useStyles = makeStyles((theme: Theme) => ({
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: ' none'
    }
  },

  gridContainer: {
    [theme.breakpoints.down('md')]: {
      margin: 10 + 'px'
    }
  },

  mobileContainer: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
      '& > .MuiGrid-item': {
        padding: 0
      }
    }
  },

  desktop: {
    [theme.breakpoints.down('md')]: {
      display: ' none'
    }
  },
  container: {
    borderRadius: '0.4rem',
    border: '1px solid #E0E0E0',
    marginBottom: '20px'
  },

  fontMobile: {
    color: BLACK,
    [theme.breakpoints.up('md')]: {
      color: YELLOW_MOBILE,
      fontSize: '0.9rem'
    }
  },
  fontSizeMobile: {
    fontSize: 0.875 + 'rem',
    lineHeight: 1 + 'rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '0.9rem',
      lineHeight: 1.4 + 'rem'
    }
  },
  titleSizeMobile: {
    fontSize: 1 + 'rem',
    lineHeight: 1.5 + 'rem',
    color: BLACK,
    fontWeight: 500
  },

  itemContainer: {
    border: '0.6px solid rgba(0, 0, 0, 0.08)',
    margin: '5px 0px',
    borderRadius: '6px',
    padding: 10 + 'px'
  },

  media: {
    height: 60,
    width: 60,
    display: 'grid',
    placeItems: 'center',
    padding: '0 1.2rem',
    backgroundColor: '#e0e0e0',
    '& .MuiCardMedia-img ': {
      objectFit: 'cover'
    }
  }
}));

const EditingHistory: FC<Props> = props => {
  const { isAccess, showComponent, historyResource, isLoading } = props;

  const classes = useStyles();
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<number>(0);

  const getActionStatus = (remarkCodeItem: string) => {
    if (remarkCodeItem && remarkCodeItem.trimEnd() === remarkCode.REJECT) {
      return 'Telah Ditolak';
    } else if (remarkCodeItem && remarkCodeItem.trimEnd() === remarkCode.EDIT) {
      return 'Telah Diedit';
    } else {
      return 'Telah Diterima';
    }
  };

  return isLoading ? (
    <Grid item xs={12} className={classes.container} style={{ padding: '0 10px' }}>
      <Skeleton variant='text' width='100%' height={30} />
      <Skeleton variant='text' width='100%' height={30} />
      <Skeleton variant='text' width='100%' height={30} />
    </Grid>
  ) : (
    <Grid item xs={12} className={classes.container}>
      {historyResource.map((value, key) => (
        <>
          <Grid
            key={key}
            xs={12}
            container
            style={{ cursor: 'pointer', backgroundColor: key === isOpen ? '#f7f9fc' : '#fff', padding: '10px 20px' }}
            onClick={() => {
              setIsOpen(key);
              setIsExpand(!isExpand);
            }}
          >
            <Grid item xs={3} sm={3} md={3} xl={1} lg={1}>
              <CardMedia
                className={classes.media}
                image={value.productImage}
                title='Image Product'
                //onClick={() => handleOpenImage(salesOrderItem.productImage || '')}
              >
                {!value.productImage && (
                  <Typography variant='h6' style={{ color: '#bdbdbd' }}>
                    No Image
                  </Typography>
                )}
              </CardMedia>
            </Grid>
            <Grid item xs={8} sm={8} md={8} xl={10} lg={10} container alignItems='center'>
              <Typography className={(classes.fontMobile, classes.fontSizeMobile)} style={{ fontWeight: 500, paddingLeft: '5px' }}>
                {value.productName}
              </Typography>
            </Grid>
            <Grid item xs={1} container justify='flex-end' alignItems='center'>
              {!isExpand && key === isOpen ? (
                <ExpandMoreRounded
                  onClick={() => {
                    setIsOpen(key);
                    setIsExpand(true);
                  }}
                />
              ) : (
                <ExpandLessRounded
                  onClick={() => {
                    setIsExpand(false);
                    setIsOpen(key);
                  }}
                />
              )}
            </Grid>
          </Grid>
          {!isExpand && key === isOpen && (
            <Grid item xs={12} container style={{ paddingLeft: '20px' }} key={key}>
              <Grid item xs={12} container justify='flex-start'>
                <Timeline align='left'>
                  {historyResource[key].history.map((value, index) => (
                    <TimelineItem style={{ marginBottom: '1em' }} key={index}>
                      <TimelineOppositeContent style={{ maxWidth: '1px', paddingLeft: '0px', paddingRight: '0px' }} />
                      <TimelineSeparator>
                        <TimelineDot color='primary' />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Grid item xs={12}>
                          <Typography
                            className={(classes.fontMobile, classes.fontSizeMobile)}
                            style={{ color: PRIMARY_MOBILE, marginBottom: '0.5em' }}
                          >
                            {getActionStatus(value.remarkCode)} - {format(new Date(value.createdAt), 'dd MMM yyyy, HH:mm')}
                          </Typography>

                          <Typography className={(classes.fontMobile, classes.fontSizeMobile)} style={{ color: 'lightgray', marginBottom: '0.5em' }}>
                            {value.updateStatus.charAt(0) + value.updateStatus.slice(1).toLowerCase()} {value.editedby}
                          </Typography>

                          <Typography className={(classes.fontMobile, classes.fontSizeMobile)} style={{ display: 'flex', alignItems: 'center ' }}>
                            {value.remark || '-'}{' '}
                            {value.remarkCode.trimEnd() === remarkCode.EDIT && (
                              <>
                                ({value.currentTotal} &#8594; {value.updateTotal})
                              </>
                            )}
                          </Typography>
                        </Grid>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </Grid>
            </Grid>
          )}
        </>
      ))}
    </Grid>
  );
};

export default EditingHistory;
