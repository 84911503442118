import React from 'react';
import { TypeIncomeStatement } from 'pages/IncomeStatementPage';
import { Divider, Grid, makeStyles, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography } from '@material-ui/core';
import { HeaderRow, TableCustom } from 'components';
import { Skeleton } from '@material-ui/lab';
import OperationalBody from './IncomeStatementBody';

interface Props {
  incomeStatement: TypeIncomeStatement;
  handleAction: (isOpen: boolean, id: number, action: 'delete' | 'update' | 'detail') => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  cellTable: {
    width: '20%'
  },
  cellTableSecond: {
    width: '5%'
  },
  container: {
    minHeight: '30vh',
    borderTop: '1px solid rgba(45, 55, 72, 0.12)'
  }
}));
const IncomeStatementTable = ({ incomeStatement, handleAction }: Props) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            headers={[
              { id: 'createdAt', label: 'Tanggal', align: 'left' },
              { id: 'dataPeriode', label: 'Data Periode', align: 'center' },
              { id: 'total', label: 'Laba Rugi', align: 'center' },
              { id: 'action', label: 'Aksi', align: 'right' }
            ]}
          />
        </TableHead>

        <TableBody>
          {incomeStatement.isLoading ? (
            <TableRow>
              <TableCell colSpan={8}>
                <Grid xs={12} container>
                  {[...new Array(5)].map((val, key) => (
                    <Grid xs={12}>
                      <Skeleton variant='text' animation='wave' height={60} key={key} />
                    </Grid>
                  ))}
                </Grid>
              </TableCell>
            </TableRow>
          ) : incomeStatement.data.length > 0 ? (
            incomeStatement.data.map((incomeStatement, key) => <OperationalBody incomeStatement={incomeStatement} handleAction={handleAction} />)
          ) : (
            <TableRow>
              <TableCell colSpan={8} align='center'>
                <Grid xs={12} className={classes.container} container alignItems='center' justify='center'>
                  <Typography variant='h6'>Data Tidak Tersedia.</Typography>
                </Grid>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default IncomeStatementTable;
