import React, { FC, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  makeStyles,
  Theme,
  Typography,
  Tabs,
  Tab,
  Tooltip,
  TableHead,
  TextField
} from '@material-ui/core';
import { DialogTitle } from 'components/Dialog';
import { TabPanel, a11yProps, TableCustom, HeaderRow, TableCellHead } from 'components';

import NumberFormat from 'react-number-format';
import Skeleton from '@material-ui/lab/Skeleton';
import { Close, Refresh } from '@material-ui/icons';
import { WHITE } from 'constants/colors';
import InvoiceTable from 'pages/PartnerDetailPage/components/InvoiceTable';
import useDebounced from 'hooks/useDebounced';
import { INVOICE_BASE_URL, PURCHASE_INVOICE_BASE_URL } from 'constants/url';
import axios from 'axios';
import PaymentPage from './PaymentPage';
import BodyRowPiutang from './BodyRowPiutang';
interface Props {
  partner: PartnerModel;
  isLoadingData: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  controlDiv: {
    '& > :nth-child(n+2)': {
      marginLeft: theme.spacing(2)
    }
  },
  tab: {
    textTransform: 'none'
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3)
  },
  box: {
    backgroundColor: WHITE,
    borderRadius: '5px',
    padding: 24,
    border: '1px solid #F2F2F2',
    boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.08)',
    marginBottom: '10px'
  },
  boxTitle: {
    marginBottom: 8
  },
  cellStart: {
    width: '20%'
  },
  cellTable: {
    width: '17%'
  }
}));

const dummyInvoicesPituang = {
  isLoading: false,
  invoices: []
};

const PartnerModal: FC<Props> = props => {
  const classes = useStyles();
  const { partner, open, isLoadingData, setOpen } = props;
  const [tabs, setTabs] = useState<number>(0);
  const [statusPayment, setStatusPayment] = useState<string>('');
  const [statusReturn, setStatusReturn] = useState<string>('');
  const [invoiceId, setInvoiceId] = useState<string>('');
  const [loadInvoice, setLoadInvoice] = useState<boolean>(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [invoicesPituang, setInvoicesPituang] = useState<{
    isLoading: boolean;
    invoices: Array<Pick<InvoiceModel, 'id' | 'invoiceNumber' | 'dueDate' | 'orderDate' | 'totalPay' | 'totalPrice'> & { status: string }>;
  }>(dummyInvoicesPituang);
  const [orderBy, setOrderBy] = useState<string>('id');
  const [count, setCount] = useState<number>(0);
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);
  const [collapseLoading, setCollapseLoading] = useState(false);
  const tokenSource = axios.CancelToken.source();
  const handleChangeTabs = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabs(newValue);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const fetchInvoice = async () => {
    const getQueryParams = () => {
      const param = new URLSearchParams();

      if (invoiceId) {
        param.append('number', invoiceId);
      }

      if (statusPayment) {
        param.append('status', statusPayment);
      }

      param.append('orderBy', orderBy);
      param.append('ordered', order);
      param.append('page', String(currentPage));
      param.append('partnerId', String(partner.id));
      return param.toString();
    };

    setLoadInvoice(true);
    try {
      const { data } = await axios.get(`${partner.partnerType === 'CUSTOMER' ? INVOICE_BASE_URL : PURCHASE_INVOICE_BASE_URL}?${getQueryParams()}`, {
        cancelToken: tokenSource.token
      });
      setInvoices(data.data);
      setCount(data.meta.last_page);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoadInvoice(false);
    }
  };

  console.log('invoicesPituang.invoices', invoicesPituang.invoices);

  const getData = async (id: number) => {
    setCollapseLoading(true);
    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/${id}`);
      setInvoices(prevState =>
        prevState.map(value => {
          if (value.id === id) {
            value.InvoiceItem = data.data.InvoiceItem;
          }
          return value;
        })
      );
      setCollapseLoading(false);
    } catch (err) {
      console.log('err', err);
    }
  };

  const debounceHits = useDebounced(fetchInvoice, 500);
  const handleOpenCollapse = (index: number, id: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    setOpenCollapse(openCollapse ? (index === indexCollapse ? false : true) : true);
    getData(id);
  };

  const fetchPiutang = async () => {
    setInvoicesPituang(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();

    params.append('zoneId', (partner?.ZoneId ?? 0).toString());
    params.append('partnerId', partner.id.toString());

    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/report-receivables?${params.toString()}`);
      const tempData = data.partners.flat().at(0);
      if (tempData) {
        console.log('Data');
        setInvoicesPituang(prev => ({ ...prev, isLoading: false, invoices: tempData.invoices }));
      }
    } catch (error) {
      console.log('error :', error);
    }
  };

  useEffect(() => {
    debounceHits();
    fetchPiutang();
    return () => tokenSource.cancel('query is cancelled');
  }, [invoiceId, statusPayment, orderBy, currentPage, partner]);

  useEffect(() => {
    fetchPiutang();
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title' fullWidth maxWidth='md'>
      <DialogTitle>
        <Grid xs={12} container justify='space-between' alignItems='center'>
          <Typography variant='h5'>Info Customer</Typography>
          <Close onClick={() => setOpen(false)} style={{ cursor: 'pointer' }} />
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid xs={12}>
          <Tabs
            value={tabs}
            onChange={handleChangeTabs}
            indicatorColor='primary'
            textColor='primary'
            variant='scrollable'
            scrollButtons='auto'
            aria-label='scrollable auto tabs example'
          >
            <Tab label='Informasi Umum' {...a11yProps(0)} className={classes.tab} />
            <Tab label='Invoice' {...a11yProps(1)} className={classes.tab} />
            <Tab label='Pembayaran' {...a11yProps(2)} className={classes.tab} />
            <Tab label='Sisa Piutang' {...a11yProps(3)} className={classes.tab} />
          </Tabs>

          <TabPanel value={tabs} index={0}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2}>Nama</TableCell>
                    <TableCell colSpan={2} align='right'>
                      {isLoadingData ? <Skeleton variant='text' /> : partner.name}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2}>Route</TableCell>
                    <TableCell colSpan={2} align='right'>
                      {isLoadingData ? <Skeleton variant='text' /> : (partner.Zone && partner.Zone.name) || ''}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2}>Plafon</TableCell>
                    <TableCell colSpan={2} align='right'>
                      <NumberFormat value={partner.plafon ? partner.plafon : 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2}>Jatuh Tempo</TableCell>
                    <TableCell colSpan={2} align='right'>
                      {isLoadingData ? <Skeleton variant='text' /> : partner.dueDate}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>Jumlah Terhutang</TableCell>
                    <TableCell colSpan={2} align='right'>
                      <NumberFormat value={partner.totalBill ? partner.totalBill : 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2}>Durasi Pelunasan</TableCell>
                    <TableCell colSpan={2} align='right'>
                      {partner.duration}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={tabs} index={1}>
            <Grid xs={12} container style={{ marginTop: '1em' }}>
              <Grid container direction='row' justify='space-between' className={classes.box}>
                <Grid item lg={4} sm={12} md={4} xs={12}>
                  <Typography align='center' variant='h5' className={classes.boxTitle}>
                    Tipe Mitra
                  </Typography>
                  <Typography align='center' variant='body2'>
                    {partner.partnerType}
                  </Typography>
                </Grid>

                <Grid item lg={4} sm={12} md={4} xs={12}>
                  <Typography align='center' variant='h5' className={classes.boxTitle}>
                    Jumlah Terhutang
                  </Typography>
                  <Typography align='center' variant='body2'>
                    <NumberFormat value={partner.totalBill ? partner.totalBill : 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  </Typography>
                </Grid>

                <Grid item lg={4} sm={12} md={4} xs={12}>
                  <Typography align='center' variant='h5' className={classes.boxTitle}>
                    Jumlah Return
                  </Typography>
                  <Typography align='center' variant='body2'>
                    <NumberFormat value={partner.totalReturn || 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  </Typography>
                </Grid>
              </Grid>

              <Grid xs={12}>
                <InvoiceTable
                  statusPayment={statusPayment}
                  invoiceId={invoiceId}
                  isLoadingData={loadInvoice}
                  count={count}
                  currentPage={currentPage}
                  invoices={invoices}
                  handleClose={handleClose}
                  order={order}
                  orderBy={orderBy}
                  openCollapse={openCollapse}
                  indexCollapse={indexCollapse}
                  setInvoiceId={setInvoiceId}
                  collapseLoading={collapseLoading}
                  setStatusPayment={setStatusPayment}
                  setStatusReturn={setStatusReturn}
                  statusReturn={statusReturn}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  handleOpenCollapse={handleOpenCollapse}
                  setCurrentPage={setCurrentPage}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabs} index={2}>
            <PaymentPage partnerId={partner.id} />
          </TabPanel>
          <TabPanel value={tabs} index={3}>
            <TableContainer>
              <TableCustom>
                <TableHead>
                  <HeaderRow
                    order={order}
                    orderBy={orderBy}
                    headers={[
                      { id: 'paymentNumber', label: 'Tanggal Invoice', sort: true },
                      { id: 'payDate', label: 'Nomor Invoice', sort: true },
                      { id: 'paymentMethod', label: 'Jatuh Tempo', sort: true },
                      { label: 'Sisa', align: 'right' }
                    ]}
                  />
                  {/* <TableRow>
                    <TableCellHead variant='head' className={classes.cellStart}>
                      <TextField id='paymentNumber' placeholder='No Kwitansi' value={code} onChange={event => setCode(event.target.value)} />
                    </TableCellHead>
                    <TableCellHead variant='head' className={classes.cellTable} />

                    <TableCellHead variant='head' className={classes.cellTable}>
                      <TextField
                        select
                        placeholder='Metode Pembayaran'
                        id='paymentMethod'
                        value={paymentMethod}
                        onChange={event => setPaymentMethod(event.target.value)}
                        style={{ width: '100%' }}
                      >
                        <MenuItem key={0} value=''>
                          Semua Metode Pembayaran
                        </MenuItem>
                        <MenuItem key={1} value={PaymentMethod.BANK_TRANSFER}>
                          Bank
                        </MenuItem>
                        <MenuItem key={2} value={PaymentMethod.CASH}>
                          Cash
                        </MenuItem>
                        <MenuItem key={3} value={PaymentMethod.GIRO}>
                          Giro
                        </MenuItem>
                      </TextField>
                    </TableCellHead>

                    <TableCellHead variant='head' className={classes.cellTable}>
                      <TextField
                        select
                        placeholder='Status'
                        id='status'
                        value={status}
                        onChange={event => setStatus(event.target.value)}
                        style={{ width: '100%' }}
                      >
                        <MenuItem key={0} value=''>
                          Status Pembayaran
                        </MenuItem>
                        <MenuItem key={1} value={PaymentStatus.PAID}>
                          Diterima / Sudah Cair
                        </MenuItem>
                        <MenuItem key={2} value={PaymentStatus.UNPAID}>
                          Belum Cair
                        </MenuItem>
                      </TextField>
                    </TableCellHead>
                  </TableRow> */}
                </TableHead>
                <TableBody>
                  {invoicesPituang.isLoading ? (
                    [0, 1, 2, 3].map(index => (
                      <BodyRowPiutang
                        key={index}
                        isLoading={true}
                        invoice={{
                          id: 0,
                          dueDate: new Date(),
                          invoiceNumber: '',
                          orderDate: new Date(),
                          totalPay: 0,
                          status: '',
                          totalPrice: 0
                        }}
                      />
                    ))
                  ) : invoicesPituang.invoices && invoicesPituang.invoices.length > 0 ? (
                    invoicesPituang.invoices.map((invoice, index) => <BodyRowPiutang key={index} isLoading={isLoadingData} invoice={invoice} />)
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align='center'>
                        {'Data Tidak Tersedia.'}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </TableCustom>
            </TableContainer>
            <Grid container xs={12} style={{ columnGap: '4em', marginTop: '1em' }} justify='flex-end' alignItems='center'>
              <Typography variant='h6'>Total Sisa</Typography>
              <Typography variant='h6'>
                <NumberFormat
                  value={invoicesPituang.invoices.reduce((a, v) => {
                    if (v.status.includes('Return')) {
                      return a - (v.totalPrice - v.totalPay);
                    }
                    return a + (v.totalPrice - v.totalPay);
                  }, 0)}
                  prefix={'Rp'}
                  thousandSeparator={true}
                  displayType='text'
                />{' '}
              </Typography>
            </Grid>
          </TabPanel>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PartnerModal;
