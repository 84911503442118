import { Divider, Grid, makeStyles, Theme, Tooltip, Typography, Fade } from '@material-ui/core';
import { ExpandMoreRounded, InfoRounded } from '@material-ui/icons';
import FlexBox from 'components/FlexBox';
import React, { memo } from 'react';
import { TData, TListExpand } from 'pages/PartnerDetailPage/components/PriceHistoryTable';
import { GRAY_3, GREY, PRIMARY_MOBILE, WHITE, BLACK_2 } from 'constants/colors';
import clsx from 'clsx';
import { Skeleton } from '@material-ui/lab';
import monthNames from 'utils/month';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';
import Loading from 'components/Loading';
import PriceHistoryContainer from './PriceHistoryContainer';
const size = {
  small: 0.1,
  medium: 0.15,
  large: 0.25
} as const;

type TTableProperty = {
  key: keyof PriceHistoryDetail;
  title: string;
  size: keyof typeof size;
  align: 'left' | 'center' | 'right';
};

const tableProperties: TTableProperty[] = [
  {
    key: 'createdAt',
    title: 'TANGGAL',
    size: 'medium',
    align: 'left'
  },
  {
    key: 'invoiceNumber',
    title: 'Nomor Invoice',
    size: 'large',
    align: 'left'
  },
  {
    key: 'routePrice',
    title: 'Harga Rute',
    size: 'large',
    align: 'left'
  },
  {
    key: 'discountUnit',
    title: 'Harga Diskon',
    size: 'large',
    align: 'left'
  },
  {
    key: 'subTotalPrice',
    title: 'Harga Jual',
    size: 'large',
    align: 'left'
  }
];

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: '50vh'
  },
  titleTable: {
    whiteSpace: 'pre-wrap' /* or pre-line */,
    color: GRAY_3
  },
  fontSizeNormal: {
    fontSize: '.9rem'
  },
  fontBold: {
    fontWeight: 500
  },
  padding: {
    padding: '1em 1em .8em'
  },

  fontStyle: {
    color: PRIMARY_MOBILE
  },

  borderTop: {
    borderTop: `1px solid ${GREY}`
  },
  itemWrapper: {
    border: `1px solid ${GREY}`,
    borderRadius: '.4em',
    '&:hover': {
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 14%)'
    }
  },

  link: {
    transition: 'all .3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      color: PRIMARY_MOBILE
    }
  },
  linkInvoice: {
    transition: 'all .3s ease-in-out',
    cursor: 'pointer',
    color: PRIMARY_MOBILE,
    '&:hover': {
      color: BLACK_2
    }
  },
  icon: {
    padding: '.15em',
    fontSize: '2rem',
    cursor: 'pointer',
    transition: 'rotate .3s',
    '&:hover': {
      background: GREY,
      borderRadius: '50%'
    }
  },
  tableWrapper: {
    backgroundColor: '#F7F9FC'
  },
  iconRotate: {
    rotate: '180deg'
  },
  skelaton: {
    height: '4em',
    background: GREY
  },
  empty: {
    background: GREY,
    borderRadius: '.4em'
  },
  positionRelative: {
    position: 'relative'
  },
  tooltipWrapper: {
    backgroundColor: WHITE,
    borderRadius: '6px',
    boxShadow: '0px 2px 2px 1px rgb(0 0 0 / 20%)',
    zIndex: 1,
    padding: '1.5em'
  },
  contentInfo: {
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      bottom: 7,
      zIndex: 2,
      width: '1.3em',
      height: '1.3em',
      transform: 'translateX(-50%) rotate(45deg)',
      borderRight: '1px solid #9F9F9F',
      borderBottom: '1px solid #9F9F9F',
      backgroundColor: WHITE
    },
    '& > *': {
      color: '#000'
    }
  },
  pointer: {
    cursor: 'pointer'
  },
  colorGrey: {
    color: '#9F9F9F'
  },
  iconHover: {
    transition: 'all .3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.2)',
      color: '#9F9F9F'
    }
  },
  minHight: {
    minHeight: '25em'
  }
}));
interface Props {
  year: TData<PriceHistory[]>;
  month: TData<PaymentHistoryMonth[]>;
  priceHistoryDetail: TData<PriceHistoryDetail[]>;
  handleListExpand: ({ id, productId, action }: TListExpand) => void;
  monthExpand: { month: string; isOpen: boolean };
  yearExpand: { year: number; productId: number; isOpen: boolean };
}

const PriceHistoryContent = ({ year, month, yearExpand, monthExpand, handleListExpand, priceHistoryDetail }: Props) => {
  const classes = useStyles();
  const dateParsing = (date: string): number => {
    const temp = +date.slice(5) - 1;
    return temp;
  };
  const handleOpenLink = (path: string) => window.open(path);
  return (
    <FlexBox container xs={12} rowGap={1} className={classes.container} alignItems='flex-start' alignContent='flex-start'>
      {year.isLoading ? (
        [...new Array(7)].map(value => <Grid xs={12} className={clsx(classes.skelaton, classes.itemWrapper)} />)
      ) : year.data.length > 0 ? (
        year.data.map(year => (
          <FlexBox xs={12} container key={year.id} className={classes.itemWrapper} alignItems='center'>
            <FlexBox
              container
              alignItems='center'
              columnGap={1}
              className={classes.padding}
              onClick={() =>
                handleListExpand({
                  id: year.id,
                  productId: year.ProductId,
                  action: 'year'
                })
              }
            >
              <ExpandMoreRounded className={clsx(classes.icon, yearExpand.productId === year.ProductId && yearExpand.isOpen && classes.iconRotate)} />
              <Typography
                variant='h6'
                color={yearExpand.year === year.ProductId && yearExpand.isOpen ? 'primary' : 'initial'}
                className={clsx(classes.fontSizeNormal, classes.link)}
              >
                {year.productName}
              </Typography>
            </FlexBox>
            <PriceHistoryContainer isExpand={yearExpand.productId === year.ProductId && yearExpand.isOpen} key={year.ProductId}>
              <FlexBox xs={12} className={clsx(classes.tableWrapper)} container>
                {month.isLoading ? (
                  <FlexBox xs={12} container rowGap={1} className={clsx(classes.padding, classes.borderTop)}>
                    <Grid
                      xs={12}
                      className={clsx(classes.tableWrapper, classes.minHight)}
                      container
                      alignItems='center'
                      justify='center'
                      direction='column'
                    >
                      <Loading />
                      <Typography variant='h6' className={classes.fontSizeNormal}>
                        Sedang Membuka...
                      </Typography>
                    </Grid>
                  </FlexBox>
                ) : month.data.length > 0 ? (
                  month.data.map(date => (
                    <FlexBox xs={12} container key={date.date} className={clsx(classes.borderTop, classes.padding)}>
                      <FlexBox
                        alignItems='center'
                        columnGap={1}
                        container
                        onClick={() =>
                          handleListExpand({
                            id: date.date,
                            productId: year.ProductId,
                            action: 'month'
                          })
                        }
                      >
                        <ExpandMoreRounded
                          className={clsx(classes.icon, monthExpand.month === date.date && monthExpand.isOpen && classes.iconRotate)}
                        />

                        <Typography
                          variant='h6'
                          color={monthExpand.month === date.date && monthExpand.isOpen ? 'primary' : 'initial'}
                          className={clsx(classes.fontSizeNormal, classes.link)}
                        >
                          {`${monthNames[dateParsing(date.date)]} ${new Date(date.date).getFullYear()}`}
                        </Typography>
                      </FlexBox>
                      <PriceHistoryContainer isExpand={monthExpand.month === date.date && monthExpand.isOpen} key={date.date}>
                        <FlexBox container xs={12} rowGap={0.8} className={classes.padding}>
                          <Grid xs={12}>
                            <Divider />
                          </Grid>

                          <FlexBox xs={12} container columnGap={0.3} alignItems='center'>
                            {tableProperties.map(tableProperty => (
                              <Grid key={tableProperty.key} style={{ flex: size[tableProperty.size] }}>
                                <Typography
                                  align={tableProperty.align}
                                  className={clsx(classes.fontSizeNormal, classes.fontBold, classes.titleTable)}
                                >
                                  {tableProperty.title.toLocaleUpperCase()}
                                </Typography>
                              </Grid>
                            ))}
                          </FlexBox>
                          {priceHistoryDetail.isLoading ? (
                            <Grid xs={12}>
                              {[...new Array(5)].map(value => (
                                <Skeleton height={50} />
                              ))}
                            </Grid>
                          ) : (
                            priceHistoryDetail.data.map((priceHistoryDetail, index) => (
                              <FlexBox xs={12} container columnGap={0.3}>
                                {tableProperties.map(tableProperty => (
                                  <Grid key={tableProperty.key} style={{ flex: size[tableProperty.size] }}>
                                    {tableProperty.key === 'createdAt' ? (
                                      <Typography align={tableProperty.align} className={classes.fontSizeNormal}>
                                        {format(new Date(priceHistoryDetail[tableProperty.key]), 'dd MMM yyyy')}
                                      </Typography>
                                    ) : tableProperty.key === 'routePrice' ? (
                                      <Typography align={tableProperty.align} className={classes.fontSizeNormal}>
                                        <NumberFormat
                                          value={priceHistoryDetail[tableProperty.key].toFixed()}
                                          prefix={'Rp'}
                                          thousandSeparator={true}
                                          displayType='text'
                                        />
                                      </Typography>
                                    ) : tableProperty.key === 'discountUnit' ? (
                                      <Typography align={tableProperty.align} className={classes.fontSizeNormal}>
                                        <NumberFormat
                                          value={priceHistoryDetail[tableProperty.key].toFixed()}
                                          prefix={'Rp'}
                                          thousandSeparator={true}
                                          displayType='text'
                                        />
                                      </Typography>
                                    ) : tableProperty.key === 'invoiceNumber' ? (
                                      <Typography
                                        key={`invoiceReturnBill-${index}`}
                                        align={tableProperty.align}
                                        className={(classes.fontSizeNormal, classes.fontStyle, classes.linkInvoice)}
                                        onClick={() => {
                                          handleOpenLink(`/invoice/${priceHistoryDetail.InvoiceId}`);
                                        }}
                                      >
                                        {priceHistoryDetail[tableProperty.key]}
                                      </Typography>
                                    ) : tableProperty.key === 'subTotalPrice' ? (
                                      <Typography align={tableProperty.align} className={classes.fontSizeNormal}>
                                        <NumberFormat
                                          value={priceHistoryDetail[tableProperty.key].toFixed()}
                                          prefix={'Rp'}
                                          thousandSeparator={true}
                                          displayType='text'
                                        />
                                      </Typography>
                                    ) : null}
                                  </Grid>
                                ))}
                              </FlexBox>
                            ))
                          )}
                        </FlexBox>
                      </PriceHistoryContainer>
                    </FlexBox>
                  ))
                ) : (
                  <Grid xs={12} container justify='center' alignItems='center' className={clsx(classes.minHight, classes.borderTop)}>
                    <Typography variant='h6'>Data tidak ada.</Typography>
                  </Grid>
                )}
              </FlexBox>
            </PriceHistoryContainer>
          </FlexBox>
        ))
      ) : (
        <Grid xs={12} container justify='center' alignItems='center' direction='column' className={clsx(classes.minHight)}>
          <Typography>Data Tidak Tersedia.</Typography>
        </Grid>
      )}
    </FlexBox>
  );
};

export default memo(PriceHistoryContent);
