import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { IconButton, Tooltip } from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart, TableRowCustom } from 'components/Table';
import { format } from 'date-fns';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import useRouter from 'hooks/useRouter';

interface Props {
  purchase: StockModel;
  isLoading: boolean;
  onDelete: React.MouseEventHandler;
}

const BodyRow: FC<Props> = props => {
  const { history } = useRouter();
  const { purchase, isLoading, onDelete } = props;

  const handleLink = () => {
    history.push('/stok/detail', { id: purchase.id });
  };
  return (
    <TableRowCustom>
      <TableCellStart onClick={handleLink}>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : format(new Date(purchase.enterDate), 'dd/MM/yyyy')}
      </TableCellStart>

      <TableCellMiddle onClick={handleLink}>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : purchase.purchasesNumber}
      </TableCellMiddle>

      <TableCellMiddle align='center' onClick={handleLink}>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : purchase.totalItem}
      </TableCellMiddle>

      <TableCellMiddle align='center' onClick={handleLink}>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : purchase.WareHouse && purchase.WareHouse.name}
      </TableCellMiddle>

      <TableCellEnd align='right'>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          <>
            <Tooltip title='Hapus'>
              <IconButton size='small' onClick={onDelete}>
                <DeleteOutline fontSize='small' />
              </IconButton>
            </Tooltip>
          </>
        )}
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRow;
