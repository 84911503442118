import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';
import { YELLOW_MOBILE } from 'constants/colors';
import React, { FC } from 'react';

interface Props {
  open: boolean;
  isLoading: boolean;
  handleCloseModalInfo: () => void;
  handleCreate: () => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '2em',
    display: 'flex',
    rowGap: '0.8em',
    flexDirection: 'column'
  },
  button: {
    borderRadius: '5px',
    padding: '7px 0'
  }
}));
const ModalInfo: FC<Props> = ({ open, isLoading, handleCloseModalInfo, handleCreate }) => {
  const classes = useStyles();
  return (
    <Dialog open={open}>
      <Grid xs={12} className={classes.container}>
        <Grid item container direction='column' alignItems='center' xs={12}>
          <WarningRounded style={{ color: YELLOW_MOBILE, fontSize: '3.5rem' }} />
          <Typography variant='h4'>Konfirmasi Surat Jalan</Typography>
        </Grid>

        <Grid xs={12} container justify='center'>
          <Grid xs={10}>
            <Typography align='center' variant='h6'>
              Apkah anda yakin ingin membuat surat jalan?
            </Typography>
          </Grid>
        </Grid>

        <Grid xs={12} container justify='center'>
          <Grid xs={12} container style={{ columnGap: '1.5em' }} justify='center'>
            <Grid xs={5}>
              <Button size='small' fullWidth className={classes.button} color='primary' variant='outlined' onClick={handleCloseModalInfo}>
                Batalkan
              </Button>
            </Grid>
            <Grid xs={5}>
              <Button size='small' fullWidth className={classes.button} color='primary' disabled={isLoading} onClick={handleCreate}>
                {isLoading ? <CircularProgress size={22} color='primary' /> : 'Buat SJ'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ModalInfo;
