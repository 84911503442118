import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import { makeStyles, Theme, Grid, Tabs, Tab, Dialog } from '@material-ui/core';
import { USER_BASE_URL, SALES_ROUTE_BASE_URL } from '../../../../constants/url';
import { isValidEmail } from 'utils';
import useRole from 'hooks/useRole';
import { TabPanel, a11yProps } from 'components';
import TypeUser from 'typings/enum/TypeUser';
import EditUserForm from './components/EditUserForm';
import RouteForm from './components/RouteForm';
import WareHouseList from './components/WareHouseList';
import { CurrentUserContext } from 'contexts/CurrentUserContext';

interface Props {
  user: UserDetailsModel;
  open: boolean;
  zone: ZoneModel[];
  warehouses: WareHouseModel[];
  roles: RoleModel[];
  setUsers: React.Dispatch<React.SetStateAction<UserDetailsModel[]>>;
  handleCancel(): void;
  updateIndividualUser: (updatedUserProperties: Partial<UserDetailsModel>) => void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    borderRadius: 4
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const EditUserModal: React.FC<Props> = props => {
  const classes = useStyles();

  const { user, open, handleCancel, setOpenSnackbar, handleSnackBar, updateIndividualUser, zone, roles } = props;

  const [isLoading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [contactNumber, setContactNumber] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [contactNumberError, setContactNumberError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordMessage, setPasswordMessage] = useState<string>('');
  const [value, setValue] = useState<number>(0);
  const [type, setType] = useState<string>(TypeUser.SALES);
  const [typeError, setTypeError] = useState<string>('');
  const [salesRoute, setSalesRoute] = useState<SalesRoute[]>([]);
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN]
  });

  const handleChangeValue = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const resetFormValues = useCallback(() => {
    if (!user) {
      return;
    }

    const { email, firstName, lastName, contactNumber, SalesRoute, type } = user;

    setEmail(email);
    setFirstName(firstName);
    setLastName(lastName);
    setContactNumber(contactNumber);
    setType(type);
    setSalesRoute(SalesRoute && SalesRoute.length > 0 ? SalesRoute : []);
  }, [user]);

  useEffect(() => {
    resetFormValues();
    clearFormErrors();
  }, [open, resetFormValues]);

  const clearFormErrors = () => {
    setEmailError('');
    setLastNameError('');
    setContactNumberError('');
    setTypeError('');
  };

  // This is to ensure that the form vale and erors are reset/cleared when user canceled the editing
  const handleOnClose = () => {
    resetFormValues();
    clearFormErrors();
    handleCancel();
  };

  const validateForm = () => {
    let ret = true;
    clearFormErrors();

    if (!email || !email.trim()) {
      setEmailError('Please enter email');
      ret = false;
    } else if (!isValidEmail(email)) {
      setEmailError('Please enter an valid email');
      ret = false;
    }

    if (!firstName || !firstName.trim()) {
      setFirstNameError('Please enter username');
      ret = false;
    }

    if (!lastName || !lastName.trim()) {
      setLastNameError('Please enter lastName');
      ret = false;
    }

    if (!contactNumber || !contactNumber.trim()) {
      setContactNumberError('Please enter Contact Number ');
      ret = false;
    }

    if (contactNumber && contactNumber.length < 10) {
      setContactNumberError('Contact Number Min 10 Character');
      ret = false;
    }

    if (!type || !type.trim()) {
      setTypeError('Tipe pengguna tidak boleh kosong');
      ret = false;
    }

    return ret;
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);

    try {
      const { data } = await axios.post(USER_BASE_URL, {
        id: user.id,
        firstName,
        email,
        contactNumber,
        lastName,
        typeUser: type
      });

      updateIndividualUser(data.data);
      setOpenSnackbar(true);
      handleCancel();
      setValue(0);
      handleSnackBar(true, 'success', 'User berhasil diperbaharui');
    } catch (err) {
      setOpenSnackbar(true);
      handleSnackBar(true, 'error', 'User gagal diperbaharui.');
    } finally {
      setLoading(false);
    }
  };

  const handleChangeRoute = (ZoneId: number) => {
    setSalesRoute(prevState => {
      if (prevState.some(value => value.ZoneId === ZoneId)) {
        return prevState.filter(value => value.ZoneId !== ZoneId);
      } else {
        return [
          ...prevState,
          {
            SalesId: user.id,
            ZoneId
          }
        ];
      }
    });
  };

  const handleOnRouteSubmit = async () => {
    setLoading(true);

    try {
      const items = salesRoute.map(route => {
        delete route.Zone;
        return route;
      });

      const { data } = await axios.post(SALES_ROUTE_BASE_URL, {
        SalesId: user.id,
        items
      });

      const token = await localStorage.getItem('token');

      if (currentUser && currentUser.id === user.id) {
        setCurrentUser({ ...currentUser!, SalesRoute: data.data }, String(token));
      }

      props.setUsers(prev => {
        return prev.map(value => {
          if (value.id === user.id) {
            return { ...value, SalesRoute: data.data };
          }
          return { ...value };
        });
      });

      handleSnackBar(true, 'success', 'Berhasil mengubah rute sales');
      setOpenSnackbar(true);
      handleCancel();
      setValue(0);
    } catch (err) {
      setOpenSnackbar(true);
      handleSnackBar(true, 'error', 'Berhasil mengubah rute sales');
    } finally {
      setLoading(false);
    }
  };

  const handleOnWareHouseSubmit = async (UserId: number, WareHouseId: number) => {
    try {
      if (user && user.WareHouseId && user.WareHouseId.some(val => val === WareHouseId)) {
        const { data } = await axios.post(`${USER_BASE_URL}/warehouse/remove`, {
          UserId,
          WareHouseId
        });

        props.setUsers(prevState =>
          prevState.map(value => {
            if (value.id === UserId) {
              value.WareHouseId = value.WareHouseId!.filter(val => val !== WareHouseId);
            }
            return value;
          })
        );
      } else {
        const { data } = await axios.post(`${USER_BASE_URL}/warehouse`, {
          UserId,
          WareHouseId
        });

        props.setUsers(prevState =>
          prevState.map(value => {
            if (value.id === UserId) {
              value.WareHouseId = [...value.WareHouseId!, data.data.WareHouseId];
            }
            return value;
          })
        );
      }

      handleSnackBar(true, 'success', 'Berhasil merubah gudang');
      setOpenSnackbar(true);
    } catch (err) {
      setOpenSnackbar(true);
      handleSnackBar(true, 'error', 'Berhasil merubah');
    } finally {
    }
  };

  const handleClose = () => {
    handleCancel();
    clearFormErrors();
    setSalesRoute([]);
    setValue(0);
    handleCancel();
  };

  return (
    <Dialog open={open} onClose={handleClose} scroll={'body'} aria-labelledby='scroll-dialog-title' aria-describedby='scroll-dialog-description'>
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} direction='row' className={classes.paper}>
        <Grid item>
          <Tabs value={value} indicatorColor='primary' onChange={handleChangeValue} aria-label='disabled tabs example'>
            <Tab label='Informasi' {...a11yProps(0)} />
            <Tab label='Rute' {...a11yProps(1)} />
            <Tab label='Gudang' {...a11yProps(2)} />
          </Tabs>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TabPanel value={value} index={0}>
            <EditUserForm
              okLabel='Edit'
              email={email}
              setEmail={setEmail}
              emailError={emailError}
              firstName={firstName}
              setFirstName={setFirstName}
              firstNameError={firstNameError}
              lastName={lastName}
              setLastName={setLastName}
              lastNameError={lastNameError}
              contactNumber={contactNumber}
              setContactNumber={setContactNumber}
              contactNumberError={contactNumberError}
              isSubmitting={isLoading}
              onSubmit={handleOnSubmit}
              onCancel={handleOnClose}
              password={password}
              setPassword={setPassword}
              passwordMessage={passwordMessage}
              type={type}
              setType={setType}
              typeError={typeError}
              roles={roles}
            />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <RouteForm
              salesRoute={salesRoute}
              zone={zone}
              isSuperAdmin={isSuperAdmin}
              handleChangeRoute={handleChangeRoute}
              handleOnSubmit={handleOnRouteSubmit}
              isLoading={isLoading}
              handleOnCancel={handleClose}
            />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <WareHouseList
              warehouses={props.warehouses}
              userId={(user && user.id) || 0}
              currentWarehouse={(user && user.WareHouseId) || []}
              handleOnSubmit={handleOnWareHouseSubmit}
            />
          </TabPanel>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default EditUserModal;
