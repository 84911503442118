enum TypeUnit {
  Unit = 'Unit',
  Buah = 'Buah',
  Pasang = 'Pasang',
  Lembar = 'Lembar',
  Keping = 'Keping',
  Batang = 'Batang',
  Bungkus = 'Bungkus',
  Butir = 'Butir',
  Roll = 'Roll',
  Meter = 'Meter',
  Dus = 'Dus',
  Pcs = 'Pcs',
  Set = 'Set',
  Paket = 'Paket',
  Kotak = 'Kotak',
  Krat = 'Krat',
  Kaleng = 'Kaleng'
}

export default TypeUnit;
