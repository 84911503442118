import React, { FC, useEffect, useState, useContext } from 'react';
import { Grid, Container, Typography, Button, Tooltip, makeStyles } from '@material-ui/core';
import { AddBox } from '@material-ui/icons';
import { Page, StandardConfirmationDialog, PaperCustom, Breadcrumb, FormPinDialog } from 'components';
import { PURCHASE_INVOICE_BASE_URL, GET_PURCHASE_INVOICE_ITEMS_BASE_URL } from 'constants/url';
import { dummyPurchaseInvoice } from 'utils/dummy';
import { format, startOfMonth } from 'date-fns';
import { GREEN, WHITE, BLUE_PRIMARY } from 'constants/colors';
import axios, { CancelTokenSource } from 'axios';

import PurchaseInvoiceTable from './components/PurchaseInvoiceTable';
import Pagination from '@material-ui/lab/Pagination';
import useRouter from 'hooks/useRouter';
import useConfirmationPin from 'hooks/useConfirmationPin';
import RefreshIcon from '@material-ui/icons/Refresh';
import DateRangeFilter from 'components/DateRangeFilter';
import useDebounced from 'hooks/useDebounced';
import CalendarIcon from '@material-ui/icons/EventNote';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import useRole from 'hooks/useRole';
import TypeUser from 'typings/enum/TypeUser';
import PaginationCustom from 'components/PaginationCustom';

const useStyles = makeStyles({
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  }
});

const PurchaseInvoicePage: FC = () => {
  const classes = useStyles();
  const { history } = useRouter();

  const [count, setCount] = useState<number>(0);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [purchaseInvoices, setPurchaseInvoices] = useState<PurchaseInvoiceModel[]>([dummyPurchaseInvoice]);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [selectedId, setSelectedId] = useState<number>();
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [invoiceId, setInvoiceId] = useState<string>('');
  const [statusPayment, setStatusPayment] = useState<string>('');
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);
  const [collapseLoading, setCollapseLoading] = useState<boolean>(false);
  const [statusInvoice, setStatusInvoice] = useState<string>('CONFIRMATION');
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [pin, setPin] = useState<number | null>(null);
  const [isComplete, setComplete] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [from, setFrom] = useState<number>(0);

  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);

  let cancelToken: CancelTokenSource = axios.CancelToken.source();
  const { currentUser } = useContext(CurrentUserContext);

  const isNotAllowCreate = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ACCOUNTING]
  });
  const pinMessage = useConfirmationPin({
    pin,
    confirmationDelete: confirmationDelete,
    isCompletePin: isComplete,
    handleOnDelete: deleteInvoice,
    setOpenConfirmation: setOpenConfirmation,
    setLoading: setLoadingDelete,
    setCompletePin: setComplete,
    setPin: setPin
  });

  const fetchData = async () => {
    setIsLoadingData(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (name) {
        params.append('partnerName', name);
      }

      if (statusPayment) {
        params.append('status', statusPayment);
      }

      if (invoiceId) {
        params.append('keyword', invoiceId);
      }

      params.append('isDeleted', statusInvoice.includes('CANCEL').toString());

      params.append('startDate', startDate);
      params.append('endDate', endDate);

      params.append('orderBy', orderBy);
      params.append('ordered', order);
      params.append('page', currentPage.toString());
      return params.toString();
    };

    try {
      const { data } = await axios.get(`${PURCHASE_INVOICE_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelToken.token });
      setPurchaseInvoices(data.data);
      setCount(data.meta.last_page);
      setCurrentPage(data.meta.current_page);
      setTotal(data.meta.total);
      setTo(data.meta.to);
      setFrom(data.meta.from);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const onRefresh = () => {
    setOrderBy('id');
    setOrder('desc');
    setName('');
    setInvoiceId('');
    setCurrentPage(1);
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleConfirmSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleConfirmationDelete = (id: number): React.MouseEventHandler => () => {
    setSelectedId(id);
    setConfirmationDelete(true);
  };

  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  async function deleteInvoice() {
    try {
      await axios.delete(`${PURCHASE_INVOICE_BASE_URL}/${selectedId}`);
      setPurchaseInvoices(purchaseInvoices.filter(value => value.id !== selectedId));
      handleSnackBar(true, 'success', 'Hapus Invoice pembelian berhasil.');
    } catch (err) {
      const error = err as { data: { message: string } };

      handleSnackBar(true, 'error', 'Hapus Invoice pembelian gagal.');
      if (error.data.message) {
        handleSnackBar(true, 'error', error.data.message);
      }
    } finally {
      setConfirmationDelete(false);
    }
  }

  const fetchItems = async (id: number) => {
    setCollapseLoading(true);
    try {
      const { data } = await axios.get(GET_PURCHASE_INVOICE_ITEMS_BASE_URL(id));
      setPurchaseInvoices(prevState =>
        prevState.map(value => {
          if (value.id === id) {
            value.PurchaseInvoiceItem = data.data;
          }
          return value;
        })
      );
    } catch (err) {
      console.log(err);
    } finally {
      setCollapseLoading(false);
    }
  };

  const handleCreateInvoice = () => {
    history.push('/invoice-pembelian/tambah');
  };

  const handleOpenCollapse = (index: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    setOpenCollapse(openCollapse ? (index === indexCollapse ? false : true) : true);
    fetchItems(index);
  };

  const debouncedFetchHits = useDebounced(fetchData, 500);

  useEffect(() => {
    debouncedFetchHits();
    return () => cancelToken.cancel('No longer latest query');
  }, [orderBy, order, currentPage, name, invoiceId, startDate, endDate, statusInvoice]);

  useEffect(() => {
    if (!name && !invoiceId) {
      return;
    }

    setCurrentPage(1);
  }, [name, invoiceId]);

  return (
    <Page title='Invoice Pembelian'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1' component='h1'>
              Pembelian
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>
        <PaperCustom>
          <Grid container direction='row' alignItems='center' item lg={12} md={12} sm={12} xs={12} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={6} xs={6}>
              <Tooltip title='Refresh'>
                <Button onClick={onRefresh} className={classes.refresh}>
                  <RefreshIcon />
                </Button>
              </Tooltip>
            </Grid>

            <Grid item xl={5} lg={5} md={5} sm={6} xs={6}>
              <Typography>{`Menampilkan ${total || 0} invoice  pembelian (${from || 0} - ${to || 0} dari ${total || 0})`}</Typography>
              {startDate && endDate && (
                <Typography variant='body2' style={{ fontSize: 12, color: BLUE_PRIMARY }}>
                  Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                </Typography>
              )}
            </Grid>

            <Grid item container justify='flex-end' alignItems='center' spacing={1} xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid item>
                <Tooltip title='Calendar filter' placement='top'>
                  <Button color='inherit' onClick={() => setOpenCalendarFilter(true)}>
                    <CalendarIcon />
                  </Button>
                </Tooltip>
              </Grid>

              {!isNotAllowCreate && (
                <Grid item>
                  <Button onClick={handleCreateInvoice} disabled={isLoadingData}>
                    <AddBox fontSize='small' /> &nbsp; Buat Invoice Baru
                  </Button>
                </Grid>
              )}
            </Grid>

            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <PurchaseInvoiceTable
                statusPayment={statusPayment}
                purchaseInvoiceId={invoiceId}
                name={name}
                isLoadingData={isLoadingData}
                count={count}
                currentPage={currentPage}
                isNotAllowCreate={isNotAllowCreate}
                purchaseInvoices={purchaseInvoices}
                order={order}
                orderBy={orderBy}
                openCollapse={openCollapse}
                indexCollapse={indexCollapse}
                statusInvoice={statusInvoice}
                setStatusInvoice={setStatusInvoice}
                collapseLoading={collapseLoading}
                setName={setName}
                setInvoiceId={setInvoiceId}
                setStatusPayment={setStatusPayment}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                handleConfirmationDelete={handleConfirmationDelete}
                handleOpenCollapse={handleOpenCollapse}
              />
            </Grid>

            <Grid container item sm={12} xs={12} justify='flex-end'>
              <PaginationCustom
                marginTop='-.4em '
                show={purchaseInvoices.length > 0}
                sxPagination={{
                  count,
                  boundaryCount: 2,
                  variant: 'outlined',
                  shape: 'rounded',
                  onChange: (event, page) => {
                    setCurrentPage(page);
                  },
                  page: currentPage
                }}
                sxPopover={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  }
                }}
                customPageProps={{
                  defaultValue: currentPage,
                  maxValue: count,
                  onSubmit(value) {
                    setCurrentPage(value);
                  }
                }}
              />
            </Grid>
          </Grid>
        </PaperCustom>
        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
          noCancelButton={true}
        />
        <StandardConfirmationDialog
          variant={'danger'}
          titleMessage={'Delete'}
          message={'Apakah kamu yakin menghapus data ini?'}
          open={confirmationDelete}
          handleClose={handleCloseConfirmationDelete}
          onConfirm={handleOpenConfirmation}
          isLoading={loadingDelete}
        />
        <FormPinDialog
          open={openConfirmation}
          pinMessage={pinMessage}
          isComplete={isComplete}
          setComplete={setComplete}
          setPin={setPin}
          handleClose={handleCloseConfirmation}
        />

        <DateRangeFilter
          openCalendarFilter={openCalendarFilter}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleClose={() => {
            setOpenCalendarFilter(false);
          }}
        />
      </Container>
    </Page>
  );
};

export default PurchaseInvoicePage;
