import React, { FC } from 'react';
import { TableRow, TableCell, TextField, makeStyles } from '@material-ui/core';
import NumberFormatMask from 'components/NumberFormatMask';
import NumberFormat from 'react-number-format';

interface Props {
  item: PurchaseInvoiceItemModel;
  handleSetValue: (value: PurchaseInvoiceItemModel) => void;
}

const useStyles = makeStyles({
  cellProduct: { width: '25%' },
  cellQty: { width: '13%' }
});

const PurchaseInvoiceItem: FC<Props> = props => {
  const classes = useStyles();
  const { item, handleSetValue } = props;

  return (
    <TableRow>
      <TableCell className={classes.cellProduct}>{(item.Product && item.Product.productName) || ''}</TableCell>

      <TableCell>
        <TextField
          variant='standard'
          size='small'
          required
          fullWidth
          label='Harga'
          value={item.price > 0 ? item.price : null}
          onChange={event => handleSetValue({ ...item, price: +event.target.value })}
          InputProps={{
            inputComponent: NumberFormatMask as any
          }}
          error={item.price === 0}
          helperText={(item.price === 0 && 'Harga tidak boleh kosong') || ''}
        />
      </TableCell>

      <TableCell className={classes.cellQty}>{`${item.totalItem} ${item.typeUnit}`}</TableCell>

      <TableCell align='right'>
        <TextField
          fullWidth
          variant='standard'
          size='small'
          label='Diskon'
          value={item.discount > 0 ? item.discount : null}
          onChange={event => handleSetValue({ ...item, discount: +event.target.value })}
          InputProps={{
            inputComponent: NumberFormatMask as any
          }}
        />
      </TableCell>

      <TableCell>
        <NumberFormat value={item.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
      </TableCell>

      <TableCell>
        <NumberFormat value={item.unitNetPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
      </TableCell>
    </TableRow>
  );
};

export default PurchaseInvoiceItem;
