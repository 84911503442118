import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom } from 'components';
import React, { FC } from 'react';
import HasPrint from 'images/icon/PrintIndicator3.png';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { format } from 'date-fns';
const useStyles = makeStyles((theme: Theme) => ({
  cellContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: '0.5em'
  }
}));
interface Props {
  handleLink: React.MouseEventHandler;
  deliveryOrder: DeliveryOrders;
}
const BodyTable: FC<Props> = (props): JSX.Element => {
  const { deliveryOrder, handleLink } = props;
  const classes = useStyles();
  return (
    <TableRowCustom key={deliveryOrder.id} onClick={handleLink} style={{ cursor: 'pointer' }}>
      <TableCellStart>#{deliveryOrder.deliveryNumber}</TableCellStart>
      <TableCellMiddle>{format(new Date(deliveryOrder.createdAt), 'dd MMM yyyy')}</TableCellMiddle>
      <TableCellMiddle>{deliveryOrder.partnerName}</TableCellMiddle>
      <TableCellMiddle>{deliveryOrder.zoneName}</TableCellMiddle>
      <TableCellMiddle>{`${deliveryOrder.totalKoli} Koli`}</TableCellMiddle>
      <TableCellMiddle>{deliveryOrder.totalInvoice}</TableCellMiddle>
      <TableCellEnd>
        <Grid xs={12} className={classes.cellContainer}>
          {deliveryOrder.salesName}

          {deliveryOrder.printCount > 0 && <img src={HasPrint} alt='image' width={50} height={40} />}
        </Grid>
      </TableCellEnd>
    </TableRowCustom>
  );
};
export default BodyTable;
