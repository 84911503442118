import React, { FC, useEffect, useState } from 'react';
import { Grid, Container, Typography, Button, Tooltip, IconButton, makeStyles, Theme } from '@material-ui/core';
import { Page, StandardConfirmationDialog, PaperCustom, Breadcrumb } from 'components';
import { AddBox } from '@material-ui/icons';
import { STOCK_BASE_URL, WAREHOUSE_BASE_URL, DELETE_STOCK_DETAIL_BASE_URL } from 'constants/url';
import { dummyStock, dummyWareHouse } from 'utils/dummy';
import { format, startOfMonth } from 'date-fns';
import axios, { CancelTokenSource } from 'axios';
import StockTable from './components/StockTable';
import StockModal from './components/StockModal';
import Pagination from '@material-ui/lab/Pagination';
import DateRangeFilter from 'components/DateRangeFilter';
import CalendarIcon from '@material-ui/icons/EventNote';
import RefreshIcon from '@material-ui/icons/Refresh';
import { BLUE_PRIMARY, GREEN, WHITE } from 'constants/colors';
import _ from 'lodash';
import PaginationCustom from 'components/PaginationCustom';

const useStyles = makeStyles((theme: Theme) => ({
  calendarIcon: {
    fontSize: 30,
    color: theme.palette.primary.main
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  }
}));

const StockInPage: FC = () => {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [purchases, setPurchases] = useState<StockModel[]>([dummyStock]);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [enterDate, setEnterDate] = useState<Date | null>(new Date());
  const [purchaseNumber, setPurchaseNumber] = useState<string>('');
  const [selectedId, setSelectedId] = useState<number>(0);
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [warehouses, setWarehouses] = useState<WareHouseModel[]>([dummyWareHouse]);
  const [warehouse, setWarehouse] = useState<WareHouseModel>(dummyWareHouse);
  const [loadWarehouse, setLoadWarehouse] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [from, setFrom] = useState<number>(0);

  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

  const fetchData = async () => {
    setIsLoadingData(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (purchaseNumber) {
        params.append('keyword', purchaseNumber);
      }

      if (warehouse && warehouse.id > 0) {
        params.append('WareHouseId', warehouse.id.toString());
      }

      if (startDate || endDate) {
        params.append('startDate', startDate);
        params.append('endDate', endDate);
      }

      if (orderBy || order) {
        params.append('orderBy', orderBy);
        params.append('ordered', order);
      }

      params.append('page', currentPage.toString());
      return params.toString();
    };

    try {
      const { data } = await axios.get(`${STOCK_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });
      setPurchases(data.data);
      setCount(data.meta.last_page);
      setCurrentPage(data.meta.current_page);
      setTotal(data.meta.total);
      setTo(data.meta.to);
      setFrom(data.meta.from);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleConfirmationDelete = (id: number): React.MouseEventHandler => () => {
    setSelectedId(id);
    setConfirmationDelete(true);
  };

  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleOpenForm = (): void => {
    setOpen(true);
  };

  const handleSearchWarehouse = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    setLoadWarehouse(true);
    try {
      const { data } = await axios.get(`${WAREHOUSE_BASE_URL}?${params.toString()}`);
      setWarehouses(data.data);
      setLoadWarehouse(false);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadWarehouse(false);
    }
  };

  const handleCalendarFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenCalendarFilter(!openCalendarFilter);
  };

  const deleteStock = async () => {
    try {
      await axios.delete(DELETE_STOCK_DETAIL_BASE_URL(selectedId));
      setPurchases(prevState => prevState.filter(value => value.id !== selectedId));
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmationDelete(false);
    }
  };

  useEffect(() => {
    const debouncedFetchHits = _.debounce(fetchData, 500);
    debouncedFetchHits();
    return () => cancelTokenSource.cancel('No longer latest query');
  }, [orderBy, order, enterDate, purchaseNumber, currentPage, warehouse, startDate, endDate]);

  return (
    <Page title='Stok Masuk'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Stok Masuk </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid container direction='row' spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={6} xs={6}>
              <Tooltip title='Memuat Ulang'>
                <Button onClick={() => fetchData()} color='inherit' className={classes.refresh}>
                  <RefreshIcon />
                </Button>
              </Tooltip>
            </Grid>

            <Grid container alignItems='center' item xl={8} lg={8} md={8} sm={6} xs={6}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography>{`Menampilkan ${total || 0} Stok Masuk (${from || 0} - ${to || 0} dari ${total || 0})`}</Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                {startDate && endDate && (
                  <Typography variant='body2' style={{ fontSize: 12, color: BLUE_PRIMARY }}>
                    Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid container alignItems='center' justify='flex-end' item xl={3} lg={3} md={3} sm={6} xs={6}>
              <Tooltip title='Calendar filter' placement='top'>
                <IconButton size='small' onClick={event => handleCalendarFilterClick(event)}>
                  <CalendarIcon className={classes.calendarIcon} />
                </IconButton>
              </Tooltip>

              <Button disabled={isLoadingData} onClick={handleOpenForm}>
                <AddBox /> &nbsp; Tambah Stok
              </Button>
            </Grid>
            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <StockTable
                isLoadingData={isLoadingData}
                purchases={purchases}
                order={order}
                orderBy={orderBy}
                enterDate={enterDate}
                setEnterDate={setEnterDate}
                purchaseNumber={purchaseNumber}
                setPurchaseNumber={setPurchaseNumber}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                handleConfirmationDelete={handleConfirmationDelete}
                warehouse={warehouse}
                warehouses={warehouses}
                setWarehouse={setWarehouse}
                loadWarehouse={loadWarehouse}
                handleSearchWarehouse={handleSearchWarehouse}
              />
            </Grid>

            <Grid container justify='flex-end' item xl={12} md={12} sm={12}>
              <PaginationCustom
                marginTop='-.4em '
                show={purchases.length > 0}
                sxPagination={{
                  count,
                  boundaryCount: 2,
                  variant: 'outlined',
                  shape: 'rounded',
                  onChange: (event, page) => {
                    setCurrentPage(page);
                  },
                  page: currentPage
                }}
                sxPopover={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  }
                }}
                customPageProps={{
                  defaultValue: currentPage,
                  maxValue: count,
                  onSubmit(value) {
                    setCurrentPage(value);
                  }
                }}
              />
            </Grid>
          </Grid>
        </PaperCustom>

        <StockModal purchases={purchases} open={open} setOpen={setOpen} setPurchases={setPurchases} handleSnackBar={handleSnackBar} />
        <DateRangeFilter
          openCalendarFilter={openCalendarFilter}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleClose={() => {
            setOpenCalendarFilter(false);
          }}
        />
        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
          onConfirm={handleCloseSnackbar}
          noCancelButton={true}
        />

        <StandardConfirmationDialog
          variant={'danger'}
          titleMessage={'Hapus'}
          message={'Apakah kamu yakin menghapus data ini?'}
          open={confirmationDelete}
          handleClose={handleCloseConfirmationDelete}
          onConfirm={deleteStock}
        />
      </Container>
    </Page>
  );
};

export default StockInPage;
