import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { ExpandLessRounded, ExpandMoreRounded, Height } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import React, { FC, useState } from 'react';
interface Props {
  isLoading: boolean;
  deliveryOrderItem: DeliveryOrderItem[];
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: '2em 0'
  },
  containerBody: {
    border: '1px solid #E0E0E0',
    borderRadius: '5px',
    padding: '1.7em 0',
    cursor: 'pointer',
    transition: 'all 1s ease',
    '&:hover': {
      boxShadow: '0 2px 5px rgb(0 0 0 / 14%)'
    }
  },
  containerProduct: {
    padding: '1em 0',
    borderRadius: '5px',
    backgroundColor: '#F7F9FC',
    border: '1px solid #E0E0E0',
    rowGap: '0.5em'
  },
  wrapProduct: {
    flex: '0.95',
    borderBottom: '1px solid #E0E0E0'
  },
  image: {
    border: '1px solid #E0E0E0',
    cursor: 'pointer',
    borderRadius: '5px',
    objectFit: 'cover',
    height: '60px',
    width: '60px',
    transition: 'transform .2s ease',
    '&:hover': {
      objectFit: 'contain',
      borderRadius: '5px',
      border: 'none',
      boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
      transform: 'scale(3)',
      transformOrigin: 'left center'
    }
  },
  expandAnimation: {
    animation: `$expandEffect 300ms linear`
  },
  '@keyframes expandEffect': {
    '0%': {
      transform: 'scaleX(0)'
    },
    '50%': {
      transform: 'scaleX(0.5)'
    },
    '100%': {
      transform: 'scaleX(1)'
    }
  }
}));
const DeliveryOrderTable: FC<Props> = ({ isLoading, deliveryOrderItem }) => {
  const classes = useStyles();
  const [expand, setExpand] = useState<boolean>(true);
  const [keyClicked, setKeyClicked] = useState<number>(0);

  const handleOpen = (key: number) => {
    setExpand(key === keyClicked ? !expand : true);
    setKeyClicked(key);
  };

  const handleClose = () => {
    setExpand(false);
  };
  return (
    <Grid xs={12}>
      <Grid className={classes.container} xs={12} container>
        <Grid xs={1} />
        <Grid xs={2}>
          <Typography color='textSecondary'>Nama Produk</Typography>
        </Grid>
        <Grid xs={3}>
          <Typography color='textSecondary'>Nama Barang</Typography>
        </Grid>
        <Grid xs={1}>
          <Typography color='textSecondary' align='center'>
            Total Qty
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Typography color='textSecondary' align='center'>
            Total Item
          </Typography>
        </Grid>
        <Grid xs={3}>
          <Typography color='textSecondary'>Nomor Invoice</Typography>
        </Grid>
      </Grid>
      <Grid xs={12} container style={{ rowGap: '1em' }}>
        {isLoading ? (
          <Grid xs={12}>
            {[...new Array(3)].map(val => (
              <Skeleton height={70} key={val} />
            ))}
          </Grid>
        ) : (
          deliveryOrderItem.map((deliveryOrderItem, key) => (
            <Grid xs={12}>
              <Grid
                className={classes.containerBody}
                xs={12}
                container
                alignItems='center'
                onClick={() => {
                  handleOpen(key);
                }}
                style={{ backgroundColor: keyClicked === key ? '#46BCEB' : '#FFF' }}
              >
                <Grid xs={1} container justify='center'>
                  {expand && keyClicked === key ? (
                    <ExpandLessRounded style={{ cursor: 'pointer' }} onClick={handleClose} />
                  ) : (
                    <ExpandMoreRounded style={{ cursor: 'pointer' }} onClick={() => handleOpen(key)} />
                  )}
                </Grid>
                <Grid xs={2} container>
                  <Grid xs={8}>
                    <Typography align='justify' variant='h6'>
                      {deliveryOrderItem.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs={3}>
                  <Typography>{deliveryOrderItem.packName}</Typography>
                </Grid>
                <Grid xs={1}>
                  <Typography align='center'>{deliveryOrderItem.totalQty}</Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography align='center'>{deliveryOrderItem.totalItem} Item</Typography>
                </Grid>
                <Grid xs={3} container direction='column'>
                  {deliveryOrderItem.invoice.map(invoice => (
                    <Typography variant='h6'>#{invoice.InvoiceNumber}</Typography>
                  ))}
                </Grid>
              </Grid>
              {expand && keyClicked === key && (
                <Grid xs={12} container className={classes.expandAnimation}>
                  <Grid xs={12} container style={{ margin: '1em 0' }}>
                    <Grid xs={6}>
                      <Typography variant='h6'>Nama Produk</Typography>
                    </Grid>
                    <Grid xs={1}>
                      <Typography variant='h6' align='center'>
                        Qty Diambil
                      </Typography>
                    </Grid>
                    <Grid xs={5}>
                      <Grid xs={5}>
                        <Typography variant='h6' align='center'>
                          Gudang
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid xs={12} container className={classes.containerProduct} alignItems='center'>
                    {deliveryOrderItem.items.map((items, key) => (
                      <Grid xs={12} container alignItems='center'>
                        <Grid xs={6} container style={{ columnGap: '1.5em', paddingLeft: '1.5em' }}>
                          <Grid xs={1}>
                            <img src={items.productImage} alt='Foto Produk' className={classes.image} />
                          </Grid>
                          <Grid xs={10}>
                            <Typography variant='h6'>{items.productName}</Typography>
                          </Grid>
                        </Grid>
                        <Grid xs={1}>
                          <Typography align='center'>{`${items.totalItem} ${items.typeUnit}`} </Typography>
                        </Grid>

                        <Grid xs={5} container>
                          <Grid xs={5}>
                            <Typography align='center'>{items.wareHouseName}</Typography>
                          </Grid>
                          <Grid xs={7}>
                            <Typography variant='h6'>#{items.InvoiceNumber}</Typography>
                          </Grid>
                        </Grid>

                        {key !== deliveryOrderItem.items.length - 1 && (
                          <Grid xs={12} container justify='center' style={{ margin: '1em 0' }}>
                            <Grid className={classes.wrapProduct} />
                          </Grid>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
            </Grid>
          ))
        )}
      </Grid>
    </Grid>
  );
};

export default DeliveryOrderTable;
