import React, { FC, useReducer, useState, useEffect, Fragment } from 'react';
import {
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Divider,
  TableContainer,
  makeStyles,
  Theme,
  Grid,
  CardMedia,
  Typography,
  Modal
} from '@material-ui/core';
import { Create, ExpandLess, ExpandMore } from '@material-ui/icons';

interface Props {
  collyResource: typeCollyModel[];
  tempCollyFinalUpdate: (id: number) => void;
  showComponent: boolean;
  setGetIdClick: React.Dispatch<React.SetStateAction<number>>;
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setHasOpenModal: React.Dispatch<React.SetStateAction<number>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '90vw'
    },
    [theme.breakpoints.down('xs')]: {
      width: '87vw',
      marginRight: theme.spacing(1)
    }
  },
  coliItemMobile: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: '1.5em'
    }
  },
  containerHeader: {
    margin: theme.spacing(2),
    display: 'flex'
  },
  itemContainer: {
    border: '0.6px solid rgba(0, 0, 0, 0.08)',
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyItems: 'center',
    padding: theme.spacing(2),
    borderRadius: '6px',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    }
  },
  titleHeader: {
    fontSize: '1rem',
    lineHeight: '1.7rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
      lineHeight: '1rem'
    }
  },
  titleHeaderBold: {
    fontWeight: 500
  },
  spacingItem: {
    margin: '0.5em 0'
  }
}));
const ColiItem: React.FC<Props> = props => {
  const { showComponent, setOpenEdit, setHasOpenModal, collyResource, setGetIdClick, tempCollyFinalUpdate } = props;
  const classes = useStyles();
  const [showColi, setShowColi] = useState<boolean>(false);
  const [clicked, setClicked] = useState<number>(0);
  const handleEvent = (id: number) => {
    setHasOpenModal(15);
    setOpenEdit(true);
    setGetIdClick(id);
    tempCollyFinalUpdate(id);
  };

  return (
    <Grid className={classes.container} xs={12} md={12} sm={12} lg={12} xl={12}>
      {collyResource.map((value, key: number) => (
        <Fragment>
          <Grid container item xs={12} md={12} sm={12} lg={12} xl={12} alignContent='center' className={classes.itemContainer}>
            <Grid xs={1} item container alignItems='center'>
              {showColi && key === clicked ? (
                <ExpandLess onClick={() => setShowColi(prevShow => false)} style={{ cursor: 'pointer' }} />
              ) : (
                <ExpandMore
                  onClick={() => {
                    setClicked(key);
                    setShowColi(prevShow => true);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              )}
            </Grid>
            <Grid xs={3} item container alignItems='center'>
              <Typography className={classes.titleHeader}>{value.name}</Typography>
            </Grid>
            <Grid xs={8} item container alignItems='center'>
              <Typography className={classes.titleHeader} style={{ flexGrow: 1 }}>
                {value.packName}
              </Typography>
              <Create style={{ cursor: 'pointer' }} color='primary' fontSize='small' onClick={() => handleEvent(value.id)} />
            </Grid>
          </Grid>

          {showColi && key === clicked && (
            <Grid container item xs={12} md={12} sm={12} lg={12} xl={12} alignContent='center' className={classes.itemContainer}>
              <Grid xs={9} lg={7} md={9} xl={9} sm={9}>
                <Typography className={(classes.titleHeader, classes.titleHeaderBold)}>Nama Produk</Typography>
              </Grid>
              <Grid xs={3} lg={5} md={3} xl={5} sm={3} className={classes.coliItemMobile}>
                <Typography className={(classes.titleHeader, classes.titleHeaderBold)}>
                  {showComponent ? 'Qty' : 'Alat - Alat Listrik Campur'}
                </Typography>
              </Grid>
              {value.BagItem.map((val, key) => (
                <Fragment>
                  <Grid container xs={12} alignItems='center'>
                    <Grid xs={9} sm={9} lg={7} md={9} xl={7}>
                      <Typography className={classes.titleHeader}>{val.productName}</Typography>
                    </Grid>
                    <Grid xs={3} lg={5} md={3} xl={5} sm={3} className={classes.coliItemMobile}>
                      <Typography className={classes.titleHeader}>{val.totalItem} </Typography>
                    </Grid>
                  </Grid>
                  {showComponent && (
                    <Grid xs={12}>
                      <Divider className={classes.spacingItem} />
                    </Grid>
                  )}
                </Fragment>
              ))}
              <Grid container xs={12} alignItems='center'>
                <Grid xs={9} sm={9} lg={7} md={9} xl={7}>
                  <Typography className={(classes.titleHeader, classes.titleHeaderBold)} style={{ marginRight: '0.8em' }} align='right'>
                    Total
                  </Typography>
                </Grid>
                <Grid xs={3} lg={5} md={3} xl={5} sm={3} className={classes.coliItemMobile}>
                  <Typography className={classes.titleHeader}>{value.totalItem} </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Fragment>
      ))}
    </Grid>
  );
};

export default ColiItem;
