import React, { FC } from 'react';
import { TableBody, TableHead, TableRow, TableCell, TableContainer } from '@material-ui/core';
import BodyRow from './components/BodyRow';
import { TableCustom } from 'components/Table';

interface Props {
  isLoadingData: boolean;
  invoiceItem: PurchaseInvoiceItemModel[];
}

const ListProduct: FC<Props> = props => {
  const { isLoadingData, invoiceItem } = props;

  return (
    <TableContainer>
      <TableCustom size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Nama Produk</TableCell>
            <TableCell align='right'>Harga</TableCell>
            <TableCell align='center'>Kuantitas</TableCell>
            <TableCell align='right'>Diskon</TableCell>
            <TableCell align='right'>Total Harga</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceItem &&
            invoiceItem.length > 0 &&
            invoiceItem.map((value, index) => <BodyRow key={index + 1} isLoading={isLoadingData} invoiceItem={value} />)}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default ListProduct;
