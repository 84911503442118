import React, { FC, useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Grid, Container, Typography, Button, MenuItem, TextField, CircularProgress, FormHelperText } from '@material-ui/core';
import { Page, StandardConfirmationDialog, PaperCustom, Breadcrumb } from 'components';
import { dummyCommissiom } from 'utils/dummy';
import { COMMISSION_BASE_URL, INVOICE_BASE_URL, GET_COMMISSION_DETAIL_BASE_URL } from 'constants/url';
import { months, convertMonth } from 'utils';
import NumberFormatMask from 'components/NumberFormatMask';
import CommissionStatus from 'typings/enum/CommissionStatus';
import useRouter from 'hooks/useRouter';
import InvoiceTable from './components/InvoiceTable';

const CommissionCreatePage: FC = () => {
  const { history, location } = useRouter();
  // eslint-disable-next-line
  const param: any = location.state;

  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);
  const [statusPayment, setStatusPayment] = useState<string>('');
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);
  const [invoiceId, setInvoiceId] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [totalCommission, setTotalCommission] = useState<number>();
  const [totalCommissionMessage, setTotalCommissionMessage] = useState<string>('');
  const [month, setMonth] = useState<number>(0);
  const [monthMessage, setMonthMessage] = useState<string>('');
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalInvoiceMessage, setTotalInvoiceMessage] = useState<string>('');
  const [loadInvoice, setLoadInvoice] = useState<boolean>(false);
  const [totalInvoice, setTotalInvoice] = useState<number>(0);
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [statusCommission, setStatusCommission] = useState<string>('');
  const [statusCommissionMessage, setStatusCommissionMessage] = useState<string>('');
  const [commissions, setCommissions] = useState<CommissionModel[]>([]);
  const [commission, setCommission] = useState<CommissionModel>(dummyCommissiom);
  const [totalRetur, setTotalRetur] = useState<number>(0);
  const [totalNet, setTotalNet] = useState<number>(0);
  const [totalPay, setTotalPay] = useState<number>(0);

  let currentYear = new Date().getFullYear();

  const fetchCurrentCommission = async () => {
    try {
      const { data } = await axios.get(GET_COMMISSION_DETAIL_BASE_URL(param.id));
      setCommission(data.data);
      setMonth(data.data.monthNumber);
      setYear(data.data.year);
      setTotalCommission(data.data.totalCommission);
      setStatusCommission(data.data.statusCommission);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const fetchCommission = async () => {
    const params = new URLSearchParams();
    params.append('salesId', String(param.salesId));
    params.append('year', currentYear.toString());
    try {
      const { data } = await axios.get(`${COMMISSION_BASE_URL}?${params.toString()}`);
      setCommissions(data.data);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const fetchInvoice = useCallback(async () => {
    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (month) {
        params.append('month', String(month));
      }

      if (year) {
        params.append('year', String(year));
      }

      if (statusPayment) {
        params.append('status', statusPayment);
      }

      if (invoiceId) {
        params.append('id', String(invoiceId));
      }

      params.append('orderBy', 'id');
      params.append('ordered', 'desc');
      params.append('page', String(currentPage));
      params.append('salesId', String(param.salesId));
      params.append('withPayment', 'true');
      params.append('full', 'true');
      return params.toString();
    };

    setLoadInvoice(true);

    try {
      setTotalPrice(0);
      const { data } = await axios.get(`${INVOICE_BASE_URL}?${getQueryParams()}`);
      setInvoices(data.data);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoadInvoice(false);
    }
  }, [orderBy, order, currentPage, param, month, year, statusPayment, invoiceId]);

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
    history.push('/komisi/detail', { id: param.salesId });
  };

  const handleOpenCollapse = (index: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    setOpenCollapse(openCollapse ? (index === indexCollapse ? false : true) : true);
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const validation = () => {
    let valid = true;

    if (month === 0 || !month) {
      setMonthMessage('Bulan tidak boleh kosong.');
      valid = false;
    }

    if (totalCommission === 0 || !totalCommission) {
      setTotalCommissionMessage('Total komisi tidak boleh kosong.');
      valid = false;
    }

    if (totalInvoice === 0) {
      setTotalInvoiceMessage('Invoice tidak boleh kosong.');
      valid = false;
    }

    if (statusCommission === '' || !statusCommission) {
      setStatusCommissionMessage('Termin pelunasan tida boleh kosong.');
    }

    return valid;
  };

  const reset = () => {
    setTotalPrice(0);
    setMonth(0);
    setTotalCommission(0);
    setStatusCommission('');
  };

  const resetValidation = () => {
    setMonthMessage('');
    setTotalInvoiceMessage('');
    setStatusCommissionMessage('');
    setTotalCommissionMessage('');
  };

  const handleOnSubmit = async () => {
    resetValidation();
    setSubmit(true);

    if (!validation()) {
      setSubmit(false);
      return;
    }

    try {
      await axios.post(COMMISSION_BASE_URL, {
        id: commission && commission.id > 0 ? commission.id : 0,
        month: convertMonth(month),
        monthNumber: month,
        year,
        SalesId: param.salesId,
        totalInvoice,
        totalBill: totalPrice,
        totalCommission,
        statusCommission
      });
      reset();
      handleSnackBar(true, 'success', 'Komisi berhasil dibuat');
    } catch (error) {
      console.log('error :', error);
      handleSnackBar(true, 'error', 'Komisi gagal dibuat');
    } finally {
      setSubmit(false);
    }
  };

  useEffect(() => {
    if (invoices.length === 0) {
      setTotalPrice(0);
      setTotalRetur(0);
      setTotalPay(0);
      setTotalNet(0);
      setTotalInvoice(0);
      return;
    }

    setTotalPrice(invoices.map(value => value.totalPrice || 0).reduce((a, b) => a + b) || 0);
    setTotalRetur(invoices.map(value => value.totalPiece || 0).reduce((a, b) => a + b));
    setTotalPay(invoices.map(value => value.totalPay || 0).reduce((a, b) => a + b));
    setTotalNet(totalPrice);
    setTotalInvoice(invoices.length);
  }, [invoices, month]);

  useEffect(() => {
    fetchInvoice();
  }, [fetchInvoice]);

  useEffect(() => {
    fetchCommission();
  }, []);

  useEffect(() => {
    if (!param && param.id < 1) {
      return;
    }

    fetchCurrentCommission();
  }, []);

  return (
    <Page title='Komisi'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Komisi </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid container direction='row' spacing={2}>
            <Grid item container justify='flex-end' xl={12} lg={12} md={12} sm={12} xs={12}>
              <Button disabled={isSubmit} onClick={handleOnSubmit}>
                {isSubmit ? <CircularProgress color='inherit' size={20} /> : 'Simpan Data'}
              </Button>
            </Grid>

            <Grid container direction='row' justify='center' spacing={1} item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  id='dueDate'
                  required
                  fullWidth
                  label='Pilih Bulan'
                  placeholder='Jatuh Tempo Invoice'
                  value={month}
                  error={monthMessage !== ''}
                  helperText={monthMessage}
                  onChange={e => setMonth(+e.target.value)}
                  select
                >
                  <MenuItem key={0} selected>
                    Pilih Bulan
                  </MenuItem>

                  {months.map((value, index) => (
                    <MenuItem disabled={commissions.some(value => value.monthNumber === index + 1)} value={index + 1} key={index + 2}>
                      {value} - {currentYear}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  id='statusCommission'
                  select
                  required
                  label='Termin Pelunasan'
                  fullWidth
                  value={statusCommission}
                  onChange={e => setStatusCommission(e.target.value as string)}
                  error={statusCommissionMessage !== ''}
                  helperText={statusCommissionMessage}
                >
                  <MenuItem key={1} selected>
                    Pilih Status
                  </MenuItem>
                  {Object.entries(CommissionStatus).map(([key, value], index) => (
                    <MenuItem key={index + 2} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  id='totalTagihan'
                  fullWidth
                  label='Total Tagihan '
                  value={totalPrice}
                  InputProps={{
                    inputComponent: NumberFormatMask as any
                  }}
                  disabled
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <TextField
                  id='total Komisi'
                  required
                  fullWidth
                  label='Masukkan Total Komisi '
                  value={totalCommission}
                  onChange={event => setTotalCommission(+event.target.value)}
                  error={totalCommissionMessage !== ''}
                  helperText={totalCommissionMessage}
                  InputProps={{
                    inputComponent: NumberFormatMask as any
                  }}
                />
              </Grid>
            </Grid>

            <Grid container direction='row' item xl={12} lg={12} md={12} sm={12} xs={12}>
              <FormHelperText error={totalInvoiceMessage !== ''}>{totalInvoiceMessage}</FormHelperText>
              <InvoiceTable
                statusPayment={statusPayment}
                invoiceId={invoiceId}
                isLoadingData={loadInvoice}
                count={count}
                currentPage={currentPage}
                invoices={invoices}
                order={order}
                orderBy={orderBy}
                openCollapse={openCollapse}
                indexCollapse={indexCollapse}
                totalPrice={totalPrice}
                totalRetur={totalRetur}
                totalNet={totalNet}
                totalPay={totalPay}
                setInvoiceId={setInvoiceId}
                setStatusPayment={setStatusPayment}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                handleOpenCollapse={handleOpenCollapse}
              />
            </Grid>

            <StandardConfirmationDialog
              variant={snackbarVariant}
              titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
              message={message}
              open={openSnackbar}
              handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
              onConfirm={handleConfirmSnackbar}
              noCancelButton={true}
            />
          </Grid>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default CommissionCreatePage;
