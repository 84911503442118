import { GREEN, WHITE } from 'constants/colors';
import React, { memo, ReactNode } from 'react';
import { Button, CircularProgress, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import FlexBox from 'components/FlexBox';
import { RefreshOutlined } from '@material-ui/icons';

const useStyles = makeStyles({
  container: {
    rowGap: '1em'
  },
  refreshButton: {
    backgroundColor: GREEN,
    color: WHITE,
    padding: '.4em',
    borderRadius: '5px',
    transition: 'all .3s',
    '&:hover': {
      transform: 'scale(1.2)',
      backgroundColor: '#27ae60'
    }
  }
});
interface Props {
  titleNode: ReactNode;
  onClick: { handleRefresh: () => void };
}
const PaymentHistoryHeader = ({ titleNode, onClick }: Props) => {
  const classes = useStyles();
  return (
    <Grid xs={12} container className={classes.container}>
      <Grid xs={6}>
        <FlexBox container columnGap={1} alignItems='center' alignContent='flex-start' xs={12}>
          <IconButton aria-label='refresh' className={classes.refreshButton} disableRipple={false} size='small' onClick={onClick.handleRefresh}>
            <RefreshOutlined />
          </IconButton>
          {titleNode}
        </FlexBox>
      </Grid> 
    </Grid>
  );
};

/* Exporting the component. */
export default PaymentHistoryHeader;
