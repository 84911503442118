import { Button, CircularProgress, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { RefreshOutlined } from '@material-ui/icons';
import FlexBox from 'components/FlexBox';
import { GREEN, WHITE } from 'constants/colors';
import React, { memo, ReactNode } from 'react';

const useStyles = makeStyles({
  container: {
    rowGap: '1em'
  },
  refreshButton: {
    backgroundColor: GREEN,
    color: WHITE,
    padding: '.4em',
    borderRadius: '5px',
    transition: 'all .3s',
    '&:hover': {
      transform: 'scale(1.2)',
      backgroundColor: '#27ae60'
    }
  }
});

/* Defining the props that the component will receive. */
interface Props {
  titleNode: ReactNode;
  onClick: { isLoadingDownload: boolean; handleDownload: () => void; handleRefresh: () => void };
}

/* A function that takes in two parameters, titleNode and onClick. */
const StockMovementHeader = ({ titleNode, onClick }: Props) => {
  const classes = useStyles();
  return (
    <Grid xs={12} container className={classes.container}>
      <Grid xs={6}>
        <FlexBox container columnGap={1} alignItems='center' alignContent='flex-start' xs={12}>
          <IconButton aria-label='refresh' className={classes.refreshButton} disableRipple={false} size='small' onClick={onClick.handleRefresh}>
            <RefreshOutlined />
          </IconButton>
          {titleNode}
        </FlexBox>
      </Grid>
      <Grid xs={6} container justify='flex-end' alignContent='center'>
        <Button
          variant='contained'
          onClick={onClick.handleDownload}
          color='primary'
          disabled={onClick.isLoadingDownload}
          startIcon={
            <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M1.5 16C1.1 16 0.75 15.85 0.45 15.55C0.15 15.25 0 14.9 0 14.5V10.925H1.5V14.5H14.5V10.925H16V14.5C16 14.9 15.85 15.25 15.55 15.55C15.25 15.85 14.9 16 14.5 16H1.5ZM8 12.175L3.175 7.35L4.25 6.275L7.25 9.275V0H8.75V9.275L11.75 6.275L12.825 7.35L8 12.175Z'
                fill='white'
              />
            </svg>
          }
        >
          {onClick.isLoadingDownload ? <CircularProgress color='primary' size={20} /> : 'Download Csv'}
        </Button>
      </Grid>
    </Grid>
  );
};

/* Exporting the component. */
export default StockMovementHeader;
