import { Badge, Button, Container, Grid, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import { AddBox, ArrowBack, GetAppRounded, NotificationsActiveOutlined, Today } from '@material-ui/icons';
import Refresh from '@material-ui/icons/Refresh';
import { Pagination } from '@material-ui/lab';

import axios, { CancelTokenSource } from 'axios';
import { Breadcrumb, Page, PaperCustom } from 'components';
import DateRangeFilter from 'components/DateRangeFilter';
import { GREEN_MOBILE, WHITE } from 'constants/colors';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { DELIVERY_ORDER_BASE_URL, DELIVERY_ORDER_NOTIF, RETURN_BILL_BASE_URL, ZONE_BASE_URL } from 'constants/url';
import { format, startOfMonth } from 'date-fns';
import useDebounced from 'hooks/useDebounced';
import useRouter from 'hooks/useRouter';
import React, { useContext, useEffect, useState } from 'react';
import { dummyMetaData } from 'utils/dummy';
import TypeUser from 'typings/enum/TypeUser';
import ReturnBillContent from './components/ReturnBillContent';
import PaginationCustom from 'components/PaginationCustom';

const useStyles = makeStyles((theme: Theme) => ({
  refresh: {
    backgroundColor: GREEN_MOBILE,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN_MOBILE,
      transform: 'scale(1.1)'
    }
  }
}));
const ReturnBillPage = () => {
  const classes = useStyles();
  const { history } = useRouter();
  let cancelToken: CancelTokenSource = axios.CancelToken.source();
  const [returnBills, setReturnBills] = useState<ReturnBill[]>([]);
  const [medaData, setMedaData] = useState<MetaData>(dummyMetaData);
  const [returnBillNumber, setReturnBillNumber] = useState('');
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);
  const [zone, setZone] = useState<number[]>([]);
  const [statusPayment, setStatusPayment] = useState<string>('UNPAID');
  const [salesName, setSalesName] = useState<string>('');
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [partnerName, setPartnerName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingItem, setIsLoadingItem] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [zones, setZones] = useState<ZoneModel[]>([]);

  const handleRefresh = () => {
    setCurrentPage(1);
    setPartnerName('');
    setSalesName('');
    setReturnBillNumber('');
    setZone([]);
  };

  const handleCalendarFilterClick = () => setOpenCalendarFilter(!openCalendarFilter);

  const fecthReturnBill = async () => {
    setIsLoading(true);
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (returnBillNumber) {
        params.append('returnBillNumber', returnBillNumber);
      }

      if (partnerName) {
        params.append('partnerName', partnerName);
      }

      params.append('status', statusPayment);

      if (salesName) {
        params.append('salesName', salesName);
      }

      if (zone.length > 0) {
        params.append('Zone', zone.join(',') || '');
      }

      params.append('page', currentPage.toString());

      params.append('startDate', startDate);
      params.append('endDate', endDate);

      return params;
    };
    try {
      const { data } = await axios.get(`${RETURN_BILL_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelToken.token });
      setMedaData(data.meta);
      setReturnBills(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fecthReturnBillItem = async (id: number) => {
    setIsLoadingItem(true);
    try {
      const { data } = await axios.get(`${RETURN_BILL_BASE_URL}/${id}`);
      setReturnBills(prevState =>
        prevState.map(value => {
          if (value.id === id) {
            value.InvoiceItem = data.data.InvoiceItem;
          }
          return value;
        })
      );
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoadingItem(false);
    }
  };
  const handleOpenCollapse = (index: number, id: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    setOpenCollapse(openCollapse ? (index === indexCollapse ? false : true) : true);
    fecthReturnBillItem(id);
  };

  const debouncedFetchHits = useDebounced(fecthReturnBill, 500);

  useEffect(() => {
    debouncedFetchHits();
    return () => cancelToken.cancel('No longer latest query');
  }, [currentPage, zone, partnerName, salesName, returnBillNumber, startDate, endDate, statusPayment]);

  const fecthZone = async () => {
    const params = new URLSearchParams();
    params.append('orderBy', 'name');
    params.append('ordered', 'asc');
    params.append('perPage', '1000');
    try {
      const { data } = await axios.get(`${ZONE_BASE_URL}?${params.toString()}`);
      setZones(data.data);
    } catch (error) {
      console.log('error :', error);
    }
  };

  useEffect(() => {
    fecthZone();
  }, []);

  return (
    <Page title='Tagihan Return'>
      <Container>
        <Grid xs={12}>
          <Typography variant='h1' component='h1'>
            Tagihan Return
          </Typography>
          <Breadcrumb />
        </Grid>

        <PaperCustom>
          <Grid xs={12} container style={{ rowGap: '1em' }}>
            <Grid xs={12} container alignItems='center'>
              <Grid xs={7} container style={{ columnGap: '1em' }} alignItems='center'>
                <Grid item>
                  <Tooltip title='Memuat Ulang'>
                    <Button size='small' onClick={handleRefresh} color='inherit' className={classes.refresh}>
                      <Refresh fontSize='small' />
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant='h6'>
                    {`Menampilkan ${medaData.total || 0} Invoice Tagihan Return (${medaData.from || 0} - ${medaData.to || 0} dari ${medaData.total ||
                      0})`}
                  </Typography>

                  <Typography color='primary'>
                    Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={5} container justify='flex-end' style={{ columnGap: '1em' }}>
                <Tooltip title='Filter' placement='top'>
                  <Button variant='contained' onClick={handleCalendarFilterClick} color='default'>
                    <Today />
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>

            <ReturnBillContent
              zone={zone}
              setZone={setZone}
              zones={zones}
              returnBills={returnBills}
              isLoading={isLoading}
              returnBillNumber={returnBillNumber}
              setReturnBillNumber={setReturnBillNumber}
              partnerName={partnerName}
              setPartnerName={setPartnerName}
              indexCollapse={indexCollapse}
              isLoadingItem={isLoadingItem}
              openCollapse={openCollapse}
              handleOpenCollapse={handleOpenCollapse}
              statusPayment={statusPayment}
              setStatusPayment={setStatusPayment}
            />

            <Grid container item sm={12} xs={12} justify='flex-end'>
              <PaginationCustom
                marginTop='-.4em '
                show={returnBills.length > 0}
                sxPagination={{
                  count: medaData.last_page,
                  boundaryCount: 2,
                  variant: 'outlined',
                  shape: 'rounded',
                  onChange: (event, page) => {
                    setCurrentPage(page);
                  },
                  page: currentPage
                }}
                sxPopover={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  }
                }}
                customPageProps={{
                  defaultValue: currentPage,
                  maxValue: medaData.last_page,
                  onSubmit(value) {
                    setCurrentPage(value);
                  }
                }}
              />
            </Grid>
          </Grid>
          <DateRangeFilter
            openCalendarFilter={openCalendarFilter}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            handleClose={() => setOpenCalendarFilter(false)}
          />
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default ReturnBillPage;
