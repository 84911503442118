import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  LinearProgress,
  makeStyles,
  Modal,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { FC } from 'react';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em',
    padding: '1em'
  },
  titleContainer: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)'
  },
  containerBox: {
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    borderRadius: '5px',
    marginTop: '0.5em'
  }
}));
interface Props {
  open: boolean;
  category: string;
  categories: CategoryModel[];
  isLoadingCategories: boolean;
  isLoadingPrint: boolean;
  handleDownload: () => void;
  handleCloseDownload: () => void;
  setCategory: React.Dispatch<React.SetStateAction<string>>;
}
const ModalCatalog: FC<Props> = ({
  open,
  handleCloseDownload,
  categories,
  handleDownload,
  isLoadingCategories,
  isLoadingPrint,
  category,
  setCategory
}) => {
  const classes = useStyles();
  return (
    <Dialog open={open}>
      <Grid xs={12} container className={classes.container}>
        <Grid xs={12} container justify='space-between' alignItems='center' className={classes.titleContainer}>
          <Typography variant='h5'>Katalog Produk</Typography>
          <Close style={{ cursor: 'pointer' }} onClick={handleCloseDownload} />
        </Grid>
        <Grid xs={12}>
          <Autocomplete
            inputValue={category}
            onInputChange={(event, newInputValue) => {
              setCategory(newInputValue);
            }}
            loading={isLoadingCategories}
            loadingText={<LinearProgress />}
            disableListWrap
            options={categories.map(val => val.name)}
            PaperComponent={({ children }) => <Grid className={classes.containerBox}>{children}</Grid>}
            renderInput={params => <TextField {...params} label='Pilih Katagori' variant='outlined' />}
          />
        </Grid>
        <Grid xs={12}>
          <Button
            variant='contained'
            fullWidth
            color='primary'
            style={{ borderRadius: '20px' }}
            disabled={isLoadingPrint || category === ''}
            onClick={handleDownload}
          >
            {isLoadingPrint ? <CircularProgress size={20} color='primary' /> : 'Download Katalog'}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ModalCatalog;
