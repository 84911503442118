import React, { FC, Fragment, useContext } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  IconButton,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom, StatusPayment, TableRowCollapse } from 'components';
import { format } from 'date-fns';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import NumberFormat from 'react-number-format';
import Skeleton from '@material-ui/lab/Skeleton';
import useRouter from 'hooks/useRouter';
import TimerOffTwoToneIcon from '@material-ui/icons/TimerOffTwoTone';
import ErrorIcon from '@material-ui/icons/Error';
import useRole from 'hooks/useRole';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import TypeUser from 'typings/enum/TypeUser';
import { CONFIRMATION_COLOR, PENDING_COLOR, RED, WHITE } from 'constants/colors';
import clsx from 'clsx';
interface Props {
  invoice: InvoiceModel;
  isLoading: boolean;
  openCollapse: boolean;
  collapseLoading: boolean;
  handleClose?: () => void;
  index: number;
  indexCollapse: number;
  handleOpenCollapse: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCollapse: {
      width: '100%'
    },
    cellCollapse: {
      border: 'none'
    },
    timeOut: {
      animation: 'shake 0.5s',
      animationIterationCount: 'infinite'
    },
    statusReturn: {
      padding: '5px 12px 5px 12px',
      borderRadius: '100px',
      fontFamily: 'Rubik',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '22px',
      color: WHITE
    },
    bgYelow: {
      background: PENDING_COLOR
    },
    bgRed: {
      background: RED
    },
    bgBlue: {
      background: CONFIRMATION_COLOR
    },
    tableCellAction: {
      width: 30
    }
  })
);

const BodyRow: FC<Props> = props => {
  const { invoice, isLoading, openCollapse, index, indexCollapse, handleOpenCollapse, handleClose, collapseLoading } = props;
  const { history } = useRouter();
  const classes = useStyles();
  const { currentUser } = useContext(CurrentUserContext);
  const isHR = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.HR]
  });

  const handleLink = () => {
    if (isHR) return;
    if (invoice.Partner && invoice.Partner.partnerType === 'SUPPLIER') {
      history.push(`/invoice-pembelian/${invoice.id}`);
    } else {
      if (invoice.type && invoice.type === 'ReturnCustomer') {
        history.push(`/return-penjualan/detail`, {
          id: invoice.id
        });
      } else {
        history.push(`/invoice/${invoice.id}`);
      }
    }
    handleClose && handleClose();
  };

  return (
    <Fragment>
      <TableRowCustom>
        <TableCellStart>
          {isLoading ? (
            <Skeleton variant='text' width={60} height={25} />
          ) : (
            <IconButton aria-label='expand row' size='small' onClick={handleOpenCollapse}>
              {openCollapse && index === indexCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCellStart>
        <TableCellMiddle onClick={handleLink}>
          {isLoading ? <Skeleton variant='text' width={60} height={25} /> : '#' + invoice.invoiceNumber}
        </TableCellMiddle>

        <TableCellMiddle onClick={handleLink} align='center'>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : invoice.type === 'ReturnCustomer' ? (
            <span
              className={clsx(classes.statusReturn, {
                [classes.bgYelow]: (invoice.statusPayment as string) === 'Potong Sebagian',
                [classes.bgRed]: (invoice.statusPayment as string) === 'Belum dipotong',
                [classes.bgBlue]: (invoice.statusPayment as string) === 'Potong Keseluruhan'
              })}
            >
              {invoice.statusPayment}
            </span>
          ) : (
            <StatusPayment
              status={invoice.statusPayment}
              overdue={invoice.paymentDue ? new Date().getTime() > new Date(invoice.paymentDue).getTime() : false}
              totalPrice={invoice.totalPrice}
              totalPay={invoice.totalPay}
            />
          )}
        </TableCellMiddle>

        <TableCellMiddle onClick={handleLink}>
          {isLoading ? (
            <Skeleton variant='text' width={60} height={25} />
          ) : invoice.createdAt ? (
            format(new Date(invoice.createdAt), 'dd MMM yyyy')
          ) : (
            ''
          )}
        </TableCellMiddle>

        <TableCellMiddle onClick={handleLink}>
          {isLoading ? (
            <Skeleton variant='text' width={60} height={25} />
          ) : invoice.paymentDue ? (
            format(new Date(invoice.paymentDue), 'dd MMM yyyy')
          ) : (
            ''
          )}{' '}
        </TableCellMiddle>

        <TableCellMiddle onClick={handleLink}>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <NumberFormat value={invoice.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>

        <TableCellMiddle onClick={handleLink}>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <NumberFormat value={invoice.totalPrice - invoice.totalPay} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>

        <TableCellEnd align='center' className={classes.tableCellAction}>
          {invoice.typeInvoice && invoice.typeInvoice === 'ALLOWED' && (
            <Tooltip title='Invoice ini melalui izin plafon.'>
              <ErrorIcon fontSize='small' color='action' />
            </Tooltip>
          )}

          {invoice.isDue && (
            <Tooltip title='Invoice ini sudah melewati tanggal pelunasan.'>
              <TimerOffTwoToneIcon fontSize='small' color='error' className={classes.timeOut} />
            </Tooltip>
          )}
        </TableCellEnd>
      </TableRowCustom>
      <TableRowCollapse open={openCollapse && index === indexCollapse} colSpan={8}>
        <Table className={classes.tableCollapse} size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Nama</TableCell>
              <TableCell align='center'>Kuantitas</TableCell>
              <TableCell align='right'> Total Harga</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {collapseLoading ? (
              <TableRow>
                <TableCell colSpan={3}>
                  <Skeleton variant='text' width='100%' height={25} />
                </TableCell>
              </TableRow>
            ) : (
              invoice.InvoiceItem &&
              invoice.InvoiceItem.map((value: InvoiceItemModel, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.cellCollapse}>{value.productName ? value.productName : '-'}</TableCell>
                  <TableCell align='center' className={classes.cellCollapse}>
                    {value.productName ? value.totalItem : 0}
                  </TableCell>
                  <TableCell align='right' className={classes.cellCollapse}>
                    <NumberFormat value={value.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableRowCollapse>
    </Fragment>
  );
};

export default BodyRow;
